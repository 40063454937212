import { fieldsWithLikeAvailable } from "@Services";
import type { PaginateResultFetchArguments } from "../types.i";

const RESERVED_SEARCH_URL_KEYWORDS = [
  "organizationId",
  "projectId",
  "scanId",
  "page",
  "pageSize",
  "sortBy",
  "sortMode",
];

type Args = {
  urlParams: Record<string, string | undefined>;
  searchParams: URLSearchParams;
};

export const getFetchArguments = ({
  urlParams,
  searchParams,
}: Args): PaginateResultFetchArguments => {
  const result: PaginateResultFetchArguments = {
    organizationId: urlParams.organizationId || "",
    projectId: urlParams.projectId || "",
    scanId: urlParams.scanId || "",
    apiDefinitionId: urlParams.apiDefinitionId || "",
    // pagination
    page: 0,
    pageSize: 0,
    // sorts
    sortBy: undefined,
    sortMode: undefined,
    // filters
    criteria: undefined,
    operators: undefined,
    values: undefined,
  };

  // page
  const page = searchParams.get("page") || "1";
  const pageValue = parseInt(page, 10);
  result.page = isNaN(pageValue) ? 1 : pageValue;

  // pageSize
  const pageSize = searchParams.get("pageSize") || "12";
  const pageSizeValue = parseInt(pageSize, 10);
  result.pageSize = isNaN(pageSizeValue) ? 12 : pageSizeValue;

  // SortBy
  result.sortBy = searchParams.get("sortBy") || undefined;

  // SortMode
  const sortMode = searchParams.get("sortMode") || undefined;
  if ([undefined, "asc", "desc"].includes(sortMode)) {
    result.sortMode = sortMode as "asc" | "desc" | undefined;
  }

  // Criteria & Operator & Value
  searchParams.forEach((v, k) => {
    if (!v || RESERVED_SEARCH_URL_KEYWORDS.includes(k)) return;
    // criteria
    result.criteria = result.criteria ?? [];
    result.criteria.push(k);
    // operator
    result.operators = result.operators ?? [];
    result.operators.push(getOperator(k));
    // value
    result.values = result.values ?? [];
    result.values.push(v);
  });

  return result;
};

const getOperator = (criteria: string) => {
  return fieldsWithLikeAvailable.includes(criteria) ? "LIKE" : "EQ";
};
