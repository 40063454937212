import type { HeaderItems } from "@Interfaces";

export const getHeaders = (): HeaderItems => [
  { text: "common.version" },
  { text: "common.created-at", sortByKey: "api_definition_created_at" },
  { text: "common.title" },
  { text: "common.description" },
  { text: "common.revision" },
  { text: "common.patched-status", tooltip: "common.patched-status-tooltip" },
];
