import type { FC } from "react";
import { memo } from "react";
import type { BoxFileProps } from "./BoxFile.i";
import { t } from "i18next";
import { Button } from "@Components";
import { equals } from "@Services";

export const BoxFile: FC<BoxFileProps> = memo(
  ({ fileOAS, setProject, setProjectExtras }) => {
    const { error, upload } = fileOAS;
    const isValid = !error;

    const uploadInfo = {
      boxFileIcon: isValid ? "bi-folder-check" : "bi-folder-x",
      icon: isValid ? "bi-check-circle" : "bi-x-octagon",
      msg: isValid ? "successful-verification" : "invalid-file",
      extraClass: isValid ? "color-text-reverse" : undefined,
    };

    return (
      <div className="d-flex">
        <div className={`box-file w-100 ${isValid ? "valid" : "invalid"}`}>
          <i className={uploadInfo.boxFileIcon} />
          <div>{upload?.name}</div>
          <div className={`upload-info ${isValid ? "valid" : "invalid"}`}>
            <div className={uploadInfo.extraClass}>
              <div className="flex-center">
                <i className={`${uploadInfo.icon} fs-2`} />
                <span style={{ fontWeight: 600, marginLeft: 10 }}>
                  {t(`project.${uploadInfo.msg}`)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center ps-3">
          <Button
            color="danger"
            children="project.delete-file"
            outline
            iconClass="bi bi-trash-fill"
            onClick={() => {
              setProjectExtras(extras => ({
                ...extras,
                oasFile: null,
              }));
              setProject(project => ({
                ...project,
                baseURL: "",
                oasUploadData: [],
              }));
            }}
          />
        </div>
      </div>
    );
  },
  equals
);
