import { memo, useContext } from "react";
import { FullLogoWhite, FullLogo } from "@Assets";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { Button } from "@Components";
import { useOrganization } from "@Hooks";
import { ThemeContext } from "@Contexts";
import { CurtesyPage } from "../../CurtesyPage";
import { ORGANIZATION_PATH_NEW_PROJECT } from "@Constants";
import { generateUrl } from "@Utils";

export const NoProjects = memo(() => {
  const organization = useOrganization();
  const { theme } = useContext(ThemeContext);
  const newProjectUrl = generateUrl(ORGANIZATION_PATH_NEW_PROJECT, [
    organization?.id || "",
  ]);
  return (
    <CurtesyPage>
      <h1>
        {`${t("project.welcome")} `}
        <img
          src={theme === "dark" ? FullLogoWhite : FullLogo}
          alt="Logo"
          className="logo"
          width={155}
        />
        {"!"}
      </h1>
      <p className="mb-3">
        {t("project.create-new-for-organization", {
          organizationName: organization?.name,
        })}
      </p>
      <Link to={newProjectUrl}>
        <Button
          hiddenForReader
          color="primary"
          children={"project.new"}
          iconClass="bi bi-plus-square-fill"
        />
      </Link>
    </CurtesyPage>
  );
});
