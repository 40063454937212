import type { OrganizationId } from "@Interfaces";
import type { UserWithRole } from "@Services";
import { roles, users } from "src/Mock/data";

export const findUsersByOrganizationId = (organizationId: OrganizationId) => {
  const userIDs = roles
    .filter(r => r.organizationID === organizationId)
    .map(u => ({ userId: u.id, role: u }));

  const result = users
    .filter(u => userIDs.find(us => u.id === us.userId))
    .map(u => {
      const { active, expiresAt, role } = userIDs.filter(
        us => us.userId === u.id
      )[0].role;
      const userWithOrganizationRole: UserWithRole = {
        ...u,
        role: role,
        roleActive: active,
        expiresAt: expiresAt,
      };
      return userWithOrganizationRole;
    });

  return result;
};
