import { FormInput } from "../Form";
import { getFilterAllowedOptions } from "@Services";
import { useMemo } from "react";
import type { Props } from "./PageFilters.i";
import { RefreshListButton } from "../Buttons";
import { usePageFilters } from "./usePageFilters";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

export const InventoryFilters = ({ updateFilters, refresh }: Props) => {
  const { getValueOf } = usePageFilters();

  // inventory_item_path, inventory_item_verb, inventory_item_category
  const [verbOptions, authExpectedOptions] = useMemo(() => {
    return [
      getFilterAllowedOptions("verb"),
      getFilterAllowedOptions("inventory_item_authentication_expected"),
    ];
  }, []);
  return (
    <PageFiltersWrapper className="inventory-filters">
      <FormInput
        id="inventory-item-verb-select"
        type="select"
        options={verbOptions}
        className="me-3"
        label="common.method"
        value={getValueOf("inventory_item_verb")}
        onChange={e => {
          updateFilters({
            inventory_item_verb: e.target.value,
          });
        }}
      />
      <FormInput
        id="inventory-item-path-input"
        type="text"
        className="me-3"
        label="common.endpoint"
        value={getValueOf("inventory_item_path")}
        onChange={e => {
          updateFilters({
            inventory_item_path: e.target.value,
          });
        }}
      />
      <FormInput
        id="inventory-item-category-input"
        type="text"
        className="me-3"
        label="common.category"
        value={getValueOf("inventory_item_category")}
        onChange={e => {
          updateFilters({
            inventory_item_category: e.target.value,
          });
        }}
      />
      <FormInput
        id="inventory-item-authentication-expected-select"
        type="select"
        options={authExpectedOptions}
        className="me-3"
        label="common.authentication"
        value={getValueOf("inventory_item_authentication_expected")}
        onChange={e => {
          updateFilters({
            inventory_item_authentication_expected: e.target.value,
          });
        }}
      />
      <div className="d-flex align-items-end me-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
