import {
  findApiDefinitionFromUrl,
  findApiKeyIdFromUrl,
  findInventoryFromUrl,
  findIssueIdFromUrl,
  findOrganizationIdFromUrl,
  findProjectIdFromUrl,
  findScanIdFromUrl,
  findUserIdFromUrl,
  findSpaceLinkFromUrl,
} from "@Services";

export const getPathnameParams = (pathname: string) => ({
  organizationId: findOrganizationIdFromUrl(pathname),
  projectId: findProjectIdFromUrl(pathname),
  userId: findUserIdFromUrl(pathname),
  apiDefinitionId: findApiDefinitionFromUrl(pathname),
  scanId: findScanIdFromUrl(pathname),
  issueId: findIssueIdFromUrl(pathname),
  apiKeyId: findApiKeyIdFromUrl(pathname),
  inventoryItemId: findInventoryFromUrl(pathname),
  spaceLinkId: findSpaceLinkFromUrl(pathname),
});
