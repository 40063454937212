import {
  getDefaultAdvancedSettings,
  type AdvancedSettings,
  type UserGetScanAdvancedSettingsResponse,
} from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";

export const scanAdvancedSettings = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId, scanId } = req.params as Record<
    string,
    string
  >;

  if (!organizationId || !projectId || !scanId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const advancedSettings: AdvancedSettings = getDefaultAdvancedSettings();

  const response: UserGetScanAdvancedSettingsResponse = {
    advancedSettings,
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
