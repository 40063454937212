import { useUrlParams } from "@Hooks";
import type { NewProjectData } from "@Interfaces";
import type { Project } from "@Services";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import { DisplayRow } from "..";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";

type Props = {
  project: Project | NewProjectData;
} & Pick<MainProps, "buttonProps">;

export const DisplayRowProjectInfo = memo(({ project, buttonProps }: Props) => {
  const { projectId, organizationId } = useUrlParams();
  const columns = useMemo(
    () =>
      [
        {
          label: "common.name",
          value: project.name,
          xs: 12,
          xl: 4,
        },
        {
          label: "common.description",
          value: project.description,
          xs: 12,
          xl: 8,
        },
        {
          label: "common.base-url",
          value: project.baseURL,
          xs: 12,
          xl: 4,
        },
        {
          label: "project.production-url",
          value: (
            <FormGroup switch noMargin>
              <Input
                type="checkbox"
                checked={project.isProductionURL}
                readOnly
                className="pe-none"
                name="production-url-readonly-checkbox"
              />
              <span
                children={t(
                  `common.${project.isProductionURL ? "enabled" : "disabled"}`
                )}
              />
            </FormGroup>
          ),
          xs: 12,
          xl: 8,
        },
        {
          label: "common.organization-id",
          value: projectId ? organizationId : "",
          xs: 12,
          xl: 4,
        },
        {
          label: "common.project-id",
          value: projectId,
          xs: 12,
          xl: 4,
        },
      ]
        .filter(e => !!e.value)
        .map((e, i, self) => ({
          ...e,
          className: getClassName(i, self.length),
        })),
    [
      organizationId,
      project.baseURL,
      project.description,
      project.isProductionURL,
      project.name,
      projectId,
    ]
  );

  return (
    <>
      <DisplayRow
        title="common.project"
        columns={columns}
        buttonProps={buttonProps}
      />
    </>
  );
});

function getClassName(i: number, length: number, currentClassName = "") {
  switch (i) {
    case length - 2:
      return `${currentClassName} mb-4 mb-xl-0`.trim();
    case length - 1:
      return `${currentClassName} mb-0`.trim();
    default:
      return `${currentClassName} mb-4`.trim();
  }
}
