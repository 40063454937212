import { Breadcrumb } from "@Components";
import {
  ORGANIZATION_PATH_API_KEYS,
  organizationLastVisitedPageRegexp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type OrganizationApiKeysBreadcrumb = {
  disabled?: boolean;
};

export const OrganizationApiKeysListBreadcrumb = ({
  disabled = false,
}: OrganizationApiKeysBreadcrumb) => {
  const { organizationId } = useUrlParams();
  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(organizationLastVisitedPageRegexp("apikeys")) ??
      generateUrl(ORGANIZATION_PATH_API_KEYS, [organizationId ?? ""]);
  return (
    <Breadcrumb disabled={disabled} name={"organization-apikeys"} to={to}>
      {t("common.api-keys")}
    </Breadcrumb>
  );
};
