import {
  AuthWrapper,
  Breadcrumb,
  DashboardOrganization,
  HomeScanListBreadcrumb,
  OrganizationBreadcrumb,
  type MenuHorizontalElementProps,
} from "@Components";
import {
  HOME_ISSUES_URL,
  HOME_PATH,
  HOME_SCANS_URL,
  homeLastVisitedPageRegex,
} from "@Constants";
import { Home, HomeIssues, HomeScans, RedirectToHomePage } from "@Pages";
import { t } from "i18next";

export const HOME_BUTTONS: Array<MenuHorizontalElementProps> = [
  {
    name: "dashboard",
    path: HOME_PATH,
    type: "button",
    icon: "bi bi-speedometer",
  },
  {
    name: "scans",
    path: HOME_SCANS_URL,
    type: "button",
    partial: true,
    recentPageSearch: homeLastVisitedPageRegex("scans"),
    icon: "bi bi-ui-checks-grid",
  },
  {
    name: "issues",
    path: HOME_ISSUES_URL,
    type: "button",
    recentPageSearch: homeLastVisitedPageRegex("issues"),
    icon: "bi bi-exclamation-square-fill",
  },
];

export const HomeRoutes = [
  {
    path: "",
    element: (
      <AuthWrapper element={<RedirectToHomePage />} pageName="Dashboard" />
    ),
  },
  {
    path: HOME_PATH,
    element: <AuthWrapper element={<Home />} pageName="Dashboard" />,
    handle: {
      buttons: HOME_BUTTONS,
      crumb: <OrganizationBreadcrumb />,
      section: "homepage",
    },
    children: [
      {
        path: "",
        element: <DashboardOrganization />,
        handle: {
          crumb: (
            <Breadcrumb name="dashboard" to={HOME_PATH}>
              {t("nav.dashboard")}
            </Breadcrumb>
          ),
        },
      },
      {
        path: `scans`,
        element: <HomeScans />,
        handle: {
          crumb: <HomeScanListBreadcrumb />,
        },
      },
      {
        path: `issues`,
        element: <HomeIssues />,
        handle: {
          crumb: (
            <Breadcrumb name="issues" to={HOME_ISSUES_URL}>
              {t("common.list-issues")}
            </Breadcrumb>
          ),
        },
      },
    ],
  },
];
