import type { PlainObject } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, organizations } from "src/Mock";

export const getOrganization = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as PlainObject<string>;

  if (!organizationId) {
    return res(ctx.delay(delay), ctx.status(403));
  }

  const result = organizations.filter(item => item.id === organizationId);

  if (!result) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  return res(ctx.delay(delay), ctx.json(result[0]));
};
