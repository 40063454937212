import { InventoryFilters, ListItems, Paginator, Section } from "@Components";
import type { ListedInventoryItem } from "@Services";
import { type Dispatch, memo } from "react";
import { useParameterLinksAPIDefinitionList } from "../Hooks/useParameterLinksAPIDefinitionList";

type ParameterLinksAPIDefinitionListProps = {
  onItemSelect: Dispatch<ListedInventoryItem>;
};
export const ParameterLinksAPIDefinitionList = memo(
  ({ onItemSelect }: ParameterLinksAPIDefinitionListProps) => {
    const {
      config,
      fetch,
      loading,
      defaultFilters,
      headers,
      response,
      updateFilters,
    } = useParameterLinksAPIDefinitionList(onItemSelect);

    return (
      <div id="parameter-links-api-definition-list">
        <Section removeLowerRadius>
          <InventoryFilters refresh={fetch} updateFilters={updateFilters} />
        </Section>
        <ListItems
          headers={headers}
          showSkeleton={loading}
          config={config}
          cardVersion={false}
          noDataMessage="common.no-endpoints-found"
          clickable
          updateCardItemOrder={[0, 2, 4, 3, 1, 5]}
          fullRowIndexes={[0, 1]}
          setSorts={updateFilters}
          defaultSorts={defaultFilters}
        />
        <Paginator
          setFilters={updateFilters}
          totalItems={response?.totalItems}
        />
      </div>
    );
  }
);
