import { DisplayTable, TrashButton } from "@Components";
import { useAuthenticationSettingFormStore } from "@Stores";
import { t } from "i18next";
import { memo, useCallback } from "react";
import { EMPTY_STRING_LABEL } from "./shared";

export const AdditionalDataTable = memo(() => {
  const { authenticationSettings, loading, setAuthenticationSettings } =
    useAuthenticationSettingFormStore();

  const deleteEntry = useCallback(
    (key: string) => {
      setAuthenticationSettings(prev => {
        if (!prev.oAuthData?.additionalData) return prev;
        const additionalData = structuredClone(prev.oAuthData.additionalData);
        delete additionalData[key];
        prev.oAuthData.additionalData = additionalData;
        return { ...prev };
      });
    },
    [setAuthenticationSettings]
  );

  if (
    !Object.entries(authenticationSettings.oAuthData?.additionalData ?? {})
      .length
  ) {
    return null;
  }

  return (
    <DisplayTable>
      <thead>
        <tr>
          <th className="text-end">{t("common.key")}</th>
          <th>{t("common.value")}</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(
          authenticationSettings.oAuthData?.additionalData ?? {}
        ).map(([key, value], i) => {
          return (
            <tr key={`${i}-${key}-${value}`}>
              <td>{key}</td>
              <td>
                <div className="d-flex justify-content-between align-items-center">
                  {value ? (
                    <span>{value}</span>
                  ) : (
                    <i className="opacity-75">{EMPTY_STRING_LABEL}</i>
                  )}
                  <TrashButton
                    onClick={() => deleteEntry(key)}
                    disabled={loading}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </DisplayTable>
  );
});
