import { CYPRESS_RUNNING } from "@Constants";
import type { OrganizationAdminUpdateUserRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getUserById } from "src/Mock";

export const updateUser = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { userId } = req.params as Record<string, string>;
  const payload = (await req.json()) as OrganizationAdminUpdateUserRequest;

  const user = getUserById(userId);

  if (!user) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const { name, surname } = payload;

  if (CYPRESS_RUNNING) {
    if ([name, surname].some(s => s.toLowerCase().includes("invalid"))) {
      return res(ctx.delay(delay), ctx.status(400));
    }
  }

  user.name = payload.name;
  user.surname = payload.surname;
  return res(ctx.delay(delay), ctx.json(user), ctx.status(200));
};
