import React from "react";
import type { Props } from "./NewPasswordRules.i";
import { t } from "i18next";

import "./style.scss";

const tt = (str: string) => t(`components.new-psw-rules.${str}`);

export const NewPasswordRules = ({
  hasValidLength,
  hasUppercaseLetter,
  hasLowercaseLetter,
  hasNumber,
  hasSpecialChar,
}: Props) => {
  return (
    <div id="password-rules">
      <p className={hasValidLength ? "text-success" : "text-danger"}>
        <i
          className={`bi ${
            hasValidLength ? "bi-check-circle" : "bi-x-circle"
          } me-2`}
        />
        <span>{tt("valid-length")}</span>
      </p>
      <p className={hasUppercaseLetter ? "text-success" : "text-danger"}>
        <i
          className={`bi ${
            hasUppercaseLetter ? "bi-check-circle" : "bi-x-circle"
          } me-2`}
        />
        <span>{tt("has-uppercase-letter")}</span>
      </p>
      <p className={hasLowercaseLetter ? "text-success" : "text-danger"}>
        <i
          className={`bi ${
            hasLowercaseLetter ? "bi-check-circle" : "bi-x-circle"
          } me-2`}
        />
        <span>{tt("has-lowercase-letter")}</span>
      </p>
      <p className={hasNumber ? "text-success" : "text-danger"}>
        <i
          className={`bi ${hasNumber ? "bi-check-circle" : "bi-x-circle"} me-2`}
        />
        <span>{tt("has-number")}</span>
      </p>
      <p className={hasSpecialChar ? "text-success" : "text-danger"}>
        <i
          className={`bi ${
            hasSpecialChar ? "bi-check-circle" : "bi-x-circle"
          } me-2`}
        />
        <span>{tt("has-special-char")}</span>
      </p>
    </div>
  );
};
