import React from "react";
import { WorkerContext } from "@Contexts";

export const useOtpToken = () => {
  const instance = React.useContext(WorkerContext);
  const [token, setToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    instance.onmessage = message => {
      if (message) {
        setToken(message.data);
      }
    };

    instance.postMessage({
      type: "GET_CACHE",
      key: "otp-token",
    });
  }, [instance]);

  return token;
};
