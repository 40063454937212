import type { Nullable } from "@Interfaces";
import { t } from "i18next";
import { ALT_KEY, CTRL_KEY, KeyCodes, SHIFT_KEY } from "./keycodes";

export class KeyAction {
  private _keyCode: Nullable<KeyCodes> = null;
  private _ctrl = false;
  private _alt = false;
  private _shift = false;
  private _descriptionDictionaryKey = "";
  private _priority = 0;
  private _prefix = "";

  private _keyEventString = "";

  constructor(keyCode: KeyCodes) {
    this._keyCode = keyCode;
    this.buildKeyEventString();
    return this;
  }

  /* GETTERS */

  public getCtrl() {
    return this._ctrl;
  }

  public getAlt() {
    return this._alt;
  }

  public getShift() {
    return this._shift;
  }

  public getKeyCode() {
    return this._keyCode;
  }

  public getKeyEventString() {
    return this._keyEventString;
  }

  public getDescription() {
    return this._descriptionDictionaryKey;
  }

  public getTitle() {
    return `${t(this.getDescription())} [${this.getKeyEventString()}]`;
  }

  public getPriority() {
    return this._priority;
  }

  public getPrefix() {
    return this._prefix;
  }

  /* BUILDERS */
  public withCtrl() {
    this._ctrl = true;
    this.buildKeyEventString();
    return this;
  }

  public withAlt() {
    this._alt = true;
    this.buildKeyEventString();
    return this;
  }

  public withShift() {
    this._shift = true;
    this.buildKeyEventString();
    return this;
  }

  public withDescription(dictionaryKey: string) {
    this._descriptionDictionaryKey = dictionaryKey;
    return this;
  }

  public withPriority(priority: number) {
    this._priority = priority;
    return this;
  }

  public withPrefix(prefix: string) {
    this._prefix = prefix;
    return this;
  }

  /* UTILITIES */
  private buildKeyEventString() {
    this._keyEventString = [
      this._ctrl && CTRL_KEY,
      this._alt && ALT_KEY,
      this._shift && SHIFT_KEY,
      this._keyCode?.toLowerCase(),
    ]
      .filter(e => !!e)
      .join("-");
  }

  public hasPriorityOver(other: KeyAction): boolean {
    return this.getPriority() >= other.getPriority();
  }

  public isEventKey(key: string) {
    return this.getKeyEventString()?.toLowerCase() === key;
  }
}

export const KA_SHIFT_Q = new KeyAction(KeyCodes.KeyQ).withShift();

/* NAVIGATION ACTIONS */

export const KA_NAVIGATE_TO_HOME = new KeyAction(KeyCodes.KeyH)
  .withCtrl()
  .withShift()
  .withDescription("keyboard.shortcuts.navigation.home");

export const KA_NAVIGATE_TO_PROJECTS = new KeyAction(KeyCodes.KeyP)
  .withCtrl()
  .withShift()
  .withDescription("keyboard.shortcuts.navigation.projects");

export const KA_NAVIGATE_TO_ORGANIZATION = new KeyAction(KeyCodes.KeyO)
  .withCtrl()
  .withShift()
  .withDescription("keyboard.shortcuts.navigation.organization");

export const KA_NAVIGATE_TO_PROFILE = new KeyAction(KeyCodes.KeyP)
  .withCtrl()
  .withDescription("keyboard.shortcuts.navigation.profile");

export const KA_NAVIGATIONS = [
  KA_NAVIGATE_TO_HOME,
  KA_NAVIGATE_TO_ORGANIZATION,
  KA_NAVIGATE_TO_PROJECTS,
  KA_NAVIGATE_TO_PROFILE,
];

/* PROFILE NAV MENU ACTIONS */

export const KA_NAV_PROFILE_MENU_TOGGLE = new KeyAction(KeyCodes.KeyO)
  .withCtrl()
  .withAlt()
  .withDescription("keyboard.shortcuts.nav-profile-menu.toggle");

export const KA_NAV_PROFILE_MENU_ESCAPE = new KeyAction(
  KeyCodes.Escape
).withDescription("keyboard.shortcuts.nav-profile-menu.escape");

export const KA_NAV_PROFILE_MENU_SEARCH = new KeyAction(KeyCodes.KeyF)
  .withCtrl()
  .withDescription("keyboard.shortcuts.nav-profile-menu.search");

export const KA_NAV_PROFILE_MENU_LOGOUT = new KeyAction(KeyCodes.KeyL)
  .withCtrl()
  .withAlt()
  .withDescription("keyboard.shortcuts.nav-profile-menu.logout");

export const KA_NAV_PROFILE_ACTIONS = [
  KA_NAV_PROFILE_MENU_TOGGLE,
  KA_NAV_PROFILE_MENU_ESCAPE,
  KA_NAV_PROFILE_MENU_SEARCH,
  KA_NAV_PROFILE_MENU_LOGOUT,
];

/* PROFILE ACTIONS */
export const KA_PROFILE_NAVIGATE_SHORTCUT_ACTION = new KeyAction(KeyCodes.KeyS)
  .withCtrl()
  .withDescription("keyboard.shortcuts.profile.navigate-shortcuts");

export const KA_PROFILE_ACTIONS = [KA_PROFILE_NAVIGATE_SHORTCUT_ACTION];

/* HTTP HISTORY ACTIONS */
export const KA_SCAN_DETAILS_HTTP_HISTORY_SHORTCUT_ACTION = new KeyAction(
  KeyCodes.Escape
).withDescription("keyboard.shortcuts.scan-details.http-history.deselect-item");

export const KA_SCAN_DETAILS_HTTP_HISTORY_PREV_ITEM_ACTION = new KeyAction(
  KeyCodes.ArrowDown
).withDescription("keyboard.shortcuts.scan-details.http-history.deselect-item");

export const KA_SCAN_DETAILS_HTTP_HISTORY_NEXT_ITEM_ACTION = new KeyAction(
  KeyCodes.ArrowDown
).withDescription("keyboard.shortcuts.scan-details.http-history.deselect-item");

export const KA_SCAN_DETAILS_HTTP_HISTORY_PREVIOUS_ITEM_ACTION = new KeyAction(
  KeyCodes.ArrowUp
).withDescription("keyboard.shortcuts.scan-details.http-history.deselect-item");

export const KA_SCAN_DETAILS_HTTP_HISTORY_ACTIONS = [
  KA_SCAN_DETAILS_HTTP_HISTORY_SHORTCUT_ACTION,
];

/* SCAN DETAILS ISSUE DETAILS ACTIONS */
export const KA_SCAN_DETAILS_ISSUE_DETAILS_EXIT_ACTION = new KeyAction(
  KeyCodes.Escape
).withDescription("keyboard.shortcuts.scan-details.issue.close");

export const KA_SCAN_DETAILS_ISSUE_DETAILS_ACTIONS = [
  KA_SCAN_DETAILS_ISSUE_DETAILS_EXIT_ACTION,
];
/* NEW PROJECT ACTIONS */

export const KA_NEW_PROJECT_CANCEL_ACTION = new KeyAction(
  KeyCodes.Escape
).withDescription("keyboard.shortcuts.new-project.cancel");

export const KA_NEW_PROJECT_ADD_ACTION = new KeyAction(
  KeyCodes.Enter
).withDescription("keyboard.shortcuts.new-project.add");

export const KA_NEW_PROJECT_ACTIONS = [
  KA_NEW_PROJECT_CANCEL_ACTION,
  KA_NEW_PROJECT_ADD_ACTION,
];

/* INVENTORY GRAPH ACTIONS */

export const KA_INVENTORY_GRAPH_DESELECT_ACTION = new KeyAction(
  KeyCodes.Escape
).withDescription("keyboard.shortcuts.inventory.graph.deselect");

export const KA_INVENTORY_GRAPH_ACTIONS = [KA_INVENTORY_GRAPH_DESELECT_ACTION];

/* API DETAILS ACTIONS */
export const KA_INVENTORY_ITEM_DETAILS_EXCLUDE_ACTION = new KeyAction(
  KeyCodes.KeyE
)
  .withCtrl()
  .withAlt()
  .withDescription("keyboard.shortcuts.inventory-item-details.exclude");

export const KA_INVENTORY_ITEM_DETAILS_ADD_PARAMETERS_LINK_ACTION =
  new KeyAction(KeyCodes.KeyP)
    .withCtrl()
    .withAlt()
    .withDescription(
      "keyboard.shortcuts.inventory-item-details.add-parameters-link"
    );

export const KA_INVENTORY_ITEM_DETAILS_ACTIONS = [
  KA_INVENTORY_ITEM_DETAILS_EXCLUDE_ACTION,
  KA_INVENTORY_ITEM_DETAILS_ADD_PARAMETERS_LINK_ACTION,
];

/* PROJECT ACTIONS */

export const KA_PROJECT_PARAMETER_LINKS_GRAPH_DESELECT_NODE_ACTION =
  new KeyAction(KeyCodes.Escape).withDescription(
    "keyboard.shortcuts.project.graph.deselect"
  );

export const KA_PROJECT_ACTIONS = [
  KA_PROJECT_PARAMETER_LINKS_GRAPH_DESELECT_NODE_ACTION,
];
