import { ThemeContext } from "@Contexts";
import type { ButtonProps, OffCanvasProps } from "@Components";
import { useContext, useMemo } from "react";

const useOffcanvas = ({
  exitLabel = "common.cancel",
  toggle,
  buttons: propsButtons,
  className: propsClassName,
  size,
  ...others
}: OffCanvasProps) => {
  const { theme } = useContext(ThemeContext);
  const close = () => toggle(false);

  const buttons = useMemo(() => {
    const buttonsBuilder: ButtonProps[] = [
      {
        onClick: () => toggle(false),
        children: exitLabel,
        color: "secondary",
        iconClass: "x-square-fill",
        size: "md",
      },
    ];
    propsButtons?.forEach(button => {
      buttonsBuilder.push({ ...button, size: "md" });
    });
    return buttonsBuilder;
  }, [exitLabel, propsButtons, toggle]);

  const className = useMemo(() => {
    const classNameBuilder = [propsClassName, theme];
    if (size && size !== "sm") {
      classNameBuilder.push(`size-${size}`);
    }
    return classNameBuilder.filter(Boolean).join(" ");
  }, [propsClassName, size, theme]);

  return { ...others, theme, close, buttons, className };
};

export default useOffcanvas;
