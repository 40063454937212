import {
  Button,
  ConfirmDialog,
  DisplaySection,
  GridContainer,
  TrashButton,
} from "@Components";
import { capitalizeString } from "@Services";
import { useState, type FC } from "react";
import type { AuthenticationSettingsCardProps } from "./AuthenticationCard.i";
import { DetailsAccordion } from "./Components";

export const AuthenticationCard: FC<AuthenticationSettingsCardProps> = ({
  authenticationSettings,
  onDelete,
  onEdit,
  onClone,
  disabled,
  loading,
  injectables,
  mtlsCertificates,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  return (
    <div className="authentication-card editable-card">
      <DisplaySection
        label="common.name"
        value={authenticationSettings.name}
        className="mb-3"
      />
      <DisplaySection
        className="mb-3"
        label="common.description"
        value={authenticationSettings.description}
      />
      <div className="d-flex mb-3">
        <DisplaySection
          className="w-50"
          label="common.role"
          value={capitalizeString(authenticationSettings.role)}
        />
        <DisplaySection
          className="w-50"
          label="common.group"
          value={authenticationSettings.group || "-"}
        />
      </div>
      <DetailsAccordion
        authenticationSettings={authenticationSettings}
        injectables={injectables}
        mtlsCertificates={mtlsCertificates}
      />
      {/*  */}
      <GridContainer rows={3} className="card-actions">
        <>
          {onDelete && (
            <TrashButton
              disabled={disabled}
              loading={loading}
              onClick={() => setShowConfirmDialog(true)}
            />
          )}
          {onEdit && (
            <Button
              iconClass="bi bi-pencil"
              loading={loading}
              disabled={disabled}
              onClick={onEdit}
              color="primary"
              hiddenForReader
              data={{ cy: "button-edit" }}
            />
          )}
          {/*onClone && (
            <Button
              iconClass="bi bi-copy"
              loading={loading}
              disabled={disabled}
              onClick={onClone}
              color="primary"
              data={{ cy: "button-clone" }}
            />
          )*/}
        </>
      </GridContainer>
      {onDelete && showConfirmDialog && (
        <ConfirmDialog
          title="confirmation-dialog.title.delete-configuration"
          description="confirmation-dialog.description.delete-configuration"
          cancelLabel="confirmation-dialog.cancel.delete-configuration"
          confirmLabel="confirmation-dialog.confirm.delete-configuration"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={() => {
            setShowConfirmDialog(false);
            onDelete();
          }}
        />
      )}
    </div>
  );
};
