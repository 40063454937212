import { useMemo } from "react";
import type { Props } from "./PageFilters.i";
import { getFilterAllowedOptions } from "@Services";
import { FormInput, RefreshListButton } from "@Components";
import { usePageFilters } from "./usePageFilters";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

export const OrganizationUsersFilters = ({ updateFilters, refresh }: Props) => {
  const { getValueOf } = usePageFilters();
  const [userRoleOptions] = useMemo(() => {
    return [getFilterAllowedOptions("enabled")];
  }, []);
  return (
    <PageFiltersWrapper>
      <FormInput
        type="text"
        id="user-surname-name-input"
        label="common.name"
        value={getValueOf("user_surname_name")}
        onChange={e => {
          updateFilters({
            user_surname_name: e.target.value,
          });
        }}
      />
      <FormInput
        type="text"
        id="user-email-input"
        label="common.email"
        className="mx-3"
        value={getValueOf("user_email")}
        onChange={e => {
          updateFilters({
            user_email: e.target.value,
          });
        }}
      />
      <FormInput
        type="select"
        id="user-role-active-input"
        label="common.active"
        value={getValueOf("user_role_active")}
        options={userRoleOptions}
        onChange={e => {
          updateFilters({
            user_role_active: e.target.value,
          });
        }}
      />
      <div className="d-flex align-items-end ms-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
