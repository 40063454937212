import type {
  FormInputProps,
  PropsCheckbox,
  PropsNumber,
  PropsPassword,
  PropsSelect,
  PropsText,
  PropsTextArea,
} from "./FormInput.i";
import { useMemo, useState, type FC } from "react";
import { t } from "i18next";
import { Button, Input, InputGroup, Label } from "reactstrap";
import { BEM, joinClasses } from "@Utils";
import { ClipboardBadge } from "@Components";
import { handleAutoTrim } from "./function";

export const FormInput: FC<FormInputProps> = ({
  label,
  className,
  ...props
}) => {
  const inputJSX = useMemo(() => {
    switch (props.type) {
      case "text":
        return <TextFormInput {...props} />;
      case "textarea":
        return <TextAreaFormInput {...props} />;
      case "select":
        return <SelectFormInput {...props} />;
      case "number":
        return <NumberFormInput {...props} />;
      case "checkbox":
        return <CheckboxFormInput {...props} />;
      case "password":
        return <PasswordFormInput {...props} />;
    }
  }, [props]);

  const labelJSX = useMemo(() => {
    const showLabel = !!label && !!props.id;
    return showLabel && <Label for={props.id}>{t(label)}</Label>;
  }, [label, props.id]);

  return (
    <div
      className={joinClasses(BEM("form-input", null, props.type), className)}
    >
      {labelJSX}
      {inputJSX}
    </div>
  );
};

//

const TextFormInput: FC<PropsText> = ({
  className,
  inputClassName,
  maxLength = 255,
  autoTrim,
  ...props
}) => {
  return (
    <Input
      {...props}
      className={joinClasses(inputClassName, !!props.value && "dirty")}
      maxLength={maxLength}
      onBlur={e => handleAutoTrim(e, { ...props, autoTrim })}
    />
  );
};

const TextAreaFormInput: FC<PropsTextArea> = ({
  className,
  inputClassName,
  rows = 5,
  maxLength = 255,
  autoTrim,
  ...props
}) => {
  return (
    <Input
      {...props}
      className={joinClasses(inputClassName, !!props.value && "dirty")}
      rows={rows}
      onBlur={e => handleAutoTrim(e, { ...props, autoTrim })}
    />
  );
};

const SelectFormInput: FC<PropsSelect> = ({
  className,
  inputClassName,
  options,
  value,
  ...props
}) => {
  const optionsJSX = useMemo(() => {
    return options.map((o, i) => (
      <option
        value={o.value}
        key={`${o.value}$-${i}`}
        className={joinClasses(i > 0 && value === o.value && "selected")}
      >
        {t(o.label)}
      </option>
    ));
  }, [options, value]);
  const isDirty = value && value !== options[0].value;
  return (
    <Input
      value={value}
      {...props}
      className={joinClasses(inputClassName, isDirty ? "dirty" : undefined)}
    >
      {optionsJSX}
    </Input>
  );
};

const NumberFormInput: FC<PropsNumber> = ({
  className,
  inputClassName,
  ...props
}) => {
  return (
    <Input
      {...props}
      className={joinClasses(inputClassName, !!`${props.value}` && "dirty")}
    />
  );
};

const CheckboxFormInput: FC<PropsCheckbox> = ({
  className,
  inputClassName,
  ...props
}) => {
  return (
    <Input
      {...props}
      className={joinClasses(inputClassName, !!`${props.value}` && "dirty")}
    />
  );
};

const PasswordFormInput: FC<PropsPassword> = ({
  className,
  inputClassName,
  canBeSeen = false,
  canBeCopy = false,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const input = (
    <Input
      {...props}
      className={joinClasses(inputClassName, !!props.value && "dirty")}
      type={visible ? "text" : "password"}
    />
  );

  if (!canBeCopy && !canBeSeen) {
    return <>{input}</>;
  }

  return (
    <InputGroup>
      {input}
      {canBeSeen && (
        <Button
          className="btn-toggler"
          onClick={() => setVisible(prev => !prev)}
        >
          <i className={`bi ${visible ? "bi-eye" : "bi-eye-slash"}`} />
        </Button>
      )}
      {canBeCopy && <ClipboardBadge value={`${props.value}`} />}
    </InputGroup>
  );
};
