import { DangerZone } from "@Components";
import { useUrlParams } from "@Hooks";
import type { ApiKey } from "@Interfaces";
import { API } from "@Services";
import { useCallback, type FC } from "react";
import { useNavigateToApiKeyList } from "../../Hooks/useNavigateToApiKeyList";

type DeleteApiKeyProps = {
  apiKey: ApiKey;
};

export const DeleteApiKey: FC<DeleteApiKeyProps> = ({ apiKey }) => {
  const { organizationId } = useUrlParams();
  const handleOnSuccess = useNavigateToApiKeyList();

  const deletePromise = useCallback(() => {
    if (!organizationId || !apiKey.id) {
      throw new Error("Invalid url params");
    }
    return API.apiKeys().organizationAdminApiKeysDelete(
      organizationId,
      apiKey.id
    );
  }, [apiKey.id, organizationId]);

  return (
    <DangerZone
      confirmationText={apiKey.name}
      target="api-key"
      promise={deletePromise}
      subject={apiKey.name}
      toastErrorMessage="delete-api-key"
      toastSuccessMessage="delete-api-key"
      onSuccess={handleOnSuccess}
    />
  );
};
