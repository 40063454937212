import { type ButtonProps, FormInput, Offcanvas, Switch } from "@Components";
import { useMemo } from "react";
import { FormGroup } from "reactstrap";
import type { EdiAPiKeyProps } from "./EditApiKey.i";
import { useEditApiKey } from "./useEditApiKey";

export const EditApiKey = (props: EdiAPiKeyProps) => {
  const {
    apiKey,
    setApiKey,
    closeCanvas,
    isOpen,
    loading,
    modified,
    options,
    handleUpdate,
  } = useEditApiKey(props);

  const saveButton: ButtonProps = useMemo(
    () => ({
      color: "primary",
      children: "common.save",
      disabled: !modified,
      loading,
      onClick: handleUpdate,
      iconClass: "bi bi-floppy",
    }),
    [loading, modified, handleUpdate]
  );

  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={closeCanvas}
      title="organization.edit-api-key"
      buttons={[saveButton]}
    >
      <FormGroup>
        <FormInput
          id="api-key-input-role"
          data-cy="api-key-input-role"
          label="common.role"
          type="select"
          options={options}
          value={apiKey.role}
          onChange={e => setApiKey({ ...apiKey, role: e.target.value })}
        />
      </FormGroup>
      <div>
        <Switch
          title="common.status"
          checked={apiKey.active}
          id="api-key-input-active"
          data-cy="api-key-input-active"
          onChange={e => setApiKey({ ...apiKey, active: e.target.checked })}
        />
      </div>
    </Offcanvas>
  );
};
