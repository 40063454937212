import { FormInput } from "@Components";
import {
  KA_NAV_PROFILE_MENU_ESCAPE,
  KA_NAV_PROFILE_MENU_SEARCH,
} from "@Constants";
import { useKeyboardEvent } from "@Hooks";
import type { Nullable, Organization } from "@Interfaces";
import { joinClasses } from "@Utils";
import { t } from "i18next";
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
} from "react";
import isEqual from "react-fast-compare";
import { useNavProfileStore } from "../NavProfileCardStore";

type NavProfileCardOrganizationsProps = {
  activeOrganization: Nullable<Organization>;
  loading: boolean;
  handleFilterOrganization: Dispatch<string>;
  handleOrganizationClick: (
    organization: Organization,
    isActive: boolean
  ) => void;
  handlEscape: VoidFunction;
};
export const NavProfileCardOrganizations = memo(
  ({
    activeOrganization,
    loading,
    handleFilterOrganization,
    handleOrganizationClick,
    handlEscape,
  }: NavProfileCardOrganizationsProps) => {
    const { resetOrganizations, defaultOrganizations, organizations } =
      useNavProfileStore();
    const [open, setOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const activeOrganizationRef = useRef<HTMLButtonElement>(null);
    const organizationInputChangeTimeout =
      useRef<Nullable<NodeJS.Timeout>>(null);

    const canOpenOrganizationButtons =
      defaultOrganizations && defaultOrganizations.data.length > 0;

    useKeyboardEvent({
      action: KA_NAV_PROFILE_MENU_ESCAPE,
      onKeyDown: () => {
        resetOrganizations();
        if (open) {
          setOpen(false);
        } else {
          handlEscape();
        }
      },
    });

    useKeyboardEvent({
      action: KA_NAV_PROFILE_MENU_SEARCH,
      onKeyDown: () => {
        if (open) {
          inputRef.current?.focus();
        } else {
          setOpen(true);
        }
      },
    });

    useEffect(() => {
      if (open || !canOpenOrganizationButtons) {
        return;
      }

      const request = requestAnimationFrame(() =>
        activeOrganizationRef.current?.focus()
      );
      return () => cancelAnimationFrame(request);
    }, [open, activeOrganizationRef, canOpenOrganizationButtons]);

    useEffect(() => {
      if (organizationInputChangeTimeout.current) {
        clearTimeout(organizationInputChangeTimeout.current);
      }
      resetOrganizations();
    }, [open, resetOrganizations]);

    useEffect(() => {
      const request = requestAnimationFrame(() => inputRef.current?.focus());
      return () => cancelAnimationFrame(request);
    }, [organizations]);

    const organizationButtons = useMemo(() => {
      if (!organizations?.data.length) {
        return (
          <p
            className={joinClasses(
              "color-severity-medium mt-2 font-size-caption",
              loading && "loading"
            )}
            id="no-organizations"
          >
            {t("nav.no-organizations-found")}
          </p>
        );
      }
      return organizations.data?.map(organization => {
        return (
          <button
            key={organization.id}
            className={joinClasses(
              `text-nowrap organization`,
              loading && "loading"
            )}
            data-cy={`organization-${organization.id}`}
            onClick={e => handleOrganizationClick(organization, false)}
          >
            <div className="d-flex flex-column w-100 align-items-start">
              <span className="text-truncate">{organization.name}</span>
              <span
                className="font-size-caption color-gray text-truncate"
                title={organization.address}
              >
                {organization.address}
              </span>
            </div>
            <i className="bi bi-diagram-3" />
          </button>
        );
      });
    }, [handleOrganizationClick, organizations?.data, loading]);

    const activeOrganizationButton = useMemo(() => {
      if (!activeOrganization) {
        return null;
      }

      return (
        <button
          type="submit"
          className={`text-nowrap organizations__active`}
          data-cy={`organization-active-button`}
          ref={activeOrganizationRef}
          onClick={() =>
            canOpenOrganizationButtons ? setOpen(o => !o) : undefined
          }
        >
          <i className="bi bi-diagram-3" />
          <div>
            <span className="font-size-caption">
              {t("common.organization")}
            </span>
            <span className="font-size-body text-truncate">
              <b>{activeOrganization.name}</b>
            </span>
          </div>
          {canOpenOrganizationButtons && (
            <i className="bi bi-chevron-expand"></i>
          )}
        </button>
      );
    }, [activeOrganization, canOpenOrganizationButtons]);

    const organizationOtherLabel = useMemo(() => {
      if (!organizations) {
        return null;
      }

      if (organizations.totalPages <= 0) {
        return null;
      }

      const totalItems =
        organizations.totalItems - organizations.data.length - 1;

      if (totalItems <= 0) {
        return;
      }

      return (
        <p className="other-organizations font-size-caption">
          {t("nav.other-organization-items", { totalItems })}
        </p>
      );
    }, [organizations]);

    const handleInputChange = useCallback(
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (organizationInputChangeTimeout.current) {
          clearTimeout(organizationInputChangeTimeout.current);
        }
        organizationInputChangeTimeout.current = setTimeout(
          () => handleFilterOrganization(target.value),
          500
        );
      },
      [handleFilterOrganization]
    );

    const filter = useMemo(
      () => (
        <FormInput
          autoFocus
          innerRef={inputRef}
          id="organization-search"
          data-cy="organization-search"
          placeholder={t("nav.organization-search-placeholder")}
          type="text"
          onChange={handleInputChange}
        />
      ),
      [handleInputChange]
    );

    return (
      <div className="organizations">
        {open ? (
          <div className="organizations__list-wrapper">
            {organizationButtons}
            {organizationOtherLabel}
            {filter}
            <button
              type="submit"
              className="font-size-caption color-gray mt-2 mb-0 interactable"
              onClick={handlEscape}
              dangerouslySetInnerHTML={{
                __html: t("common.keyboard-hint", {
                  keyCode: "Escape",
                  action: t("common.close"),
                }),
              }}
            />
          </div>
        ) : (
          activeOrganizationButton
        )}
      </div>
    );
  },
  isEqual
);
