import React from "react";
import { useNavigate } from "react-router-dom";
import { WorkerContext } from "@Contexts";
import { API, config, storeJwtToken } from "@Services";
import { t } from "i18next";
import { AxiosError } from "axios";

export const useLogin = () => {
  const instance = React.useContext(WorkerContext);

  const navigate = useNavigate();

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>,
    username: string,
    password: string,
    setError: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    event.preventDefault();
    if (!username || !password) {
      setError(t("login.err-no-fields") as string);
    } else {
      setLoading(true);
      try {
        const response = (
          await API.login().login({
            email: username,
            password,
          })
        ).data;

        const success = (path: string) => {
          instance.postMessage({
            type: "SET_CACHE",
            key: "otp-token",
            data: response.token,
          });

          storeJwtToken(response.token, () => navigate(path));
        };

        if (response?.status === config["otp"]["SUCCESS"]) {
          success("/");
        } else if (
          response?.status === config["otp"]["OTP_REQUIRED"] &&
          response?.token
        ) {
          success("/2fa");
        } else if (
          response?.status === config["otp"]["OTP_SETUP_REQUIRED"] &&
          response?.token
        ) {
          success("/setup-2fa");
        } else {
          setError(t("login.login-api-error"));
        }
      } catch (error) {
        let errorMessage = "error-page.500.error";
        if (
          error &&
          typeof error === "object" &&
          "response" in error &&
          error instanceof AxiosError
        ) {
          if (error.response?.status === 400) {
            errorMessage = "login.err-invalid-fields";
          }
        }
        setError(t(errorMessage) as string);
      }
      setLoading(false);
    }
  };

  return { handleSubmit };
};
