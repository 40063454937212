import { memo } from "react";
import "./style.scss";
export const CubesSVG = memo(
  () => {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 471 338"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cubes"
      >
        <path
          d="M338.053 13.1895L376.045 35.1241V78.9935L338.053 100.928L300.061 78.9935V35.1241L338.053 13.1895Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          className="line"
          clipRule="evenodd"
          d="M337.925 6.9282L297.339 30.3606L337.925 52.27L378.452 30.326L337.925 6.9282ZM294.447 82.2346V35.6177L334.926 57.4692V105.605L294.447 82.2346ZM340.926 105.604L381.404 82.2346V35.5507L340.926 57.4684V105.604ZM387.404 28.5662L337.925 0L288.447 28.5662V85.6987L337.925 114.265L387.404 85.6987V28.5662Z"
          fill="#160D27"
        />
        <path
          d="M434.967 68.8145L472.959 90.7491V134.619L434.967 156.553L396.975 134.619V90.7491L434.967 68.8145Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M434.839 62.5532L394.253 85.9856L434.839 107.895L475.366 85.951L434.839 62.5532ZM391.361 137.86V91.2427L431.84 113.094V161.23L391.361 137.86ZM437.84 161.229L478.318 137.86V91.1757L437.84 113.093V161.229ZM484.318 84.1912L434.839 55.625L385.361 84.1912V141.324L434.839 169.89L484.318 141.324V84.1912Z"
          fill="#160D27"
        />
        <path
          d="M242.283 68.8145L280.275 90.7491V134.619L242.283 156.553L204.291 134.619V90.7491L242.283 68.8145Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M242.158 62.5532L201.572 85.9856L242.158 107.895L282.684 85.951L242.158 62.5532ZM198.68 137.86V91.2427L239.158 113.094V161.23L198.68 137.86ZM245.158 161.229L285.636 137.86V91.1757L245.158 113.093V161.229ZM291.636 84.1912L242.158 55.625L192.68 84.1912V141.324L242.158 169.89L291.636 141.324V84.1912Z"
          fill="#160D27"
        />
        <g filter="url(#filter0_d_1849_92745)">
          <path
            d="M434.967 181.211L472.959 203.146V247.016L434.967 268.95L396.975 247.016V203.146L434.967 181.211Z"
            fill="#D63384"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M434.839 174.95L394.253 198.383L434.839 220.292L475.366 198.348L434.839 174.95ZM391.361 250.257V203.64L431.84 225.491V273.627L391.361 250.257ZM437.84 273.626L478.318 250.257V203.573L437.84 225.491V273.626ZM484.318 196.588L434.839 168.022L385.361 196.588V253.721L434.839 282.287L484.318 253.721V196.588Z"
          fill="#2B0A1A"
        />
        <path
          d="M145.369 125.587L183.361 147.522V191.391L145.369 213.326L107.377 191.391V147.522L145.369 125.587Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M145.244 119.326L104.658 142.758L145.244 164.667L185.77 142.723L145.244 119.326ZM101.766 194.632V148.015L142.244 169.867V218.002L101.766 194.632ZM148.244 218.002L188.722 194.632V147.948L148.244 169.866V218.002ZM194.722 140.964L145.244 112.397L95.7656 140.964V198.096L145.244 226.662L194.722 198.096V140.964Z"
          fill="#160D27"
        />
        <path
          d="M242.283 181.211L280.275 203.146V247.016L242.283 268.95L204.291 247.016V203.146L242.283 181.211Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M242.158 174.95L201.572 198.383L242.158 220.292L282.684 198.348L242.158 174.95ZM198.68 250.257V203.64L239.158 225.491V273.627L198.68 250.257ZM245.158 273.626L285.636 250.257V203.573L245.158 225.491V273.626ZM291.636 196.588L242.158 168.022L192.68 196.588V253.721L242.158 282.287L291.636 253.721V196.588Z"
          fill="#160D27"
        />
        <path
          d="M434.967 293.608L472.959 315.543V359.412L434.967 381.347L396.975 359.412V315.543L434.967 293.608Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M434.839 287.347L394.253 310.78L434.839 332.689L475.366 310.745L434.839 287.347ZM391.361 362.654V316.037L431.84 337.888V386.024L391.361 362.654ZM437.84 386.023L478.318 362.654V315.97L437.84 337.888V386.023ZM484.318 308.985L434.839 280.419L385.361 308.985V366.118L434.839 394.684L484.318 366.118V308.985Z"
          fill="#160D27"
        />
        <path
          d="M49.6038 181.211L87.5958 203.146V247.016L49.6038 268.95L11.6118 247.016V203.146L49.6038 181.211Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M49.4782 174.95L8.89187 198.383L49.4779 220.292L90.0046 198.348L49.4782 174.95ZM6 250.257V203.64L46.4785 225.491V273.627L6 250.257ZM52.4785 273.626L92.9563 250.257V203.573L52.4785 225.491V273.626ZM98.9563 196.588L49.4782 168.022L0 196.588V253.721L49.4782 282.287L98.9563 253.721V196.588Z"
          fill="#160D27"
        />
        <path
          d="M242.283 293.608L280.275 315.543V359.412L242.283 381.347L204.291 359.412V315.543L242.283 293.608Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M242.158 287.347L201.572 310.78L242.158 332.689L282.684 310.745L242.158 287.347ZM198.68 362.654V316.037L239.158 337.888V386.024L198.68 362.654ZM245.158 386.023L285.636 362.654V315.97L245.158 337.888V386.023ZM291.636 308.985L242.158 280.419L192.68 308.985V366.118L242.158 394.684L291.636 366.118V308.985Z"
          fill="#160D27"
        />
        <path
          d="M145.369 237.984L183.361 259.919V303.788L145.369 325.723L107.377 303.788V259.919L145.369 237.984Z"
          fill="#59359A"
          fillOpacity="0.06"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M145.244 231.723L104.657 255.155L145.244 277.065L185.77 255.121L145.244 231.723ZM101.766 307.029V260.412L142.244 282.264V330.399L101.766 307.029ZM148.244 330.399L188.722 307.029V260.345L148.244 282.263V330.399ZM194.722 253.361L145.244 224.794L95.7656 253.361V310.493L145.244 339.059L194.722 310.493V253.361Z"
          fill="#160D27"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M337.925 344.12L297.339 367.553L337.925 389.462L378.452 367.518L337.925 344.12ZM294.447 419.426V372.81L334.926 394.661V442.797L294.447 419.426ZM340.926 442.796L381.404 419.426V372.743L340.926 394.661V442.796ZM387.404 365.758L337.925 337.192L288.447 365.758V422.891L337.925 451.457L387.404 422.891V365.758Z"
          fill="#160D27"
        />
        <g filter="url(#filter1_d_1849_92745)">
          <path
            d="M338.053 237.984L376.045 259.919V303.788L338.053 325.723L300.061 303.788V259.919L338.053 237.984Z"
            fill="#2D8BC7"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M337.925 231.723L297.339 255.155L337.925 277.065L378.452 255.121L337.925 231.723ZM294.447 307.029V260.412L334.926 282.264V330.399L294.447 307.029ZM340.926 330.399L381.404 307.029V260.345L340.926 282.263V330.399ZM387.404 253.361L337.925 224.794L288.447 253.361V310.493L337.925 339.059L387.404 310.493V253.361Z"
          fill="#092D48"
        />
        <g filter="url(#filter2_d_1849_92745)">
          <path
            d="M338.053 125.587L376.045 147.522V191.391L338.053 213.326L300.061 191.391V147.522L338.053 125.587Z"
            fill="#59359A"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className="line"
          d="M337.925 119.326L297.339 142.758L337.925 164.667L378.452 142.723L337.925 119.326ZM294.447 194.632V148.015L334.926 169.867V218.002L294.447 194.632ZM340.926 218.002L381.404 194.632V147.948L340.926 169.866V218.002ZM387.404 140.964L337.925 112.397L288.447 140.964V198.096L337.925 226.662L387.404 198.096V140.964Z"
          fill="#2F1B56"
        />
        <defs>
          <filter
            id="filter0_d_1849_92745"
            x="362.975"
            y="147.211"
            width="143.984"
            height="155.739"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.839216 0 0 0 0 0.2 0 0 0 0 0.517647 0 0 0 0.49 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1849_92745"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1849_92745"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1849_92745"
            x="266.061"
            y="203.984"
            width="143.984"
            height="155.739"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.176471 0 0 0 0 0.541176 0 0 0 0 0.780392 0 0 0 0.49 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1849_92745"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1849_92745"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_1849_92745"
            x="256.061"
            y="85.5869"
            width="163.984"
            height="175.739"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="22" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.37732 0 0 0 0 0.196875 0 0 0 0 0.703125 0 0 0 0.48 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1849_92745"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1849_92745"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
  (p, n) => true
);
