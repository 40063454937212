import type { ParameterLinkEdges } from "@Components";
import {
  PARAMETER_LINK_NODE_ITEM_HEIGHT,
  PARAMETER_LINK_NODE_TITLE_HEIGHT,
  PARAMETER_LINK_NODE_WIDTH,
  type ParameterLinkNodeData,
} from "@Components";
import { BASE_EDGE } from "@Constants";
import { type ParameterLinksEntry } from "@Services";
import { APP_ZOOM_PERCENTAGE } from "@Stores";
import { hashString } from "@Utils";
import type { Edge } from "@xyflow/react";
import { type Node } from "@xyflow/react";

const EMPTY_DATA = {
  nodes: [],
  edges: [],
};

export const parseParameterLinksIntoNodesAndEdges = (
  parameterLinks: Array<ParameterLinksEntry>
) => {
  if (!parameterLinks?.length) {
    return EMPTY_DATA;
  }

  const { producingNodes, consumingNodes, edges } =
    processParameterLinks(parameterLinks);

  const nodes = mergeNodes([...producingNodes, ...consumingNodes]);

  nodes.forEach(node => {
    node.height = node.height =
      (PARAMETER_LINK_NODE_TITLE_HEIGHT +
        node.data.parameters.length * PARAMETER_LINK_NODE_ITEM_HEIGHT) *
      APP_ZOOM_PERCENTAGE;
  });

  return { nodes, edges };
};

const processParameterLinks = (parameterLinks: Array<ParameterLinksEntry>) => {
  const producingNodes: Record<string, Node<ParameterLinkNodeData>> = {};
  const consumingNodes: Record<string, Node<ParameterLinkNodeData>> = {};
  let edges: ParameterLinkEdges = [];
  const producingParamsAdded: Set<string> = new Set<string>();
  const consumingParamsAdded: Set<string> = new Set<string>();

  parameterLinks.forEach((parameterLink, index) => {
    const producingKey = generateKey(
      parameterLink.producingOperationURI,
      parameterLink.producingOperationVerb
    );
    const consumingKey = generateKey(
      parameterLink.consumingOperationURI,
      parameterLink.consumingOperationVerb
    );

    addOrUpdateNode(
      producingNodes,
      producingKey,
      parameterLink,
      false,
      producingParamsAdded
    );
    addOrUpdateNode(
      consumingNodes,
      consumingKey,
      parameterLink,
      true,
      consumingParamsAdded
    );

    producingParamsAdded.add(parameterLink.producingParameterPath);
    consumingParamsAdded.add(parameterLink.consumingParameterPath);

    edges.push(
      createEdge(
        producingKey,
        consumingKey,
        parameterLink,
        index,
        producingNodes,
        consumingNodes
      )
    );
  });

  edges = sanitizeEdges(edges);

  return {
    producingNodes: Object.values(producingNodes),
    consumingNodes: Object.values(consumingNodes),
    edges,
  };
};

const generateKey = (uri: string, verb: string): string => {
  return `${uri}_${verb}`.replace(/\//g, "");
};

const addOrUpdateNode = (
  nodeMap: Record<string, Node<ParameterLinkNodeData>>,
  key: string,
  parameterLink: ParameterLinksEntry,
  isConsumer: boolean,
  paramsAdded: Set<string>
) => {
  const nodeData = createNodeData(
    isConsumer
      ? parameterLink.consumingOperationURI
      : parameterLink.producingOperationURI,
    isConsumer
      ? parameterLink.consumingOperationVerb
      : parameterLink.producingOperationVerb,
    isConsumer,
    isConsumer
      ? parameterLink.consumingParameterPath
      : parameterLink.producingParameterPath
  );

  if (!nodeMap[key]) {
    nodeMap[key] = {
      id: key,
      position: { x: 0, y: 0 },
      type: "default",
      data: nodeData,
      width: PARAMETER_LINK_NODE_WIDTH * APP_ZOOM_PERCENTAGE,
    };
  } else if (!paramsAdded.has(nodeData.parameters[0].name)) {
    nodeMap[key].data.parameters.push(nodeData.parameters[0]);
  }
};

const createNodeData = (
  uri: string,
  verb: string,
  isConsumer: boolean,
  parameterPath: string
): ParameterLinkNodeData => ({
  name: uri,
  path: uri,
  verb,
  consumer: isConsumer,
  selected: false,
  parameters: [
    {
      input: isConsumer,
      output: !isConsumer,
      name: parameterPath,
      type: "token",
    },
  ],
});

const createEdge = (
  sourceKey: string,
  targetKey: string,
  parameterLink: ParameterLinksEntry,
  index: number,
  producingNodes: Record<string, Node<ParameterLinkNodeData>>,
  consumingNodes: Record<string, Node<ParameterLinkNodeData>>
): ParameterLinkEdges[number] => ({
  ...BASE_EDGE,
  id: hashString(
    `${sourceKey}_${parameterLink.producingParameterPath}_${parameterLink.producingOperationVerb}-${targetKey}_${parameterLink.consumingParameterPath}_${parameterLink.consumingParameterPath}`
  ),
  source: sourceKey,
  sourceHandle: `handle-source-${sourceKey}_${parameterLink.producingParameterPath}`,
  target: targetKey,
  targetHandle: `handle-target-${targetKey}_${parameterLink.consumingParameterPath}`,
  type: "deleteUserParameterLink",
  data: {
    sourceNode: producingNodes[sourceKey],
    targetNode: consumingNodes[targetKey],
    parameterLink,
    parameterLinkIndex: index,
  },
});

const mergeNodes = (
  nodes: Node<ParameterLinkNodeData>[]
): Node<ParameterLinkNodeData>[] => {
  const nodesByKey: Record<string, Node<ParameterLinkNodeData>> = {};

  nodes.forEach(node => {
    if (nodesByKey[node.id]) {
      nodesByKey[node.id].data.parameters.push(...node.data.parameters);
    } else {
      nodesByKey[node.id] = node;
    }
  });

  return Object.values(nodesByKey);
};

const sanitizeEdges = (edges: ParameterLinkEdges): ParameterLinkEdges => {
  if (!edges) {
    return [];
  }

  const seenItems: Record<string, Edge> = {};

  edges.forEach(edge => {
    const existingOne = seenItems[edge.id];
    if (!existingOne) {
      seenItems[edge.id] = edge;
    }
  });

  return Object.values(seenItems);
};
