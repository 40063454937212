import { useUpdateCacheFilters } from "@Hooks";
import type { ScanPageProps } from "../Scans.i";
import { useCallback } from "react";
import { useNewScanButtonConfig } from "./useNewScanButtonConfig";

export const useScanPage = (props: ScanPageProps) => {
  const { forProject, refreshScans, response } = props;

  const cacheKey = forProject ? "project" : "organization";
  const updateFilters = useUpdateCacheFilters(`${cacheKey}.scans`);

  const { setScanInProgress, ...newScanButtonConfig } = useNewScanButtonConfig(
    response?.data
  );

  const handleScanCreate = useCallback(() => {
    refreshScans();
    setScanInProgress(true);
  }, [setScanInProgress, refreshScans]);

  return { ...props, updateFilters, handleScanCreate, newScanButtonConfig };
};
