import type { UserListAttemptsResponse } from "@Services";
import { containsLikeQuery } from "@Utils";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { ListedAttempts, delay } from "src/Mock";

export const getScanListedAttempts = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;

  const { params } = req;

  const { organizationId, projectId, scanId } = params as Record<
    string,
    string
  >;

  if (!organizationId || !projectId || !scanId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  const attempts = ListedAttempts.filter(a => {
    let bConditionMet = true;

    if (filters?.attempt_sequence_auth_profile_id) {
      bConditionMet &&=
        a.authProfileName === filters?.attempt_sequence_auth_profile_id;
    }

    if (filters?.attempt_method) {
      bConditionMet &&= a.method === filters?.attempt_method;
    }

    if (filters?.attempt_path) {
      bConditionMet &&= containsLikeQuery(a.path, filters.attempt_path);
    }

    if (filters?.attempt_status_code) {
      bConditionMet &&= a.statusCode === +filters?.attempt_status_code;
    }

    if (filters?.attempt_resolved) {
      bConditionMet &&= `${a.responseResolved}` === filters?.attempt_resolved;
    }

    if (filters?.attempt_scope) {
      bConditionMet &&= a.scope === filters?.attempt_scope;
    }

    if (filters?.attempt_caller) {
      bConditionMet &&= a.caller === filters?.attempt_caller;
    }

    return bConditionMet;
  });

  const offset = (page - 1) * size;
  const limit = offset + size;

  const responseData = attempts.slice(offset, limit);

  const sortBy = sP.get("sort_by");
  const sortMode = sP.get("sort_mode");

  if (sortBy) {
    switch (sortBy) {
      case "attempt_index": {
        responseData.sort((a, b) =>
          sortMode === "asc" ? a.index - b.index : b.index - a.index
        );
        break;
      }

      case "attempt_status_code": {
        responseData.sort((a, b) =>
          sortMode === "asc"
            ? a.statusCode - b.statusCode
            : b.statusCode - a.statusCode
        );
        break;
      }

      case "attempt_started": {
        responseData.sort((a, b) => {
          const modifier = sortMode === "asc" ? 1 : -1;
          return (
            (moment(a.startedAt).isSameOrBefore(moment(b.startedAt)) ? -1 : 1) *
            modifier
          );
        });
        break;
      }
    }
  }

  return res(
    ctx.delay(delay),
    ctx.status(200),
    ctx.json({
      data: responseData.map(i => ({
        ...i,
        scanID: scanId,
      })),
      page,
      size,
      totalItems: attempts.length,
      totalPages: Math.ceil(attempts.length / size),
    } as UserListAttemptsResponse)
  );
};
