import { equals } from "@Services";
import Markdown, { type MarkdownToJSX } from "markdown-to-jsx";
import { memo, type FC } from "react";

interface MarkdownTextProps {
  children: string;
  options?: MarkdownToJSX.Options;
}

export const MarkdownText: FC<MarkdownTextProps> = memo(
  ({ children, options }) => {
    return (
      <div className="custom-markdown">
        <Markdown options={options}>{children}</Markdown>
      </div>
    );
  },
  equals
);
