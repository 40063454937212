import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQrCode, useToast } from "@Hooks";
import { verifyOtp } from "@Services";
import { AuthContext } from "@Contexts";
import { type ButtonProps } from "@Components";

export const useSetup = (
  showOffcanvas: boolean,
  setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const toast = useToast();
  const { user, setUser } = useContext(AuthContext);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    loading,
    qrCodeImg,
    fetchData: fetchQRCode,
  } = useQrCode({
    withAuth: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (error) {
      toast({
        message: error,
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onCanvasClose = useCallback(() => {
    setShowOffcanvas(false);
    setOtp("");
  }, [setShowOffcanvas]);

  const onClickHandle = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      await verifyOtp({
        otp,
        setError,
        setLoading: setLoadingSubmit,
        successCallback: () => {
          toast({
            message: "successes.2fa-enabled",
            type: "success",
          });

          setShowOffcanvas(false);
          if (user) {
            setUser({
              ...user,
              twoFactorAuthenticationEnabled: true,
            });
          }
        },
        enable2fa: true,
        withAuth: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, otp]
  );

  const buttons = useMemo(() => {
    return [
      {
        color: "primary",
        children: "common.update",
        disabled: otp.length !== 6,
        loading: loadingSubmit,
        iconClass: "bi bi-floppy",
        onClick: onClickHandle,
        data: { cy: "submit-btn" },
      },
    ] as ButtonProps[];
  }, [otp.length, loadingSubmit, onClickHandle]);

  React.useEffect(() => {
    if (showOffcanvas && !qrCodeImg && loading) {
      // load data on click
      fetchQRCode();
    }
  }, [showOffcanvas, qrCodeImg, loading, fetchQRCode]);

  return {
    loading,
    qrCodeImg,
    setOtp,
    buttons,
    onCanvasClose,
  };
};
