import type { HeaderItems } from "@Interfaces";
import { API, projectMaxEndDate } from "@Services";

export const onChangeUser = async (
  e: React.ChangeEvent<HTMLInputElement>,
  organizationId: string | undefined,
  userId: string,
  role: string,
  userActive: boolean,
  refreshUsers: () => Promise<void>
) => {
  e.stopPropagation();
  if (organizationId) {
    await API.role().organizationAdminUpdateRoleValue(userId, organizationId, {
      role,
      active: !userActive,
      expiresAt: projectMaxEndDate,
    });
    refreshUsers();
  }
};

export const getHeaders = (): HeaderItems => [
  { text: "common.name", sortByKey: "user_surname_name" },
  { text: "common.email", sortByKey: "user_email" },
  { text: "common.role", sortByKey: "user_role_value" },
  { text: "common.status", sortByKey: "user_role_active" },
  { text: "common.created", sortByKey: "user_created_at" },
  { text: "common.expires-at" },
];
