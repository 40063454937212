/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrganizationAdminCreateSpaceLinkRequest } from '../model';
// @ts-ignore
import { OrganizationAdminCreateSpaceLinkResponse } from '../model';
// @ts-ignore
import { OrganizationAdminUpdateSpaceLinkRequest } from '../model';
// @ts-ignore
import { UserGetSpaceLinkResponse } from '../model';
// @ts-ignore
import { UserListSpaceLinksResponse } from '../model';
/**
 * SpaceLinkApi - axios parameter creator
 * @export
 */
export const SpaceLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new space link in the organization with given id
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminCreateSpaceLinkRequest} requestBody OrganizationAdminCreateSpaceLinkRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkCreate: async (organizationId: string, requestBody: OrganizationAdminCreateSpaceLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('spaceLinkCreate', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('spaceLinkCreate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/spacelinks`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkDelete: async (organizationId: string, spaceLinkId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('spaceLinkDelete', 'organizationId', organizationId)
            // verify required parameter 'spaceLinkId' is not null or undefined
            assertParamExists('spaceLinkDelete', 'spaceLinkId', spaceLinkId)
            const localVarPath = `/v1/organizations/{organization_id}/spacelinks/{space_link_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"space_link_id"}}`, encodeURIComponent(String(spaceLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkGet: async (organizationId: string, spaceLinkId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('spaceLinkGet', 'organizationId', organizationId)
            // verify required parameter 'spaceLinkId' is not null or undefined
            assertParamExists('spaceLinkGet', 'spaceLinkId', spaceLinkId)
            const localVarPath = `/v1/organizations/{organization_id}/spacelinks/{space_link_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"space_link_id"}}`, encodeURIComponent(String(spaceLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the space links in the organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'space_link_created_at' | 'space_link_name' | 'space_link_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkList: async (organizationId: string, page?: number, size?: number, sortBy?: 'space_link_created_at' | 'space_link_name' | 'space_link_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('spaceLinkList', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/spacelinks`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {OrganizationAdminUpdateSpaceLinkRequest} requestBody OrganizationAdminUpdateSpaceLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkUpdate: async (organizationId: string, spaceLinkId: string, requestBody: OrganizationAdminUpdateSpaceLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('spaceLinkUpdate', 'organizationId', organizationId)
            // verify required parameter 'spaceLinkId' is not null or undefined
            assertParamExists('spaceLinkUpdate', 'spaceLinkId', spaceLinkId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('spaceLinkUpdate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/spacelinks/{space_link_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"space_link_id"}}`, encodeURIComponent(String(spaceLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpaceLinkApi - functional programming interface
 * @export
 */
export const SpaceLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpaceLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new space link in the organization with given id
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminCreateSpaceLinkRequest} requestBody OrganizationAdminCreateSpaceLinkRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceLinkCreate(organizationId: string, requestBody: OrganizationAdminCreateSpaceLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminCreateSpaceLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spaceLinkCreate(organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceLinkDelete(organizationId: string, spaceLinkId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spaceLinkDelete(organizationId, spaceLinkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceLinkGet(organizationId: string, spaceLinkId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetSpaceLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spaceLinkGet(organizationId, spaceLinkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the space links in the organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'space_link_created_at' | 'space_link_name' | 'space_link_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceLinkList(organizationId: string, page?: number, size?: number, sortBy?: 'space_link_created_at' | 'space_link_name' | 'space_link_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListSpaceLinksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spaceLinkList(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {OrganizationAdminUpdateSpaceLinkRequest} requestBody OrganizationAdminUpdateSpaceLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceLinkUpdate(organizationId: string, spaceLinkId: string, requestBody: OrganizationAdminUpdateSpaceLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spaceLinkUpdate(organizationId, spaceLinkId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpaceLinkApi - factory interface
 * @export
 */
export const SpaceLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpaceLinkApiFp(configuration)
    return {
        /**
         * Creates a new space link in the organization with given id
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminCreateSpaceLinkRequest} requestBody OrganizationAdminCreateSpaceLinkRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkCreate(organizationId: string, requestBody: OrganizationAdminCreateSpaceLinkRequest, options?: any): AxiosPromise<OrganizationAdminCreateSpaceLinkResponse> {
            return localVarFp.spaceLinkCreate(organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkDelete(organizationId: string, spaceLinkId: string, options?: any): AxiosPromise<void> {
            return localVarFp.spaceLinkDelete(organizationId, spaceLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkGet(organizationId: string, spaceLinkId: string, options?: any): AxiosPromise<UserGetSpaceLinkResponse> {
            return localVarFp.spaceLinkGet(organizationId, spaceLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the space links in the organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'space_link_created_at' | 'space_link_name' | 'space_link_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkList(organizationId: string, page?: number, size?: number, sortBy?: 'space_link_created_at' | 'space_link_name' | 'space_link_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListSpaceLinksResponse> {
            return localVarFp.spaceLinkList(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the space link with the given id
         * @param {string} organizationId Organization ID
         * @param {string} spaceLinkId SpaceLink ID
         * @param {OrganizationAdminUpdateSpaceLinkRequest} requestBody OrganizationAdminUpdateSpaceLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceLinkUpdate(organizationId: string, spaceLinkId: string, requestBody: OrganizationAdminUpdateSpaceLinkRequest, options?: any): AxiosPromise<void> {
            return localVarFp.spaceLinkUpdate(organizationId, spaceLinkId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpaceLinkApi - interface
 * @export
 * @interface SpaceLinkApi
 */
export interface SpaceLinkApiInterface {
    /**
     * Creates a new space link in the organization with given id
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminCreateSpaceLinkRequest} requestBody OrganizationAdminCreateSpaceLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApiInterface
     */
    spaceLinkCreate(organizationId: string, requestBody: OrganizationAdminCreateSpaceLinkRequest, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminCreateSpaceLinkResponse>;

    /**
     * Deletes the space link with the given id
     * @param {string} organizationId Organization ID
     * @param {string} spaceLinkId SpaceLink ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApiInterface
     */
    spaceLinkDelete(organizationId: string, spaceLinkId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the space link with the given id
     * @param {string} organizationId Organization ID
     * @param {string} spaceLinkId SpaceLink ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApiInterface
     */
    spaceLinkGet(organizationId: string, spaceLinkId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetSpaceLinkResponse>;

    /**
     * Returns the space links in the organization
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'space_link_created_at' | 'space_link_name' | 'space_link_expires_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApiInterface
     */
    spaceLinkList(organizationId: string, page?: number, size?: number, sortBy?: 'space_link_created_at' | 'space_link_name' | 'space_link_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListSpaceLinksResponse>;

    /**
     * Updates the space link with the given id
     * @param {string} organizationId Organization ID
     * @param {string} spaceLinkId SpaceLink ID
     * @param {OrganizationAdminUpdateSpaceLinkRequest} requestBody OrganizationAdminUpdateSpaceLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApiInterface
     */
    spaceLinkUpdate(organizationId: string, spaceLinkId: string, requestBody: OrganizationAdminUpdateSpaceLinkRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * SpaceLinkApi - object-oriented interface
 * @export
 * @class SpaceLinkApi
 * @extends {BaseAPI}
 */
export class SpaceLinkApi extends BaseAPI implements SpaceLinkApiInterface {
    /**
     * Creates a new space link in the organization with given id
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminCreateSpaceLinkRequest} requestBody OrganizationAdminCreateSpaceLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApi
     */
    public spaceLinkCreate(organizationId: string, requestBody: OrganizationAdminCreateSpaceLinkRequest, options?: AxiosRequestConfig) {
        return SpaceLinkApiFp(this.configuration).spaceLinkCreate(organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the space link with the given id
     * @param {string} organizationId Organization ID
     * @param {string} spaceLinkId SpaceLink ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApi
     */
    public spaceLinkDelete(organizationId: string, spaceLinkId: string, options?: AxiosRequestConfig) {
        return SpaceLinkApiFp(this.configuration).spaceLinkDelete(organizationId, spaceLinkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the space link with the given id
     * @param {string} organizationId Organization ID
     * @param {string} spaceLinkId SpaceLink ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApi
     */
    public spaceLinkGet(organizationId: string, spaceLinkId: string, options?: AxiosRequestConfig) {
        return SpaceLinkApiFp(this.configuration).spaceLinkGet(organizationId, spaceLinkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the space links in the organization
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'space_link_created_at' | 'space_link_name' | 'space_link_expires_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApi
     */
    public spaceLinkList(organizationId: string, page?: number, size?: number, sortBy?: 'space_link_created_at' | 'space_link_name' | 'space_link_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'space_link_name' | 'space_link_status' | 'space_link_connected' | 'space_link_expired'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return SpaceLinkApiFp(this.configuration).spaceLinkList(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the space link with the given id
     * @param {string} organizationId Organization ID
     * @param {string} spaceLinkId SpaceLink ID
     * @param {OrganizationAdminUpdateSpaceLinkRequest} requestBody OrganizationAdminUpdateSpaceLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceLinkApi
     */
    public spaceLinkUpdate(organizationId: string, spaceLinkId: string, requestBody: OrganizationAdminUpdateSpaceLinkRequest, options?: AxiosRequestConfig) {
        return SpaceLinkApiFp(this.configuration).spaceLinkUpdate(organizationId, spaceLinkId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
