import { Button } from "@Components";
import { PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH } from "@Constants";
import { useOrganizationId, useUrlParams } from "@Hooks";
import { generateUrl } from "@Utils";
import { t } from "i18next";
import { Link } from "react-router-dom";

export const APIDefinitionDetailsNotFound = () => {
  const { organizationId, projectId, apiDefinitionId } = useUrlParams();
  const organizationIdFromContext = useOrganizationId();

  return (
    <div className="d-flex w-100 align-items-center h-100">
      <div className="text-center w-100">
        <h1>{t("inventory.not-found")}</h1>
        <Link
          to={generateUrl(PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH, [
            organizationId || organizationIdFromContext || "",
            projectId ?? "",
            apiDefinitionId ?? "",
          ])}
        >
          <Button
            color="primary"
            children={"common.back-list"}
            iconClass="bi bi-arrow-left-square-fill"
          />
        </Link>
      </div>
    </div>
  );
};
