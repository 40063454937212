import { useManageApiResponse } from "@Hooks";
import { API } from "@Services";
import { useCallback, useEffect } from "react";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const useGetData = () => {
  const {
    organizationId,
    projectId,
    scanId,
    setLoading,
    advancedSettings,
    setAdvancedSettings,
  } = useSettingsStore();
  const manageResponse = useManageApiResponse(setLoading);
  const fetchAdvancedSettings = useCallback(() => {
    if (!advancedSettings && organizationId && projectId && scanId) {
      (async () => {
        const response = await manageResponse({
          promise: API.scanSettings().getScanAdvancedSettings(
            organizationId,
            projectId,
            scanId
          ),
          errorMessage: "retrieving-data",
        }).then(r => r.data);

        if (response && response.advancedSettings) {
          setAdvancedSettings(response.advancedSettings);
        }
      })();
    }
  }, [
    advancedSettings,
    manageResponse,
    organizationId,
    projectId,
    scanId,
    setAdvancedSettings,
  ]);

  useEffect(() => {
    fetchAdvancedSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
