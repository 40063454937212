import {
  Details,
  InfoBadge,
  LongText,
  LongTextOffcanvas,
  MethodBadge,
  NotificationBadge,
  Section,
} from "@Components";
import type { Method as MethodI } from "@Interfaces";
import { getInputIconClass } from "@Services";
import { t } from "i18next";
import { memo, useState } from "react";
import { useAPIDefinitionDetailsStore } from "../Store/APIDefinitionDetailsStore";

export const APIDefinitionDetailsSectionDetails = memo(() => {
  const { details } = useAPIDefinitionDetailsStore();
  const [showLongTextModal, setShowLongTextModal] = useState<false | string>(
    false
  );

  if (!details) {
    return null;
  }

  return (
    <>
      <Section title={t("common.inventory-item-detail")}>
        <Details
          details={{
            [t("common.endpoint")]: (
              <div className="d-flex align-items-center">
                <MethodBadge type={details.verb as MethodI} />
                <span className="ms-2">{details.path}</span>
                {details.createdFrom === "FROM_EXTENSION" && (
                  <div className="ms-auto">
                    <NotificationBadge display="badge" type="shadow" />
                  </div>
                )}
              </div>
            ),
          }}
        />

        <Details
          details={{
            [t("common.operation")]: details.name || "-",
            [t("common.tags")]: details.tags.length ? (
              <div className="badge-container">
                {details.tags.map(t => (
                  <InfoBadge className="ms-2 mb-2" key={t}>
                    {t}
                  </InfoBadge>
                ))}
              </div>
            ) : (
              "-"
            ),
          }}
          className="mt-3"
        />

        <>
          {details.description && (
            <Details
              details={{
                [t("common.description")]: (
                  <LongText
                    text={details.description}
                    setShowLongTextModal={setShowLongTextModal}
                  />
                ),
              }}
              className="mt-3"
            />
          )}
        </>

        <Details
          details={{
            [t("common.category")]: details.category,
            [t("common.auth")]: t(
              details.authenticationRequired
                ? "common.enabled"
                : "common.not-enabled"
            ),
          }}
          className="mt-3"
        />

        <Details
          details={{
            [t("common.inputs")]: details.dataFormatInputs.length ? (
              <div className="badge-container">
                {details.dataFormatInputs.map(p => (
                  <InfoBadge key={p} className="ms-2 mb-2">
                    <>
                      <i className={getInputIconClass(p) + " me-2"} />
                      {t([`compliance.${p}`, p])}
                    </>
                  </InfoBadge>
                ))}
              </div>
            ) : (
              "-"
            ),
            [t("common.outputs")]: details.dataFormatOutputs.length ? (
              <div className="badge-container">
                {details.dataFormatOutputs.map(p => (
                  <InfoBadge key={p} className="ms-2 mb-2">
                    <>
                      <i className={getInputIconClass(p) + " me-2"} />
                      {t([`compliance.${p}`, p])}
                    </>
                  </InfoBadge>
                ))}
              </div>
            ) : (
              "-"
            ),
          }}
          className="mt-3"
        />
      </Section>
      <LongTextOffcanvas
        showLongTextModal={showLongTextModal}
        setShowLongTextModal={setShowLongTextModal}
        text={details.description}
      />
    </>
  );
});
