import { useCallback, useState } from "react";
import type { SidebarNewUserProps } from "./SidebarNewUser.i";
import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API } from "@Services";
import { getFormInitialState } from "./Functions/getFormInitialState";

export const useSidebarNewUser = ({
  isOpen,
  onUserInvited,
  toggle,
}: SidebarNewUserProps) => {
  const [user, setUser] = useState(getFormInitialState);
  const [isCanvasLoading, setIsCanvasLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const manageResponse = useManageApiResponse(setIsCanvasLoading);

  const { organizationId } = useUrlParams();

  const handleOnClose = useCallback(() => {
    toggle(false);
    setUser(getFormInitialState);
  }, [toggle]);

  const handleSuccessfullSubmit = useCallback(() => {
    onUserInvited();
    handleOnClose();
  }, [handleOnClose, onUserInvited]);

  const handleSubmit = useCallback(() => {
    if (!organizationId) throw new Error("missing-organization-id");
    manageResponse({
      promise: API.user().organizationAdminInvite(organizationId, user),
      onSuccess: handleSuccessfullSubmit,
      successMessage: "invite-user",
      errorMessage: "invite-user",
    });
  }, [handleSuccessfullSubmit, manageResponse, organizationId, user]);

  return {
    user,
    setUser,
    isOpen,
    handleOnClose,
    handleSubmit,
    isCanvasLoading,
    isFormValid,
    setIsFormValid,
  };
};
