import { useManageApiResponse } from "@Hooks";
import type { OrganizationId } from "@Interfaces";
import type {
  OrganizationAdminUpdateSpaceLinkRequest,
  SpaceLink,
} from "@Services";
import { API } from "@Services";
import { isStatusCodeSuccess } from "@Utils";
import { useCallback, useState } from "react";

type Configuration = {
  onSuccess?: VoidFunction;
  onError?: VoidFunction;
  successMessage?: string;
  errorMessage?: string;
};

export const useOrganizationSpaceLinkUpdate = ({
  errorMessage,
  onError,
  onSuccess,
  successMessage,
}: Configuration = {}) => {
  const [loading, setLoading] = useState(false);
  const manage = useManageApiResponse(setLoading);

  const updateSpaceLink = useCallback(
    async (
      organizationId: OrganizationId,
      spaceLinkId: SpaceLink["id"],
      requestBody: OrganizationAdminUpdateSpaceLinkRequest
    ) => {
      const { status } = await manage({
        onSuccess,
        onError,
        successMessage,
        errorMessage,
        promise: API.spaceLink().spaceLinkUpdate(
          organizationId,
          spaceLinkId,
          requestBody
        ),
      });
      return isStatusCodeSuccess(status);
    },
    [errorMessage, manage, onError, onSuccess, successMessage]
  );

  return { updateSpaceLink, loading };
};
