import type { FC } from "react";
import { LoginTemplate, MainTemplate } from "./Components";
import type { TemplateComponentProps } from "./Template.i";

export const Template: FC<TemplateComponentProps> = props => {
  switch (props?.type) {
    case "main":
      return <MainTemplate {...props} />;
    case "login":
      return <LoginTemplate {...props} />;
  }
};
