import type { Dispatch, FC } from "react";
import { FormInput } from "../../FormInput";
import type { SimpleChangeEvent } from "../UserForm.i";

type NameSurnameProps = {
  surname: string;
  setUser: Dispatch<SimpleChangeEvent>;
  surnameValid: boolean;
  disabled?: boolean;
};

export const SurnameField: FC<NameSurnameProps> = ({
  surname,
  surnameValid,
  disabled,
  setUser,
}) => (
  <FormInput
    label="common.surname"
    id="user-form-input-surname"
    value={surname}
    name="surname"
    onChange={setUser}
    type="text"
    valid={surnameValid}
    invalid={!surnameValid}
    disabled={disabled}
    autoComplete="off"
    autoTrim
  />
);
