import { useCallback, useState } from "react";
import type { ButtonProps } from "@Components";
import { getSaveButtonProps } from "../../../Functions";
import type { CanvasCommonProps, RenderedCanvasProps } from "../Components.i";
import useProjectEditFormCanvas from "./useProjectEditFormCanvas";
import { projectDataToUpdateProjectData } from "@Services";

const useProjectEditCanvas = ({
  isOpen,
  setClosed,
  project,
  setProject: update,
}: CanvasCommonProps) => {
  const [canvasLoading, setCanvasLoading] = useState(false);
  const { data, status, utils } = useProjectEditFormCanvas(project);

  const closeCanvas = useCallback(() => {
    setClosed();
    utils.reset();
  }, [setClosed, utils]);

  const saveButton: ButtonProps = getSaveButtonProps({
    loading: canvasLoading,
    disabled: !status.dirty || !status.valid,
    onClick: () => {
      setCanvasLoading(true);
      update({
        ...projectDataToUpdateProjectData(project),
        ...utils.getWorkingFields(),
      })
        .then(ok => {
          ok && closeCanvas();
        })
        .finally(() => {
          setCanvasLoading(false);
        });
    },
  });

  const canvasProps: RenderedCanvasProps = {
    isOpen,
    saveButton,
    setClosed: closeCanvas,
    loading: canvasLoading,
  };

  return {
    canvasProps,
    form: data,
  };
};

export default useProjectEditCanvas;
