import { type Nullable } from "@Interfaces";
import { type ListedIssue } from "@Services";
import { createStore } from "src/Hooks/store/useStore";

export type IssuesStoreProperties = {
  issues: Nullable<Array<ListedIssue>>;
  totalIssues: number;
  loading: boolean;
};

export type IssueState = IssuesStoreProperties & {
  setIssues: (
    issues: Nullable<Array<ListedIssue>>,
    totalIssues: number
  ) => void;
  setLoading: (loading: boolean) => void;
  reset: VoidFunction;
};

const initialState: IssuesStoreProperties = {
  issues: null,
  totalIssues: 0,
  loading: false,
};

export const useIssuesStore = createStore<IssueState>((get, set) => ({
  ...initialState,
  setIssues: (issues, totalIssues) =>
    set(state => ({ ...state, issues, totalIssues })),
  setLoading: loading => set(state => ({ ...state, loading })),
  reset: () => set(state => ({ ...state, ...initialState })),
}));
