import { useIsAdmin, useOrganization, usePreference } from "@Hooks";
import { API } from "@Services";
import { useNavigate } from "react-router-dom";

const useOrganizationGeneral = () => {
  const isAdmin = useIsAdmin();
  const organization = useOrganization();
  const navigate = useNavigate();
  const [prefOrganizationId, setPrefOrganizationId] =
    usePreference("organization-id");

  return {
    isAdmin,
    organization,
    dangerZone: organization
      ? {
          onSuccess: () => {
            if (prefOrganizationId) setPrefOrganizationId(null);
            navigate("/login");
          },
          promise: () => {
            return API.organization().organizationAdminDeleteOrganization(
              organization?.id ?? ""
            );
          },
        }
      : undefined,
  };
};

export default useOrganizationGeneral;
