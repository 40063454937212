import { Button, GridContainer, Section } from "@Components";
import { useState, type FC } from "react";
import { ApiKeyData } from "../ApiKeyData/ApiKeyData";
import { getApiKeyExtraData } from "@Services";
import type { ApiKeySectionShowcaseProps } from "./SectionDetails.i";
import { EditApiKey } from "../EditApiKey/EditApiKey";
import { DeleteApiKey } from "../DeleteApiKey/DeleteApiKey";

export const SectionDetails: FC<ApiKeySectionShowcaseProps> = ({
  updateApiKey,
  apiKey,
}) => {
  const [canvasOpen, setCanvasOpen] = useState(false);
  const { isExpired, remainingDays, status } = getApiKeyExtraData(apiKey);
  return (
    <GridContainer className="section-grid-container">
      <Section title="common.api-key">
        <GridContainer>
          <ApiKeyData
            apiKey={apiKey}
            isExpired={isExpired}
            remainingDays={remainingDays}
            status={status}
          />
          <>
            {!isExpired && (
              <>
                <Button
                  color="primary"
                  children="common.edit"
                  data-cy="edit-button"
                  onClick={() => setCanvasOpen(true)}
                  iconClass="bi bi-pencil-fill"
                />
                <EditApiKey
                  apiKey={apiKey}
                  isOpen={canvasOpen}
                  toggle={setCanvasOpen}
                  onSubmit={updateApiKey}
                />
              </>
            )}
          </>
        </GridContainer>
      </Section>
      <DeleteApiKey apiKey={apiKey} />
    </GridContainer>
  );
};
