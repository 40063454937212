import { Section, Switch } from "@Components";
import { AuthContext } from "@Contexts";
import React, { useContext } from "react";

export const FeedSettingsCard = () => {
  const { settings, setSettings } = useContext(AuthContext);

  return settings ? (
    <Section title="profile.feed-settings.card-title">
      <Switch
        id="notificationsSwitch"
        title="profile.feed-settings.notifications"
        checked={settings.notification}
        onChange={() =>
          settings &&
          setSettings(
            {
              ...(settings || {}),
              notification: !settings.notification,
            },
            true
          )
        }
      />
    </Section>
  ) : (
    <></>
  );
};
