import { t } from "i18next";
import type { SectionProps } from "./Section.i";
import { Card } from "reactstrap";
import "./style.scss";
import type { FC } from "react";
import { joinClasses } from "@Utils";

export const Section: FC<SectionProps> = ({
  title,
  children,
  className: propsClassName,
  removeLowerRadius = false,
  removeTopLeftRadius = false,
  id,
  ...otherProps
}) => {
  const className = joinClasses(
    "section-container",
    propsClassName,
    removeLowerRadius && "border-b-radius-0",
    removeTopLeftRadius && "border-tl-radius-0"
  );
  return (
    <section className={className} id={id} {...otherProps}>
      {title && <h2 className="section-title">{t(title)}</h2>}
      <Card className="section-content flex-grow-1 d-flex">{children}</Card>
    </section>
  );
};
