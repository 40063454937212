import { ThemeContext } from "@Contexts";
import { useContext } from "react";
import type { TooltipProps } from "reactstrap";
import { Tooltip as ReactstrapTooltip } from "reactstrap";

export const Tooltip = ({
  popperClassName: propsPopperClassName = "",
  ...props
}: TooltipProps) => {
  const { theme } = useContext(ThemeContext);
  const popperClassName = `${propsPopperClassName} ${theme}`.trim();

  return <ReactstrapTooltip {...props} popperClassName={popperClassName} />;
};
