import type { FC } from "react";
import { FormInput } from "../../FormInput";
import type { MTLSCertificateFormProps } from "../MTLSCertificatesForm.i";
import { useMTLSCertificateForm } from "./useMTLSCertificateForm";
import { GridContainer } from "src/Components/GridContainer";
import { FormMessage } from "../../FormMessage";

export const MTLSCertificatesForm: FC<MTLSCertificateFormProps> = props => {
  const {
    mtlsCertificateEntry,
    setMTLSCertificateEntry,
    disabled,
    validities,
    duplicationError,
  } = useMTLSCertificateForm(props);
  return (
    <GridContainer columns={1} rows={4} id="mtls-certificate-form">
      <div>
        <FormInput
          type="text"
          id="mtls-certificates-input-name"
          data-cy="mtls-certificates-input-name"
          label="common.name"
          name="name"
          valid={validities.name}
          invalid={!validities.name}
          value={mtlsCertificateEntry.name}
          onChange={setMTLSCertificateEntry}
          disabled={disabled}
          bsSize="sm"
          required
          autoTrim
        />
        {duplicationError && (
          <FormMessage
            color={duplicationError.severity}
            message={duplicationError.message}
          />
        )}
      </div>
      <FormInput
        type="textarea"
        rows={4}
        id="mtls-certificates-input-client-key"
        data-cy="mtls-certificates-input-client-key"
        label="common.private-key"
        name="clientKey"
        valid={validities.clientKey}
        invalid={!validities.clientKey}
        value={mtlsCertificateEntry.clientKey}
        onChange={setMTLSCertificateEntry}
        disabled={disabled}
        bsSize="sm"
        required
        autoTrim
      />
      <FormInput
        type="textarea"
        rows={4}
        id="mtls-certificates-input-client-cert"
        data-cy="mtls-certificates-input-client-cert"
        label="common.client-certificate"
        name="clientCert"
        valid={validities.clientCert}
        invalid={!validities.clientCert}
        value={mtlsCertificateEntry.clientCert}
        onChange={setMTLSCertificateEntry}
        disabled={disabled}
        bsSize="sm"
        required
        autoTrim
      />
      <FormInput
        type="textarea"
        rows={4}
        id="mtls-certificates-input-client-cacert"
        data-cy="mtls-certificates-input-client-cacert"
        label="common.certificate-authority"
        name="caCert"
        valid={!!mtlsCertificateEntry.caCert}
        value={mtlsCertificateEntry.caCert}
        onChange={setMTLSCertificateEntry}
        disabled={disabled}
        bsSize="sm"
        required
        autoTrim
      />
    </GridContainer>
  );
};
