import type { Nullable } from "@Interfaces";
import type { Injectable } from "@Services";
import { t } from "i18next";
import { useMemo } from "react";

type ErrorType = {
  message: string;
  severity: "danger" | "warning";
};

const detect = (i: Injectable, j: Injectable) => {
  return i.name === j.name && i.location === j.location;
};

export const useInjectableNameErrorDetector = (
  entry: Injectable,
  localInjectables?: Injectable[],
  globalInjectables?: Injectable[]
): Nullable<ErrorType> => {
  const error = useMemo<ErrorType | null>(() => {
    return (
      findInvalidCharacters(entry) ||
      findDuplicationError(entry, localInjectables, globalInjectables)
    );
  }, [entry, globalInjectables, localInjectables]);

  return error;
};

function findInvalidCharacters({
  name,
  location,
}: Injectable): Nullable<ErrorType> {
  if (!name) return null;
  let invalid = true;
  switch (location) {
    case "cookie":
      invalid = /[^!#$%&'()*+\-.^_`|~0-9A-Za-z]/.test(name);
      break;
    case "header":
      invalid = /[^A-Za-z0-9!#$%&'*+-.^_`|~]/.test(name);
      break;
  }
  return invalid
    ? { message: t("validation.invalid-characters"), severity: "danger" }
    : null;
}

function findDuplicationError(
  entry: Injectable,
  localInjectables?: Injectable[],
  globalInjectables?: Injectable[]
): Nullable<ErrorType> {
  if (localInjectables?.find(i => detect(i, entry))) {
    return {
      message: t("validation.x-already-in-use", { x: t("common.name") }),
      severity: "danger",
    };
  }

  if (globalInjectables?.find(i => detect(i, entry))) {
    return {
      message: t("common.override-information-injectable-parameters"),
      severity: "warning",
    };
  }

  return null;
}
