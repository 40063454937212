import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./Locales/en.json";

const resources = {
  en: {
    translation: enTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Imposta la lingua predefinita
  fallbackLng: "en", // Utilizza l'inglese come fallback se una traduzione specifica non è disponibile
  interpolation: {
    escapeValue: false, // Non effettua l'escape automatico dei caratteri HTML
  },
});

export default i18n;
