import { Offcanvas, SpaceLinkReadonlyTable } from "@Components";
import { useSpaceLinkEditCanvas } from "./Hooks";
import type { CanvasCommonProps } from "./SpaceLinkEditCanvas.i";

export const SpaceLinkEditCanvas = (props: CanvasCommonProps) => {
  const { canvasProps, selectedSpaceLinkID, handleSpaceLinkSelect } =
    useSpaceLinkEditCanvas(props);

  return (
    <Offcanvas
      id="space-link-edit-canvas"
      isOpen={canvasProps.isOpen}
      className={canvasProps.loading ? "loading" : undefined}
      toggle={canvasProps.setClosed}
      buttons={[canvasProps.saveButton, canvasProps.disconnectButton].filter(
        b => !!b
      )}
      title="project.edit-canvas-titles.space-link"
      children={
        <SpaceLinkReadonlyTable
          projectSpaceLinkID={props.project.spaceLinkID}
          selectedSpaceLinkID={selectedSpaceLinkID ?? ""}
          handleSpaceLinkSelect={handleSpaceLinkSelect}
        />
      }
      size="md"
    />
  );
};
