import { FormInput, MethodsComboBox, Offcanvas, Switch } from "@Components";
import { useIssueExclusionCanvas } from "./Hooks";

import type { Method } from "@Interfaces";
import { pureComponent } from "@Utils";
import { t } from "i18next";
import { memo } from "react";
import { FormGroup } from "reactstrap";

export const IssueExclusionEditCanvas = memo(() => {
  const {
    issueExclusionForm,
    open,
    close,
    setIssueExclusionForm,
    loading,
    saveButton,
  } = useIssueExclusionCanvas();

  return (
    <Offcanvas
      id="issue-exclusion-edit-canvas"
      isOpen={open}
      toggle={close}
      title="common.issue-exclusion"
      buttons={saveButton}
      className={loading ? "loading" : undefined}
      children={
        issueExclusionForm ? (
          <div id="issue-exclusion-form">
            <FormGroup>
              <FormInput
                label="common.extension-name"
                type="text"
                id="issue-exclusion-extensionName"
                value={issueExclusionForm.extensionName}
                bsSize="sm"
                readOnly
              />
            </FormGroup>
            <hr></hr>
            <FormGroup>
              <FormInput
                autoTrim
                label="common.path"
                type="text"
                id="issue-exclusion-name"
                value={issueExclusionForm.path}
                bsSize="sm"
                onChange={e =>
                  setIssueExclusionForm({
                    ...issueExclusionForm,
                    path: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <span className="form-label">{t("common.methods")}</span>
              <MethodsComboBox
                methods={["GET", "POST", "PATCH", "PUT", "DELETE"]}
                activeMethods={issueExclusionForm.methods as Method[]}
                setActiveMethods={methods =>
                  setIssueExclusionForm({
                    ...issueExclusionForm,
                    methods: methods as Method[],
                  })
                }
                addAll
                minSelected={1}
              />
            </FormGroup>
            <FormGroup noMargin>
              <Switch
                title="common.false-positive"
                id="issue-exclusion-false-positive"
                checked={issueExclusionForm.falsePositive}
                showLabel={false}
                onChange={() =>
                  setIssueExclusionForm({
                    ...issueExclusionForm,
                    falsePositive: !issueExclusionForm.falsePositive,
                  })
                }
              />
            </FormGroup>
          </div>
        ) : (
          <></>
        )
      }
    />
  );
}, pureComponent);
