import type { NewProjectData } from "@Interfaces";
import type {
  AuthenticationSettings,
  Injectable,
  MTLSCertificate,
  Project,
} from "@Services";
/**
 * Return a deep cloned copy of {source}, adding the provided (and mandatory) authentication-settings
 * and (if provided) injectable and/or mTLSCertificates
 * @param {T extends ProjectData | NewProjectData} source
 * @param {AuthenticationSettings} authenticationSettings
 * @param {Array<Injectable>} injectables
 * @param {Array<MTLSCertificate>} mTLSCertificates
 * @returns {T}
 */
export const handleAuthenticationAddedToProject = <
  T extends Project | NewProjectData,
>(
  source: T,
  authenticationSettings: AuthenticationSettings,
  injectables?: Injectable[],
  mTLSCertificates?: MTLSCertificate[]
): T => {
  const result = structuredClone(source);
  result.authenticationSettings ??= [];
  result.injectables ??= [];
  result.mTLSCertificates ??= [];
  const associatedAuthSetting = authenticationSettings.name;
  result.authenticationSettings.push(authenticationSettings);
  if (injectables) {
    result.injectables.push(
      ...injectables.map(e => ({ ...e, associatedAuthSetting }))
    );
  }
  if (mTLSCertificates) {
    result.mTLSCertificates.push(
      ...mTLSCertificates.map(e => ({ ...e, associatedAuthSetting }))
    );
  }
  return result;
};

export const handleAuthenticationEditedToProject = <
  T extends Project | NewProjectData,
>(
  source: T,
  authenticationSettings: AuthenticationSettings,
  injectables: Injectable[],
  mTLSCertificates: MTLSCertificate[],
  settingIndex: number
) => {
  const newProjectData = structuredClone(source);
  newProjectData.authenticationSettings[settingIndex] = authenticationSettings;

  const associatedAuthSetting = authenticationSettings.name;
  const nextMTLS = (newProjectData.mTLSCertificates ?? []).filter(
    m => m.associatedAuthSetting !== associatedAuthSetting
  );
  nextMTLS.push(
    ...(mTLSCertificates ?? []).map(e => ({
      ...e,
      associatedAuthSetting,
    }))
  );
  newProjectData.mTLSCertificates = nextMTLS;

  const nextInjectables = (newProjectData.injectables ?? []).filter(
    i => i.associatedAuthSetting !== associatedAuthSetting
  );
  nextInjectables.push(
    ...(injectables ?? []).map(e => ({
      ...e,
      associatedAuthSetting,
    }))
  );
  newProjectData.injectables = nextInjectables;

  return newProjectData;
};
