import type { PlainObject } from "@Interfaces";

export const getParameters = (spec: any) => {
  const paths = spec.paths as PlainObject<any>;
  const resultSet = new Set<string>();

  type Parameter = { name?: string; schema?: { properties: PlainObject<any> } };

  Object.values(paths).forEach(endpoints => {
    //
    // endpoints = { get : {...}, post : {...}, ...}
    //
    Object.values(endpoints).forEach((method: any) => {
      /*
          method = {
            parameters : {
              name : string,
              schema ?: { 
                properties : [ property1 : {...}, property2 : {...} ...]
              }
            }
         }
        */
      const parameters = method.parameters as Parameter[] | undefined;

      (parameters ?? []).forEach(({ name, schema }) => {
        if (name && name !== "requestBody") {
          resultSet.add(name);
        }

        if (schema && schema.properties) {
          Object.keys(schema.properties).forEach(k => {
            resultSet.add(k);
          });
        }
      });
    });
  });

  return Array.from(resultSet);
};
