import { NewProjectContext } from "@Contexts";
import type { AuthenticationFlow } from "@Interfaces";
import {
  getEmptyAuthenticationSetting,
  handleAuthenticationAddedToProject,
  handleAuthenticationEditedToProject,
  type AuthenticationSettings,
  type Injectable,
  type MTLSCertificate,
} from "@Services";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useAuthenticationStore } from "../Authentication.store";

export const useAuthenticationCanvas = () => {
  const { project, setProject } = useContext(NewProjectContext);
  const {
    formValue,
    canvasFlow,
    canvasMode,
    authenticationSettings,
    updateAuthenticationSetting,
    addAuthenticationSetting,
    setFormValue,
    resetCanvas,
  } = useAuthenticationStore();

  const invalidNames = useMemo(() => {
    return (authenticationSettings ?? []).map(
      a => a.authenticationSettings.name
    );
  }, [authenticationSettings]);

  const openCanvas = useCallback(
    (authenticationFlow: AuthenticationFlow) => {
      if (formValue) {
        return;
      }

      const authenticationSettings = getEmptyAuthenticationSetting({
        authenticationFlow,
        role: "admin",
      });

      if (authenticationFlow === "oauth_credentials") {
        if (!authenticationSettings.userAuthTokens?.length) {
          authenticationSettings.userAuthTokens = [
            {
              authenticationSchemeType: "http-bearer",
              key: "Authorization",
              location: "header",
            },
          ];
        }
      }

      setFormValue({
        value: {
          authenticationSettings,
          injectables: [],
          mTLSCertificates: [],
        },
        index: -1,
      });
    },
    [formValue, setFormValue]
  );

  const hideCanvas = useCallback(() => {
    resetCanvas();
  }, [resetCanvas]);

  const handleSubmit = useCallback(
    async (
      authenticationSettings: AuthenticationSettings,
      injectables?: Injectable[],
      mTLSCertificates?: MTLSCertificate[]
    ) => {
      switch (canvasMode) {
        case "insert": {
          addAuthenticationSetting({
            authenticationSettings,
            injectables: injectables ?? [],
            mTLSCertificates: mTLSCertificates ?? [],
          });
          const newProjectData = handleAuthenticationAddedToProject(
            project,
            authenticationSettings,
            injectables,
            mTLSCertificates
          );
          setProject(newProjectData);
          break;
        }
        case "edit": {
          updateAuthenticationSetting(
            {
              authenticationSettings,
              injectables: injectables ?? [],
              mTLSCertificates: mTLSCertificates ?? [],
            },
            formValue?.index ?? 0
          );

          const newProjectData = handleAuthenticationEditedToProject(
            project,
            authenticationSettings,
            injectables ?? [],
            mTLSCertificates ?? [],
            formValue?.index ?? 0
          );

          setProject(newProjectData);
          break;
        }
      }
      return true;
    },
    [
      canvasMode,
      addAuthenticationSetting,
      project,
      setProject,
      updateAuthenticationSetting,
      formValue,
    ]
  );

  useEffect(() => {
    if (canvasFlow) {
      openCanvas(canvasFlow);
    }
  }, [canvasFlow, openCanvas]);

  return {
    showCanvas: !!canvasFlow,
    formValue,
    invalidNames,
    handleSubmit,
    openCanvas,
    hideCanvas,
  };
};
