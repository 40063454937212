import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getListSequences } from "src/Mock";
import type { PaginateResult, History } from "@Interfaces";

export const getAuthenticationProfiles = (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const pageSize = Number(sP.get("size")) || 12;

  const list: History[] = getListSequences(sP);

  const start = pageSize * (page - 1);
  const end = start + pageSize;

  const response: PaginateResult<History> = {
    data: list.slice(start, end),
    page: page,
    size: pageSize,
    totalItems: list.length,
    totalPages: Math.ceil(list.length / pageSize),
  };

  return res(ctx.delay(delay), ctx.json(response));
};
