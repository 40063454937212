import { usePaginatedResult } from "@Hooks";
import type { CriteriaType, OperatorsType, SortByType } from "./Scans.i";
import { API, getDefaultFilters } from "@Services";
import { useMemo } from "react";

export const useScans = () => {
  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters: useMemo(() => getDefaultFilters("scans"), []),
    getResponse: ({
      organizationId,
      projectId,
      page,
      pageSize,
      sortBy,
      sortMode,
      criteria,
      operators,
      values,
    }) => {
      return API.scan().scanListInProject(
        organizationId,
        projectId,
        page,
        pageSize,
        sortBy as SortByType,
        sortMode,
        criteria as CriteriaType,
        operators as OperatorsType,
        values
      );
    },
  });

  return { response, loading, fetch };
};
