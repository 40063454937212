import { useAuthenticationSettingFormStore } from "@Stores";

export const useRefreshDefinitionForm = () => {
  const { authenticationSettings, loading } =
    useAuthenticationSettingFormStore();

  return {
    authenticationSettings,
    loading,
    type: authenticationSettings.refreshDefinition?.type ?? "",
  };
};
