import type { ChangeEvent } from "react";
import { memo, useCallback, useMemo, useState, type FC } from "react";
import {
  FormInput,
  Button,
  DisplayTable,
  TrashButton,
  FormMessage,
} from "@Components";
import { isFilledString } from "@Services";
import { t } from "i18next";
import { FormGroup } from "reactstrap";
import { useAuthenticationSettingFormStore } from "@Stores";

const emptyStringLabel = t("common.empty-string").toLowerCase();

type CredentialInputModel = {
  key: string;
  value: string;
};

const DEFAULT_ENTRY: CredentialInputModel = {
  key: "",
  value: "",
};

/**
 * Credential Inputs form component. It update authenticationSettings.credentialInputs on submit
 */
const CredentialInputsForm: FC = () => {
  const {
    authenticationSettings: { credentialInputs },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const [entry, setEntry] = useState(DEFAULT_ENTRY);

  const updateEntry = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;
      setEntry(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const { validities, duplicateKey, valid } = useMemo(() => {
    const duplicateKey = Object.keys(credentialInputs ?? []).includes(
      entry.key
    );
    const validities = {
      key: !duplicateKey && isFilledString(entry.key),
      value: true,
    };
    const valid = Object.values(validities).every(Boolean);
    return { validities, valid, duplicateKey };
  }, [credentialInputs, entry.key]);

  const handleSubmit = useCallback(() => {
    setAuthenticationSettings(prev => {
      const credentialInputs = structuredClone(prev.credentialInputs);
      if (credentialInputs) {
        credentialInputs[entry.key] = entry.value;
        const sortedEntries = Object.entries(credentialInputs).sort(
          (entryA, entryB) => entryA[0].localeCompare(entryB[0])
        );
        return { ...prev, credentialInputs: Object.fromEntries(sortedEntries) };
      }

      return prev;
    });
    setEntry(DEFAULT_ENTRY);
  }, [entry, setAuthenticationSettings]);

  return (
    <>
      <div id="credential-input-form">
        <FormInput
          id="credential-key"
          data-cy="authentication-settings-input-credential-key"
          label="project.authentication-and-dictionary.credential-key"
          type="text"
          name="key"
          bsSize="sm"
          value={entry.key}
          valid={validities.key}
          invalid={!validities.key}
          onChange={updateEntry}
          disabled={loading}
          autoTrim
        />
        <FormInput
          id="credential-value"
          data-cy="authentication-settings-input-credential-value"
          label="project.authentication-and-dictionary.credential-value"
          type="text"
          name="value"
          bsSize="sm"
          value={entry.value}
          valid={validities.value}
          invalid={!validities.value}
          placeholder={emptyStringLabel}
          onChange={updateEntry}
          disabled={loading}
          autoTrim
        />
        <Button
          color="primary"
          size="sm"
          data-cy="authentication-settings-add-credential-button"
          onClick={handleSubmit}
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!valid || loading}
        />
      </div>
      {duplicateKey && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.key") })}
        />
      )}
    </>
  );
};

/**
 * Credential Inputs component. Contains Form and Showcase
 */
export const CredentialInputsTab: FC = memo(() => {
  const {
    authenticationSettings: { credentialInputs },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const length = Object.entries(credentialInputs ?? []).length;

  const deleteEntry = useCallback(
    (key: string) => {
      setAuthenticationSettings(prev => {
        const credentialInputs = structuredClone(prev.credentialInputs);
        if (!credentialInputs) {
          return prev;
        }
        delete credentialInputs[key];
        return { ...prev, credentialInputs };
      });
    },
    [setAuthenticationSettings]
  );

  const table = useMemo(() => {
    if (!length) return null;
    return (
      <DisplayTable>
        <thead>
          <tr>
            <th className="text-end">{t("common.key")}</th>
            <th>{t("common.value")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(credentialInputs ?? {}).map(([key, value], i) => {
            return (
              <tr key={`${i}-${key}-${value}`}>
                <td>{key}</td>
                <td>
                  <div className="d-flex justify-content-between align-items-center">
                    {value ? (
                      <span>{value}</span>
                    ) : (
                      <i className="opacity-75">{emptyStringLabel}</i>
                    )}
                    <TrashButton
                      onClick={() => deleteEntry(key)}
                      disabled={loading}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </DisplayTable>
    );
  }, [credentialInputs, deleteEntry, length, loading]);

  return (
    <>
      <FormGroup noMargin={length === 0}>
        <CredentialInputsForm />
      </FormGroup>
      {table}
    </>
  );
});
