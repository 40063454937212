export function binaryArrayToString(content: string | number[]): string {
  // check if is a string
  if (typeof content === "string") {
    return atob(content);
  } else {
    const byteArray = new Uint8Array(content as number[]);
    const textDecoder = new TextDecoder();
    const text = textDecoder.decode(byteArray);
    return atob(text);
  }
}
