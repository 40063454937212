import { useCallback, useState } from "react";
import { useEditingItself } from "../../Hooks/useEditingItself";

export const useUserSectionDetails = () => {
  const editingItself = useEditingItself();
  const [canvasOpen, setCanvasOpen] = useState(false);

  const showCanvas = useCallback(() => {
    setCanvasOpen(true);
  }, []);

  const closeCanvas = useCallback(() => {
    setCanvasOpen(false);
  }, []);

  return { editingItself, canvasOpen, showCanvas, closeCanvas };
};
