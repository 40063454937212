import type { AskPasswordResetRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getUserByEmail } from "src/Mock";

export const askPasswordReset = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { email } = (await req.json()) as {
    email: string;
  } as AskPasswordResetRequest;
  const user = getUserByEmail(email);
  if (!user) {
    return res(ctx.delay(delay), ctx.status(400));
  }
  return res(ctx.delay(delay), ctx.json({}));
};
