import { t } from "i18next";
import type { LongTextProps } from "./LongText.i";
import { MarkdownText } from "@Components";
import type { FC } from "react";
import { memo, useMemo } from "react";
import { equals } from "@Services";

export const LongText: FC<LongTextProps> = memo(
  ({ text, type = "description", maxLength = 200, setShowLongTextModal }) => {
    const { isTextLong, markdownChildren } = useMemo(() => {
      const isTextLong = text.length > maxLength;
      const markdownChildren = isTextLong
        ? `${text.slice(0, maxLength)}… `
        : text;
      return { isTextLong, markdownChildren };
    }, [maxLength, text]);
    return (
      <div className="long-text">
        <MarkdownText>{markdownChildren}</MarkdownText>
        {isTextLong && (
          <span
            className="color-pink cursor-pointer"
            role="button"
            onClick={() => setShowLongTextModal(type)}
          >
            {t("common.show-more")}
          </span>
        )}
      </div>
    );
  },
  equals
);
