import type { License } from "@Interfaces";
import moment from "moment-timezone";

export const licenses: License[] = [
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "1",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "2",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 1,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "3",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "4",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "5",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "6",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "7",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "8",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "9",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "10",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "11",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "12",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "13",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "14",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
  {
    startDate: moment()
      .subtract(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    endDate: moment()
      .add(100, "days")
      .format("YYYY-MM-DD[T]HH:mm:ss[.000000000Z]"),
    id: "47",
    name: "Enterprise",
    size: 100,
    quantity: 5,
    residual: 480,
    endpoints: 20,
  },
];
