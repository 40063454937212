import { type Nullable } from "@Interfaces";
import { type AuthProfileInfo, type AuthResolvedOperation } from "@Services";
import { createStore } from "src/Hooks/store/useStore";

export type AuthorizationMatrixStoreProperties = {
  operations: Nullable<Array<AuthResolvedOperation>>;
  profiles: Nullable<Array<AuthProfileInfo>>;
  totalItems: number;
  loading: boolean;
};

export type AuthorizationMatrixState = AuthorizationMatrixStoreProperties & {
  setOperationsAndProfiles: (
    operations: Nullable<Array<AuthResolvedOperation>>,
    profiles: Nullable<Array<AuthProfileInfo>>,
    totalItems: number
  ) => void;
  setLoading: (loading: boolean) => void;
  reset: VoidFunction;
};

const initialState: AuthorizationMatrixStoreProperties = {
  operations: null,
  profiles: null,
  totalItems: 0,
  loading: false,
};

export const useAuthorizationMatrixStore =
  createStore<AuthorizationMatrixState>((get, set) => ({
    ...initialState,
    setOperationsAndProfiles: (operations, profiles, totalItems) =>
      set(state => ({ ...state, operations, profiles, totalItems })),
    setLoading: loading => set(state => ({ ...state, loading })),
    reset: () => set(state => ({ ...state, ...initialState })),
  }));
