import validator from "validator";
import { isURL } from "./isURL";

type ControlType = "name" | "email" | "number" | "url" | "jwt";

export const isValid = (control: ControlType, value: string) => {
  switch (control) {
    case "name": {
      return new RegExp("^[\\p{L}\\s]+$", "u").test(value);
    }
    case "email": {
      return validator.isEmail(value);
    }
    case "number": {
      return !/^[0-9]$/.test(value);
    }
    case "url": {
      return isURL(value, {
        allowLocalhost: false,
        isUrlOptions: {
          protocols: ["http", "https"],
          require_tld: true,
          require_protocol: true,
          require_host: true,
          require_port: false,
          require_valid_protocol: true,
          allow_underscores: true,
          allow_trailing_dot: false,
          allow_protocol_relative_urls: false,
          allow_fragments: false,
          allow_query_components: false,
          disallow_auth: false,
          validate_length: true,
        },
      });
    }
    case "jwt": {
      const splitted = value.split(".");
      return splitted.length === 3 && splitted.every(fragment => !!fragment);
    }
  }
};
