import { createStore } from "@Hooks";
import type { AuthenticationFormMode } from "@Pages";
import {
  EMPTY_OAUTHDATA,
  getEmptyAuthenticationSetting,
  type AuthenticationSettings,
  type Injectable,
  type MTLSCertificate,
} from "@Services";
import { cloneDeep } from "lodash";
import type { Dispatch, SetStateAction } from "react";

type SubFormValidities = {
  main: boolean;
  flow: boolean;
};

type AuthenticationSettingStoreProperties = {
  // form data
  authenticationSettings: AuthenticationSettings; // todo remove frontend overrides
  mTLSCertificates: Array<MTLSCertificate>;
  injectables: Array<Injectable>;
  // form status
  loading: boolean;
  subFormValidities: SubFormValidities;
  mode: AuthenticationFormMode;
};

type AuthenticationSettingStore = AuthenticationSettingStoreProperties & {
  // authentication
  setAuthenticationSettings: Dispatch<SetStateAction<AuthenticationSettings>>;
  // injectables
  setInjectables: Dispatch<Injectable[]>;
  addInjectable: Dispatch<Injectable>;
  removeInjectable: Dispatch<number>;
  // mTLS certificates
  setMTLSCertificates: Dispatch<MTLSCertificate[]>;
  addMTLSCertificate: Dispatch<MTLSCertificate>;
  removeMTLSCertificate: Dispatch<number>;
  // utils
  setLoading: Dispatch<boolean>;
  setMode: Dispatch<AuthenticationFormMode>;
  setSubFormValidity: (type: keyof SubFormValidities, valid: boolean) => void;
  reset: () => void;
};

const INITIAL_STATE: AuthenticationSettingStoreProperties = {
  authenticationSettings: getEmptyAuthenticationSetting(),
  injectables: [],
  mTLSCertificates: [],
  mode: "insert",
  loading: false,
  subFormValidities: {
    flow: false,
    main: false,
  },
};

export const useAuthenticationSettingFormStore =
  createStore<AuthenticationSettingStore>((_, set) => ({
    ...INITIAL_STATE,
    setAuthenticationSettings: dispatch => {
      set(store => {
        let authenticationSettings = cloneDeep(store.authenticationSettings);

        if (typeof dispatch === "function") {
          authenticationSettings = dispatch(authenticationSettings);
        } else if (typeof dispatch === "object") {
          authenticationSettings = dispatch;
        }

        if (authenticationSettings.authenticationFlow === "oauth_credentials") {
          if (!authenticationSettings.oAuthData) {
            authenticationSettings.oAuthData = EMPTY_OAUTHDATA;
          }
        }

        if (authenticationSettings.refreshDefinition) {
          const nextType = authenticationSettings.refreshDefinition.type;
          const type =
            store.authenticationSettings.refreshDefinition?.type ?? "";

          if (nextType !== type) {
            if (nextType === "external") {
              authenticationSettings.refreshDefinition.oAuthData = {
                ...(authenticationSettings.refreshDefinition.oAuthData ??
                  EMPTY_OAUTHDATA),
                grantType: "refresh_token" /* required as default value */,
              };
            }
          }
        }

        return { ...store, authenticationSettings };
      });
    },
    // injectable actions
    setInjectables: value => {
      set(store => ({ ...store, injectables: value }));
    },
    addInjectable: injectable => {
      set(store => {
        const value = [...store.injectables];
        value.push(injectable);
        return { ...store, injectables: value };
      });
    },
    removeInjectable: start => {
      set(store => {
        const value = [...store.injectables];
        value.splice(start, 1);
        return { ...store, injectables: value };
      });
    },
    // mtls certificate actions
    setMTLSCertificates: value => {
      set(store => ({ ...store, mTLSCertificates: value }));
    },
    addMTLSCertificate: mTLSCertificate => {
      set(store => {
        const value = [...store.mTLSCertificates];
        value.push(mTLSCertificate);
        return { ...store, mTLSCertificates: value };
      });
    },
    removeMTLSCertificate: start => {
      set(store => {
        const value = [...store.mTLSCertificates];
        value.splice(start, 1);
        return { ...store, mTLSCertificates: value };
      });
    },
    // other
    setMode: value => {
      set(store => ({ ...store, mode: value }));
    },
    setSubFormValidity: (type, valid) => {
      set(store => {
        const { subFormValidities } = store;
        const value = { ...subFormValidities, [type]: valid };
        return { ...store, subFormValidities: value };
      });
    },
    setLoading: value => {
      set(store => ({ ...store, loading: value }));
    },
    reset: () => {
      set(store => ({ ...store, ...INITIAL_STATE }));
    },
  }));
