/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AskEnableTwoFactorAuthenticationResponse } from '../model';
// @ts-ignore
import { AuthLoginRequest } from '../model';
// @ts-ignore
import { ConfirmEnableTwoFactorAuthenticationRequest } from '../model';
// @ts-ignore
import { ConfirmEnableTwoFactorAuthenticationResponse } from '../model';
// @ts-ignore
import { ResponseLogin } from '../model';
// @ts-ignore
import { VerifyOTPRequest } from '../model';
// @ts-ignore
import { VerifyOTPResponse } from '../model';
/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lets a user ask to enable two-factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askEnable2fa: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ask-enable-2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication EnableTwoFactorAuthenticationBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lets a user confirm to enable two-factor authentication, returning an authentication token if successful
         * @param {ConfirmEnableTwoFactorAuthenticationRequest} requestBody ConfirmEnableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEnable2fa: async (requestBody: ConfirmEnableTwoFactorAuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('confirmEnable2fa', 'requestBody', requestBody)
            const localVarPath = `/v1/confirm-enable-2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication EnableTwoFactorAuthenticationBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lets a user log in, returning a status and a token to continue the process if successful
         * @param {AuthLoginRequest} requestBody AuthLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (requestBody: AuthLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('login', 'requestBody', requestBody)
            const localVarPath = `/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lets a user log in with two-factor authentication, returning an authentication token if successful
         * @param {VerifyOTPRequest} requestBody VerifyOTPRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOtp: async (requestBody: VerifyOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('verifyOtp', 'requestBody', requestBody)
            const localVarPath = `/v1/verify-otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication VerifyOTPBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * Lets a user ask to enable two-factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async askEnable2fa(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AskEnableTwoFactorAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.askEnable2fa(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lets a user confirm to enable two-factor authentication, returning an authentication token if successful
         * @param {ConfirmEnableTwoFactorAuthenticationRequest} requestBody ConfirmEnableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEnable2fa(requestBody: ConfirmEnableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmEnableTwoFactorAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEnable2fa(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lets a user log in, returning a status and a token to continue the process if successful
         * @param {AuthLoginRequest} requestBody AuthLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(requestBody: AuthLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lets a user log in with two-factor authentication, returning an authentication token if successful
         * @param {VerifyOTPRequest} requestBody VerifyOTPRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyOtp(requestBody: VerifyOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyOTPResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyOtp(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * Lets a user ask to enable two-factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askEnable2fa(options?: any): AxiosPromise<AskEnableTwoFactorAuthenticationResponse> {
            return localVarFp.askEnable2fa(options).then((request) => request(axios, basePath));
        },
        /**
         * Lets a user confirm to enable two-factor authentication, returning an authentication token if successful
         * @param {ConfirmEnableTwoFactorAuthenticationRequest} requestBody ConfirmEnableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEnable2fa(requestBody: ConfirmEnableTwoFactorAuthenticationRequest, options?: any): AxiosPromise<ConfirmEnableTwoFactorAuthenticationResponse> {
            return localVarFp.confirmEnable2fa(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Lets a user log in, returning a status and a token to continue the process if successful
         * @param {AuthLoginRequest} requestBody AuthLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestBody: AuthLoginRequest, options?: any): AxiosPromise<ResponseLogin> {
            return localVarFp.login(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Lets a user log in with two-factor authentication, returning an authentication token if successful
         * @param {VerifyOTPRequest} requestBody VerifyOTPRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOtp(requestBody: VerifyOTPRequest, options?: any): AxiosPromise<VerifyOTPResponse> {
            return localVarFp.verifyOtp(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - interface
 * @export
 * @interface LoginApi
 */
export interface LoginApiInterface {
    /**
     * Lets a user ask to enable two-factor authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApiInterface
     */
    askEnable2fa(options?: AxiosRequestConfig): AxiosPromise<AskEnableTwoFactorAuthenticationResponse>;

    /**
     * Lets a user confirm to enable two-factor authentication, returning an authentication token if successful
     * @param {ConfirmEnableTwoFactorAuthenticationRequest} requestBody ConfirmEnableTwoFactorAuthenticationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApiInterface
     */
    confirmEnable2fa(requestBody: ConfirmEnableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig): AxiosPromise<ConfirmEnableTwoFactorAuthenticationResponse>;

    /**
     * Lets a user log in, returning a status and a token to continue the process if successful
     * @param {AuthLoginRequest} requestBody AuthLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApiInterface
     */
    login(requestBody: AuthLoginRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseLogin>;

    /**
     * Lets a user log in with two-factor authentication, returning an authentication token if successful
     * @param {VerifyOTPRequest} requestBody VerifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApiInterface
     */
    verifyOtp(requestBody: VerifyOTPRequest, options?: AxiosRequestConfig): AxiosPromise<VerifyOTPResponse>;

}

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI implements LoginApiInterface {
    /**
     * Lets a user ask to enable two-factor authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public askEnable2fa(options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).askEnable2fa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lets a user confirm to enable two-factor authentication, returning an authentication token if successful
     * @param {ConfirmEnableTwoFactorAuthenticationRequest} requestBody ConfirmEnableTwoFactorAuthenticationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public confirmEnable2fa(requestBody: ConfirmEnableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).confirmEnable2fa(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lets a user log in, returning a status and a token to continue the process if successful
     * @param {AuthLoginRequest} requestBody AuthLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(requestBody: AuthLoginRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).login(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lets a user log in with two-factor authentication, returning an authentication token if successful
     * @param {VerifyOTPRequest} requestBody VerifyOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public verifyOtp(requestBody: VerifyOTPRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).verifyOtp(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
