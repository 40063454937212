import "./style.scss";
import type { Props } from "./OtpInput.i";
import useOtpInput from "./useOtpInput";

export const OtpInput = ({
  otpLength = 6,
  setOtp,
  disabled = false,
}: Props) => {
  const { onChange, onKeyPress, refs } = useOtpInput({ setOtp, disabled });

  const otpArray = Array(otpLength).fill(0);

  return (
    <div id="otp-inputs-wrapper" data-disabled={disabled}>
      {otpArray.map((v, i) => (
        <input
          id={`otp-input-${i + 1}`}
          key={i}
          type="number"
          readOnly={disabled}
          className="form-control otp-input-box"
          ref={el => (refs.current[i] = el as HTMLInputElement)}
          onKeyDown={e => disabled || onKeyPress(e, i)}
          onChange={e => disabled || onChange(e, i)}
        />
      ))}
    </div>
  );
};

export { type Props as OtpInputProps };
