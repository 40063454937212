import { memo } from "react";

import "./style.scss";
import { joinClasses } from "@Utils";

interface InfoBadgeProps {
  children: number | string | JSX.Element | JSX.Element[];
  bgColor?: string;
  className?: string;
  type?: "severity";
  id?: string;
  title?: string;
  iconClass?: string;
  onClick?: VoidFunction;
}

export const InfoBadge = memo(
  ({
    children,
    bgColor = "secondary",
    className = "",
    type,
    iconClass,
    ...otherProps
  }: InfoBadgeProps) => (
    <div
      role="presentation"
      className={`info-badge bg-${bgColor} ${className}`}
      data-type={type}
      {...otherProps}
    >
      {iconClass && <i className={joinClasses(iconClass, "me-2")} />}
      {children}
    </div>
  )
);
