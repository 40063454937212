import type { IssueExclusion } from "@Services";

export const issueExclusion: IssueExclusion[] = [
  {
    id: "1",
    extensionName: "server-side-request-forgery",
    falsePositive: true,
    methods: ["POST"],
    path: "/upload-media",
  },
];
