import type { Nullable } from "@Interfaces";
import React, {
  type ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";

type Vector2 = {
  x: number;
  y: number;
};

enum ETooltipVisibility {
  NotVisible,
  ReadyToBeVisible,
  Visible,
}

export function withMouseCursorTooltip<
  WrappedComponentProps extends object,
  TooltipProps extends object,
>(
  WrappedComponent: ComponentType<WrappedComponentProps>,
  TooltipComponent: ComponentType<TooltipProps>,
  tooltipProps: TooltipProps
): ComponentType<WrappedComponentProps> {
  return (props: WrappedComponentProps) => {
    const [tooltipVisible, setTooltipVisible] = useState(
      ETooltipVisibility.NotVisible
    );
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const hoverTimeoutRef = useRef<Nullable<NodeJS.Timeout>>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const latestMousePositionRef = useRef<Vector2>({ x: 0, y: 0 });

    const updateTooltipPosition = useCallback((position: Vector2) => {
      const tooltipWidth = tooltipRef.current?.offsetWidth ?? 0;
      const tooltipHeight = tooltipRef.current?.offsetHeight ?? 0;
      const padding = 10;

      let x = position.x + padding;
      let y = position.y + padding;

      if (x + tooltipWidth > window.innerWidth) {
        x = position.x - tooltipWidth - padding;
      }

      if (y + tooltipHeight > window.innerHeight) {
        y = position.y - tooltipHeight - padding;
      }

      setTooltipPosition({ x, y });
    }, []);

    const handleMouseEnter = useCallback(() => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      hoverTimeoutRef.current = setTimeout(() => {
        setTooltipVisible(ETooltipVisibility.ReadyToBeVisible);
        requestAnimationFrame(() => {
          updateTooltipPosition(latestMousePositionRef.current);
          setTooltipVisible(ETooltipVisibility.Visible);
        });
      }, 1000);
    }, [updateTooltipPosition]);

    const handleMouseLeave = useCallback(() => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      setTooltipVisible(ETooltipVisibility.NotVisible);
    }, []);

    const handleMouseMove = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        const mousePosition: Vector2 = { x: event.clientX, y: event.clientY };
        latestMousePositionRef.current = mousePosition;
        updateTooltipPosition(mousePosition);
      },
      [updateTooltipPosition]
    );

    useEffect(() => {
      if (!tooltipVisible) {
        return () => window.removeEventListener("mousedown", handleMouseLeave);
      }
      window.addEventListener("mousedown", handleMouseLeave, true);
      return () => window.removeEventListener("mousedown", handleMouseLeave);
    }, [handleMouseLeave, tooltipVisible]);

    const tooltipStyle: React.CSSProperties = {
      position: "fixed",
      top: tooltipPosition.y,
      left: tooltipPosition.x,
      pointerEvents: "none",
      zIndex: 2000,
      transform: "translate(10px, 10px)", // Slight offset to avoid cursor overlap
      visibility:
        tooltipVisible === ETooltipVisibility.Visible ? "visible" : "hidden",
    };

    return (
      <div
        className="with-tooltip"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <WrappedComponent {...props} />
        {tooltipVisible !== ETooltipVisibility.NotVisible &&
          ReactDOM.createPortal(
            <div ref={tooltipRef} style={{ ...tooltipStyle }}>
              <TooltipComponent {...tooltipProps} />
            </div>,
            document.body
          )}
      </div>
    );
  };
}
