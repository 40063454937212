import { useOrganizationSettings } from "./useOrganizationSettings";

export const useValidDomain = () => {
  const { organizationSettings } = useOrganizationSettings();
  const { scope: enabledDomains } = organizationSettings || {};

  function isValidSingleDomain(input: string, pattern: string) {
    const regexPattern = pattern.replace(/\./g, "\\.").replace(/\*/g, ".*");
    return new RegExp(`^${regexPattern}$`, "i").test(input);
  }

  return {
    enabledDomains,
    isDomainEnabled: (baseURL: string) =>
      !enabledDomains ||
      enabledDomains.some(domain => isValidSingleDomain(baseURL, domain)),
  };
};
