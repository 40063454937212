import { MenuHorizontalContainer } from "./Components";
import type { MenuHorizontalProps } from "./MenuHorizontal.i";
import { ButtonsMenu } from "./Components/ButtonsMenu/ButtonsMenu";
import { Breadcrumbs } from "./Components/Breadcrumbs";
import "./style.scss";

export const MenuHorizontal = ({
  className = "",
  children,
}: MenuHorizontalProps) => {
  return (
    <nav
      id="menu-horizontal"
      className={`d-flex w-100 align-items-center ${className}`.trim()}
    >
      <Breadcrumbs />
      {children && (
        <MenuHorizontalContainer
          flexGrow={0}
          justifyContent={"start"}
          children={children}
          className="menu-horizontal-content"
        />
      )}
      <ButtonsMenu />
    </nav>
  );
};
