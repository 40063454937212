import "./style.scss";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Chart, type Point, registerables } from "chart.js";
import "chartjs-adapter-moment";
import type { VulnerabilityChartProps } from "./VulnerabilityChart.i";
import { createGradient, getOptions, normalizeDataToWeeks } from "./functions";
import { ThemeContext } from "@Contexts";
import { t } from "i18next";
import { severityColors } from "@Constants";
import { usePreference } from "@Hooks";

Chart.register(...registerables);

interface StringKeyedObject {
  [key: string]: string;
}

const LEGEND_ITEMS_TEXT_MAP: StringKeyedObject = {
  high: t("common.high"),
  medium: t("common.medium"),
  low: t("common.low"),
  information: t("common.information"),
};

const DEFAULT_VULNERABILITY_VISIBLE_DATASETS = "low|medium|high";

export const VulnerabilityChart = ({
  vulnerabilities,
  period,
}: VulnerabilityChartProps) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null); // Store chart instance
  const { theme } = useContext(ThemeContext);
  const [vulnerabilityVisibleDataSets, setVulnerabilityVisibleDataSets] =
    usePreference(
      "vulnerability.visible.datasets",
      DEFAULT_VULNERABILITY_VISIBLE_DATASETS
    );

  const isDark = theme === "dark";

  const handleLegendItemClick = useCallback(
    (chart: Chart<"line", (number | Point | null)[], unknown>): void => {
      const allActiveItems = chart.data.datasets.filter((dataSet, index) =>
        chart.isDatasetVisible(index)
      );

      if (!allActiveItems) {
        setVulnerabilityVisibleDataSets(DEFAULT_VULNERABILITY_VISIBLE_DATASETS);
        return;
      }

      const visibleDatasetLabels = allActiveItems.map(dataSet => dataSet.label);

      const visibleItemsKeys = Object.keys(LEGEND_ITEMS_TEXT_MAP).filter(key =>
        visibleDatasetLabels.includes(LEGEND_ITEMS_TEXT_MAP[key])
      );

      setVulnerabilityVisibleDataSets(visibleItemsKeys.join("|"));
    },
    [setVulnerabilityVisibleDataSets]
  );

  useEffect(() => {
    if (chartRef.current) {
      const chartCanvas = chartRef.current.getContext("2d");

      if (chartCanvas) {
        const normData = normalizeDataToWeeks(vulnerabilities);

        const highData = normData.map(({ severityCount }) => {
          return severityCount.high || 0;
        });
        const mediumData = normData.map(({ severityCount }) => {
          return severityCount.medium || 0;
        });
        const lowData = normData.map(({ severityCount }) => {
          return severityCount.low || 0;
        });
        const informationData = normData.map(({ severityCount }) => {
          return severityCount.information || 0;
        });

        const maxValue = Math.max(
          ...highData.concat(mediumData).concat(lowData).concat(informationData)
        );

        if (!chartInstance.current) {
          const actualVisibleDatasets =
            vulnerabilityVisibleDataSets ??
            DEFAULT_VULNERABILITY_VISIBLE_DATASETS;

          chartInstance.current = new Chart(chartCanvas, {
            type: "line",
            data: {
              labels: normData.map(data => data.date).sort(),
              datasets: [
                {
                  label: LEGEND_ITEMS_TEXT_MAP.high,
                  data: highData,
                  backgroundColor: createGradient(highData, maxValue),
                  borderColor: severityColors.high,
                  pointBackgroundColor: severityColors.high,
                  fill: true,
                  clip: 10,
                  hidden: !actualVisibleDatasets.includes("high"),
                },
                {
                  label: LEGEND_ITEMS_TEXT_MAP.medium,
                  data: mediumData,
                  backgroundColor: createGradient(mediumData, maxValue),
                  borderColor: severityColors.medium,
                  pointBackgroundColor: severityColors.medium,
                  fill: true,
                  clip: 10,
                  hidden: !actualVisibleDatasets.includes("medium"),
                },
                {
                  label: LEGEND_ITEMS_TEXT_MAP.low,
                  data: lowData,
                  backgroundColor: createGradient(lowData, maxValue),
                  borderColor: severityColors.low,
                  pointBackgroundColor: severityColors.low,
                  fill: true,
                  clip: 10,
                  hidden: !actualVisibleDatasets.includes("low"),
                },
                {
                  label: LEGEND_ITEMS_TEXT_MAP.information,
                  data: informationData,
                  backgroundColor: createGradient(informationData, maxValue),
                  borderColor: severityColors.information,
                  pointBackgroundColor: severityColors.information,
                  fill: true,
                  clip: 10,
                  hidden: !actualVisibleDatasets.includes("information"),
                },
              ],
            },
            options: getOptions(
              normData,
              period,
              isDark,
              handleLegendItemClick
            ),
          });
        } else {
          const datasets = chartInstance.current.data.datasets;
          datasets[0].data = highData;
          datasets[0].backgroundColor = createGradient(highData, maxValue);
          datasets[1].data = mediumData;
          datasets[1].backgroundColor = createGradient(mediumData, maxValue);
          datasets[2].data = lowData;
          datasets[2].backgroundColor = createGradient(lowData, maxValue);
          chartInstance.current.update();
        }
      }
    }
  }, [
    vulnerabilities,
    period,
    isDark,
    vulnerabilityVisibleDataSets,
    handleLegendItemClick,
  ]);

  return (
    <canvas
      className="vulnerability-chart"
      data-cy="vulnerability-chart"
      ref={chartRef}
    />
  );
};
