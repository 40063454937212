import type { Header } from "@Interfaces";
import { isFilledString } from "../utils";

export const ENABLE_MOCK_ENV_VAR = "REACT_APP_ENABLE_MOCK";
export const APP_API_URI_ENV_VAR = "REACT_APP_API_URI";
export const ENV = "REACT_APP_ENVIRONMENT";
export const APP_API_HEADER_ENV_VAR = "APP_API_HEADER_ENV_VAR";

export const MAINTENANCE_START = "REACT_APP_MAINTENANCE_START";
export const MAINTENANCE_END = "REACT_APP_MAINTENANCE_END";
export const MAINTENANCE_LOCK_START = "REACT_APP_MAINTENANCE_LOCK_START";
export const MAINTENANCE_LOCK_END = "REACT_APP_MAINTENANCE_LOCK_END";

const checkMockEnabled = (value: string | undefined): boolean => value === "1";

export const isMockEnable = (): boolean => {
  try {
    const inStorage = readFromStorage(ENABLE_MOCK_ENV_VAR);
    if (inStorage != null) {
      return checkMockEnabled(inStorage);
    }
  } catch (e) {
    // nop, impl to avoid error
  }
  return checkMockEnabled(process.env[ENABLE_MOCK_ENV_VAR]);
};

export const isLocalHost = () => window.location.origin.includes("localhost");
export const isDevelopment = () => process.env[ENV] === "development";

export const getAppAPIURL = (): string | undefined => {
  try {
    const inStorage = readFromStorage(APP_API_URI_ENV_VAR);
    if (inStorage != null) {
      return inStorage;
    }
  } catch (e) {
    // nop, impl to avoid error
  }
  return process.env[APP_API_URI_ENV_VAR];
};

export const getAppAPIHeaders = (): Header[] => {
  try {
    const inStorage = readFromStorage(APP_API_HEADER_ENV_VAR);
    if (inStorage != null) {
      return JSON.parse(inStorage);
    }
  } catch (e) {
    // nop, impl to avoid error
  }
  return [];
};

const readFromStorage = (variable: string): string | null => {
  try {
    return localStorage.getItem(variable);
  } catch (e) {
    return null;
  }
};

export const getMaintenanceModeStart = (): number => {
  return getMaintenanceValue(MAINTENANCE_START);
};

export const getMaintenanceModeEnd = (): number => {
  return getMaintenanceValue(MAINTENANCE_END);
};

export const getMaintenanceModeLockStart = (): number => {
  return (
    getMaintenanceValue(MAINTENANCE_LOCK_START) || getMaintenanceModeStart()
  );
};

export const getMaintenanceModeLockEnd = (): number => {
  return getMaintenanceValue(MAINTENANCE_LOCK_END) || getMaintenanceModeEnd();
};

const getMaintenanceValue = (key: string) => {
  const inStorage = readFromStorage(key);

  const value = inStorage || process.env[key];
  return value && isFilledString(value) ? +value : 0;
};
