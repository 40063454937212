import React, { memo, useMemo } from "react";
import {
  RiskLevelCard,
  EndpointCountCard,
  IssueCountCard,
  OpenIssuesCard,
  WeeklyTrendCard,
  MostCommonIssuesCard,
  DataClassificationCard,
} from "../../Cards";
import { useDashboardProject } from "./useDashboardProject";
import { DashboardLoader } from "../../Components";
import {
  ArchivedProject,
  DashboardError,
  ErrorProject,
  GridContainer,
  NoScans,
} from "@Components";
import { pureComponent } from "@Utils";

export const DashboardProject = memo(() => {
  const {
    issueCounter,
    loading,
    chartData,
    summary,
    projectArchived,
    projectError,
    projectName = "N/A",
  } = useDashboardProject();

  const content = useMemo(() => {
    switch (true) {
      case loading:
        return <DashboardLoader />;
      case projectError:
        return <ErrorProject projectName={projectName} />;
      case projectArchived:
        return <ArchivedProject projectName={projectName} />;
      case !summary || !chartData:
        return <DashboardError />;
      case !summary?.summary.totalScansCount:
        return <NoScans />;
      default:
        const hasAnyCompletedScan = !!summary?.summary.completedScansCount;
        return (
          <GridContainer className="dashboard project">
            <RiskLevelCard
              riskLevel={summary?.summary.riskLevel}
              showNoData={!hasAnyCompletedScan}
            />
            <EndpointCountCard
              endpointsCounter={summary?.summary.endpoints || 0}
              showNoData={false} // alwais display the card content
            />
            <IssueCountCard
              issueCounter={issueCounter}
              showNoData={!hasAnyCompletedScan}
            />
            <OpenIssuesCard
              severityCount={summary?.summary.severityCount}
              showNoData={!hasAnyCompletedScan}
            />
            <WeeklyTrendCard
              vulnerabilities={chartData?.dailySeverityCount}
              showNoData={!hasAnyCompletedScan}
            />
            <MostCommonIssuesCard
              mostCommonIssues={
                summary?.summary.mostFrequentIssuesSeverityCount
              }
              showNoData={!hasAnyCompletedScan}
            />
            <DataClassificationCard
              dataClassification={summary?.dataClassification}
              showNoData={false} // alwais display the card content
            />
          </GridContainer>
        );
    }
  }, [
    chartData,
    issueCounter,
    loading,
    projectArchived,
    projectError,
    projectName,
    summary,
  ]);
  return content;
}, pureComponent);
