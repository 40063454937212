import useIssueTable from "./useIssueTable";
import { ListItems } from "@Components";
import isEqual from "react-fast-compare";
import { type Dispatch, memo } from "react";
import { type ListedIssue } from "@Services";

export type IssuesTableProps = {
  issues: ListedIssue[] | undefined;
  loading: boolean;
  setFilters: Dispatch<Record<string, string>>;
};

export const IssuesTable = memo((props: IssuesTableProps) => {
  const {
    headers,
    loading,
    config,
    cardItemOrder,
    fullRowIndexes,
    defaultFilters,
    setFilters,
  } = useIssueTable(props);

  return (
    <ListItems
      cardVersion={false}
      config={config}
      headers={headers}
      showSkeleton={loading}
      clickable={true}
      noDataMessage="common.no-issues-found"
      updateCardItemOrder={cardItemOrder}
      fullRowIndexes={fullRowIndexes}
      setSorts={setFilters}
      defaultSorts={defaultFilters}
    />
  );
}, isEqual);
