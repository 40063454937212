import "./style.scss";
import React, { useCallback, useContext } from "react";
import { RunPolicyForm, Section } from "@Components";
import { NewProjectContext, NewProjectExtrasContext } from "@Contexts";
import type { RunPolicy } from "@Services";

export const Schedule = React.memo(() => {
  const { project, setProject } = useContext(NewProjectContext);
  const { setProjectExtras } = useContext(NewProjectExtrasContext);

  const setPolicy = useCallback(
    (runPolicy: RunPolicy) => {
      setProject({ ...project, runPolicy });
    },
    [project, setProject]
  );

  const setState = useCallback(
    (valid: boolean) => {
      setProjectExtras(projectExtras => ({
        ...projectExtras,
        runPolicyValid: valid,
      }));
    },
    [setProjectExtras]
  );

  return (
    <div id="schedule">
      <Section>
        <RunPolicyForm
          runPolicy={project.runPolicy}
          setRunPolicy={setPolicy}
          startDateOffset={5}
          setValid={setState}
        />
      </Section>
    </div>
  );
});
