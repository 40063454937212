import { Button, PathExclusionForm } from "@Components";
import type { Method } from "@Interfaces";
import { isFilledString, type PathExclusionEntry } from "@Services";
import type { Dispatch, FC } from "react";
import { memo, useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";

type AddPathExclusionProps = {
  paths: string[];
  addPathExclusion: Dispatch<PathExclusionEntry>;
};

export const AddPathExclusion: FC<AddPathExclusionProps> = memo(
  ({ addPathExclusion, paths }) => {
    const [path, setPath] = useState("");
    const [activeMethods, setActiveMethods] = useState<Method[]>([]);

    const validities = useMemo(
      () => ({
        methods: !!activeMethods.length,
        path: isFilledString(path),
      }),
      [activeMethods.length, path]
    );

    const handleAddPathExclusion = useCallback(() => {
      addPathExclusion({
        methods: activeMethods,
        path,
      });
      setPath("");
    }, [activeMethods, addPathExclusion, path]);

    return (
      <div>
        <PathExclusionForm
          path={path}
          setPath={setPath}
          activeMethods={activeMethods}
          setActiveMethods={setActiveMethods}
          validities={validities}
          paths={paths}
        />
        <Row className="mt-3">
          <Col xs={12} className="text-end">
            <Button
              children="common.add"
              iconClass="bi bi-plus-square-fill"
              color="primary"
              id="add-path-exclusion-button"
              disabled={!validities.methods || !validities.path}
              onClick={handleAddPathExclusion}
            />
          </Col>
        </Row>
      </div>
    );
  }
);
