import React from "react";
import { getTextLabel, getThWidth } from "./functions";
import type { Props } from "./THead.i";
import { t } from "i18next";
import ReactDomServer from "react-dom/server";

const THead = React.memo(
  ({
    headers,
    columnDistribution,
    columnSizes,
    translateHeaders,
    showSkeleton,
  }: Props) => (
    <thead>
      <tr>
        {headers.map((headerItem, index) => {
          const objectLike = typeof headerItem === "object";
          const hasOnClickHandler = objectLike && !!headerItem.onClick;
          return (
            <th
              key={index}
              style={{
                width: getThWidth(columnDistribution, {
                  columnSizes: columnSizes || [],
                  columnsLength: headers.length,
                  index,
                }),
                cursor: hasOnClickHandler && !showSkeleton ? "pointer" : "auto",
              }}
              onClick={
                hasOnClickHandler && !showSkeleton
                  ? headerItem.onClick
                  : undefined
              }
            >
              <span>{getTextLabel(translateHeaders, headerItem)}</span>
              {objectLike && (
                <>
                  {!!headerItem.iconClass && (
                    <i className={`ms-1 bi bi-${headerItem.iconClass}`} />
                  )}
                  {!!headerItem.tooltip && (
                    <i
                      className="bi-info-circle ms-2"
                      data-tooltip-id="tooltip"
                      data-tooltip-html={ReactDomServer.renderToStaticMarkup(
                        <>
                          {typeof headerItem.tooltip === "string"
                            ? t(headerItem.tooltip) || ""
                            : headerItem.tooltip}
                        </>
                      )}
                      data-tooltip-place="left"
                    />
                  )}
                </>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  )
);

export default THead;
