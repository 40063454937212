import { useEffect, useState, type ChangeEvent } from "react";
import {
  ENABLE_MOCK_ENV_VAR,
  APP_API_URI_ENV_VAR,
  APP_API_HEADER_ENV_VAR,
  isMockEnable,
  getAppAPIURL,
  getAppAPIHeaders,
  setCustomHeaders,
  MAINTENANCE_END,
  MAINTENANCE_LOCK_END,
  MAINTENANCE_LOCK_START,
  MAINTENANCE_START,
  getMaintenanceModeStart,
  getMaintenanceModeEnd,
  getMaintenanceModeLockStart,
  getMaintenanceModeLockEnd,
} from "@Services";
import type { DevModeProps } from "./LoginCard.i";
import type { Header } from "@Interfaces";

export const useDevSetting = () => {
  const [devMode, setDevMode] = useState(false); // Easter egg state
  const [devSettings, setDevSettings] = useState<DevModeProps["devSettings"]>({
    enableMock: isMockEnable() ? "1" : "0",
    backendURI: getAppAPIURL() || "",
    maintenanceModeStart: getMaintenanceModeStart(),
    maintenanceModeEnd: getMaintenanceModeEnd(),
    maintenanceModeLockStart: getMaintenanceModeLockStart(),
    maintenanceModeLockEnd: getMaintenanceModeLockEnd(),
  });
  const [headers, setHeaders] = useState<Header[]>(getAppAPIHeaders());

  // easter egg key combination: 'Shift+Q'
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        "getModifierState" in event &&
        event.getModifierState("Shift") &&
        event.code === "KeyQ"
      ) {
        setDevMode(!devMode);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [devMode]);

  const handleDevSettingChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setDevSettings(prev => ({
      ...prev,
      [name]: name === "enableMock" ? (checked ? "1" : "0") : value,
    }));
  };

  const handleHeaderChange = (newHeaders: Header[]) => {
    setHeaders(newHeaders);
  };

  const handleDevSubmit = () => {
    localStorage.setItem(ENABLE_MOCK_ENV_VAR, devSettings.enableMock);
    if (devSettings.enableMock === "0") {
      localStorage.setItem(APP_API_URI_ENV_VAR, devSettings.backendURI);
    }
    // save headers in localStorage or handle them accordingly
    localStorage.setItem(APP_API_HEADER_ENV_VAR, JSON.stringify(headers));
    localStorage.setItem(
      MAINTENANCE_START,
      `${devSettings.maintenanceModeStart}`
    );
    localStorage.setItem(MAINTENANCE_END, `${devSettings.maintenanceModeEnd}`);
    localStorage.setItem(
      MAINTENANCE_LOCK_START,
      `${devSettings.maintenanceModeLockStart}`
    );
    localStorage.setItem(
      MAINTENANCE_LOCK_END,
      `${devSettings.maintenanceModeLockEnd}`
    );

    // set custom headers from local storage
    setCustomHeaders();

    // refresh the page after the settings are saved
    window.location.reload();
  };

  return {
    devMode,
    devSettings,
    headers,
    handleDevSettingChange,
    handleHeaderChange,
    handleDevSubmit,
  };
};
