import { useDebounce } from "@Hooks";
import type { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useManageApiResponse } from "src/Hooks/http";
import { useUrlParams } from "../useUrlParams";
import { getFetchArguments } from "./getFetchArguments";
import type { PaginateResultFetchArguments } from "./types.i";
import { CYPRESS_RUNNING } from "@Constants";

/* you can add the isp_ prefix (ignore search param) to any search param to avoid re-fetch */
const IGNORED_SEARCH_PARAMS = new RegExp(/[?&](isp_)\w*=[\w\d-]*[&#]?/, "ig");

interface Props<T> {
  defaultFilters: Record<string, string>;
  getResponse: (
    args: PaginateResultFetchArguments
  ) => Promise<AxiosResponse<T>>;
  debounceDelay?: number;
  skipFirstFetch?: boolean;
}

export const usePaginatedResult = <T extends Record<string, any>>({
  defaultFilters,
  getResponse,
  debounceDelay = CYPRESS_RUNNING ? 10 : 500,
  skipFirstFetch = false,
}: Props<T>) => {
  const [needToSkipFirstFetch, setNeedToSkipFirstFetch] =
    useState<boolean>(skipFirstFetch);
  const [axiosResponse, setAxiosResponse] = useState<AxiosResponse<T>>();
  const [loading, setLoading] = useState(!skipFirstFetch);
  const manageResponse = useManageApiResponse(setLoading);
  const urlParams = useUrlParams();
  const { search } = useLocation();
  const debouncedSearch = useDebounce(
    search.replaceAll(IGNORED_SEARCH_PARAMS, "").trim(),
    debounceDelay
  );

  const fetch = useCallback(
    async (force = false) => {
      if (loading && !force) return;

      const searchParams = new URLSearchParams(debouncedSearch);
      Object.entries(defaultFilters).forEach(([key, defaultValue]) => {
        if (!searchParams.has(key)) searchParams.set(key, defaultValue);
      });

      const fetchData = getFetchArguments({
        urlParams,
        searchParams,
      });

      manageResponse({
        errorMessage: "retrieving-data",
        promise: getResponse(fetchData),
        onSuccess: r => {
          setAxiosResponse(r);
        },
        onError: () => {
          setAxiosResponse(undefined);
        },
      });
    },
    [
      debouncedSearch,
      defaultFilters,
      getResponse,
      loading,
      manageResponse,
      urlParams,
    ]
  );

  useEffect(() => {
    if (needToSkipFirstFetch) {
      setNeedToSkipFirstFetch(false);
      return;
    }
    fetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return {
    loading,
    response: axiosResponse?.data,
    fetch: () => fetch(false),
  };
};

export * from "./types.i";
