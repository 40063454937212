import { useArchivedProject } from "@Hooks";
import { AddInjectableParameter } from "./AddInjectableParameter";
import { useInjectableParameters } from "./useInjectableParameters";
import { DisplayRowProjectInjectables, SettingsContainer } from "@Components";
import { useEffect, useMemo } from "react";

export const InjectableParameters = () => {
  const {
    canvasOpen,
    deleteEntry,
    error,
    injectables,
    loading,
    setCanvasOpen,
    resetStore,
  } = useInjectableParameters();

  const archivedProject = useArchivedProject();
  useEffect(() => resetStore, [resetStore]);

  const children = useMemo(() => {
    return archivedProject ? (
      <DisplayRowProjectInjectables injectableEntries={injectables ?? []} />
    ) : (
      <DisplayRowProjectInjectables
        injectableEntries={injectables ?? []}
        deleteEntry={deleteEntry}
        buttonProps={{
          color: "primary",
          children: "common.add",
          iconClass: "bi bi-plus-square-fill",
          onClick: () => setCanvasOpen(true),
          data: { cy: "show-injectable-canvas-button" },
          hiddenForReader: true,
        }}
      />
    );
  }, [archivedProject, deleteEntry, injectables, setCanvasOpen]);

  return (
    <div id="project-injectable-parameters">
      <SettingsContainer
        loading={loading}
        section="injectables"
        hasError={error}
        children={children}
      />
      <AddInjectableParameter
        canvasOpen={canvasOpen}
        setCanvasOpen={setCanvasOpen}
      />
    </div>
  );
};
