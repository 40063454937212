import { t } from "i18next";
import React from "react";

type Props = {
  totalItems: number;
};

export const TotalItemsLabel = React.memo(({ totalItems }: Props) => {
  return <span>{t("paginator.total-items", { N: totalItems })}</span>;
});
