import { useCallback } from "react";
import type { FullAccessModeProps } from "./FullAccessMode.i";
import type { UserUpdateProjectRequest } from "@Services";

export const useFullAccessMode = ({
  canvasController,
  setProject,
  dangerZoneController,
  license,
  project,
}: FullAccessModeProps) => {
  const closeCanvas = useCallback(
    () => canvasController.setCanvasType(undefined),
    [canvasController]
  );

  const updateAndRefresh = useCallback(
    (data: UserUpdateProjectRequest) => setProject(data, true),
    [setProject]
  );

  const updateOnly = useCallback(
    (data: UserUpdateProjectRequest) => setProject(data, false),
    [setProject]
  );

  return {
    dangerZoneController,
    license,
    project,
    canvasController,
    closeCanvas,
    updateAndRefresh,
    updateOnly,
  };
};
