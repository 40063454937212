import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useEffect, useMemo } from "react";
import {
  SCAN_HISTORY_FILTERS_KEY,
  SCAN_HISTORY_PAGE_SIZE,
} from "src/Pages/Scans/constants";
import { useHistoryStore } from "../Store/HistoryStore";

type Criteria = Array<
  | "attempt_method"
  | "attempt_path"
  | "attempt_scope"
  | "attempt_caller"
  | "attempt_content_type"
  | "attempt_status_code"
  | "attempt_resolved"
>;

export type ListedAttemptsParams = [
  string,
  string,
  string,
  number,
  number,
  (
    | "attempt_index"
    | "attempt_started"
    | "attempt_status_code"
    | "attempt_content_length"
    | undefined
  ),
  "asc" | "desc" | undefined,
  Criteria | undefined,
  Array<"LIKE" | "EQ" | "NEQ" | "GE" | "LE"> | undefined,
  Array<string> | undefined,
];

export const useGetHistory = () => {
  const setFilters = useUpdateCacheFilters(SCAN_HISTORY_FILTERS_KEY);
  const defaultFilters = useMemo(() => getDefaultFilters("history"), []);
  const { listedAttempts, setListedAttempts, setLoading } = useHistoryStore();

  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      const apiParams = [
        args.organizationId,
        args.projectId,
        args.scanId,
        args.page,
        SCAN_HISTORY_PAGE_SIZE,
        args.sortBy,
        args.sortMode,
        args.criteria,
        args.operators,
        args.values,
      ];
      return API.attempts().attemptsListInAnonymousProfile(
        ...(apiParams as ListedAttemptsParams)
      );
    }, []),
    debounceDelay: 500,
    skipFirstFetch: !!listedAttempts,
  });

  useEffect(() => {
    if (response?.data) {
      setListedAttempts(response?.data, response.totalItems);
    }
  }, [response, setListedAttempts]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return { setFilters, fetch, defaultFilters };
};
