import { isValid } from "@Services";
import type { Dispatch } from "react";
import { useEffect, useMemo } from "react";
import moment from "moment-timezone";
import type { InviteOrEditUserModel, ValidityState } from "@Interfaces";
import { availableRoles } from "../constants";

export const useFormValidation = (
  formModel: InviteOrEditUserModel,
  setFormValid?: Dispatch<boolean>
) => {
  const { validities, formValid } = useMemo(() => {
    const { email, expiresAt, name, role, surname } = formModel;
    const momentExpiresAt = moment(expiresAt);

    const validities: ValidityState<InviteOrEditUserModel> = {
      active: true,
      name: isValid("name", name.trim()),
      surname: isValid("name", surname.trim()),
      email: isValid("email", email.trim()),
      role: availableRoles.includes(role),
      expiresAt:
        momentExpiresAt.isValid() &&
        moment().isSameOrBefore(momentExpiresAt, "date"),
    };

    return {
      validities,
      formValid: Object.values(validities).every(Boolean),
    };
  }, [formModel]);

  useEffect(() => {
    setFormValid?.(formValid);
  }, [formValid, setFormValid]);

  return { validities, formValid };
};
