import type {
  ListSpaceLinkCriteria,
  ListSpaceLinkSort,
  Nullable,
  Predicate,
} from "@Interfaces";
import type { UserListSpaceLinksResponse, SpaceLink } from "@Services";
import { getDaysBetween } from "@Utils";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getFiltersFromUrlParams, spaceLinks } from "src/Mock";

export const listSpaceLink = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as Record<string, string>;

  if (!organizationId) return res(ctx.status(400), ctx.delay(delay));

  const spaceLinksArray = spaceLinks.get(organizationId);
  const organizationSpaceLinks = spaceLinksArray ? [...spaceLinksArray] : [];

  const requestParams = getFiltersFromUrlParams(req.url.searchParams);

  const { criteria, values } = requestParams;
  filterCollection(organizationSpaceLinks, criteria, values);

  const { sortBy, sortMode } = requestParams;
  sortCollection(organizationSpaceLinks, sortBy, sortMode);

  const { page, size } = requestParams;
  const data = spliceCollection(organizationSpaceLinks, page, size);

  const { length } = organizationSpaceLinks;

  const response: UserListSpaceLinksResponse = {
    data,
    page,
    size,
    totalItems: length,
    totalPages: Math.ceil(length / size),
  };

  return res(ctx.status(200), ctx.json(response), ctx.delay(delay));
};

function sortCollection(
  collection: SpaceLink[],
  reqSortBy: Nullable<string>,
  reqSortMode: Nullable<string>
): void {
  const sortBy = reqSortBy || "space_link_name";
  const sortMode = reqSortMode || "desc";
  const m = sortMode === "desc" ? 1 : -1;
  switch (sortBy as ListSpaceLinkSort) {
    case "space_link_created_at":
      collection?.sort((r1, r2) => {
        return m * r1.createdAt.localeCompare(r2.createdAt);
      });
      break;
    case "space_link_expires_at":
      collection?.sort((r1, r2) => {
        return m * r1.expiresAt.localeCompare(r2.expiresAt);
      });
      break;
    // default sort value
    case "space_link_name":
    default:
      collection?.sort((r1, r2) => {
        return m * r1.name.localeCompare(r2.name);
      });
      break;
  }
}

function filterCollection(
  collection: SpaceLink[],
  criterias: string[],
  values: string[]
): void {
  const predicates: Predicate<SpaceLink>[] = [];

  (criterias as unknown as ListSpaceLinkCriteria[]).forEach((criteria, i) => {
    const value = (values[i] || "").toLowerCase();
    if (!criteria || !value) return;
    switch (criteria) {
      case "space_link_name":
        predicates.push(r => r.name.toLowerCase().includes(value));
        break;
      case "space_link_status":
        predicates.push(r => `${r.active}` === value);
        break;
      case "space_link_connected":
        predicates.push(r => `${r.connected}` === value);
        break;
      case "space_link_expired":
        predicates.push(r => {
          const daysBetween = getDaysBetween(r.expiresAt);
          if (value === "true") {
            return daysBetween <= 0;
          }
          return daysBetween > 0;
        });
        break;
    }
  });

  const filteredCollection = collection.filter(r =>
    predicates.every(p => p(r))
  );

  collection.splice(0, collection.length);
  collection.push(...filteredCollection);
}

function spliceCollection(collection: SpaceLink[], page: number, size: number) {
  return collection;
}
