import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAndCreateScans } from "src/Mock";

export const stopScan = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, scanId: paramsScanId } = req.params;

  const organizationScan = getAndCreateScans(`${organizationId}`);

  if (!organizationScan || !organizationScan.length) {
    return res(ctx.delay(delay), ctx.status(500));
  }

  const scanId = `${paramsScanId}`;

  const scan = organizationScan.find(s => s.id === scanId);

  if (!scan) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  scan.status = "stopping";

  return res(ctx.delay(delay), ctx.status(200));
};
