import type { ListAuditLogsResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, userAuditLogs } from "src/Mock";

export const getUserLogs = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const page = +(req.url.searchParams.get("page") || 1);
  const size = +(req.url.searchParams.get("size") || 10);

  const { userId } = req.params;

  const offset = (page - 1) * size;
  const limit = offset + size;

  const collection = userAuditLogs.get(`${userId}`) ?? [];

  const data = collection.slice(offset, limit);

  const response: ListAuditLogsResponse = {
    data,
    page,
    size,
    totalItems: collection.length,
    totalPages: Math.ceil(collection.length / size),
  };

  return res(ctx.json(response), ctx.status(200), ctx.status(delay));
};
