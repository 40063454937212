import type { Nullable } from "@Interfaces";
import {
  isFilledString,
  type SpaceLink,
  fetchSpaceLinkDetails,
} from "@Services";
import { useState, useCallback, useEffect } from "react";
import { useManageApiResponse } from "../http";
import { useUrlParams } from "../other";
import { t } from "i18next";
import { createCache } from "@Utils";

const spaceLinkCache = createCache<SpaceLink>(50);
const SPACE_LINK_NOT_FOUND: SpaceLink = {
  name: t("errors.deleted-space-link"),
  active: false,
  connected: false,
  createdAt: "",
  expiresAt: "",
  id: "",
} as const;

export const useSpaceLinkDetails = (spaceLinkID: string) => {
  const [spaceLinkDetails, setSpaceLinkDetails] =
    useState<Nullable<SpaceLink>>(null);
  const [fetching, setFetching] = useState(false);
  const { organizationId } = useUrlParams();

  const manage = useManageApiResponse();

  const fetchSpaceLinks = useCallback(async () => {
    if (!isFilledString(spaceLinkID) || !organizationId) {
      setSpaceLinkDetails(null);
      return;
    }

    const cachedData = spaceLinkCache.getData(spaceLinkID);
    if (cachedData) {
      setSpaceLinkDetails({ ...cachedData });
      return;
    }

    setFetching(true);

    const { status } = await manage({
      promise: fetchSpaceLinkDetails(organizationId, spaceLinkID),
      onSuccess: response => {
        const { spaceLink } = response.data;
        spaceLinkCache.push(spaceLinkID, spaceLink);
        setSpaceLinkDetails(spaceLink);
      },
      onError: () => setSpaceLinkDetails(null),
    });

    if (status === 404) {
      setSpaceLinkDetails(SPACE_LINK_NOT_FOUND);
    }

    setFetching(false);
  }, [manage, organizationId, spaceLinkID]);

  useEffect(() => {
    fetchSpaceLinks();
  }, [fetchSpaceLinks]);

  return { spaceLink: spaceLinkDetails, fetching };
};
