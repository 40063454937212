import { Button, Callout, Offcanvas, Section } from "@Components";
import type { DangerZoneProps } from "./DangerZone.i";
import { useDangerZone } from "./useDangerZone";
import { Input } from "reactstrap";
import type { FC } from "react";
import { memo, useMemo } from "react";
import isEqual from "react-fast-compare";

export const DangerZone: FC<DangerZoneProps> = memo(props => {
  const { canvas, info, title } = useDangerZone(props);

  const alerts = useMemo(() => {
    return (
      <>
        {canvas.alerts.map((alert, i) => (
          <Callout
            key={`${i}-${alert.children}`}
            className="mb-3"
            level={alert.level}
            children={alert.children}
          />
        ))}
      </>
    );
  }, [canvas.alerts]);

  return (
    <>
      <Section title="common.danger-zone" className="danger-zone">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <b className="danger-zone-title d-block">{title}</b>
            <span className="danger-zone-card-content">{info}</span>
          </div>
          <div className="ms-3">
            <Button
              color="danger"
              children={title}
              onClick={canvas.openCanvas}
              data-cy="danger-zone-button"
              iconClass="bi bi-archive-fill"
            />
          </div>
        </div>
      </Section>
      <Offcanvas
        id="danger-zone-canvas"
        title={title}
        isOpen={canvas.isOpen}
        toggle={canvas.closeCanvas}
        buttons={[canvas.confirmButton]}
      >
        <h4 className="mb-3 canvas-subject">{canvas.innerTitle}</h4>

        {alerts}

        <p className="mb-4 canvas-description">{canvas.innerContent}</p>
        <p>{canvas.deleteLabel}</p>
        <Input
          id="danger-zone-canvas-input"
          disabled={canvas.loading}
          value={canvas.inputText.value}
          onChange={canvas.inputText.onChange}
          valid={canvas.inputText.valid}
          invalid={canvas.inputText.invalid}
          autoCorrect="off"
        />
      </Offcanvas>
    </>
  );
}, isEqual);
