import type { FC } from "react";
import { memo, useCallback } from "react";
import { DangerZone } from "@Components";
import { API, equals } from "@Services";
import { useUrlParams } from "@Hooks";
import { useNavigateToUserList } from "../../Hooks/useNavigateToUserList";

export type DeleteUserProps = {
  subject: string;
};

export const DeleteUser: FC<DeleteUserProps> = memo(({ subject }) => {
  const { userId, organizationId } = useUrlParams();

  const deletePromise = useCallback(() => {
    if (!userId || !organizationId) {
      throw new Error("Missing parameters");
    }
    return API.role().organizationAdminDeleteRole(userId, organizationId);
  }, [organizationId, userId]);

  const handleOnSuccess = useNavigateToUserList();

  return (
    <DangerZone
      confirmationText={subject}
      promise={deletePromise}
      subject={subject}
      target="user"
      toastErrorMessage="delete-user"
      toastSuccessMessage="delete-user"
      onSuccess={handleOnSuccess}
    />
  );
}, equals);
