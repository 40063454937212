import { InfoBadge } from "@Components";
import "./style.scss";

interface NotificationBadgeProps {
  display: "sup" | "badge";
  type: "new" | "shadow";
  iconClass?: string;
}

export const NotificationBadge = ({
  display,
  type,
  iconClass,
}: NotificationBadgeProps) => {
  const className = "notification-badge";
  return (
    <>
      {display === "sup" && <sup className={className} children={type} />}
      {display === "badge" && (
        <InfoBadge
          className={className}
          children={type}
          iconClass={iconClass}
        />
      )}
    </>
  );
};
