import type { FC } from "react";
import { memo } from "react";
import {
  NewScanButton,
  Paginator,
  ScansFilters,
  ScansTable,
  Section,
} from "@Components";
import type { ScanPageProps } from "./Scans.i";
import { useScanPage } from "./Hooks";

export const ScansPage: FC<ScanPageProps> = memo(props => {
  const {
    forProject,
    loading,
    refreshScans,
    response,
    updateFilters,
    handleScanCreate,
    newScanButtonConfig,
  } = useScanPage(props);

  return (
    <div id="scans">
      <Section removeLowerRadius>
        <div className="d-flex justify-content-between align-items-end">
          <ScansFilters updateFilters={updateFilters} refresh={refreshScans} />
          {forProject && (
            <NewScanButton
              onAPIsuccess={handleScanCreate}
              disabled={newScanButtonConfig.disabled}
              tooltip={newScanButtonConfig.tooltip}
              showSuccessToast
            />
          )}
        </div>
      </Section>
      <ScansTable
        scans={response?.data ?? []}
        loading={loading}
        forProject={forProject}
      />
      <Paginator setFilters={updateFilters} totalItems={response?.totalItems} />
    </div>
  );
});
