import { useCallback, useMemo, useState } from "react";
import { equals, type SpaceLink } from "@Services";
import { useOrganizationSpaceLinkStore } from "@Stores";
import type { SpaceLinkEditProps } from "./SpaceLinkEdit.i";
import { useOrganizationSpaceLinkUpdate } from "@Hooks";
import { useUrlParams } from "@Hooks";
import type { ButtonProps } from "@Components";

const defaultFormData: SpaceLink = {
  active: false,
  connected: false,
  createdAt: "",
  expiresAt: "",
  id: "",
  name: "",
};

export const useSpaceLinkEdit = ({ refresh }: SpaceLinkEditProps) => {
  const { spaceLinkDetails: spaceLink } = useOrganizationSpaceLinkStore();
  const { organizationId } = useUrlParams();

  const [formData, setFormData] = useState<SpaceLink>(defaultFormData);
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const handleOpenCanvas = useCallback(() => {
    if (!spaceLink) return;
    setFormData(structuredClone(spaceLink));
    setCanvasOpen(true);
  }, [spaceLink]);

  const formDirty = useMemo(() => {
    if (!spaceLink) return false;
    return !equals(formData, spaceLink);
  }, [formData, spaceLink]);

  const updateProps = useMemo(
    () => ({
      onSuccess: () => {
        refresh();
        setCanvasOpen(false);
      },
      successMessage: "put-space-link",
      errorMessage: "put-space-link",
    }),
    [refresh]
  );

  const { updateSpaceLink, loading: updatingSpaceLink } =
    useOrganizationSpaceLinkUpdate(updateProps);

  const handleCanvasSubmit = useCallback(() => {
    if (!organizationId) throw new Error("invalid-url-params");
    if (!spaceLink?.id) throw new Error("missing-space-link-id");
    if (!formData) throw new Error("invalid-form-data");
    updateSpaceLink(organizationId, spaceLink.id, {
      active: formData.active,
      name: formData.name,
    });
  }, [formData, organizationId, spaceLink?.id, updateSpaceLink]);

  const canvasButtons = useMemo<ButtonProps[]>(
    () => [
      {
        color: "primary",
        children: "common.save",
        iconClass: "floppy",
        data: { cy: "subtmit-space-link-canvas" },
        onClick: handleCanvasSubmit,
        disabled: !formValid || !formDirty,
        loading: updatingSpaceLink,
      },
    ],
    [formDirty, formValid, handleCanvasSubmit, updatingSpaceLink]
  );

  return {
    canvas: {
      loading: updatingSpaceLink,
      isOpen: canvasOpen,
      open: handleOpenCanvas,
      close: setCanvasOpen,
      buttons: canvasButtons,
    },
    form: {
      data: formData,
      set: setFormData,
      setValid: setFormValid,
    },
  };
};
