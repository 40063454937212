import { joinClasses } from "@Utils";
import { t } from "i18next";

export const OverrideIcon = (props: { className?: string }) => (
  <i
    className={joinClasses(props.className, "text-warning bi bi-info-circle")}
    data-tooltip-id="tooltip"
    data-tooltip-class-name="text-center"
    data-tooltip-content={t(`common.override-project-configuration`)}
  />
);
