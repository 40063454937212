import { useUrlParams } from "@Hooks";
import type { OrganizationId, ProjectId } from "@Interfaces";
import { API } from "@Services";
import { useIssueListStore } from "@Stores";
import type { Dispatch } from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const getOpenIssueCount = (
  oId: OrganizationId,
  pId: ProjectId | undefined,
  setCounter: Dispatch<number | undefined>
) => {
  const promise = pId
    ? API.issue().issueListOpenInProject(oId, pId)
    : API.issue().issueListOpenInOrganization(oId);

  promise
    .then(response => {
      setCounter(response.data.totalItems);
    })
    .catch(() => {
      setCounter(undefined);
    });
};

export const useIssueList = () => {
  const { projectId, organizationId } = useUrlParams();
  const { hash } = useLocation();
  const { reset } = useIssueListStore();

  const [openIssueCount, setOpenIssueCount] = useState<number>();
  const [activeTab, setActiveTab] = useState(() => (hash === "#all" ? 1 : 0));

  useEffect(() => {
    if (organizationId) {
      getOpenIssueCount(organizationId, projectId, setOpenIssueCount);
    }
  }, [organizationId, projectId]);

  useEffect(() => {
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    openIssueCount,
    activeTab,
    setActiveTab,
    forProject: !!projectId,
  };
};
