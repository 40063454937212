import type { MouseEvent, MouseEventHandler } from "react";
import { useCallback, useMemo } from "react";
import type { ButtonProps } from "./Button.i";
import type { PlainObject } from "@Interfaces";

type OnClickType = MouseEventHandler<HTMLButtonElement> | undefined;
type OnClickEvent = MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;

const defaultSize: ButtonProps["size"] = "md";
const defaultType = "button";

export const useButtonCmp = (props: ButtonProps) => {
  const {
    color,
    loading,
    disabled,
    onClick,
    iconClass,
    data,
    outline,
    size,
    type,
  } = props;

  // class-name
  const className = useMemo(() => {
    const _classNames = ["btn", `btn-${size || defaultSize}`];
    // outline and color
    let outlineAndColor = "btn-";
    if (outline) {
      outlineAndColor = `${outlineAndColor}outline-`;
    }
    outlineAndColor = `${outlineAndColor}${color}`;
    _classNames.push(outlineAndColor);
    // forced bg color
    if (loading) {
      _classNames.push("btn-loading");
    }
    return _classNames.join(" ");
  }, [size, color, loading, outline]);

  // on-click
  const _onClick: OnClickType = useCallback(
    (e: OnClickEvent) => {
      if (disabled || loading || !onClick) {
        return () => undefined;
      }
      return onClick(e);
    },
    [disabled, loading, onClick]
  );

  const _iconClass = useMemo(() => {
    if (iconClass) {
      if (iconClass?.includes("bi bi-")) {
        return iconClass;
      }
      return `bi bi-${iconClass}`;
    }
  }, [iconClass]);

  const _data = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const output: PlainObject<string> = {};
    const entries = Object.entries(data);
    entries.forEach(([key, value]) => {
      output[`data-${key}`] = value;
    });
    return output;
  }, [data]);

  // config
  return {
    ...props,
    outline: `${Boolean(props.outline)}`,
    className,
    type: type || defaultType,
    onClick: _onClick,
    disabled: disabled || loading,
    iconClass: _iconClass,
    data: _data,
  };
};
