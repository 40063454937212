import type { ScanStatus } from "@Interfaces";
import type { ListedScan } from "@Services";
import { dateTimeFormat } from "@Services";
import moment from "moment-timezone";

export const formatDate = (str: string) => {
  return dateTimeFormat(moment(str));
};

// Display values in table
const displayWithStatus = [
  "completed",
  "failed",
  "stopped",
] as ScanStatus[] as string[];

export const displayEndDate = ({ status, completedAt }: ListedScan) => {
  return displayWithStatus.includes(status) ? formatDate(completedAt) : "";
};
