import type { ApiKey, ApiKeys, PlainObject, Predicate } from "@Interfaces";
import type { OrganizationAdminAPIKeysListResponse } from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { OrganizationAPIKeys, apiKeys, delay, organizations } from "src/Mock";

export const apiKeyList = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as PlainObject<string>;

  if (organizations.every(o => o.id !== organizationId)) {
    return res(ctx.delay(delay), ctx.json({}), ctx.status(404));
  }
  const sp = req.url.searchParams;

  let source: ApiKeys = apiKeys.filter(e => {
    return OrganizationAPIKeys.find(
      rel => rel.apiKeyId === e.id && rel.organizationId === organizationId
    );
  });

  source = filterCollection(source, sp);
  source = sortCollection(source, sp);

  const page = Number(sp.get("page")) || 1;
  const size = Number(sp.get("size")) || 12;
  const totalItems = source.length;

  const response: OrganizationAdminAPIKeysListResponse = {
    data: source.slice((page - 1) * size, page * size),
    page,
    size,
    totalItems,
    totalPages: Math.ceil(totalItems / size),
  };

  return res(ctx.delay(delay), ctx.json(response), ctx.status(200));
};

function filterCollection(collection: ApiKeys, params: URLSearchParams) {
  // api_key_name, api_key_role_value, api_key_role_status

  const criteria = params.getAll("filter_criteria");
  const values = params.getAll("filter_values");

  const predicated: Array<Predicate<ApiKey>> = [];

  criteria.forEach((c, i) => {
    const v = values[i] ? values[i].toLowerCase() : null;
    if (!v) {
      return;
    } else if (c === "api_key_name") {
      predicated.push(k => k.name.toLowerCase().includes(v));
    } else if (c === "api_key_role_value") {
      predicated.push(k => k.role === v);
    } else if (c === "api_key_role_status") {
      predicated.push(k => (k.active ? "true" : "false") === v);
    }
  });

  return collection.filter(a => predicated.every(p => p(a)));
}

function sortCollection(collection: ApiKeys, params: URLSearchParams) {
  const sortBy = params.get("sort_by") || "api_key_created_at";
  const sortMode = params.get("sort_mode") || "desc";
  const copy = collection.slice();

  copy.sort((a, b) => {
    let result = 0;

    if (sortBy === "api_key_created_at") {
      result = moment(a.createdAt).diff(b.createdAt);
    }
    if (sortBy === "api_key_name") {
      result = b.name.localeCompare(a.name);
    }
    if (sortBy === "api_key_role_status") {
      result = Number(b.active) - Number(a.active);
    }
    if (sortBy === "api_key_role_value") {
      result = a.role.localeCompare(b.role);
    }
    if (sortBy === "api_key_expiration_date") {
      result = moment(a.expiresAt).diff(b.expiresAt);
    }

    return result * (sortMode === "asc" ? 1 : -1);
  });

  return copy;
}
