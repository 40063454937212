import { Button, DisplaySection } from "@Components";
import { useCallback, useMemo, useState } from "react";
import type { InspectorTableItem } from "./InspectorTable.i";
import { type Props } from "./InspectorTable.i";

import type { Nullable } from "@Interfaces";
import "./style.scss";
import { t } from "i18next";
import { BEM } from "@Utils";

export const InspectorTable = ({ configuration }: Props) => {
  const [selectedItem, setSelectedItem] =
    useState<Nullable<InspectorTableItem>>(null);

  const handleClickOnItem = useCallback((item: InspectorTableItem) => {
    setSelectedItem(item);
    item.onClick?.(item);
  }, []);

  const table = useMemo(
    () => (
      <div className="inspector-table">
        <div className={BEM("inspector-table", "body")}>
          {(configuration?.items || []).map((item, index) => (
            <div className={BEM("inspector-table", "row")} key={index}>
              <span>
                <b>{item.label}</b>
              </span>

              {item.jsx}

              <Button
                color="transparent"
                className="open-details"
                iconClass="bi bi-chevron-right"
                onClick={() => handleClickOnItem(item)}
              />
            </div>
          ))}
        </div>
      </div>
    ),
    [configuration?.items, handleClickOnItem]
  );

  const details = useMemo(() => {
    if (!selectedItem) {
      return null;
    }

    const { detailsConfig } = selectedItem;

    return (
      <div className="inspector-details">
        <div
          className={BEM("inspector-details", "title-bar")}
          onClick={() => setSelectedItem(null)}
        >
          <i className="bi bi-chevron-left cursor-pointer" />
          <span>{t("common.back")}</span>
        </div>
        <div className={BEM("inspector-details", "content")}>
          <DisplaySection
            label={detailsConfig?.label ?? selectedItem.label}
            value={detailsConfig?.jsx ?? selectedItem.jsx}
          ></DisplaySection>
        </div>
      </div>
    );
  }, [selectedItem]);

  return selectedItem ? details : table;
};
