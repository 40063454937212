import React, { useCallback, useContext, useMemo, useState } from "react";
import { AuthContext } from "@Contexts";
import { API } from "@Services";
import { type ButtonProps } from "@Components";
import { useManageApiResponse } from "@Hooks";

export const useDisableOtp = (
  setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = useState("");
  const manageResponse = useManageApiResponse(setLoading);

  const onCanvasClose = useCallback(() => {
    setShowOffcanvas(false);
    setOtp("");
  }, [setShowOffcanvas, setOtp]);

  const onClickHandle = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      const { status } = await manageResponse({
        errorMessage: "2fa-disabling",
        promise: API.user().userDisable2fa({ otp }),
        successMessage: "2fa-disabled",
      });
      const success = (status ?? 500) < 300;
      if (!success || !user) return;
      onCanvasClose();
      setUser({
        ...user,
        twoFactorAuthenticationEnabled: false,
      });
    },
    [otp, user, manageResponse, setUser, onCanvasClose]
  );

  const buttons: ButtonProps[] = useMemo(() => {
    return [
      {
        color: "primary",
        onClick: onClickHandle,
        disabled: otp.length !== 6,
        loading: loading,
        children: "common.update",
        iconClass: "bi bi-floppy",
        data: { cy: "submit-btn" },
      },
    ];
  }, [onClickHandle, otp, loading]);

  return {
    otp,
    setOtp,
    onCanvasClose,
    buttons,
  };
};
