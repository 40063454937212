import { capitalizeString, type Injectable } from "@Services";
import { memo, useState } from "react";
import { ConfirmDialog, DisplaySection, TrashButton } from "@Components";
import { joinClasses } from "@Utils";

interface Props {
  injectableEntry: Injectable;
  onDelete?: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

export const InjectablesEntryCard = memo((props: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  return (
    <div
      className={joinClasses(
        "injectables-entry-card editable-card",
        props.className
      )}
    >
      <DisplaySection
        label="common.name"
        value={props.injectableEntry.name}
        className="mb-3"
        truncate
      />
      <DisplaySection
        className="mb-3"
        label="common.location"
        value={capitalizeString(props.injectableEntry.location)}
      />
      <DisplaySection
        label="common.value"
        value={props.injectableEntry.value}
        truncate
      />

      {!!props.onDelete && (
        <div className="card-actions">
          <TrashButton
            disabled={props.disabled}
            loading={props.loading}
            onClick={() => setShowConfirmDialog(true)}
          />
          {showConfirmDialog && (
            <ConfirmDialog
              title="confirmation-dialog.title.delete-configuration"
              description="confirmation-dialog.description.delete-configuration"
              cancelLabel="confirmation-dialog.cancel.delete-configuration"
              confirmLabel="confirmation-dialog.confirm.delete-configuration"
              onCancel={() => setShowConfirmDialog(false)}
              onConfirm={() => {
                setShowConfirmDialog(false);
                props.onDelete?.();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
});
