import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePreference, useUrlParams } from "@Hooks";
import { mapHeaderItemsWithSorts, type SpaceLink } from "@Services";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_SPACE_LINKS } from "@Constants";
import { defaultSorts } from "../../../../../constants";
import { headerItems } from "../tableHeaders";
import type { SpaceLinkTableProps } from "../SpaceLinkTable.i";
import type { ListItemConfigElement } from "@Components";
import { getSpaceLinkTableConfig } from "../tableConfig";
import { useInlineStatusToggle } from "./useInlineStatusToggle";

export const useSpaceLinkTable = ({
  updateFiltersFunction,
  loading,
  spaceLinks,
}: SpaceLinkTableProps) => {
  const { search } = useLocation();
  const { organizationId } = useUrlParams();

  const navigate = useNavigate();

  const [cardVersion, setCardVersion] = usePreference(
    "organization-space-link-card-version"
  );

  const handleSetCardVersion = useCallback(
    (value: boolean) => {
      setCardVersion(value ? "true" : null);
    },
    [setCardVersion]
  );

  const navigateToId = useCallback(
    (id: SpaceLink["id"]) => {
      if (!organizationId) return;
      const dest = generateUrl(ORGANIZATION_PATH_SPACE_LINKS, [organizationId]);
      navigate(`${dest}/${id}`);
    },
    [navigate, organizationId]
  );

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultSorts,
      items: headerItems,
      search,
      updateFunction: updateFiltersFunction,
    });
  }, [search, updateFiltersFunction]);

  const { updateInlineStatus, updatingInlineStatus } = useInlineStatusToggle();
  const config = useMemo<ListItemConfigElement[]>(() => {
    if (!spaceLinks) return [];
    return getSpaceLinkTableConfig({
      spaceLinks,
      navigateToId,
      updateInlineStatus,
      updatingInlineStatus,
    });
  }, [spaceLinks, navigateToId, updateInlineStatus, updatingInlineStatus]);

  return {
    cardVersion: cardVersion === "true",
    setCardVersion: handleSetCardVersion,
    headers,
    config,
    updateFiltersFunction,
    loading,
  };
};
