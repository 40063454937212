import { InjectableParameterForm, Offcanvas } from "@Components";
import type { InjectableCanvasPropsFormProps } from "../InjectableParameterForm.i";
import type { FC } from "react";
import { useGlobalInjectableParameterFormCanvas } from "./useGlobalInjectableParameterFormCanvas";

export const GlobalInjectableParameterFormCanvas: FC<
  InjectableCanvasPropsFormProps
> = props => {
  const {
    close,
    entry,
    isOpen,
    loading,
    setEntry,
    setFormValid,
    submitButtons,
    localInjectables,
  } = useGlobalInjectableParameterFormCanvas(props);
  return (
    <Offcanvas
      id="injectable-parameter-form"
      isOpen={isOpen}
      toggle={close}
      title="common.new-injectable-parameters"
      buttons={submitButtons}
    >
      <InjectableParameterForm
        injectableEntry={entry}
        setInjectableEntry={setEntry}
        disabled={loading}
        setFormValid={setFormValid}
        localInjectables={localInjectables}
      />
    </Offcanvas>
  );
};
