import React from "react";
import type { Props } from "./ScansTable.i";
import { ListItems } from "@Components";
import useScansTable from "./useScansTable";

import "./style.scss";

export const ScansTable = React.memo((props: Props) => {
  const {
    headers,
    loading,
    config,
    cardOrder,
    fullRowIndexes,
    defaultSorts,
    setSorts,
  } = useScansTable(props);

  return (
    <ListItems
      cardVersion={false}
      config={config}
      headers={headers}
      noDataMessage="common.no-scans-found"
      showSkeleton={loading}
      updateCardItemOrder={cardOrder}
      fullRowIndexes={fullRowIndexes}
      className="scans-list-table"
      setSorts={setSorts}
      defaultSorts={defaultSorts}
    />
  );
});
