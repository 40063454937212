import { FullLogo, FullLogoWhite } from "@Assets";
import { NewScanButton } from "@Components";
import { PROJECT_DETAILS_SCANS_URL } from "@Constants";
import { ThemeContext } from "@Contexts";
import { useOrganization, useProject } from "@Hooks";
import { generateUrl } from "@Utils";
import { t } from "i18next";
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurtesyPage } from "../CurtesyPage";

export const NoScans = () => {
  const organization = useOrganization();
  const { theme } = useContext(ThemeContext);
  const { project } = useProject();
  const navigate = useNavigate();

  const handleOnAPISuccess = useCallback(() => {
    // navigate to scans page
    if (!organization?.id || !project?.id) throw new Error("invalid-id");
    const url = generateUrl(PROJECT_DETAILS_SCANS_URL, [
      organization.id,
      project.id,
    ]);
    navigate(url);
  }, [navigate, organization?.id, project?.id]);

  return (
    <CurtesyPage>
      <h1>
        {`${t("project.welcome")} `}
        <img
          src={theme === "dark" ? FullLogoWhite : FullLogo}
          alt="Logo"
          className="logo"
          width={155}
        />
        {"!"}
      </h1>
      <p className="mb-3">
        {t("common.no-scans", {
          organizationName: organization?.name,
        })}
      </p>
      <>
        <NewScanButton onAPIsuccess={handleOnAPISuccess} showSuccessToast />
      </>
    </CurtesyPage>
  );
};
