import type { ValidityState } from "@Interfaces";
import type { FormData } from "../ApiKeyCanvas.i";
import { getFilterAllowedValues, isFilledString } from "@Services";
import moment from "moment-timezone";
import { constants } from "../contants";

export const getValidities = (form: FormData): ValidityState<FormData> => {
  const [minExpiration, maxExpiration] = constants.expireDateRangesInDays.map(
    increment => moment().add(increment, "day")
  );
  return {
    name: isFilledString(form.name),
    role: getFilterAllowedValues("api_key_role").includes(form.role),
    expiresAt: moment(form.expiresAt).isBetween(
      minExpiration,
      maxExpiration,
      "dates",
      "[]"
    ),
  };
};
