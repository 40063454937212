import { BEM } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import React, { memo, useEffect } from "react";
import isEqual from "react-fast-compare";
import type { NewLinkWizardNodeProps } from "./NewParameterLinkNode.i";

export const NewLinkWizardNode: React.FC<NewLinkWizardNodeProps> = memo(
  ({ id: nodeId, data }) => {
    const updateNodeInternals = useUpdateNodeInternals();

    useEffect(() => {
      updateNodeInternals(nodeId);
    }, [data, nodeId, updateNodeInternals]);

    return (
      <div id={nodeId} className={"api-definition-node"}>
        <div className={BEM("api-definition-node", "title")}>
          <span>New link?</span>
        </div>
        <div className={BEM("api-definition-node", "property")}>
          <span>What to doo</span>
          <Handle
            type="target"
            position={Position.Left}
            id={`handle-target-${nodeId}`}
          >
            <i className="bi bi-arrow-right-square-fill" />
          </Handle>
        </div>
      </div>
    );
  },
  isEqual
);
