import type { Options } from "@Interfaces";
import { t } from "i18next";

export const APP_ZOOM_VALUES: Options<number> = [
  {
    label: "50",
    value: 50,
  },
  {
    label: "60",
    value: 60,
  },
  {
    label: "70",
    value: 70,
  },
  {
    label: "80",
    value: 80,
  },
  {
    label: "90",
    value: 90,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "110",
    value: 110,
  },
  {
    label: "120",
    value: 120,
  },
  {
    label: "130",
    value: 130,
  },
  {
    label: "140",
    value: 140,
  },
  {
    label: "150",
    value: 150,
  },
];

export const APP_THEME_VALUES: Options<string> = [
  {
    label: `${t("common.system")} (default)`,
    value: "system",
  },
  {
    label: "Light",
    value: "light",
  },
  {
    label: "Dark",
    value: "dark",
  },
];
