import { FormInput, RefreshListButton } from "@Components";
import type { Props } from "./PageFilters.i";
import { usePageFilters } from "./usePageFilters";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

export const OrganizationProjectFilters = ({
  refresh,
  updateFilters,
}: Props) => {
  const { getValueOf } = usePageFilters();
  return (
    <PageFiltersWrapper>
      <FormInput
        id="project-name-input"
        type="text"
        label="common.name"
        value={getValueOf("project_name")}
        onChange={e => {
          updateFilters({
            project_name: e.target.value,
          });
        }}
      />
      <div className="d-flex align-items-end ms-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
