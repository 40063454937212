import { ApiDefinition as ApiDefinitionComponent } from "@Components";
import { NewProjectContext, NewProjectExtrasContext } from "@Contexts";
import { getAvailableEndpointFromLicense } from "@Services";
import { useContext, useMemo } from "react";

export const ApiDefinition = () => {
  const { project, setProject } = useContext(NewProjectContext);
  const { projectExtras, setProjectExtras } = useContext(
    NewProjectExtrasContext
  );

  const availableEndpoints = useMemo(() => {
    return getAvailableEndpointFromLicense(projectExtras.license);
  }, [projectExtras.license]);

  return (
    <ApiDefinitionComponent
      project={project}
      projectExtras={projectExtras}
      setProject={setProject}
      setProjectExtras={setProjectExtras}
      availableEndpoints={availableEndpoints}
      newProject
    />
  );
};
