import { useOrganizationSpaceLinkUpdate, useUrlParams } from "@Hooks";
import { useCallback } from "react";
import { useOrganizationSpaceLinkStore } from "@Stores";
import type { SpaceLink } from "@Services";

const updateConfig = {
  errorMessage: "put-space-link",
};

export const useInlineStatusToggle = () => {
  const { organizationId } = useUrlParams();
  const { spaceLinkList, setSpaceLinkList } = useOrganizationSpaceLinkStore();

  const { updateSpaceLink, loading: spaceLinkUpdating } =
    useOrganizationSpaceLinkUpdate(updateConfig);

  const handleUpdate = useCallback(
    (spaceLinkId: SpaceLink["id"]) => {
      if (!organizationId || !spaceLinkList) return;
      const index = spaceLinkList.findIndex(r => r.id === spaceLinkId);
      const spaceLink = spaceLinkList[index];
      if (!spaceLink) return;

      const originalSpaceLinkList = [...spaceLinkList];
      const spaceLinkListModified = [...spaceLinkList];
      const modifiedSpceLink = { ...spaceLink, active: !spaceLink.active };
      spaceLinkListModified[index] = modifiedSpceLink;
      setSpaceLinkList(spaceLinkListModified);

      updateSpaceLink(organizationId, spaceLinkId, {
        active: modifiedSpceLink.active,
        name: modifiedSpceLink.name,
      }).then(updated => {
        if (updated) return;
        setSpaceLinkList(originalSpaceLinkList); // on API error, restore original
      });
    },
    [organizationId, spaceLinkList, setSpaceLinkList, updateSpaceLink]
  );

  return {
    updateInlineStatus: handleUpdate,
    updatingInlineStatus: spaceLinkUpdating,
  };
};
