import { joinClasses } from "@Utils";
import type { FC } from "react";
import { useMemo } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Input } from "reactstrap";
import type { ComboBoxProps } from "./ComboBox.i";
import { ComboBoxDropdown } from "./Dropdown";
import "./style.scss";
import useComboBox from "./useComboBox";

export const ComboBox: FC<ComboBoxProps> = ({
  id,
  bsSize = "sm",
  data = [],
  value,
  disabled,
  onChange,
  onBlur,
  valid,
  invalid,
  minInputs = 0,
  role,
  placeholder,
  ...otherProps
}: ComboBoxProps) => {
  const { options, inputValue, showSuggestion, ...actions } = useComboBox({
    data,
    onChange,
    value,
  });
  const DropdownComponent = useMemo(() => {
    const hasOptions = !!options.length;
    const hasMinInputs = value.length >= minInputs;
    const show = showSuggestion && hasOptions && hasMinInputs;
    return (
      <ComboBoxDropdown
        onSelect={actions.handleOptionSelect}
        options={options}
        show={show}
      />
    );
  }, [actions.handleOptionSelect, minInputs, options, showSuggestion, value]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => actions.setShowSuggestion(false)}
    >
      <div className="combo-box position-relative">
        <Input
          type="text"
          bsSize={bsSize}
          value={inputValue}
          id={id}
          name={id}
          autoComplete="off"
          autoCorrect="off"
          role={role ?? "searchbox"}
          valid={valid}
          invalid={invalid}
          disabled={disabled}
          className={joinClasses(
            showSuggestion && "on-focus",
            !!value && "dirty"
          )}
          onMouseDown={() => actions.setShowSuggestion(true)}
          onChange={e => actions.handleInputKeyDown(e.target.value)}
          onBlur={onBlur}
          maxLength={255}
          placeholder={placeholder}
          {...otherProps}
        />
        {DropdownComponent}
      </div>
    </OutsideClickHandler>
  );
};
