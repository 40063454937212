import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { ApiKey, Method } from "@Interfaces";
import type {
  OrganizationAdminCreateAPIKeysRequest as CreateRequestBody,
  OrganizationAdminUpdateAPIKeyRoleRequest as UpdateRequestBody,
} from "@Services";
import { API } from "@Services";
import { isStatusCodeSuccess } from "@Utils";
import { useCallback, useEffect, useState } from "react";

type LoadingStatus = Method | false;

export const useOrganizationApiKey = () => {
  const [apiKey, setApiKey] = useState<ApiKey>();
  const [loading, setLoading] = useState<LoadingStatus>("GET");
  const manageResponse = useManageApiResponse();

  // API args
  const { organizationId, apiKeyId } = useUrlParams();

  const fetch = useCallback(async () => {
    if (!organizationId || !apiKeyId) return false;
    setLoading("GET");
    const apikey = await manageResponse({
      promise: API.apiKeys().organizationAdminGetApiKeyByOrganizationId(
        organizationId,
        apiKeyId
      ),
    }).then(r => r.data);
    setLoading(false);
    setApiKey(apikey);
    return !apikey;
  }, [organizationId, apiKeyId, manageResponse]);

  const create = useCallback(
    async (requestBody: CreateRequestBody) => {
      if (!organizationId) return false;
      setLoading("POST");
      const response = await manageResponse({
        errorMessage: "post-api-key",
        promise: API.apiKeys().organizationAdminTokensCreate(
          organizationId,
          requestBody
        ),
      }).then(r => r.data);
      setLoading(false);
      return response;
    },
    [manageResponse, organizationId]
  );

  const update = useCallback(
    async (body: UpdateRequestBody) => {
      if (!organizationId || !apiKeyId) return false;
      setLoading("PUT");
      const { status } = await manageResponse({
        errorMessage: "put-api-key",
        successMessage: "put-api-key",
        promise: API.apiKeys().organizationAdminUpdateApiKeyRole(
          apiKeyId,
          organizationId,
          body
        ),
      });
      setLoading(false);
      const success = isStatusCodeSuccess(status);
      if (success) {
        fetch();
      }
      return success;
    },
    [organizationId, apiKeyId, manageResponse, fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    apiKey,
    actions: {
      fetch,
      create,
      update,
    },
    loading,
  };
};
