import { t } from "i18next";

export const FailedTab = () => {
  return (
    <div
      className="d-flex w-100 align-items-center h-100"
      id="scan-details-failed-tab"
    >
      <div className="text-center w-100 d-flex align-items-center justify-content-center">
        <i className="bi bi-exclamation-square-fill font-size-h3"></i>{" "}
        <h1 className="m-0 ms-3">{t("common.no-data-scan-failed")}</h1>
      </div>
    </div>
  );
};
