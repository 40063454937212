import {
  Button,
  MTLSCertificateCard,
  ProjectSettingsCardContainer,
  GlobalMTLSCertificateForm,
} from "@Components";
import { useMemo } from "react";
import { Card } from "reactstrap";
import { useMTLSCertificates } from "./useMTLSCertificates";

export const MTLSCertificates = () => {
  const {
    addCertificate,
    closeCanvas,
    openCanvas,
    globalMTLSCertificates,
    isOpen,
    removeCertificate,
  } = useMTLSCertificates();

  const cards = useMemo(() => {
    return globalMTLSCertificates.map(c => (
      <MTLSCertificateCard
        key={c.name}
        mTLSCertificate={c}
        onDelete={() => removeCertificate(c)}
      />
    ));
  }, [removeCertificate, globalMTLSCertificates]);

  return (
    <section id="mtls-certificate">
      <Card className="p-4 border-tl-0">
        <div className="d-flex w-100 justify-content-end">
          <Button
            color="primary"
            children="common.add"
            iconClass="bi bi-plus-square-fill"
            onClick={openCanvas}
            data-cy="show-mtls-certificates-canvas-button"
          />
        </div>
        <ProjectSettingsCardContainer
          cardType="mtls-certificate"
          emptyLabel="project.settings.no-mtls-settings"
        >
          {cards}
        </ProjectSettingsCardContainer>
      </Card>

      <GlobalMTLSCertificateForm
        isOpen={isOpen}
        close={closeCanvas}
        saveEntry={addCertificate}
      />
    </section>
  );
};
