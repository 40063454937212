import { joinClasses } from "@Utils";
import type { ReactElement } from "react";
import "./PageFiltersWrapper.style.scss";

interface Props {
  children: ReactElement[] | ReactElement;
  className?: string;
}

export const PageFiltersWrapper = ({ children, className }: Props) => (
  <form
    className={joinClasses("d-flex", "filters-form", className)}
    onSubmit={e => e.preventDefault()}
    children={children}
  />
);
