import type { OrganizationId, OrganizationSettings } from "@Interfaces";
import { organizationSetting } from "src/Mock";

export const getOrganizationSettings = (organizationId: OrganizationId) => {
  let result: OrganizationSettings | undefined = undefined;

  if (!organizationSetting.has(organizationId)) {
    result = {
      id: `${organizationSetting.size + 1}`,
      organizationID: organizationId,
      advancedSettings: organizationId === "1",
      timezone: "Europe/Rome",
      mandatoryTwoFactorAuthentication: false,
      scope: [
        "https://app.equixly.com",
        "https://api.equixly.com",
        "https://api.equixly.com/*",
        "https://pwnpoint.equixly.com",
        "https://pwnpoint.equixly.com/*",
      ],
    };
    organizationSetting.set(organizationId, result);
  } else {
    result = organizationSetting.get(organizationId) as OrganizationSettings;
  }

  return result;
};
