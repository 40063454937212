import { Button } from "@Components";
import { isFilledString, type AuthenticationSettings } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import { t } from "i18next";
import {
  useCallback,
  useMemo,
  useState,
  type ChangeEvent,
  type FC,
} from "react";
import { FormInput } from "src/Components/Form/FormInput";
import { FormMessage } from "src/Components/Form/FormMessage";
import { EMPTY_STRING_LABEL } from "./shared";

type AdditionalDataModel = {
  key: string;
  value: string;
};

const DEFAULT_ENTRY: AdditionalDataModel = {
  key: "",
  value: "",
};

/**
 * Credential Inputs form component. It update authenticationSettings.credentialInputs on submit
 */
export const AdditionalDataForm: FC = () => {
  const { authenticationSettings, setAuthenticationSettings, loading } =
    useAuthenticationSettingFormStore();

  const [entry, setEntry] = useState(DEFAULT_ENTRY);

  const updateEntry = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;
      setEntry(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const { validities, duplicateKey, valid } = useMemo(() => {
    const duplicateKey = Object.keys(
      authenticationSettings.oAuthData?.additionalData ?? {}
    ).includes(entry.key);
    const validities = {
      key: !duplicateKey && isFilledString(entry.key),
      value: true,
    };
    const valid = Object.values(validities).every(Boolean);
    return { validities, valid, duplicateKey };
  }, [authenticationSettings.oAuthData?.additionalData, entry.key]);

  const handleSubmit = useCallback(() => {
    setAuthenticationSettings(prev => {
      const additionalData =
        structuredClone(prev.oAuthData?.additionalData) ?? {};

      additionalData[entry.key] = entry.value;
      const sortedEntries = Object.entries(additionalData).sort(
        (entryA, entryB) => entryA[0].localeCompare(entryB[0])
      );
      return {
        ...prev,
        oAuthData: {
          ...prev.oAuthData,
          additionalData: Object.fromEntries(sortedEntries),
        },
      } as AuthenticationSettings;
    });
    setEntry(DEFAULT_ENTRY);
  }, [entry, setAuthenticationSettings]);

  return (
    <>
      <div id="additional-data-input-form">
        <FormInput
          id="additional-data-key"
          data-cy="authentication-settings-input-additional-data-key"
          label="project.authentication-and-dictionary.additional-data-key"
          type="text"
          name="key"
          bsSize="sm"
          value={entry.key}
          valid={validities.key}
          invalid={!validities.key}
          onChange={updateEntry}
          disabled={loading}
          required
          autoTrim
        />
        <FormInput
          id="additional-data-value"
          data-cy="authentication-settings-input-additional-data-value"
          label="project.authentication-and-dictionary.additional-data-value"
          type="text"
          name="value"
          bsSize="sm"
          value={entry.value}
          valid={validities.value}
          invalid={!validities.value}
          placeholder={EMPTY_STRING_LABEL}
          onChange={updateEntry}
          disabled={loading}
          autoTrim
        />
        <Button
          color="primary"
          size="sm"
          data-cy="authentication-settings-add-additional-data-button"
          onClick={handleSubmit}
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!valid || loading}
        />
      </div>
      {duplicateKey && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.key") })}
        />
      )}
    </>
  );
};
