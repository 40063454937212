import { NoProjects } from "@Components";
import { Container } from "reactstrap";
import { WithProjects } from "./Components";
import "./style.scss";
import { useProjects } from "./useProjects";

export const ProjectsList = () => {
  const { response, loading, fetch, showCurtesyPage } = useProjects();

  if (loading || (response && !showCurtesyPage)) {
    return (
      <Container fluid id="projects-list">
        <WithProjects fetch={fetch} loading={loading} response={response} />
      </Container>
    );
  }

  if (response && showCurtesyPage) {
    return (
      <Container fluid id="projects-list">
        <NoProjects />
      </Container>
    );
  }

  return null;
};
