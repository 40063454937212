import { ListItems, Paginator } from "@Components";
import type { ListedAttempt } from "@Services";
import { mapHeaderItemsWithSorts } from "@Services";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useScanDetailsStore } from "src/Pages/Scans/Details/Store/ScanDetailsStore";
import { SCAN_HISTORY_PAGE_SIZE } from "src/Pages/Scans/constants";
import { useHistoryStore } from "../../../../Store/HistoryStore";
import { getConfig, getHeaders } from "./functions";
import type { Props } from "./HistoryTable.i";

export const HistoryTable = memo(({ setFilters, defaultFilters }: Props) => {
  const {
    listedAttempts,
    loading,
    totalAttempts,
    selectedListedAttemptIndex,
    setSelectedListedAttempt,
    visibleFilters,
  } = useHistoryStore();
  const { details } = useScanDetailsStore();
  const { search } = useLocation();

  const handleAttemptClick = useCallback(
    (attempt: ListedAttempt) => {
      setSelectedListedAttempt(attempt);
    },
    [setSelectedListedAttempt]
  );

  useEffect(() => {
    const selectedRow = document.querySelector(
      `tbody tr:nth-child(${selectedListedAttemptIndex + 1})`
    );

    if (selectedRow) {
      selectedRow.scrollIntoView({
        inline: "nearest",
        block: "end",
      });
    }
  }, [selectedListedAttemptIndex]);

  const listItems = useMemo(
    () => {
      const headers = mapHeaderItemsWithSorts({
        items: getHeaders(selectedListedAttemptIndex, visibleFilters),
        search,
        updateFunction: setFilters,
        defaults: defaultFilters,
      });

      const config = getConfig(
        listedAttempts ?? [],
        selectedListedAttemptIndex,
        details?.scan.baseURL ?? "",
        visibleFilters,
        handleAttemptClick
      );

      const attemptSelected = selectedListedAttemptIndex > -1;

      return (
        <ListItems
          cardVersion={false}
          fillWidth={attemptSelected}
          config={config}
          headers={headers}
          noDataMessage={"scans.history.no-attempts-found"}
          showSkeleton={loading}
          setSorts={setFilters}
          defaultSorts={defaultFilters}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listedAttempts, loading, selectedListedAttemptIndex, visibleFilters]
  );

  const paginator = useMemo(
    () => {
      const hasSelectedAttempt = selectedListedAttemptIndex >= 0;
      return (
        <Paginator
          setFilters={setFilters}
          totalItems={totalAttempts}
          fixedPageSize={SCAN_HISTORY_PAGE_SIZE}
          align={hasSelectedAttempt ? "center" : "end"}
          paginatorConfig={{
            maxPageButtons: hasSelectedAttempt ? 5 : 9,
          }}
          showItemsPerPage={false}
          showTotalItems={!hasSelectedAttempt}
          className="p-4"
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalAttempts, setFilters, selectedListedAttemptIndex]
  );

  return (
    <div className="history-table-container">
      {listItems}
      {paginator}
    </div>
  );
});
