import { SettingsContainer } from "@Components";
import { useProject } from "@Hooks";
import { useEffect } from "react";
import { useAuthenticationStore } from "./Authentication.store";
import { AuthenticationCards, AuthenticationOffCanvas } from "./Components";

export const Authentication = () => {
  const { reset, loading } = useAuthenticationStore();
  const { project } = useProject();

  useEffect(() => reset, [reset]);

  return (
    <div id="project-authentication">
      <SettingsContainer
        loading={!project}
        section="authentication"
        hasError={!loading && !project}
        children={<AuthenticationCards />}
      />
      <AuthenticationOffCanvas />
    </div>
  );
};
