import { Button } from "@Components";
import { StopScanCanvas } from "./StopScanCanvas";
import { useState } from "react";
import { useActions } from "./useActions";

export const StopScanAction = () => {
  const [showStopScanCanvas, setShowStopScanCanvas] = useState(false);
  const { stopScan } = useActions();
  return (
    <>
      <Button
        hiddenForReader
        color="primary"
        iconClass="bi bi-stop-circle-fill"
        children="common.terminate-scan"
        onClick={() => setShowStopScanCanvas(true)}
        data-cy="stop-scan-button"
      />
      <StopScanCanvas
        isOpen={showStopScanCanvas}
        close={() => setShowStopScanCanvas(false)}
        stopScan={stopScan}
      />
    </>
  );
};
