import { CubesSVG, type TabProps, Tabs } from "@Components";
import { BEM } from "@Utils";
import { memo, useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { SkeletonPage } from "src/Pages/Profile/Components";
import "./style.scss";
import {
  OrganizationGeneralPageGeneralTab,
  OrganizationGeneralPageSettingsTab,
} from "./Tabs";
import useOrganizationGeneral from "./useOrganizationGeneral";

const tabs: Array<TabProps> = [
  {
    label: "common.details",
    iconClass: "bi bi-info-square-fill",
  },
  {
    label: "common.settings",
    iconClass: "bi bi-gear-wide-connected",
  },
];

export const OrganizationGeneralPage = memo(() => {
  const { organization } = useOrganizationGeneral();
  const [activeTab, setActiveTab] = useState<number>(0);

  const generalSection = useMemo(
    () =>
      organization && (
        <div className={BEM("organization-details", "general-info")}>
          <div>
            <h1 className="color-pink m-0">{organization.name}</h1>
            <span>{organization.address}</span>
          </div>
        </div>
      ),
    [organization]
  );

  if (!organization) {
    return <SkeletonPage />;
  }

  return (
    <div id="organization-details" className="organization-details">
      <div className={BEM("organization-details", "wrapper")}>
        {generalSection}
        <Tabs
          className={BEM("organization-details", "tabs")}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
          updateUrl
        />
        <OrganizationGeneralPageGeneralTab active={activeTab === 0} />
        <OrganizationGeneralPageSettingsTab active={activeTab === 1} />
      </div>

      <div className={BEM("organization-details", "background")}>
        <CubesSVG />
      </div>
    </div>
  );
}, isEqual);
