import React from "react";
import { useOtpToken } from "@Hooks";
import { OtpInput } from "@Components";
import { Spinner } from "reactstrap";
import useSendOtp from "./useSendOtp";
import { t } from "i18next";
import { setAxiosAuth } from "@Services";

export const SendOtp = React.memo(() => {
  const { setOtp, loading, error } = useSendOtp();
  const token = useOtpToken();
  if (token) {
    setAxiosAuth(token);
  }

  return (
    <div id="send-otp">
      <h2 className="text-center mt-0 mb-3">{t("login.otp.send-otp-title")}</h2>
      <div className="d-flex align-items-center mt-2">
        <i className="bi-arrow-up-right" />
        <span className="ms-2">{t("login.otp.open-app")}</span>
      </div>
      <div className="d-flex align-items-center mt-2">
        <i className="bi-check-circle" />
        <span className="ms-2">{t("login.otp.step2")}</span>
      </div>
      <div className="mt-3">
        <p className="form-label">{t("login.otp.input-label")}</p>
        <OtpInput setOtp={setOtp} disabled={loading} />
      </div>
      {loading && !error && (
        <div className="text-center mt-3">
          <Spinner />
        </div>
      )}
      {error && (
        <div className="alert alert-danger mt-3 mb-0" role="alert">
          {error}
        </div>
      )}
    </div>
  );
});
