import type { TabsProps } from "@Components";
import { useState } from "react";

const tabs: TabsProps["tabs"] = [
  {
    label: "common.table",
    iconClass: "bi bi-table",
  },
  {
    label: "common.graph",
    iconClass: "bi bi-diagram-3-fill",
  },
];

export const useTabs = () => {
  const [activeTab, setActiveTab] = useState(() => {
    return tabs.findIndex(tab => !tab.disabled);
  });

  return {
    activeTab,
    setActiveTab,
    tabs,
  };
};
