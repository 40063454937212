import { Input } from "reactstrap";
import React, { useEffect } from "react";
import { NewPasswordRules } from "./NewPasswordRules";
import type { Props } from "./PasswordInput.i";
import { stringHasPuncts, stringHasSymbols } from "@Services";

export const PasswordInput = ({
  id,
  name,
  password,
  setPassword,
  setValidPassword,
  autoComplete,
  bsSize,
}: Props) => {
  // checks
  const hasValidLength = password.length >= 8 && password.length <= 64;
  const hasUppercaseLetter = /[A-Z]/.test(password);
  const hasLowercaseLetter = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar =
    stringHasPuncts(password) || stringHasSymbols(password);

  const passwordInvalid =
    !hasValidLength ||
    !hasUppercaseLetter ||
    !hasLowercaseLetter ||
    !hasNumber ||
    !hasSpecialChar;

  useEffect(() => {
    if (undefined !== setValidPassword) {
      setValidPassword(!passwordInvalid);
    }
  }, [passwordInvalid, setValidPassword]);

  return (
    <>
      <NewPasswordRules
        hasValidLength={hasValidLength}
        hasUppercaseLetter={hasUppercaseLetter}
        hasLowercaseLetter={hasLowercaseLetter}
        hasNumber={hasNumber}
        hasSpecialChar={hasSpecialChar}
      />
      <Input
        type="password"
        id={id}
        name={name}
        bsSize={bsSize}
        value={password}
        onChange={event => setPassword(event.target.value)}
        invalid={!!password && passwordInvalid}
        valid={!!password && !passwordInvalid}
        autoComplete={autoComplete}
      />
    </>
  );
};
