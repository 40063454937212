import { Button } from "@Components";
import { equals } from "@Services";
import { t } from "i18next";
import type { FC } from "react";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { CurtesyPage } from "../CurtesyPage";

type ItemNotFoundProps = {
  message: string;
  navigateToList?: VoidFunction;
  redirectTo?: string;
};

export const ItemNotFound: FC<ItemNotFoundProps> = memo(
  ({ message, navigateToList, redirectTo }) => {
    const redirect = useMemo(() => {
      if (redirectTo) {
        return (
          <Link to={redirectTo}>
            <Button
              color="primary"
              children="common.back-list"
              data={{ cy: "back-to-list-button" }}
              iconClass="bi bi-arrow-left-square-fill"
            />
          </Link>
        );
      }
      if (navigateToList) {
        return (
          <Button
            color="primary"
            children="common.back-list"
            onClick={navigateToList}
            data={{ cy: "back-to-list-button" }}
            iconClass="bi bi-arrow-left-square-fill"
          />
        );
      }
      return null;
    }, [redirectTo, navigateToList]);

    return (
      <CurtesyPage>
        <i className="bi bi-emoji-tear-fill font-size-h1 color-gray"></i>
        <h3 className="text-center m-0 mb-3">{t(message)}</h3>
        <>{!!redirect && <div className="mt-3">{redirect}</div>}</>
      </CurtesyPage>
    );
  },
  equals
);
