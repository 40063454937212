import type { Props } from "./Table.i";

export { getThWidth } from "./Components/THead/functions";

export const getClassName = ({
  showSkeleton,
  className,
}: Pick<Props, "showSkeleton" | "className">) => {
  const classNameArray = ["main-table"];
  showSkeleton && classNameArray.push("loading");
  className && classNameArray.push(className);
  return classNameArray.join(" ");
};
