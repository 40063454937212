import type { LicenseName } from "@Interfaces";

type Output = {
  icon: string;
  color: string;
};

const options: { [key in LicenseName]: Output } = {
  MSSP: {
    color: "#2D8AC7",
    icon: "stack",
  },
  Enterprise: {
    color: "#D63384",
    icon: "layers-fill",
  },
  Standard: {
    color: "#A370F7",
    icon: "layers-half",
  },
  Startup: {
    color: "#9DCAE6",
    icon: "layers",
  },
};

export const getLicenseIconAndColor = (licenseName: LicenseName): Output => {
  return options[licenseName];
};
