import { useCallback } from "react";
import { equals } from "@Services";
import { useOrganizationMember } from "@Hooks";
import type { InviteOrEditUserModel } from "@Interfaces";

export const useSaveUserForm = (
  originalData: InviteOrEditUserModel,
  updatedData: InviteOrEditUserModel
) => {
  const { updatePersonalData, updateRole } = useOrganizationMember({
    autoFetch: false,
  });

  const submitHandler = useCallback(async () => {
    const nameUpdated = isPersonalDataChanged(originalData, updatedData);
    const roleUpdated = isRoleDataChanged(originalData, updatedData);
    const promises: Promise<boolean>[] = [];

    if (nameUpdated) {
      promises.push(
        updatePersonalData({
          name: updatedData.name,
          surname: updatedData.surname,
        })
      );
    }

    if (roleUpdated) {
      promises.push(
        updateRole({
          role: updatedData.role,
          active: updatedData.active,
          expiresAt: updatedData.expiresAt,
        })
      );
    }
    return Promise.all(promises);
  }, [originalData, updatePersonalData, updateRole, updatedData]);

  return submitHandler;
};

function isPersonalDataChanged(
  originalData: InviteOrEditUserModel,
  updatedData: InviteOrEditUserModel
) {
  return !equals(
    {
      name: originalData.name,
      surname: originalData.surname,
    },
    {
      name: updatedData.name,
      surname: updatedData.surname,
    }
  );
}

function isRoleDataChanged(
  originalData: InviteOrEditUserModel,
  updatedData: InviteOrEditUserModel
) {
  return !equals(
    {
      active: originalData.active,
      role: originalData.role,
      expirationDate: originalData.expiresAt,
    },
    {
      active: updatedData.active,
      role: updatedData.role,
      expirationDate: updatedData.expiresAt,
    }
  );
}
