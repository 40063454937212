import { DisplayRowProjectInjectables, SettingsContainer } from "@Components";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const InjectableParameters = () => {
  const { loading, injectableParametersSettings } = useSettingsStore();
  return (
    <div id="scan-injectable-parameters" className="p-4">
      <SettingsContainer loading={loading} section="injectables">
        <DisplayRowProjectInjectables
          injectableEntries={injectableParametersSettings.injectables ?? []}
        />
      </SettingsContainer>
    </div>
  );
};
