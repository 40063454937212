export const APIDefinitions = [
  {
    apiVersion: "4.1.0",
    createdAt: new Date("2023-03-15").toISOString(),
    description: "Minor release of the OpenAPI Specification 4.1.0",
    id: "67890",
    revisionVersion: "1.0.9",
    title: "OpenAPI Specification 4.1.0 (Minor)",
    wasPatched: false,
  },
  {
    apiVersion: "4.0.2",
    createdAt: new Date("2022-08-08").toISOString(),
    description: "Patch release of the OpenAPI Specification 4.0.2.",
    id: "43210",
    revisionVersion: "1.0.8",
    title: "OpenAPI Specification 4.0.2 (Patch)",
    wasPatched: true,
  },
  {
    apiVersion: "4.0.1",
    createdAt: new Date("2022-05-20").toISOString(),
    description: "Patch release of the OpenAPI Specification 4.0.1",
    id: "87654",
    revisionVersion: "1.0.7",
    title: "OpenAPI Specification 4.0.1 (Patch)",
    wasPatched: true,
  },
  {
    apiVersion: "4.0",
    createdAt: new Date("2022-01-15").toISOString(),
    description:
      "Major release of the OpenAPI Specification 4.0.  The alteration was strategically made with the primary objective of bolstering and fortifying web security measures. This change intends to significantly enhance the safety protocols, ultimately ensuring a more secure and protected online experience for all users involved.",
    id: "54321",
    revisionVersion: "1.0.6",
    title: "OpenAPI Specification 4.0 (Major)",
    wasPatched: false,
  },
  {
    apiVersion: "3.1.0",
    createdAt: new Date("2021-02-15").toISOString(),
    description: "Minor release of the OpenAPI Specification 3.1.0",
    id: "32432",
    revisionVersion: "1.0.5",
    title: "OpenAPI Specification 3.1.0 (Minor)",
    wasPatched: false,
  },
  {
    apiVersion: "3.0.3",
    createdAt: new Date("2020-02-20").toISOString(),
    description: "Patch release of the OpenAPI Specification 3.0.3",
    id: "54823",
    revisionVersion: "1.0.4",
    title: "OpenAPI Specification 3.0.3 (Patch)",
    wasPatched: true,
  },
  {
    apiVersion: "3.0.2",
    createdAt: new Date("2018-10-08").toISOString(),
    description: "Patch release of the OpenAPI Specification 3.0.2",
    id: "12345",
    revisionVersion: "1.0.3",
    title: "OpenAPI Specification 3.0.2 (Patch)",
    wasPatched: true,
  },
  {
    apiVersion: "3.0.1",
    createdAt: new Date("2017-12-06").toISOString(),
    description: "Patch release of the OpenAPI Specification 3.0.1",
    id: "76543",
    revisionVersion: "1.0.2",
    title: "OpenAPI Specification 3.0.1 (Patch)",
    wasPatched: true,
  },
  {
    apiVersion: "3.0.0",
    createdAt: new Date("2017-07-26").toISOString(),
    description: "Major release of the OpenAPI Specification 3.0.0",
    id: "98765",
    revisionVersion: "1.0.1",
    title: "OpenAPI Specification 3.0.0 (Major)",
    wasPatched: false,
  },
  {
    apiVersion: "2.0",
    createdAt: new Date("2014-09-08").toISOString(),
    description: "Major release of Swagger 2.0",
    id: "23456",
    revisionVersion: "1.0.0",
    title: "Swagger 2.0 (Major)",
    wasPatched: false,
  },
];
