import type { ButtonProps } from "@Components";
import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { ValidityState } from "@Interfaces";
import { API } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { FormData, Props } from "./ApiKeyCanvas.i";
import { getValidities } from "./Functions/getValidities";

const defaults: FormData = {
  name: "",
  expiresAt: "",
  role: "api_organization_admin",
};

export const useApiKeyCanvas = ({
  closeCanvas: propsCloseCanvas,
  onSuccess,
  open,
}: Props) => {
  const { organizationId } = useUrlParams();
  const [formData, setFormData] = useState<FormData>(() => {
    return structuredClone(defaults);
  });
  const [validities, setValidities] = useState<ValidityState<FormData>>();
  const [secretApiKey, setSecretApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const manageResponse = useManageApiResponse(setLoading);

  useEffect(() => {
    const updatedValidities = getValidities(formData);
    setValidities(updatedValidities);
  }, [formData]);

  const formValid = useMemo(
    () => (validities ? Object.values(validities).every(Boolean) : false),
    [validities]
  );

  const submitCanvas = useCallback(() => {
    if (!organizationId) return;
    manageResponse({
      promise: API.apiKeys().organizationAdminTokensCreate(
        organizationId,
        formData
      ),
      errorMessage: "errors.post-api-key",
      onSuccess: r => {
        setSecretApiKey(r.data.apiKey);
        onSuccess();
      },
    });
  }, [formData, manageResponse, onSuccess, organizationId]);

  const buttons = useMemo(() => {
    const result: ButtonProps[] = [];
    if (!secretApiKey) {
      result.push({
        color: "primary",
        children: "common.add",
        disabled: !formValid,
        loading,
        onClick: submitCanvas,
        iconClass: "bi bi-plus-square-fill",
      });
    }
    return result;
  }, [formValid, loading, secretApiKey, submitCanvas]);

  return {
    formData,
    setFormData,
    validities,
    isOpen: open,
    buttons,
    formDisabled: !!secretApiKey,
    secretApiKey,
    //
    closeCanvas: useCallback(() => {
      propsCloseCanvas();
      setFormData({ ...defaults });
      setSecretApiKey("");
    }, [propsCloseCanvas]),
  };
};
