import { FormInput, GridContainer, MethodsComboBox } from "@Components";
import type { Method } from "@Interfaces";
import { useAuthenticationSettingFormStore } from "@Stores";
import { t } from "i18next";
import { set } from "lodash";
import { type ChangeEvent, type FC, useCallback, useState } from "react";
import { FormGroup } from "reactstrap";

const ALLOWED_METHODS: Method[] = ["GET", "POST", "PUT"];

type RefreshDefinitionOperationProps = {
  loading: boolean;
  validities: { [key: string]: boolean };
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const RefreshDefinitionOperation: FC<
  RefreshDefinitionOperationProps
> = ({
  loading,
  validities,
  handleChange,
}: RefreshDefinitionOperationProps) => {
  const { authenticationSettings, setAuthenticationSettings } =
    useAuthenticationSettingFormStore();
  const [methods, setMethods] = useState<Method[]>(
    (authenticationSettings.refreshDefinition?.operation?.methods ??
      []) as Method[]
  );
  const handleMethodsChange = useCallback(
    (methods: Method[]) => {
      setMethods(methods);
      setAuthenticationSettings(prev =>
        set(prev, "refreshDefinition.operation.methods", methods)
      );
    },
    [setAuthenticationSettings]
  );

  return (
    <FormGroup className="mt-3">
      <GridContainer columns={2}>
        <FormInput
          id="operation-path"
          data-cy="authentication-settings-rd-operation-path"
          label="common.path"
          type="text"
          bsSize="sm"
          name="refreshDefinition.operation.path"
          value={
            authenticationSettings.refreshDefinition?.operation?.path ?? ""
          }
          valid={validities.rDOperationPath}
          invalid={!validities.rDOperationPath}
          onChange={handleChange}
          disabled={loading}
          autoTrim
          required
        />
        <div>
          <span className="input-label">{t("common.methods")}</span>
          <MethodsComboBox
            activeMethods={methods}
            setActiveMethods={handleMethodsChange}
            methods={ALLOWED_METHODS}
            disabled={loading}
            required
          />
        </div>
      </GridContainer>
    </FormGroup>
  );
};
