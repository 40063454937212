import { t } from "i18next";
import { getTotalIssueFromSeverityCount, type SeverityCount } from "@Services";
import { DashboardCard, NoVulnerableData } from "../Components";
import { DoughnutChart, DoughnutSeverity } from "@Components";
import { useMemo } from "react";
import type { DashboardCardProps } from "./DashboardCard.i";
import { severityColors } from "@Constants";

export const OpenIssuesCard = ({
  severityCount,
  showNoData,
}: DashboardCardProps<{
  severityCount?: SeverityCount;
}>) => {
  const cardContent = useMemo(() => {
    if (
      showNoData ||
      !severityCount ||
      !getTotalIssueFromSeverityCount(severityCount)
    ) {
      return <NoVulnerableData text="common.no-issues" />;
    }

    const chartData = [
      {
        label: "common.high",
        value: severityCount.high,
        color: severityColors["high"],
      },
      {
        label: "common.medium",
        value: severityCount.medium,
        color: severityColors["medium"],
      },
      {
        label: "common.low",
        value: severityCount.low,
        color: severityColors["low"],
      },
      {
        label: "common.information",
        value: severityCount.information,
        color: severityColors["information"],
      },
    ];

    return (
      <section className="open-issues-container">
        <DoughnutSeverity severityCount={severityCount} />
        <div className="doughnut-container">
          <DoughnutChart data={chartData} />
        </div>
      </section>
    );
  }, [showNoData, severityCount]);

  return (
    <DashboardCard className="titled open-issues">
      <span className="title">{t("common.open-issues")}</span>
      {cardContent}
    </DashboardCard>
  );
};
