import { ListItems } from "@Components";
import type { Props } from "./OrganizationTable.i";
import "./styles.scss";
import useOrganizationTable from "./useOrganizationTable";

export const OrganizationTable = (props: Props) => {
  const {
    cardVersion,
    config,
    setCardVersion,
    loading,
    headers,
    defaultSorts,
    setSorts,
  } = useOrganizationTable(props);
  return (
    <ListItems
      headers={headers}
      striped={true}
      columnDistribution="auto"
      showSkeleton={loading}
      config={config}
      cardVersion={cardVersion}
      setCardVersion={setCardVersion}
      fullRowIndexes={[2]}
      updateCardItemOrder={[0, 3, 1, 2, 4]}
      defaultSorts={defaultSorts}
      setSorts={setSorts}
    />
  );
};
