import { useMemo } from "react";
import type { NewScanButtonProps } from "../NewScanButton.i";
import { usePreference } from "@Hooks";
import { useApiCall } from "./useApiCall";
import { useButtonStatus } from "./useButtonStatus";
import type { ButtonProps } from "@Components";
import { t } from "i18next";

const CACHE_KEY = "project-scan-button-action";

const getButtonDataProps = (message: string | undefined) => {
  const result: Record<string, string> = {
    cy: "new-scan-button",
  };
  if (!message) return result;
  return {
    ...result,
    "tooltip-id": "tooltip",
    "tooltip-place": "left",
    "tooltip-content": t(message),
  };
};

export const useNewScanButton = ({
  tooltip: propsTooltip,
  ...props
}: NewScanButtonProps) => {
  const { setLoading, scanButtonProps, hidden } = useButtonStatus(props);

  const createScanFunction = useApiCall({ ...props, setLoading });

  const buttonBaseProps = useMemo<ButtonProps>(() => {
    const { disabled, loading, tooltip: hookTooltip } = scanButtonProps;
    return {
      color: "primary",
      loading,
      disabled,
      data: getButtonDataProps(hookTooltip || propsTooltip),
    };
  }, [scanButtonProps, propsTooltip]);

  const [scanType, setScanType] = usePreference(CACHE_KEY);
  const initialIndex = scanType === "explore" ? 1 : 0;

  return {
    buttonBaseProps,
    hidden,
    createScan: createScanFunction,
    initialIndex,
    setScanType,
  };
};
