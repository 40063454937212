import { VerticalStepper } from "@Components";
import {
  NewProjectContext,
  NewProjectExtrasContext,
  NewProjectExtrasProvider,
  NewProjectProvider,
  PageContext,
} from "@Contexts";
import { memo, useContext, useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import {
  ApiDefinition,
  BottomBar,
  NameAndLicenses,
  Schedule,
  SecurityChecks,
  Settings,
  Summary,
} from "./Components";
import { Pages, verticalStepperItems } from "./constants";

import { useAuthenticationStore } from "./Components/Settings/Components/Authentication/Authentication.store";
import "./NewProject.style.scss";

export const NewProject = memo(() => {
  return (
    <div id="new-project">
      <VerticalStepper items={verticalStepperItems} />
      <NewProjectProvider>
        <NewProjectExtrasProvider>
          <NewProjectContent />
        </NewProjectExtrasProvider>
      </NewProjectProvider>
    </div>
  );
});

const NewProjectContent = memo(() => {
  const { page, setPage } = useContext(PageContext);
  const { reset: npReset } = useContext(NewProjectContext);
  const { reset: npeReset } = useContext(NewProjectExtrasContext);
  const { reset: resetAuthenticationSettingsStore } = useAuthenticationStore();

  useEffect(() => {
    return () => {
      setPage(Pages.NameAndLicense);

      resetAuthenticationSettingsStore();

      npReset();
      npeReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = useMemo(() => {
    switch (page) {
      case Pages.NameAndLicense:
        return <NameAndLicenses />;
      case Pages.ApiDefinition:
        return <ApiDefinition />;
      case Pages.Settings:
        return <Settings />;
      case Pages.Extensions:
        return <SecurityChecks />;
      case Pages.Schedule:
        return <Schedule />;
      case Pages.Summary:
        return <Summary />;
    }
  }, [page]);

  return (
    <>
      <Container id="new-project-content">{content}</Container>
      <BottomBar />
    </>
  );
});
