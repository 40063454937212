import type { MTLSCertificate } from "@Services";

export const generateRandomMTLSCertificates = (): MTLSCertificate[] => {
  return [
    {
      associatedAuthSetting: "",
      clientCert: `-----BEGIN CERTIFICATE-----
  MIIDWjCCAkKgAwIBAgIJALuVV1TxWZnzMA0GCSqGSIb3DQEBCwUAMEUxCzAJBgNV
  BAYTAkFVMQswCQYDVQQIDAJDQTERMA8GA1UEBwwIQnJpc2JhbmUxEDAOBgNVBAoM
  B0JyaXNDb3JwMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowRTELMAkG
  -----END CERTIFICATE-----`,
      clientKey: `-----BEGIN PRIVATE KEY-----
  MIIEpQIBAAKCAQEAtvWz7SaDtcCNiD9A/8Z7vB2Blb6C6J4ixBdZnxdMJQb8yp5f
  /QGby2pS3Bcb+hbZQF0ZyEprcW/KsYPvK/pn5bBvVB4t2U6kgYK6YQF8ySBLOJkU
  ZMK4qH4wz3Z8oEz2D5IhCqK1m7rFl6luyzZwprmHcRZ3KbG70mG8E5KP4QyRpNMb
  -----END PRIVATE KEY-----`,
      caCert: `-----BEGIN CERTIFICATE-----
  MIIDqDCCApCgAwIBAgIJAO2/UpXOEgVpMA0GCSqGSIb3DQEBCwUAMIGhMQswCQYD
  VQQGEwJVUzELMAkGA1UECBMCQ0ExEzARBgNVBAcTCkNvbGxlZ2UgUGExETAPBgNV
  BAoTCE15IENvbXBhbnkxDDAKBgNVBAsTA1lBUjEbMBkGA1UEAxMSTG9uZyBDb21w
  YW55IFJvb3QgQ0ExJzAlBgkqhkiG9w0BCQEWG2FkbWluQG15Y29tcGFueS5jb20g
  Rm9yIFRlc3RzMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowgYgxCzAJ
  -----END CERTIFICATE-----`,
      name: "User Authentication Certificate",
    },
    {
      associatedAuthSetting: "",
      clientCert: `-----BEGIN CERTIFICATE-----
  MIIDWjCCAkKgAwIBAgIJALuVV1TxWZnzMA0GCSqGSIb3DQEBCwUAMEUxCzAJBgNV
  BAYTAkFVMQswCQYDVQQIDAJDQTERMA8GA1UEBwwIQnJpc2JhbmUxEDAOBgNVBAoM
  B0JyaXNDb3JwMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowRTELMAkG
  -----END CERTIFICATE-----`,
      clientKey: `-----BEGIN PRIVATE KEY-----
  MIIEpQIBAAKCAQEAtvWz7SaDtcCNiD9A/8Z7vB2Blb6C6J4ixBdZnxdMJQb8yp5f
  /QGby2pS3Bcb+hbZQF0ZyEprcW/KsYPvK/pn5bBvVB4t2U6kgYK6YQF8ySBLOJkU
  ZMK4qH4wz3Z8oEz2D5IhCqK1m7rFl6luyzZwprmHcRZ3KbG70mG8E5KP4QyRpNMb
  -----END PRIVATE KEY-----`,
      caCert: `-----BEGIN CERTIFICATE-----
  MIIDqDCCApCgAwIBAgIJAO2/UpXOEgVpMA0GCSqGSIb3DQEBCwUAMIGhMQswCQYD
  VQQGEwJVUzELMAkGA1UECBMCQ0ExEzARBgNVBAcTCkNvbGxlZ2UgUGExETAPBgNV
  BAoTCE15IENvbXBhbnkxDDAKBgNVBAsTA1lBUjEbMBkGA1UEAxMSTG9uZyBDb21w
  YW55IFJvb3QgQ0ExJzAlBgkqhkiG9w0BCQEWG2FkbWluQG15Y29tcGFueS5jb20g
  Rm9yIFRlc3RzMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowgYgxCzAJ
  -----END CERTIFICATE-----`,
      name: "Secure Transaction Certificate",
    },
    {
      associatedAuthSetting: "",
      clientCert: `-----BEGIN CERTIFICATE-----
  MIIDWjCCAkKgAwIBAgIJALuVV1TxWZnzMA0GCSqGSIb3DQEBCwUAMEUxCzAJBgNV
  BAYTAkFVMQswCQYDVQQIDAJDQTERMA8GA1UEBwwIQnJpc2JhbmUxEDAOBgNVBAoM
  B0JyaXNDb3JwMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowRTELMAkG
  -----END CERTIFICATE-----`,
      clientKey: `-----BEGIN PRIVATE KEY-----
  MIIEpQIBAAKCAQEAtvWz7SaDtcCNiD9A/8Z7vB2Blb6C6J4ixBdZnxdMJQb8yp5f
  /QGby2pS3Bcb+hbZQF0ZyEprcW/KsYPvK/pn5bBvVB4t2U6kgYK6YQF8ySBLOJkU
  ZMK4qH4wz3Z8oEz2D5IhCqK1m7rFl6luyzZwprmHcRZ3KbG70mG8E5KP4QyRpNMb
  -----END PRIVATE KEY-----`,
      caCert: `-----BEGIN CERTIFICATE-----
  MIIDqDCCApCgAwIBAgIJAO2/UpXOEgVpMA0GCSqGSIb3DQEBCwUAMIGhMQswCQYD
  VQQGEwJVUzELMAkGA1UECBMCQ0ExEzARBgNVBAcTCkNvbGxlZ2UgUGExETAPBgNV
  BAoTCE15IENvbXBhbnkxDDAKBgNVBAsTA1lBUjEbMBkGA1UEAxMSTG9uZyBDb21w
  YW55IFJvb3QgQ0ExJzAlBgkqhkiG9w0BCQEWG2FkbWluQG15Y29tcGFueS5jb20g
  Rm9yIFRlc3RzMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowgYgxCzAJ
  -----END CERTIFICATE-----`,
      name: "Trusted Connection Certificate",
    },
    {
      associatedAuthSetting: "API JWT",
      clientCert: `-----BEGIN CERTIFICATE-----
  MIIDWjCCAkKgAwIBAgIJALuVV1TxWZnzMA0GCSqGSIb3DQEBCwUAMEUxCzAJBgNV
  BAYTAkFVMQswCQYDVQQIDAJDQTERMA8GA1UEBwwIQnJpc2JhbmUxEDAOBgNVBAoM
  B0JyaXNDb3JwMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowRTELMAkG
  -----END CERTIFICATE-----`,
      clientKey: `-----BEGIN PRIVATE KEY-----
  MIIEpQIBAAKCAQEAtvWz7SaDtcCNiD9A/8Z7vB2Blb6C6J4ixBdZnxdMJQb8yp5f
  /QGby2pS3Bcb+hbZQF0ZyEprcW/KsYPvK/pn5bBvVB4t2U6kgYK6YQF8ySBLOJkU
  ZMK4qH4wz3Z8oEz2D5IhCqK1m7rFl6luyzZwprmHcRZ3KbG70mG8E5KP4QyRpNMb
  -----END PRIVATE KEY-----`,
      caCert: `-----BEGIN CERTIFICATE-----
  MIIDqDCCApCgAwIBAgIJAO2/UpXOEgVpMA0GCSqGSIb3DQEBCwUAMIGhMQswCQYD
  VQQGEwJVUzELMAkGA1UECBMCQ0ExEzARBgNVBAcTCkNvbGxlZ2UgUGExETAPBgNV
  BAoTCE15IENvbXBhbnkxDDAKBgNVBAsTA1lBUjEbMBkGA1UEAxMSTG9uZyBDb21w
  YW55IFJvb3QgQ0ExJzAlBgkqhkiG9w0BCQEWG2FkbWluQG15Y29tcGFueS5jb20g
  Rm9yIFRlc3RzMB4XDTIwMDUwMTAwMDAwMFoXDTMwMDUwMTAwMDAwMFowgYgxCzAJ
  -----END CERTIFICATE-----`,
      name: "Trusted Connection Certificate",
    },
  ];
};
