import { Button } from "@Components";
import type { Nullable } from "@Interfaces";
import { clamp } from "@Services";
import { BEM, joinClasses, pureComponent } from "@Utils";
import { debounce } from "lodash";
import { t } from "i18next";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { isElementFullyVisible } from "src/Utils/Layout/visibility";
import type { TabProps, TabsProps } from "./Tabs.i";
import { getNavigateUrl } from "./functions";
import { useUpdateUrl } from "./useUpdateUrl";
import { useStateRef } from "@Hooks";
import "./Tabs.style.scss";

const DEBOUNCE_DELAY = 100;

type VisibleIndexes = {
  left: number;
  right: number;
};

const TabLoadingSpinner = memo(
  () => <div className="spinner-border spinner-border-sm" role="status" />,
  pureComponent
);

export const Tabs = memo(
  ({
    tabs,
    activeTab,
    updateUrl = false,
    vertical = false,
    setActiveTab,
    className,
  }: TabsProps) => {
    const navContainerRef = useRef<Nullable<HTMLDivElement>>(null);
    const [overflowing, setOverflowing] = useState(false);
    const [visibleIndexes, setVisibleIndexes, visibleIndexesRef] =
      useStateRef<VisibleIndexes>({ left: 0, right: Infinity });

    const overflowingLeft = visibleIndexes.left > 0;
    const overflowingRight =
      tabs.length > 0 && visibleIndexes.right < tabs.length - 1;

    const navigate = useNavigate();
    useUpdateUrl(tabs, updateUrl, selectedTabIndex => {
      scrollTo(selectedTabIndex);
      setActiveTab(selectedTabIndex);
    });

    const handleTabClick = useCallback(
      (
        event: React.MouseEvent<HTMLElement>,
        tab: TabProps,
        tabIndex: number
      ) => {
        if (activeTab === tabIndex || tab.disabled || tab.hidden) return;

        tab.onClick?.(event);

        if (updateUrl) {
          const navigationUrl = getNavigateUrl(tabs[tabIndex].label);
          navigate(navigationUrl, { replace: true });
        }

        setActiveTab(tabIndex);
      },
      [activeTab, navigate, setActiveTab, tabs, updateUrl]
    );

    const tabsTemplate = useMemo(
      () =>
        tabs.map((tab, i) => {
          const slug = tab.label
            .split(".")
            .slice(1)
            .join("-")
            .toLowerCase()
            .replace(/\W/g, "-");
          return (
            <NavItem
              key={i}
              onClick={event => handleTabClick(event, tab, i)}
              data-active={activeTab === i || undefined}
              data-disabled={tab.disabled || undefined}
              data-hidden={tab.hidden || undefined}
              data-index={i}
              data-cy={tab.hidden ? undefined : slug}
            >
              {tab.hidden ? null : (
                <>
                  {tab.iconClass && <i className={`${tab.iconClass} me-2`} />}
                  <span className="label">{t(tab.label)}</span>
                  {Number.isInteger(tab.counter) && (
                    <span className="counter">
                      {tab.loading ? <TabLoadingSpinner /> : tab.counter}
                    </span>
                  )}
                </>
              )}
            </NavItem>
          );
        }),
      [activeTab, handleTabClick, tabs]
    );

    const updateVisibleIndexes = useCallback(() => {
      const container = navContainerRef.current;
      if (!container) return;

      const { children } = container;
      const nextIndexes: VisibleIndexes = {
        left: 0,
        right: children.length - 1,
      };

      for (let childIndex = 0; childIndex < children.length; childIndex++) {
        if (
          isElementFullyVisible(children[childIndex] as HTMLElement, container)
        ) {
          nextIndexes.left = childIndex;
          break;
        }
      }

      for (
        let childIndex = children.length - 1;
        childIndex >= 0;
        childIndex--
      ) {
        if (
          isElementFullyVisible(children[childIndex] as HTMLElement, container)
        ) {
          nextIndexes.right = childIndex;
          break;
        }
      }

      setVisibleIndexes(nextIndexes);
    }, [setVisibleIndexes]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleContainerScroll = useCallback(
      debounce(updateVisibleIndexes, DEBOUNCE_DELAY),
      [updateVisibleIndexes]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleWindowResize = useCallback(
      debounce(() => {
        if (vertical) {
          return;
        }

        const container = navContainerRef.current;
        if (!container) return;

        setOverflowing(container.clientWidth < container.scrollWidth);
        handleContainerScroll();
        setTimeout(() => scrollTo(activeTab), 10);
      }, DEBOUNCE_DELAY),
      [activeTab, handleContainerScroll, vertical]
    );

    const scrollTo = useCallback(
      (childIndex: number, inline: ScrollLogicalPosition = "center") => {
        const container = navContainerRef.current;
        if (!container) return;
        const { children } = container;

        childIndex = clamp(childIndex, 0, children.length - 1);
        if (!children[childIndex]) {
          return;
        }
        children[childIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline,
        });
      },
      []
    );

    const handleScrollToRightmost = useCallback(() => {
      scrollTo(visibleIndexesRef.current.right + 1, "end");
    }, [scrollTo, visibleIndexesRef]);

    const handleScrollToLeftMost = useCallback(() => {
      scrollTo(visibleIndexesRef.current.left - 1, "start");
    }, [scrollTo, visibleIndexesRef]);

    useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      handleWindowResize();
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, [handleWindowResize]);

    useEffect(() => {
      updateVisibleIndexes();
    }, [setVisibleIndexes, tabs.length, updateVisibleIndexes]);

    return (
      <Nav
        tabs
        className={joinClasses(
          vertical && "vertical",
          overflowing && "nav-tabs--overflowing",
          className
        )}
      >
        {overflowing && (
          <Button
            data-cy="nav-tabs-scroll-button"
            className={BEM("nav-tabs", "scroll-button", "left")}
            iconClass="bi bi-chevron-left"
            color="transparent"
            disabled={!overflowingLeft}
            onClick={handleScrollToLeftMost}
          />
        )}
        <div
          className={BEM("nav-tabs", "items-container")}
          ref={navContainerRef}
          onScroll={handleContainerScroll}
        >
          {tabsTemplate}
        </div>
        {overflowing && (
          <Button
            data-cy="nav-tabs-scroll-button"
            className={BEM("nav-tabs", "scroll-button", "right")}
            iconClass="bi bi-chevron-right"
            color="transparent"
            disabled={!overflowingRight}
            onClick={handleScrollToRightmost}
          />
        )}
      </Nav>
    );
  }
);

export default Tabs;
