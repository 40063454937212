import React from "react";
import { LoginBg } from "@Assets";

import "./style.scss";
import { t } from "i18next";

export const MobileVersion = React.memo(() => (
  <div id="no-responsive">
    <div className="card">
      <h1>{t("no-responsive.title")}</h1>
      <p>{t("no-responsive.description")}</p>
    </div>
    <img src={LoginBg} className="logo-bg" alt="Background Logo" />
  </div>
));
