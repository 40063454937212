"use client";

import { LoadingApplication, Meta, Template } from "@Components";
import { AuthProvider, OrganizationProvider, ProjectProvider } from "@Contexts";
import type { Nullable } from "@Interfaces";
import { ErrorPage, NotFound } from "@Pages";
import React, { memo, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useMatches } from "react-router-dom";
import type { Match } from "../Router/Router.i";
import type { AccessRolesProps, PageProps } from "./AuthWrapper.i";
import {
  useAuthGuards,
  usePopulateAuthContext,
  useValidateToken,
} from "./hooks";
import { useNavigationHistory } from "./useNavigationHistory";
import { useProject, useRbac } from "@Hooks";
import ResponseInterceptor from "./ResponseInterceptor";
import { UIManager } from "@Managers";

const AuthContent: React.FC<PageProps & { permits: AccessRolesProps }> = ({
  element,
  pageName,
  permits,
}) => {
  useNavigationHistory();

  const validToken = useValidateToken();
  const loadingAuth = usePopulateAuthContext(validToken);
  const validAuth = useAuthGuards(permits);
  const { canAccess, loading: rbacLoading } = useRbac(permits.accessControl);
  const { notFound: projectNotFound } = useProject();

  if (!validToken) {
    return null; // will be redirect to login
  }
  if (loadingAuth || rbacLoading) {
    return <LoadingApplication />;
  }
  if (!validAuth || !canAccess || projectNotFound) {
    return <NotFound />;
  }

  return (
    <>
      <Meta pageName={pageName} />
      <Template type="main">{element}</Template>
      <UIManager />
    </>
  );
};

export const AuthWrapper: React.FC<PageProps> = memo(props => {
  const matches = useMatches() as Match[];

  const [accessControl, pageName] = useMemo(() => {
    let pageNameResult: Nullable<string> = null;
    let accessControlResult: string | undefined;

    for (const match of matches) {
      if (!match.handle) {
        continue;
      }
      const { accessControl, pageName } = match.handle;
      pageNameResult = pageName ?? null;
      accessControlResult ??= accessControl;
    }

    return [accessControlResult, pageNameResult];
  }, [matches]);

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => <ErrorPage error={error} />}
    >
      <AuthProvider>
        <ResponseInterceptor />
        <OrganizationProvider>
          <ProjectProvider>
            <AuthContent
              {...props}
              pageName={pageName || props.pageName}
              permits={{
                accessControl,
              }}
            />
          </ProjectProvider>
        </OrganizationProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
});
