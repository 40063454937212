import { DisplayTable, EmptyStringLabel } from "@Components";
import type { AuthenticationSettings } from "@Services";
import { memo } from "react";

export const AdditionalDataTab = memo(
  ({ oAuthData }: AuthenticationSettings) => {
    return (
      <DisplayTable>
        <tbody>
          {Object.entries(oAuthData?.additionalData ?? {}).map(
            ([key, value]) => (
              <tr key={key + value}>
                <td className={`text-end`.trimEnd()}>{key}</td>
                <td>{value || <EmptyStringLabel />}</td>
              </tr>
            )
          )}
        </tbody>
      </DisplayTable>
    );
  }
);
