import { DropdownButtons } from "@Components";
import type { NewScanButtonProps } from "./NewScanButton.i";
import { useMemo, type FC } from "react";
import { useNewScanButton } from "./Hooks/useNewScanButtons";

export const NewScanButton: FC<NewScanButtonProps> = props => {
  const { buttonBaseProps, createScan, hidden, initialIndex, setScanType } =
    useNewScanButton(props);

  const buttons = useMemo(
    () => [
      {
        ...buttonBaseProps,
        children: "common.exploit-api",
        onSelect: () => setScanType("exploit"),
        onClick: () => createScan({ exploitation: true }),
      },
      {
        ...buttonBaseProps,
        children: "common.explore-api",
        onSelect: () => setScanType("explore"),
        onClick: () => createScan({ exploitation: false }),
      },
    ],
    [buttonBaseProps, createScan, setScanType]
  );

  if (hidden) return null;

  return <DropdownButtons initialIndex={initialIndex} buttons={buttons} />;
};
