import { Tabs } from "@Components";
import { useProject } from "@Hooks";
import { memo, useCallback, useMemo, useState } from "react";
import { Card } from "reactstrap";
import { APIDefinitionDetailsActions } from "./Components/APIDefinitionDetailsActions";
import { OffCanvasAddParameterLink } from "./Components/OffCanvasAddParameterLink/OffCanvasAddParameterLink";
import { useAPIDefinitionDetails } from "./Hooks/useAPIDefinitionDetails";
import { useAPIDefinitionDetailsTabs } from "./Hooks/useAPIDefinitionDetailsTabs";
import { useAPIDefinitionDetailsStore } from "./Store/APIDefinitionDetailsStore";
import "./style.scss";
import {
  APIDefinitionGeneralTab,
  APIDefinitionParameterLinksTab,
} from "./Tabs";

export const APIDefinitionDetails = memo(() => {
  const { activeTab, setActiveTab, tabs } = useAPIDefinitionDetailsTabs();
  const { isLatestAPIDefinition } = useAPIDefinitionDetailsStore();
  const { projectStatus } = useProject();
  const canAddParameterLinks =
    isLatestAPIDefinition && projectStatus === "ready";
  const [openAddParameterLink, setOpenAddParameterLink] = useState(false);
  useAPIDefinitionDetails();

  const handleOnAddParameterLink = useCallback(() => {
    if (!canAddParameterLinks) {
      return;
    }
    setOpenAddParameterLink(true);
  }, [canAddParameterLinks]);

  const handleAddParametersLinkCanvasClose = useCallback(() => {
    if (projectStatus !== "ready") {
      return;
    }
    setOpenAddParameterLink(false);
  }, [projectStatus]);

  const content = useMemo(() => {
    switch (activeTab) {
      case 1:
        return <APIDefinitionParameterLinksTab />;
      default:
        return <APIDefinitionGeneralTab />;
    }
  }, [activeTab]);

  return (
    <>
      <div id="api-definition-details">
        <APIDefinitionDetailsActions
          onAddParameterLink={handleOnAddParameterLink}
        />
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
          updateUrl={true}
        />
        <Card className="p-4 border-tl-0 w-100 h-100">{content}</Card>
      </div>

      {openAddParameterLink && (
        <OffCanvasAddParameterLink
          onClose={handleAddParametersLinkCanvasClose}
        />
      )}
    </>
  );
});
