/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrganizationAdminGetUserRoleResponse } from '../model';
// @ts-ignore
import { OrganizationAdminUpdateUserRoleRequest } from '../model';
// @ts-ignore
import { UserGetRoleResponse } from '../model';
// @ts-ignore
import { UserListRolesResponse } from '../model';
/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the roles of a user in a specific organization
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminDeleteRole: async (userId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminDeleteRole', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminDeleteRole', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the roles assigned to a user in the organizations of the requester organization_admin
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetRole: async (userId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminGetRole', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetRole', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a role value for a user
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateUserRoleRequest} requestBody OrganizationAdminUpdateUserRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateRoleValue: async (userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminUpdateRoleValue', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminUpdateRoleValue', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminUpdateRoleValue', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the role of the user in the organization
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetRole: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('userGetRole', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/roles`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the active and not expired roles of the user
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'role_value' | 'role_active' | 'role_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'role_value'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListRoles: async (page?: number, size?: number, sortBy?: 'role_value' | 'role_active' | 'role_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'role_value'>, filterOperators?: Array<'EQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the roles of a user in a specific organization
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminDeleteRole(userId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminDeleteRole(userId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the roles assigned to a user in the organizations of the requester organization_admin
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetRole(userId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminGetUserRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetRole(userId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a role value for a user
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateUserRoleRequest} requestBody OrganizationAdminUpdateUserRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminUpdateRoleValue(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminUpdateRoleValue(userId, organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the role of the user in the organization
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetRole(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetRole(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the active and not expired roles of the user
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'role_value' | 'role_active' | 'role_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'role_value'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userListRoles(page?: number, size?: number, sortBy?: 'role_value' | 'role_active' | 'role_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'role_value'>, filterOperators?: Array<'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userListRoles(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * Deletes the roles of a user in a specific organization
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminDeleteRole(userId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminDeleteRole(userId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the roles assigned to a user in the organizations of the requester organization_admin
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetRole(userId: string, organizationId: string, options?: any): AxiosPromise<OrganizationAdminGetUserRoleResponse> {
            return localVarFp.organizationAdminGetRole(userId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a role value for a user
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateUserRoleRequest} requestBody OrganizationAdminUpdateUserRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateRoleValue(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminUpdateRoleValue(userId, organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the role of the user in the organization
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetRole(organizationId: string, options?: any): AxiosPromise<UserGetRoleResponse> {
            return localVarFp.userGetRole(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the active and not expired roles of the user
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'role_value' | 'role_active' | 'role_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'role_value'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListRoles(page?: number, size?: number, sortBy?: 'role_value' | 'role_active' | 'role_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'role_value'>, filterOperators?: Array<'EQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListRolesResponse> {
            return localVarFp.userListRoles(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - interface
 * @export
 * @interface RoleApi
 */
export interface RoleApiInterface {
    /**
     * Deletes the roles of a user in a specific organization
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    organizationAdminDeleteRole(userId: string, organizationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Gets the roles assigned to a user in the organizations of the requester organization_admin
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    organizationAdminGetRole(userId: string, organizationId: string, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminGetUserRoleResponse>;

    /**
     * Updates a role value for a user
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateUserRoleRequest} requestBody OrganizationAdminUpdateUserRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    organizationAdminUpdateRoleValue(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserRoleRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the role of the user in the organization
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    userGetRole(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetRoleResponse>;

    /**
     * Returns the active and not expired roles of the user
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'role_value' | 'role_active' | 'role_expires_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'role_value'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    userListRoles(page?: number, size?: number, sortBy?: 'role_value' | 'role_active' | 'role_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'role_value'>, filterOperators?: Array<'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListRolesResponse>;

}

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI implements RoleApiInterface {
    /**
     * Deletes the roles of a user in a specific organization
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public organizationAdminDeleteRole(userId: string, organizationId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).organizationAdminDeleteRole(userId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the roles assigned to a user in the organizations of the requester organization_admin
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public organizationAdminGetRole(userId: string, organizationId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).organizationAdminGetRole(userId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a role value for a user
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateUserRoleRequest} requestBody OrganizationAdminUpdateUserRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public organizationAdminUpdateRoleValue(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserRoleRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).organizationAdminUpdateRoleValue(userId, organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the role of the user in the organization
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public userGetRole(organizationId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).userGetRole(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the active and not expired roles of the user
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'role_value' | 'role_active' | 'role_expires_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'role_value'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public userListRoles(page?: number, size?: number, sortBy?: 'role_value' | 'role_active' | 'role_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'role_value'>, filterOperators?: Array<'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).userListRoles(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
