import { type Props } from "./Paginator.i";
import { NavigationBar, PageSizeSelector, TotalItemsLabel } from "./Components";
import usePaginator from "./usePaginator";
import { memo, useMemo } from "react";

import "./style.scss";

export const Paginator = memo((props: Props) => {
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    show,
    totalItems,
    totalPages,
    align,
    onBottom,
    pageSizes,
    showItemsPerPage,
    showTotalItems,
  } = usePaginator(props);

  const className = useMemo(() => {
    const result = ["paginator", `justify-content-${align}`, props.className];
    onBottom && result.push("on-bottom");
    return result.join(" ");
  }, [align, onBottom, props.className]);

  return (
    <>
      {show && (
        <div className={className}>
          {showTotalItems && (
            <div className="me-3">
              <TotalItemsLabel totalItems={totalItems} />
            </div>
          )}
          <div className="paginator-buttons">
            <NavigationBar
              page={page}
              totalPages={totalPages}
              setPage={setPage}
              {...props.paginatorConfig}
            />
          </div>
          {showItemsPerPage && totalItems > 0 && (
            <div className="ms-3">
              <PageSizeSelector
                pageSize={pageSize}
                pageSizes={pageSizes}
                setPageSize={setPageSize}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
});
