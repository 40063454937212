/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserGetAttemptResponse } from '../model';
// @ts-ignore
import { UserListAttemptsResponse } from '../model';
/**
 * AttemptApi - axios parameter creator
 * @export
 */
export const AttemptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the HTTP attempt with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {string} attemptId Attempt ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptGet: async (organizationId: string, projectId: string, scanId: string, attemptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('attemptGet', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('attemptGet', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('attemptGet', 'scanId', scanId)
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('attemptGet', 'attemptId', attemptId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/attempts/{attempt_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)))
                .replace(`{${"attempt_id"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the HTTP attempts in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptsListInAnonymousProfile: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('attemptsListInAnonymousProfile', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('attemptsListInAnonymousProfile', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('attemptsListInAnonymousProfile', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/attempts`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttemptApi - functional programming interface
 * @export
 */
export const AttemptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttemptApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the HTTP attempt with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {string} attemptId Attempt ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attemptGet(organizationId: string, projectId: string, scanId: string, attemptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetAttemptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attemptGet(organizationId, projectId, scanId, attemptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the HTTP attempts in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attemptsListInAnonymousProfile(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListAttemptsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attemptsListInAnonymousProfile(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttemptApi - factory interface
 * @export
 */
export const AttemptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttemptApiFp(configuration)
    return {
        /**
         * Returns the HTTP attempt with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {string} attemptId Attempt ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptGet(organizationId: string, projectId: string, scanId: string, attemptId: string, options?: any): AxiosPromise<UserGetAttemptResponse> {
            return localVarFp.attemptGet(organizationId, projectId, scanId, attemptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the HTTP attempts in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attemptsListInAnonymousProfile(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListAttemptsResponse> {
            return localVarFp.attemptsListInAnonymousProfile(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttemptApi - interface
 * @export
 * @interface AttemptApi
 */
export interface AttemptApiInterface {
    /**
     * Returns the HTTP attempt with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {string} attemptId Attempt ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttemptApiInterface
     */
    attemptGet(organizationId: string, projectId: string, scanId: string, attemptId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetAttemptResponse>;

    /**
     * Returns the HTTP attempts in the scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttemptApiInterface
     */
    attemptsListInAnonymousProfile(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListAttemptsResponse>;

}

/**
 * AttemptApi - object-oriented interface
 * @export
 * @class AttemptApi
 * @extends {BaseAPI}
 */
export class AttemptApi extends BaseAPI implements AttemptApiInterface {
    /**
     * Returns the HTTP attempt with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {string} attemptId Attempt ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttemptApi
     */
    public attemptGet(organizationId: string, projectId: string, scanId: string, attemptId: string, options?: AxiosRequestConfig) {
        return AttemptApiFp(this.configuration).attemptGet(organizationId, projectId, scanId, attemptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the HTTP attempts in the scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttemptApi
     */
    public attemptsListInAnonymousProfile(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'attempt_index' | 'attempt_started' | 'attempt_status_code' | 'attempt_content_length' | 'attempt_sequence_auth_profile_id', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'attempt_method' | 'attempt_path' | 'attempt_scope' | 'attempt_caller' | 'attempt_content_type' | 'attempt_status_code' | 'attempt_resolved' | 'attempt_sequence_auth_profile_id'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return AttemptApiFp(this.configuration).attemptsListInAnonymousProfile(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
