import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, generateRandomToken } from "src/Mock";

export const refreshToken = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  return res(
    ctx.delay(delay),
    ctx.json({
      token: generateRandomToken(),
    })
  );
};
