import { useResponsive } from "@Hooks";
import { cardHeight, gap } from "./constants";

export const ExtensionsLoader = () => {
  const lgOrLower = useResponsive("lg");
  const size = lgOrLower ? 12 : 6;
  return (
    <>
      {Array.from({ length: 6 }, (_, i) => (
        <LoaderCard row={i} size={size} key={i} />
      ))}
    </>
  );
};

const LoaderCard = ({ row, size }: { row: number; size: 6 | 12 }) => {
  const customCardHeight = cardHeight / 3;
  const y = row * (gap + customCardHeight);
  const width = {
    6: "calc(50% - 10px)",
    12: `100%`,
  }[size];
  return (
    <>
      <rect x={0} y={y} rx="3" ry="3" width={width} height={customCardHeight} />
      {size === 6 && (
        <rect
          x={`calc(50% + 10px)`}
          y={y}
          rx="3"
          ry="3"
          width={width}
          height={customCardHeight}
        />
      )}
    </>
  );
};
