import type { ApiKey } from "@Interfaces";
import moment from "moment-timezone";
import { getRandomBetweenMinMax } from "../helpers";
import { OrganizationAPIKeys } from "./relations";
import { APIKEY_ROLES } from "@Services";
import { CYPRESS_RUNNING } from "@Constants";

export const apiKeys = CYPRESS_RUNNING
  ? mockApiKeyForCypress()
  : mockApiKeysForDevelopment();

//

function mockApiKeysForDevelopment() {
  const apiKeyNames = [
    "Repository - Deploy PROD",
    "Repository - Deploy GOLD",
    "Repository - Deploy TEST",
    "Repository - Deploy DEVELOP",
    "Backup - Database PROD",
    "Backup - Database GOLD",
    "Backup - Database TEST",
    "Backup - Database DEVELOP",
    "API Remote Access Admin",
    "API Remote Access Supervisors",
    "API Remote Access Users",
    "Organization - Last Scan Result",
    "Organization - Common Vulnerability",
    "Organization - Status",
    "Organization - Real Time Check",
  ];

  return apiKeyNames.map((name, i) => {
    const isExpired = Math.random() < 0.1;
    const isActive = Math.random() < 0.6;
    // expired and active are not related

    const expirationDate = moment();
    if (isExpired) {
      expirationDate.subtract(getRandomBetweenMinMax(24, 240), "hours");
    } else if (Math.random() < 0.95) {
      expirationDate.add(getRandomBetweenMinMax(600, 3000), "hours");
    }

    const apiKeyId = `${apiKeyNames.length - i}`;

    const apiKey: ApiKey = {
      active: isActive,
      createdAt: moment()
        .subtract(getRandomBetweenMinMax(600, 3000), "hours")
        .toISOString(),
      expiresAt: expirationDate.toISOString(),
      id: apiKeyId,
      name,
      role: APIKEY_ROLES[Math.floor(Math.random() * 3)].value,
    };

    OrganizationAPIKeys.push({ organizationId: "1", apiKeyId });
    return apiKey;
  });
}

function mockApiKeyForCypress() {
  return APIKEY_ROLES.map((role, index) => {
    const isExpired = role.value === "api_security_reader";

    const createdAt = moment();
    const expirationDate = moment().add(90, "days");

    if (isExpired) {
      createdAt.subtract(180, "days");
      expirationDate.subtract(180, "days");
    }

    const apiKey: ApiKey = {
      active: index % 2 === 1,
      createdAt: createdAt.toISOString(),
      expiresAt: expirationDate.toISOString(),
      id: `${index + 1}`,
      name: `${role.label.toUpperCase()} API KEY`.replaceAll(/\W/g, "_"),
      role: role.value,
    };
    OrganizationAPIKeys.push({ organizationId: "1", apiKeyId: apiKey.id });
    return apiKey;
  });
}
