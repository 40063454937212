// --------------------------
// - scans
// --------------------------

import type { OrganizationId } from "@Interfaces";
import type { ListedScan } from "@Services";

export const scans = new Map<OrganizationId, ListedScan[]>();
export const MOCKED_SCANS = [
  {
    completedAt: "",
    id: "1-100000",
    severityCount: {
      high: 0,
      medium: 5,
      low: 6,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-08-05T00:00:00.000Z",
    status: "run_exploitation_phase",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-08-04T04:00:00.000Z",
    id: "1-100001",
    severityCount: {
      high: 1,
      medium: 1,
      low: 8,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-08-04T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-08-03T03:00:00.000Z",
    id: "1-100002",
    severityCount: {
      high: 0,
      medium: 2,
      low: 8,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-08-03T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "",
    id: "1-100003",
    severityCount: {
      high: 2,
      medium: 3,
      low: 5,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-08-02T00:00:00.000Z",
    status: "run_exploration_phase",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-08-01T04:00:00.000Z",
    id: "1-100004",
    severityCount: {
      high: 1,
      medium: 8,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-08-01T00:00:00.000Z",
    status: "pending",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-31T03:00:00.000Z",
    id: "1-100005",
    severityCount: {
      high: 0,
      medium: 0,
      low: 14,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-31T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-30T05:00:00.000Z",
    id: "1-100006",
    severityCount: {
      high: 1,
      medium: 3,
      low: 8,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-30T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-29T03:00:00.000Z",
    id: "1-100007",
    severityCount: {
      high: 1,
      medium: 2,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-29T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-28T05:00:00.000Z",
    id: "1-100008",
    severityCount: {
      high: 1,
      medium: 3,
      low: 9,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-28T00:00:00.000Z",
    status: "pending",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-27T03:00:00.000Z",
    id: "1-100009",
    severityCount: {
      high: 0,
      medium: 0,
      low: 14,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-27T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-26T04:00:00.000Z",
    id: "1-100010",
    severityCount: {
      high: 1,
      medium: 2,
      low: 9,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-26T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-25T03:00:00.000Z",
    id: "1-100011",
    severityCount: {
      high: 2,
      medium: 7,
      low: 5,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-25T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-24T04:00:00.000Z",
    id: "1-100012",
    severityCount: {
      high: 0,
      medium: 4,
      low: 8,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-24T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-23T03:00:00.000Z",
    id: "1-100013",
    severityCount: {
      high: 3,
      medium: 0,
      low: 8,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-23T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-22T03:00:00.000Z",
    id: "1-100014",
    severityCount: {
      high: 2,
      medium: 9,
      low: 0,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-22T00:00:00.000Z",
    status: "stopped",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-21T04:00:00.000Z",
    id: "1-100015",
    severityCount: {
      high: 1,
      medium: 2,
      low: 7,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-21T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-20T05:00:00.000Z",
    id: "1-100016",
    severityCount: {
      high: 0,
      medium: 7,
      low: 7,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-20T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-19T03:00:00.000Z",
    id: "1-100017",
    severityCount: {
      high: 4,
      medium: 0,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-19T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-18T03:00:00.000Z",
    id: "1-100018",
    severityCount: {
      high: 3,
      medium: 5,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-18T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-17T05:00:00.000Z",
    id: "1-100019",
    severityCount: {
      high: 2,
      medium: 3,
      low: 6,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-17T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-16T04:00:00.000Z",
    id: "1-100020",
    severityCount: {
      high: 4,
      medium: 0,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-16T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-15T05:00:00.000Z",
    id: "1-100021",
    severityCount: {
      high: 0,
      medium: 1,
      low: 10,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-15T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-14T04:00:00.000Z",
    id: "1-100022",
    severityCount: {
      high: 1,
      medium: 4,
      low: 9,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-14T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-13T05:00:00.000Z",
    id: "1-100023",
    severityCount: {
      high: 3,
      medium: 2,
      low: 8,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-13T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-12T03:00:00.000Z",
    id: "1-100024",
    severityCount: {
      high: 4,
      medium: 6,
      low: 3,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-12T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-11T03:00:00.000Z",
    id: "1-100025",
    severityCount: {
      high: 1,
      medium: 8,
      low: 5,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-11T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-10T05:00:00.000Z",
    id: "1-100026",
    severityCount: {
      high: 3,
      medium: 4,
      low: 5,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-10T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-09T03:00:00.000Z",
    id: "1-100027",
    severityCount: {
      high: 0,
      medium: 1,
      low: 12,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-09T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-08T03:00:00.000Z",
    id: "1-100028",
    severityCount: {
      high: 3,
      medium: 4,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-08T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-07T03:00:00.000Z",
    id: "1-100029",
    severityCount: {
      high: 2,
      medium: 4,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-07T00:00:00.000Z",
    status: "stopped",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-06T03:00:00.000Z",
    id: "1-100030",
    severityCount: {
      high: 4,
      medium: 0,
      low: 7,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-06T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-05T04:00:00.000Z",
    id: "1-100031",
    severityCount: {
      high: 1,
      medium: 1,
      low: 12,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-05T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-04T05:00:00.000Z",
    id: "1-100032",
    severityCount: {
      high: 3,
      medium: 8,
      low: 2,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-04T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-03T03:00:00.000Z",
    id: "1-100033",
    severityCount: {
      high: 4,
      medium: 3,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-03T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-07-02T05:00:00.000Z",
    id: "1-100034",
    severityCount: {
      high: 4,
      medium: 8,
      low: 0,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-02T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-07-01T04:00:00.000Z",
    id: "1-100035",
    severityCount: {
      high: 1,
      medium: 6,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-07-01T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-30T05:00:00.000Z",
    id: "1-100036",
    severityCount: {
      high: 1,
      medium: 1,
      low: 10,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-30T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-29T04:00:00.000Z",
    id: "1-100037",
    severityCount: {
      high: 3,
      medium: 9,
      low: 0,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-29T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-28T03:00:00.000Z",
    id: "1-100038",
    severityCount: {
      high: 4,
      medium: 1,
      low: 6,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-28T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-27T04:00:00.000Z",
    id: "1-100039",
    severityCount: {
      high: 2,
      medium: 7,
      low: 3,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-27T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-26T03:00:00.000Z",
    id: "1-100040",
    severityCount: {
      high: 3,
      medium: 8,
      low: 3,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-26T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-25T04:00:00.000Z",
    id: "1-100041",
    severityCount: {
      high: 3,
      medium: 2,
      low: 7,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-25T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-24T05:00:00.000Z",
    id: "1-100042",
    severityCount: {
      high: 2,
      medium: 8,
      low: 3,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-24T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-23T05:00:00.000Z",
    id: "1-100043",
    severityCount: {
      high: 1,
      medium: 5,
      low: 7,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-23T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-22T05:00:00.000Z",
    id: "1-100044",
    severityCount: {
      high: 1,
      medium: 0,
      low: 12,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-22T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-21T03:00:00.000Z",
    id: "1-100045",
    severityCount: {
      high: 4,
      medium: 5,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-21T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-20T03:00:00.000Z",
    id: "1-100046",
    severityCount: {
      high: 0,
      medium: 2,
      low: 10,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-20T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-19T05:00:00.000Z",
    id: "1-100047",
    severityCount: {
      high: 2,
      medium: 8,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-19T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-18T04:00:00.000Z",
    id: "1-100048",
    severityCount: {
      high: 0,
      medium: 3,
      low: 7,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-18T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-17T04:00:00.000Z",
    id: "1-100049",
    severityCount: {
      high: 0,
      medium: 7,
      low: 5,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-17T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-16T03:00:00.000Z",
    id: "1-100050",
    severityCount: {
      high: 1,
      medium: 7,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-16T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-15T03:00:00.000Z",
    id: "1-100051",
    severityCount: {
      high: 0,
      medium: 2,
      low: 10,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-15T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-14T05:00:00.000Z",
    id: "1-100052",
    severityCount: {
      high: 1,
      medium: 7,
      low: 5,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-14T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-13T04:00:00.000Z",
    id: "1-100053",
    severityCount: {
      high: 4,
      medium: 1,
      low: 7,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-13T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-12T03:00:00.000Z",
    id: "1-100054",
    severityCount: {
      high: 0,
      medium: 5,
      low: 8,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-12T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-11T05:00:00.000Z",
    id: "1-100055",
    severityCount: {
      high: 1,
      medium: 3,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-11T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-10T03:00:00.000Z",
    id: "1-100056",
    severityCount: {
      high: 3,
      medium: 7,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-10T00:00:00.000Z",
    status: "stopped",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-09T05:00:00.000Z",
    id: "1-100057",
    severityCount: {
      high: 0,
      medium: 8,
      low: 5,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-09T00:00:00.000Z",
    status: "failed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-08T04:00:00.000Z",
    id: "1-100058",
    severityCount: {
      high: 3,
      medium: 9,
      low: 2,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-08T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-07T04:00:00.000Z",
    id: "1-100059",
    severityCount: {
      high: 2,
      medium: 7,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-07T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-06T05:00:00.000Z",
    id: "1-100060",
    severityCount: {
      high: 1,
      medium: 0,
      low: 12,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-06T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-05T05:00:00.000Z",
    id: "1-100061",
    severityCount: {
      high: 4,
      medium: 4,
      low: 5,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-05T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-04T04:00:00.000Z",
    id: "1-100062",
    severityCount: {
      high: 4,
      medium: 1,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-04T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-06-03T03:00:00.000Z",
    id: "1-100063",
    severityCount: {
      high: 1,
      medium: 9,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-03T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-02T05:00:00.000Z",
    id: "1-100064",
    severityCount: {
      high: 4,
      medium: 4,
      low: 2,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-02T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-06-01T03:00:00.000Z",
    id: "1-100065",
    severityCount: {
      high: 0,
      medium: 2,
      low: 12,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-06-01T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-31T03:00:00.000Z",
    id: "1-100066",
    severityCount: {
      high: 3,
      medium: 7,
      low: 0,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-31T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-30T05:00:00.000Z",
    id: "1-100067",
    severityCount: {
      high: 2,
      medium: 0,
      low: 11,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-30T00:00:00.000Z",
    status: "stopped",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-29T05:00:00.000Z",
    id: "1-100068",
    severityCount: {
      high: 2,
      medium: 2,
      low: 10,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-29T00:00:00.000Z",
    status: "stopped",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-28T05:00:00.000Z",
    id: "1-100069",
    severityCount: {
      high: 4,
      medium: 0,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-28T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-27T05:00:00.000Z",
    id: "1-100070",
    severityCount: {
      high: 2,
      medium: 1,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-27T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-26T04:00:00.000Z",
    id: "1-100071",
    severityCount: {
      high: 1,
      medium: 7,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-26T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-25T03:00:00.000Z",
    id: "1-100072",
    severityCount: {
      high: 1,
      medium: 6,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-25T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-24T03:00:00.000Z",
    id: "1-100073",
    severityCount: {
      high: 0,
      medium: 7,
      low: 5,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-24T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-23T03:00:00.000Z",
    id: "1-100074",
    severityCount: {
      high: 0,
      medium: 3,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-23T00:00:00.000Z",
    status: "stopped",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-22T03:00:00.000Z",
    id: "1-100075",
    severityCount: {
      high: 4,
      medium: 1,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-22T00:00:00.000Z",
    status: "failed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-21T03:00:00.000Z",
    id: "1-100076",
    severityCount: {
      high: 1,
      medium: 6,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-21T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-20T03:00:00.000Z",
    id: "1-100077",
    severityCount: {
      high: 0,
      medium: 9,
      low: 5,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-20T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-19T04:00:00.000Z",
    id: "1-100078",
    severityCount: {
      high: 4,
      medium: 9,
      low: 0,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-19T00:00:00.000Z",
    status: "stopped",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-18T05:00:00.000Z",
    id: "1-100079",
    severityCount: {
      high: 1,
      medium: 9,
      low: 4,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-18T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-17T04:00:00.000Z",
    id: "1-100080",
    severityCount: {
      high: 2,
      medium: 3,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-17T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-16T05:00:00.000Z",
    id: "1-100081",
    severityCount: {
      high: 4,
      medium: 9,
      low: 0,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-16T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-15T04:00:00.000Z",
    id: "1-100082",
    severityCount: {
      high: 1,
      medium: 9,
      low: 3,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-15T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-14T03:00:00.000Z",
    id: "1-100083",
    severityCount: {
      high: 3,
      medium: 9,
      low: 0,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-14T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-13T03:00:00.000Z",
    id: "1-100084",
    severityCount: {
      high: 1,
      medium: 8,
      low: 1,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-13T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-12T05:00:00.000Z",
    id: "1-100085",
    severityCount: {
      high: 2,
      medium: 3,
      low: 9,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-12T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
  {
    completedAt: "2024-05-11T03:00:00.000Z",
    id: "1-100086",
    severityCount: {
      high: 1,
      medium: 4,
      low: 6,
      information: 0,
    },
    triggeredBy: "Scheduler",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-11T00:00:00.000Z",
    status: "completed",
    exploitation: false,
    lastError: "",
  },
  {
    completedAt: "2024-05-10T05:00:00.000Z",
    id: "1-100087",
    severityCount: {
      high: 1,
      medium: 4,
      low: 7,
      information: 0,
    },
    triggeredBy: "API",
    projectID: "1",
    projectName: "Project alpha",
    startedAt: "2024-05-10T00:00:00.000Z",
    status: "completed",
    exploitation: true,
    lastError: "",
  },
];

scans.set("1", [...MOCKED_SCANS]);
scans.set("2", [...MOCKED_SCANS]);
scans.set("3", [...MOCKED_SCANS]);
scans.set("4", [...MOCKED_SCANS]);
