import type { AuthenticationProfile } from "@Services";

const sequences: AuthenticationProfile[] = [
  {
    group: "group1",
    id: "user5",
    invalid: false,
    name: "User5",
    origin: "end_user_output_token",
    role: "security_reader",
    workedPreviously: true,
  },
  {
    group: "group2",
    id: "user1",
    invalid: true,
    name: "User1",
    origin: "autogenerated",
    role: "user",
    workedPreviously: false,
  },
  {
    group: "group3",
    id: "user9",
    invalid: false,
    name: "User9",
    origin: "end_user_output_token",
    role: "user",
    workedPreviously: true,
  },
  {
    group: "group4",
    id: "user7",
    invalid: true,
    name: "User7",
    origin: "end_user_input_credentials",
    role: "user",
    workedPreviously: true,
  },
  {
    group: "group5",
    id: "user2",
    invalid: false,
    name: "User2",
    origin: "end_user_input_credentials",
    role: "user",
    workedPreviously: false,
  },
  {
    group: "group6",
    id: "",
    invalid: true,
    name: "",
    origin: "end_user_input_credentials",
    role: "organization_admin",
    workedPreviously: true,
  },
  {
    group: "group7",
    id: "user3",
    invalid: false,
    name: "User3",
    origin: "end_user_input_credentials",
    role: "organization_admin",
    workedPreviously: false,
  },
  {
    group: "group8",
    id: "user12",
    invalid: true,
    name: "User12",
    origin: "autogenerated",
    role: "user",
    workedPreviously: true,
  },
  {
    group: "group9",
    id: "user4",
    invalid: false,
    name: "User4",
    origin: "autogenerated",
    role: "organization_admin",
    workedPreviously: false,
  },
];

export const getListSequences = (sP: URLSearchParams) => {
  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const sortMode = sP.get("sort_mode");
  const sortBy = sP.get("sort_by");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  const responseData = sequences
    .filter(a => {
      if (!filters?.authentication_profile_name) return true;
      return a.name
        .toLowerCase()
        .includes(filters.authentication_profile_name.toLowerCase());
    })
    .filter(a => {
      if (!filters?.authentication_profile_origin) return true;
      return a.origin
        .toLowerCase()
        .includes(filters.authentication_profile_origin.toLowerCase());
    });

  if (sortBy === "authentication_profile_name") {
    responseData.sort((a, b) => {
      return a.name.localeCompare(b.name) * (sortMode === "asc" ? 1 : -1);
    });
  }

  return responseData;
};
