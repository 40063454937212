import { pureComponent } from "@Utils";
import { memo } from "react";
import { useSpaceLinkListing } from "./Hooks/useSpaceLinkListing";
import { CreateSpaceLink, SpaceLinkListingContent } from "./Components";

export const SpaceLinkListing = memo(() => {
  const {
    fetch,
    hasAnyItem,
    hasError,
    loading,
    spaceLinks,
    totalItems,
    updateSearchFilter,
  } = useSpaceLinkListing();

  return (
    <div id="organization-space-links">
      <SpaceLinkListingContent
        fetch={fetch}
        hasAnyItem={hasAnyItem}
        hasError={hasError}
        loading={loading}
        spaceLinks={spaceLinks}
        totalItems={totalItems}
        updateSearchFilter={updateSearchFilter}
      />
      <CreateSpaceLink fetch={fetch} />
    </div>
  );
}, pureComponent);
