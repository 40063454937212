import React, { memo } from "react";
import type { Props } from "./CardDetail.i";
import { t } from "i18next";
import { BEM, joinClasses } from "@Utils";
import { GridContainer } from "@Components";

import "./style.scss";

export const CardDetail = memo(
  ({ icon, title, children, className = "" }: Props) => (
    <div
      className={joinClasses(
        className,
        BEM("presentation-card", null, icon ? "with-icon" : null)
      )}
    >
      <GridContainer>
        <>
          {icon && (
            <div className="icon-container">
              <i className={`bi bi-${icon}`}></i>
            </div>
          )}
        </>
        <div className="info-container">
          {title && <span className="info-title">{t(title)}</span>}
          <span className="info-value">{children}</span>
        </div>
      </GridContainer>
    </div>
  )
);
