import { useMemo, useState } from "react";
import type { DropDownButtonsProps } from "./DropdownButtons.i";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import "./style.scss";
import { clamp } from "@Services";
import { ActiveButton } from "./Components/ActiveButton";
import { DropdownMenu } from "./Components/DropdownMenu";

export const DropdownButtons = ({
  buttons,
  initialIndex = 0,
}: DropDownButtonsProps) => {
  const [activeIndex, setActiveIndex] = useState(() => {
    return clamp(initialIndex, 0, buttons.length);
  });

  const activeButton = useMemo(() => {
    return buttons[activeIndex];
  }, [activeIndex, buttons]);

  return (
    <UncontrolledDropdown group className="dropdown-buttons">
      <ActiveButton activeButton={activeButton} />
      <DropdownToggle
        caret
        color={activeButton.color}
        disabled={activeButton.loading || activeButton.disabled}
      />
      <DropdownMenu
        buttons={buttons}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </UncontrolledDropdown>
  );
};
