export const symbols = [
  "U+0024",
  "U+002B",
  "U+003C",
  "U+003D",
  "U+003E",
  "U+005E",
  "U+0060",
  "U+007C",
  "U+007E",
  "U+00A2",
  "U+00A3",
  "U+00A4",
  "U+00A5",
  "U+00A6",
  "U+00A8",
  "U+00A9",
  "U+00AC",
  "U+00AE",
  "U+00AF",
  "U+00B0",
  "U+00B1",
  "U+00B4",
  "U+00B8",
  "U+00D7",
  "U+00F7",
  "U+02C2",
  "U+02C3",
  "U+02C4",
  "U+02C5",
  "U+02D2",
  "U+02D3",
  "U+02D4",
  "U+02D5",
  "U+02D6",
  "U+02D7",
  "U+02D8",
  "U+02D9",
  "U+02DA",
  "U+02DB",
  "U+02DC",
  "U+02DD",
  "U+02DE",
  "U+02DF",
  "U+02E5",
  "U+02E6",
  "U+02E7",
  "U+02E8",
  "U+02E9",
  "U+02EA",
  "U+02EB",
  "U+02ED",
  "U+02EF",
  "U+02F0",
  "U+02F1",
  "U+02F2",
  "U+02F3",
  "U+02F4",
  "U+02F5",
  "U+02F6",
  "U+02F7",
  "U+02F8",
  "U+02F9",
  "U+02FA",
  "U+02FB",
  "U+02FC",
  "U+02FD",
  "U+02FE",
  "U+02FF",
  "U+0375",
  "U+0384",
  "U+0385",
  "U+03F6",
  "U+0482",
  "U+058D",
  "U+058E",
  "U+058F",
  "U+0606",
  "U+0607",
  "U+0608",
  "U+060B",
  "U+060E",
  "U+060F",
  "U+06DE",
  "U+06E9",
  "U+06FD",
  "U+06FE",
  "U+07F6",
  "U+07FE",
  "U+07FF",
  "U+0888",
  "U+09F2",
  "U+09F3",
  "U+09FA",
  "U+09FB",
  "U+0AF1",
  "U+0B70",
  "U+0BF3",
  "U+0BF4",
  "U+0BF5",
  "U+0BF6",
  "U+0BF7",
  "U+0BF8",
  "U+0BF9",
  "U+0BFA",
  "U+0C7F",
  "U+0D4F",
  "U+0D79",
  "U+0E3F",
  "U+0F01",
  "U+0F02",
  "U+0F03",
  "U+0F13",
  "U+0F15",
  "U+0F16",
  "U+0F17",
  "U+0F1A",
  "U+0F1B",
  "U+0F1C",
  "U+0F1D",
  "U+0F1E",
  "U+0F1F",
  "U+0F34",
  "U+0F36",
  "U+0F38",
  "U+0FBE",
  "U+0FBF",
  "U+0FC0",
  "U+0FC1",
  "U+0FC2",
  "U+0FC3",
  "U+0FC4",
  "U+0FC5",
  "U+0FC7",
  "U+0FC8",
  "U+0FC9",
  "U+0FCA",
  "U+0FCB",
  "U+0FCC",
  "U+0FCE",
  "U+0FCF",
  "U+0FD5",
  "U+0FD6",
  "U+0FD7",
  "U+0FD8",
  "U+109E",
  "U+109F",
  "U+1390",
  "U+1391",
  "U+1392",
  "U+1393",
  "U+1394",
  "U+1395",
  "U+1396",
  "U+1397",
  "U+1398",
  "U+1399",
  "U+166D",
  "U+17DB",
  "U+1940",
  "U+19DE",
  "U+19DF",
  "U+19E0",
  "U+19E1",
  "U+19E2",
  "U+19E3",
  "U+19E4",
  "U+19E5",
  "U+19E6",
  "U+19E7",
  "U+19E8",
  "U+19E9",
  "U+19EA",
  "U+19EB",
  "U+19EC",
  "U+19ED",
  "U+19EE",
  "U+19EF",
  "U+19F0",
  "U+19F1",
  "U+19F2",
  "U+19F3",
  "U+19F4",
  "U+19F5",
  "U+19F6",
  "U+19F7",
  "U+19F8",
  "U+19F9",
  "U+19FA",
  "U+19FB",
  "U+19FC",
  "U+19FD",
  "U+19FE",
  "U+19FF",
  "U+1B61",
  "U+1B62",
  "U+1B63",
  "U+1B64",
  "U+1B65",
  "U+1B66",
  "U+1B67",
  "U+1B68",
  "U+1B69",
  "U+1B6A",
  "U+1B74",
  "U+1B75",
  "U+1B76",
  "U+1B77",
  "U+1B78",
  "U+1B79",
  "U+1B7A",
  "U+1B7B",
  "U+1B7C",
  "U+1FBD",
  "U+1FBF",
  "U+1FC0",
  "U+1FC1",
  "U+1FCD",
  "U+1FCE",
  "U+1FCF",
  "U+1FDD",
  "U+1FDE",
  "U+1FDF",
  "U+1FED",
  "U+1FEE",
  "U+1FEF",
  "U+1FFD",
  "U+1FFE",
  "U+2044",
  "U+2052",
  "U+207A",
  "U+207B",
  "U+207C",
  "U+208A",
  "U+208B",
  "U+208C",
  "U+20A0",
  "U+20A1",
  "U+20A2",
  "U+20A3",
  "U+20A4",
  "U+20A5",
  "U+20A6",
  "U+20A7",
  "U+20A8",
  "U+20A9",
  "U+20AA",
  "U+20AB",
  "U+20AC",
  "U+20AD",
  "U+20AE",
  "U+20AF",
  "U+20B0",
  "U+20B1",
  "U+20B2",
  "U+20B3",
  "U+20B4",
  "U+20B5",
  "U+20B6",
  "U+20B7",
  "U+20B8",
  "U+20B9",
  "U+20BA",
  "U+20BB",
  "U+20BC",
  "U+20BD",
  "U+20BE",
  "U+20BF",
  "U+20C0",
  "U+2100",
  "U+2101",
  "U+2103",
  "U+2104",
  "U+2105",
  "U+2106",
  "U+2108",
  "U+2109",
  "U+2114",
  "U+2116",
  "U+2117",
  "U+2118",
  "U+211E",
  "U+211F",
  "U+2120",
  "U+2121",
  "U+2122",
  "U+2123",
  "U+2125",
  "U+2127",
  "U+2129",
  "U+212E",
  "U+213A",
  "U+213B",
  "U+2140",
  "U+2141",
  "U+2142",
  "U+2143",
  "U+2144",
  "U+214A",
  "U+214B",
  "U+214C",
  "U+214D",
  "U+214F",
  "U+218A",
  "U+218B",
  "U+2190",
  "U+2191",
  "U+2192",
  "U+2193",
  "U+2194",
  "U+2195",
  "U+2196",
  "U+2197",
  "U+2198",
  "U+2199",
  "U+219A",
  "U+219B",
  "U+219C",
  "U+219D",
  "U+219E",
  "U+219F",
  "U+21A0",
  "U+21A1",
  "U+21A2",
  "U+21A3",
  "U+21A4",
  "U+21A5",
  "U+21A6",
  "U+21A7",
  "U+21A8",
  "U+21A9",
  "U+21AA",
  "U+21AB",
  "U+21AC",
  "U+21AD",
  "U+21AE",
  "U+21AF",
  "U+21B0",
  "U+21B1",
  "U+21B2",
  "U+21B3",
  "U+21B4",
  "U+21B5",
  "U+21B6",
  "U+21B7",
  "U+21B8",
  "U+21B9",
  "U+21BA",
  "U+21BB",
  "U+21BC",
  "U+21BD",
  "U+21BE",
  "U+21BF",
  "U+21C0",
  "U+21C1",
  "U+21C2",
  "U+21C3",
  "U+21C4",
  "U+21C5",
  "U+21C6",
  "U+21C7",
  "U+21C8",
  "U+21C9",
  "U+21CA",
  "U+21CB",
  "U+21CC",
  "U+21CD",
  "U+21CE",
  "U+21CF",
  "U+21D0",
  "U+21D1",
  "U+21D2",
  "U+21D3",
  "U+21D4",
  "U+21D5",
  "U+21D6",
  "U+21D7",
  "U+21D8",
  "U+21D9",
  "U+21DA",
  "U+21DB",
  "U+21DC",
  "U+21DD",
  "U+21DE",
  "U+21DF",
  "U+21E0",
  "U+21E1",
  "U+21E2",
  "U+21E3",
  "U+21E4",
  "U+21E5",
  "U+21E6",
  "U+21E7",
  "U+21E8",
  "U+21E9",
  "U+21EA",
  "U+21EB",
  "U+21EC",
  "U+21ED",
  "U+21EE",
  "U+21EF",
  "U+21F0",
  "U+21F1",
  "U+21F2",
  "U+21F3",
  "U+21F4",
  "U+21F5",
  "U+21F6",
  "U+21F7",
  "U+21F8",
  "U+21F9",
  "U+21FA",
  "U+21FB",
  "U+21FC",
  "U+21FD",
  "U+21FE",
  "U+21FF",
  "U+2200",
  "U+2201",
  "U+2202",
  "U+2203",
  "U+2204",
  "U+2205",
  "U+2206",
  "U+2207",
  "U+2208",
  "U+2209",
  "U+220A",
  "U+220B",
  "U+220C",
  "U+220D",
  "U+220E",
  "U+220F",
  "U+2210",
  "U+2211",
  "U+2212",
  "U+2213",
  "U+2214",
  "U+2215",
  "U+2216",
  "U+2217",
  "U+2218",
  "U+2219",
  "U+221A",
  "U+221B",
  "U+221C",
  "U+221D",
  "U+221E",
  "U+221F",
  "U+2220",
  "U+2221",
  "U+2222",
  "U+2223",
  "U+2224",
  "U+2225",
  "U+2226",
  "U+2227",
  "U+2228",
  "U+2229",
  "U+222A",
  "U+222B",
  "U+222C",
  "U+222D",
  "U+222E",
  "U+222F",
  "U+2230",
  "U+2231",
  "U+2232",
  "U+2233",
  "U+2234",
  "U+2235",
  "U+2236",
  "U+2237",
  "U+2238",
  "U+2239",
  "U+223A",
  "U+223B",
  "U+223C",
  "U+223D",
  "U+223E",
  "U+223F",
  "U+2240",
  "U+2241",
  "U+2242",
  "U+2243",
  "U+2244",
  "U+2245",
  "U+2246",
  "U+2247",
  "U+2248",
  "U+2249",
  "U+224A",
  "U+224B",
  "U+224C",
  "U+224D",
  "U+224E",
  "U+224F",
  "U+2250",
  "U+2251",
  "U+2252",
  "U+2253",
  "U+2254",
  "U+2255",
  "U+2256",
  "U+2257",
  "U+2258",
  "U+2259",
  "U+225A",
  "U+225B",
  "U+225C",
  "U+225D",
  "U+225E",
  "U+225F",
  "U+2260",
  "U+2261",
  "U+2262",
  "U+2263",
  "U+2264",
  "U+2265",
  "U+2266",
  "U+2267",
  "U+2268",
  "U+2269",
  "U+226A",
  "U+226B",
  "U+226C",
  "U+226D",
  "U+226E",
  "U+226F",
  "U+2270",
  "U+2271",
  "U+2272",
  "U+2273",
  "U+2274",
  "U+2275",
  "U+2276",
  "U+2277",
  "U+2278",
  "U+2279",
  "U+227A",
  "U+227B",
  "U+227C",
  "U+227D",
  "U+227E",
  "U+227F",
  "U+2280",
  "U+2281",
  "U+2282",
  "U+2283",
  "U+2284",
  "U+2285",
  "U+2286",
  "U+2287",
  "U+2288",
  "U+2289",
  "U+228A",
  "U+228B",
  "U+228C",
  "U+228D",
  "U+228E",
  "U+228F",
  "U+2290",
  "U+2291",
  "U+2292",
  "U+2293",
  "U+2294",
  "U+2295",
  "U+2296",
  "U+2297",
  "U+2298",
  "U+2299",
  "U+229A",
  "U+229B",
  "U+229C",
  "U+229D",
  "U+229E",
  "U+229F",
  "U+22A0",
  "U+22A1",
  "U+22A2",
  "U+22A3",
  "U+22A4",
  "U+22A5",
  "U+22A6",
  "U+22A7",
  "U+22A8",
  "U+22A9",
  "U+22AA",
  "U+22AB",
  "U+22AC",
  "U+22AD",
  "U+22AE",
  "U+22AF",
  "U+22B0",
  "U+22B1",
  "U+22B2",
  "U+22B3",
  "U+22B4",
  "U+22B5",
  "U+22B6",
  "U+22B7",
  "U+22B8",
  "U+22B9",
  "U+22BA",
  "U+22BB",
  "U+22BC",
  "U+22BD",
  "U+22BE",
  "U+22BF",
  "U+22C0",
  "U+22C1",
  "U+22C2",
  "U+22C3",
  "U+22C4",
  "U+22C5",
  "U+22C6",
  "U+22C7",
  "U+22C8",
  "U+22C9",
  "U+22CA",
  "U+22CB",
  "U+22CC",
  "U+22CD",
  "U+22CE",
  "U+22CF",
  "U+22D0",
  "U+22D1",
  "U+22D2",
  "U+22D3",
  "U+22D4",
  "U+22D5",
  "U+22D6",
  "U+22D7",
  "U+22D8",
  "U+22D9",
  "U+22DA",
  "U+22DB",
  "U+22DC",
  "U+22DD",
  "U+22DE",
  "U+22DF",
  "U+22E0",
  "U+22E1",
  "U+22E2",
  "U+22E3",
  "U+22E4",
  "U+22E5",
  "U+22E6",
  "U+22E7",
  "U+22E8",
  "U+22E9",
  "U+22EA",
  "U+22EB",
  "U+22EC",
  "U+22ED",
  "U+22EE",
  "U+22EF",
  "U+22F0",
  "U+22F1",
  "U+22F2",
  "U+22F3",
  "U+22F4",
  "U+22F5",
  "U+22F6",
  "U+22F7",
  "U+22F8",
  "U+22F9",
  "U+22FA",
  "U+22FB",
  "U+22FC",
  "U+22FD",
  "U+22FE",
  "U+22FF",
  "U+2300",
  "U+2301",
  "U+2302",
  "U+2303",
  "U+2304",
  "U+2305",
  "U+2306",
  "U+2307",
  "U+230C",
  "U+230D",
  "U+230E",
  "U+230F",
  "U+2310",
  "U+2311",
  "U+2312",
  "U+2313",
  "U+2314",
  "U+2315",
  "U+2316",
  "U+2317",
  "U+2318",
  "U+2319",
  "U+231A",
  "U+231B",
  "U+231C",
  "U+231D",
  "U+231E",
  "U+231F",
  "U+2320",
  "U+2321",
  "U+2322",
  "U+2323",
  "U+2324",
  "U+2325",
  "U+2326",
  "U+2327",
  "U+2328",
  "U+232B",
  "U+232C",
  "U+232D",
  "U+232E",
  "U+232F",
  "U+2330",
  "U+2331",
  "U+2332",
  "U+2333",
  "U+2334",
  "U+2335",
  "U+2336",
  "U+2337",
  "U+2338",
  "U+2339",
  "U+233A",
  "U+233B",
  "U+233C",
  "U+233D",
  "U+233E",
  "U+233F",
  "U+2340",
  "U+2341",
  "U+2342",
  "U+2343",
  "U+2344",
  "U+2345",
  "U+2346",
  "U+2347",
  "U+2348",
  "U+2349",
  "U+234A",
  "U+234B",
  "U+234C",
  "U+234D",
  "U+234E",
  "U+234F",
  "U+2350",
  "U+2351",
  "U+2352",
  "U+2353",
  "U+2354",
  "U+2355",
  "U+2356",
  "U+2357",
  "U+2358",
  "U+2359",
  "U+235A",
  "U+235B",
  "U+235C",
  "U+235D",
  "U+235E",
  "U+235F",
  "U+2360",
  "U+2361",
  "U+2362",
  "U+2363",
  "U+2364",
  "U+2365",
  "U+2366",
  "U+2367",
  "U+2368",
  "U+2369",
  "U+236A",
  "U+236B",
  "U+236C",
  "U+236D",
  "U+236E",
  "U+236F",
  "U+2370",
  "U+2371",
  "U+2372",
  "U+2373",
  "U+2374",
  "U+2375",
  "U+2376",
  "U+2377",
  "U+2378",
  "U+2379",
  "U+237A",
  "U+237B",
  "U+237C",
  "U+237D",
  "U+237E",
  "U+237F",
  "U+2380",
  "U+2381",
  "U+2382",
  "U+2383",
  "U+2384",
  "U+2385",
  "U+2386",
  "U+2387",
  "U+2388",
  "U+2389",
  "U+238A",
  "U+238B",
  "U+238C",
  "U+238D",
  "U+238E",
  "U+238F",
  "U+2390",
  "U+2391",
  "U+2392",
  "U+2393",
  "U+2394",
  "U+2395",
  "U+2396",
  "U+2397",
  "U+2398",
  "U+2399",
  "U+239A",
  "U+239B",
  "U+239C",
  "U+239D",
  "U+239E",
  "U+239F",
  "U+23A0",
  "U+23A1",
  "U+23A2",
  "U+23A3",
  "U+23A4",
  "U+23A5",
  "U+23A6",
  "U+23A7",
  "U+23A8",
  "U+23A9",
  "U+23AA",
  "U+23AB",
  "U+23AC",
  "U+23AD",
  "U+23AE",
  "U+23AF",
  "U+23B0",
  "U+23B1",
  "U+23B2",
  "U+23B3",
  "U+23B4",
  "U+23B5",
  "U+23B6",
  "U+23B7",
  "U+23B8",
  "U+23B9",
  "U+23BA",
  "U+23BB",
  "U+23BC",
  "U+23BD",
  "U+23BE",
  "U+23BF",
  "U+23C0",
  "U+23C1",
  "U+23C2",
  "U+23C3",
  "U+23C4",
  "U+23C5",
  "U+23C6",
  "U+23C7",
  "U+23C8",
  "U+23C9",
  "U+23CA",
  "U+23CB",
  "U+23CC",
  "U+23CD",
  "U+23CE",
  "U+23CF",
  "U+23D0",
  "U+23D1",
  "U+23D2",
  "U+23D3",
  "U+23D4",
  "U+23D5",
  "U+23D6",
  "U+23D7",
  "U+23D8",
  "U+23D9",
  "U+23DA",
  "U+23DB",
  "U+23DC",
  "U+23DD",
  "U+23DE",
  "U+23DF",
  "U+23E0",
  "U+23E1",
  "U+23E2",
  "U+23E3",
  "U+23E4",
  "U+23E5",
  "U+23E6",
  "U+23E7",
  "U+23E8",
  "U+23E9",
  "U+23EA",
  "U+23EB",
  "U+23EC",
  "U+23ED",
  "U+23EE",
  "U+23EF",
  "U+23F0",
  "U+23F1",
  "U+23F2",
  "U+23F3",
  "U+23F4",
  "U+23F5",
  "U+23F6",
  "U+23F7",
  "U+23F8",
  "U+23F9",
  "U+23FA",
  "U+23FB",
  "U+23FC",
  "U+23FD",
  "U+23FE",
  "U+23FF",
  "U+2400",
  "U+2401",
  "U+2402",
  "U+2403",
  "U+2404",
  "U+2405",
  "U+2406",
  "U+2407",
  "U+2408",
  "U+2409",
  "U+240A",
  "U+240B",
  "U+240C",
  "U+240D",
  "U+240E",
  "U+240F",
  "U+2410",
  "U+2411",
  "U+2412",
  "U+2413",
  "U+2414",
  "U+2415",
  "U+2416",
  "U+2417",
  "U+2418",
  "U+2419",
  "U+241A",
  "U+241B",
  "U+241C",
  "U+241D",
  "U+241E",
  "U+241F",
  "U+2420",
  "U+2421",
  "U+2422",
  "U+2423",
  "U+2424",
  "U+2425",
  "U+2426",
  "U+2440",
  "U+2441",
  "U+2442",
  "U+2443",
  "U+2444",
  "U+2445",
  "U+2446",
  "U+2447",
  "U+2448",
  "U+2449",
  "U+244A",
  "U+249C",
  "U+249D",
  "U+249E",
  "U+249F",
  "U+24A0",
  "U+24A1",
  "U+24A2",
  "U+24A3",
  "U+24A4",
  "U+24A5",
  "U+24A6",
  "U+24A7",
  "U+24A8",
  "U+24A9",
  "U+24AA",
  "U+24AB",
  "U+24AC",
  "U+24AD",
  "U+24AE",
  "U+24AF",
  "U+24B0",
  "U+24B1",
  "U+24B2",
  "U+24B3",
  "U+24B4",
  "U+24B5",
  "U+24B6",
  "U+24B7",
  "U+24B8",
  "U+24B9",
  "U+24BA",
  "U+24BB",
  "U+24BC",
  "U+24BD",
  "U+24BE",
  "U+24BF",
  "U+24C0",
  "U+24C1",
  "U+24C2",
  "U+24C3",
  "U+24C4",
  "U+24C5",
  "U+24C6",
  "U+24C7",
  "U+24C8",
  "U+24C9",
  "U+24CA",
  "U+24CB",
  "U+24CC",
  "U+24CD",
  "U+24CE",
  "U+24CF",
  "U+24D0",
  "U+24D1",
  "U+24D2",
  "U+24D3",
  "U+24D4",
  "U+24D5",
  "U+24D6",
  "U+24D7",
  "U+24D8",
  "U+24D9",
  "U+24DA",
  "U+24DB",
  "U+24DC",
  "U+24DD",
  "U+24DE",
  "U+24DF",
  "U+24E0",
  "U+24E1",
  "U+24E2",
  "U+24E3",
  "U+24E4",
  "U+24E5",
  "U+24E6",
  "U+24E7",
  "U+24E8",
  "U+24E9",
  "U+2500",
  "U+2501",
  "U+2502",
  "U+2503",
  "U+2504",
  "U+2505",
  "U+2506",
  "U+2507",
  "U+2508",
  "U+2509",
  "U+250A",
  "U+250B",
  "U+250C",
  "U+250D",
  "U+250E",
  "U+250F",
  "U+2510",
  "U+2511",
  "U+2512",
  "U+2513",
  "U+2514",
  "U+2515",
  "U+2516",
  "U+2517",
  "U+2518",
  "U+2519",
  "U+251A",
  "U+251B",
  "U+251C",
  "U+251D",
  "U+251E",
  "U+251F",
  "U+2520",
  "U+2521",
  "U+2522",
  "U+2523",
  "U+2524",
  "U+2525",
  "U+2526",
  "U+2527",
  "U+2528",
  "U+2529",
  "U+252A",
  "U+252B",
  "U+252C",
  "U+252D",
  "U+252E",
  "U+252F",
  "U+2530",
  "U+2531",
  "U+2532",
  "U+2533",
  "U+2534",
  "U+2535",
  "U+2536",
  "U+2537",
  "U+2538",
  "U+2539",
  "U+253A",
  "U+253B",
  "U+253C",
  "U+253D",
  "U+253E",
  "U+253F",
  "U+2540",
  "U+2541",
  "U+2542",
  "U+2543",
  "U+2544",
  "U+2545",
  "U+2546",
  "U+2547",
  "U+2548",
  "U+2549",
  "U+254A",
  "U+254B",
  "U+254C",
  "U+254D",
  "U+254E",
  "U+254F",
  "U+2550",
  "U+2551",
  "U+2552",
  "U+2553",
  "U+2554",
  "U+2555",
  "U+2556",
  "U+2557",
  "U+2558",
  "U+2559",
  "U+255A",
  "U+255B",
  "U+255C",
  "U+255D",
  "U+255E",
  "U+255F",
  "U+2560",
  "U+2561",
  "U+2562",
  "U+2563",
  "U+2564",
  "U+2565",
  "U+2566",
  "U+2567",
  "U+2568",
  "U+2569",
  "U+256A",
  "U+256B",
  "U+256C",
  "U+256D",
  "U+256E",
  "U+256F",
  "U+2570",
  "U+2571",
  "U+2572",
  "U+2573",
  "U+2574",
  "U+2575",
  "U+2576",
  "U+2577",
  "U+2578",
  "U+2579",
  "U+257A",
  "U+257B",
  "U+257C",
  "U+257D",
  "U+257E",
  "U+257F",
  "U+2580",
  "U+2581",
  "U+2582",
  "U+2583",
  "U+2584",
  "U+2585",
  "U+2586",
  "U+2587",
  "U+2588",
  "U+2589",
  "U+258A",
  "U+258B",
  "U+258C",
  "U+258D",
  "U+258E",
  "U+258F",
  "U+2590",
  "U+2591",
  "U+2592",
  "U+2593",
  "U+2594",
  "U+2595",
  "U+2596",
  "U+2597",
  "U+2598",
  "U+2599",
  "U+259A",
  "U+259B",
  "U+259C",
  "U+259D",
  "U+259E",
  "U+259F",
  "U+25A0",
  "U+25A1",
  "U+25A2",
  "U+25A3",
  "U+25A4",
  "U+25A5",
  "U+25A6",
  "U+25A7",
  "U+25A8",
  "U+25A9",
  "U+25AA",
  "U+25AB",
  "U+25AC",
  "U+25AD",
  "U+25AE",
  "U+25AF",
  "U+25B0",
  "U+25B1",
  "U+25B2",
  "U+25B3",
  "U+25B4",
  "U+25B5",
  "U+25B6",
  "U+25B7",
  "U+25B8",
  "U+25B9",
  "U+25BA",
  "U+25BB",
  "U+25BC",
  "U+25BD",
  "U+25BE",
  "U+25BF",
  "U+25C0",
  "U+25C1",
  "U+25C2",
  "U+25C3",
  "U+25C4",
  "U+25C5",
  "U+25C6",
  "U+25C7",
  "U+25C8",
  "U+25C9",
  "U+25CA",
  "U+25CB",
  "U+25CC",
  "U+25CD",
  "U+25CE",
  "U+25CF",
  "U+25D0",
  "U+25D1",
  "U+25D2",
  "U+25D3",
  "U+25D4",
  "U+25D5",
  "U+25D6",
  "U+25D7",
  "U+25D8",
  "U+25D9",
  "U+25DA",
  "U+25DB",
  "U+25DC",
  "U+25DD",
  "U+25DE",
  "U+25DF",
  "U+25E0",
  "U+25E1",
  "U+25E2",
  "U+25E3",
  "U+25E4",
  "U+25E5",
  "U+25E6",
  "U+25E7",
  "U+25E8",
  "U+25E9",
  "U+25EA",
  "U+25EB",
  "U+25EC",
  "U+25ED",
  "U+25EE",
  "U+25EF",
  "U+25F0",
  "U+25F1",
  "U+25F2",
  "U+25F3",
  "U+25F4",
  "U+25F5",
  "U+25F6",
  "U+25F7",
  "U+25F8",
  "U+25F9",
  "U+25FA",
  "U+25FB",
  "U+25FC",
  "U+25FD",
  "U+25FE",
  "U+25FF",
  "U+2600",
  "U+2601",
  "U+2602",
  "U+2603",
  "U+2604",
  "U+2605",
  "U+2606",
  "U+2607",
  "U+2608",
  "U+2609",
  "U+260A",
  "U+260B",
  "U+260C",
  "U+260D",
  "U+260E",
  "U+260F",
  "U+2610",
  "U+2611",
  "U+2612",
  "U+2613",
  "U+2614",
  "U+2615",
  "U+2616",
  "U+2617",
  "U+2618",
  "U+2619",
  "U+261A",
  "U+261B",
  "U+261C",
  "U+261D",
  "U+261E",
  "U+261F",
  "U+2620",
  "U+2621",
  "U+2622",
  "U+2623",
  "U+2624",
  "U+2625",
  "U+2626",
  "U+2627",
  "U+2628",
  "U+2629",
  "U+262A",
  "U+262B",
  "U+262C",
  "U+262D",
  "U+262E",
  "U+262F",
  "U+2630",
  "U+2631",
  "U+2632",
  "U+2633",
  "U+2634",
  "U+2635",
  "U+2636",
  "U+2637",
  "U+2638",
  "U+2639",
  "U+263A",
  "U+263B",
  "U+263C",
  "U+263D",
  "U+263E",
  "U+263F",
  "U+2640",
  "U+2641",
  "U+2642",
  "U+2643",
  "U+2644",
  "U+2645",
  "U+2646",
  "U+2647",
  "U+2648",
  "U+2649",
  "U+264A",
  "U+264B",
  "U+264C",
  "U+264D",
  "U+264E",
  "U+264F",
  "U+2650",
  "U+2651",
  "U+2652",
  "U+2653",
  "U+2654",
  "U+2655",
  "U+2656",
  "U+2657",
  "U+2658",
  "U+2659",
  "U+265A",
  "U+265B",
  "U+265C",
  "U+265D",
  "U+265E",
  "U+265F",
  "U+2660",
  "U+2661",
  "U+2662",
  "U+2663",
  "U+2664",
  "U+2665",
  "U+2666",
  "U+2667",
  "U+2668",
  "U+2669",
  "U+266A",
  "U+266B",
  "U+266C",
  "U+266D",
  "U+266E",
  "U+266F",
  "U+2670",
  "U+2671",
  "U+2672",
  "U+2673",
  "U+2674",
  "U+2675",
  "U+2676",
  "U+2677",
  "U+2678",
  "U+2679",
  "U+267A",
  "U+267B",
  "U+267C",
  "U+267D",
  "U+267E",
  "U+267F",
  "U+2680",
  "U+2681",
  "U+2682",
  "U+2683",
  "U+2684",
  "U+2685",
  "U+2686",
  "U+2687",
  "U+2688",
  "U+2689",
  "U+268A",
  "U+268B",
  "U+268C",
  "U+268D",
  "U+268E",
  "U+268F",
  "U+2690",
  "U+2691",
  "U+2692",
  "U+2693",
  "U+2694",
  "U+2695",
  "U+2696",
  "U+2697",
  "U+2698",
  "U+2699",
  "U+269A",
  "U+269B",
  "U+269C",
  "U+269D",
  "U+269E",
  "U+269F",
  "U+26A0",
  "U+26A1",
  "U+26A2",
  "U+26A3",
  "U+26A4",
  "U+26A5",
  "U+26A6",
  "U+26A7",
  "U+26A8",
  "U+26A9",
  "U+26AA",
  "U+26AB",
  "U+26AC",
  "U+26AD",
  "U+26AE",
  "U+26AF",
  "U+26B0",
  "U+26B1",
  "U+26B2",
  "U+26B3",
  "U+26B4",
  "U+26B5",
  "U+26B6",
  "U+26B7",
  "U+26B8",
  "U+26B9",
  "U+26BA",
  "U+26BB",
  "U+26BC",
  "U+26BD",
  "U+26BE",
  "U+26BF",
  "U+26C0",
  "U+26C1",
  "U+26C2",
  "U+26C3",
  "U+26C4",
  "U+26C5",
  "U+26C6",
  "U+26C7",
  "U+26C8",
  "U+26C9",
  "U+26CA",
  "U+26CB",
  "U+26CC",
  "U+26CD",
  "U+26CE",
  "U+26CF",
  "U+26D0",
  "U+26D1",
  "U+26D2",
  "U+26D3",
  "U+26D4",
  "U+26D5",
  "U+26D6",
  "U+26D7",
  "U+26D8",
  "U+26D9",
  "U+26DA",
  "U+26DB",
  "U+26DC",
  "U+26DD",
  "U+26DE",
  "U+26DF",
  "U+26E0",
  "U+26E1",
  "U+26E2",
  "U+26E3",
  "U+26E4",
  "U+26E5",
  "U+26E6",
  "U+26E7",
  "U+26E8",
  "U+26E9",
  "U+26EA",
  "U+26EB",
  "U+26EC",
  "U+26ED",
  "U+26EE",
  "U+26EF",
  "U+26F0",
  "U+26F1",
  "U+26F2",
  "U+26F3",
  "U+26F4",
  "U+26F5",
  "U+26F6",
  "U+26F7",
  "U+26F8",
  "U+26F9",
  "U+26FA",
  "U+26FB",
  "U+26FC",
  "U+26FD",
  "U+26FE",
  "U+26FF",
  "U+2700",
  "U+2701",
  "U+2702",
  "U+2703",
  "U+2704",
  "U+2705",
  "U+2706",
  "U+2707",
  "U+2708",
  "U+2709",
  "U+270A",
  "U+270B",
  "U+270C",
  "U+270D",
  "U+270E",
  "U+270F",
  "U+2710",
  "U+2711",
  "U+2712",
  "U+2713",
  "U+2714",
  "U+2715",
  "U+2716",
  "U+2717",
  "U+2718",
  "U+2719",
  "U+271A",
  "U+271B",
  "U+271C",
  "U+271D",
  "U+271E",
  "U+271F",
  "U+2720",
  "U+2721",
  "U+2722",
  "U+2723",
  "U+2724",
  "U+2725",
  "U+2726",
  "U+2727",
  "U+2728",
  "U+2729",
  "U+272A",
  "U+272B",
  "U+272C",
  "U+272D",
  "U+272E",
  "U+272F",
  "U+2730",
  "U+2731",
  "U+2732",
  "U+2733",
  "U+2734",
  "U+2735",
  "U+2736",
  "U+2737",
  "U+2738",
  "U+2739",
  "U+273A",
  "U+273B",
  "U+273C",
  "U+273D",
  "U+273E",
  "U+273F",
  "U+2740",
  "U+2741",
  "U+2742",
  "U+2743",
  "U+2744",
  "U+2745",
  "U+2746",
  "U+2747",
  "U+2748",
  "U+2749",
  "U+274A",
  "U+274B",
  "U+274C",
  "U+274D",
  "U+274E",
  "U+274F",
  "U+2750",
  "U+2751",
  "U+2752",
  "U+2753",
  "U+2754",
  "U+2755",
  "U+2756",
  "U+2757",
  "U+2758",
  "U+2759",
  "U+275A",
  "U+275B",
  "U+275C",
  "U+275D",
  "U+275E",
  "U+275F",
  "U+2760",
  "U+2761",
  "U+2762",
  "U+2763",
  "U+2764",
  "U+2765",
  "U+2766",
  "U+2767",
  "U+2794",
  "U+2795",
  "U+2796",
  "U+2797",
  "U+2798",
  "U+2799",
  "U+279A",
  "U+279B",
  "U+279C",
  "U+279D",
  "U+279E",
  "U+279F",
  "U+27A0",
  "U+27A1",
  "U+27A2",
  "U+27A3",
  "U+27A4",
  "U+27A5",
  "U+27A6",
  "U+27A7",
  "U+27A8",
  "U+27A9",
  "U+27AA",
  "U+27AB",
  "U+27AC",
  "U+27AD",
  "U+27AE",
  "U+27AF",
  "U+27B0",
  "U+27B1",
  "U+27B2",
  "U+27B3",
  "U+27B4",
  "U+27B5",
  "U+27B6",
  "U+27B7",
  "U+27B8",
  "U+27B9",
  "U+27BA",
  "U+27BB",
  "U+27BC",
  "U+27BD",
  "U+27BE",
  "U+27BF",
  "U+27C0",
  "U+27C1",
  "U+27C2",
  "U+27C3",
  "U+27C4",
  "U+27C7",
  "U+27C8",
  "U+27C9",
  "U+27CA",
  "U+27CB",
  "U+27CC",
  "U+27CD",
  "U+27CE",
  "U+27CF",
  "U+27D0",
  "U+27D1",
  "U+27D2",
  "U+27D3",
  "U+27D4",
  "U+27D5",
  "U+27D6",
  "U+27D7",
  "U+27D8",
  "U+27D9",
  "U+27DA",
  "U+27DB",
  "U+27DC",
  "U+27DD",
  "U+27DE",
  "U+27DF",
  "U+27E0",
  "U+27E1",
  "U+27E2",
  "U+27E3",
  "U+27E4",
  "U+27E5",
  "U+27F0",
  "U+27F1",
  "U+27F2",
  "U+27F3",
  "U+27F4",
  "U+27F5",
  "U+27F6",
  "U+27F7",
  "U+27F8",
  "U+27F9",
  "U+27FA",
  "U+27FB",
  "U+27FC",
  "U+27FD",
  "U+27FE",
  "U+27FF",
  "U+2800",
  "U+2801",
  "U+2802",
  "U+2803",
  "U+2804",
  "U+2805",
  "U+2806",
  "U+2807",
  "U+2808",
  "U+2809",
  "U+280A",
  "U+280B",
  "U+280C",
  "U+280D",
  "U+280E",
  "U+280F",
  "U+2810",
  "U+2811",
  "U+2812",
  "U+2813",
  "U+2814",
  "U+2815",
  "U+2816",
  "U+2817",
  "U+2818",
  "U+2819",
  "U+281A",
  "U+281B",
  "U+281C",
  "U+281D",
  "U+281E",
  "U+281F",
  "U+2820",
  "U+2821",
  "U+2822",
  "U+2823",
  "U+2824",
  "U+2825",
  "U+2826",
  "U+2827",
  "U+2828",
  "U+2829",
  "U+282A",
  "U+282B",
  "U+282C",
  "U+282D",
  "U+282E",
  "U+282F",
  "U+2830",
  "U+2831",
  "U+2832",
  "U+2833",
  "U+2834",
  "U+2835",
  "U+2836",
  "U+2837",
  "U+2838",
  "U+2839",
  "U+283A",
  "U+283B",
  "U+283C",
  "U+283D",
  "U+283E",
  "U+283F",
  "U+2840",
  "U+2841",
  "U+2842",
  "U+2843",
  "U+2844",
  "U+2845",
  "U+2846",
  "U+2847",
  "U+2848",
  "U+2849",
  "U+284A",
  "U+284B",
  "U+284C",
  "U+284D",
  "U+284E",
  "U+284F",
  "U+2850",
  "U+2851",
  "U+2852",
  "U+2853",
  "U+2854",
  "U+2855",
  "U+2856",
  "U+2857",
  "U+2858",
  "U+2859",
  "U+285A",
  "U+285B",
  "U+285C",
  "U+285D",
  "U+285E",
  "U+285F",
  "U+2860",
  "U+2861",
  "U+2862",
  "U+2863",
  "U+2864",
  "U+2865",
  "U+2866",
  "U+2867",
  "U+2868",
  "U+2869",
  "U+286A",
  "U+286B",
  "U+286C",
  "U+286D",
  "U+286E",
  "U+286F",
  "U+2870",
  "U+2871",
  "U+2872",
  "U+2873",
  "U+2874",
  "U+2875",
  "U+2876",
  "U+2877",
  "U+2878",
  "U+2879",
  "U+287A",
  "U+287B",
  "U+287C",
  "U+287D",
  "U+287E",
  "U+287F",
  "U+2880",
  "U+2881",
  "U+2882",
  "U+2883",
  "U+2884",
  "U+2885",
  "U+2886",
  "U+2887",
  "U+2888",
  "U+2889",
  "U+288A",
  "U+288B",
  "U+288C",
  "U+288D",
  "U+288E",
  "U+288F",
  "U+2890",
  "U+2891",
  "U+2892",
  "U+2893",
  "U+2894",
  "U+2895",
  "U+2896",
  "U+2897",
  "U+2898",
  "U+2899",
  "U+289A",
  "U+289B",
  "U+289C",
  "U+289D",
  "U+289E",
  "U+289F",
  "U+28A0",
  "U+28A1",
  "U+28A2",
  "U+28A3",
  "U+28A4",
  "U+28A5",
  "U+28A6",
  "U+28A7",
  "U+28A8",
  "U+28A9",
  "U+28AA",
  "U+28AB",
  "U+28AC",
  "U+28AD",
  "U+28AE",
  "U+28AF",
  "U+28B0",
  "U+28B1",
  "U+28B2",
  "U+28B3",
  "U+28B4",
  "U+28B5",
  "U+28B6",
  "U+28B7",
  "U+28B8",
  "U+28B9",
  "U+28BA",
  "U+28BB",
  "U+28BC",
  "U+28BD",
  "U+28BE",
  "U+28BF",
  "U+28C0",
  "U+28C1",
  "U+28C2",
  "U+28C3",
  "U+28C4",
  "U+28C5",
  "U+28C6",
  "U+28C7",
  "U+28C8",
  "U+28C9",
  "U+28CA",
  "U+28CB",
  "U+28CC",
  "U+28CD",
  "U+28CE",
  "U+28CF",
  "U+28D0",
  "U+28D1",
  "U+28D2",
  "U+28D3",
  "U+28D4",
  "U+28D5",
  "U+28D6",
  "U+28D7",
  "U+28D8",
  "U+28D9",
  "U+28DA",
  "U+28DB",
  "U+28DC",
  "U+28DD",
  "U+28DE",
  "U+28DF",
  "U+28E0",
  "U+28E1",
  "U+28E2",
  "U+28E3",
  "U+28E4",
  "U+28E5",
  "U+28E6",
  "U+28E7",
  "U+28E8",
  "U+28E9",
  "U+28EA",
  "U+28EB",
  "U+28EC",
  "U+28ED",
  "U+28EE",
  "U+28EF",
  "U+28F0",
  "U+28F1",
  "U+28F2",
  "U+28F3",
  "U+28F4",
  "U+28F5",
  "U+28F6",
  "U+28F7",
  "U+28F8",
  "U+28F9",
  "U+28FA",
  "U+28FB",
  "U+28FC",
  "U+28FD",
  "U+28FE",
  "U+28FF",
  "U+2900",
  "U+2901",
  "U+2902",
  "U+2903",
  "U+2904",
  "U+2905",
  "U+2906",
  "U+2907",
  "U+2908",
  "U+2909",
  "U+290A",
  "U+290B",
  "U+290C",
  "U+290D",
  "U+290E",
  "U+290F",
  "U+2910",
  "U+2911",
  "U+2912",
  "U+2913",
  "U+2914",
  "U+2915",
  "U+2916",
  "U+2917",
  "U+2918",
  "U+2919",
  "U+291A",
  "U+291B",
  "U+291C",
  "U+291D",
  "U+291E",
  "U+291F",
  "U+2920",
  "U+2921",
  "U+2922",
  "U+2923",
  "U+2924",
  "U+2925",
  "U+2926",
  "U+2927",
  "U+2928",
  "U+2929",
  "U+292A",
  "U+292B",
  "U+292C",
  "U+292D",
  "U+292E",
  "U+292F",
  "U+2930",
  "U+2931",
  "U+2932",
  "U+2933",
  "U+2934",
  "U+2935",
  "U+2936",
  "U+2937",
  "U+2938",
  "U+2939",
  "U+293A",
  "U+293B",
  "U+293C",
  "U+293D",
  "U+293E",
  "U+293F",
  "U+2940",
  "U+2941",
  "U+2942",
  "U+2943",
  "U+2944",
  "U+2945",
  "U+2946",
  "U+2947",
  "U+2948",
  "U+2949",
  "U+294A",
  "U+294B",
  "U+294C",
  "U+294D",
  "U+294E",
  "U+294F",
  "U+2950",
  "U+2951",
  "U+2952",
  "U+2953",
  "U+2954",
  "U+2955",
  "U+2956",
  "U+2957",
  "U+2958",
  "U+2959",
  "U+295A",
  "U+295B",
  "U+295C",
  "U+295D",
  "U+295E",
  "U+295F",
  "U+2960",
  "U+2961",
  "U+2962",
  "U+2963",
  "U+2964",
  "U+2965",
  "U+2966",
  "U+2967",
  "U+2968",
  "U+2969",
  "U+296A",
  "U+296B",
  "U+296C",
  "U+296D",
  "U+296E",
  "U+296F",
  "U+2970",
  "U+2971",
  "U+2972",
  "U+2973",
  "U+2974",
  "U+2975",
  "U+2976",
  "U+2977",
  "U+2978",
  "U+2979",
  "U+297A",
  "U+297B",
  "U+297C",
  "U+297D",
  "U+297E",
  "U+297F",
  "U+2980",
  "U+2981",
  "U+2982",
  "U+2999",
  "U+299A",
  "U+299B",
  "U+299C",
  "U+299D",
  "U+299E",
  "U+299F",
  "U+29A0",
  "U+29A1",
  "U+29A2",
  "U+29A3",
  "U+29A4",
  "U+29A5",
  "U+29A6",
  "U+29A7",
  "U+29A8",
  "U+29A9",
  "U+29AA",
  "U+29AB",
  "U+29AC",
  "U+29AD",
  "U+29AE",
  "U+29AF",
  "U+29B0",
  "U+29B1",
  "U+29B2",
  "U+29B3",
  "U+29B4",
  "U+29B5",
  "U+29B6",
  "U+29B7",
  "U+29B8",
  "U+29B9",
  "U+29BA",
  "U+29BB",
  "U+29BC",
  "U+29BD",
  "U+29BE",
  "U+29BF",
  "U+29C0",
  "U+29C1",
  "U+29C2",
  "U+29C3",
  "U+29C4",
  "U+29C5",
  "U+29C6",
  "U+29C7",
  "U+29C8",
  "U+29C9",
  "U+29CA",
  "U+29CB",
  "U+29CC",
  "U+29CD",
  "U+29CE",
  "U+29CF",
  "U+29D0",
  "U+29D1",
  "U+29D2",
  "U+29D3",
  "U+29D4",
  "U+29D5",
  "U+29D6",
  "U+29D7",
  "U+29DC",
  "U+29DD",
  "U+29DE",
  "U+29DF",
  "U+29E0",
  "U+29E1",
  "U+29E2",
  "U+29E3",
  "U+29E4",
  "U+29E5",
  "U+29E6",
  "U+29E7",
  "U+29E8",
  "U+29E9",
  "U+29EA",
  "U+29EB",
  "U+29EC",
  "U+29ED",
  "U+29EE",
  "U+29EF",
  "U+29F0",
  "U+29F1",
  "U+29F2",
  "U+29F3",
  "U+29F4",
  "U+29F5",
  "U+29F6",
  "U+29F7",
  "U+29F8",
  "U+29F9",
  "U+29FA",
  "U+29FB",
  "U+29FE",
  "U+29FF",
  "U+2A00",
  "U+2A01",
  "U+2A02",
  "U+2A03",
  "U+2A04",
  "U+2A05",
  "U+2A06",
  "U+2A07",
  "U+2A08",
  "U+2A09",
  "U+2A0A",
  "U+2A0B",
  "U+2A0C",
  "U+2A0D",
  "U+2A0E",
  "U+2A0F",
  "U+2A10",
  "U+2A11",
  "U+2A12",
  "U+2A13",
  "U+2A14",
  "U+2A15",
  "U+2A16",
  "U+2A17",
  "U+2A18",
  "U+2A19",
  "U+2A1A",
  "U+2A1B",
  "U+2A1C",
  "U+2A1D",
  "U+2A1E",
  "U+2A1F",
  "U+2A20",
  "U+2A21",
  "U+2A22",
  "U+2A23",
  "U+2A24",
  "U+2A25",
  "U+2A26",
  "U+2A27",
  "U+2A28",
  "U+2A29",
  "U+2A2A",
  "U+2A2B",
  "U+2A2C",
  "U+2A2D",
  "U+2A2E",
  "U+2A2F",
  "U+2A30",
  "U+2A31",
  "U+2A32",
  "U+2A33",
  "U+2A34",
  "U+2A35",
  "U+2A36",
  "U+2A37",
  "U+2A38",
  "U+2A39",
  "U+2A3A",
  "U+2A3B",
  "U+2A3C",
  "U+2A3D",
  "U+2A3E",
  "U+2A3F",
  "U+2A40",
  "U+2A41",
  "U+2A42",
  "U+2A43",
  "U+2A44",
  "U+2A45",
  "U+2A46",
  "U+2A47",
  "U+2A48",
  "U+2A49",
  "U+2A4A",
  "U+2A4B",
  "U+2A4C",
  "U+2A4D",
  "U+2A4E",
  "U+2A4F",
  "U+2A50",
  "U+2A51",
  "U+2A52",
  "U+2A53",
  "U+2A54",
  "U+2A55",
  "U+2A56",
  "U+2A57",
  "U+2A58",
  "U+2A59",
  "U+2A5A",
  "U+2A5B",
  "U+2A5C",
  "U+2A5D",
  "U+2A5E",
  "U+2A5F",
  "U+2A60",
  "U+2A61",
  "U+2A62",
  "U+2A63",
  "U+2A64",
  "U+2A65",
  "U+2A66",
  "U+2A67",
  "U+2A68",
  "U+2A69",
  "U+2A6A",
  "U+2A6B",
  "U+2A6C",
  "U+2A6D",
  "U+2A6E",
  "U+2A6F",
  "U+2A70",
  "U+2A71",
  "U+2A72",
  "U+2A73",
  "U+2A74",
  "U+2A75",
  "U+2A76",
  "U+2A77",
  "U+2A78",
  "U+2A79",
  "U+2A7A",
  "U+2A7B",
  "U+2A7C",
  "U+2A7D",
  "U+2A7E",
  "U+2A7F",
  "U+2A80",
  "U+2A81",
  "U+2A82",
  "U+2A83",
  "U+2A84",
  "U+2A85",
  "U+2A86",
  "U+2A87",
  "U+2A88",
  "U+2A89",
  "U+2A8A",
  "U+2A8B",
  "U+2A8C",
  "U+2A8D",
  "U+2A8E",
  "U+2A8F",
  "U+2A90",
  "U+2A91",
  "U+2A92",
  "U+2A93",
  "U+2A94",
  "U+2A95",
  "U+2A96",
  "U+2A97",
  "U+2A98",
  "U+2A99",
  "U+2A9A",
  "U+2A9B",
  "U+2A9C",
  "U+2A9D",
  "U+2A9E",
  "U+2A9F",
  "U+2AA0",
  "U+2AA1",
  "U+2AA2",
  "U+2AA3",
  "U+2AA4",
  "U+2AA5",
  "U+2AA6",
  "U+2AA7",
  "U+2AA8",
  "U+2AA9",
  "U+2AAA",
  "U+2AAB",
  "U+2AAC",
  "U+2AAD",
  "U+2AAE",
  "U+2AAF",
  "U+2AB0",
  "U+2AB1",
  "U+2AB2",
  "U+2AB3",
  "U+2AB4",
  "U+2AB5",
  "U+2AB6",
  "U+2AB7",
  "U+2AB8",
  "U+2AB9",
  "U+2ABA",
  "U+2ABB",
  "U+2ABC",
  "U+2ABD",
  "U+2ABE",
  "U+2ABF",
  "U+2AC0",
  "U+2AC1",
  "U+2AC2",
  "U+2AC3",
  "U+2AC4",
  "U+2AC5",
  "U+2AC6",
  "U+2AC7",
  "U+2AC8",
  "U+2AC9",
  "U+2ACA",
  "U+2ACB",
  "U+2ACC",
  "U+2ACD",
  "U+2ACE",
  "U+2ACF",
  "U+2AD0",
  "U+2AD1",
  "U+2AD2",
  "U+2AD3",
  "U+2AD4",
  "U+2AD5",
  "U+2AD6",
  "U+2AD7",
  "U+2AD8",
  "U+2AD9",
  "U+2ADA",
  "U+2ADB",
  "U+2ADC",
  "U+2ADD",
  "U+2ADE",
  "U+2ADF",
  "U+2AE0",
  "U+2AE1",
  "U+2AE2",
  "U+2AE3",
  "U+2AE4",
  "U+2AE5",
  "U+2AE6",
  "U+2AE7",
  "U+2AE8",
  "U+2AE9",
  "U+2AEA",
  "U+2AEB",
  "U+2AEC",
  "U+2AED",
  "U+2AEE",
  "U+2AEF",
  "U+2AF0",
  "U+2AF1",
  "U+2AF2",
  "U+2AF3",
  "U+2AF4",
  "U+2AF5",
  "U+2AF6",
  "U+2AF7",
  "U+2AF8",
  "U+2AF9",
  "U+2AFA",
  "U+2AFB",
  "U+2AFC",
  "U+2AFD",
  "U+2AFE",
  "U+2AFF",
  "U+2B00",
  "U+2B01",
  "U+2B02",
  "U+2B03",
  "U+2B04",
  "U+2B05",
  "U+2B06",
  "U+2B07",
  "U+2B08",
  "U+2B09",
  "U+2B0A",
  "U+2B0B",
  "U+2B0C",
  "U+2B0D",
  "U+2B0E",
  "U+2B0F",
  "U+2B10",
  "U+2B11",
  "U+2B12",
  "U+2B13",
  "U+2B14",
  "U+2B15",
  "U+2B16",
  "U+2B17",
  "U+2B18",
  "U+2B19",
  "U+2B1A",
  "U+2B1B",
  "U+2B1C",
  "U+2B1D",
  "U+2B1E",
  "U+2B1F",
  "U+2B20",
  "U+2B21",
  "U+2B22",
  "U+2B23",
  "U+2B24",
  "U+2B25",
  "U+2B26",
  "U+2B27",
  "U+2B28",
  "U+2B29",
  "U+2B2A",
  "U+2B2B",
  "U+2B2C",
  "U+2B2D",
  "U+2B2E",
  "U+2B2F",
  "U+2B30",
  "U+2B31",
  "U+2B32",
  "U+2B33",
  "U+2B34",
  "U+2B35",
  "U+2B36",
  "U+2B37",
  "U+2B38",
  "U+2B39",
  "U+2B3A",
  "U+2B3B",
  "U+2B3C",
  "U+2B3D",
  "U+2B3E",
  "U+2B3F",
  "U+2B40",
  "U+2B41",
  "U+2B42",
  "U+2B43",
  "U+2B44",
  "U+2B45",
  "U+2B46",
  "U+2B47",
  "U+2B48",
  "U+2B49",
  "U+2B4A",
  "U+2B4B",
  "U+2B4C",
  "U+2B4D",
  "U+2B4E",
  "U+2B4F",
  "U+2B50",
  "U+2B51",
  "U+2B52",
  "U+2B53",
  "U+2B54",
  "U+2B55",
  "U+2B56",
  "U+2B57",
  "U+2B58",
  "U+2B59",
  "U+2B5A",
  "U+2B5B",
  "U+2B5C",
  "U+2B5D",
  "U+2B5E",
  "U+2B5F",
  "U+2B60",
  "U+2B61",
  "U+2B62",
  "U+2B63",
  "U+2B64",
  "U+2B65",
  "U+2B66",
  "U+2B67",
  "U+2B68",
  "U+2B69",
  "U+2B6A",
  "U+2B6B",
  "U+2B6C",
  "U+2B6D",
  "U+2B6E",
  "U+2B6F",
  "U+2B70",
  "U+2B71",
  "U+2B72",
  "U+2B73",
  "U+2B76",
  "U+2B77",
  "U+2B78",
  "U+2B79",
  "U+2B7A",
  "U+2B7B",
  "U+2B7C",
  "U+2B7D",
  "U+2B7E",
  "U+2B7F",
  "U+2B80",
  "U+2B81",
  "U+2B82",
  "U+2B83",
  "U+2B84",
  "U+2B85",
  "U+2B86",
  "U+2B87",
  "U+2B88",
  "U+2B89",
  "U+2B8A",
  "U+2B8B",
  "U+2B8C",
  "U+2B8D",
  "U+2B8E",
  "U+2B8F",
  "U+2B90",
  "U+2B91",
  "U+2B92",
  "U+2B93",
  "U+2B94",
  "U+2B95",
  "U+2B97",
  "U+2B98",
  "U+2B99",
  "U+2B9A",
  "U+2B9B",
  "U+2B9C",
  "U+2B9D",
  "U+2B9E",
  "U+2B9F",
  "U+2BA0",
  "U+2BA1",
  "U+2BA2",
  "U+2BA3",
  "U+2BA4",
  "U+2BA5",
  "U+2BA6",
  "U+2BA7",
  "U+2BA8",
  "U+2BA9",
  "U+2BAA",
  "U+2BAB",
  "U+2BAC",
  "U+2BAD",
  "U+2BAE",
  "U+2BAF",
  "U+2BB0",
  "U+2BB1",
  "U+2BB2",
  "U+2BB3",
  "U+2BB4",
  "U+2BB5",
  "U+2BB6",
  "U+2BB7",
  "U+2BB8",
  "U+2BB9",
  "U+2BBA",
  "U+2BBB",
  "U+2BBC",
  "U+2BBD",
  "U+2BBE",
  "U+2BBF",
  "U+2BC0",
  "U+2BC1",
  "U+2BC2",
  "U+2BC3",
  "U+2BC4",
  "U+2BC5",
  "U+2BC6",
  "U+2BC7",
  "U+2BC8",
  "U+2BC9",
  "U+2BCA",
  "U+2BCB",
  "U+2BCC",
  "U+2BCD",
  "U+2BCE",
  "U+2BCF",
  "U+2BD0",
  "U+2BD1",
  "U+2BD2",
  "U+2BD3",
  "U+2BD4",
  "U+2BD5",
  "U+2BD6",
  "U+2BD7",
  "U+2BD8",
  "U+2BD9",
  "U+2BDA",
  "U+2BDB",
  "U+2BDC",
  "U+2BDD",
  "U+2BDE",
  "U+2BDF",
  "U+2BE0",
  "U+2BE1",
  "U+2BE2",
  "U+2BE3",
  "U+2BE4",
  "U+2BE5",
  "U+2BE6",
  "U+2BE7",
  "U+2BE8",
  "U+2BE9",
  "U+2BEA",
  "U+2BEB",
  "U+2BEC",
  "U+2BED",
  "U+2BEE",
  "U+2BEF",
  "U+2BF0",
  "U+2BF1",
  "U+2BF2",
  "U+2BF3",
  "U+2BF4",
  "U+2BF5",
  "U+2BF6",
  "U+2BF7",
  "U+2BF8",
  "U+2BF9",
  "U+2BFA",
  "U+2BFB",
  "U+2BFC",
  "U+2BFD",
  "U+2BFE",
  "U+2BFF",
  "U+2CE5",
  "U+2CE6",
  "U+2CE7",
  "U+2CE8",
  "U+2CE9",
  "U+2CEA",
  "U+2E50",
  "U+2E51",
  "U+2E80",
  "U+2E81",
  "U+2E82",
  "U+2E83",
  "U+2E84",
  "U+2E85",
  "U+2E86",
  "U+2E87",
  "U+2E88",
  "U+2E89",
  "U+2E8A",
  "U+2E8B",
  "U+2E8C",
  "U+2E8D",
  "U+2E8E",
  "U+2E8F",
  "U+2E90",
  "U+2E91",
  "U+2E92",
  "U+2E93",
  "U+2E94",
  "U+2E95",
  "U+2E96",
  "U+2E97",
  "U+2E98",
  "U+2E99",
  "U+2E9B",
  "U+2E9C",
  "U+2E9D",
  "U+2E9E",
  "U+2E9F",
  "U+2EA0",
  "U+2EA1",
  "U+2EA2",
  "U+2EA3",
  "U+2EA4",
  "U+2EA5",
  "U+2EA6",
  "U+2EA7",
  "U+2EA8",
  "U+2EA9",
  "U+2EAA",
  "U+2EAB",
  "U+2EAC",
  "U+2EAD",
  "U+2EAE",
  "U+2EAF",
  "U+2EB0",
  "U+2EB1",
  "U+2EB2",
  "U+2EB3",
  "U+2EB4",
  "U+2EB5",
  "U+2EB6",
  "U+2EB7",
  "U+2EB8",
  "U+2EB9",
  "U+2EBA",
  "U+2EBB",
  "U+2EBC",
  "U+2EBD",
  "U+2EBE",
  "U+2EBF",
  "U+2EC0",
  "U+2EC1",
  "U+2EC2",
  "U+2EC3",
  "U+2EC4",
  "U+2EC5",
  "U+2EC6",
  "U+2EC7",
  "U+2EC8",
  "U+2EC9",
  "U+2ECA",
  "U+2ECB",
  "U+2ECC",
  "U+2ECD",
  "U+2ECE",
  "U+2ECF",
  "U+2ED0",
  "U+2ED1",
  "U+2ED2",
  "U+2ED3",
  "U+2ED4",
  "U+2ED5",
  "U+2ED6",
  "U+2ED7",
  "U+2ED8",
  "U+2ED9",
  "U+2EDA",
  "U+2EDB",
  "U+2EDC",
  "U+2EDD",
  "U+2EDE",
  "U+2EDF",
  "U+2EE0",
  "U+2EE1",
  "U+2EE2",
  "U+2EE3",
  "U+2EE4",
  "U+2EE5",
  "U+2EE6",
  "U+2EE7",
  "U+2EE8",
  "U+2EE9",
  "U+2EEA",
  "U+2EEB",
  "U+2EEC",
  "U+2EED",
  "U+2EEE",
  "U+2EEF",
  "U+2EF0",
  "U+2EF1",
  "U+2EF2",
  "U+2EF3",
  "U+2F00",
  "U+2F01",
  "U+2F02",
  "U+2F03",
  "U+2F04",
  "U+2F05",
  "U+2F06",
  "U+2F07",
  "U+2F08",
  "U+2F09",
  "U+2F0A",
  "U+2F0B",
  "U+2F0C",
  "U+2F0D",
  "U+2F0E",
  "U+2F0F",
  "U+2F10",
  "U+2F11",
  "U+2F12",
  "U+2F13",
  "U+2F14",
  "U+2F15",
  "U+2F16",
  "U+2F17",
  "U+2F18",
  "U+2F19",
  "U+2F1A",
  "U+2F1B",
  "U+2F1C",
  "U+2F1D",
  "U+2F1E",
  "U+2F1F",
  "U+2F20",
  "U+2F21",
  "U+2F22",
  "U+2F23",
  "U+2F24",
  "U+2F25",
  "U+2F26",
  "U+2F27",
  "U+2F28",
  "U+2F29",
  "U+2F2A",
  "U+2F2B",
  "U+2F2C",
  "U+2F2D",
  "U+2F2E",
  "U+2F2F",
  "U+2F30",
  "U+2F31",
  "U+2F32",
  "U+2F33",
  "U+2F34",
  "U+2F35",
  "U+2F36",
  "U+2F37",
  "U+2F38",
  "U+2F39",
  "U+2F3A",
  "U+2F3B",
  "U+2F3C",
  "U+2F3D",
  "U+2F3E",
  "U+2F3F",
  "U+2F40",
  "U+2F41",
  "U+2F42",
  "U+2F43",
  "U+2F44",
  "U+2F45",
  "U+2F46",
  "U+2F47",
  "U+2F48",
  "U+2F49",
  "U+2F4A",
  "U+2F4B",
  "U+2F4C",
  "U+2F4D",
  "U+2F4E",
  "U+2F4F",
  "U+2F50",
  "U+2F51",
  "U+2F52",
  "U+2F53",
  "U+2F54",
  "U+2F55",
  "U+2F56",
  "U+2F57",
  "U+2F58",
  "U+2F59",
  "U+2F5A",
  "U+2F5B",
  "U+2F5C",
  "U+2F5D",
  "U+2F5E",
  "U+2F5F",
  "U+2F60",
  "U+2F61",
  "U+2F62",
  "U+2F63",
  "U+2F64",
  "U+2F65",
  "U+2F66",
  "U+2F67",
  "U+2F68",
  "U+2F69",
  "U+2F6A",
  "U+2F6B",
  "U+2F6C",
  "U+2F6D",
  "U+2F6E",
  "U+2F6F",
  "U+2F70",
  "U+2F71",
  "U+2F72",
  "U+2F73",
  "U+2F74",
  "U+2F75",
  "U+2F76",
  "U+2F77",
  "U+2F78",
  "U+2F79",
  "U+2F7A",
  "U+2F7B",
  "U+2F7C",
  "U+2F7D",
  "U+2F7E",
  "U+2F7F",
  "U+2F80",
  "U+2F81",
  "U+2F82",
  "U+2F83",
  "U+2F84",
  "U+2F85",
  "U+2F86",
  "U+2F87",
  "U+2F88",
  "U+2F89",
  "U+2F8A",
  "U+2F8B",
  "U+2F8C",
  "U+2F8D",
  "U+2F8E",
  "U+2F8F",
  "U+2F90",
  "U+2F91",
  "U+2F92",
  "U+2F93",
  "U+2F94",
  "U+2F95",
  "U+2F96",
  "U+2F97",
  "U+2F98",
  "U+2F99",
  "U+2F9A",
  "U+2F9B",
  "U+2F9C",
  "U+2F9D",
  "U+2F9E",
  "U+2F9F",
  "U+2FA0",
  "U+2FA1",
  "U+2FA2",
  "U+2FA3",
  "U+2FA4",
  "U+2FA5",
  "U+2FA6",
  "U+2FA7",
  "U+2FA8",
  "U+2FA9",
  "U+2FAA",
  "U+2FAB",
  "U+2FAC",
  "U+2FAD",
  "U+2FAE",
  "U+2FAF",
  "U+2FB0",
  "U+2FB1",
  "U+2FB2",
  "U+2FB3",
  "U+2FB4",
  "U+2FB5",
  "U+2FB6",
  "U+2FB7",
  "U+2FB8",
  "U+2FB9",
  "U+2FBA",
  "U+2FBB",
  "U+2FBC",
  "U+2FBD",
  "U+2FBE",
  "U+2FBF",
  "U+2FC0",
  "U+2FC1",
  "U+2FC2",
  "U+2FC3",
  "U+2FC4",
  "U+2FC5",
  "U+2FC6",
  "U+2FC7",
  "U+2FC8",
  "U+2FC9",
  "U+2FCA",
  "U+2FCB",
  "U+2FCC",
  "U+2FCD",
  "U+2FCE",
  "U+2FCF",
  "U+2FD0",
  "U+2FD1",
  "U+2FD2",
  "U+2FD3",
  "U+2FD4",
  "U+2FD5",
  "U+2FF0",
  "U+2FF1",
  "U+2FF2",
  "U+2FF3",
  "U+2FF4",
  "U+2FF5",
  "U+2FF6",
  "U+2FF7",
  "U+2FF8",
  "U+2FF9",
  "U+2FFA",
  "U+2FFB",
  "U+3004",
  "U+3012",
  "U+3013",
  "U+3020",
  "U+3036",
  "U+3037",
  "U+303E",
  "U+303F",
  "U+309B",
  "U+309C",
  "U+3190",
  "U+3191",
  "U+3196",
  "U+3197",
  "U+3198",
  "U+3199",
  "U+319A",
  "U+319B",
  "U+319C",
  "U+319D",
  "U+319E",
  "U+319F",
  "U+31C0",
  "U+31C1",
  "U+31C2",
  "U+31C3",
  "U+31C4",
  "U+31C5",
  "U+31C6",
  "U+31C7",
  "U+31C8",
  "U+31C9",
  "U+31CA",
  "U+31CB",
  "U+31CC",
  "U+31CD",
  "U+31CE",
  "U+31CF",
  "U+31D0",
  "U+31D1",
  "U+31D2",
  "U+31D3",
  "U+31D4",
  "U+31D5",
  "U+31D6",
  "U+31D7",
  "U+31D8",
  "U+31D9",
  "U+31DA",
  "U+31DB",
  "U+31DC",
  "U+31DD",
  "U+31DE",
  "U+31DF",
  "U+31E0",
  "U+31E1",
  "U+31E2",
  "U+31E3",
  "U+3200",
  "U+3201",
  "U+3202",
  "U+3203",
  "U+3204",
  "U+3205",
  "U+3206",
  "U+3207",
  "U+3208",
  "U+3209",
  "U+320A",
  "U+320B",
  "U+320C",
  "U+320D",
  "U+320E",
  "U+320F",
  "U+3210",
  "U+3211",
  "U+3212",
  "U+3213",
  "U+3214",
  "U+3215",
  "U+3216",
  "U+3217",
  "U+3218",
  "U+3219",
  "U+321A",
  "U+321B",
  "U+321C",
  "U+321D",
  "U+321E",
  "U+322A",
  "U+322B",
  "U+322C",
  "U+322D",
  "U+322E",
  "U+322F",
  "U+3230",
  "U+3231",
  "U+3232",
  "U+3233",
  "U+3234",
  "U+3235",
  "U+3236",
  "U+3237",
  "U+3238",
  "U+3239",
  "U+323A",
  "U+323B",
  "U+323C",
  "U+323D",
  "U+323E",
  "U+323F",
  "U+3240",
  "U+3241",
  "U+3242",
  "U+3243",
  "U+3244",
  "U+3245",
  "U+3246",
  "U+3247",
  "U+3250",
  "U+3260",
  "U+3261",
  "U+3262",
  "U+3263",
  "U+3264",
  "U+3265",
  "U+3266",
  "U+3267",
  "U+3268",
  "U+3269",
  "U+326A",
  "U+326B",
  "U+326C",
  "U+326D",
  "U+326E",
  "U+326F",
  "U+3270",
  "U+3271",
  "U+3272",
  "U+3273",
  "U+3274",
  "U+3275",
  "U+3276",
  "U+3277",
  "U+3278",
  "U+3279",
  "U+327A",
  "U+327B",
  "U+327C",
  "U+327D",
  "U+327E",
  "U+327F",
  "U+328A",
  "U+328B",
  "U+328C",
  "U+328D",
  "U+328E",
  "U+328F",
  "U+3290",
  "U+3291",
  "U+3292",
  "U+3293",
  "U+3294",
  "U+3295",
  "U+3296",
  "U+3297",
  "U+3298",
  "U+3299",
  "U+329A",
  "U+329B",
  "U+329C",
  "U+329D",
  "U+329E",
  "U+329F",
  "U+32A0",
  "U+32A1",
  "U+32A2",
  "U+32A3",
  "U+32A4",
  "U+32A5",
  "U+32A6",
  "U+32A7",
  "U+32A8",
  "U+32A9",
  "U+32AA",
  "U+32AB",
  "U+32AC",
  "U+32AD",
  "U+32AE",
  "U+32AF",
  "U+32B0",
  "U+32C0",
  "U+32C1",
  "U+32C2",
  "U+32C3",
  "U+32C4",
  "U+32C5",
  "U+32C6",
  "U+32C7",
  "U+32C8",
  "U+32C9",
  "U+32CA",
  "U+32CB",
  "U+32CC",
  "U+32CD",
  "U+32CE",
  "U+32CF",
  "U+32D0",
  "U+32D1",
  "U+32D2",
  "U+32D3",
  "U+32D4",
  "U+32D5",
  "U+32D6",
  "U+32D7",
  "U+32D8",
  "U+32D9",
  "U+32DA",
  "U+32DB",
  "U+32DC",
  "U+32DD",
  "U+32DE",
  "U+32DF",
  "U+32E0",
  "U+32E1",
  "U+32E2",
  "U+32E3",
  "U+32E4",
  "U+32E5",
  "U+32E6",
  "U+32E7",
  "U+32E8",
  "U+32E9",
  "U+32EA",
  "U+32EB",
  "U+32EC",
  "U+32ED",
  "U+32EE",
  "U+32EF",
  "U+32F0",
  "U+32F1",
  "U+32F2",
  "U+32F3",
  "U+32F4",
  "U+32F5",
  "U+32F6",
  "U+32F7",
  "U+32F8",
  "U+32F9",
  "U+32FA",
  "U+32FB",
  "U+32FC",
  "U+32FD",
  "U+32FE",
  "U+32FF",
  "U+3300",
  "U+3301",
  "U+3302",
  "U+3303",
  "U+3304",
  "U+3305",
  "U+3306",
  "U+3307",
  "U+3308",
  "U+3309",
  "U+330A",
  "U+330B",
  "U+330C",
  "U+330D",
  "U+330E",
  "U+330F",
  "U+3310",
  "U+3311",
  "U+3312",
  "U+3313",
  "U+3314",
  "U+3315",
  "U+3316",
  "U+3317",
  "U+3318",
  "U+3319",
  "U+331A",
  "U+331B",
  "U+331C",
  "U+331D",
  "U+331E",
  "U+331F",
  "U+3320",
  "U+3321",
  "U+3322",
  "U+3323",
  "U+3324",
  "U+3325",
  "U+3326",
  "U+3327",
  "U+3328",
  "U+3329",
  "U+332A",
  "U+332B",
  "U+332C",
  "U+332D",
  "U+332E",
  "U+332F",
  "U+3330",
  "U+3331",
  "U+3332",
  "U+3333",
  "U+3334",
  "U+3335",
  "U+3336",
  "U+3337",
  "U+3338",
  "U+3339",
  "U+333A",
  "U+333B",
  "U+333C",
  "U+333D",
  "U+333E",
  "U+333F",
  "U+3340",
  "U+3341",
  "U+3342",
  "U+3343",
  "U+3344",
  "U+3345",
  "U+3346",
  "U+3347",
  "U+3348",
  "U+3349",
  "U+334A",
  "U+334B",
  "U+334C",
  "U+334D",
  "U+334E",
  "U+334F",
  "U+3350",
  "U+3351",
  "U+3352",
  "U+3353",
  "U+3354",
  "U+3355",
  "U+3356",
  "U+3357",
  "U+3358",
  "U+3359",
  "U+335A",
  "U+335B",
  "U+335C",
  "U+335D",
  "U+335E",
  "U+335F",
  "U+3360",
  "U+3361",
  "U+3362",
  "U+3363",
  "U+3364",
  "U+3365",
  "U+3366",
  "U+3367",
  "U+3368",
  "U+3369",
  "U+336A",
  "U+336B",
  "U+336C",
  "U+336D",
  "U+336E",
  "U+336F",
  "U+3370",
  "U+3371",
  "U+3372",
  "U+3373",
  "U+3374",
  "U+3375",
  "U+3376",
  "U+3377",
  "U+3378",
  "U+3379",
  "U+337A",
  "U+337B",
  "U+337C",
  "U+337D",
  "U+337E",
  "U+337F",
  "U+3380",
  "U+3381",
  "U+3382",
  "U+3383",
  "U+3384",
  "U+3385",
  "U+3386",
  "U+3387",
  "U+3388",
  "U+3389",
  "U+338A",
  "U+338B",
  "U+338C",
  "U+338D",
  "U+338E",
  "U+338F",
  "U+3390",
  "U+3391",
  "U+3392",
  "U+3393",
  "U+3394",
  "U+3395",
  "U+3396",
  "U+3397",
  "U+3398",
  "U+3399",
  "U+339A",
  "U+339B",
  "U+339C",
  "U+339D",
  "U+339E",
  "U+339F",
  "U+33A0",
  "U+33A1",
  "U+33A2",
  "U+33A3",
  "U+33A4",
  "U+33A5",
  "U+33A6",
  "U+33A7",
  "U+33A8",
  "U+33A9",
  "U+33AA",
  "U+33AB",
  "U+33AC",
  "U+33AD",
  "U+33AE",
  "U+33AF",
  "U+33B0",
  "U+33B1",
  "U+33B2",
  "U+33B3",
  "U+33B4",
  "U+33B5",
  "U+33B6",
  "U+33B7",
  "U+33B8",
  "U+33B9",
  "U+33BA",
  "U+33BB",
  "U+33BC",
  "U+33BD",
  "U+33BE",
  "U+33BF",
  "U+33C0",
  "U+33C1",
  "U+33C2",
  "U+33C3",
  "U+33C4",
  "U+33C5",
  "U+33C6",
  "U+33C7",
  "U+33C8",
  "U+33C9",
  "U+33CA",
  "U+33CB",
  "U+33CC",
  "U+33CD",
  "U+33CE",
  "U+33CF",
  "U+33D0",
  "U+33D1",
  "U+33D2",
  "U+33D3",
  "U+33D4",
  "U+33D5",
  "U+33D6",
  "U+33D7",
  "U+33D8",
  "U+33D9",
  "U+33DA",
  "U+33DB",
  "U+33DC",
  "U+33DD",
  "U+33DE",
  "U+33DF",
  "U+33E0",
  "U+33E1",
  "U+33E2",
  "U+33E3",
  "U+33E4",
  "U+33E5",
  "U+33E6",
  "U+33E7",
  "U+33E8",
  "U+33E9",
  "U+33EA",
  "U+33EB",
  "U+33EC",
  "U+33ED",
  "U+33EE",
  "U+33EF",
  "U+33F0",
  "U+33F1",
  "U+33F2",
  "U+33F3",
  "U+33F4",
  "U+33F5",
  "U+33F6",
  "U+33F7",
  "U+33F8",
  "U+33F9",
  "U+33FA",
  "U+33FB",
  "U+33FC",
  "U+33FD",
  "U+33FE",
  "U+33FF",
  "U+4DC0",
  "U+4DC1",
  "U+4DC2",
  "U+4DC3",
  "U+4DC4",
  "U+4DC5",
  "U+4DC6",
  "U+4DC7",
  "U+4DC8",
  "U+4DC9",
  "U+4DCA",
  "U+4DCB",
  "U+4DCC",
  "U+4DCD",
  "U+4DCE",
  "U+4DCF",
  "U+4DD0",
  "U+4DD1",
  "U+4DD2",
  "U+4DD3",
  "U+4DD4",
  "U+4DD5",
  "U+4DD6",
  "U+4DD7",
  "U+4DD8",
  "U+4DD9",
  "U+4DDA",
  "U+4DDB",
  "U+4DDC",
  "U+4DDD",
  "U+4DDE",
  "U+4DDF",
  "U+4DE0",
  "U+4DE1",
  "U+4DE2",
  "U+4DE3",
  "U+4DE4",
  "U+4DE5",
  "U+4DE6",
  "U+4DE7",
  "U+4DE8",
  "U+4DE9",
  "U+4DEA",
  "U+4DEB",
  "U+4DEC",
  "U+4DED",
  "U+4DEE",
  "U+4DEF",
  "U+4DF0",
  "U+4DF1",
  "U+4DF2",
  "U+4DF3",
  "U+4DF4",
  "U+4DF5",
  "U+4DF6",
  "U+4DF7",
  "U+4DF8",
  "U+4DF9",
  "U+4DFA",
  "U+4DFB",
  "U+4DFC",
  "U+4DFD",
  "U+4DFE",
  "U+4DFF",
  "U+A490",
  "U+A491",
  "U+A492",
  "U+A493",
  "U+A494",
  "U+A495",
  "U+A496",
  "U+A497",
  "U+A498",
  "U+A499",
  "U+A49A",
  "U+A49B",
  "U+A49C",
  "U+A49D",
  "U+A49E",
  "U+A49F",
  "U+A4A0",
  "U+A4A1",
  "U+A4A2",
  "U+A4A3",
  "U+A4A4",
  "U+A4A5",
  "U+A4A6",
  "U+A4A7",
  "U+A4A8",
  "U+A4A9",
  "U+A4AA",
  "U+A4AB",
  "U+A4AC",
  "U+A4AD",
  "U+A4AE",
  "U+A4AF",
  "U+A4B0",
  "U+A4B1",
  "U+A4B2",
  "U+A4B3",
  "U+A4B4",
  "U+A4B5",
  "U+A4B6",
  "U+A4B7",
  "U+A4B8",
  "U+A4B9",
  "U+A4BA",
  "U+A4BB",
  "U+A4BC",
  "U+A4BD",
  "U+A4BE",
  "U+A4BF",
  "U+A4C0",
  "U+A4C1",
  "U+A4C2",
  "U+A4C3",
  "U+A4C4",
  "U+A4C5",
  "U+A4C6",
  "U+A700",
  "U+A701",
  "U+A702",
  "U+A703",
  "U+A704",
  "U+A705",
  "U+A706",
  "U+A707",
  "U+A708",
  "U+A709",
  "U+A70A",
  "U+A70B",
  "U+A70C",
  "U+A70D",
  "U+A70E",
  "U+A70F",
  "U+A710",
  "U+A711",
  "U+A712",
  "U+A713",
  "U+A714",
  "U+A715",
  "U+A716",
  "U+A720",
  "U+A721",
  "U+A789",
  "U+A78A",
  "U+A828",
  "U+A829",
  "U+A82A",
  "U+A82B",
  "U+A836",
  "U+A837",
  "U+A838",
  "U+A839",
  "U+AA77",
  "U+AA78",
  "U+AA79",
  "U+AB5B",
  "U+AB6A",
  "U+AB6B",
  "U+FB29",
  "U+FBB2",
  "U+FBB3",
  "U+FBB4",
  "U+FBB5",
  "U+FBB6",
  "U+FBB7",
  "U+FBB8",
  "U+FBB9",
  "U+FBBA",
  "U+FBBB",
  "U+FBBC",
  "U+FBBD",
  "U+FBBE",
  "U+FBBF",
  "U+FBC0",
  "U+FBC1",
  "U+FBC2",
  "U+FD40",
  "U+FD41",
  "U+FD42",
  "U+FD43",
  "U+FD44",
  "U+FD45",
  "U+FD46",
  "U+FD47",
  "U+FD48",
  "U+FD49",
  "U+FD4A",
  "U+FD4B",
  "U+FD4C",
  "U+FD4D",
  "U+FD4E",
  "U+FD4F",
  "U+FDCF",
  "U+FDFC",
  "U+FDFD",
  "U+FDFE",
  "U+FDFF",
  "U+FE62",
  "U+FE64",
  "U+FE65",
  "U+FE66",
  "U+FE69",
  "U+FF04",
  "U+FF0B",
  "U+FF1C",
  "U+FF1D",
  "U+FF1E",
  "U+FF3E",
  "U+FF40",
  "U+FF5C",
  "U+FF5E",
  "U+FFE0",
  "U+FFE1",
  "U+FFE2",
  "U+FFE3",
  "U+FFE4",
  "U+FFE5",
  "U+FFE6",
  "U+FFE8",
  "U+FFE9",
  "U+FFEA",
  "U+FFEB",
  "U+FFEC",
  "U+FFED",
  "U+FFEE",
  "U+FFFC",
  "U+FFFD",
  "U+10137",
  "U+10138",
  "U+10139",
  "U+1013A",
  "U+1013B",
  "U+1013C",
  "U+1013D",
  "U+1013E",
  "U+1013F",
  "U+10179",
  "U+1017A",
  "U+1017B",
  "U+1017C",
  "U+1017D",
  "U+1017E",
  "U+1017F",
  "U+10180",
  "U+10181",
  "U+10182",
  "U+10183",
  "U+10184",
  "U+10185",
  "U+10186",
  "U+10187",
  "U+10188",
  "U+10189",
  "U+1018C",
  "U+1018D",
  "U+1018E",
  "U+10190",
  "U+10191",
  "U+10192",
  "U+10193",
  "U+10194",
  "U+10195",
  "U+10196",
  "U+10197",
  "U+10198",
  "U+10199",
  "U+1019A",
  "U+1019B",
  "U+1019C",
  "U+101A0",
  "U+101D0",
  "U+101D1",
  "U+101D2",
  "U+101D3",
  "U+101D4",
  "U+101D5",
  "U+101D6",
  "U+101D7",
  "U+101D8",
  "U+101D9",
  "U+101DA",
  "U+101DB",
  "U+101DC",
  "U+101DD",
  "U+101DE",
  "U+101DF",
  "U+101E0",
  "U+101E1",
  "U+101E2",
  "U+101E3",
  "U+101E4",
  "U+101E5",
  "U+101E6",
  "U+101E7",
  "U+101E8",
  "U+101E9",
  "U+101EA",
  "U+101EB",
  "U+101EC",
  "U+101ED",
  "U+101EE",
  "U+101EF",
  "U+101F0",
  "U+101F1",
  "U+101F2",
  "U+101F3",
  "U+101F4",
  "U+101F5",
  "U+101F6",
  "U+101F7",
  "U+101F8",
  "U+101F9",
  "U+101FA",
  "U+101FB",
  "U+101FC",
  "U+10877",
  "U+10878",
  "U+10AC8",
  "U+1173F",
  "U+11FD5",
  "U+11FD6",
  "U+11FD7",
  "U+11FD8",
  "U+11FD9",
  "U+11FDA",
  "U+11FDB",
  "U+11FDC",
  "U+11FDD",
  "U+11FDE",
  "U+11FDF",
  "U+11FE0",
  "U+11FE1",
  "U+11FE2",
  "U+11FE3",
  "U+11FE4",
  "U+11FE5",
  "U+11FE6",
  "U+11FE7",
  "U+11FE8",
  "U+11FE9",
  "U+11FEA",
  "U+11FEB",
  "U+11FEC",
  "U+11FED",
  "U+11FEE",
  "U+11FEF",
  "U+11FF0",
  "U+11FF1",
  "U+16B3C",
  "U+16B3D",
  "U+16B3E",
  "U+16B3F",
  "U+16B45",
  "U+1BC9C",
  "U+1CF50",
  "U+1CF51",
  "U+1CF52",
  "U+1CF53",
  "U+1CF54",
  "U+1CF55",
  "U+1CF56",
  "U+1CF57",
  "U+1CF58",
  "U+1CF59",
  "U+1CF5A",
  "U+1CF5B",
  "U+1CF5C",
  "U+1CF5D",
  "U+1CF5E",
  "U+1CF5F",
  "U+1CF60",
  "U+1CF61",
  "U+1CF62",
  "U+1CF63",
  "U+1CF64",
  "U+1CF65",
  "U+1CF66",
  "U+1CF67",
  "U+1CF68",
  "U+1CF69",
  "U+1CF6A",
  "U+1CF6B",
  "U+1CF6C",
  "U+1CF6D",
  "U+1CF6E",
  "U+1CF6F",
  "U+1CF70",
  "U+1CF71",
  "U+1CF72",
  "U+1CF73",
  "U+1CF74",
  "U+1CF75",
  "U+1CF76",
  "U+1CF77",
  "U+1CF78",
  "U+1CF79",
  "U+1CF7A",
  "U+1CF7B",
  "U+1CF7C",
  "U+1CF7D",
  "U+1CF7E",
  "U+1CF7F",
  "U+1CF80",
  "U+1CF81",
  "U+1CF82",
  "U+1CF83",
  "U+1CF84",
  "U+1CF85",
  "U+1CF86",
  "U+1CF87",
  "U+1CF88",
  "U+1CF89",
  "U+1CF8A",
  "U+1CF8B",
  "U+1CF8C",
  "U+1CF8D",
  "U+1CF8E",
  "U+1CF8F",
  "U+1CF90",
  "U+1CF91",
  "U+1CF92",
  "U+1CF93",
  "U+1CF94",
  "U+1CF95",
  "U+1CF96",
  "U+1CF97",
  "U+1CF98",
  "U+1CF99",
  "U+1CF9A",
  "U+1CF9B",
  "U+1CF9C",
  "U+1CF9D",
  "U+1CF9E",
  "U+1CF9F",
  "U+1CFA0",
  "U+1CFA1",
  "U+1CFA2",
  "U+1CFA3",
  "U+1CFA4",
  "U+1CFA5",
  "U+1CFA6",
  "U+1CFA7",
  "U+1CFA8",
  "U+1CFA9",
  "U+1CFAA",
  "U+1CFAB",
  "U+1CFAC",
  "U+1CFAD",
  "U+1CFAE",
  "U+1CFAF",
  "U+1CFB0",
  "U+1CFB1",
  "U+1CFB2",
  "U+1CFB3",
  "U+1CFB4",
  "U+1CFB5",
  "U+1CFB6",
  "U+1CFB7",
  "U+1CFB8",
  "U+1CFB9",
  "U+1CFBA",
  "U+1CFBB",
  "U+1CFBC",
  "U+1CFBD",
  "U+1CFBE",
  "U+1CFBF",
  "U+1CFC0",
  "U+1CFC1",
  "U+1CFC2",
  "U+1CFC3",
  "U+1D000",
  "U+1D001",
  "U+1D002",
  "U+1D003",
  "U+1D004",
  "U+1D005",
  "U+1D006",
  "U+1D007",
  "U+1D008",
  "U+1D009",
  "U+1D00A",
  "U+1D00B",
  "U+1D00C",
  "U+1D00D",
  "U+1D00E",
  "U+1D00F",
  "U+1D010",
  "U+1D011",
  "U+1D012",
  "U+1D013",
  "U+1D014",
  "U+1D015",
  "U+1D016",
  "U+1D017",
  "U+1D018",
  "U+1D019",
  "U+1D01A",
  "U+1D01B",
  "U+1D01C",
  "U+1D01D",
  "U+1D01E",
  "U+1D01F",
  "U+1D020",
  "U+1D021",
  "U+1D022",
  "U+1D023",
  "U+1D024",
  "U+1D025",
  "U+1D026",
  "U+1D027",
  "U+1D028",
  "U+1D029",
  "U+1D02A",
  "U+1D02B",
  "U+1D02C",
  "U+1D02D",
  "U+1D02E",
  "U+1D02F",
  "U+1D030",
  "U+1D031",
  "U+1D032",
  "U+1D033",
  "U+1D034",
  "U+1D035",
  "U+1D036",
  "U+1D037",
  "U+1D038",
  "U+1D039",
  "U+1D03A",
  "U+1D03B",
  "U+1D03C",
  "U+1D03D",
  "U+1D03E",
  "U+1D03F",
  "U+1D040",
  "U+1D041",
  "U+1D042",
  "U+1D043",
  "U+1D044",
  "U+1D045",
  "U+1D046",
  "U+1D047",
  "U+1D048",
  "U+1D049",
  "U+1D04A",
  "U+1D04B",
  "U+1D04C",
  "U+1D04D",
  "U+1D04E",
  "U+1D04F",
  "U+1D050",
  "U+1D051",
  "U+1D052",
  "U+1D053",
  "U+1D054",
  "U+1D055",
  "U+1D056",
  "U+1D057",
  "U+1D058",
  "U+1D059",
  "U+1D05A",
  "U+1D05B",
  "U+1D05C",
  "U+1D05D",
  "U+1D05E",
  "U+1D05F",
  "U+1D060",
  "U+1D061",
  "U+1D062",
  "U+1D063",
  "U+1D064",
  "U+1D065",
  "U+1D066",
  "U+1D067",
  "U+1D068",
  "U+1D069",
  "U+1D06A",
  "U+1D06B",
  "U+1D06C",
  "U+1D06D",
  "U+1D06E",
  "U+1D06F",
  "U+1D070",
  "U+1D071",
  "U+1D072",
  "U+1D073",
  "U+1D074",
  "U+1D075",
  "U+1D076",
  "U+1D077",
  "U+1D078",
  "U+1D079",
  "U+1D07A",
  "U+1D07B",
  "U+1D07C",
  "U+1D07D",
  "U+1D07E",
  "U+1D07F",
  "U+1D080",
  "U+1D081",
  "U+1D082",
  "U+1D083",
  "U+1D084",
  "U+1D085",
  "U+1D086",
  "U+1D087",
  "U+1D088",
  "U+1D089",
  "U+1D08A",
  "U+1D08B",
  "U+1D08C",
  "U+1D08D",
  "U+1D08E",
  "U+1D08F",
  "U+1D090",
  "U+1D091",
  "U+1D092",
  "U+1D093",
  "U+1D094",
  "U+1D095",
  "U+1D096",
  "U+1D097",
  "U+1D098",
  "U+1D099",
  "U+1D09A",
  "U+1D09B",
  "U+1D09C",
  "U+1D09D",
  "U+1D09E",
  "U+1D09F",
  "U+1D0A0",
  "U+1D0A1",
  "U+1D0A2",
  "U+1D0A3",
  "U+1D0A4",
  "U+1D0A5",
  "U+1D0A6",
  "U+1D0A7",
  "U+1D0A8",
  "U+1D0A9",
  "U+1D0AA",
  "U+1D0AB",
  "U+1D0AC",
  "U+1D0AD",
  "U+1D0AE",
  "U+1D0AF",
  "U+1D0B0",
  "U+1D0B1",
  "U+1D0B2",
  "U+1D0B3",
  "U+1D0B4",
  "U+1D0B5",
  "U+1D0B6",
  "U+1D0B7",
  "U+1D0B8",
  "U+1D0B9",
  "U+1D0BA",
  "U+1D0BB",
  "U+1D0BC",
  "U+1D0BD",
  "U+1D0BE",
  "U+1D0BF",
  "U+1D0C0",
  "U+1D0C1",
  "U+1D0C2",
  "U+1D0C3",
  "U+1D0C4",
  "U+1D0C5",
  "U+1D0C6",
  "U+1D0C7",
  "U+1D0C8",
  "U+1D0C9",
  "U+1D0CA",
  "U+1D0CB",
  "U+1D0CC",
  "U+1D0CD",
  "U+1D0CE",
  "U+1D0CF",
  "U+1D0D0",
  "U+1D0D1",
  "U+1D0D2",
  "U+1D0D3",
  "U+1D0D4",
  "U+1D0D5",
  "U+1D0D6",
  "U+1D0D7",
  "U+1D0D8",
  "U+1D0D9",
  "U+1D0DA",
  "U+1D0DB",
  "U+1D0DC",
  "U+1D0DD",
  "U+1D0DE",
  "U+1D0DF",
  "U+1D0E0",
  "U+1D0E1",
  "U+1D0E2",
  "U+1D0E3",
  "U+1D0E4",
  "U+1D0E5",
  "U+1D0E6",
  "U+1D0E7",
  "U+1D0E8",
  "U+1D0E9",
  "U+1D0EA",
  "U+1D0EB",
  "U+1D0EC",
  "U+1D0ED",
  "U+1D0EE",
  "U+1D0EF",
  "U+1D0F0",
  "U+1D0F1",
  "U+1D0F2",
  "U+1D0F3",
  "U+1D0F4",
  "U+1D0F5",
  "U+1D100",
  "U+1D101",
  "U+1D102",
  "U+1D103",
  "U+1D104",
  "U+1D105",
  "U+1D106",
  "U+1D107",
  "U+1D108",
  "U+1D109",
  "U+1D10A",
  "U+1D10B",
  "U+1D10C",
  "U+1D10D",
  "U+1D10E",
  "U+1D10F",
  "U+1D110",
  "U+1D111",
  "U+1D112",
  "U+1D113",
  "U+1D114",
  "U+1D115",
  "U+1D116",
  "U+1D117",
  "U+1D118",
  "U+1D119",
  "U+1D11A",
  "U+1D11B",
  "U+1D11C",
  "U+1D11D",
  "U+1D11E",
  "U+1D11F",
  "U+1D120",
  "U+1D121",
  "U+1D122",
  "U+1D123",
  "U+1D124",
  "U+1D125",
  "U+1D126",
  "U+1D129",
  "U+1D12A",
  "U+1D12B",
  "U+1D12C",
  "U+1D12D",
  "U+1D12E",
  "U+1D12F",
  "U+1D130",
  "U+1D131",
  "U+1D132",
  "U+1D133",
  "U+1D134",
  "U+1D135",
  "U+1D136",
  "U+1D137",
  "U+1D138",
  "U+1D139",
  "U+1D13A",
  "U+1D13B",
  "U+1D13C",
  "U+1D13D",
  "U+1D13E",
  "U+1D13F",
  "U+1D140",
  "U+1D141",
  "U+1D142",
  "U+1D143",
  "U+1D144",
  "U+1D145",
  "U+1D146",
  "U+1D147",
  "U+1D148",
  "U+1D149",
  "U+1D14A",
  "U+1D14B",
  "U+1D14C",
  "U+1D14D",
  "U+1D14E",
  "U+1D14F",
  "U+1D150",
  "U+1D151",
  "U+1D152",
  "U+1D153",
  "U+1D154",
  "U+1D155",
  "U+1D156",
  "U+1D157",
  "U+1D158",
  "U+1D159",
  "U+1D15A",
  "U+1D15B",
  "U+1D15C",
  "U+1D15D",
  "U+1D15E",
  "U+1D15F",
  "U+1D160",
  "U+1D161",
  "U+1D162",
  "U+1D163",
  "U+1D164",
  "U+1D16A",
  "U+1D16B",
  "U+1D16C",
  "U+1D183",
  "U+1D184",
  "U+1D18C",
  "U+1D18D",
  "U+1D18E",
  "U+1D18F",
  "U+1D190",
  "U+1D191",
  "U+1D192",
  "U+1D193",
  "U+1D194",
  "U+1D195",
  "U+1D196",
  "U+1D197",
  "U+1D198",
  "U+1D199",
  "U+1D19A",
  "U+1D19B",
  "U+1D19C",
  "U+1D19D",
  "U+1D19E",
  "U+1D19F",
  "U+1D1A0",
  "U+1D1A1",
  "U+1D1A2",
  "U+1D1A3",
  "U+1D1A4",
  "U+1D1A5",
  "U+1D1A6",
  "U+1D1A7",
  "U+1D1A8",
  "U+1D1A9",
  "U+1D1AE",
  "U+1D1AF",
  "U+1D1B0",
  "U+1D1B1",
  "U+1D1B2",
  "U+1D1B3",
  "U+1D1B4",
  "U+1D1B5",
  "U+1D1B6",
  "U+1D1B7",
  "U+1D1B8",
  "U+1D1B9",
  "U+1D1BA",
  "U+1D1BB",
  "U+1D1BC",
  "U+1D1BD",
  "U+1D1BE",
  "U+1D1BF",
  "U+1D1C0",
  "U+1D1C1",
  "U+1D1C2",
  "U+1D1C3",
  "U+1D1C4",
  "U+1D1C5",
  "U+1D1C6",
  "U+1D1C7",
  "U+1D1C8",
  "U+1D1C9",
  "U+1D1CA",
  "U+1D1CB",
  "U+1D1CC",
  "U+1D1CD",
  "U+1D1CE",
  "U+1D1CF",
  "U+1D1D0",
  "U+1D1D1",
  "U+1D1D2",
  "U+1D1D3",
  "U+1D1D4",
  "U+1D1D5",
  "U+1D1D6",
  "U+1D1D7",
  "U+1D1D8",
  "U+1D1D9",
  "U+1D1DA",
  "U+1D1DB",
  "U+1D1DC",
  "U+1D1DD",
  "U+1D1DE",
  "U+1D1DF",
  "U+1D1E0",
  "U+1D1E1",
  "U+1D1E2",
  "U+1D1E3",
  "U+1D1E4",
  "U+1D1E5",
  "U+1D1E6",
  "U+1D1E7",
  "U+1D1E8",
  "U+1D1E9",
  "U+1D1EA",
  "U+1D200",
  "U+1D201",
  "U+1D202",
  "U+1D203",
  "U+1D204",
  "U+1D205",
  "U+1D206",
  "U+1D207",
  "U+1D208",
  "U+1D209",
  "U+1D20A",
  "U+1D20B",
  "U+1D20C",
  "U+1D20D",
  "U+1D20E",
  "U+1D20F",
  "U+1D210",
  "U+1D211",
  "U+1D212",
  "U+1D213",
  "U+1D214",
  "U+1D215",
  "U+1D216",
  "U+1D217",
  "U+1D218",
  "U+1D219",
  "U+1D21A",
  "U+1D21B",
  "U+1D21C",
  "U+1D21D",
  "U+1D21E",
  "U+1D21F",
  "U+1D220",
  "U+1D221",
  "U+1D222",
  "U+1D223",
  "U+1D224",
  "U+1D225",
  "U+1D226",
  "U+1D227",
  "U+1D228",
  "U+1D229",
  "U+1D22A",
  "U+1D22B",
  "U+1D22C",
  "U+1D22D",
  "U+1D22E",
  "U+1D22F",
  "U+1D230",
  "U+1D231",
  "U+1D232",
  "U+1D233",
  "U+1D234",
  "U+1D235",
  "U+1D236",
  "U+1D237",
  "U+1D238",
  "U+1D239",
  "U+1D23A",
  "U+1D23B",
  "U+1D23C",
  "U+1D23D",
  "U+1D23E",
  "U+1D23F",
  "U+1D240",
  "U+1D241",
  "U+1D245",
  "U+1D300",
  "U+1D301",
  "U+1D302",
  "U+1D303",
  "U+1D304",
  "U+1D305",
  "U+1D306",
  "U+1D307",
  "U+1D308",
  "U+1D309",
  "U+1D30A",
  "U+1D30B",
  "U+1D30C",
  "U+1D30D",
  "U+1D30E",
  "U+1D30F",
  "U+1D310",
  "U+1D311",
  "U+1D312",
  "U+1D313",
  "U+1D314",
  "U+1D315",
  "U+1D316",
  "U+1D317",
  "U+1D318",
  "U+1D319",
  "U+1D31A",
  "U+1D31B",
  "U+1D31C",
  "U+1D31D",
  "U+1D31E",
  "U+1D31F",
  "U+1D320",
  "U+1D321",
  "U+1D322",
  "U+1D323",
  "U+1D324",
  "U+1D325",
  "U+1D326",
  "U+1D327",
  "U+1D328",
  "U+1D329",
  "U+1D32A",
  "U+1D32B",
  "U+1D32C",
  "U+1D32D",
  "U+1D32E",
  "U+1D32F",
  "U+1D330",
  "U+1D331",
  "U+1D332",
  "U+1D333",
  "U+1D334",
  "U+1D335",
  "U+1D336",
  "U+1D337",
  "U+1D338",
  "U+1D339",
  "U+1D33A",
  "U+1D33B",
  "U+1D33C",
  "U+1D33D",
  "U+1D33E",
  "U+1D33F",
  "U+1D340",
  "U+1D341",
  "U+1D342",
  "U+1D343",
  "U+1D344",
  "U+1D345",
  "U+1D346",
  "U+1D347",
  "U+1D348",
  "U+1D349",
  "U+1D34A",
  "U+1D34B",
  "U+1D34C",
  "U+1D34D",
  "U+1D34E",
  "U+1D34F",
  "U+1D350",
  "U+1D351",
  "U+1D352",
  "U+1D353",
  "U+1D354",
  "U+1D355",
  "U+1D356",
  "U+1D6C1",
  "U+1D6DB",
  "U+1D6FB",
  "U+1D715",
  "U+1D735",
  "U+1D74F",
  "U+1D76F",
  "U+1D789",
  "U+1D7A9",
  "U+1D7C3",
  "U+1D800",
  "U+1D801",
  "U+1D802",
  "U+1D803",
  "U+1D804",
  "U+1D805",
  "U+1D806",
  "U+1D807",
  "U+1D808",
  "U+1D809",
  "U+1D80A",
  "U+1D80B",
  "U+1D80C",
  "U+1D80D",
  "U+1D80E",
  "U+1D80F",
  "U+1D810",
  "U+1D811",
  "U+1D812",
  "U+1D813",
  "U+1D814",
  "U+1D815",
  "U+1D816",
  "U+1D817",
  "U+1D818",
  "U+1D819",
  "U+1D81A",
  "U+1D81B",
  "U+1D81C",
  "U+1D81D",
  "U+1D81E",
  "U+1D81F",
  "U+1D820",
  "U+1D821",
  "U+1D822",
  "U+1D823",
  "U+1D824",
  "U+1D825",
  "U+1D826",
  "U+1D827",
  "U+1D828",
  "U+1D829",
  "U+1D82A",
  "U+1D82B",
  "U+1D82C",
  "U+1D82D",
  "U+1D82E",
  "U+1D82F",
  "U+1D830",
  "U+1D831",
  "U+1D832",
  "U+1D833",
  "U+1D834",
  "U+1D835",
  "U+1D836",
  "U+1D837",
  "U+1D838",
  "U+1D839",
  "U+1D83A",
  "U+1D83B",
  "U+1D83C",
  "U+1D83D",
  "U+1D83E",
  "U+1D83F",
  "U+1D840",
  "U+1D841",
  "U+1D842",
  "U+1D843",
  "U+1D844",
  "U+1D845",
  "U+1D846",
  "U+1D847",
  "U+1D848",
  "U+1D849",
  "U+1D84A",
  "U+1D84B",
  "U+1D84C",
  "U+1D84D",
  "U+1D84E",
  "U+1D84F",
  "U+1D850",
  "U+1D851",
  "U+1D852",
  "U+1D853",
  "U+1D854",
  "U+1D855",
  "U+1D856",
  "U+1D857",
  "U+1D858",
  "U+1D859",
  "U+1D85A",
  "U+1D85B",
  "U+1D85C",
  "U+1D85D",
  "U+1D85E",
  "U+1D85F",
  "U+1D860",
  "U+1D861",
  "U+1D862",
  "U+1D863",
  "U+1D864",
  "U+1D865",
  "U+1D866",
  "U+1D867",
  "U+1D868",
  "U+1D869",
  "U+1D86A",
  "U+1D86B",
  "U+1D86C",
  "U+1D86D",
  "U+1D86E",
  "U+1D86F",
  "U+1D870",
  "U+1D871",
  "U+1D872",
  "U+1D873",
  "U+1D874",
  "U+1D875",
  "U+1D876",
  "U+1D877",
  "U+1D878",
  "U+1D879",
  "U+1D87A",
  "U+1D87B",
  "U+1D87C",
  "U+1D87D",
  "U+1D87E",
  "U+1D87F",
  "U+1D880",
  "U+1D881",
  "U+1D882",
  "U+1D883",
  "U+1D884",
  "U+1D885",
  "U+1D886",
  "U+1D887",
  "U+1D888",
  "U+1D889",
  "U+1D88A",
  "U+1D88B",
  "U+1D88C",
  "U+1D88D",
  "U+1D88E",
  "U+1D88F",
  "U+1D890",
  "U+1D891",
  "U+1D892",
  "U+1D893",
  "U+1D894",
  "U+1D895",
  "U+1D896",
  "U+1D897",
  "U+1D898",
  "U+1D899",
  "U+1D89A",
  "U+1D89B",
  "U+1D89C",
  "U+1D89D",
  "U+1D89E",
  "U+1D89F",
  "U+1D8A0",
  "U+1D8A1",
  "U+1D8A2",
  "U+1D8A3",
  "U+1D8A4",
  "U+1D8A5",
  "U+1D8A6",
  "U+1D8A7",
  "U+1D8A8",
  "U+1D8A9",
  "U+1D8AA",
  "U+1D8AB",
  "U+1D8AC",
  "U+1D8AD",
  "U+1D8AE",
  "U+1D8AF",
  "U+1D8B0",
  "U+1D8B1",
  "U+1D8B2",
  "U+1D8B3",
  "U+1D8B4",
  "U+1D8B5",
  "U+1D8B6",
  "U+1D8B7",
  "U+1D8B8",
  "U+1D8B9",
  "U+1D8BA",
  "U+1D8BB",
  "U+1D8BC",
  "U+1D8BD",
  "U+1D8BE",
  "U+1D8BF",
  "U+1D8C0",
  "U+1D8C1",
  "U+1D8C2",
  "U+1D8C3",
  "U+1D8C4",
  "U+1D8C5",
  "U+1D8C6",
  "U+1D8C7",
  "U+1D8C8",
  "U+1D8C9",
  "U+1D8CA",
  "U+1D8CB",
  "U+1D8CC",
  "U+1D8CD",
  "U+1D8CE",
  "U+1D8CF",
  "U+1D8D0",
  "U+1D8D1",
  "U+1D8D2",
  "U+1D8D3",
  "U+1D8D4",
  "U+1D8D5",
  "U+1D8D6",
  "U+1D8D7",
  "U+1D8D8",
  "U+1D8D9",
  "U+1D8DA",
  "U+1D8DB",
  "U+1D8DC",
  "U+1D8DD",
  "U+1D8DE",
  "U+1D8DF",
  "U+1D8E0",
  "U+1D8E1",
  "U+1D8E2",
  "U+1D8E3",
  "U+1D8E4",
  "U+1D8E5",
  "U+1D8E6",
  "U+1D8E7",
  "U+1D8E8",
  "U+1D8E9",
  "U+1D8EA",
  "U+1D8EB",
  "U+1D8EC",
  "U+1D8ED",
  "U+1D8EE",
  "U+1D8EF",
  "U+1D8F0",
  "U+1D8F1",
  "U+1D8F2",
  "U+1D8F3",
  "U+1D8F4",
  "U+1D8F5",
  "U+1D8F6",
  "U+1D8F7",
  "U+1D8F8",
  "U+1D8F9",
  "U+1D8FA",
  "U+1D8FB",
  "U+1D8FC",
  "U+1D8FD",
  "U+1D8FE",
  "U+1D8FF",
  "U+1D900",
  "U+1D901",
  "U+1D902",
  "U+1D903",
  "U+1D904",
  "U+1D905",
  "U+1D906",
  "U+1D907",
  "U+1D908",
  "U+1D909",
  "U+1D90A",
  "U+1D90B",
  "U+1D90C",
  "U+1D90D",
  "U+1D90E",
  "U+1D90F",
  "U+1D910",
  "U+1D911",
  "U+1D912",
  "U+1D913",
  "U+1D914",
  "U+1D915",
  "U+1D916",
  "U+1D917",
  "U+1D918",
  "U+1D919",
  "U+1D91A",
  "U+1D91B",
  "U+1D91C",
  "U+1D91D",
  "U+1D91E",
  "U+1D91F",
  "U+1D920",
  "U+1D921",
  "U+1D922",
  "U+1D923",
  "U+1D924",
  "U+1D925",
  "U+1D926",
  "U+1D927",
  "U+1D928",
  "U+1D929",
  "U+1D92A",
  "U+1D92B",
  "U+1D92C",
  "U+1D92D",
  "U+1D92E",
  "U+1D92F",
  "U+1D930",
  "U+1D931",
  "U+1D932",
  "U+1D933",
  "U+1D934",
  "U+1D935",
  "U+1D936",
  "U+1D937",
  "U+1D938",
  "U+1D939",
  "U+1D93A",
  "U+1D93B",
  "U+1D93C",
  "U+1D93D",
  "U+1D93E",
  "U+1D93F",
  "U+1D940",
  "U+1D941",
  "U+1D942",
  "U+1D943",
  "U+1D944",
  "U+1D945",
  "U+1D946",
  "U+1D947",
  "U+1D948",
  "U+1D949",
  "U+1D94A",
  "U+1D94B",
  "U+1D94C",
  "U+1D94D",
  "U+1D94E",
  "U+1D94F",
  "U+1D950",
  "U+1D951",
  "U+1D952",
  "U+1D953",
  "U+1D954",
  "U+1D955",
  "U+1D956",
  "U+1D957",
  "U+1D958",
  "U+1D959",
  "U+1D95A",
  "U+1D95B",
  "U+1D95C",
  "U+1D95D",
  "U+1D95E",
  "U+1D95F",
  "U+1D960",
  "U+1D961",
  "U+1D962",
  "U+1D963",
  "U+1D964",
  "U+1D965",
  "U+1D966",
  "U+1D967",
  "U+1D968",
  "U+1D969",
  "U+1D96A",
  "U+1D96B",
  "U+1D96C",
  "U+1D96D",
  "U+1D96E",
  "U+1D96F",
  "U+1D970",
  "U+1D971",
  "U+1D972",
  "U+1D973",
  "U+1D974",
  "U+1D975",
  "U+1D976",
  "U+1D977",
  "U+1D978",
  "U+1D979",
  "U+1D97A",
  "U+1D97B",
  "U+1D97C",
  "U+1D97D",
  "U+1D97E",
  "U+1D97F",
  "U+1D980",
  "U+1D981",
  "U+1D982",
  "U+1D983",
  "U+1D984",
  "U+1D985",
  "U+1D986",
  "U+1D987",
  "U+1D988",
  "U+1D989",
  "U+1D98A",
  "U+1D98B",
  "U+1D98C",
  "U+1D98D",
  "U+1D98E",
  "U+1D98F",
  "U+1D990",
  "U+1D991",
  "U+1D992",
  "U+1D993",
  "U+1D994",
  "U+1D995",
  "U+1D996",
  "U+1D997",
  "U+1D998",
  "U+1D999",
  "U+1D99A",
  "U+1D99B",
  "U+1D99C",
  "U+1D99D",
  "U+1D99E",
  "U+1D99F",
  "U+1D9A0",
  "U+1D9A1",
  "U+1D9A2",
  "U+1D9A3",
  "U+1D9A4",
  "U+1D9A5",
  "U+1D9A6",
  "U+1D9A7",
  "U+1D9A8",
  "U+1D9A9",
  "U+1D9AA",
  "U+1D9AB",
  "U+1D9AC",
  "U+1D9AD",
  "U+1D9AE",
  "U+1D9AF",
  "U+1D9B0",
  "U+1D9B1",
  "U+1D9B2",
  "U+1D9B3",
  "U+1D9B4",
  "U+1D9B5",
  "U+1D9B6",
  "U+1D9B7",
  "U+1D9B8",
  "U+1D9B9",
  "U+1D9BA",
  "U+1D9BB",
  "U+1D9BC",
  "U+1D9BD",
  "U+1D9BE",
  "U+1D9BF",
  "U+1D9C0",
  "U+1D9C1",
  "U+1D9C2",
  "U+1D9C3",
  "U+1D9C4",
  "U+1D9C5",
  "U+1D9C6",
  "U+1D9C7",
  "U+1D9C8",
  "U+1D9C9",
  "U+1D9CA",
  "U+1D9CB",
  "U+1D9CC",
  "U+1D9CD",
  "U+1D9CE",
  "U+1D9CF",
  "U+1D9D0",
  "U+1D9D1",
  "U+1D9D2",
  "U+1D9D3",
  "U+1D9D4",
  "U+1D9D5",
  "U+1D9D6",
  "U+1D9D7",
  "U+1D9D8",
  "U+1D9D9",
  "U+1D9DA",
  "U+1D9DB",
  "U+1D9DC",
  "U+1D9DD",
  "U+1D9DE",
  "U+1D9DF",
  "U+1D9E0",
  "U+1D9E1",
  "U+1D9E2",
  "U+1D9E3",
  "U+1D9E4",
  "U+1D9E5",
  "U+1D9E6",
  "U+1D9E7",
  "U+1D9E8",
  "U+1D9E9",
  "U+1D9EA",
  "U+1D9EB",
  "U+1D9EC",
  "U+1D9ED",
  "U+1D9EE",
  "U+1D9EF",
  "U+1D9F0",
  "U+1D9F1",
  "U+1D9F2",
  "U+1D9F3",
  "U+1D9F4",
  "U+1D9F5",
  "U+1D9F6",
  "U+1D9F7",
  "U+1D9F8",
  "U+1D9F9",
  "U+1D9FA",
  "U+1D9FB",
  "U+1D9FC",
  "U+1D9FD",
  "U+1D9FE",
  "U+1D9FF",
  "U+1DA37",
  "U+1DA38",
  "U+1DA39",
  "U+1DA3A",
  "U+1DA6D",
  "U+1DA6E",
  "U+1DA6F",
  "U+1DA70",
  "U+1DA71",
  "U+1DA72",
  "U+1DA73",
  "U+1DA74",
  "U+1DA76",
  "U+1DA77",
  "U+1DA78",
  "U+1DA79",
  "U+1DA7A",
  "U+1DA7B",
  "U+1DA7C",
  "U+1DA7D",
  "U+1DA7E",
  "U+1DA7F",
  "U+1DA80",
  "U+1DA81",
  "U+1DA82",
  "U+1DA83",
  "U+1DA85",
  "U+1DA86",
  "U+1E14F",
  "U+1E2FF",
  "U+1ECAC",
  "U+1ECB0",
  "U+1ED2E",
  "U+1EEF0",
  "U+1EEF1",
  "U+1F000",
  "U+1F001",
  "U+1F002",
  "U+1F003",
  "U+1F004",
  "U+1F005",
  "U+1F006",
  "U+1F007",
  "U+1F008",
  "U+1F009",
  "U+1F00A",
  "U+1F00B",
  "U+1F00C",
  "U+1F00D",
  "U+1F00E",
  "U+1F00F",
  "U+1F010",
  "U+1F011",
  "U+1F012",
  "U+1F013",
  "U+1F014",
  "U+1F015",
  "U+1F016",
  "U+1F017",
  "U+1F018",
  "U+1F019",
  "U+1F01A",
  "U+1F01B",
  "U+1F01C",
  "U+1F01D",
  "U+1F01E",
  "U+1F01F",
  "U+1F020",
  "U+1F021",
  "U+1F022",
  "U+1F023",
  "U+1F024",
  "U+1F025",
  "U+1F026",
  "U+1F027",
  "U+1F028",
  "U+1F029",
  "U+1F02A",
  "U+1F02B",
  "U+1F030",
  "U+1F031",
  "U+1F032",
  "U+1F033",
  "U+1F034",
  "U+1F035",
  "U+1F036",
  "U+1F037",
  "U+1F038",
  "U+1F039",
  "U+1F03A",
  "U+1F03B",
  "U+1F03C",
  "U+1F03D",
  "U+1F03E",
  "U+1F03F",
  "U+1F040",
  "U+1F041",
  "U+1F042",
  "U+1F043",
  "U+1F044",
  "U+1F045",
  "U+1F046",
  "U+1F047",
  "U+1F048",
  "U+1F049",
  "U+1F04A",
  "U+1F04B",
  "U+1F04C",
  "U+1F04D",
  "U+1F04E",
  "U+1F04F",
  "U+1F050",
  "U+1F051",
  "U+1F052",
  "U+1F053",
  "U+1F054",
  "U+1F055",
  "U+1F056",
  "U+1F057",
  "U+1F058",
  "U+1F059",
  "U+1F05A",
  "U+1F05B",
  "U+1F05C",
  "U+1F05D",
  "U+1F05E",
  "U+1F05F",
  "U+1F060",
  "U+1F061",
  "U+1F062",
  "U+1F063",
  "U+1F064",
  "U+1F065",
  "U+1F066",
  "U+1F067",
  "U+1F068",
  "U+1F069",
  "U+1F06A",
  "U+1F06B",
  "U+1F06C",
  "U+1F06D",
  "U+1F06E",
  "U+1F06F",
  "U+1F070",
  "U+1F071",
  "U+1F072",
  "U+1F073",
  "U+1F074",
  "U+1F075",
  "U+1F076",
  "U+1F077",
  "U+1F078",
  "U+1F079",
  "U+1F07A",
  "U+1F07B",
  "U+1F07C",
  "U+1F07D",
  "U+1F07E",
  "U+1F07F",
  "U+1F080",
  "U+1F081",
  "U+1F082",
  "U+1F083",
  "U+1F084",
  "U+1F085",
  "U+1F086",
  "U+1F087",
  "U+1F088",
  "U+1F089",
  "U+1F08A",
  "U+1F08B",
  "U+1F08C",
  "U+1F08D",
  "U+1F08E",
  "U+1F08F",
  "U+1F090",
  "U+1F091",
  "U+1F092",
  "U+1F093",
  "U+1F0A0",
  "U+1F0A1",
  "U+1F0A2",
  "U+1F0A3",
  "U+1F0A4",
  "U+1F0A5",
  "U+1F0A6",
  "U+1F0A7",
  "U+1F0A8",
  "U+1F0A9",
  "U+1F0AA",
  "U+1F0AB",
  "U+1F0AC",
  "U+1F0AD",
  "U+1F0AE",
  "U+1F0B1",
  "U+1F0B2",
  "U+1F0B3",
  "U+1F0B4",
  "U+1F0B5",
  "U+1F0B6",
  "U+1F0B7",
  "U+1F0B8",
  "U+1F0B9",
  "U+1F0BA",
  "U+1F0BB",
  "U+1F0BC",
  "U+1F0BD",
  "U+1F0BE",
  "U+1F0BF",
  "U+1F0C1",
  "U+1F0C2",
  "U+1F0C3",
  "U+1F0C4",
  "U+1F0C5",
  "U+1F0C6",
  "U+1F0C7",
  "U+1F0C8",
  "U+1F0C9",
  "U+1F0CA",
  "U+1F0CB",
  "U+1F0CC",
  "U+1F0CD",
  "U+1F0CE",
  "U+1F0CF",
  "U+1F0D1",
  "U+1F0D2",
  "U+1F0D3",
  "U+1F0D4",
  "U+1F0D5",
  "U+1F0D6",
  "U+1F0D7",
  "U+1F0D8",
  "U+1F0D9",
  "U+1F0DA",
  "U+1F0DB",
  "U+1F0DC",
  "U+1F0DD",
  "U+1F0DE",
  "U+1F0DF",
  "U+1F0E0",
  "U+1F0E1",
  "U+1F0E2",
  "U+1F0E3",
  "U+1F0E4",
  "U+1F0E5",
  "U+1F0E6",
  "U+1F0E7",
  "U+1F0E8",
  "U+1F0E9",
  "U+1F0EA",
  "U+1F0EB",
  "U+1F0EC",
  "U+1F0ED",
  "U+1F0EE",
  "U+1F0EF",
  "U+1F0F0",
  "U+1F0F1",
  "U+1F0F2",
  "U+1F0F3",
  "U+1F0F4",
  "U+1F0F5",
  "U+1F10D",
  "U+1F10E",
  "U+1F10F",
  "U+1F110",
  "U+1F111",
  "U+1F112",
  "U+1F113",
  "U+1F114",
  "U+1F115",
  "U+1F116",
  "U+1F117",
  "U+1F118",
  "U+1F119",
  "U+1F11A",
  "U+1F11B",
  "U+1F11C",
  "U+1F11D",
  "U+1F11E",
  "U+1F11F",
  "U+1F120",
  "U+1F121",
  "U+1F122",
  "U+1F123",
  "U+1F124",
  "U+1F125",
  "U+1F126",
  "U+1F127",
  "U+1F128",
  "U+1F129",
  "U+1F12A",
  "U+1F12B",
  "U+1F12C",
  "U+1F12D",
  "U+1F12E",
  "U+1F12F",
  "U+1F130",
  "U+1F131",
  "U+1F132",
  "U+1F133",
  "U+1F134",
  "U+1F135",
  "U+1F136",
  "U+1F137",
  "U+1F138",
  "U+1F139",
  "U+1F13A",
  "U+1F13B",
  "U+1F13C",
  "U+1F13D",
  "U+1F13E",
  "U+1F13F",
  "U+1F140",
  "U+1F141",
  "U+1F142",
  "U+1F143",
  "U+1F144",
  "U+1F145",
  "U+1F146",
  "U+1F147",
  "U+1F148",
  "U+1F149",
  "U+1F14A",
  "U+1F14B",
  "U+1F14C",
  "U+1F14D",
  "U+1F14E",
  "U+1F14F",
  "U+1F150",
  "U+1F151",
  "U+1F152",
  "U+1F153",
  "U+1F154",
  "U+1F155",
  "U+1F156",
  "U+1F157",
  "U+1F158",
  "U+1F159",
  "U+1F15A",
  "U+1F15B",
  "U+1F15C",
  "U+1F15D",
  "U+1F15E",
  "U+1F15F",
  "U+1F160",
  "U+1F161",
  "U+1F162",
  "U+1F163",
  "U+1F164",
  "U+1F165",
  "U+1F166",
  "U+1F167",
  "U+1F168",
  "U+1F169",
  "U+1F16A",
  "U+1F16B",
  "U+1F16C",
  "U+1F16D",
  "U+1F16E",
  "U+1F16F",
  "U+1F170",
  "U+1F171",
  "U+1F172",
  "U+1F173",
  "U+1F174",
  "U+1F175",
  "U+1F176",
  "U+1F177",
  "U+1F178",
  "U+1F179",
  "U+1F17A",
  "U+1F17B",
  "U+1F17C",
  "U+1F17D",
  "U+1F17E",
  "U+1F17F",
  "U+1F180",
  "U+1F181",
  "U+1F182",
  "U+1F183",
  "U+1F184",
  "U+1F185",
  "U+1F186",
  "U+1F187",
  "U+1F188",
  "U+1F189",
  "U+1F18A",
  "U+1F18B",
  "U+1F18C",
  "U+1F18D",
  "U+1F18E",
  "U+1F18F",
  "U+1F190",
  "U+1F191",
  "U+1F192",
  "U+1F193",
  "U+1F194",
  "U+1F195",
  "U+1F196",
  "U+1F197",
  "U+1F198",
  "U+1F199",
  "U+1F19A",
  "U+1F19B",
  "U+1F19C",
  "U+1F19D",
  "U+1F19E",
  "U+1F19F",
  "U+1F1A0",
  "U+1F1A1",
  "U+1F1A2",
  "U+1F1A3",
  "U+1F1A4",
  "U+1F1A5",
  "U+1F1A6",
  "U+1F1A7",
  "U+1F1A8",
  "U+1F1A9",
  "U+1F1AA",
  "U+1F1AB",
  "U+1F1AC",
  "U+1F1AD",
  "U+1F1E6",
  "U+1F1E7",
  "U+1F1E8",
  "U+1F1E9",
  "U+1F1EA",
  "U+1F1EB",
  "U+1F1EC",
  "U+1F1ED",
  "U+1F1EE",
  "U+1F1EF",
  "U+1F1F0",
  "U+1F1F1",
  "U+1F1F2",
  "U+1F1F3",
  "U+1F1F4",
  "U+1F1F5",
  "U+1F1F6",
  "U+1F1F7",
  "U+1F1F8",
  "U+1F1F9",
  "U+1F1FA",
  "U+1F1FB",
  "U+1F1FC",
  "U+1F1FD",
  "U+1F1FE",
  "U+1F1FF",
  "U+1F200",
  "U+1F201",
  "U+1F202",
  "U+1F210",
  "U+1F211",
  "U+1F212",
  "U+1F213",
  "U+1F214",
  "U+1F215",
  "U+1F216",
  "U+1F217",
  "U+1F218",
  "U+1F219",
  "U+1F21A",
  "U+1F21B",
  "U+1F21C",
  "U+1F21D",
  "U+1F21E",
  "U+1F21F",
  "U+1F220",
  "U+1F221",
  "U+1F222",
  "U+1F223",
  "U+1F224",
  "U+1F225",
  "U+1F226",
  "U+1F227",
  "U+1F228",
  "U+1F229",
  "U+1F22A",
  "U+1F22B",
  "U+1F22C",
  "U+1F22D",
  "U+1F22E",
  "U+1F22F",
  "U+1F230",
  "U+1F231",
  "U+1F232",
  "U+1F233",
  "U+1F234",
  "U+1F235",
  "U+1F236",
  "U+1F237",
  "U+1F238",
  "U+1F239",
  "U+1F23A",
  "U+1F23B",
  "U+1F240",
  "U+1F241",
  "U+1F242",
  "U+1F243",
  "U+1F244",
  "U+1F245",
  "U+1F246",
  "U+1F247",
  "U+1F248",
  "U+1F250",
  "U+1F251",
  "U+1F260",
  "U+1F261",
  "U+1F262",
  "U+1F263",
  "U+1F264",
  "U+1F265",
  "U+1F300",
  "U+1F301",
  "U+1F302",
  "U+1F303",
  "U+1F304",
  "U+1F305",
  "U+1F306",
  "U+1F307",
  "U+1F308",
  "U+1F309",
  "U+1F30A",
  "U+1F30B",
  "U+1F30C",
  "U+1F30D",
  "U+1F30E",
  "U+1F30F",
  "U+1F310",
  "U+1F311",
  "U+1F312",
  "U+1F313",
  "U+1F314",
  "U+1F315",
  "U+1F316",
  "U+1F317",
  "U+1F318",
  "U+1F319",
  "U+1F31A",
  "U+1F31B",
  "U+1F31C",
  "U+1F31D",
  "U+1F31E",
  "U+1F31F",
  "U+1F320",
  "U+1F321",
  "U+1F322",
  "U+1F323",
  "U+1F324",
  "U+1F325",
  "U+1F326",
  "U+1F327",
  "U+1F328",
  "U+1F329",
  "U+1F32A",
  "U+1F32B",
  "U+1F32C",
  "U+1F32D",
  "U+1F32E",
  "U+1F32F",
  "U+1F330",
  "U+1F331",
  "U+1F332",
  "U+1F333",
  "U+1F334",
  "U+1F335",
  "U+1F336",
  "U+1F337",
  "U+1F338",
  "U+1F339",
  "U+1F33A",
  "U+1F33B",
  "U+1F33C",
  "U+1F33D",
  "U+1F33E",
  "U+1F33F",
  "U+1F340",
  "U+1F341",
  "U+1F342",
  "U+1F343",
  "U+1F344",
  "U+1F345",
  "U+1F346",
  "U+1F347",
  "U+1F348",
  "U+1F349",
  "U+1F34A",
  "U+1F34B",
  "U+1F34C",
  "U+1F34D",
  "U+1F34E",
  "U+1F34F",
  "U+1F350",
  "U+1F351",
  "U+1F352",
  "U+1F353",
  "U+1F354",
  "U+1F355",
  "U+1F356",
  "U+1F357",
  "U+1F358",
  "U+1F359",
  "U+1F35A",
  "U+1F35B",
  "U+1F35C",
  "U+1F35D",
  "U+1F35E",
  "U+1F35F",
  "U+1F360",
  "U+1F361",
  "U+1F362",
  "U+1F363",
  "U+1F364",
  "U+1F365",
  "U+1F366",
  "U+1F367",
  "U+1F368",
  "U+1F369",
  "U+1F36A",
  "U+1F36B",
  "U+1F36C",
  "U+1F36D",
  "U+1F36E",
  "U+1F36F",
  "U+1F370",
  "U+1F371",
  "U+1F372",
  "U+1F373",
  "U+1F374",
  "U+1F375",
  "U+1F376",
  "U+1F377",
  "U+1F378",
  "U+1F379",
  "U+1F37A",
  "U+1F37B",
  "U+1F37C",
  "U+1F37D",
  "U+1F37E",
  "U+1F37F",
  "U+1F380",
  "U+1F381",
  "U+1F382",
  "U+1F383",
  "U+1F384",
  "U+1F385",
  "U+1F386",
  "U+1F387",
  "U+1F388",
  "U+1F389",
  "U+1F38A",
  "U+1F38B",
  "U+1F38C",
  "U+1F38D",
  "U+1F38E",
  "U+1F38F",
  "U+1F390",
  "U+1F391",
  "U+1F392",
  "U+1F393",
  "U+1F394",
  "U+1F395",
  "U+1F396",
  "U+1F397",
  "U+1F398",
  "U+1F399",
  "U+1F39A",
  "U+1F39B",
  "U+1F39C",
  "U+1F39D",
  "U+1F39E",
  "U+1F39F",
  "U+1F3A0",
  "U+1F3A1",
  "U+1F3A2",
  "U+1F3A3",
  "U+1F3A4",
  "U+1F3A5",
  "U+1F3A6",
  "U+1F3A7",
  "U+1F3A8",
  "U+1F3A9",
  "U+1F3AA",
  "U+1F3AB",
  "U+1F3AC",
  "U+1F3AD",
  "U+1F3AE",
  "U+1F3AF",
  "U+1F3B0",
  "U+1F3B1",
  "U+1F3B2",
  "U+1F3B3",
  "U+1F3B4",
  "U+1F3B5",
  "U+1F3B6",
  "U+1F3B7",
  "U+1F3B8",
  "U+1F3B9",
  "U+1F3BA",
  "U+1F3BB",
  "U+1F3BC",
  "U+1F3BD",
  "U+1F3BE",
  "U+1F3BF",
  "U+1F3C0",
  "U+1F3C1",
  "U+1F3C2",
  "U+1F3C3",
  "U+1F3C4",
  "U+1F3C5",
  "U+1F3C6",
  "U+1F3C7",
  "U+1F3C8",
  "U+1F3C9",
  "U+1F3CA",
  "U+1F3CB",
  "U+1F3CC",
  "U+1F3CD",
  "U+1F3CE",
  "U+1F3CF",
  "U+1F3D0",
  "U+1F3D1",
  "U+1F3D2",
  "U+1F3D3",
  "U+1F3D4",
  "U+1F3D5",
  "U+1F3D6",
  "U+1F3D7",
  "U+1F3D8",
  "U+1F3D9",
  "U+1F3DA",
  "U+1F3DB",
  "U+1F3DC",
  "U+1F3DD",
  "U+1F3DE",
  "U+1F3DF",
  "U+1F3E0",
  "U+1F3E1",
  "U+1F3E2",
  "U+1F3E3",
  "U+1F3E4",
  "U+1F3E5",
  "U+1F3E6",
  "U+1F3E7",
  "U+1F3E8",
  "U+1F3E9",
  "U+1F3EA",
  "U+1F3EB",
  "U+1F3EC",
  "U+1F3ED",
  "U+1F3EE",
  "U+1F3EF",
  "U+1F3F0",
  "U+1F3F1",
  "U+1F3F2",
  "U+1F3F3",
  "U+1F3F4",
  "U+1F3F5",
  "U+1F3F6",
  "U+1F3F7",
  "U+1F3F8",
  "U+1F3F9",
  "U+1F3FA",
  "U+1F3FB",
  "U+1F3FC",
  "U+1F3FD",
  "U+1F3FE",
  "U+1F3FF",
  "U+1F400",
  "U+1F401",
  "U+1F402",
  "U+1F403",
  "U+1F404",
  "U+1F405",
  "U+1F406",
  "U+1F407",
  "U+1F408",
  "U+1F409",
  "U+1F40A",
  "U+1F40B",
  "U+1F40C",
  "U+1F40D",
  "U+1F40E",
  "U+1F40F",
  "U+1F410",
  "U+1F411",
  "U+1F412",
  "U+1F413",
  "U+1F414",
  "U+1F415",
  "U+1F416",
  "U+1F417",
  "U+1F418",
  "U+1F419",
  "U+1F41A",
  "U+1F41B",
  "U+1F41C",
  "U+1F41D",
  "U+1F41E",
  "U+1F41F",
  "U+1F420",
  "U+1F421",
  "U+1F422",
  "U+1F423",
  "U+1F424",
  "U+1F425",
  "U+1F426",
  "U+1F427",
  "U+1F428",
  "U+1F429",
  "U+1F42A",
  "U+1F42B",
  "U+1F42C",
  "U+1F42D",
  "U+1F42E",
  "U+1F42F",
  "U+1F430",
  "U+1F431",
  "U+1F432",
  "U+1F433",
  "U+1F434",
  "U+1F435",
  "U+1F436",
  "U+1F437",
  "U+1F438",
  "U+1F439",
  "U+1F43A",
  "U+1F43B",
  "U+1F43C",
  "U+1F43D",
  "U+1F43E",
  "U+1F43F",
  "U+1F440",
  "U+1F441",
  "U+1F442",
  "U+1F443",
  "U+1F444",
  "U+1F445",
  "U+1F446",
  "U+1F447",
  "U+1F448",
  "U+1F449",
  "U+1F44A",
  "U+1F44B",
  "U+1F44C",
  "U+1F44D",
  "U+1F44E",
  "U+1F44F",
  "U+1F450",
  "U+1F451",
  "U+1F452",
  "U+1F453",
  "U+1F454",
  "U+1F455",
  "U+1F456",
  "U+1F457",
  "U+1F458",
  "U+1F459",
  "U+1F45A",
  "U+1F45B",
  "U+1F45C",
  "U+1F45D",
  "U+1F45E",
  "U+1F45F",
  "U+1F460",
  "U+1F461",
  "U+1F462",
  "U+1F463",
  "U+1F464",
  "U+1F465",
  "U+1F466",
  "U+1F467",
  "U+1F468",
  "U+1F469",
  "U+1F46A",
  "U+1F46B",
  "U+1F46C",
  "U+1F46D",
  "U+1F46E",
  "U+1F46F",
  "U+1F470",
  "U+1F471",
  "U+1F472",
  "U+1F473",
  "U+1F474",
  "U+1F475",
  "U+1F476",
  "U+1F477",
  "U+1F478",
  "U+1F479",
  "U+1F47A",
  "U+1F47B",
  "U+1F47C",
  "U+1F47D",
  "U+1F47E",
  "U+1F47F",
  "U+1F480",
  "U+1F481",
  "U+1F482",
  "U+1F483",
  "U+1F484",
  "U+1F485",
  "U+1F486",
  "U+1F487",
  "U+1F488",
  "U+1F489",
  "U+1F48A",
  "U+1F48B",
  "U+1F48C",
  "U+1F48D",
  "U+1F48E",
  "U+1F48F",
  "U+1F490",
  "U+1F491",
  "U+1F492",
  "U+1F493",
  "U+1F494",
  "U+1F495",
  "U+1F496",
  "U+1F497",
  "U+1F498",
  "U+1F499",
  "U+1F49A",
  "U+1F49B",
  "U+1F49C",
  "U+1F49D",
  "U+1F49E",
  "U+1F49F",
  "U+1F4A0",
  "U+1F4A1",
  "U+1F4A2",
  "U+1F4A3",
  "U+1F4A4",
  "U+1F4A5",
  "U+1F4A6",
  "U+1F4A7",
  "U+1F4A8",
  "U+1F4A9",
  "U+1F4AA",
  "U+1F4AB",
  "U+1F4AC",
  "U+1F4AD",
  "U+1F4AE",
  "U+1F4AF",
  "U+1F4B0",
  "U+1F4B1",
  "U+1F4B2",
  "U+1F4B3",
  "U+1F4B4",
  "U+1F4B5",
  "U+1F4B6",
  "U+1F4B7",
  "U+1F4B8",
  "U+1F4B9",
  "U+1F4BA",
  "U+1F4BB",
  "U+1F4BC",
  "U+1F4BD",
  "U+1F4BE",
  "U+1F4BF",
  "U+1F4C0",
  "U+1F4C1",
  "U+1F4C2",
  "U+1F4C3",
  "U+1F4C4",
  "U+1F4C5",
  "U+1F4C6",
  "U+1F4C7",
  "U+1F4C8",
  "U+1F4C9",
  "U+1F4CA",
  "U+1F4CB",
  "U+1F4CC",
  "U+1F4CD",
  "U+1F4CE",
  "U+1F4CF",
  "U+1F4D0",
  "U+1F4D1",
  "U+1F4D2",
  "U+1F4D3",
  "U+1F4D4",
  "U+1F4D5",
  "U+1F4D6",
  "U+1F4D7",
  "U+1F4D8",
  "U+1F4D9",
  "U+1F4DA",
  "U+1F4DB",
  "U+1F4DC",
  "U+1F4DD",
  "U+1F4DE",
  "U+1F4DF",
  "U+1F4E0",
  "U+1F4E1",
  "U+1F4E2",
  "U+1F4E3",
  "U+1F4E4",
  "U+1F4E5",
  "U+1F4E6",
  "U+1F4E7",
  "U+1F4E8",
  "U+1F4E9",
  "U+1F4EA",
  "U+1F4EB",
  "U+1F4EC",
  "U+1F4ED",
  "U+1F4EE",
  "U+1F4EF",
  "U+1F4F0",
  "U+1F4F1",
  "U+1F4F2",
  "U+1F4F3",
  "U+1F4F4",
  "U+1F4F5",
  "U+1F4F6",
  "U+1F4F7",
  "U+1F4F8",
  "U+1F4F9",
  "U+1F4FA",
  "U+1F4FB",
  "U+1F4FC",
  "U+1F4FD",
  "U+1F4FE",
  "U+1F4FF",
  "U+1F500",
  "U+1F501",
  "U+1F502",
  "U+1F503",
  "U+1F504",
  "U+1F505",
  "U+1F506",
  "U+1F507",
  "U+1F508",
  "U+1F509",
  "U+1F50A",
  "U+1F50B",
  "U+1F50C",
  "U+1F50D",
  "U+1F50E",
  "U+1F50F",
  "U+1F510",
  "U+1F511",
  "U+1F512",
  "U+1F513",
  "U+1F514",
  "U+1F515",
  "U+1F516",
  "U+1F517",
  "U+1F518",
  "U+1F519",
  "U+1F51A",
  "U+1F51B",
  "U+1F51C",
  "U+1F51D",
  "U+1F51E",
  "U+1F51F",
  "U+1F520",
  "U+1F521",
  "U+1F522",
  "U+1F523",
  "U+1F524",
  "U+1F525",
  "U+1F526",
  "U+1F527",
  "U+1F528",
  "U+1F529",
  "U+1F52A",
  "U+1F52B",
  "U+1F52C",
  "U+1F52D",
  "U+1F52E",
  "U+1F52F",
  "U+1F530",
  "U+1F531",
  "U+1F532",
  "U+1F533",
  "U+1F534",
  "U+1F535",
  "U+1F536",
  "U+1F537",
  "U+1F538",
  "U+1F539",
  "U+1F53A",
  "U+1F53B",
  "U+1F53C",
  "U+1F53D",
  "U+1F53E",
  "U+1F53F",
  "U+1F540",
  "U+1F541",
  "U+1F542",
  "U+1F543",
  "U+1F544",
  "U+1F545",
  "U+1F546",
  "U+1F547",
  "U+1F548",
  "U+1F549",
  "U+1F54A",
  "U+1F54B",
  "U+1F54C",
  "U+1F54D",
  "U+1F54E",
  "U+1F54F",
  "U+1F550",
  "U+1F551",
  "U+1F552",
  "U+1F553",
  "U+1F554",
  "U+1F555",
  "U+1F556",
  "U+1F557",
  "U+1F558",
  "U+1F559",
  "U+1F55A",
  "U+1F55B",
  "U+1F55C",
  "U+1F55D",
  "U+1F55E",
  "U+1F55F",
  "U+1F560",
  "U+1F561",
  "U+1F562",
  "U+1F563",
  "U+1F564",
  "U+1F565",
  "U+1F566",
  "U+1F567",
  "U+1F568",
  "U+1F569",
  "U+1F56A",
  "U+1F56B",
  "U+1F56C",
  "U+1F56D",
  "U+1F56E",
  "U+1F56F",
  "U+1F570",
  "U+1F571",
  "U+1F572",
  "U+1F573",
  "U+1F574",
  "U+1F575",
  "U+1F576",
  "U+1F577",
  "U+1F578",
  "U+1F579",
  "U+1F57A",
  "U+1F57B",
  "U+1F57C",
  "U+1F57D",
  "U+1F57E",
  "U+1F57F",
  "U+1F580",
  "U+1F581",
  "U+1F582",
  "U+1F583",
  "U+1F584",
  "U+1F585",
  "U+1F586",
  "U+1F587",
  "U+1F588",
  "U+1F589",
  "U+1F58A",
  "U+1F58B",
  "U+1F58C",
  "U+1F58D",
  "U+1F58E",
  "U+1F58F",
  "U+1F590",
  "U+1F591",
  "U+1F592",
  "U+1F593",
  "U+1F594",
  "U+1F595",
  "U+1F596",
  "U+1F597",
  "U+1F598",
  "U+1F599",
  "U+1F59A",
  "U+1F59B",
  "U+1F59C",
  "U+1F59D",
  "U+1F59E",
  "U+1F59F",
  "U+1F5A0",
  "U+1F5A1",
  "U+1F5A2",
  "U+1F5A3",
  "U+1F5A4",
  "U+1F5A5",
  "U+1F5A6",
  "U+1F5A7",
  "U+1F5A8",
  "U+1F5A9",
  "U+1F5AA",
  "U+1F5AB",
  "U+1F5AC",
  "U+1F5AD",
  "U+1F5AE",
  "U+1F5AF",
  "U+1F5B0",
  "U+1F5B1",
  "U+1F5B2",
  "U+1F5B3",
  "U+1F5B4",
  "U+1F5B5",
  "U+1F5B6",
  "U+1F5B7",
  "U+1F5B8",
  "U+1F5B9",
  "U+1F5BA",
  "U+1F5BB",
  "U+1F5BC",
  "U+1F5BD",
  "U+1F5BE",
  "U+1F5BF",
  "U+1F5C0",
  "U+1F5C1",
  "U+1F5C2",
  "U+1F5C3",
  "U+1F5C4",
  "U+1F5C5",
  "U+1F5C6",
  "U+1F5C7",
  "U+1F5C8",
  "U+1F5C9",
  "U+1F5CA",
  "U+1F5CB",
  "U+1F5CC",
  "U+1F5CD",
  "U+1F5CE",
  "U+1F5CF",
  "U+1F5D0",
  "U+1F5D1",
  "U+1F5D2",
  "U+1F5D3",
  "U+1F5D4",
  "U+1F5D5",
  "U+1F5D6",
  "U+1F5D7",
  "U+1F5D8",
  "U+1F5D9",
  "U+1F5DA",
  "U+1F5DB",
  "U+1F5DC",
  "U+1F5DD",
  "U+1F5DE",
  "U+1F5DF",
  "U+1F5E0",
  "U+1F5E1",
  "U+1F5E2",
  "U+1F5E3",
  "U+1F5E4",
  "U+1F5E5",
  "U+1F5E6",
  "U+1F5E7",
  "U+1F5E8",
  "U+1F5E9",
  "U+1F5EA",
  "U+1F5EB",
  "U+1F5EC",
  "U+1F5ED",
  "U+1F5EE",
  "U+1F5EF",
  "U+1F5F0",
  "U+1F5F1",
  "U+1F5F2",
  "U+1F5F3",
  "U+1F5F4",
  "U+1F5F5",
  "U+1F5F6",
  "U+1F5F7",
  "U+1F5F8",
  "U+1F5F9",
  "U+1F5FA",
  "U+1F5FB",
  "U+1F5FC",
  "U+1F5FD",
  "U+1F5FE",
  "U+1F5FF",
  "U+1F600",
  "U+1F601",
  "U+1F602",
  "U+1F603",
  "U+1F604",
  "U+1F605",
  "U+1F606",
  "U+1F607",
  "U+1F608",
  "U+1F609",
  "U+1F60A",
  "U+1F60B",
  "U+1F60C",
  "U+1F60D",
  "U+1F60E",
  "U+1F60F",
  "U+1F610",
  "U+1F611",
  "U+1F612",
  "U+1F613",
  "U+1F614",
  "U+1F615",
  "U+1F616",
  "U+1F617",
  "U+1F618",
  "U+1F619",
  "U+1F61A",
  "U+1F61B",
  "U+1F61C",
  "U+1F61D",
  "U+1F61E",
  "U+1F61F",
  "U+1F620",
  "U+1F621",
  "U+1F622",
  "U+1F623",
  "U+1F624",
  "U+1F625",
  "U+1F626",
  "U+1F627",
  "U+1F628",
  "U+1F629",
  "U+1F62A",
  "U+1F62B",
  "U+1F62C",
  "U+1F62D",
  "U+1F62E",
  "U+1F62F",
  "U+1F630",
  "U+1F631",
  "U+1F632",
  "U+1F633",
  "U+1F634",
  "U+1F635",
  "U+1F636",
  "U+1F637",
  "U+1F638",
  "U+1F639",
  "U+1F63A",
  "U+1F63B",
  "U+1F63C",
  "U+1F63D",
  "U+1F63E",
  "U+1F63F",
  "U+1F640",
  "U+1F641",
  "U+1F642",
  "U+1F643",
  "U+1F644",
  "U+1F645",
  "U+1F646",
  "U+1F647",
  "U+1F648",
  "U+1F649",
  "U+1F64A",
  "U+1F64B",
  "U+1F64C",
  "U+1F64D",
  "U+1F64E",
  "U+1F64F",
  "U+1F650",
  "U+1F651",
  "U+1F652",
  "U+1F653",
  "U+1F654",
  "U+1F655",
  "U+1F656",
  "U+1F657",
  "U+1F658",
  "U+1F659",
  "U+1F65A",
  "U+1F65B",
  "U+1F65C",
  "U+1F65D",
  "U+1F65E",
  "U+1F65F",
  "U+1F660",
  "U+1F661",
  "U+1F662",
  "U+1F663",
  "U+1F664",
  "U+1F665",
  "U+1F666",
  "U+1F667",
  "U+1F668",
  "U+1F669",
  "U+1F66A",
  "U+1F66B",
  "U+1F66C",
  "U+1F66D",
  "U+1F66E",
  "U+1F66F",
  "U+1F670",
  "U+1F671",
  "U+1F672",
  "U+1F673",
  "U+1F674",
  "U+1F675",
  "U+1F676",
  "U+1F677",
  "U+1F678",
  "U+1F679",
  "U+1F67A",
  "U+1F67B",
  "U+1F67C",
  "U+1F67D",
  "U+1F67E",
  "U+1F67F",
  "U+1F680",
  "U+1F681",
  "U+1F682",
  "U+1F683",
  "U+1F684",
  "U+1F685",
  "U+1F686",
  "U+1F687",
  "U+1F688",
  "U+1F689",
  "U+1F68A",
  "U+1F68B",
  "U+1F68C",
  "U+1F68D",
  "U+1F68E",
  "U+1F68F",
  "U+1F690",
  "U+1F691",
  "U+1F692",
  "U+1F693",
  "U+1F694",
  "U+1F695",
  "U+1F696",
  "U+1F697",
  "U+1F698",
  "U+1F699",
  "U+1F69A",
  "U+1F69B",
  "U+1F69C",
  "U+1F69D",
  "U+1F69E",
  "U+1F69F",
  "U+1F6A0",
  "U+1F6A1",
  "U+1F6A2",
  "U+1F6A3",
  "U+1F6A4",
  "U+1F6A5",
  "U+1F6A6",
  "U+1F6A7",
  "U+1F6A8",
  "U+1F6A9",
  "U+1F6AA",
  "U+1F6AB",
  "U+1F6AC",
  "U+1F6AD",
  "U+1F6AE",
  "U+1F6AF",
  "U+1F6B0",
  "U+1F6B1",
  "U+1F6B2",
  "U+1F6B3",
  "U+1F6B4",
  "U+1F6B5",
  "U+1F6B6",
  "U+1F6B7",
  "U+1F6B8",
  "U+1F6B9",
  "U+1F6BA",
  "U+1F6BB",
  "U+1F6BC",
  "U+1F6BD",
  "U+1F6BE",
  "U+1F6BF",
  "U+1F6C0",
  "U+1F6C1",
  "U+1F6C2",
  "U+1F6C3",
  "U+1F6C4",
  "U+1F6C5",
  "U+1F6C6",
  "U+1F6C7",
  "U+1F6C8",
  "U+1F6C9",
  "U+1F6CA",
  "U+1F6CB",
  "U+1F6CC",
  "U+1F6CD",
  "U+1F6CE",
  "U+1F6CF",
  "U+1F6D0",
  "U+1F6D1",
  "U+1F6D2",
  "U+1F6D3",
  "U+1F6D4",
  "U+1F6D5",
  "U+1F6D6",
  "U+1F6D7",
  "U+1F6DC",
  "U+1F6DD",
  "U+1F6DE",
  "U+1F6DF",
  "U+1F6E0",
  "U+1F6E1",
  "U+1F6E2",
  "U+1F6E3",
  "U+1F6E4",
  "U+1F6E5",
  "U+1F6E6",
  "U+1F6E7",
  "U+1F6E8",
  "U+1F6E9",
  "U+1F6EA",
  "U+1F6EB",
  "U+1F6EC",
  "U+1F6F0",
  "U+1F6F1",
  "U+1F6F2",
  "U+1F6F3",
  "U+1F6F4",
  "U+1F6F5",
  "U+1F6F6",
  "U+1F6F7",
  "U+1F6F8",
  "U+1F6F9",
  "U+1F6FA",
  "U+1F6FB",
  "U+1F6FC",
  "U+1F700",
  "U+1F701",
  "U+1F702",
  "U+1F703",
  "U+1F704",
  "U+1F705",
  "U+1F706",
  "U+1F707",
  "U+1F708",
  "U+1F709",
  "U+1F70A",
  "U+1F70B",
  "U+1F70C",
  "U+1F70D",
  "U+1F70E",
  "U+1F70F",
  "U+1F710",
  "U+1F711",
  "U+1F712",
  "U+1F713",
  "U+1F714",
  "U+1F715",
  "U+1F716",
  "U+1F717",
  "U+1F718",
  "U+1F719",
  "U+1F71A",
  "U+1F71B",
  "U+1F71C",
  "U+1F71D",
  "U+1F71E",
  "U+1F71F",
  "U+1F720",
  "U+1F721",
  "U+1F722",
  "U+1F723",
  "U+1F724",
  "U+1F725",
  "U+1F726",
  "U+1F727",
  "U+1F728",
  "U+1F729",
  "U+1F72A",
  "U+1F72B",
  "U+1F72C",
  "U+1F72D",
  "U+1F72E",
  "U+1F72F",
  "U+1F730",
  "U+1F731",
  "U+1F732",
  "U+1F733",
  "U+1F734",
  "U+1F735",
  "U+1F736",
  "U+1F737",
  "U+1F738",
  "U+1F739",
  "U+1F73A",
  "U+1F73B",
  "U+1F73C",
  "U+1F73D",
  "U+1F73E",
  "U+1F73F",
  "U+1F740",
  "U+1F741",
  "U+1F742",
  "U+1F743",
  "U+1F744",
  "U+1F745",
  "U+1F746",
  "U+1F747",
  "U+1F748",
  "U+1F749",
  "U+1F74A",
  "U+1F74B",
  "U+1F74C",
  "U+1F74D",
  "U+1F74E",
  "U+1F74F",
  "U+1F750",
  "U+1F751",
  "U+1F752",
  "U+1F753",
  "U+1F754",
  "U+1F755",
  "U+1F756",
  "U+1F757",
  "U+1F758",
  "U+1F759",
  "U+1F75A",
  "U+1F75B",
  "U+1F75C",
  "U+1F75D",
  "U+1F75E",
  "U+1F75F",
  "U+1F760",
  "U+1F761",
  "U+1F762",
  "U+1F763",
  "U+1F764",
  "U+1F765",
  "U+1F766",
  "U+1F767",
  "U+1F768",
  "U+1F769",
  "U+1F76A",
  "U+1F76B",
  "U+1F76C",
  "U+1F76D",
  "U+1F76E",
  "U+1F76F",
  "U+1F770",
  "U+1F771",
  "U+1F772",
  "U+1F773",
  "U+1F774",
  "U+1F775",
  "U+1F776",
  "U+1F77B",
  "U+1F77C",
  "U+1F77D",
  "U+1F77E",
  "U+1F77F",
  "U+1F780",
  "U+1F781",
  "U+1F782",
  "U+1F783",
  "U+1F784",
  "U+1F785",
  "U+1F786",
  "U+1F787",
  "U+1F788",
  "U+1F789",
  "U+1F78A",
  "U+1F78B",
  "U+1F78C",
  "U+1F78D",
  "U+1F78E",
  "U+1F78F",
  "U+1F790",
  "U+1F791",
  "U+1F792",
  "U+1F793",
  "U+1F794",
  "U+1F795",
  "U+1F796",
  "U+1F797",
  "U+1F798",
  "U+1F799",
  "U+1F79A",
  "U+1F79B",
  "U+1F79C",
  "U+1F79D",
  "U+1F79E",
  "U+1F79F",
  "U+1F7A0",
  "U+1F7A1",
  "U+1F7A2",
  "U+1F7A3",
  "U+1F7A4",
  "U+1F7A5",
  "U+1F7A6",
  "U+1F7A7",
  "U+1F7A8",
  "U+1F7A9",
  "U+1F7AA",
  "U+1F7AB",
  "U+1F7AC",
  "U+1F7AD",
  "U+1F7AE",
  "U+1F7AF",
  "U+1F7B0",
  "U+1F7B1",
  "U+1F7B2",
  "U+1F7B3",
  "U+1F7B4",
  "U+1F7B5",
  "U+1F7B6",
  "U+1F7B7",
  "U+1F7B8",
  "U+1F7B9",
  "U+1F7BA",
  "U+1F7BB",
  "U+1F7BC",
  "U+1F7BD",
  "U+1F7BE",
  "U+1F7BF",
  "U+1F7C0",
  "U+1F7C1",
  "U+1F7C2",
  "U+1F7C3",
  "U+1F7C4",
  "U+1F7C5",
  "U+1F7C6",
  "U+1F7C7",
  "U+1F7C8",
  "U+1F7C9",
  "U+1F7CA",
  "U+1F7CB",
  "U+1F7CC",
  "U+1F7CD",
  "U+1F7CE",
  "U+1F7CF",
  "U+1F7D0",
  "U+1F7D1",
  "U+1F7D2",
  "U+1F7D3",
  "U+1F7D4",
  "U+1F7D5",
  "U+1F7D6",
  "U+1F7D7",
  "U+1F7D8",
  "U+1F7D9",
  "U+1F7E0",
  "U+1F7E1",
  "U+1F7E2",
  "U+1F7E3",
  "U+1F7E4",
  "U+1F7E5",
  "U+1F7E6",
  "U+1F7E7",
  "U+1F7E8",
  "U+1F7E9",
  "U+1F7EA",
  "U+1F7EB",
  "U+1F7F0",
  "U+1F800",
  "U+1F801",
  "U+1F802",
  "U+1F803",
  "U+1F804",
  "U+1F805",
  "U+1F806",
  "U+1F807",
  "U+1F808",
  "U+1F809",
  "U+1F80A",
  "U+1F80B",
  "U+1F810",
  "U+1F811",
  "U+1F812",
  "U+1F813",
  "U+1F814",
  "U+1F815",
  "U+1F816",
  "U+1F817",
  "U+1F818",
  "U+1F819",
  "U+1F81A",
  "U+1F81B",
  "U+1F81C",
  "U+1F81D",
  "U+1F81E",
  "U+1F81F",
  "U+1F820",
  "U+1F821",
  "U+1F822",
  "U+1F823",
  "U+1F824",
  "U+1F825",
  "U+1F826",
  "U+1F827",
  "U+1F828",
  "U+1F829",
  "U+1F82A",
  "U+1F82B",
  "U+1F82C",
  "U+1F82D",
  "U+1F82E",
  "U+1F82F",
  "U+1F830",
  "U+1F831",
  "U+1F832",
  "U+1F833",
  "U+1F834",
  "U+1F835",
  "U+1F836",
  "U+1F837",
  "U+1F838",
  "U+1F839",
  "U+1F83A",
  "U+1F83B",
  "U+1F83C",
  "U+1F83D",
  "U+1F83E",
  "U+1F83F",
  "U+1F840",
  "U+1F841",
  "U+1F842",
  "U+1F843",
  "U+1F844",
  "U+1F845",
  "U+1F846",
  "U+1F847",
  "U+1F850",
  "U+1F851",
  "U+1F852",
  "U+1F853",
  "U+1F854",
  "U+1F855",
  "U+1F856",
  "U+1F857",
  "U+1F858",
  "U+1F859",
  "U+1F860",
  "U+1F861",
  "U+1F862",
  "U+1F863",
  "U+1F864",
  "U+1F865",
  "U+1F866",
  "U+1F867",
  "U+1F868",
  "U+1F869",
  "U+1F86A",
  "U+1F86B",
  "U+1F86C",
  "U+1F86D",
  "U+1F86E",
  "U+1F86F",
  "U+1F870",
  "U+1F871",
  "U+1F872",
  "U+1F873",
  "U+1F874",
  "U+1F875",
  "U+1F876",
  "U+1F877",
  "U+1F878",
  "U+1F879",
  "U+1F87A",
  "U+1F87B",
  "U+1F87C",
  "U+1F87D",
  "U+1F87E",
  "U+1F87F",
  "U+1F880",
  "U+1F881",
  "U+1F882",
  "U+1F883",
  "U+1F884",
  "U+1F885",
  "U+1F886",
  "U+1F887",
  "U+1F890",
  "U+1F891",
  "U+1F892",
  "U+1F893",
  "U+1F894",
  "U+1F895",
  "U+1F896",
  "U+1F897",
  "U+1F898",
  "U+1F899",
  "U+1F89A",
  "U+1F89B",
  "U+1F89C",
  "U+1F89D",
  "U+1F89E",
  "U+1F89F",
  "U+1F8A0",
  "U+1F8A1",
  "U+1F8A2",
  "U+1F8A3",
  "U+1F8A4",
  "U+1F8A5",
  "U+1F8A6",
  "U+1F8A7",
  "U+1F8A8",
  "U+1F8A9",
  "U+1F8AA",
  "U+1F8AB",
  "U+1F8AC",
  "U+1F8AD",
  "U+1F8B0",
  "U+1F8B1",
  "U+1F900",
  "U+1F901",
  "U+1F902",
  "U+1F903",
  "U+1F904",
  "U+1F905",
  "U+1F906",
  "U+1F907",
  "U+1F908",
  "U+1F909",
  "U+1F90A",
  "U+1F90B",
  "U+1F90C",
  "U+1F90D",
  "U+1F90E",
  "U+1F90F",
  "U+1F910",
  "U+1F911",
  "U+1F912",
  "U+1F913",
  "U+1F914",
  "U+1F915",
  "U+1F916",
  "U+1F917",
  "U+1F918",
  "U+1F919",
  "U+1F91A",
  "U+1F91B",
  "U+1F91C",
  "U+1F91D",
  "U+1F91E",
  "U+1F91F",
  "U+1F920",
  "U+1F921",
  "U+1F922",
  "U+1F923",
  "U+1F924",
  "U+1F925",
  "U+1F926",
  "U+1F927",
  "U+1F928",
  "U+1F929",
  "U+1F92A",
  "U+1F92B",
  "U+1F92C",
  "U+1F92D",
  "U+1F92E",
  "U+1F92F",
  "U+1F930",
  "U+1F931",
  "U+1F932",
  "U+1F933",
  "U+1F934",
  "U+1F935",
  "U+1F936",
  "U+1F937",
  "U+1F938",
  "U+1F939",
  "U+1F93A",
  "U+1F93B",
  "U+1F93C",
  "U+1F93D",
  "U+1F93E",
  "U+1F93F",
  "U+1F940",
  "U+1F941",
  "U+1F942",
  "U+1F943",
  "U+1F944",
  "U+1F945",
  "U+1F946",
  "U+1F947",
  "U+1F948",
  "U+1F949",
  "U+1F94A",
  "U+1F94B",
  "U+1F94C",
  "U+1F94D",
  "U+1F94E",
  "U+1F94F",
  "U+1F950",
  "U+1F951",
  "U+1F952",
  "U+1F953",
  "U+1F954",
  "U+1F955",
  "U+1F956",
  "U+1F957",
  "U+1F958",
  "U+1F959",
  "U+1F95A",
  "U+1F95B",
  "U+1F95C",
  "U+1F95D",
  "U+1F95E",
  "U+1F95F",
  "U+1F960",
  "U+1F961",
  "U+1F962",
  "U+1F963",
  "U+1F964",
  "U+1F965",
  "U+1F966",
  "U+1F967",
  "U+1F968",
  "U+1F969",
  "U+1F96A",
  "U+1F96B",
  "U+1F96C",
  "U+1F96D",
  "U+1F96E",
  "U+1F96F",
  "U+1F970",
  "U+1F971",
  "U+1F972",
  "U+1F973",
  "U+1F974",
  "U+1F975",
  "U+1F976",
  "U+1F977",
  "U+1F978",
  "U+1F979",
  "U+1F97A",
  "U+1F97B",
  "U+1F97C",
  "U+1F97D",
  "U+1F97E",
  "U+1F97F",
  "U+1F980",
  "U+1F981",
  "U+1F982",
  "U+1F983",
  "U+1F984",
  "U+1F985",
  "U+1F986",
  "U+1F987",
  "U+1F988",
  "U+1F989",
  "U+1F98A",
  "U+1F98B",
  "U+1F98C",
  "U+1F98D",
  "U+1F98E",
  "U+1F98F",
  "U+1F990",
  "U+1F991",
  "U+1F992",
  "U+1F993",
  "U+1F994",
  "U+1F995",
  "U+1F996",
  "U+1F997",
  "U+1F998",
  "U+1F999",
  "U+1F99A",
  "U+1F99B",
  "U+1F99C",
  "U+1F99D",
  "U+1F99E",
  "U+1F99F",
  "U+1F9A0",
  "U+1F9A1",
  "U+1F9A2",
  "U+1F9A3",
  "U+1F9A4",
  "U+1F9A5",
  "U+1F9A6",
  "U+1F9A7",
  "U+1F9A8",
  "U+1F9A9",
  "U+1F9AA",
  "U+1F9AB",
  "U+1F9AC",
  "U+1F9AD",
  "U+1F9AE",
  "U+1F9AF",
  "U+1F9B0",
  "U+1F9B1",
  "U+1F9B2",
  "U+1F9B3",
  "U+1F9B4",
  "U+1F9B5",
  "U+1F9B6",
  "U+1F9B7",
  "U+1F9B8",
  "U+1F9B9",
  "U+1F9BA",
  "U+1F9BB",
  "U+1F9BC",
  "U+1F9BD",
  "U+1F9BE",
  "U+1F9BF",
  "U+1F9C0",
  "U+1F9C1",
  "U+1F9C2",
  "U+1F9C3",
  "U+1F9C4",
  "U+1F9C5",
  "U+1F9C6",
  "U+1F9C7",
  "U+1F9C8",
  "U+1F9C9",
  "U+1F9CA",
  "U+1F9CB",
  "U+1F9CC",
  "U+1F9CD",
  "U+1F9CE",
  "U+1F9CF",
  "U+1F9D0",
  "U+1F9D1",
  "U+1F9D2",
  "U+1F9D3",
  "U+1F9D4",
  "U+1F9D5",
  "U+1F9D6",
  "U+1F9D7",
  "U+1F9D8",
  "U+1F9D9",
  "U+1F9DA",
  "U+1F9DB",
  "U+1F9DC",
  "U+1F9DD",
  "U+1F9DE",
  "U+1F9DF",
  "U+1F9E0",
  "U+1F9E1",
  "U+1F9E2",
  "U+1F9E3",
  "U+1F9E4",
  "U+1F9E5",
  "U+1F9E6",
  "U+1F9E7",
  "U+1F9E8",
  "U+1F9E9",
  "U+1F9EA",
  "U+1F9EB",
  "U+1F9EC",
  "U+1F9ED",
  "U+1F9EE",
  "U+1F9EF",
  "U+1F9F0",
  "U+1F9F1",
  "U+1F9F2",
  "U+1F9F3",
  "U+1F9F4",
  "U+1F9F5",
  "U+1F9F6",
  "U+1F9F7",
  "U+1F9F8",
  "U+1F9F9",
  "U+1F9FA",
  "U+1F9FB",
  "U+1F9FC",
  "U+1F9FD",
  "U+1F9FE",
  "U+1F9FF",
  "U+1FA00",
  "U+1FA01",
  "U+1FA02",
  "U+1FA03",
  "U+1FA04",
  "U+1FA05",
  "U+1FA06",
  "U+1FA07",
  "U+1FA08",
  "U+1FA09",
  "U+1FA0A",
  "U+1FA0B",
  "U+1FA0C",
  "U+1FA0D",
  "U+1FA0E",
  "U+1FA0F",
  "U+1FA10",
  "U+1FA11",
  "U+1FA12",
  "U+1FA13",
  "U+1FA14",
  "U+1FA15",
  "U+1FA16",
  "U+1FA17",
  "U+1FA18",
  "U+1FA19",
  "U+1FA1A",
  "U+1FA1B",
  "U+1FA1C",
  "U+1FA1D",
  "U+1FA1E",
  "U+1FA1F",
  "U+1FA20",
  "U+1FA21",
  "U+1FA22",
  "U+1FA23",
  "U+1FA24",
  "U+1FA25",
  "U+1FA26",
  "U+1FA27",
  "U+1FA28",
  "U+1FA29",
  "U+1FA2A",
  "U+1FA2B",
  "U+1FA2C",
  "U+1FA2D",
  "U+1FA2E",
  "U+1FA2F",
  "U+1FA30",
  "U+1FA31",
  "U+1FA32",
  "U+1FA33",
  "U+1FA34",
  "U+1FA35",
  "U+1FA36",
  "U+1FA37",
  "U+1FA38",
  "U+1FA39",
  "U+1FA3A",
  "U+1FA3B",
  "U+1FA3C",
  "U+1FA3D",
  "U+1FA3E",
  "U+1FA3F",
  "U+1FA40",
  "U+1FA41",
  "U+1FA42",
  "U+1FA43",
  "U+1FA44",
  "U+1FA45",
  "U+1FA46",
  "U+1FA47",
  "U+1FA48",
  "U+1FA49",
  "U+1FA4A",
  "U+1FA4B",
  "U+1FA4C",
  "U+1FA4D",
  "U+1FA4E",
  "U+1FA4F",
  "U+1FA50",
  "U+1FA51",
  "U+1FA52",
  "U+1FA53",
  "U+1FA60",
  "U+1FA61",
  "U+1FA62",
  "U+1FA63",
  "U+1FA64",
  "U+1FA65",
  "U+1FA66",
  "U+1FA67",
  "U+1FA68",
  "U+1FA69",
  "U+1FA6A",
  "U+1FA6B",
  "U+1FA6C",
  "U+1FA6D",
  "U+1FA70",
  "U+1FA71",
  "U+1FA72",
  "U+1FA73",
  "U+1FA74",
  "U+1FA75",
  "U+1FA76",
  "U+1FA77",
  "U+1FA78",
  "U+1FA79",
  "U+1FA7A",
  "U+1FA7B",
  "U+1FA7C",
  "U+1FA80",
  "U+1FA81",
  "U+1FA82",
  "U+1FA83",
  "U+1FA84",
  "U+1FA85",
  "U+1FA86",
  "U+1FA87",
  "U+1FA88",
  "U+1FA90",
  "U+1FA91",
  "U+1FA92",
  "U+1FA93",
  "U+1FA94",
  "U+1FA95",
  "U+1FA96",
  "U+1FA97",
  "U+1FA98",
  "U+1FA99",
  "U+1FA9A",
  "U+1FA9B",
  "U+1FA9C",
  "U+1FA9D",
  "U+1FA9E",
  "U+1FA9F",
  "U+1FAA0",
  "U+1FAA1",
  "U+1FAA2",
  "U+1FAA3",
  "U+1FAA4",
  "U+1FAA5",
  "U+1FAA6",
  "U+1FAA7",
  "U+1FAA8",
  "U+1FAA9",
  "U+1FAAA",
  "U+1FAAB",
  "U+1FAAC",
  "U+1FAAD",
  "U+1FAAE",
  "U+1FAAF",
  "U+1FAB0",
  "U+1FAB1",
  "U+1FAB2",
  "U+1FAB3",
  "U+1FAB4",
  "U+1FAB5",
  "U+1FAB6",
  "U+1FAB7",
  "U+1FAB8",
  "U+1FAB9",
  "U+1FABA",
  "U+1FABB",
  "U+1FABC",
  "U+1FABD",
  "U+1FABF",
  "U+1FAC0",
  "U+1FAC1",
  "U+1FAC2",
  "U+1FAC3",
  "U+1FAC4",
  "U+1FAC5",
  "U+1FACE",
  "U+1FACF",
  "U+1FAD0",
  "U+1FAD1",
  "U+1FAD2",
  "U+1FAD3",
  "U+1FAD4",
  "U+1FAD5",
  "U+1FAD6",
  "U+1FAD7",
  "U+1FAD8",
  "U+1FAD9",
  "U+1FADA",
  "U+1FADB",
  "U+1FAE0",
  "U+1FAE1",
  "U+1FAE2",
  "U+1FAE3",
  "U+1FAE4",
  "U+1FAE5",
  "U+1FAE6",
  "U+1FAE7",
  "U+1FAE8",
  "U+1FAF0",
  "U+1FAF1",
  "U+1FAF2",
  "U+1FAF3",
  "U+1FAF4",
  "U+1FAF5",
  "U+1FAF6",
  "U+1FAF7",
  "U+1FAF8",
  "U+1FB00",
  "U+1FB01",
  "U+1FB02",
  "U+1FB03",
  "U+1FB04",
  "U+1FB05",
  "U+1FB06",
  "U+1FB07",
  "U+1FB08",
  "U+1FB09",
  "U+1FB0A",
  "U+1FB0B",
  "U+1FB0C",
  "U+1FB0D",
  "U+1FB0E",
  "U+1FB0F",
  "U+1FB10",
  "U+1FB11",
  "U+1FB12",
  "U+1FB13",
  "U+1FB14",
  "U+1FB15",
  "U+1FB16",
  "U+1FB17",
  "U+1FB18",
  "U+1FB19",
  "U+1FB1A",
  "U+1FB1B",
  "U+1FB1C",
  "U+1FB1D",
  "U+1FB1E",
  "U+1FB1F",
  "U+1FB20",
  "U+1FB21",
  "U+1FB22",
  "U+1FB23",
  "U+1FB24",
  "U+1FB25",
  "U+1FB26",
  "U+1FB27",
  "U+1FB28",
  "U+1FB29",
  "U+1FB2A",
  "U+1FB2B",
  "U+1FB2C",
  "U+1FB2D",
  "U+1FB2E",
  "U+1FB2F",
  "U+1FB30",
  "U+1FB31",
  "U+1FB32",
  "U+1FB33",
  "U+1FB34",
  "U+1FB35",
  "U+1FB36",
  "U+1FB37",
  "U+1FB38",
  "U+1FB39",
  "U+1FB3A",
  "U+1FB3B",
  "U+1FB3C",
  "U+1FB3D",
  "U+1FB3E",
  "U+1FB3F",
  "U+1FB40",
  "U+1FB41",
  "U+1FB42",
  "U+1FB43",
  "U+1FB44",
  "U+1FB45",
  "U+1FB46",
  "U+1FB47",
  "U+1FB48",
  "U+1FB49",
  "U+1FB4A",
  "U+1FB4B",
  "U+1FB4C",
  "U+1FB4D",
  "U+1FB4E",
  "U+1FB4F",
  "U+1FB50",
  "U+1FB51",
  "U+1FB52",
  "U+1FB53",
  "U+1FB54",
  "U+1FB55",
  "U+1FB56",
  "U+1FB57",
  "U+1FB58",
  "U+1FB59",
  "U+1FB5A",
  "U+1FB5B",
  "U+1FB5C",
  "U+1FB5D",
  "U+1FB5E",
  "U+1FB5F",
  "U+1FB60",
  "U+1FB61",
  "U+1FB62",
  "U+1FB63",
  "U+1FB64",
  "U+1FB65",
  "U+1FB66",
  "U+1FB67",
  "U+1FB68",
  "U+1FB69",
  "U+1FB6A",
  "U+1FB6B",
  "U+1FB6C",
  "U+1FB6D",
  "U+1FB6E",
  "U+1FB6F",
  "U+1FB70",
  "U+1FB71",
  "U+1FB72",
  "U+1FB73",
  "U+1FB74",
  "U+1FB75",
  "U+1FB76",
  "U+1FB77",
  "U+1FB78",
  "U+1FB79",
  "U+1FB7A",
  "U+1FB7B",
  "U+1FB7C",
  "U+1FB7D",
  "U+1FB7E",
  "U+1FB7F",
  "U+1FB80",
  "U+1FB81",
  "U+1FB82",
  "U+1FB83",
  "U+1FB84",
  "U+1FB85",
  "U+1FB86",
  "U+1FB87",
  "U+1FB88",
  "U+1FB89",
  "U+1FB8A",
  "U+1FB8B",
  "U+1FB8C",
  "U+1FB8D",
  "U+1FB8E",
  "U+1FB8F",
  "U+1FB90",
  "U+1FB91",
  "U+1FB92",
  "U+1FB94",
  "U+1FB95",
  "U+1FB96",
  "U+1FB97",
  "U+1FB98",
  "U+1FB99",
  "U+1FB9A",
  "U+1FB9B",
  "U+1FB9C",
  "U+1FB9D",
  "U+1FB9E",
  "U+1FB9F",
  "U+1FBA0",
  "U+1FBA1",
  "U+1FBA2",
  "U+1FBA3",
  "U+1FBA4",
  "U+1FBA5",
  "U+1FBA6",
  "U+1FBA7",
  "U+1FBA8",
  "U+1FBA9",
  "U+1FBAA",
  "U+1FBAB",
  "U+1FBAC",
  "U+1FBAD",
  "U+1FBAE",
  "U+1FBAF",
  "U+1FBB0",
  "U+1FBB1",
  "U+1FBB2",
  "U+1FBB3",
  "U+1FBB4",
  "U+1FBB5",
  "U+1FBB6",
  "U+1FBB7",
  "U+1FBB8",
  "U+1FBB9",
  "U+1FBBA",
  "U+1FBBB",
  "U+1FBBC",
  "U+1FBBD",
  "U+1FBBE",
  "U+1FBBF",
  "U+1FBC0",
  "U+1FBC1",
  "U+1FBC2",
  "U+1FBC3",
  "U+1FBC4",
  "U+1FBC5",
  "U+1FBC6",
  "U+1FBC7",
  "U+1FBC8",
  "U+1FBC9",
  "U+1FBCA",
];
