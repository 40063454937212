import React from "react";
import { Offcanvas, OtpInput } from "@Components";
import type { Props } from "./DisableOtp.i";
import { useDisableOtp } from "./useDisableOtp";
import { t } from "i18next";

export const DisableOtp = React.memo(
  ({ showOffcanvas, setShowOffcanvas }: Props) => {
    const { buttons, setOtp, onCanvasClose } = useDisableOtp(setShowOffcanvas);

    return (
      <Offcanvas
        title={t("profile.disable-otp") as string}
        isOpen={showOffcanvas}
        toggle={onCanvasClose}
        buttons={buttons}
      >
        <div>{t("profile.offcanvas.disable-otp-text")}</div>
        <div className="mt-2">
          <p className="form-label">
            {t("profile.offcanvas.authentication-code")}
          </p>
          <OtpInput setOtp={setOtp} />
        </div>
      </Offcanvas>
    );
  }
);
