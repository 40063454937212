/**
 * Trims all occurrences of the specified character from both the start and the end of the given string.
 *
 * @param {string} str - The input string to be trimmed.
 * @param {string} character - The character to trim from the beginning and end of the string.
 * @returns {string} - The trimmed string. If the string contains only the specified character, an empty string is returned.
 *
 * @example
 * // Basic usage:
 * trimByChar('---hello---', '-'); // Returns 'hello'
 *
 * @example
 * // Trimming a different character:
 * trimByChar('###example###', '#'); // Returns 'example'
 *
 * @example
 * // When the string is entirely composed of the character:
 * trimByChar('******', '*'); // Returns ''
 *
 * @example
 * // When the string does not contain the character at the edges:
 * trimByChar('hello', '-'); // Returns 'hello'
 *
 * @example
 * // When the character is present only at one end:
 * trimByChar('-hello', '-'); // Returns 'hello'
 * trimByChar('hello-', '-'); // Returns 'hello'
 */
export const trimByChar = (str: string, character: string): string => {
  const splitted = Array.from(str);
  const first = splitted.findIndex(char => char !== character);
  const last = splitted.reverse().findIndex(char => char !== character);
  return first === -1 && last === -1
    ? ""
    : str.substring(first, str.length - last);
};
