import { HOME_PATH } from "@Constants";
import { useOrganizationId } from "@Hooks";
import { generateUrl } from "@Utils";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const RedirectToHomePage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();

  useEffect(() => {
    if (!pathname.startsWith("/organizations")) {
      navigate(generateUrl(HOME_PATH, [organizationId ?? ""]));
    }
  }, [pathname, organizationId, navigate]);

  return null;
};
