import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAuthUserContext, organizations, roles } from "src/Mock";

export const getUserRole = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  let result;
  const context = getAuthUserContext();
  const user = context.getUser();
  const { organizationId } = req.params;

  if (!organizationId) {
    return res(ctx.delay(delay), ctx.status(403));
  }

  if (!user) {
    const organizationIds = organizations.map(o => o.id);
    result = [
      {
        active: true,
        id: "11",
        organizationID: "1",
        role: "organization_admin",
      },
      {
        active: true,
        id: "11",
        organizationID: "2",
        role: "user",
      },
    ].filter(r => organizationIds.includes(r.organizationID));
  } else {
    result = roles.filter(
      item => item.id === user?.id && item.organizationID === organizationId
    );
  }

  if (!result.length) {
    return res(ctx.delay(delay), ctx.status(403));
  }

  return res(ctx.delay(delay), ctx.json({ role: result[0] }));
};
