import { getStartOf } from "@Services";
import moment from "moment-timezone";
import { issues } from "src/Mock/data";
import { seeder } from "src/Mock/seeder";

export const findIssuesOfOrganization = (
  organizationId: string,
  criterias: string[] = [],
  values: string[] = []
) => {
  if (!issues.has(organizationId)) {
    seeder().issue(organizationId);
  }

  let data = issues.get(organizationId) || [];

  const severityIndex = criterias.indexOf("issue_severity");

  if (severityIndex >= 0) {
    const severity = values[severityIndex];
    data = data.filter(issue => issue.severity === severity);
  }

  const periodIndex = criterias.indexOf("period");

  if (periodIndex >= 0) {
    const start = getStartOf(values[periodIndex]);
    data = data.filter(issue => moment(issue.createdAt).isSameOrAfter(start));
  }

  return data;
};
