import { NewProjectContext, NewProjectExtrasContext } from "@Contexts";
import { usePool } from "@Hooks";
import type { DictionaryEntry } from "@Services";
import { useCallback, useContext } from "react";

export const useDictionary = () => {
  const { project, setProject } = useContext(NewProjectContext);
  const { projectExtras } = useContext(NewProjectExtrasContext);

  const { pool, consume, restore } = usePool<string>(
    projectExtras.oasFile?.parameters ?? []
  );

  const addEntry = useCallback(
    (dictionaryEntry: DictionaryEntry) => {
      project.dictionary.unshift(dictionaryEntry);
      consume(dictionaryEntry.key);
      setProject({ ...project });
    },
    [consume, project, setProject]
  );

  const removeEntry = useCallback(
    async (index: number) => {
      const [deleted] = project.dictionary.splice(index, 1);
      restore(deleted.key);
      setProject({ ...project });
      return true;
    },
    [project, restore, setProject]
  );

  return { pool, addEntry, removeEntry, entries: project.dictionary };
};
