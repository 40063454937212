import React, { memo } from "react";
import type { Props } from "./Table.i";
import { ListItems } from "@Components";
import { useTableConfig } from "./useTableConfig";

export const ProjectsListTable = memo((props: Props) => {
  const {
    config,
    headers,
    cardVersion,
    loading,
    setCardVersion,
    defaultSorts,
    setSorts,
  } = useTableConfig(props);

  return (
    <ListItems
      headers={headers}
      columnDistribution="auto"
      className="mb-0"
      showSkeleton={loading}
      config={config}
      cardVersion={cardVersion}
      setCardVersion={setCardVersion}
      updateCardItemOrder={[0, 1, 2, 3, 5, 4]}
      fullRowIndexes={[0, 5]}
      defaultSorts={defaultSorts}
      setSorts={setSorts}
    />
  );
});
