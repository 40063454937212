import {
  NewProjectContext,
  NewProjectExtrasContext,
  PageContext,
} from "@Contexts";
import { useManageApiResponse, useOrganizationId } from "@Hooks";
import { API, getPreference, runPolicyDateConverter } from "@Services";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../constants";
import { PROJECTS_PATH } from "@Constants";
import { generateUrl, isStatusCodeSuccess } from "@Utils";

const useBottomBar = () => {
  const { page, setPage } = useContext(PageContext);
  const { project } = useContext(NewProjectContext);
  const { projectExtras } = useContext(NewProjectExtrasContext);
  const [loading, setLoading] = useState(false);
  const manageResponse = useManageApiResponse(setLoading);

  const organizationId = useOrganizationId();
  const navigate = useNavigate();
  const isLastPage = page === Pages.Summary;

  const toProjectListing = useCallback(() => {
    if (!organizationId) throw new Error("no-organization-id");
    let destination = generateUrl(PROJECTS_PATH, [organizationId]);
    const search = getPreference("organization.projects.search");
    if (search?.startsWith("?")) {
      destination = `${destination}${search}`;
    }
    navigate(destination);
  }, [navigate, organizationId]);

  const onCreate = useCallback(async () => {
    if (!organizationId) return;
    const { status } = await manageResponse({
      errorMessage: "post-project",
      statusErrorMessage: {
        "423": "new-project.errors.create-failed-due-to-pendings",
      },
      promise: API.project().projectCreate(organizationId, {
        ...project,
        runPolicy: runPolicyDateConverter(project.runPolicy),
      }),
    });

    if (isStatusCodeSuccess(status)) {
      toProjectListing();
    }
  }, [manageResponse, organizationId, project, toProjectListing]);

  const previousPage = useCallback(() => {
    if (page > 0) setPage(page - 1);
  }, [setPage, page]);

  const nextPage = useCallback(() => {
    if (!isLastPage) setPage(page + 1);
  }, [setPage, page, isLastPage]);

  return {
    page,
    project,
    projectExtras,
    navigation: {
      isFirstPage: page === Pages.NameAndLicense,
      isLastPage,
      nextPage,
      previousPage,
    },
    actions: {
      onCreate,
      onCancel: toProjectListing,
    },
    status: {
      loading,
    },
  };
};

export default useBottomBar;
