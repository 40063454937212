import { useArchivedProject, useProject } from "@Hooks";
import type { AuthenticationFlow } from "@Interfaces";
import {
  handleAuthenticationRemovedFromProject,
  projectDataToUpdateProjectData,
} from "@Services";
import { cloneDeep } from "lodash";
import { useCallback, useEffect } from "react";
import type {
  AuthenticationFormMode,
  AuthenticationSettingsFormModel,
  CanvasFlow,
} from "../Authentication.i";
import { useAuthenticationStore } from "../Authentication.store";

export const useProjectAuthenticationCards = () => {
  const { project, update } = useProject();
  const {
    authenticationSettings,
    setAuthenticationSettings,
    setCanvasFlow,
    setFormValue,
    setCanvasMode,
  } = useAuthenticationStore();
  const isArchivedProject = useArchivedProject();

  useEffect(() => {
    const authenticationSettings = project?.authenticationSettings ?? [];
    const injectables = project?.injectables ?? [];
    const mtlsCertificates = project?.mTLSCertificates ?? [];

    const authentications: Array<AuthenticationSettingsFormModel> = [];

    authenticationSettings.forEach(authenticationSetting => {
      const thisInjectables = injectables.filter(
        i => i.associatedAuthSetting === authenticationSetting.name
      );
      const thisMtlsCertificates = mtlsCertificates.filter(
        m => m.associatedAuthSetting === authenticationSetting.name
      );

      authentications.push({
        authenticationSettings: authenticationSetting,
        injectables: thisInjectables,
        mTLSCertificates: thisMtlsCertificates,
      });
    });

    setAuthenticationSettings(authentications);
  }, [
    project?.authenticationSettings,
    project?.injectables,
    project?.mTLSCertificates,
    setAuthenticationSettings,
  ]);

  const handleAuthenticationDelete = useCallback(
    async (index: number) => {
      if (!project) return false;
      const updateProject = handleAuthenticationRemovedFromProject(
        project,
        index
      );
      if (!updateProject) return false;
      const requestBody = projectDataToUpdateProjectData(updateProject);
      let success = false;
      success = await update(requestBody);
      return success;
    },
    [project, update]
  );

  const handleOpenCanvas = useCallback(
    (flow: CanvasFlow, mode: AuthenticationFormMode) => {
      setCanvasMode(mode);
      setCanvasFlow(flow);
    },
    [setCanvasFlow, setCanvasMode]
  );

  const handleAuthenticationEdit = useCallback(
    (index: number) => {
      const authenticationSetting = authenticationSettings[index];
      setFormValue({ value: authenticationSetting, index });
      handleOpenCanvas(
        authenticationSetting.authenticationSettings
          .authenticationFlow as AuthenticationFlow,
        "edit"
      );
    },
    [authenticationSettings, handleOpenCanvas, setFormValue]
  );

  const handleAuthenticationClone = useCallback(
    (index: number) => {
      const authenticationSetting = cloneDeep(authenticationSettings[index]);
      authenticationSetting.authenticationSettings.name = "";

      setFormValue({ value: authenticationSetting, index: -1 });
      handleOpenCanvas(
        authenticationSetting.authenticationSettings
          .authenticationFlow as AuthenticationFlow,
        "insert"
      );
    },
    [authenticationSettings, handleOpenCanvas, setFormValue]
  );

  return {
    authenticationSettings,
    isArchivedProject,
    handleOpenCanvas,
    handleAuthenticationDelete: isArchivedProject
      ? undefined
      : handleAuthenticationDelete,
    handleAuthenticationClone: isArchivedProject
      ? undefined
      : handleAuthenticationClone,
    handleAuthenticationEdit: isArchivedProject
      ? undefined
      : handleAuthenticationEdit,
  };
};
