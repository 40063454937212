import { rest } from "msw";
import { getApiUrl } from "@Services";
import {} from "./data"; // Not remove
import {
  projectGet,
  projectPost,
  projectPut,
  userInvite,
  organizationDelete,
  getDashboardSummary,
  getDashboardChart,
  askEnable2fa,
  confirmEnable2fa,
  login,
  verifyOtp,
  projectDelete,
  getOrganizationScans,
  getProjectScans,
  settingsGet,
  getOrganizationIssues,
  getProjectIssues,
  userGet,
  userPut,
  getScanReport,
  getIssue,
  newIssueExclusion,
  settingsPut,
  logout,
  disable2fa,
  userConfirmEnable2fa,
  userAskEnable2fa,
  refreshToken,
  confirmPasswordReset,
  askPasswordReset,
  listIssueExclusions,
  deleteIssueExclusions,
  apiKeyList,
  getRoles,
  updateUserRoles,
  getUserRoles,
  deleteUserRoles,
  apiKeyPost,
  editIssueExclusions,
  getSingleInventoryAction,
  getInventory,
  getApiDefinitions,
  getOrganizations,
  getUsers,
  getUser,
  updateUser,
  getProjects,
  getLicenses,
  apiKeyGet,
  apiKeyDelete,
  apiKeyPut,
  projectArchive,
  getOrganizationSettings,
  updateOrganizationSettings,
  createScan,
  getOrganizationOpenIssues,
  getProjectOpenIssues,
  getScan,
  stopScan,
  getScanIssues,
  scanAuthenticationSettings,
  scanDictionary,
  scanPathExclusions,
  scanIssueExclusions,
  scanInjectables,
  scanAdvancedSettings,
  getScanAuthorizationMatrix,
  getAuthenticationProfiles,
  userReinvite,
  getExtensionSettings,
  putExtensionSettings,
  getScanListedAttempts,
  getScanAttempt,
  getOrganization,
  scanMTLSCertificates,
  getUserRole,
  getParameterGraph,
  getUserLogs,
  getApiKeyLogs,
  listSpaceLink,
  getSpaceLink,
  createSpaceLink,
  editSpaceLink,
  deleteSpaceLink,
} from "./actions";
import { CYPRESS_RUNNING } from "@Constants";

export const delay = CYPRESS_RUNNING ? 0 : 300;

const apiPath = getApiUrl();
const authPath = `${apiPath}organizations/:organizationId`;
const userPath = `${apiPath}users`;
const projectPath = `${authPath}/projects/:projectId`;
const apiKeysPath = `${authPath}/apikeys`;
const scanPath = `${projectPath}/scans/:scanId`;
const spaceLinkPath = `${authPath}/spacelinks`;

const loginHandlers = [
  rest.post(`${apiPath}ask-enable-2fa`, askEnable2fa),
  rest.post(`${apiPath}confirm-enable-2fa`, confirmEnable2fa),
  rest.post(`${apiPath}login`, login),
  rest.post(`${apiPath}verify-otp`, verifyOtp),
];

const dashboardHandlers = [
  rest.get(`${authPath}/dashboard/chart`, getDashboardChart),
  rest.get(`${projectPath}/dashboard/chart`, getDashboardChart),
  rest.get(`${authPath}/dashboard/summary`, getDashboardSummary),
  rest.get(`${projectPath}/dashboard/summary`, getDashboardSummary),
];

const scanHandlers = [
  rest.get(`${authPath}/scans`, getOrganizationScans),
  rest.get(`${projectPath}/scans`, getProjectScans),
  rest.post(`${projectPath}/scans/:scanId/report`, getScanReport),
  rest.get(`${projectPath}/scans/:scanId`, getScan),
  rest.get(`${projectPath}/scans/:scanId/issues`, getScanIssues),
  rest.post(`${projectPath}/scans/:scanId/stop`, stopScan),
  rest.post(`${projectPath}/scans/create`, createScan),
  rest.get(
    `${projectPath}/scans/:scanId/authorization-matrix`,
    getScanAuthorizationMatrix
  ),
  rest.get(`${projectPath}/scans/:scanId/attempts`, getScanListedAttempts),
  rest.get(`${projectPath}/scans/:scanId/attempts/:attemptId`, getScanAttempt),
];

const issueHandlers = [
  rest.get(`${authPath}/issues`, getOrganizationIssues),
  rest.get(`${authPath}/issues/open`, getOrganizationOpenIssues),
  rest.get(`${projectPath}/issues`, getProjectIssues),
  rest.get(`${projectPath}/issues/open`, getProjectOpenIssues),
  rest.get(`${projectPath}/scans/:scanId/issues/:issueId`, getIssue),
];

const projectHandlers = [
  rest.get(`${projectPath}`, projectGet),
  rest.post(`${authPath}/projects`, projectPost),
  rest.put(`${projectPath}`, projectPut),
  rest.delete(`${projectPath}`, projectDelete),
  rest.put(`${projectPath}/archive`, projectArchive),
];

const userHandlers = [
  rest.get(`${userPath}`, userGet),
  rest.put(`${userPath}`, userPut),
  rest.get(`${userPath}/settings`, settingsGet),
  rest.put(`${userPath}/settings`, settingsPut),
  rest.post(`${userPath}/ask-password-reset`, askPasswordReset),
  rest.post(`${userPath}/confirm-password-reset`, confirmPasswordReset),
  rest.get(`${userPath}/refresh-token`, refreshToken),
  rest.post(`${userPath}/ask-enable-2fa`, userAskEnable2fa),
  rest.post(`${userPath}/confirm-enable-2fa`, userConfirmEnable2fa),
  rest.post(`${userPath}/disable-2fa`, disable2fa),
  rest.get(`${userPath}/logout`, logout),
];

const scansSettingsHandlers = [
  rest.post(`${projectPath}/issue-exclusions`, newIssueExclusion),
  rest.get(`${projectPath}/issue-exclusions`, listIssueExclusions),
  rest.delete(
    `${projectPath}/issue-exclusions/:issueExclusionId`,
    deleteIssueExclusions
  ),
  rest.put(
    `${projectPath}/issue-exclusions/:issueExclusionId`,
    editIssueExclusions
  ),
  rest.get(`${scanPath}/authentication-settings`, scanAuthenticationSettings),
  rest.get(`${scanPath}/dictionary`, scanDictionary),
  rest.get(`${scanPath}/path-exclusions`, scanPathExclusions),
  rest.get(`${scanPath}/issue-exclusions`, scanIssueExclusions),
  rest.get(`${scanPath}/injectables`, scanInjectables),
  rest.get(`${scanPath}/mtls-certificates`, scanMTLSCertificates),
  rest.get(`${scanPath}/advanced-settings`, scanAdvancedSettings),
  rest.get(`${scanPath}/authentication-profiles`, getAuthenticationProfiles),
  // extensions settings
  rest.get(`${projectPath}/extension-settings`, getExtensionSettings),
  rest.get(`${scanPath}/extension-settings`, getExtensionSettings),
  rest.put(`${projectPath}/extension-settings`, putExtensionSettings),
];

const apiKeysHandlers = [
  rest.get(`${apiKeysPath}`, apiKeyList),
  rest.get(`${apiKeysPath}/:apiKeyId`, apiKeyGet),
  rest.post(`${apiKeysPath}`, apiKeyPost),
  rest.put(`${apiKeysPath}/:apiKeyId/roles`, apiKeyPut),
  rest.delete(`${apiKeysPath}/:apiKeyId`, apiKeyDelete),
];

const roleHandlers = [
  rest.get(`${apiPath}roles`, getRoles),
  rest.get(`${authPath}/roles`, getUserRole),
  rest.put(`${authPath}/users/:userId/roles`, updateUserRoles),
  rest.get(`${authPath}/users/:userId/roles`, getUserRoles),
  rest.delete(`${authPath}/users/:userId/roles`, deleteUserRoles), // user kick
];

const organizationHandlers = [
  rest.delete(`${authPath}`, organizationDelete),
  rest.post(`${authPath}/users/invite`, userInvite),
  rest.post(`${authPath}/users/:userId/re-invite`, userReinvite),
  rest.get(`${apiPath}organizations`, getOrganizations),
  rest.get(`${apiPath}organizations/:organizationId`, getOrganization),
  rest.get(`${authPath}/users`, getUsers),
  rest.get(`${authPath}/users/:userId`, getUser),
  rest.put(`${authPath}/users/:userId`, updateUser),
  rest.get(`${authPath}/projects`, getProjects),
  rest.get(`${authPath}/licenses`, getLicenses),
  rest.get(`${authPath}/settings`, getOrganizationSettings),
  rest.put(`${authPath}/settings`, updateOrganizationSettings),
];

const inventoryHandlers = [
  rest.get(`${projectPath}/api-definitions`, getApiDefinitions),
  rest.get(
    `${projectPath}/api-definitions/:apiDefinitionId/inventory`,
    getInventory
  ),
  rest.get(
    `${projectPath}/inventory/:inventoryItemId`,
    getSingleInventoryAction
  ),
  rest.get(`${projectPath}/parameter-graph`, getParameterGraph),
];

const auditLogHandlers = [
  rest.get(`${authPath}/users/:userId/audit-logs`, getUserLogs),
  rest.get(`${authPath}/apikeys/:apiKeyId/audit-logs`, getApiKeyLogs),
];

const spaceLinkHandlers = [
  rest.get(`${spaceLinkPath}`, listSpaceLink),
  rest.get(`${spaceLinkPath}/:spaceLinkId`, getSpaceLink),
  rest.post(`${spaceLinkPath}`, createSpaceLink),
  rest.put(`${spaceLinkPath}/:spaceLinkId`, editSpaceLink),
  rest.delete(`${spaceLinkPath}/:spaceLinkId`, deleteSpaceLink),
];

export const handlers = [
  ...loginHandlers,
  ...dashboardHandlers,
  ...projectHandlers,
  ...issueHandlers,
  ...scanHandlers,
  ...scansSettingsHandlers,
  ...roleHandlers,
  ...userHandlers,
  ...apiKeysHandlers,
  ...organizationHandlers,
  ...inventoryHandlers,
  ...auditLogHandlers,
  ...spaceLinkHandlers,
];

export * from "./utils";
export * from "./helpers";
export * from "./data";
