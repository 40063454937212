import type { FC } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import type { SpaceLinkFormProps } from "./SpaceLinkForm.i";
import { useSpaceLinkForm } from "./Hooks/useSpaceLinkForm";
import { DatePicker, FormInput, Switch } from "@Components";
import { t } from "i18next";
import moment from "moment-timezone";
import { joinClasses } from "@Utils";

const expiresAtLabel = t("common.expires-at");

export const SpaceLinkForm: FC<SpaceLinkFormProps> = props => {
  const {
    handleExpiresAtUpdate,
    handleFieldUpdate,
    spaceLink,
    validities,
    disabled,
    expiresAtChecked,
    editMode,
    handleExpirationToggleChange,
  } = useSpaceLinkForm(props);

  return (
    <Form onSubmit={undefined}>
      <FormGroup>
        <FormInput
          type="text"
          label="common.name"
          value={spaceLink.name}
          onChange={handleFieldUpdate}
          valid={validities.name}
          invalid={!validities.name}
          id="space-link-form-input-name"
          name="name"
          data-cy="space-link-form-input-name"
          disabled={disabled}
          autoTrim
          autoComplete="off"
        />
      </FormGroup>

      {editMode ? (
        <FormGroup noMargin>
          <Switch
            checked={spaceLink.active}
            title="common.status"
            id="space-link-form-input-active"
            data-cy="space-link-form-input-active"
            name="active"
            onChange={handleFieldUpdate}
            disabled={disabled}
          />
        </FormGroup>
      ) : (
        <div>
          <FormGroup noMargin switch className="d-flex">
            <Input
              type="checkbox"
              checked={expiresAtChecked}
              onChange={handleExpirationToggleChange}
              id="space-link-form-toggle-expires-at"
              data-cy="space-link-form-toggle-expires-at"
              disabled={disabled}
            />
            <Label
              htmlFor="space-link-form-toggle-expires-at"
              className={joinClasses("ps-2", !disabled && "cursor-pointer")}
            >
              {expiresAtLabel}
            </Label>
          </FormGroup>
          <DatePicker
            onChange={handleExpiresAtUpdate}
            disabled={disabled || !expiresAtChecked}
            min={moment().add(1, "day").toDate()}
            value={new Date(spaceLink.expiresAt)}
            id="space-link-form-input-expires-at"
          />
        </div>
      )}
    </Form>
  );
};
