import { ErrorPage, LoginPage, MaintenancePage, NotFound } from "@Pages";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  HomeRoutes,
  OrganizationRoutes,
  ProfileRoutes,
  ProjectRoutes,
} from "./Routes";
import { LOGIN_PATH } from "@Constants";
import { Root } from "./Root";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      ...HomeRoutes,
      ...ProjectRoutes,
      ...OrganizationRoutes,
      ...ProfileRoutes,
      {
        path: LOGIN_PATH,
        element: <LoginPage />,
      },
      {
        path: "/forgot-password",
        element: <LoginPage subPage="forgot-password" />,
      },
      {
        path: "/new-password",
        element: <LoginPage subPage="new-password" />,
      },
      {
        path: "/setup-2fa",
        element: <LoginPage subPage="setup-2fa" />,
      },
      {
        path: "/2fa",
        element: <LoginPage subPage="send-otp" />,
      },
      {
        path: "/500",
        element: <ErrorPage />,
      },
      {
        path: "/maintenance-in-progress",
        element: <MaintenancePage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
export const AppRouter = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
