import type { Spec } from "../types.i";
import { swaggerParser, postmanParser } from "./parsers";

export const getData = async (spec: Spec, textFile: string) => {
  try {
    return await swaggerParser(spec, textFile);
  } catch (e: any) {
    // unparsabled as swagger
  }

  try {
    return await postmanParser(spec, textFile);
  } catch (e: any) {
    // unparsabled as postman collection
  }

  return undefined;
};
