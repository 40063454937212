import { getPathnameParams } from "@Services";
import { useLocation } from "react-router-dom";

let cache = {
  pathname: "",
  params: getPathnameParams(""),
};

export const useUrlParams = () => {
  const { pathname } = useLocation(); // triggers the re-render at pathname change

  if (cache.pathname === pathname) {
    // cache HIT
    return cache.params;
  }

  // cache MISS
  const params = getPathnameParams(pathname);
  cache = { pathname, params };
  return params;
};
