import { AuthMatrixFilters, Paginator, Section } from "@Components";
import { memo, useMemo } from "react";
import { Table } from "./Table";
import { useGetData } from "./useGetData";

import "./style.scss";
import { useAuthorizationMatrixStore } from "./Store/AuthorizationMatrixStore";

export const AuthorizationMatrix = memo(() => {
  const { fetch, setFilters } = useGetData();
  const { totalItems } = useAuthorizationMatrixStore();

  const filters = useMemo(
    () => (
      <Section className={"border-none"}>
        <div className="d-flex justify-content-between align-items-end">
          <AuthMatrixFilters updateFilters={setFilters} refresh={fetch} />
        </div>
      </Section>
    ),
    [fetch, setFilters]
  );

  const table = useMemo(() => <Table setFilters={setFilters} />, [setFilters]);

  return (
    <div id="scan-authorization-matrix">
      {filters}
      {table}
      <Paginator
        setFilters={setFilters}
        totalItems={totalItems}
        className="p-4"
      />
    </div>
  );
});
