import { Button, type ButtonProps } from "@Components";
import { useArchivedProject, useIsReader } from "@Hooks";

type Props = Pick<ButtonProps, "loading" | "disabled" | "size" | "onClick">;

export const TrashButton = ({
  onClick,
  loading,
  disabled,
  size = "md",
}: Props) => {
  const isArchivedProject = useArchivedProject();
  if (useIsReader() || isArchivedProject) {
    return <></>;
  }

  return (
    <Button
      className="trash-button"
      type="button"
      iconClass="trash"
      size={size}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      color="primary"
      data={{
        cy: "trash-button",
      }}
    />
  );
};
