import { HorizontalLineChart } from "@Components";
import type { MostCommonIssuesCardProps } from "./MostCommonIssues.i";
import { useMemo } from "react";
import { severityColors } from "@Constants";

export const ChartView = ({
  mostCommonIssues,
}: Required<MostCommonIssuesCardProps>) => {
  const chartData = useMemo(() => {
    const colors = severityColors as Record<string, string>;
    return mostCommonIssues.map(i => ({
      label: i.name,
      value: i.count,
      color: colors[i.severity],
    }));
  }, [mostCommonIssues]);

  return (
    <div className="pt-1">
      <HorizontalLineChart data={chartData} />
    </div>
  );
};
