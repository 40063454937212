import type { ParameterLinksEntry } from "@Services";

export const ParameterLinks: Record<string, Array<ParameterLinksEntry>> = {
  "1": [
    {
      consumingParameterLocation: "body",
      consumingParameterPath: "\u003croot-param\u003e",
      producingParameterLocation: "body",
      producingParameterPath: "\u003croot-param\u003e",
      consumingOperationVerb: "GET",
      consumingOperationURI: "/instance/hostname",
      producingOperationVerb: "GET",
      producingOperationURI: "/instance/attributes/",
    },
    {
      consumingParameterLocation: "body",
      consumingParameterPath: "\u003croot-param\u003e",
      producingParameterLocation: "body",
      producingParameterPath: "\u003croot-param\u003e",
      consumingOperationVerb: "GET",
      consumingOperationURI: "/project/attributes/",
      producingOperationVerb: "GET",
      producingOperationURI: "/instance/attributes/",
    },
    {
      consumingParameterLocation: "query",
      consumingParameterPath: "recursive",
      producingParameterLocation: "body",
      producingParameterPath: "\u003croot-param\u003e",
      consumingOperationVerb: "GET",
      consumingOperationURI: "/project/attributes/",
      producingOperationVerb: "GET",
      producingOperationURI: "/instance/attributes/",
    },
  ],
};
