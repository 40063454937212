import { Row } from "reactstrap";
import type { Props } from "./AdvancedSettingsForm.i";
import { memo } from "react";
import { getOnChangeHandler } from "./functions";
import "./style.scss";
import { AdvancedSettingsProviderAttackStrength } from "@Services";
import { AdvancedSettingsFormContext } from "./AdvancedSettingsFormContext";
import { AdvancedSettingsInput } from "./Components";

const NANO_IN_SECOND = 1_000_000_000;
const TIMEOUT_MIN_VALUE = 0;

export const AdvancedSettingsForm = memo((props: Props) => {
  const { advancedSettings, setAdvancedSettings = () => false } = props;

  return (
    <AdvancedSettingsFormContext.Provider value={props}>
      <Row className="flex-wrap" id="advanced-settings-form">
        <AdvancedSettingsInput
          type="number"
          id="clientTimeout"
          label="clientTimeout (seconds)"
          min={TIMEOUT_MIN_VALUE}
          value={`${advancedSettings.clientTimeout / NANO_IN_SECOND}`}
          {...getOnChangeHandler({
            props,
            key: `clientTimeout`,
            mult: NANO_IN_SECOND,
          })}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="disableCompression"
          label="disableCompression"
          checked={advancedSettings.disableCompression}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              disableCompression: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="disableKeepAlives"
          label="disableKeepAlives"
          checked={advancedSettings.disableKeepAlives}
          onChange={e =>
            setAdvancedSettings({
              ...advancedSettings,
              disableKeepAlives: e.target.checked,
            })
          }
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="enableDebugHeaders"
          label="enableDebugHeaders"
          checked={advancedSettings.enableDebugHeaders}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              enableDebugHeaders: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="number"
          id="exitOnCoverage"
          label="exitOnCoverage (%)"
          min={0}
          max={100}
          value={`${advancedSettings.exitOnCoverage}`}
          {...getOnChangeHandler({
            props,
            key: "exitOnCoverage",
          })}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="exitOnFullCoverage"
          label="exitOnFullCoverage"
          checked={advancedSettings.exitOnFullCoverage}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              exitOnFullCoverage: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="number"
          id="exitOnReachability"
          label="exitOnReachability (%)"
          min={0}
          max={100}
          value={`${advancedSettings.exitOnReachability}`}
          {...getOnChangeHandler({
            props,
            key: "exitOnReachability",
          })}
        />
        <AdvancedSettingsInput
          type="number"
          id="explorationPhaseTimeout"
          label="explorationPhaseTimeout (seconds)"
          min={TIMEOUT_MIN_VALUE}
          value={`${advancedSettings.explorationPhaseTimeout / NANO_IN_SECOND}`}
          {...getOnChangeHandler({
            props,
            key: "explorationPhaseTimeout",
            mult: NANO_IN_SECOND,
          })}
        />
        <AdvancedSettingsInput
          type="number"
          id="exploitationPhaseTimeout"
          label="exploitationPhaseTimeout (seconds)"
          min={TIMEOUT_MIN_VALUE}
          value={`${advancedSettings.exploitationPhaseTimeout / NANO_IN_SECOND}`}
          {...getOnChangeHandler({
            props,
            key: "exploitationPhaseTimeout",
            mult: NANO_IN_SECOND,
          })}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="followRedirect"
          label="followRedirect"
          checked={advancedSettings.followRedirect}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              followRedirect: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="insecureSkipVerify"
          label="insecureSkipVerify"
          checked={advancedSettings.insecureSkipVerify}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              insecureSkipVerify: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="number"
          id="maxIteration"
          label="maxIteration"
          min={0}
          value={`${advancedSettings.maxIteration}`}
          {...getOnChangeHandler({
            props,
            key: "maxIteration",
          })}
        />
        <AdvancedSettingsInput
          type="number"
          id="maxRenderAttempts"
          label="maxRenderAttempts"
          min={0}
          value={`${advancedSettings.maxRenderAttempts}`}
          {...getOnChangeHandler({
            props,
            key: "maxRenderAttempts",
          })}
        />
        <AdvancedSettingsInput
          type="text"
          id="proxyUrl"
          label="proxyUrl"
          value={`${advancedSettings.proxyUrl}`}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              proxyUrl: e.target.value,
            });
          }}
        />
        <AdvancedSettingsInput
          type="text"
          id="userAgent"
          label="userAgent"
          value={`${advancedSettings.userAgent}`}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              userAgent: e.target.value,
            });
          }}
        />

        <AdvancedSettingsInput
          type="number"
          id="requestThrottle"
          label="requestThrottle (seconds)"
          min={TIMEOUT_MIN_VALUE}
          value={`${advancedSettings.requestThrottle / NANO_IN_SECOND}`}
          {...getOnChangeHandler({
            props,
            key: "requestThrottle",
            mult: NANO_IN_SECOND,
          })}
        />
        <AdvancedSettingsInput
          type="number"
          id="requestJitterPercentage"
          label="requestJitterPercentage (%)"
          min={0}
          max={100}
          value={`${advancedSettings.requestJitterPercentage}`}
          {...getOnChangeHandler({
            props,
            key: "requestJitterPercentage",
          })}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="anonymousExploration"
          label="anonymousExploration"
          checked={advancedSettings.anonymousExploration}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              anonymousExploration: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="select"
          id="providerAttackStrength"
          label="providerAttackStrength"
          value={advancedSettings.providerAttackStrength}
          options={Object.values(AdvancedSettingsProviderAttackStrength).map(
            v => ({
              label: `common.${v}`,
              value: v,
            })
          )}
          onChange={e => {
            const v = e.target.value as AdvancedSettingsProviderAttackStrength;
            setAdvancedSettings({
              ...advancedSettings,
              providerAttackStrength: v,
            });
          }}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="exploitOnlyResolvedNodes"
          label="exploitOnlyResolvedNodes"
          checked={advancedSettings.exploitOnlyResolvedNodes}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              exploitOnlyResolvedNodes: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="includeAuthProfileOnlyForResolved"
          label="includeAuthProfileOnlyForResolved"
          checked={advancedSettings.includeAuthProfileOnlyForResolved}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              includeAuthProfileOnlyForResolved: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="checkbox"
          id="includeParametersInHash"
          label="includeParametersInHash"
          checked={advancedSettings.includeParametersInHash}
          onChange={e => {
            setAdvancedSettings({
              ...advancedSettings,
              includeParametersInHash: e.target.checked,
            });
          }}
        />
        <AdvancedSettingsInput
          type="number"
          id="providerMaxScanDuration"
          label="providerMaxScanDuration (minutes)"
          min={TIMEOUT_MIN_VALUE}
          value={`${advancedSettings.providerMaxScanDuration / NANO_IN_SECOND / 60}`}
          {...getOnChangeHandler({
            props,
            key: `providerMaxScanDuration`,
            mult: NANO_IN_SECOND * 60,
          })}
        />
      </Row>
    </AdvancedSettingsFormContext.Provider>
  );
});
