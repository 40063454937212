import React from "react";

type Sizes = "xs" | "sm" | "md" | "lg" | "xl";

export function useResponsive(lessThen: Sizes) {
  const [mobile, setMobile] = React.useState(false);

  let size: number;
  switch (lessThen) {
    case "xs":
      size = 576;
      break;
    case "sm":
      size = 768;
      break;
    case "md":
      size = 992;
      break;
    case "lg":
      size = 1200;
      break;
    case "xl":
      size = 1400;
      break;
  }

  React.useEffect(() => {
    const resizeF = () => {
      setMobile(window.innerWidth <= size);
    };
    window.addEventListener("resize", resizeF);
    resizeF();

    return () => window.removeEventListener("resize", resizeF);
  }, [size]);

  return mobile;
}
