import { memo, useCallback, useMemo, type FC } from "react";
import type { DangerZoneController } from "../../ProjectGeneral.i";
import isEqual from "react-fast-compare";
import { DangerZone } from "@Components";
import type { Project } from "@Services";
import { useUrlParams } from "@Hooks";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "@Utils";
import { PROJECTS_PATH } from "@Constants";
import { Alert } from "reactstrap";
import { t } from "i18next";
import { getRemaingDaysBeforeDelete } from "../../functions";

type DangerZoneContainerProps = {
  project: Project;
  dangerZoneController: DangerZoneController;
};

export const DangerZoneContainer: FC<DangerZoneContainerProps> = memo(
  ({
    project: { name, archivedAt },
    dangerZoneController: { archiveProject, deleteProject },
  }) => {
    const navigate = useNavigate();
    const { organizationId } = useUrlParams();

    const backToList = useCallback(() => {
      if (!organizationId) throw new Error("missing-organization-id");
      navigate(generateUrl(PROJECTS_PATH, [organizationId]));
    }, [navigate, organizationId]);

    const children = useMemo(() => {
      const action = archivedAt ? "delete" : "archive";
      const daysRemaining = getRemaingDaysBeforeDelete(archivedAt);
      const promise = action === "delete" ? deleteProject : archiveProject;

      if (action === "delete" && daysRemaining > 0) {
        return (
          <>
            <hr className="my-4" />
            <Alert color="warning">
              <p className="p-1 m-0">
                {t("common.delete-button-time", { daysRemaining })}
              </p>
            </Alert>
          </>
        );
      }

      return (
        <DangerZone
          confirmationText={name}
          subject={name}
          target="project"
          onSuccess={backToList}
          promise={promise}
          toastSuccessMessage={`${action}-project`}
          toastErrorMessage={`${action}-project`}
          action={action}
        />
      );
    }, [archiveProject, archivedAt, backToList, deleteProject, name]);

    return <section className="mt-3">{children}</section>;
  },
  isEqual
);
