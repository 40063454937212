import {} from "@Components";
import type { Project } from "@Services";
import type { FC } from "react";
import { ProjectShowCase } from "../ProjectShowcase";

type ReadOnlyModeProps = {
  project: Project;
};

export const ReadOnlyMode: FC<ReadOnlyModeProps> = ({ project }) => {
  return <ProjectShowCase project={project} />;
};
