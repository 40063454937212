import type { Dispatch, SetStateAction } from "react";
import React, { createContext, useState } from "react";

export const PageContext = createContext({
  page: 1 as number,
  setPage: (() => null) as Dispatch<SetStateAction<number>>,
});

export const PageProvider = ({ children }: { children: JSX.Element }) => {
  const [page, setPage] = useState<number>(1);

  return (
    <PageContext.Provider value={{ page, setPage }}>
      {children}
    </PageContext.Provider>
  );
};
