import { t } from "i18next";
import { DashboardCard, NoVulnerableData } from "../Components";
import type { ListedVulnerableProjectEntity } from "@Services";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { DashboardCardProps } from "./DashboardCard.i";
import { PROJECT_DETAILS_URL } from "@Constants";
import { generateUrl } from "@Utils";
import { useUrlParams } from "@Hooks";

const SORT_MAPPER: Record<string, number> = {
  high: 3,
  medium: 2,
  low: 1,
};

function projectPredicate({ riskLevel }: { riskLevel: string }) {
  return riskLevel === "high" || riskLevel === "medium" || riskLevel === "low";
}

export const MostVulnerableProjectCard = ({
  vulnerableProjects,
  showNoData,
}: DashboardCardProps<{
  vulnerableProjects?: Array<ListedVulnerableProjectEntity>;
}>) => {
  const navigate = useNavigate();
  const { organizationId } = useUrlParams();

  const cardContent = useMemo(() => {
    if (!vulnerableProjects || showNoData || !organizationId) {
      return null;
    }

    return vulnerableProjects
      .filter(projectPredicate)
      .sort((a, b) => SORT_MAPPER[b.riskLevel] - SORT_MAPPER[a.riskLevel])
      .slice(0, 3) // max 3 project can be displayed
      .map(({ name, riskLevel, projectID }, i) => {
        const handleOnClick = () => {
          const projectDashboardURL = generateUrl(PROJECT_DETAILS_URL, [
            organizationId,
            projectID,
          ]);
          navigate(projectDashboardURL);
        };
        return (
          <div
            className="card cursor-pointer"
            key={`${i}-${name}`}
            onClick={handleOnClick}
          >
            <div className="project-name-label">{name}</div>
            <div className="project-infos">
              <span className={`severity color-severity-${riskLevel}`}>
                {t(`common.${riskLevel}`)}
              </span>
              <span>&nbsp;&nbsp;{t("common.risk-level")}</span>
            </div>
          </div>
        );
      });
  }, [navigate, organizationId, showNoData, vulnerableProjects]);

  return (
    <DashboardCard className="titled most-vulnerable-projects">
      <span className="title">{t("common.most-vulnerable-projects")}</span>
      {showNoData || !cardContent?.length ? (
        <NoVulnerableData text="common.no-projects" />
      ) : (
        <div className="card-wrapper">{cardContent}</div>
      )}
    </DashboardCard>
  );
};
