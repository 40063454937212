import type { KeyboardEvent } from "react";

export const handleInputKeyPress = (
  refs: HTMLInputElement[],
  event: KeyboardEvent<HTMLInputElement>,
  index: number
) => {
  const newCharacter = event.key;

  const allowedSpecialKeys = ["Tab", "Delete", "ArrowLeft", "ArrowRight"];

  if (allowedSpecialKeys.includes(newCharacter)) {
    return;
  }

  const input = event.target as HTMLInputElement;
  const inputValue = input.value;

  const notNumberKey = newCharacter.match(/[^0-9]/);
  const isDeleting = newCharacter === "Backspace";

  if (notNumberKey && !isDeleting) {
    // invalid input
    event.preventDefault();
    return;
  }

  if (inputValue && !isDeleting) {
    // input already filled
    input.value = "";
    return;
  }

  if (index > 0 && !inputValue && isDeleting) {
    const prevInput = refs[index - 1];
    prevInput.value = "";
    prevInput.focus();
    return;
  }
};
