import { useOrganizationContext } from "@Hooks";
import type { LicenseName } from "@Interfaces";
import { type ComponentType } from "react";

export function withLicence<WrappedComponentProps extends object>(
  licenceName: LicenseName,
  WrappedComponent: ComponentType<WrappedComponentProps>
): ComponentType<WrappedComponentProps> {
  return (props: WrappedComponentProps) => {
    const { license } = useOrganizationContext();

    if (license.loading || license.name !== licenceName) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}
