import { usePreference, useUpdateCacheFilters } from "@Hooks";
import type { Props } from "./ApiKeysShowCase.i";
import { useLocation, useNavigate } from "react-router-dom";
import type { ApiKeyId } from "@Interfaces";
import { useCallback, useMemo } from "react";
import { getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { getConfig, getHeaders } from "./functions";

export const useApiKeyShowCase = ({ response, ...rest }: Props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [cardVersion, setCardVersion] = usePreference(
    "organization-api-keys-card-version",
    "true"
  );

  const defaultFilters = useMemo(() => getDefaultFilters("apikeys"), []);
  const updateFilters = useUpdateCacheFilters("organization.api-keys");

  const goToDetail = useCallback(
    (id: ApiKeyId) => navigate(`${pathname}/${id}`),
    [navigate, pathname]
  );

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      items: getHeaders(),
      updateFunction: updateFilters,
      defaults: defaultFilters,
      search,
    });
  }, [defaultFilters, search, updateFilters]);

  const config = useMemo(
    () => getConfig(response?.data || [], goToDetail),
    [goToDetail, response?.data]
  );

  return {
    cardVersion: cardVersion === "true",
    setCardVersion,
    response,
    updateFilters,
    headers,
    defaultFilters,
    config,
    ...rest,
  };
};
