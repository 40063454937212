import { NewProjectContext } from "@Contexts";
import type { AuthenticationFlow } from "@Interfaces";
import { handleAuthenticationRemovedFromProject } from "@Services";
import { cloneDeep } from "lodash";
import { useCallback, useContext } from "react";
import type { AuthenticationFormMode, CanvasFlow } from "../Authentication.i";
import { useAuthenticationStore } from "../Authentication.store";

export const useAuthenticationCards = () => {
  const { project, setProject } = useContext(NewProjectContext);
  const {
    authenticationSettings,
    removeAuthenticationSettings,
    setCanvasMode,
    setCanvasFlow,
    setFormValue,
  } = useAuthenticationStore();

  const handleAuthenticationSetting = useCallback(
    (index: number) => {
      const updatedProject = handleAuthenticationRemovedFromProject(
        project,
        index
      );
      if (!updatedProject) return false;
      removeAuthenticationSettings(index);
      setProject(updatedProject);
      return true;
    },
    [project, removeAuthenticationSettings, setProject]
  );

  const handleOpenCanvas = useCallback(
    (flow: CanvasFlow, mode: AuthenticationFormMode) => {
      setCanvasMode(mode);
      setCanvasFlow(flow);
    },
    [setCanvasFlow, setCanvasMode]
  );

  const handleAuthenticationEdit = useCallback(
    (index: number) => {
      const authenticationSetting = authenticationSettings[index];
      setFormValue({ value: authenticationSetting, index });
      handleOpenCanvas(
        authenticationSetting.authenticationSettings
          .authenticationFlow as AuthenticationFlow,
        "edit"
      );
    },
    [authenticationSettings, handleOpenCanvas, setFormValue]
  );

  const handleAuthenticationClone = useCallback(
    (index: number) => {
      const authenticationSetting = cloneDeep(authenticationSettings[index]);
      authenticationSetting.authenticationSettings.name = "";

      setFormValue({ value: authenticationSetting, index: -1 });
      handleOpenCanvas(
        authenticationSetting.authenticationSettings
          .authenticationFlow as AuthenticationFlow,
        "insert"
      );
    },
    [authenticationSettings, handleOpenCanvas, setFormValue]
  );

  return {
    project,
    authenticationSettings,
    handleAuthenticationSetting,
    handleAuthenticationEdit,
    handleAuthenticationClone,
    handleOpenCanvas,
  };
};
