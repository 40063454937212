import { useState } from "react";

type AllowedTypes = string | number;

function sort<T>(pool: Array<T>) {
  if (pool.length) {
    pool.sort((a, b) => {
      return `${a}`.localeCompare(`${b}`, undefined, {
        numeric: typeof pool[0] === "number",
        sensitivity: "base",
      });
    });
  }
  return pool;
}

export const usePool = <T extends AllowedTypes>(
  entries: Array<T> | (() => Array<T>)
) => {
  const [uniques] = useState(() => {
    let list = typeof entries === "function" ? entries() : entries;
    list = list.filter((e, i) => {
      return list.lastIndexOf(e) === i;
    });
    return sort(list);
  });
  const [pool, setPool] = useState<Array<T>>(() => [...uniques]);

  return {
    pool,
    restore: (entry: T): boolean => {
      let outcome = false;
      if (uniques.includes(entry) && !pool.includes(entry)) {
        pool.push(entry);
        setPool([...sort(pool)]);
        outcome = true;
      }
      return outcome;
    },
    consume: (entry: T): boolean => {
      let outcome = false;
      if (uniques.includes(entry)) {
        const index = pool.indexOf(entry);
        if (index >= 0) {
          pool.splice(index, 1);
          setPool([...sort(pool)]);
          outcome = true;
        }
      }
      return outcome;
    },
  };
};
