import { useProject } from "@Hooks";
import type { AuthenticationFlow } from "@Interfaces";
import type {
  AuthenticationSettings,
  Injectable,
  MTLSCertificate,
} from "@Services";
import {
  getEmptyAuthenticationSetting,
  handleAuthenticationAddedToProject,
  handleAuthenticationEditedToProject,
  projectDataToUpdateProjectData,
} from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthenticationStore } from "../Authentication.store";

export const useProjectAuthenticationCanvas = () => {
  const { formValue, canvasFlow, canvasMode, setFormValue, resetCanvas } =
    useAuthenticationStore();
  const [canvasLoading, setCanvasLoading] = useState(false);
  const { project, update } = useProject();

  const openCreateCanvas = useCallback(
    (authenticationFlow: AuthenticationFlow) => {
      if (formValue) {
        /* while in edit mode, formValue is set externally */
        return;
      }

      const authenticationSettings = getEmptyAuthenticationSetting({
        authenticationFlow,
        role: "admin",
      });

      if (authenticationFlow === "oauth_credentials") {
        if (!authenticationSettings.userAuthTokens?.length) {
          authenticationSettings.userAuthTokens = [
            {
              authenticationSchemeType: "http-bearer",
              key: "Authorization",
              location: "header",
            },
          ];
        }
      }

      setFormValue({
        value: {
          authenticationSettings: authenticationSettings,
          injectables: [],
          mTLSCertificates: [],
        },
        index: -1 /* new one */,
      });
    },
    [formValue, setFormValue]
  );

  useEffect(() => {
    if (canvasFlow) {
      openCreateCanvas(canvasFlow);
    }
  }, [canvasFlow, openCreateCanvas]);

  const closeCanvas = useCallback(resetCanvas, [resetCanvas]);

  const handleFormSubmit = useCallback(
    async (
      authenticationSettings: AuthenticationSettings,
      injectables?: Injectable[],
      mTLSCertificates?: MTLSCertificate[]
    ) => {
      if (!project) {
        return false;
      }

      let updatedProject = { ...project };

      switch (canvasMode) {
        case "insert": {
          updatedProject = handleAuthenticationAddedToProject(
            project,
            authenticationSettings,
            injectables,
            mTLSCertificates
          );
          break;
        }
        case "edit": {
          updatedProject = handleAuthenticationEditedToProject(
            project,
            authenticationSettings,
            injectables ?? [],
            mTLSCertificates ?? [],
            formValue?.index ?? 0
          );
        }
      }

      const requestBody = projectDataToUpdateProjectData(updatedProject);
      setCanvasLoading(true);
      const success = await update(requestBody);
      setCanvasLoading(false);
      return success;
    },
    [canvasMode, formValue?.index, project, update]
  );

  const invalidNames = useMemo(() => {
    return project?.authenticationSettings.map(({ name }) => name) ?? [];
  }, [project?.authenticationSettings]);

  return {
    showCanvas: !!canvasFlow,
    loading: canvasLoading,
    model: formValue,
    invalidNames,
    actions: {
      openCreateCanvas,
      closeCanvas,
      handleFormSubmit,
    },
  };
};
