import { FormInput, RefreshListButton } from "@Components";
import { getFilterAllowedOptions } from "@Services";
import { useMemo } from "react";
import type { Props } from "./PageFilters.i";
import { PageFiltersWrapper } from "./PageFiltersWrapper";
import { usePageFilters } from "./usePageFilters";

export const AuthMatrixFilters = ({ updateFilters, refresh }: Props) => {
  const { getValueOf } = usePageFilters();
  const [verbOptions] = useMemo(() => {
    return [getFilterAllowedOptions("auth_matrix_verb")];
  }, []);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-verb"
        label="components.date-filter.verb-label"
        type="select"
        options={verbOptions}
        value={getValueOf("auth_matrix_verb")}
        onChange={e => {
          updateFilters({
            auth_matrix_verb: e.target.value,
          });
        }}
      />
      <FormInput
        type="text"
        id="input-auth-matrix-path"
        label="common.path"
        value={getValueOf("auth_matrix_path")}
        className="ms-3"
        onChange={e => {
          updateFilters({
            auth_matrix_path: e.target.value,
          });
        }}
      />
      <div className="d-flex align-items-end ms-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
