import type { Nullable, OrganizationId, OrganizationUser } from "@Interfaces";
import { findUsersByOrganizationId } from ".";
import moment from "moment-timezone";

type MatchFields = {
  organizationId: OrganizationId;
  searchParams: URLSearchParams;
};

type Comparator = (a: OrganizationUser, b: OrganizationUser) => number;

export const findUsersByFilters = ({
  organizationId,
  searchParams,
}: MatchFields) => {
  let userPool = findUsersByOrganizationId(organizationId);
  const criteria = searchParams.getAll("filter_criteria");
  const values = searchParams.getAll("filter_values");

  const predicates: Array<(user: OrganizationUser) => boolean> = [];

  criteria.forEach((c, i) => {
    const v = values[i] ? values[i].toLowerCase() : "";
    if (!v) {
      return;
    } else if (c === "user_name") {
      predicates.push(u => u.name.toLowerCase().includes(v));
    } else if (c === "user_surname") {
      predicates.push(u => u.surname.toLowerCase().includes(v));
    } else if (c === "user_surname_name") {
      predicates.push(u =>
        [u.name, u.surname].some(e => e.toLowerCase().includes(v))
      );
    } else if (c === "user_email") {
      predicates.push(u => u.email.toLowerCase().includes(v));
    } else if (c === "user_role_active") {
      predicates.push(u => (u.roleActive ? "true" : "false") === v);
    } else if (c === "user_role_value") {
      predicates.push(u => u.role.toLowerCase().includes(v));
    }
  });

  userPool = userPool.filter(u => predicates.every(p => p(u)));

  const sortBy = searchParams.get("sort_by") || "user_created_at";
  const sortMode = searchParams.get("sort_mode") || "desc";

  let comparator: Nullable<Comparator> = null;

  if (sortBy === "user_name") {
    comparator = (a, b) => a.name.localeCompare(b.name);
  } else if (sortBy === "user_surname") {
    comparator = (a, b) => a.surname.localeCompare(b.surname);
  } else if (sortBy === "user_surname_name") {
    comparator = (a, b) => {
      const v1 = `${a.surname} ${a.name}`;
      const v2 = `${b.surname} ${b.name}`;
      return v1.localeCompare(v2);
    };
  } else if (sortBy === "user_email") {
    comparator = (a, b) => a.email.localeCompare(b.email);
  } else if (sortBy === "user_role_active") {
    comparator = (a, b) => {
      if (a.roleActive === b.roleActive) return 0;
      if (a.roleActive) return -1;
      if (b.roleActive) return 1;
      return 0;
    };
  } else if (sortBy === "user_role_value") {
    comparator = (a, b) => a.role.localeCompare(b.role);
  } else if (sortBy === "user_created_at") {
    comparator = (a, b) => moment(a.createdAt).diff(b.createdAt);
  }

  if (comparator) {
    userPool.sort((a, b) => {
      return (sortMode === "desc" ? 1 : -1) * comparator(a, b);
    });
  }

  return userPool;
};
