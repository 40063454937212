import { Breadcrumb } from "@Components";
import { HOME_SCANS_URL, homeLastVisitedPageRegex } from "@Constants";
import { lastVisitedPageByRegexp } from "@Services";
import { t } from "i18next";

type OrganizatiPropsonScanListBreadcrumb = {
  disabled?: boolean;
};

const scanRegexp = homeLastVisitedPageRegex("scans");

export const HomeScanListBreadcrumb = ({
  disabled = false,
}: OrganizatiPropsonScanListBreadcrumb) => {
  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(scanRegexp) ?? HOME_SCANS_URL;
  return (
    <Breadcrumb disabled={disabled} name={"scans"} to={to}>
      {t("common.list-scans")}
    </Breadcrumb>
  );
};
