/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiKeyResponse } from '../model';
// @ts-ignore
import { OrganizationAdminAPIKeysListResponse } from '../model';
// @ts-ignore
import { OrganizationAdminCreateAPIKeysRequest } from '../model';
// @ts-ignore
import { OrganizationAdminCreateAPIKeysResponse } from '../model';
// @ts-ignore
import { OrganizationAdminUpdateAPIKeyRoleRequest } from '../model';
/**
 * ApiKeyApi - axios parameter creator
 * @export
 */
export const ApiKeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an api-key in the given organization
         * @param {string} organizationId Organization ID
         * @param {string} apiKeyId API Key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminApiKeysDelete: async (organizationId: string, apiKeyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminApiKeysDelete', 'organizationId', organizationId)
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('organizationAdminApiKeysDelete', 'apiKeyId', apiKeyId)
            const localVarPath = `/v1/organizations/{organization_id}/apikeys/{api_key_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"api_key_id"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the API key with the given id in the organization
         * @param {string} organizationId Organization ID
         * @param {string} apiKeyId API key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetApiKeyByOrganizationId: async (organizationId: string, apiKeyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetApiKeyByOrganizationId', 'organizationId', organizationId)
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('organizationAdminGetApiKeyByOrganizationId', 'apiKeyId', apiKeyId)
            const localVarPath = `/v1/organizations/{organization_id}/apikeys/{api_key_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"api_key_id"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the api keys related to the organization with the given id.
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListApiKeysByOrganizationId: async (organizationId: string, page?: number, size?: number, sortBy?: 'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminListApiKeysByOrganizationId', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/apikeys`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user with role API in the given organization
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminCreateAPIKeysRequest} requestBody OrganizationAdminCreateAPIKeysRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminTokensCreate: async (organizationId: string, requestBody: OrganizationAdminCreateAPIKeysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminTokensCreate', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminTokensCreate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/apikeys`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an API key role
         * @param {string} apiKeyId API key ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateAPIKeyRoleRequest} requestBody OrganizationAdminUpdateAPIKeyRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateApiKeyRole: async (apiKeyId: string, organizationId: string, requestBody: OrganizationAdminUpdateAPIKeyRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('organizationAdminUpdateApiKeyRole', 'apiKeyId', apiKeyId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminUpdateApiKeyRole', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminUpdateApiKeyRole', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/apikeys/{api_key_id}/roles`
                .replace(`{${"api_key_id"}}`, encodeURIComponent(String(apiKeyId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeyApi - functional programming interface
 * @export
 */
export const ApiKeyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiKeyApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an api-key in the given organization
         * @param {string} organizationId Organization ID
         * @param {string} apiKeyId API Key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminApiKeysDelete(organizationId: string, apiKeyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminApiKeysDelete(organizationId, apiKeyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the API key with the given id in the organization
         * @param {string} organizationId Organization ID
         * @param {string} apiKeyId API key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetApiKeyByOrganizationId(organizationId: string, apiKeyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetApiKeyByOrganizationId(organizationId, apiKeyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the api keys related to the organization with the given id.
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminListApiKeysByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminAPIKeysListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminListApiKeysByOrganizationId(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user with role API in the given organization
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminCreateAPIKeysRequest} requestBody OrganizationAdminCreateAPIKeysRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminTokensCreate(organizationId: string, requestBody: OrganizationAdminCreateAPIKeysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminCreateAPIKeysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminTokensCreate(organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an API key role
         * @param {string} apiKeyId API key ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateAPIKeyRoleRequest} requestBody OrganizationAdminUpdateAPIKeyRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminUpdateApiKeyRole(apiKeyId: string, organizationId: string, requestBody: OrganizationAdminUpdateAPIKeyRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminUpdateApiKeyRole(apiKeyId, organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiKeyApi - factory interface
 * @export
 */
export const ApiKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiKeyApiFp(configuration)
    return {
        /**
         * Delete an api-key in the given organization
         * @param {string} organizationId Organization ID
         * @param {string} apiKeyId API Key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminApiKeysDelete(organizationId: string, apiKeyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminApiKeysDelete(organizationId, apiKeyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the API key with the given id in the organization
         * @param {string} organizationId Organization ID
         * @param {string} apiKeyId API key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetApiKeyByOrganizationId(organizationId: string, apiKeyId: string, options?: any): AxiosPromise<ApiKeyResponse> {
            return localVarFp.organizationAdminGetApiKeyByOrganizationId(organizationId, apiKeyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the api keys related to the organization with the given id.
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListApiKeysByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<OrganizationAdminAPIKeysListResponse> {
            return localVarFp.organizationAdminListApiKeysByOrganizationId(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user with role API in the given organization
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminCreateAPIKeysRequest} requestBody OrganizationAdminCreateAPIKeysRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminTokensCreate(organizationId: string, requestBody: OrganizationAdminCreateAPIKeysRequest, options?: any): AxiosPromise<OrganizationAdminCreateAPIKeysResponse> {
            return localVarFp.organizationAdminTokensCreate(organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an API key role
         * @param {string} apiKeyId API key ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateAPIKeyRoleRequest} requestBody OrganizationAdminUpdateAPIKeyRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateApiKeyRole(apiKeyId: string, organizationId: string, requestBody: OrganizationAdminUpdateAPIKeyRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminUpdateApiKeyRole(apiKeyId, organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiKeyApi - interface
 * @export
 * @interface ApiKeyApi
 */
export interface ApiKeyApiInterface {
    /**
     * Delete an api-key in the given organization
     * @param {string} organizationId Organization ID
     * @param {string} apiKeyId API Key ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApiInterface
     */
    organizationAdminApiKeysDelete(organizationId: string, apiKeyId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the API key with the given id in the organization
     * @param {string} organizationId Organization ID
     * @param {string} apiKeyId API key ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApiInterface
     */
    organizationAdminGetApiKeyByOrganizationId(organizationId: string, apiKeyId: string, options?: AxiosRequestConfig): AxiosPromise<ApiKeyResponse>;

    /**
     * Returns the api keys related to the organization with the given id.
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApiInterface
     */
    organizationAdminListApiKeysByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminAPIKeysListResponse>;

    /**
     * Creates a new user with role API in the given organization
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminCreateAPIKeysRequest} requestBody OrganizationAdminCreateAPIKeysRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApiInterface
     */
    organizationAdminTokensCreate(organizationId: string, requestBody: OrganizationAdminCreateAPIKeysRequest, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminCreateAPIKeysResponse>;

    /**
     * Updates an API key role
     * @param {string} apiKeyId API key ID
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateAPIKeyRoleRequest} requestBody OrganizationAdminUpdateAPIKeyRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApiInterface
     */
    organizationAdminUpdateApiKeyRole(apiKeyId: string, organizationId: string, requestBody: OrganizationAdminUpdateAPIKeyRoleRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ApiKeyApi - object-oriented interface
 * @export
 * @class ApiKeyApi
 * @extends {BaseAPI}
 */
export class ApiKeyApi extends BaseAPI implements ApiKeyApiInterface {
    /**
     * Delete an api-key in the given organization
     * @param {string} organizationId Organization ID
     * @param {string} apiKeyId API Key ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public organizationAdminApiKeysDelete(organizationId: string, apiKeyId: string, options?: AxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).organizationAdminApiKeysDelete(organizationId, apiKeyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the API key with the given id in the organization
     * @param {string} organizationId Organization ID
     * @param {string} apiKeyId API key ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public organizationAdminGetApiKeyByOrganizationId(organizationId: string, apiKeyId: string, options?: AxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).organizationAdminGetApiKeyByOrganizationId(organizationId, apiKeyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the api keys related to the organization with the given id.
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public organizationAdminListApiKeysByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'api_key_created_at' | 'api_key_name' | 'api_key_role_status' | 'api_key_role_value' | 'api_key_expires_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'api_key_name' | 'api_key_role_value' | 'api_key_role_status'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).organizationAdminListApiKeysByOrganizationId(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user with role API in the given organization
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminCreateAPIKeysRequest} requestBody OrganizationAdminCreateAPIKeysRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public organizationAdminTokensCreate(organizationId: string, requestBody: OrganizationAdminCreateAPIKeysRequest, options?: AxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).organizationAdminTokensCreate(organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an API key role
     * @param {string} apiKeyId API key ID
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateAPIKeyRoleRequest} requestBody OrganizationAdminUpdateAPIKeyRoleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public organizationAdminUpdateApiKeyRole(apiKeyId: string, organizationId: string, requestBody: OrganizationAdminUpdateAPIKeyRoleRequest, options?: AxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).organizationAdminUpdateApiKeyRole(apiKeyId, organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
