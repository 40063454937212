import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "@Constants";
import { AuthContext } from "@Contexts";

const handleAPISuccess = (response: AxiosResponse<any, any>) => response;

/**
 * @description this component is used to centralize axios.js configuration
 * under the "authenticated" user context
 */
const ResponseInterceptor = memo(() => {
  const navigate = useNavigate();

  const user = useContext(AuthContext);

  const userCanAccessDashboard = useMemo(() => {
    return !!user.user && !!user.role;
  }, [user.role, user.user]);

  const handleAPIErrors = useCallback(
    (error: AxiosError) => {
      switch (error.response?.status) {
        case 401:
          const redirect = `${LOGIN_PATH}?session-expired=1`;
          navigate(redirect);
          break;
        case 403:
          navigate(LOGIN_PATH);
          break;
      }
      return Promise.reject(error);
    },
    [navigate]
  );

  useEffect(() => {
    if (!userCanAccessDashboard) return;
    const interceptor = axios.interceptors.response.use(
      handleAPISuccess,
      handleAPIErrors
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [handleAPIErrors, userCanAccessDashboard]);
  return null;
});

export default ResponseInterceptor;
