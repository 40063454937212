import type { IssueListingType } from "@Interfaces";
import { Tabs as TabsComponent } from "@Components";
import { useNavigate } from "react-router-dom";
import { getPreference } from "@Services";
import type { Dispatch } from "react";
import { useCallback } from "react";

interface Props {
  forProject: boolean;
  activeTab: number;
  setActiveTab: Dispatch<number>;
  openIssueCount: number | undefined;
}

export const Tabs = ({
  openIssueCount,
  activeTab,
  setActiveTab,
  forProject,
}: Props) => {
  const navigate = useNavigate();
  const updateSearchUrl = useCallback(
    (type: IssueListingType) => {
      const prefix = forProject ? "project" : "organization";
      const key = `${prefix}.${type}-issues.search`;
      const search = getPreference(key) || undefined;
      navigate({ search }, { replace: true });
    },
    [forProject, navigate]
  );
  return (
    <TabsComponent
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      updateUrl
      tabs={[
        {
          label: "common.open",
          counter: openIssueCount,
          onClick: () => updateSearchUrl("open"),
        },
        {
          label: "common.all",
          onClick: () => updateSearchUrl("all"),
        },
      ]}
    />
  );
};
