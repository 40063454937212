import { isMaxEndDate } from "@Services";
import moment from "moment";

export const prettyFormatDiff = (startDate: string, endDate: string) => {
  const duration = moment.duration(moment(endDate).diff(startDate));
  const milliseconds = Math.abs(duration.asMilliseconds());

  if (milliseconds < 1000) {
    // Less than a second
    return `${milliseconds}ms`;
  } else if (milliseconds < 60000) {
    // Less than a minute
    return `${duration.seconds()}s`;
  } else if (milliseconds < 3600000) {
    // Less than an hour
    const seconds = duration.seconds();
    if (seconds <= 0) {
      return `${duration.minutes()}m`;
    }
    return `${duration.minutes()}m ${duration.seconds()}s`;
  } else {
    // More than an hour
    return moment.utc(milliseconds).format("HH:mm:ss.SSS");
  }
};

/**
 *
 * @param input
 * @param unitOfTime
 * @returns the difference between today and the input date.
 * result > 0 : input is after today
 * result = 0 : same dates
 * result < 0 : input is before today
 */
export const getDaysBetween = (input: moment.MomentInput) => {
  if (!input) return NaN;

  let momentInput = moment(input, undefined, true);

  if (!momentInput.isValid()) return NaN;
  if (isMaxEndDate(momentInput)) return Infinity;

  const now = moment().startOf("day");
  momentInput = momentInput.startOf("day");

  const result = now.diff(momentInput, "day");
  if (!result) return 0; // handle result = -0
  return -result;
};
