import type { AccordionProps } from "@Components";
import { Accordion } from "@Components";
import { useMemo } from "react";
import type { AuthenticationSettingsCardProps } from "../../AuthenticationCard.i";
import {
  AdditionalDataTab,
  CredentialsTab,
  EndpointsTab,
  InjectablesTab,
  MTLSCertificateTab,
  RefreshDefinitionTab,
  TokenTab,
} from "./Components";

type Props = Pick<
  AuthenticationSettingsCardProps,
  "authenticationSettings" | "injectables" | "mtlsCertificates"
>;

export const DetailsAccordion = ({
  authenticationSettings,
  injectables,
  mtlsCertificates,
}: Props) => {
  const accordionData = useMemo<AccordionProps["data"]>(() => {
    const { authenticationFlow, inputOperations, oAuthData } =
      authenticationSettings;
    const data: AccordionProps["data"] = [];

    const addAccordionItem = (
      key: string,
      header: string,
      children: JSX.Element
    ) => {
      data.push({ key, header, children });
    };

    switch (authenticationFlow) {
      case "static_token":
        addAccordionItem(
          "token-configuration",
          `project.authentication-and-dictionary.token-configuration`,
          <TokenTab {...authenticationSettings} />
        );
        if (authenticationSettings.refreshDefinition) {
          addAccordionItem(
            "refresh-definition",
            `project.authentication-and-dictionary.refresh-definition`,
            <RefreshDefinitionTab
              {...authenticationSettings.refreshDefinition}
            />
          );
        }
        break;

      case "credentials":
        addAccordionItem(
          "credential-inputs",
          `project.authentication-and-dictionary.credential-inputs`,
          <CredentialsTab {...authenticationSettings} />
        );

        if (Object.keys(inputOperations ?? {}).length > 0) {
          addAccordionItem(
            "authentication-endpoints",
            `project.authentication-and-dictionary.authentication-endpoints`,
            <EndpointsTab {...authenticationSettings} />
          );
        }
        break;

      case "oauth_credentials":
        addAccordionItem(
          "token-configuration",
          `project.authentication-and-dictionary.token-configuration`,
          <TokenTab {...authenticationSettings} />
        );
        if (Object.entries(oAuthData?.additionalData ?? {}).length > 0) {
          addAccordionItem(
            "additional-data",
            "project.authentication-and-dictionary.additional-data",
            <AdditionalDataTab {...authenticationSettings} />
          );
        }
        break;

      default:
        break;
    }

    if (injectables.length > 0) {
      addAccordionItem(
        "injectable-parameters",
        `common.injectable-parameters`,
        <InjectablesTab injectables={injectables} />
      );
    }

    if (mtlsCertificates.length > 0) {
      addAccordionItem(
        "mtls-certificates",
        `common.mtls-certificates`,
        <MTLSCertificateTab mtlsCertificates={mtlsCertificates} />
      );
    }

    return data;
  }, [authenticationSettings, injectables, mtlsCertificates]);

  return <Accordion data={accordionData} />;
};
