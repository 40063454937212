import { t } from "i18next";
import { DashboardCard, NoVulnerableData } from "../Components";
import type { OWASPTopTenCountEntry } from "@Services";
import { Table } from "reactstrap";
import { useMemo } from "react";
import type { DashboardCardProps } from "./DashboardCard.i";

const TABLE_ENTRIES_LENGTH = 5;

export const OwaspTopTenCard = ({
  owaspTopTenEntries,
  showNoData,
}: DashboardCardProps<{
  owaspTopTenEntries?: Array<OWASPTopTenCountEntry>;
}>) => {
  const cardContent = useMemo(() => {
    if (showNoData || !owaspTopTenEntries?.length) {
      return <NoVulnerableData text="common.no-issues" />;
    }

    const tableRows = owaspTopTenEntries
      .sort((a, b) => b.count - a.count)
      .slice(0, TABLE_ENTRIES_LENGTH)
      .map(({ count, name }, i) => (
        <tr key={`${i}-${name}`}>
          <td>{name}</td>
          <td>{count}</td>
        </tr>
      ));

    return (
      <Table>
        <thead>
          <tr>
            <th>OWASP</th>
            <th>{t("common.count")}</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
    );
  }, [owaspTopTenEntries, showNoData]);

  return (
    <DashboardCard className="titled owasp-top-ten">
      <span className="title">
        {t("common.most-common-OWASP-top-ten-count")}
      </span>
      {cardContent}
    </DashboardCard>
  );
};
