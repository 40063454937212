import React from "react";
import type { Props } from "./PaginatorButtons.i";

export const PaginatorButton = React.memo(
  ({ active, disabled, label, onClick, className }: Props) => {
    const classNames = ["btn", active ? "btn-active" : "", className];
    return (
      <button
        type="button"
        className={classNames.filter(Boolean).join(" ")}
        onClick={active ? undefined : () => onClick()}
        disabled={disabled}
        children={label}
      />
    );
  }
);
