import { NewProjectContext, NewProjectExtrasContext } from "@Contexts";
import { usePool } from "@Hooks";
import { equals, type PathExclusionEntry } from "@Services";
import { useCallback, useContext } from "react";

export const usePathExclusion = () => {
  const { project, setProject } = useContext(NewProjectContext);
  const {
    projectExtras: { oasFile },
  } = useContext(NewProjectExtrasContext);

  const { pool, consume, restore } = usePool(oasFile?.paths ?? []);

  const handleAddEntry = useCallback(
    (entry: PathExclusionEntry) => {
      const newPathExclusions = [...project.pathExclusion];
      newPathExclusions.push(entry);
      consume(entry.path);
      setProject({ ...project, pathExclusion: newPathExclusions });
    },
    [consume, project, setProject]
  );

  const handleRemoveEntry = useCallback(
    async (entry: PathExclusionEntry) => {
      const indexOf = project.pathExclusion.findIndex(e => equals(e, entry));
      if (indexOf < 0) return false;
      const newPathExclusions = [...project.pathExclusion];
      newPathExclusions.splice(indexOf, 1);
      if (!newPathExclusions.find(e => e.path === entry.path)) {
        restore(entry.path);
      }
      setProject({ ...project, pathExclusion: newPathExclusions });
      return true;
    },
    [project, restore, setProject]
  );

  return {
    pathExclusion: project.pathExclusion,
    addEntry: handleAddEntry,
    removeEntry: handleRemoveEntry,
    paths: pool,
  };
};
