import { MenuHorizontal, MobileVersion, Nav } from "@Components";
import {
  HOME_PATH,
  KA_NAVIGATE_TO_HOME,
  KA_NAVIGATE_TO_ORGANIZATION,
  KA_NAVIGATE_TO_PROFILE,
  KA_NAVIGATE_TO_PROJECTS,
  ORGANIZATION_PATH_GENERAL,
  PROFILE_PATH,
  PROJECTS_PATH,
  profileLastVisitedPageRegex,
} from "@Constants";
import { PageProvider } from "@Contexts";
import { useKeyboardEvent, useOrganizationId, useResponsive } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import type { Props } from "./MainTemplate.i";

const RouterKeyboardShortcuts = () => {
  const navigate = useNavigate();
  const organizationId = useOrganizationId();

  useKeyboardEvent({
    action: KA_NAVIGATE_TO_ORGANIZATION,
    onKeyDown: () =>
      navigate(generateUrl(ORGANIZATION_PATH_GENERAL, [organizationId ?? ""])),
  });

  useKeyboardEvent({
    action: KA_NAVIGATE_TO_PROJECTS,
    onKeyDown: () =>
      navigate(generateUrl(PROJECTS_PATH, [organizationId ?? ""])),
  });

  useKeyboardEvent({
    action: KA_NAVIGATE_TO_HOME,
    onKeyDown: () => navigate(generateUrl(HOME_PATH, [organizationId ?? ""])),
  });

  useKeyboardEvent({
    action: KA_NAVIGATE_TO_PROFILE,
    onKeyDown: () =>
      navigate(
        lastVisitedPageByRegexp(profileLastVisitedPageRegex) ?? PROFILE_PATH
      ),
  });

  return null;
};

export const MainTemplate = React.memo(({ children }: Props) => {
  const mobileSize = useResponsive("sm");

  const shortcuts = useMemo(() => <RouterKeyboardShortcuts />, []);

  return mobileSize ? (
    <MobileVersion />
  ) : (
    <PageProvider>
      <>
        <Nav />
        <div id="content-wrapper" className="d-flex flex-column flex-grow-1">
          <MenuHorizontal />
          <Container id="content" fluid>
            {children}
          </Container>
        </div>
        {shortcuts}
      </>
    </PageProvider>
  );
});
