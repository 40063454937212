import type { OrganizationId } from "@Interfaces";
import { ProjectOrganization, projects } from "src/Mock/data";

const findProjectsByOrganizationId = (organizationId: OrganizationId) => {
  const projectIds = ProjectOrganization.filter(
    x => x.organizationId === organizationId
  ).map(x => x.projectId);
  return projects.filter(p => projectIds.includes(p.id));
};

export default findProjectsByOrganizationId;
