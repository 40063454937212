import { getBoundedNeighbor } from ".";

type Args = {
  length: number;
  page: number;
  totalPages: number;
};

export const evalButtonsList = ({ length, page, totalPages }: Args) => {
  const output = [] as number[];
  if (totalPages <= length) {
    // total pages are less then displayable buttons
    output.push(...Array.from(Array(totalPages), (x, k) => k + 1));
  } else {
    const portion = length / 2;
    const needLeftSpread = page > Math.ceil(portion);
    const needRightSpread = page + Math.floor(portion) <= totalPages - 1;
    length -= Number(needLeftSpread) + Number(needRightSpread);

    const fillersCount = length - 2;
    const fillers = getBoundedNeighbor({
      length: fillersCount,
      lowerBound: 1,
      upperBound: totalPages,
      start: page,
    });
    output.push(1);
    needLeftSpread && output.push(0);
    output.push(...fillers);
    needRightSpread && output.push(0);
    output.push(totalPages);
  }

  return output;
};
