import { usePreference } from "@Hooks";
import type { Dispatch, PropsWithChildren } from "react";
import { createContext, useCallback, useEffect, useMemo } from "react";

export type Theme = "system" | "light" | "dark";

type SelectableTheme = "dark" | "light";
type UpdateTheme = Dispatch<Theme>;

type ThemeContextType = {
  theme: SelectableTheme;
  setTheme: UpdateTheme;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: "dark",
  setTheme: () => true,
});

const DEFAULT_THEME: SelectableTheme = "dark";
const validThemes: Array<Theme> = ["system", "light", "dark"];

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [cacheTheme, setCacheTheme] = usePreference("theme", "system");

  const theme: SelectableTheme = useMemo(() => {
    switch (cacheTheme) {
      case "light":
        return "light";
      case "dark":
        return "dark";
      case "system":
        if (!("matchMedia" in window)) return DEFAULT_THEME;
        if (window.matchMedia("(prefers-color-scheme: dark)").matches)
          return "dark";
        if (window.matchMedia("(prefers-color-scheme: light)").matches)
          return "light";
        return DEFAULT_THEME;
      default:
        return DEFAULT_THEME;
    }
  }, [cacheTheme]);

  const handleSetTheme = useCallback(
    (newTheme: Theme) => {
      const toBeApplied = validThemes.find(t => t === newTheme) || "system";
      setCacheTheme(toBeApplied);
    },
    [setCacheTheme]
  );

  useEffect(() => {
    if (theme === "light") {
      document.body.classList.remove("darkUI");
      document.body.classList.add("lightUI");
    } else {
      document.body.classList.add("darkUI");
      document.body.classList.remove("lightUI");
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: handleSetTheme,
      }}
      children={
        <div
          id="theme-wrapper"
          className={`${theme}UI vw-100 vh-100 d-flex flex-row`}
          children={children}
        />
      }
    />
  );
};
