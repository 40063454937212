import type { PlainObject, Predicate } from "@Interfaces";
import type { SeverityCount } from "@Services";
import { getStartOf, type ListedIssue } from "@Services";
import moment from "moment-timezone";
import { getFiltersFromUrlParams } from "src/Mock";

type Issues = ListedIssue[];

export const filterAndSortIssueByParams = (
  collection: Issues,
  params: URLSearchParams
): Issues => {
  let result = applyFilters(collection, params);
  result = applySorts(result, params);
  return result;
};

function applyFilters(collection: Issues, params: URLSearchParams) {
  const predicates: Predicate<ListedIssue>[] = [];
  const { criteria, values } = getFiltersFromUrlParams(params);
  criteria.forEach((c, i) => {
    const v = values[i];
    if (!v) return;
    else if (c === "issue_name") {
      predicates.push(i => i.name.toLowerCase().includes(v));
    } else if (c === "issue_severity") {
      predicates.push(i => i.severity.toLowerCase() === v);
    } else if (c === "issue_verb") {
      predicates.push(i => i.verb.toLowerCase() === v);
    } else if (c === "issue_path") {
      predicates.push(i => i.endpoint.toLowerCase().includes(v));
    } else if (c === "issue_category") {
      predicates.push(i => i.category.toLowerCase().includes(v));
    } else if (c === "issue_owasp_top_ten") {
      // ?
    } else if (c === "period") {
      predicates.push(i => {
        return moment(i.createdAt).isAfter(getStartOf(v), "date");
      });
    }
  });

  return collection.filter(issue => {
    return predicates.every(p => p(issue));
  });
}

function applySorts(collection: Issues, params: URLSearchParams) {
  const result = collection.slice();
  const { sortBy = "issue_path", sortMode = "asc" } =
    getFiltersFromUrlParams(params);

  const severityMapper: SeverityCount = {
    high: 0,
    medium: 1,
    low: 2,
    information: 3,
  };

  result.sort((a, b) => {
    if (sortBy === "issue_created_at") {
      const mult = sortMode === "asc" ? 1 : -1;
      return mult * moment(a.createdAt).diff(b.createdAt);
    } else if (sortBy === "issue_path") {
      const mult = sortMode === "desc" ? 1 : -1;
      return mult * a.endpoint.localeCompare(b.endpoint);
    } else if (sortBy === "issue_severity") {
      const mult = sortMode === "desc" ? 1 : -1;
      const mapper = severityMapper as unknown as PlainObject<number>;
      return mult * (mapper[a.severity] - mapper[b.severity]);
    }
    return 0;
  });

  return result;
}
