import { useLocation, useNavigate } from "react-router-dom";
import { getConfig, getHeaders } from "./Functions";
import { useMemo } from "react";
import type { Props } from "./Table.i";
import { getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { useUpdateCacheFilters } from "@Hooks";

export const useTableConfig = ({ projects, ...rest }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const updateFunction = useUpdateCacheFilters("organization.projects");

  const defaultFilters = useMemo(() => getDefaultFilters("projects"), []);
  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      items: getHeaders(),
      search,
      updateFunction,
      defaults: defaultFilters,
    });
  }, [defaultFilters, search, updateFunction]);

  const config = useMemo(
    () => getConfig(projects ?? [], pathname, navigate),
    [projects, pathname, navigate]
  );

  return {
    config,
    headers,
    defaultSorts: defaultFilters,
    setSorts: updateFunction,
    ...rest,
  };
};
