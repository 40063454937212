import {
  NewProjectContext,
  NewProjectExtrasContext,
  PageContext,
} from "@Contexts";
import { useValidDomain } from "@Hooks";
import { getAvailableEndpointFromLicense, isValid } from "@Services";
import { useContext, useMemo } from "react";
import { Pages } from "../../constants";

export const useContinueButtonDisabled = (): {
  disabled: boolean;
  error: string;
} => {
  const { project } = useContext(NewProjectContext);
  const { projectExtras } = useContext(NewProjectExtrasContext);
  const { page } = useContext(PageContext);
  const { isDomainEnabled } = useValidDomain();

  return useMemo(() => {
    let error = "";
    const availableEndpoints = getAvailableEndpointFromLicense(
      projectExtras.license
    );

    switch (page) {
      case Pages.NameAndLicense:
        const isValidName = project.name.trim().length > 0;
        const isValidDescription = project.description.trim().length > 0;

        if (!isValidName) {
          error = "new-project.validations.invalid-name";
        } else if (!isValidDescription) {
          error = "new-project.validations.invalid-description";
        } else if (availableEndpoints < 1) {
          error = "new-project.validations.invalid-available-endpoints";
        }

        break;

      case Pages.ApiDefinition:
        if (!projectExtras.oasFile) {
          error = "new-project.validations.no-oas-uploaded";
          break;
        }
        const pathCount =
          projectExtras.oasFile?.endpoints ?? Number.MAX_SAFE_INTEGER;
        const isValidPathCount = pathCount <= availableEndpoints;
        const isValidBaseUrl = isValid("url", project.baseURL);
        const hasValidUploadData = project.oasUploadData.length > 0;
        const isValidDomain =
          isValidBaseUrl && isDomainEnabled(project.baseURL);

        if (!isValidPathCount) {
          error = "new-project.validations.invalid-path-count-exceeded";
        } else if (!isValidBaseUrl) {
          error = "new-project.validations.invalid-base-url";
        } else if (!hasValidUploadData) {
          error = "new-project.validations.invalid-upload-data";
        } else if (!isValidDomain) {
          error = "new-project.validations.invalid-domain";
        }
        break;

      case Pages.Extensions:
        const isValidExtensionSettings =
          project.extensionSettings.enabledCategories.length > 0;

        if (!isValidExtensionSettings) {
          error = "new-project.validations.invalid-ext-settings";
        }

        break;

      case Pages.Schedule:
        const runPolicyValid = projectExtras.runPolicyValid;

        if (!runPolicyValid) {
          error = "new-project.validations.invalid-run-policy";
        }

        break;

      case Pages.Settings:
      default:
        break;
    }

    return { disabled: !!error, error };
  }, [page, project, projectExtras, isDomainEnabled]);
};
