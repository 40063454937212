import type { Dispatch, FC } from "react";
import { memo } from "react";
import { useSpaceLinkListing } from "./Hooks";
import { SpaceLinkListingContent } from "./Components";
import { equals } from "@Services";

type SpaceLinkReadonlyTableProps = {
  projectSpaceLinkID: string;
  selectedSpaceLinkID: string;
  handleSpaceLinkSelect: Dispatch<string>;
};
export const SpaceLinkReadonlyTable: FC<SpaceLinkReadonlyTableProps> = memo(
  ({ projectSpaceLinkID, selectedSpaceLinkID, handleSpaceLinkSelect }) => {
    const { fetch, loading, spaceLinks, totalItems, updateSearchFilter } =
      useSpaceLinkListing();
    return (
      <div id="organization-space-links">
        <SpaceLinkListingContent
          fetch={fetch}
          loading={loading}
          spaceLinks={spaceLinks}
          totalItems={totalItems}
          projectSpaceLinkID={projectSpaceLinkID}
          selectedSpaceLinkID={selectedSpaceLinkID}
          updateSearchFilter={updateSearchFilter}
          handleSpaceLinkSelect={handleSpaceLinkSelect}
        />
      </div>
    );
  },
  equals
);
