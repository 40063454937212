import type { InventoryDetail } from "@Interfaces";
import type { Parameter } from "@Services";

export const getTabs = (details: InventoryDetail) => {
  const removingDuplicates = (p: Parameter[]): Parameter[] =>
    Array.from(new Set(p.map(param => JSON.stringify(param)))).map(str =>
      JSON.parse(str)
    );

  // removing duplicates
  details.parameters = removingDuplicates(details.parameters || []);
  details.headers = removingDuplicates(details.headers || []);

  // Concatenate headers and parameters after duplicates are removed
  const tabs = (details.headers || []).concat(details.parameters || []);

  const groupedByIn = tabs.reduce(
    (result, parameter) => {
      const inValue = parameter.in;

      // Check if there's an existing group with the same "in" value
      const existingGroup = result.find(
        group => group.label.replace("common.", "") === inValue
      );

      const parameterWithoutIn = { ...parameter, in: undefined };

      if (existingGroup) {
        // Add the parameter to the existing group's items array
        existingGroup.items.push(parameterWithoutIn);
      } else {
        // Create a new group if it doesn't exist and add the parameter to its items array
        result.push({
          label: `common.${inValue}`,
          items: [parameterWithoutIn],
          counter: 0,
        });
      }

      return result;
    },
    [] as {
      label: string;
      items: Omit<Parameter, "in">[];
      counter: number;
    }[]
  );

  groupedByIn.forEach(group => {
    group.counter = group.items.length;
  });

  return groupedByIn;
};

export const decodeIntentoryDetailItem = (detail: InventoryDetail) => {
  const { headers, parameters } = detail;

  const parameterMapper = (parameter: Parameter) => {
    const result = { ...parameter };
    if (result.pathName) {
      result.pathName = decodeURIComponent(result.pathName);
    }
    return result;
  };

  return {
    ...detail,
    headers: headers.map(parameterMapper),
    parameters: parameters.map(parameterMapper),
  };
};
