import {
  getMinutesUntilExpiration,
  getTokenFromCache,
  hasAxiosAuth,
  isValid,
  refreshToken,
  setAxiosAuth,
} from "@Services";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useValidateToken = () => {
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [tokenSetted, setTokenSetted] = useState(hasAxiosAuth);

  const navigate = useNavigate();
  const token = getTokenFromCache();

  const validToken = useMemo(() => {
    const tokenFound = !!token;
    const tokenValid = tokenFound && isValid("jwt", token);
    const tokenExpired = tokenValid && getMinutesUntilExpiration(token) <= 0;
    return tokenFound && tokenValid && !tokenExpired;
  }, [token]);

  useEffect(() => {
    if (!validToken) {
      navigate("/login");
    } else if (token && !tokenSetted) {
      // ensure token is stored inside axios object
      setAxiosAuth(token);
      setTokenSetted(true);
    }
  }, [navigate, token, tokenSetted, validToken]);

  useEffect(() => {
    // refresh token during navigation
    // each {sleepTime} minutes, if the token is valid and not expired invoke the 'refresh-token' API procedure
    const token = getTokenFromCache();
    const remaining = getMinutesUntilExpiration(token);

    if (!token || remaining <= 0) {
      return;
    }

    const sleepTime = remaining - 5;
    setTimeout(
      () => {
        refreshToken(() => {
          navigate("/login?session-expired");
        }).then(() => {
          // re-trigger the timeout with the new token
          setRefreshCounter(i => i + 1);
        });
      },
      Math.max(0, sleepTime * 60000)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  return validToken && tokenSetted;
};
