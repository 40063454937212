import { Section } from "@Components";
import {
  KA_INVENTORY_ITEM_DETAILS_ACTIONS,
  KA_NAVIGATIONS,
  KA_NAV_PROFILE_ACTIONS,
  KA_PROFILE_ACTIONS,
  type KeyAction,
  CLIENT_OS,
  KA_SCAN_DETAILS_HTTP_HISTORY_ACTIONS,
  KA_NEW_PROJECT_ACTIONS,
  KA_INVENTORY_GRAPH_ACTIONS,
  KA_SCAN_DETAILS_ISSUE_DETAILS_ACTIONS,
  KA_PROJECT_ACTIONS,
} from "@Constants";
import { BEM } from "@Utils";
import { t } from "i18next";
import { memo } from "react";
import isEqual from "react-fast-compare";
import { Container } from "reactstrap";
import "./style.scss";

const ProfileShortcutsPageShortcutTable = memo(
  ({ shortcuts }: { shortcuts: Array<KeyAction> }) => {
    return (
      <>
        <div className={BEM("shortcut-table", "head")}>
          <b>Command</b>
          <b>Shortcut</b>
        </div>
        <div className={BEM("shortcut-table", "body")}>
          {shortcuts.map(action => (
            <div
              className={BEM("shortcut-table", "body__row")}
              key={action.getKeyEventString()}
            >
              <span>{t(action.getDescription())}</span>
              <div className={BEM("shortcut-table", "body__row__shortcut")}>
                {action.getCtrl() && (
                  <kbd className="me-2">
                    {CLIENT_OS === "MAC" ? "CMD" : "CTRL"}
                  </kbd>
                )}
                {action.getAlt() && (
                  <kbd className="me-2">
                    {CLIENT_OS === "MAC" ? "OPT" : "ALT"}
                  </kbd>
                )}
                {action.getShift() && <kbd className="me-2">SHIFT</kbd>}
                <kbd>{action.getKeyCode()?.toLocaleUpperCase() ?? "???"}</kbd>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  },
  isEqual
);

export const ProfileShortcutsPage = memo(() => {
  return (
    <Container fluid id="profile-shortcuts-page">
      <Section title="profile.shortcuts-page.shortcuts">
        <h6 className="color-primary">
          {t("profile.shortcuts-page.nav-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable shortcuts={KA_NAVIGATIONS} />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.nav-profile-menu-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable shortcuts={KA_NAV_PROFILE_ACTIONS} />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.profile-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable shortcuts={KA_PROFILE_ACTIONS} />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.scan-details.http-history-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable
          shortcuts={KA_SCAN_DETAILS_HTTP_HISTORY_ACTIONS}
        />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.new-project-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable shortcuts={KA_NEW_PROJECT_ACTIONS} />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.inventory-graph-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable
          shortcuts={KA_INVENTORY_GRAPH_ACTIONS}
        />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.inventory-item-details-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable
          shortcuts={KA_INVENTORY_ITEM_DETAILS_ACTIONS}
        />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.scan-details.issue-details-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable
          shortcuts={KA_SCAN_DETAILS_ISSUE_DETAILS_ACTIONS}
        />
        <hr></hr>
        <h6 className="color-primary">
          {t("profile.shortcuts-page.project-shortcuts")}
        </h6>
        <ProfileShortcutsPageShortcutTable shortcuts={KA_PROJECT_ACTIONS} />
      </Section>
    </Container>
  );
}, isEqual);
