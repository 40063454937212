import React from "react";
import { Offcanvas, OtpInput, SkeletonImg } from "@Components";
import type { Props } from "./SetupOtp.i";
import { useSetup } from "./useSetup";
import { t } from "i18next";

export const SetupOtp = React.memo(
  ({ showOffcanvas, setShowOffcanvas }: Props) => {
    const { loading, qrCodeImg, setOtp, buttons, onCanvasClose } = useSetup(
      showOffcanvas,
      setShowOffcanvas
    );

    return (
      <Offcanvas
        title="profile.offcanvas.title"
        isOpen={showOffcanvas}
        toggle={onCanvasClose}
        buttons={buttons}
      >
        <div>
          {t("profile.offcanvas.use-phone-scan")}{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            {t("profile.offcanvas.google-authenticator")}
          </a>
          {", "}
          <a
            href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en"
            target="_blank"
            rel="noreferrer"
          >
            {t("profile.offcanvas.microsoft-authenticator")}
          </a>
          {", "}
          <a
            href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            {t("profile.offcanvas.authy")}
          </a>
          {", "}
          {t("profile.offcanvas.or-enter-code")}
        </div>
        <div className="qr-container pt-3">
          {(loading && <SkeletonImg width="235" height="235" />) || (
            <>
              {(qrCodeImg && (
                <img src={qrCodeImg} className="w-100" alt="QR code" />
              )) ||
                ""}
            </>
          )}
        </div>
        <div className="mt-3">{t("profile.offcanvas.after-scanning")}</div>
        <div className="mt-2">
          <p className="form-label">
            {t("profile.offcanvas.authentication-code")}
          </p>
          <div>
            <OtpInput setOtp={setOtp} />
          </div>
        </div>
      </Offcanvas>
    );
  }
);
