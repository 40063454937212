/* eslint-disable no-loop-func */
import { AuthContext } from "@Contexts";
import { useUrlParams } from "@Hooks";
import type { OrganizationUser } from "@Interfaces";
import { dateTimeFormat } from "@Services";
import { t } from "i18next";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import { onChangeUser } from "./functions";

export const useTableConfig = (
  users: OrganizationUser[],
  refreshUsers: () => Promise<void>
) => {
  const { user: currentUser } = useContext(AuthContext);
  const { organizationId } = useUrlParams();
  const navigate = useNavigate();
  const location = useLocation();
  const config = [];

  for (const index in users) {
    const user = users[index];
    const isCurrentUser = user.id === currentUser?.id;

    config.push({
      props: {
        className: `active organization-table-row ${
          Number(index) % 2 ? "even" : "odd"
        } role-${user.role}`,
        onClick: (e: React.MouseEvent<HTMLTableRowElement>) => {
          e.stopPropagation();
          navigate(`${location.pathname}/${user.id}`);
        },
      },
      items: [
        {
          jsx: <div>{`${user.name || ""} ${user.surname || ""}`.trim()}</div>,
        },
        {
          jsx: <div>{user.email}</div>,
        },
        {
          jsx: <div>{t(`roles.${user.role}`)}</div>,
        },
        {
          jsx: (
            <div>
              {!isCurrentUser && (
                <div className="form-switch">
                  <Input
                    type="switch"
                    onClick={e => e.stopPropagation()}
                    onChange={e =>
                      onChangeUser(
                        e,
                        organizationId,
                        user.id,
                        user.role,
                        user.roleActive,
                        refreshUsers
                      )
                    }
                    checked={user.roleActive}
                  />
                  <span className="ms-2">
                    {t(`common.${user.roleActive ? "enabled" : "disabled"}`)}
                  </span>
                </div>
              )}
              {isCurrentUser && <span>{t(`common.enabled`)}</span>}
            </div>
          ),
        },
        {
          jsx: <div>{dateTimeFormat(user.createdAt, { trimTime: true })}</div>,
        },
        {
          jsx: (
            <div>
              {dateTimeFormat(user.expiresAt, {
                trimTime: true,
                checkMaxEndDate: true,
              })}
            </div>
          ),
        },
      ],
    });
  }
  return config;
};
