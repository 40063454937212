import { useUrlParams } from "@Hooks";
import type { AuthenticationProfile } from "@Services";
import { API } from "@Services";
import { createCache } from "@Utils";
import { useEffect } from "react";
import { useHistoryStore } from "../Store/HistoryStore";

const authenticationProfilesCache = createCache<Array<AuthenticationProfile>>();

const getAuthenticationProfilesAPI = async (
  organizationId: string | undefined,
  projectId: string | undefined,
  scanId: string | undefined,
  onSuccess: (authenticationProfiles: Array<AuthenticationProfile>) => unknown
) => {
  if (!organizationId || !projectId || !scanId) {
    onSuccess([]);
    return;
  }

  const cachedElementId = `${projectId}-${scanId}`;
  const cachedData = authenticationProfilesCache.getData(cachedElementId);

  if (cachedData) {
    onSuccess(cachedData);
    return;
  }

  const result = await API.scan().scanListAuthenticationProfiles(
    organizationId,
    projectId,
    scanId,
    undefined,
    undefined,
    "authentication_profile_name",
    "asc"
  );

  if (!result || !result.data) {
    onSuccess([]);
    return;
  }

  const { data } = result;

  const { data: authenticationProfiles } = data;

  authenticationProfilesCache.push(cachedElementId, authenticationProfiles);
  onSuccess(authenticationProfiles);
};

export const useGetAuthenticationProfiles = () => {
  const { authenticationProfiles, setAuthenticationProfiles } =
    useHistoryStore();
  const { organizationId, projectId, scanId } = useUrlParams();

  useEffect(() => {
    getAuthenticationProfilesAPI(
      organizationId,
      projectId,
      scanId,
      setAuthenticationProfiles
    );
  }, [organizationId, projectId, scanId, setAuthenticationProfiles]);

  return {
    authenticationProfiles,
  };
};
