import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";
import { getAuthUserContext } from "src/Mock/utils";

export const userGet = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const context = getAuthUserContext();
  const user = context.getUser();
  const status = user ? 200 : 404;

  return res(ctx.delay(delay), ctx.json({ user }), ctx.status(status));
};
