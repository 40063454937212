import { DisplaySection, Section } from "@Components";
import { useOrganization } from "@Hooks";
import { BEM } from "@Utils";

export const OrganizationInfo = () => {
  const organization = useOrganization();

  return (
    <Section
      title="organization.main"
      className="organization-info"
      id="organization-info"
    >
      <div className={BEM("organization-info", "details")}>
        <DisplaySection
          label={"common.email"}
          value={organization?.email || "-"}
        />
        <DisplaySection
          label={"common.vat"}
          value={organization?.fiscalCode || "-"}
        />
        <DisplaySection
          label={"common.organization-id"}
          value={organization?.id || "-"}
        />
      </div>
    </Section>
  );
};
