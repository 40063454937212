import type { OffCanvasProps } from "@Components";
import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { Options } from "@Interfaces";
import type { UserReportScanResponse } from "@Services";
import { API, ReportFormat } from "@Services";
import { useCallback, useMemo, useState } from "react";
import type { Props } from "./DownloadCanvas.i";
import { downloadReport } from "./Functions";

const INITIAL_REPORT_FORMAT: ReportFormat = "pdf";

export const useDownloadScanReportCanvas = (props: Props) => {
  const [scanReport, setScanReport] = useState<UserReportScanResponse>();
  const [reportFormat, setReportFormat] = useState<ReportFormat>(
    INITIAL_REPORT_FORMAT
  );
  const { organizationId, projectId, scanId } = useUrlParams();
  const [loading, setLoading] = useState(false);
  const manageResponse = useManageApiResponse(setLoading);

  const canvasProps: OffCanvasProps = {
    children: "",
    isOpen: props.isOpen,
    title: "scans.download-report-canvas",
    id: "download-scan-report-canvas",
    toggle: () => {
      if (loading) return;
      setReportFormat(INITIAL_REPORT_FORMAT);
      setLoading(false);
      setScanReport(undefined);
      props.close();
    },
    buttons: [],
    exitLabel: "common.close",
  };

  const formatOptions: Options<ReportFormat> = useMemo(() => {
    return Object.values(ReportFormat).map(format => ({
      label: format.toUpperCase(),
      value: format,
    }));
  }, []);

  const getScanReport = useCallback(() => {
    if (!organizationId || !projectId || !scanId) {
      return undefined;
    }
    setScanReport(undefined);
    manageResponse<UserReportScanResponse>({
      promise: API.scan().scanGetReport(organizationId, projectId, scanId, {
        format: props.format,
      }),
      errorMessage: "get-report",
      onSuccess: axiosResponse => {
        const response = axiosResponse.data;
        if (response) {
          setScanReport(response);
          downloadReport({ scanId, data: response.data });
        }
      },
    });
  }, [manageResponse, organizationId, projectId, props.format, scanId]);

  return {
    canvasProps,
    formatOptions,
    reportFormat,
    setReportFormat,
    getScanReport,
    loading,
    scanReport,
  };
};

export default useDownloadScanReportCanvas;
