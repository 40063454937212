import type { APIDefinition } from "@Interfaces";
import type { UserListAPIDefinitionsResponse } from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { APIDefinitions, delay, getFiltersFromUrlParams } from "src/Mock";

export const getApiDefinitions = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { params } = req;
  const { searchParams } = req.url;
  const projectId = `${params.projectId}`;

  const queryData = getFiltersFromUrlParams(searchParams);
  const {
    size,
    page,
    sortBy = "api_definition_created_at",
    sortMode = "desc",
  } = queryData;

  const offset = (page - 1) * size;
  const limit = offset + size;

  let data: APIDefinition[] = [];

  if (projectId === "2") {
    data.push(...APIDefinitions.slice(4));
  } else {
    data.push(...APIDefinitions);
  }

  data = data.sort((a, b) => {
    if (sortBy === "api_definition_created_at") {
      const mult = sortMode === "asc" ? 1 : -1;
      return mult * moment(a.createdAt).diff(b.createdAt);
    }
    return 0;
  });

  const response: UserListAPIDefinitionsResponse = {
    data: data.slice(offset, limit),
    page,
    size,
    totalItems: data.length,
    totalPages: Math.ceil(data.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
