import { Input, InputGroup, InputGroupText } from "reactstrap";
import { useSortInput } from "./useSortInput";
import type { Props } from "./SortInput.i";
import { memo } from "react";

import "./style.scss";

export const SortInput = memo((props: Props) => {
  const { length, iconProps, inputProps, className } = useSortInput(props);
  return length > 0 ? (
    <InputGroup
      id="card-sort-input"
      className={`w-auto ${className}`.trimEnd()}
    >
      {inputProps.type === "text" ? (
        <span className="sort-display form-control">{inputProps.children}</span>
      ) : (
        <Input {...inputProps} className="sort-display" />
      )}
      <InputGroupText onClick={iconProps.onClick}>
        <i className={`bi bi-${iconProps.asc}`} />
        &nbsp;
        <i className={`bi bi-${iconProps.desc}`} />
      </InputGroupText>
    </InputGroup>
  ) : (
    <></>
  );
});
