import { useMemo } from "react";
import { StaticTokenForm } from "./StaticTokenForm/StaticTokenForm";
import { CredentialsForm } from "./CredentialsForm/CredentialsForm";
import type { AuthenticationFlow } from "@Interfaces";
import { FormGroup } from "reactstrap";
import { useAuthenticationSettingFormStore } from "@Stores";
import { OAuthCredentialsForm } from "./OAuthCredentialsForm/OAuthCredentialsForm";

export const FlowForm = () => {
  const {
    authenticationSettings: { authenticationFlow },
  } = useAuthenticationSettingFormStore();

  const flowForm = useMemo(() => {
    switch (authenticationFlow as AuthenticationFlow) {
      case "static_token":
        return <StaticTokenForm />;
      case "credentials":
        return <CredentialsForm />;
      case "oauth_credentials":
        return <OAuthCredentialsForm />;
      default:
        throw new Error(`invalid-authentication-flow-${authenticationFlow}`);
    }
  }, [authenticationFlow]);

  return <FormGroup>{flowForm}</FormGroup>;
};
