import { InfoBadge, MethodBadge, NotificationBadge } from "@Components";
import { PROJECT_DETAILS_SCAN_INVENTORY_URL } from "@Constants";
import { useUrlParams } from "@Hooks";
import type { Inventory, Method as MethodI } from "@Interfaces";
import { generateUrl } from "@Utils";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { getIcons } from "src/Pages/Inventory/functions";
import { useScanDetailsStore } from "../../../Store/ScanDetailsStore";
import { getTooltips } from "../functions";

export const useTableConfig = (items: Inventory[], baseURL: string) => {
  const { details } = useScanDetailsStore();
  const config = [];
  const navigate = useNavigate();
  const { organizationId, projectId } = useUrlParams();
  const scanId = details?.scan.id ?? "";
  for (const index in items) {
    const data = items[index];

    const { tagsTooltipProps } = getTooltips(data);
    const maxElements = 5;

    config.push({
      props: {
        className: "inventory-item-list-row",
        onClick: () =>
          navigate(
            generateUrl(PROJECT_DETAILS_SCAN_INVENTORY_URL, [
              organizationId ?? "",
              projectId ?? "",
              scanId ?? "",
              data.id,
            ])
          ),
      },
      items: [
        {
          jsx: (
            <div className="d-flex align-items-center">
              <MethodBadge className="me-2" type={data.verb as MethodI} />
              <span title={`${baseURL}${data.path}`}>{data.path}</span>
              {data.createdFrom === "FROM_EXTENSION" && (
                <NotificationBadge display="sup" type="shadow" />
              )}
            </div>
          ),
        },
        {
          jsx: <div>{data.category}</div>,
        },
        {
          jsx: (
            <div className="d-flex" {...tagsTooltipProps}>
              {data.tags.map((t, index) =>
                index < 2 ? (
                  <InfoBadge className="me-2" key={t + index}>
                    {t}
                  </InfoBadge>
                ) : null
              )}
              {data.tags.length > 2 ? <span>...</span> : ""}
            </div>
          ),
        },
        {
          jsx: (
            <div className="d-flex">
              {getIcons(data.dataFormatInputs)
                .filter((_, i) => i < maxElements)
                .map((i, index) => (
                  <i
                    key={i.label + index}
                    data-tooltip-id="tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      <>
                        <span className="color-pink">Category: </span> {i.label}
                      </>
                    )}
                    className={i.icon + ` ms-2`}
                  />
                ))}
              {data.dataFormatInputs.length >= maxElements ? (
                <span className="ms-2">...</span>
              ) : (
                ""
              )}
            </div>
          ),
          hide: !data.dataFormatInputs.length,
        },
        {
          jsx: (
            <div className="d-flex">
              {getIcons(data.dataFormatOutputs)
                .filter((_, i) => i < maxElements)
                .map((o, index) => (
                  <i
                    key={o.label + index}
                    data-tooltip-id="tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      <>
                        <span className="color-pink">Category: </span> {o.label}
                      </>
                    )}
                    className={o.icon + ` ms-2`}
                  />
                ))}
              {data.dataFormatOutputs.length >= maxElements ? (
                <span className="ms-2">...</span>
              ) : (
                ""
              )}
            </div>
          ),
          hide: !data.dataFormatOutputs.length,
        },
        {
          jsx: (
            <div>
              {data.authenticationRequired ? <i className="bi-lock" /> : ""}
            </div>
          ),
          hide: !data.authenticationRequired,
        },
      ],
    });
  }

  return config;
};
