import type { MTLSCertificate } from "../client";

const defaultData: MTLSCertificate = {
  associatedAuthSetting: "",
  clientCert: "",
  clientKey: "",
  name: "",
  caCert: "",
};

export const getEmptyMtlsCertificate = (
  partial: Partial<MTLSCertificate> = {}
): MTLSCertificate => {
  return Object.assign({}, defaultData, partial);
};
