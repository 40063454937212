import type { InventoryInput } from "@Interfaces";
import type { ParameterLinksEntry } from "@Services";
import type { Edge, Node, NodeProps } from "@xyflow/react";
import { DeleteUserParameterLinkEdge } from "../../../Edges"; // keep
import { ParameterLinkNode } from "./ParameterLinkNode";

export const PARAMETER_LINK_NODE_WIDTH = 400; // 20rem
export const PARAMETER_LINK_NODE_TITLE_HEIGHT = 48;
export const PARAMETER_LINK_NODE_ITEM_HEIGHT = 34;

export type Location =
  | "body"
  | "header"
  | "query"
  | "path"
  | "cookie"
  | "unknown";

export type ParameterType =
  | "text"
  | "password"
  | "email"
  | "number"
  | "boolean";

export type Parameter = {
  name: string;
  type: InventoryInput;
  output: boolean;
  input: boolean;
};

export type Parameters = Array<Parameter>;

// Define the type for the node data
export type ParameterLinkNodeData = {
  path: string;
  verb: string;
  name: string;
  parameters: Parameters;
  consumer?: boolean;
  selected?: boolean;
};

// Define the type for the custom node component props
export type ParameterLinkNodeProps = {
  data: ParameterLinkNodeData;
  id: string;
};

export type ParameterLinkEdgeData = {
  sourceNode: Node<ParameterLinkNodeData | NodeProps>;
  targetNode: Node<ParameterLinkNodeData | NodeProps>;
  parameterLink: ParameterLinksEntry;
  parameterLinkIndex: number;
};

export const parameterLinkNodeTypes = {
  default: ParameterLinkNode,
};

export const parameterLinkEdgeTypes = {
  deleteUserParameterLink: DeleteUserParameterLinkEdge,
};

export type ParameterLinkNodes = Array<Node<ParameterLinkNodeData | NodeProps>>;
export type ParameterLinkEdges = Array<Edge>;
