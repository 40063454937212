import {
  MethodBadge,
  withMouseCursorTooltip,
  type NodeParameterData,
  type ParameterLocation,
} from "@Components";
import type { Method } from "@Interfaces";
import { BEM, joinClasses, limitString } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import { AdditionalParameterDetails } from "./AdditionalParameterDetails";
import type {
  NewParameterLinkNodeData,
  NewParameterLinkNodeProps,
} from "./NewParameterLinkNode.i";

type AdditionalDetailsProps = {
  parameter: NodeParameterData;
};

const AdditionalDetails = ({
  parameter,
  ...otherProps
}: AdditionalDetailsProps) => {
  return (
    <div
      className={BEM("api-definition-node__property", "additional-details")}
      {...otherProps}
    >
      <div className={BEM("api-definition-node", "title")}>
        <span>{parameter.name}</span>
      </div>
      <AdditionalParameterDetails parameter={parameter.data} />
    </div>
  );
};

type NewParameterLinkNodePropertyProps = {
  parameter: NodeParameterData;
  parameterKey: string;
  data: NewParameterLinkNodeData;
  source: boolean;
  key: string;
};

const NewParameterLinkNodeProperty = ({
  parameter,
  parameterKey,
  data,
  source,
}: NewParameterLinkNodePropertyProps) => {
  return (
    <div className={BEM("api-definition-node", "property")}>
      {!source && (
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target-${parameterKey}`}
          isConnectable={!data.isConnected}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
      )}
      <span>{limitString(parameter.name, 40)}</span>
      {source && (
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source-${parameterKey}`}
          isConnectable={!data.isConnected}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
      )}
    </div>
  );
};

export const NewParameterLinkNode: React.FC<
  NewParameterLinkNodeProps & { source: boolean }
> = ({ id: nodeId, data, source }) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const properties = useMemo(() => {
    const { parameters } = data;
    if (!parameters) {
      return null;
    }

    const result = [];

    const locationDictionary = t("common.location");
    const availableLocations = Object.keys(data.parameters);

    for (const location of availableLocations) {
      const locationParameters = data.parameters[location as ParameterLocation];

      if (!locationParameters) {
        continue;
      }

      result.push(
        <div className={BEM("api-definition-node", "location")} key={location}>
          <span>{location}</span>
          <span className="color-gray font-size-caption">
            {locationDictionary}
          </span>
        </div>
      );

      for (const parameter of locationParameters) {
        const key = `${nodeId}_${parameter.name}`;
        const Property = withMouseCursorTooltip<
          NewParameterLinkNodePropertyProps,
          AdditionalDetailsProps
        >(NewParameterLinkNodeProperty, AdditionalDetails, { parameter });
        result.push(
          <Property
            parameterKey={key}
            parameter={parameter}
            data={data}
            source={source}
            key={key}
          />
        );
      }
    }

    return result;
  }, [data, nodeId, source]);

  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [data, nodeId, updateNodeInternals]);

  return (
    <div
      id={nodeId}
      className={joinClasses(
        "api-definition-node",
        source ? "source" : "target"
      )}
    >
      <div className={BEM("api-definition-node", "title")}>
        <span title={data.path}>{limitString(data.path, 30)}</span>
        {data.verb && <MethodBadge type={data.verb as Method} />}
      </div>
      {properties}
    </div>
  );
};
