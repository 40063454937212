import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getChartData } from "src/Mock";

export const getDashboardChart = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params as {
    organizationId: string;
    projectId: string | undefined;
  };
  const period = req.url.searchParams.get("filter_values");
  const dailySeverityCount = getChartData(
    organizationId,
    period as string,
    projectId
  );
  return res(ctx.delay(delay), ctx.json({ dailySeverityCount }));
};
