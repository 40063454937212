import type { FC } from "react";
import { useMemo } from "react";
import type { AccordionProps } from "@Components";
import { Accordion } from "@Components";
import { t } from "i18next";
import { CredentialInputsTab } from "./Components/CredentialInputsTab";
import { InputOperationsTab } from "./Components/InputOperationsTab";
import { useCredentialsForm } from "./useCredentialsForm";
import { joinClasses } from "@Utils";

const credentialInputHeader = t(
  "project.authentication-and-dictionary.login-credentials"
);

const inputOperationHeader = t(
  "project.authentication-and-dictionary.authentication-endpoints"
);

export const CredentialsForm = () => {
  const { credentialInputs, inputOperations } = useCredentialsForm();

  const data: AccordionProps["data"] = useMemo(() => {
    const credentialInputsCount = Object.keys(credentialInputs ?? {}).length;
    const inputOperationCount = inputOperations?.length ?? 0;

    return [
      {
        children: <CredentialInputsTab />,
        header: <CredentialHeader count={credentialInputsCount} />,
        key: "login-credentials",
      },
      {
        children: <InputOperationsTab />,
        header: `${inputOperationHeader} (${inputOperationCount})`,
        key: "authentication-endpoints",
      },
    ];
  }, [credentialInputs, inputOperations]);

  return <Accordion data={data} />;
};

const CredentialHeader: FC<{ count: number }> = ({ count }) => {
  const icon = count
    ? "bi-check text-success"
    : "bi-exclamation-circle text-danger";
  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <span>{`${credentialInputHeader} (${count})`}</span>
      <i
        className={joinClasses("ms-2 bi cursor-pointer", icon)}
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-content={t("common.required")}
      />
    </div>
  );
};
