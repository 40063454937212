import { useAuthenticationSettingFormStore } from "@Stores";
import { useEffect, useMemo } from "react";

export const useCredentialsForm = () => {
  const {
    authenticationSettings: { credentialInputs, inputOperations },
    setSubFormValidity,
  } = useAuthenticationSettingFormStore();

  const { valid } = useMemo(() => {
    const validities = {
      credentialInputs: Object.values(credentialInputs ?? {}).length > 0,
    };
    const valid = Object.values(validities).every(Boolean);
    return { valid };
  }, [credentialInputs]);

  useEffect(() => {
    setSubFormValidity("flow", valid);
  }, [setSubFormValidity, valid]);

  return { credentialInputs, inputOperations };
};
