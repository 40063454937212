import { toFont } from "chart.js/helpers";
import type { Props } from "./HorizontalLineChart.i";
import type { FontSpec } from "chart.js";

const MAX_LENGTH = 25;
const PADDING = 8;

export function manageTicks(value: string) {
  const valueLen = value.length;
  if (valueLen <= MAX_LENGTH) {
    return value;
  }
  const buildedString = value.substring(0, MAX_LENGTH) + "...";
  return buildedString;
}

export function getMax(value: number): number {
  return (Math.trunc(value / 2) + 1) * 2;
}

export function getChartHeight(data: Props["data"]): number {
  // set height depending on amount of rows
  if (!data.length) {
    return 0;
  }
  const font: Partial<FontSpec> = {
    size: 16,
    weight: "bold",
  };
  const normFont = toFont(font);
  // row height
  const rows = data.length * (normFont.lineHeight + PADDING * 2);
  const xAxis = normFont.lineHeight * 2;
  return rows + xAxis;
}
