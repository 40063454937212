import type { ListedRole, Nullable, Organization } from "@Interfaces";
import { API } from "@Services";
import { createCache } from "@Utils";
import { type Dispatch, memo, useEffect, useState } from "react";

const organizationsCache = createCache<Organization>(50);
const getOrganization = async (
  organizationId: string,
  onSuccess: Dispatch<Nullable<Organization>>
) => {
  const cachedData = organizationsCache.getData(organizationId);
  if (cachedData) {
    onSuccess(cachedData);
    return;
  }

  const result = await API.organization().userGetOrganization(organizationId);

  if (!result || !result.data) {
    onSuccess(null);
    return;
  }

  const { data } = result;

  organizationsCache.push(organizationId, data);
  onSuccess(data as Organization);
};

export const RoleOrganizationName = memo(({ role }: { role: ListedRole }) => {
  const [organization, setOrganization] =
    useState<Nullable<Organization>>(null);

  useEffect(() => {
    getOrganization(role.organizationID, setOrganization);
  }, [role.organizationID]);

  return (
    <>
      {organization ? (
        <div>
          <h6>
            <b>{organization.name}</b>
          </h6>
          <p className="m-0 text-truncate font-size-caption color-gray">
            {organization.address}
          </p>
        </div>
      ) : (
        <div
          className="spinner-border font-size-caption color-gray"
          style={{ width: "1rem", height: "1rem" }}
          role="status"
        />
      )}
    </>
  );
});
