import { useCallback, useEffect, useState } from "react";
import { useMTLSCertificatesStore } from "@Stores";
import {
  type MTLSCertificate,
  equals,
  projectDataToUpdateProjectData,
} from "@Services";
import { useProject } from "@Hooks";

export const useMTLSCertificates = () => {
  const [canvasOpen, setCanvasOpen] = useState(false);
  const { project, loading: projectLoading, update } = useProject();
  const {
    loading,
    setLoading,
    error,
    setError,
    mTLSCertificates,
    setProjectMTLSCertificates,
    resetStore,
  } = useMTLSCertificatesStore();

  useEffect(() => {
    setLoading(projectLoading === "get");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectLoading]);

  useEffect(() => {
    if (loading) return;
    setProjectMTLSCertificates(project?.mTLSCertificates ?? []);
    setError(!project);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, project]);

  const deleteMTLSCertificate = useCallback(
    async (mtlsCertificate: MTLSCertificate) => {
      if (!project?.mTLSCertificates) return false;
      const indexOf = project.mTLSCertificates.findIndex(e =>
        equals(e, mtlsCertificate)
      );
      if (indexOf < 0) return false;
      const mTLSCertificates = [...project.mTLSCertificates];
      mTLSCertificates.splice(indexOf, 1);
      const requestBody = projectDataToUpdateProjectData(project);
      requestBody.mTLSCertificates = mTLSCertificates;
      const success = await update(requestBody);
      if (success) {
        setProjectMTLSCertificates(mTLSCertificates);
      }
      return success;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project, update]
  );

  return {
    loading,
    error,
    mTLSCertificates,
    deleteMTLSCertificate,
    canvasOpen,
    setCanvasOpen,
    resetStore,
  };
};
