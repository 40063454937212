import { t } from "i18next";
import { memo } from "react";
import type { Props } from "./ErrorPlaceholder.i";

import "./style.scss";

export const ErrorPlaceholder = memo(
  ({ error = "retrieving-data", iconSize = "M" }: Props) => (
    <div className="error-placeholder h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <i className={`bi-exclamation-circle icon-${iconSize.toLowerCase()}`} />
        <div>{t(`errors.${error}`)}</div>
      </div>
    </div>
  )
);
