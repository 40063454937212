import type { UserUpdateRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";
import { getAuthUserContext } from "src/Mock/utils";

export const userPut = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const context = getAuthUserContext();
  const user = context.getUser();
  let status = 404;
  if (user) {
    const payload = (await req.json()) as UserUpdateRequest;
    const { name, surname } = payload;
    if (name && surname) {
      status = 200;
      context.setUser({ ...user, name, surname });
    } else {
      status = 400;
    }
  }
  return res(ctx.delay(delay), ctx.status(status));
};
