import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { Alert, DangerZoneProps } from "./DangerZone.i";
import type { ButtonProps } from "@Components";
import { t } from "i18next";
import { useManageApiResponse } from "@Hooks";

const tBase = "components.danger-zone.";

export const useDangerZone = ({
  action = "delete",
  target,
  confirmationText,
  subject,
  promise,
  toastErrorMessage,
  toastSuccessMessage,
  onSuccess,
  additionalAlerts,
}: DangerZoneProps) => {
  const title = `${tBase}${action}-${target}`;
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const manageResponse = useManageApiResponse(setLoading);

  const closeCanvas = useCallback(() => {
    setInputValue("");
    setCanvasOpen(false);
  }, []);

  const deleteHandler = useCallback(async () => {
    const { status } = await manageResponse({
      promise,
      errorMessage: toastErrorMessage,
      successMessage: toastSuccessMessage,
      onSuccess: () => {
        closeCanvas();
        onSuccess && onSuccess();
      },
    });

    if (status === 200) {
      closeCanvas();
      onSuccess && onSuccess();
    }
  }, [
    manageResponse,
    onSuccess,
    promise,
    closeCanvas,
    toastErrorMessage,
    toastSuccessMessage,
  ]);

  const confirmButton: ButtonProps = useMemo(() => {
    return {
      color: "danger",
      children: `common.${action}`,
      disabled,
      loading,
      onClick: deleteHandler,
      id: `confirm-${action}-btn`,
      iconClass: "bi bi-exclamation-square-fill",
    };
  }, [disabled, loading, action, deleteHandler]);

  const inputText = useMemo(() => {
    return {
      value: inputValue,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setInputValue(value);
      },
      disabled: loading,
      valid: !disabled,
      invalid: disabled,
    };
  }, [loading, disabled, inputValue]);

  useEffect(() => {
    const invalid = inputValue !== confirmationText;
    if (disabled !== invalid) {
      setDisabled(invalid);
    }
  }, [inputValue, confirmationText, disabled]);

  const alerts = useMemo(() => {
    const result: Array<Alert> = [
      {
        level: "warning",
        children: t(`${tBase}delete-warning`),
      },
    ];

    additionalAlerts?.forEach(e => {
      result.push({
        level: e.level,
        children: e.children,
      });
    });

    return result.sort((a1, a2) => {
      return a1.level.localeCompare(a2.level); // errors on top, warning on bottom
    });
  }, [additionalAlerts]);

  return {
    title: t(title),
    info: t(`${title}-info`),
    canvas: {
      isOpen: canvasOpen,
      confirmButton,
      loading,
      openCanvas: () => setCanvasOpen(true),
      closeCanvas,
      alerts,
      inputText,
      innerTitle: subject,
      innerContent: t(`${title}-warning`, { subject }),
      deleteLabel: t(`${tBase}delete-input-message`, {
        text: confirmationText,
      }),
    },
  };
};
