import type { OrganizationAdminGetUserResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, findUsersByOrganizationId, userAuditLogs } from "src/Mock";

export const getUser = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { userId, organizationId } = req.params;

  const user = findUsersByOrganizationId(`${organizationId}`).find(
    user => user.id === userId
  );

  if (!user) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const response: OrganizationAdminGetUserResponse = {
    ...user,
  };

  const logs = userAuditLogs.get(`${userId}`);

  // simplification : assuming first audit log is the most recent
  response.lastLogin = logs?.[0].createdAt || "";

  return res(ctx.delay(delay), ctx.json(response));
};
