import type { Category, IssueExclusion } from "@Services";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";

export type ProjectSettingsStoreProperties = {
  issueExclusions: Array<IssueExclusion>;
  extensionSettings: Array<Category>;
  loading: boolean;
};

export type ProjectSettingsState = ProjectSettingsStoreProperties & {
  setIssueExclusions: Dispatch<Array<IssueExclusion>>;
  setExtensionsCategories: Dispatch<Array<Category>>;
  setLoading: Dispatch<boolean>;
  reset: VoidFunction;
};

const initialState: ProjectSettingsStoreProperties = {
  issueExclusions: [],
  extensionSettings: [],
  loading: false,
};

export const useProjectSettingsStore = createStore<ProjectSettingsState>(
  (get, set) => ({
    ...initialState,
    setIssueExclusions: issueExclusions =>
      set(state => ({ ...state, issueExclusions })),
    setExtensionsCategories: extensionSettings =>
      set(state => ({
        ...state,
        extensionSettings,
      })),
    setLoading: loading =>
      set(state => ({
        ...state,
        loading: loading,
      })),
    reset: () => set(state => ({ ...state, ...initialState })),
  })
);
