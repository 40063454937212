import type { SwaggerClientResponse } from "@Interfaces";
import type { ParserOutcome } from "../../../types.i";
import { getBaseUrls } from "./getBaseUrls";
import { getParameters } from "./getParameters";
import { getTitle } from "./getTitle";
import { getTotalPaths } from "./getTotalPaths";
import { getVersion } from "./getVersion";
import { getPaths } from "./getPaths";

type Result = Omit<ParserOutcome, "oasUploadData" | "hasExternalReferences">;

export const parseOpenApi = (openapi: SwaggerClientResponse): Result => {
  const { spec } = openapi;
  const baseUrlSuggestions = getBaseUrls(spec);
  const parameters = getParameters(spec);
  const title = getTitle(spec);
  const totalPaths = getTotalPaths(spec);
  const version = getVersion(spec);
  const paths = getPaths(spec);
  return { baseUrlSuggestions, parameters, title, totalPaths, version, paths };
};
