import { useEffect, useMemo } from "react";
import type { Props } from "./Paginator.i";
import { showPaginator as showPaginatorFunction } from "./function";
import { useSearchParams } from "react-router-dom";

const DEFAULT_PAGE_SIZES = [12, 24, 48];

const usePaginatorSmart = ({
  filters,
  setFilters,
  //
  totalItems = 0,
  //
  show = "default",
  align = "end",
  showItemsPerPage = true,
  showTotalItems = true,
  fixedPageSize,
  ...otherProps
}: Props) => {
  const [searchParams] = useSearchParams();
  //
  const { page, setPage } = useMemo(() => {
    const fromProps = filters?.page;
    const fromUrl = searchParams.get("page");
    const asInteger = parseInt(`${fromProps || fromUrl}`, 10);
    const page = isNaN(asInteger) ? 1 : asInteger;
    return {
      page,
      setPage: (page: number) => {
        setFilters(f => ({ ...f, page: `${page}` }));
      },
    };
  }, [filters?.page, searchParams, setFilters]);

  const { pageSize, setPageSize } = useMemo(() => {
    if (fixedPageSize && fixedPageSize > 0) {
      return {
        pageSize: fixedPageSize,
        setPageSize: (pageSize: number) => {
          setFilters(f => ({ ...f, pageSize: `${pageSize}` }));
        },
      };
    }

    const fromProps = filters?.pageSize;
    const fromUrl = searchParams.get("pageSize");
    const asInteger = parseInt(`${fromProps || fromUrl}`, 10);
    const pageSize = isNaN(asInteger) ? DEFAULT_PAGE_SIZES[0] : asInteger;
    return {
      pageSize,
      setPageSize: (pageSize: number) => {
        setFilters(f => ({ ...f, pageSize: `${pageSize}` }));
      },
    };
  }, [filters?.pageSize, searchParams, setFilters, fixedPageSize]);

  const totalPages = Math.ceil(totalItems / pageSize);
  const showPaginator = showPaginatorFunction({ show, totalItems });

  useEffect(() => {
    if (page < 1) {
      setPage(1);
    } else if (totalPages && page > totalPages) {
      setPage(totalPages);
    }

    if (pageSize < DEFAULT_PAGE_SIZES[0]) {
      setPageSize(DEFAULT_PAGE_SIZES[0]);
    }
  }, [page, pageSize, setPage, setPageSize, totalPages]);

  return {
    page,
    pageSize,
    setPage,
    setPageSize,
    pageSizes: DEFAULT_PAGE_SIZES,
    totalItems,
    totalPages,
    show: showPaginator,
    align,
    showItemsPerPage,
    showTotalItems,
    ...otherProps,
  };
};

export default usePaginatorSmart;
