import { t } from "i18next";
import type { Props as MainProps } from "../../Table.i";
import { SkeletonTable } from "src/Components/Skeleton";

type Props = Pick<
  MainProps,
  "striped" | "children" | "showSkeleton" | "skeletonRowCount"
> & {
  colSpan: number;
  noDataMessage?: string;
};

const TBody = ({
  striped,
  children = [],
  colSpan,
  showSkeleton = false,
  skeletonRowCount = 12,
  noDataMessage = "common.no-data-found",
}: Props) => {
  const data: Props["children"] = children.slice();
  if (showSkeleton) {
    data.unshift(
      <tr key="loading-row">
        <td colSpan={colSpan} className="text-center">
          <SkeletonTable rows={skeletonRowCount} rowDistance={5} height={34} />
        </td>
      </tr>
    );
  } else if (!children || children.length === 0) {
    data.push(
      <tr key="no-data-found">
        <td colSpan={colSpan} className="no-data-found">
          {t(noDataMessage)}
        </td>
      </tr>
    );
  }
  return <tbody className={`${striped ? "striped" : ""}`}>{data}</tbody>;
};

export default TBody;
