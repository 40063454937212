// src/components/AvatarGenerator.tsx
import React, { useEffect, useMemo, useRef } from "react";
import { useUIStore } from "@Stores";
import { SPACE_INVADERS_PATTERNS } from "./patterns";

interface User {
  name: string;
  surname: string;
  createdAt: string;
}

interface AvatarGeneratorProps {
  user: User;
  width?: number;
  height?: number;
}

const drawPattern = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  pattern: number[][],
  color: string
) => {
  ctx.fillStyle = color;
  for (let i = 0; i < pattern.length; i++) {
    for (let j = 0; j < pattern[i].length; j++) {
      if (pattern[i][j]) {
        ctx.fillRect(x + j * size, y + i * size, size, size);
      }
    }
  }
};

const generateColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const r = (hash >> 24) & 0xff;
  const g = (hash >> 16) & 0xff;
  const b = (hash >> 8) & 0xff;
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

const invertColor = (color: string): string => {
  const rgba = color.match(/\d+/g);
  if (!rgba || rgba.length < 3) {
    return "#ffffff"; // Default to white if there's an issue
  }
  const r = 255 - parseInt(rgba[0]);
  const g = 255 - parseInt(rgba[1]);
  const b = 255 - parseInt(rgba[2]);
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

const drawCube = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string
) => {
  ctx.fillStyle = color;
  ctx.fillRect(x, y, size, size);
};

const patchLogo = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  appZoomPercentage: number
) => {
  const logoBgSize = 24 * appZoomPercentage;
  drawCube(
    ctx,
    canvas.width - logoBgSize,
    canvas.height - logoBgSize,
    logoBgSize,
    `rgba(0,0,0,1)`
  );

  const image = new Image();
  image.src = "/static/media/Logo.b1f38a9964da1f1feea7a45a94390bb6.svg";
  image.onload = () => {
    const imageSize = 16 * appZoomPercentage;
    const imageWidth = imageSize;
    const imageHeight = imageSize;
    const xPos = canvas.width - imageWidth - 4 * appZoomPercentage;
    const yPos = canvas.height - imageHeight - 4 * appZoomPercentage;
    ctx.drawImage(image, xPos, yPos, imageWidth, imageHeight);
  };
};

const renderContent = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  user: User,
  sizes: { width: number; height: number; percentage: number }
) => {
  // Get user data to generate avatar
  const dataString = `${user.name}${user.surname}${user.createdAt}`;
  const color = generateColor(dataString);
  const backgroundColor = invertColor(color);

  // Draw background
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Select a pattern based on the hash of the user data
  const patternIndex =
    Math.abs(
      dataString.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0)
    ) % SPACE_INVADERS_PATTERNS.length;
  const pattern = SPACE_INVADERS_PATTERNS[patternIndex];

  const size = Math.min(sizes.width, sizes.height) / pattern.length; // Size of each cell in the pattern

  // Calculate the position to center the pattern
  const xOffset = (sizes.width - pattern[0].length * size) / 2;
  const yOffset = (sizes.height - pattern.length * size) / 2;

  // Draw the pattern in the center of the canvas
  drawPattern(ctx, xOffset, yOffset, size, pattern, color);

  patchLogo(canvas, ctx, sizes.percentage);
};

export const AvatarGenerator: React.FC<AvatarGeneratorProps> = ({
  user,
  width = 80,
  height = 80,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { appZoom } = useUIStore();
  const realSizes = useMemo(() => {
    const appZoomPercentage = appZoom ? +appZoom / 100 : 1;
    return {
      width: width * appZoomPercentage,
      height: height * appZoomPercentage,
      percentage: appZoomPercentage,
    };
  }, [appZoom, height, width]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    renderContent(canvas, ctx, user, realSizes);
  }, [user, realSizes]);

  return (
    <canvas
      className="user-avatar"
      ref={canvasRef}
      width={realSizes.width}
      height={realSizes.height}
    ></canvas>
  );
};
