import { type Nullable } from "@Interfaces";
import { type ListedInventoryItem } from "@Services";
import { createStore } from "@Hooks";

export type ScanDetailsInventoryStoreProperties = {
  items: Nullable<Array<ListedInventoryItem>>;
  totalItems: number;
  loading: boolean;
};

export type ScanDetailsInventoryStore = ScanDetailsInventoryStoreProperties & {
  setItems: (
    items: Nullable<Array<ListedInventoryItem>>,
    totalItems: number
  ) => void;
  setLoading: (loading: boolean) => void;
  reset: VoidFunction;
};

const initialState: ScanDetailsInventoryStoreProperties = {
  items: null,
  totalItems: 0,
  loading: true,
};

export const useScanDetailsInventoryStore =
  createStore<ScanDetailsInventoryStore>((get, set) => ({
    ...initialState,
    setItems: (items, totalItems) =>
      set(state => ({ ...state, items, totalItems })),
    setLoading: loading => set(state => ({ ...state, loading })),
    reset: () => set(state => ({ ...state, ...initialState })),
  }));
