import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, organizations, roles } from "src/Mock";

export const organizationDelete = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params;

  const organizationIndex = organizations.findIndex(
    org => org.id === organizationId
  );

  if (organizationIndex !== -1) {
    organizations.splice(organizationIndex, 1);
  }

  roles.forEach((role, index) => {
    if (role.organizationID === organizationId) {
      roles.splice(index, 1);
    }
  });

  return res(ctx.delay(delay), ctx.json({}));
};
