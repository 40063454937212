import type { Role } from "@Services";
import findRolesByUserId from "./findRolesByUserId";

type Args = {
  userId: Role["id"];
  organizationId: Role["organizationID"];
};

const getRole = ({ userId, organizationId }: Args) => {
  return findRolesByUserId(userId).find(
    r => r.organizationID === organizationId
  );
};

export default getRole;
