import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import type { Inventory } from "@Interfaces";
import { API, getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getHeaders } from "../functions";
import { useTableConfig } from "./useTableConfig";

export const useAPIDefinitionList = () => {
  const defaultFilters = getDefaultFilters("apiDefinitions");
  const { search } = useLocation();

  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      return API.inventory().inventoryListInProject(
        args.organizationId,
        args.projectId,
        args.apiDefinitionId,
        args.page,
        args.pageSize,
        args.sortBy as "inventory_item_path" | undefined,
        args.sortMode,
        args.criteria as
          | (
              | "inventory_item_path"
              | "inventory_item_verb"
              | "inventory_item_category"
              | "inventory_item_authentication_expected"
            )[]
          | undefined,
        args.operators as ("LIKE" | "EQ")[],
        args.values
      );
    }, []),
  });

  const updateFilters = useUpdateCacheFilters("project.inventory");
  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultFilters,
      items: getHeaders(),
      search,
      updateFunction: updateFilters,
    });
  }, [defaultFilters, search, updateFilters]);

  const config = useTableConfig(response ? (response.data as Inventory[]) : []);

  return {
    defaultFilters,
    response,
    fetch,
    loading,
    config,
    headers,
    updateFilters,
  };
};
