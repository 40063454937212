import { memo, useMemo } from "react";
import {
  DisplayRowProjectMTLSCertificates,
  SettingsContainer,
} from "@Components";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const MTSLCertificates = memo(() => {
  const { loading, mTLSCertificatesSettings } = useSettingsStore();

  const mTLSCertificates = useMemo(() => {
    const mTLSCertificates = mTLSCertificatesSettings.mTLSCertificates ?? [];
    const globalCertificates = mTLSCertificates?.filter(
      c => !c.associatedAuthSetting
    );
    return (
      <DisplayRowProjectMTLSCertificates
        mtlsCertificates={globalCertificates}
      />
    );
  }, [mTLSCertificatesSettings.mTLSCertificates]);

  return (
    <div id="mtls-certificates" className="p-4">
      <SettingsContainer loading={loading} section="issue-exclusion">
        {mTLSCertificates}
      </SettingsContainer>
    </div>
  );
});
