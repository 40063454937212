import { useKeyboardEvent, useUrlParamsBindedString } from "@Hooks";
import { joinClasses } from "@Utils";
import { t } from "i18next";
import { memo } from "react";
import ReactDomServer from "react-dom/server";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { type MenuHorizontalElementProps } from "../../MenuHorizontal.i";
import { isElementActive } from "./functions";

export const MenuHorizontalElement = memo(
  ({
    name,
    path,
    type,
    className = "",
    partial = false,
    disabled = false,
    tooltip,
    disabledTooltip,
    customLabel,
    shortcut,
    icon,
  }: MenuHorizontalElementProps) => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const bindedPath = useUrlParamsBindedString(path || "");
    const active = isElementActive(pathname, bindedPath, partial);
    const clickable = !disabled && `${pathname}${search}` !== bindedPath;

    useKeyboardEvent({
      action: shortcut,
      onKeyDown: () => navigate(bindedPath),
    });

    const btnClasses = joinClasses(
      `menu-horizontal-element`,
      `menu-horizontal-${type}`,
      !path && "no-path",
      active && "active",
      clickable && "clickable",
      disabled && "btn-disabled"
    );

    const tooltipProps = tooltip
      ? {
          "data-tooltip-id": "tooltip",
          "data-tooltip-place": tooltip.place,
          "data-tooltip-html": ReactDomServer.renderToStaticMarkup(
            <>{tooltip.content}</>
          ),
        }
      : null;

    const disabledTooltipProps =
      disabledTooltip && disabled
        ? {
            "data-tooltip-id": "tooltip",
            "data-tooltip-place": disabledTooltip.place,
            "data-tooltip-html": ReactDomServer.renderToStaticMarkup(
              <>{disabledTooltip.content}</>
            ),
          }
        : null;

    const button = (
      <button
        type="button"
        className={btnClasses}
        data-cy={`${type}-${name.split(".").pop()}`}
        disabled={disabled}
        {...tooltipProps}
        {...disabledTooltipProps}
      >
        <>
          {icon && <i className={icon} />}
          <span>{customLabel ? customLabel : t(`common.${name}`, name)}</span>
          {(tooltip || disabledTooltipProps) && (
            <i className="bi bi-info-circle ms-2" />
          )}
        </>
      </button>
    );

    return (
      <span
        className={`menu-horizontal-element-wrapper ${className}`.trimEnd()}
        children={
          clickable ? (
            <Link to={bindedPath} replace>
              {" "}
              {button}
            </Link>
          ) : (
            button
          )
        }
      />
    );
  }
);
