import type { Dispatch } from "react";
import { useCallback, useMemo, useState, type FC } from "react";
import {
  Button,
  MTLSCertificateDisplayTable,
  MTLSCertificatesForm,
  TrashButton,
} from "@Components";
import { getEmptyMtlsCertificate, type MTLSCertificate } from "@Services";
import { FormGroup } from "reactstrap";
import { useGlobalMTLSCertificates } from "@Hooks";
import { useAuthenticationSettingFormStore } from "@Stores";

const EMPTY_MTLS_CERTIFICATE = getEmptyMtlsCertificate();

type FormProps = {
  siblings: MTLSCertificate[];
  save: Dispatch<MTLSCertificate>;
  disabled: boolean;
};

export const MTLSCertificatesTab: FC = () => {
  const {
    mTLSCertificates,
    addMTLSCertificate,
    removeMTLSCertificate,
    loading,
  } = useAuthenticationSettingFormStore();

  const table = useMemo(() => {
    return mTLSCertificates.map((mtlsCertificate, i) => {
      const noMargin = i === mTLSCertificates.length - 1;
      const key = JSON.stringify(mtlsCertificate);

      return (
        <FormGroup noMargin={noMargin} key={key} className="flex-center">
          <MTLSCertificateDisplayTable mtlsCertificate={mtlsCertificate} />
          <div className="ps-3">
            <TrashButton
              onClick={() => removeMTLSCertificate(i)}
              disabled={loading}
            />
          </div>
        </FormGroup>
      );
    });
  }, [loading, mTLSCertificates, removeMTLSCertificate]);

  return (
    <>
      <FormGroup noMargin={mTLSCertificates.length === 0}>
        <MTLSCertificateTabForm
          siblings={mTLSCertificates}
          save={addMTLSCertificate}
          disabled={loading}
        />
      </FormGroup>
      {table}
    </>
  );
};

const MTLSCertificateTabForm: FC<FormProps> = ({
  siblings,
  save,
  disabled,
}) => {
  const [entry, setEntry] = useState(EMPTY_MTLS_CERTIFICATE);
  const [formValid, setFormValid] = useState(false);

  const handleSave = useCallback(() => {
    save(entry);
    setEntry(EMPTY_MTLS_CERTIFICATE);
  }, [entry, save]);

  const globalMTLSCertificates = useGlobalMTLSCertificates();

  return (
    <>
      <div className="text-end">
        <Button
          color="primary"
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!formValid}
          onClick={handleSave}
          size="sm"
          data-cy="authentication-settings-add-mtls-certificate-button"
        />
      </div>
      <MTLSCertificatesForm
        mtlsCertificateEntry={entry}
        setMTLSCertificateEntry={setEntry}
        localMTLSCertificates={siblings}
        globalMTLSCertificates={globalMTLSCertificates}
        setFormValid={setFormValid}
        disabled={disabled}
      />
    </>
  );
};
