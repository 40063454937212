import { useCallback, useMemo } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { DatePicker, FormInput } from "@Components";
import { t } from "i18next";
import moment from "moment-timezone";
import type { Props } from "./CreateForm.i";
import { useCreateForm } from "./useCreateForm";
import { VirtualRole } from "@Services";

export const CreateForm = (props: Props) => {
  const {
    maxExpiration,
    minExpiration,
    roleOptions,
    validities,
    formData,
    setFormData,
    endDateEnabled,
    toggleEndDate,
    disabled,
  } = useCreateForm(props);

  const handleRoleChange = useCallback(
    (value: string) => {
      const newRole = Object.values(VirtualRole).find(i => i === value);
      if (newRole) setFormData({ ...formData, role: newRole });
    },
    [formData, setFormData]
  );

  return (
    <form id="api-key-form">
      <FormGroup>
        <FormInput
          type="text"
          id="api-key-input-name"
          label="common.name"
          value={formData.name}
          disabled={disabled}
          onChange={e => {
            setFormData({ ...formData, name: e.target.value });
          }}
          onBlur={e => {
            setFormData({ ...formData, name: e.target.value.trim() });
          }}
          valid={validities?.name}
          invalid={!validities?.name}
        />
      </FormGroup>
      <FormGroup>
        <FormGroup switch className="d-flex">
          <Input
            type="checkbox"
            checked={endDateEnabled}
            id="api-key-has-expiration-date"
            onChange={e => toggleEndDate(e.target.checked)}
            disabled={disabled}
          />
          <Label
            className="ps-2 cursor-pointer user-select-none"
            for="api-key-has-expiration-date"
            children={t("common.expires-at")}
          />
        </FormGroup>
        <DatePicker
          id="api-key-input-date"
          value={useMemo(
            () => moment(formData.expiresAt || minExpiration).toDate(),
            [formData.expiresAt, minExpiration]
          )}
          onChange={d => {
            setFormData({
              ...formData,
              expiresAt: moment(d).format("YYYY-MM-DD"),
            });
          }}
          hideValue={!endDateEnabled}
          disabled={disabled || !endDateEnabled}
          min={minExpiration}
          max={maxExpiration}
        />
      </FormGroup>
      <div>
        <FormInput
          type="select"
          options={roleOptions}
          id="api-key-role"
          label="common.role"
          value={formData.role}
          disabled={disabled}
          onChange={e => handleRoleChange(e.target.value)}
          invalid={!validities?.role}
        />
      </div>
    </form>
  );
};
