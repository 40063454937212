import { AuthContext } from "@Contexts";
import { useManageApiResponse, useOrganization, usePreference } from "@Hooks";
import type { Organization } from "@Interfaces";
import { API, type UserGetRelatedOrganizationsResponse } from "@Services";
import { useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavProfileStore } from "./NavProfileCardStore";

const useNavProfileCard = () => {
  const {
    open,
    organizations,
    loading,
    setOpen,
    toggle,
    setOrganizations,
    setLoading,
    resetOrganizations,
    reset,
  } = useNavProfileStore();

  const { user, role } = useContext(AuthContext);
  const username = `${user?.name} ${user?.surname}`.trim();
  const activeOrganization = useOrganization();
  const { pathname } = useLocation();
  const isProfilePage = pathname.includes("profile");
  const [, setLatestOrgId] = usePreference("organization-id");
  const manageResponse = useManageApiResponse(setLoading);

  const fetch = useCallback(
    (organizationName?: string) => {
      manageResponse<UserGetRelatedOrganizationsResponse>({
        promise: API.organization().userGetOrganizationsRelatedToRequester(
          1,
          10,
          "organization_name",
          "asc",
          organizationName ? ["organization_name"] : undefined,
          organizationName ? ["LIKE"] : undefined,
          organizationName ? [organizationName] : undefined
        ),
        errorMessage: "get-organizations",
        onSuccess: axiosResponse => {
          const response = axiosResponse.data;
          if (response) {
            setOrganizations(response, activeOrganization);
          } else {
            throw new Error("Role organization data is empty.");
          }
        },
      });
    },
    [activeOrganization, manageResponse, setOrganizations]
  );

  const setOrganizationActive = useCallback(
    async (organization: Organization) => {
      setLatestOrgId(organization.id);
      setOpen(false);
      window.location.href = "/";
    },
    [setLatestOrgId, setOpen]
  );

  useEffect(() => {
    fetch();

    return reset;
  }, [fetch, reset]);

  return {
    actions: {
      toggle,
      close: () => {
        resetOrganizations();
        setOpen(false);
      },
    },
    isProfilePage,
    isOpen: open,
    activeOrganization,
    organizations,
    username,
    email: user?.email || "",
    role,
    fetch,
    loading,
    setOrganizationActive,
    reset: resetOrganizations,
  };
};

export default useNavProfileCard;
