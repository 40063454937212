import { isURL } from "@Services";

export const getBaseUrls = (spec: any) => {
  const suggestions: string[] = [];

  if (spec.swagger && spec.swagger.startsWith("2")) {
    suggestions.push(getBaseUrlOnV2(spec));
  }

  if (spec.openapi && spec.openapi.startsWith("3")) {
    suggestions.push(...getBaseUrlsOnV3(spec));
  }

  return suggestions.filter(s => isURL(s, { allowLocalhost: false }));
};

const getBaseUrlOnV2 = (spec: any) => {
  let baseUrl;
  if (spec?.schemes && spec.schemes.length) {
    if (spec.schemes.includes("https")) {
      baseUrl = "https";
    } else {
      baseUrl = spec.schemes[0];
    }
  } else {
    baseUrl = "https";
  }

  baseUrl += "://";

  if (spec.host) {
    baseUrl += spec.host;
  }

  if (spec.host && spec.basePath) {
    baseUrl += spec.basePath;
  }

  return baseUrl;
};

const getBaseUrlsOnV3 = (spec: any) => {
  const result: string[] = [];

  if (spec.servers && spec.servers.length) {
    const urls = spec.servers.map((server: { url: string }) => server.url);
    result.push(...urls);
  }

  return result;
};
