import type { Props } from "./MenuHorizontalContainer.i";

export const MenuHorizontalContainer = ({
  flexGrow,
  justifyContent,
  children,
  className = "",
}: Props) => {
  const classNameArray = [
    "d-flex",
    "align-items-center",
    "menu-horizontal-container",
    `justify-content-${justifyContent}`,
    `flex-grow-${flexGrow}`,
    className,
  ];
  return <div className={classNameArray.join(" ")}>{children}</div>;
};
