import { Button } from "@Components";
import {
  KA_INVENTORY_ITEM_DETAILS_ADD_PARAMETERS_LINK_ACTION,
  KA_INVENTORY_ITEM_DETAILS_EXCLUDE_ACTION,
} from "@Constants";
import { useKeyboardEvent } from "@Hooks";
import { t } from "i18next";
import { memo } from "react";
import { usePathExclusion } from "../Hooks/usePathExclusion";
import { useAPIDefinitionDetailsStore } from "../Store/APIDefinitionDetailsStore";

type ActionProps = {
  onAddParameterLink: VoidFunction;
};
export const APIDefinitionDetailsActions = memo(
  ({ onAddParameterLink }: ActionProps) => {
    const { details } = useAPIDefinitionDetailsStore();
    const { canExclude, loading, exclude } = usePathExclusion();
    const { isLatestAPIDefinition } = useAPIDefinitionDetailsStore();
    const canAddParameters = details && details.parameters.length > 0;
    const addParametersTooltip = !isLatestAPIDefinition
      ? "inventory.add-parameter-link-to-api-tooltip-outdated-api-definition"
      : canAddParameters
        ? "inventory.add-parameter-link-to-api-tooltip"
        : "inventory.add-parameter-link-to-api-tooltip-no-parameters";

    useKeyboardEvent({
      action: KA_INVENTORY_ITEM_DETAILS_EXCLUDE_ACTION,
      onKeyDown: exclude,
    });

    useKeyboardEvent({
      action: KA_INVENTORY_ITEM_DETAILS_ADD_PARAMETERS_LINK_ACTION,
      onKeyDown: onAddParameterLink,
    });

    return (
      <div id="actions">
        <Button
          hiddenForReader
          disabled={!canExclude || loading}
          color="danger"
          iconClass="slash-circle"
          children="inventory.details.exclude-btn-label"
          data-tooltip-id="tooltip"
          data-tooltip-content={t("inventory.exclude-inventory-tooltip")}
          data-tooltip-place="left"
          data-cy="button-inventory-exclude"
          loading={loading}
          onClick={exclude}
        />

        <Button
          hiddenForReader
          color="primary"
          iconClass="bi bi-link-45deg"
          children="common.parameter-link"
          disabled={!canAddParameters || !isLatestAPIDefinition}
          data-tooltip-id="tooltip"
          data-tooltip-content={t(addParametersTooltip)}
          data-tooltip-place="left"
          data-cy="button-inventory-add-parameter-link"
          onClick={onAddParameterLink}
          style={{ pointerEvents: "all" }}
        />
      </div>
    );
  }
);
