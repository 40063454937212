import type { PlainObject } from "@Interfaces";

export const getTotalPaths = (spec: any) => {
  return Object.values(spec.paths as PlainObject<any>).reduce(
    (total, endpointObject) => {
      const methods = Object.keys(endpointObject).filter(method => {
        const m = method.toLowerCase();
        return ["get", "post", "put", "delete", "patch"].includes(m);
      });
      return total + methods.length;
    },
    0
  );
};
