import type { ApiKeyId, OrganizationId } from "@Interfaces";
import { API } from "@Services";
import type { PageState } from "./ApiKeyDetail.i";
import type { Dispatch } from "react";

export const getFetchPromise = (
  apiKeyId: ApiKeyId,
  organizationId: OrganizationId
) => {
  return API.auditLog().organizationAdminListApikeysAuditLogs(
    apiKeyId,
    organizationId,
    1,
    15,
    undefined,
    undefined,
    ["audit_log_path", "audit_log_status"],
    ["EQ", "EQ"],
    ["/v1/login", "200"]
  );
};

type HandleComponentStateArgs = {
  pageState: PageState;
  setPageState: Dispatch<PageState>;
  apiKeyFetched: boolean;
  loadingApiKey: boolean;
  loadingLogs: boolean;
  handleFetchLogs: VoidFunction;
};
export const handleComponentState = ({
  apiKeyFetched,
  loadingApiKey,
  loadingLogs,
  pageState,
  setPageState,
  handleFetchLogs,
}: HandleComponentStateArgs) => {
  switch (pageState) {
    case "LOADING_API_KEY":
      if (!apiKeyFetched && !loadingApiKey) {
        setPageState("FINISHED"); // API Error
      } else if (apiKeyFetched) {
        handleFetchLogs(); // next page state
        setPageState("LOADING_LOG");
      }
      break;
    case "LOADING_LOG":
      if (!loadingLogs) {
        setPageState("FINISHED"); // next page state
      }
      break;
    case "FINISHED":
    default:
      break;
  }
};
