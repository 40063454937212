import { LoadingScreen, Tabs } from "@Components";
import { memo, useEffect, useMemo } from "react";
import { Card, Container } from "reactstrap";
import {
  Actions,
  AuthorizationMatrix,
  FailedTab,
  General,
  HistoryList,
  Inventory,
  NotFound,
  PendingTab,
  Settings,
} from "./Components";
import { useTabs, useFetchDetails } from "./Hooks";
import { useHistoryStore } from "./Components/History/Store";
import { useIssuesStore } from "./Components/Issues/Store/IssuesStore";
import { useScanDetailsStore } from "./Store/ScanDetailsStore";

import { IssuesList } from "@Components";
import { useAuthorizationMatrixStore } from "./Components/AuthorizationMatrix/Store/AuthorizationMatrixStore";
import { useScanDetailsInventoryStore } from "./Components/Inventory/Inventory.store";
import { useSettingsStore } from "./Components/Settings/Store/SettingsStore";

import "./ScanDetails.style.scss";

const TABS_MAPPING = {
  general: <General />,
  issues: <IssuesList forScan />,
  "auth-matrix": <AuthorizationMatrix />,
  inventory: <Inventory />,
  history: <HistoryList />,
  settings: <Settings />,
};

const ScanDetailsDataProvider = () => {
  const { reset: resetAuthorizationMatrix } = useAuthorizationMatrixStore();
  const { reset: resetInventoryStore } = useScanDetailsInventoryStore();
  const { reset: resetSettingsStore } = useSettingsStore();
  const { reset: resetHistoryStore } = useHistoryStore();
  const { reset: resetIssuesStore } = useIssuesStore();
  const { reset } = useScanDetailsStore();
  const fetchContent = useFetchDetails();

  useEffect(() => {
    return () => {
      resetAuthorizationMatrix();
      resetInventoryStore();
      resetSettingsStore();
      resetHistoryStore();
      resetIssuesStore();
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  return null;
};

const ScanDetailsContent = () => {
  const { notFound, loading, pending, failed } = useScanDetailsStore();
  const { activeTab, getTabIndex, updateActiveTab, tabs } = useTabs();
  const tabContent = useMemo(() => {
    if (loading) {
      return <LoadingScreen message="common.loading-scan-scan-details" />;
    }

    switch (activeTab) {
      case "issues":
      case "auth-matrix":
      case "inventory":
      case "history":
        if (pending) {
          return <PendingTab />;
        }

        if (failed) {
          return <FailedTab />;
        }
      // eslint-disable-next-line no-fallthrough
      // the fallthrough is intended as every tab will be mapped
      default:
        return TABS_MAPPING[activeTab] ?? null;
    }
  }, [activeTab, loading, pending, failed]);
  return (
    <Container id="scan-detail" fluid>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          <Actions />
          <Tabs
            activeTab={getTabIndex(activeTab)}
            setActiveTab={updateActiveTab}
            tabs={tabs}
            updateUrl
          />
          <Card id="tab-content">{tabContent}</Card>
        </>
      )}
    </Container>
  );
};

export const ScanDetails = memo(() => {
  return (
    <>
      <ScanDetailsDataProvider />
      <ScanDetailsContent />
    </>
  );
});
