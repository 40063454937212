import { memo } from "react";
import {
  Offcanvas as BootstrapOffcanvas,
  Card,
  OffcanvasBody,
} from "reactstrap";
import { OffcanvasHeader } from "./Components";
import "./OffCanvas.style.scss";
import useOffcanvas from "./useOffcanvas";
import type { ButtonBarProps } from "../ButtonBar/ButtonBar";

export type OffCanvasProps = {
  id?: string;
  title: string;
  children: JSX.Element | JSX.Element[] | string;
  isOpen: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  buttons?: ButtonBarProps["buttons"];
  className?: string;
  size?: "sm" | "md" | "lg";
  exitLabel?: string;
};

export const Offcanvas = memo((props: OffCanvasProps) => {
  const {
    children,
    close,
    isOpen,
    buttons,
    id,
    title: canvasTitle,
    className,
  } = useOffcanvas(props);

  return (
    <BootstrapOffcanvas
      id={id}
      isOpen={isOpen}
      toggle={close}
      direction="end"
      className={className}
      title={undefined}
    >
      <OffcanvasHeader title={canvasTitle} buttons={buttons} />
      <OffcanvasBody>
        <Card className="p-4">{children}</Card>
      </OffcanvasBody>
    </BootstrapOffcanvas>
  );
});
