import { GridContainer, LogsViewer } from "@Components";
import { ErrorPage, LoadingPage, SectionDetails } from "./Components";
import { useApiKeyDetail } from "./Hooks/useApiKeyDetail";
import { useMemo } from "react";
import "./style.scss";

export const ApiKeyDetail = () => {
  const { apiKey, apiKeyLoading, handleUpdate, logs, logsLoading } =
    useApiKeyDetail();

  const loading = apiKeyLoading || logsLoading;
  const allFetched = !!apiKey && !!logs;

  const content = useMemo(() => {
    if (loading) return <LoadingPage />;
    if (!allFetched) return <ErrorPage />;
    return (
      <GridContainer>
        <SectionDetails apiKey={apiKey} updateApiKey={handleUpdate} />
        <LogsViewer logs={logs} loading={logsLoading}></LogsViewer>
      </GridContainer>
    );
  }, [allFetched, apiKey, handleUpdate, loading, logs, logsLoading]);

  return <div id="api-key-details">{content}</div>;
};
