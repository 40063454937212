import type { MTLSCertificate } from "@Services";
import { DisplayTable } from "..";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { useGlobalMTLSCertificates } from "@Hooks";
import { OverrideIcon } from "../Components";

export const MTLSCertificateDisplayTable = memo(
  (props: { mtlsCertificate: MTLSCertificate }) => {
    const { name, clientKey, clientCert, caCert } = props.mtlsCertificate;
    const globalMTLSCertificares = useGlobalMTLSCertificates();
    const isOverriding = useMemo(() => {
      return globalMTLSCertificares.some(i => {
        return i.name === name;
      });
    }, [globalMTLSCertificares, name]);
    return (
      <DisplayTable>
        <tbody>
          <tr>
            <td>
              <span>{t("common.name")}</span>
            </td>
            <td className="px-0">
              {isOverriding && <OverrideIcon className="pe-2" />}
              <span>{name}</span>
            </td>
          </tr>
          <tr>
            <td>{t("common.private-key")}</td>
            <td className="px-0">
              <pre className="m-0">{clientKey}</pre>
            </td>
          </tr>
          <tr>
            <td>{t("common.client-certificate")}</td>
            <td className="px-0">
              <pre className="m-0">{clientCert}</pre>
            </td>
          </tr>
          {caCert && (
            <tr>
              <td>{t("common.certificate-authority")}</td>
              <td className="px-0">
                <pre className="m-0">{caCert}</pre>
              </td>
            </tr>
          )}
        </tbody>
      </DisplayTable>
    );
  }
);
