import type { DictionaryEntry } from "@Services";
import { memo, useState } from "react";
import {
  ConfirmDialog,
  DisplaySection,
  EmptyStringLabel,
  TrashButton,
} from "@Components";

type Props = {
  entry: DictionaryEntry;
  deleteEntry?: VoidFunction;
  loading?: boolean;
  disabled?: boolean;
};

export const DictionaryCard = memo(
  ({ entry, deleteEntry, loading, disabled }: Props) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    return (
      <div className="dictionary-card editable-card">
        <DisplaySection label={"common.key"} value={entry.key} truncate />
        <DisplaySection
          className="mt-3"
          label={"common.value"}
          truncate
          value={entry.value || <EmptyStringLabel />}
        />
        {deleteEntry && (
          <div className="card-actions">
            <TrashButton
              onClick={() => setShowConfirmDialog(true)}
              loading={loading}
              disabled={disabled}
            />
          </div>
        )}
        {deleteEntry && showConfirmDialog && (
          <ConfirmDialog
            title="confirmation-dialog.title.delete-configuration"
            description="confirmation-dialog.description.delete-configuration"
            cancelLabel="confirmation-dialog.cancel.delete-configuration"
            confirmLabel="confirmation-dialog.confirm.delete-configuration"
            onCancel={() => setShowConfirmDialog(false)}
            onConfirm={() => {
              setShowConfirmDialog(false);
              deleteEntry();
            }}
          />
        )}
      </div>
    );
  }
);
