import type { Nullable } from "@Interfaces";
import { isLocalHost } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import { pureComponent } from "@Utils";
import hljs from "highlight.js";
import { type FC, memo, useEffect, useRef } from "react";

export const DataViewer: FC = memo(() => {
  const { authenticationSettings } = useAuthenticationSettingFormStore();
  const preRef = useRef<Nullable<HTMLPreElement>>(null);

  useEffect(() => {
    if (!authenticationSettings || !isLocalHost()) {
      return;
    }

    if (!preRef.current) {
      return;
    }

    preRef.current.innerHTML = hljs.highlight(
      JSON.stringify(authenticationSettings, null, 2),
      {
        language: "json",
      }
    ).value;
  }, [authenticationSettings]);

  if (!authenticationSettings || !isLocalHost()) {
    return null;
  }
  return (
    <pre
      className="my-4 color-white p-4 scrollbar-styled"
      style={{ minHeight: "20rem", backgroundColor: "black" }}
      ref={preRef}
    />
  );
}, pureComponent);
