import { BEM, joinClasses, pureComponent } from "@Utils";
import { memo } from "react";
import { SecurityCard } from "../../../Components";

export const ProfileDetailsPageSecurityTab = memo(() => {
  return (
    <div
      className={joinClasses(BEM("profile-details", "container"), "card")}
      id="profile-details-security-tab"
    >
      <SecurityCard />
    </div>
  );
}, pureComponent);
