import type { Dispatch } from "react";
import { useCallback, useMemo, useState, type FC } from "react";
import {
  Button,
  InjectableDisplayTable,
  InjectableParameterForm,
  TrashButton,
} from "@Components";
import type { Injectable } from "@Services";
import { getEmptyInjectablesEntry } from "@Services";
import { FormGroup } from "reactstrap";
import { useGlobalInjectables } from "@Hooks";
import { useAuthenticationSettingFormStore } from "@Stores";

const EMPTY_INJECTABLE = getEmptyInjectablesEntry();

type FormProps = {
  siblings: Injectable[];
  save: Dispatch<Injectable>;
  disabled: boolean;
};

export const InjectablesTab: FC = () => {
  const { injectables, addInjectable, removeInjectable, loading } =
    useAuthenticationSettingFormStore();

  const table = useMemo(() => {
    return injectables.map((injectable, i) => {
      const noMargin = i === injectables.length - 1;
      const key = `${injectable.name}-${injectable.location}`;
      return (
        <FormGroup
          noMargin={noMargin}
          key={key}
          className="flex-center"
          data-cy="injectable-list"
        >
          <InjectableDisplayTable injectable={injectable} />
          <div className="ps-3">
            <TrashButton
              onClick={() => removeInjectable(i)}
              disabled={loading}
            />
          </div>
        </FormGroup>
      );
    });
  }, [injectables, loading, removeInjectable]);

  return (
    <>
      <FormGroup noMargin={injectables.length === 0}>
        <InjectableTabForm
          siblings={injectables}
          save={addInjectable}
          disabled={loading}
        />
      </FormGroup>
      {table}
    </>
  );
};

const InjectableTabForm: FC<FormProps> = ({ siblings, save, disabled }) => {
  const [entry, setEntry] = useState(EMPTY_INJECTABLE);
  const [formValid, setFormValid] = useState(false);

  const handleSave = useCallback(() => {
    save(entry);
    setEntry(EMPTY_INJECTABLE);
  }, [entry, save]);

  const globalInjectables = useGlobalInjectables();

  return (
    <>
      <div className="text-end">
        <Button
          color="primary"
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!formValid}
          onClick={handleSave}
          size="sm"
        />
      </div>
      <InjectableParameterForm
        injectableEntry={entry}
        setInjectableEntry={setEntry}
        setFormValid={setFormValid}
        localInjectables={siblings}
        globalInjectables={globalInjectables}
        disabled={disabled}
      />
    </>
  );
};
