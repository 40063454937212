import { createStore } from "@Hooks";
import type { Nullable, ScanDetail } from "@Interfaces";
import type { Dispatch } from "react";

export const EMPTY_SCAN_DETAILS: ScanDetail = {
  scan: {
    severityCount: { high: 0, medium: 0, low: 0, information: 0 },
    id: "",
    status: "pending",
    apiDefinitionID: "",
    baseURL: "",
    completedAt: "",
    exploitation: false,
    isProductionURL: false,
    lastError: "",
    startedAt: "",
    triggeredBy: "",
    spaceLinkID: "",
  },
  settingsCount: {
    authenticationSettingsCount: 0,
    dictionaryCount: 0,
    issueExclusionsCount: 0,
    pathExclusionsCount: 0,
    userInjectablesCount: 0,
    mtlsCertificatesCount: 0,
    parameterLinksCount: 0,
    extensionSettingsEnabledCategoriesCount: 0,
  },
};

export type ScanDetailStateProperties = {
  details: Nullable<ScanDetail>;
  pending: boolean;
  failed: boolean;
  loading: boolean;
  notFound: boolean;
  issueCount: number;
  associatedSpaceLinkName: string;
};

export type ScanDetailState = ScanDetailStateProperties & {
  setScanDetails: (details: ScanDetail) => void;
  setLoading: (loading: boolean) => void;
  setNotFound: (notFound: boolean) => void;
  setAssociatedSpaceLinkName: Dispatch<string>;
  reset: VoidFunction;
};

const initialState: ScanDetailStateProperties = {
  details: null,
  pending: false,
  failed: false,
  loading: true,
  notFound: false,
  issueCount: 0,
  associatedSpaceLinkName: "",
};

export const useScanDetailsStore = createStore<ScanDetailState>((get, set) => ({
  ...initialState,
  setScanDetails: details => set(state => updateScanDetails(details, state)),
  setLoading: loading => set(state => ({ ...state, loading })),
  setNotFound: notFound => set(state => ({ ...state, notFound })),
  setAssociatedSpaceLinkName: associatedSpaceLinkName => {
    set(state => ({ ...state, associatedSpaceLinkName }));
  },
  reset: () => set(state => ({ ...state, ...initialState })),
}));

const updateScanDetails = (details: ScanDetail, state: ScanDetailState) => {
  if (!details) {
    return state;
  }

  const { scan } = details;

  const issueCount = Object.values(scan.severityCount).reduce(
    (current, partial) => (current += partial),
    0
  );

  return {
    ...state,
    details,
    pending: "completed|failed|stopped".indexOf(scan.status) < 0,
    failed: scan.status === "failed",
    issueCount,
  };
};
