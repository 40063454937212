import { useProject } from "@Hooks";
import {
  type Injectable,
  equals,
  projectDataToUpdateProjectData,
} from "@Services";
import { useInjectableStore } from "@Stores";
import { useCallback, useEffect, useState } from "react";

export const useInjectableParameters = () => {
  const { project, update, loading: projectLoading } = useProject();

  const {
    injectables,
    setProjectInjectables,
    loading,
    setLoading,
    error,
    setError,
    resetStore,
  } = useInjectableStore();

  useEffect(() => {
    setLoading(projectLoading === "get");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectLoading]);

  useEffect(() => {
    if (loading) return;
    setProjectInjectables(project?.injectables ?? []);
    setError(!project);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, project]);

  const [canvasOpen, setCanvasOpen] = useState(false);

  const deleteEntry = useCallback(
    async (injectable: Injectable) => {
      if (!project?.injectables) return false;
      const indexOf = project.injectables.findIndex(e => equals(e, injectable));
      if (indexOf < 0) return false;
      const injectables = [...project.injectables];
      injectables.splice(indexOf, 1);
      const requestBody = projectDataToUpdateProjectData(project);
      requestBody.injectables = injectables;
      const success = await update(requestBody);
      if (success) {
        setProjectInjectables(injectables);
      }
      return success;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project, update]
  );

  return {
    injectables,
    loading,
    error,
    canvasOpen,
    setCanvasOpen,
    deleteEntry,
    resetStore,
  };
};
