import { useEffect } from "react";
import type { TabsProps } from "./Tabs.i";
import { t } from "i18next";

export const useUpdateUrl = (
  tabs: TabsProps["tabs"],
  updateUrl: TabsProps["updateUrl"],
  setActiveTab: TabsProps["setActiveTab"]
) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (updateUrl && hash) {
      tabs.forEach((tab, i) => {
        if (
          t(tab.label).toLowerCase().replaceAll(" ", "-") === hash.substring(1)
        ) {
          setActiveTab(i);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, updateUrl]);
};
