import type { NewProjectData, ProjectExtras } from "@Interfaces";
import { getFileFormat } from "./getFileFormat";
import { readFileAsText } from "@Services";
import { getSpec } from "./getSpec";
import { getData } from "./getData";
import { testParsingResult } from "./testParsingResult";
import { t } from "i18next";

export const handleFileSelected = async (
  file: File,
  getPathsLimit: () => number,
  setProject: React.Dispatch<React.SetStateAction<NewProjectData>>,
  setProjectExtras: React.Dispatch<React.SetStateAction<ProjectExtras>>
) => {
  try {
    // eval file format (json / yaml)
    const format = getFileFormat(file);
    if (!format) throw new Error("error-file-type");

    const fileAsText = await readFileAsText(file);

    // spec : js object representation of file text
    const spec = getSpec(format, fileAsText);
    if (!spec) throw new Error("error-file-format");

    // data : usefull data for models
    const result = await getData(spec, fileAsText);
    if (!result) throw new Error("error-conversion");

    testParsingResult(result, { pathsLimit: getPathsLimit() });

    const {
      baseUrlSuggestions,
      oasUploadData,
      parameters,
      title,
      totalPaths,
      version,
      paths,
    } = result;

    if (totalPaths) setProject(p => ({ ...p, oasUploadData }));
    setProjectExtras(pe => ({
      ...pe,
      oasFile: {
        upload: file,
        baseUrls: baseUrlSuggestions,
        endpoints: totalPaths,
        error: "",
        format: `application/${format}`,
        parameters,
        paths,
        title,
        version,
      },
    }));
  } catch (e: any) {
    setProject(p => ({ ...p, oasUploadData: [] }));
    setProjectExtras(pe => ({
      ...pe,
      oasFile: {
        upload: file,
        baseUrls: [],
        endpoints: 0,
        error: t([`project.errors.${e.message}`, e.message]),
        format: "",
        parameters: [],
        paths: [],
        title: "",
        version: "",
      },
    }));
  }
};
