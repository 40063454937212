import moment from "moment-timezone";
import { getRandomSeverityCount } from "../helpers";
import type { DashboardChart } from "@Interfaces";

const cachedData = new Map();

export const getAndCreateChartData = (
  organizationID: string,
  projectId = ""
) => {
  if (!cachedData.has(organizationID + projectId)) {
    const data: DashboardChart = [];
    for (let i = 365, days = 1; i >= days; i--) {
      const time = moment().add(-i, "day");
      const date = time.startOf("day").format("YYYY-MM-DD");
      data.push({
        date,
        severityCount: getRandomSeverityCount(),
      });
      cachedData.set(organizationID + projectId, data);
    }
  }
  return cachedData.get(organizationID + projectId) as DashboardChart;
};
