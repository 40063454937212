import { DisplaySection } from "@Components";
import type { ApiKey } from "@Interfaces";
import {
  dateTimeFormat,
  getFilterAllowedOptions,
  isMaxEndDate,
} from "@Services";
import { t } from "i18next";
import type { FC } from "react";
import { useMemo } from "react";

type Props = {
  apiKey: ApiKey;
  isExpired: boolean;
  remainingDays: number;
  status: string;
};

export const ApiKeyData: FC<Props> = ({
  apiKey,
  isExpired,
  remainingDays,
  status,
}) => {
  const hasExpiration = !isMaxEndDate(apiKey.expiresAt);

  let expiresAtValue = "";
  let remainingDaysValue = 0;

  if (hasExpiration) {
    expiresAtValue = dateTimeFormat(apiKey.expiresAt, {
      trimTime: true,
    });
    if (Number.isFinite(remainingDays)) {
      remainingDaysValue = remainingDays;
    }
  }

  const roleValue = useMemo(() => {
    const resultRole = getFilterAllowedOptions("api_key_role").find(
      r => r.value === apiKey.role
    );
    return resultRole?.label || "N/A";
  }, [apiKey.role]);

  const statusValue = useMemo(() => {
    return isExpired ? t("common.expired") : t(`common.${status}`);
  }, [isExpired, status]);

  return (
    <div className="showcase-wrapper" data-cy="api-key-data">
      <DisplaySection mask label="common.name" value={apiKey.name} />
      <DisplaySection
        mask
        label="common.created-at"
        value={dateTimeFormat(apiKey.createdAt, { trimTime: true })}
      />
      {!!expiresAtValue && (
        <DisplaySection mask label="common.expires-at" value={expiresAtValue} />
      )}
      {!!remainingDaysValue && (
        <DisplaySection
          mask
          label="common.remaining-days"
          value={remainingDays}
        />
      )}
      <DisplaySection mask label="common.role" value={roleValue} />
      <DisplaySection mask label="common.status" value={statusValue} />
    </div>
  );
};
