/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrganizationAdminUpdateUserSettingsRequest } from '../model';
// @ts-ignore
import { UserGetUserSettingsResponse } from '../model';
/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the settings of the requested user in the same organization of the requester
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetUserSettings: async (organizationId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetUserSettings', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminGetUserSettings', 'userId', userId)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the settings of the user related to the organization of the requester organization admin
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateUserSettings: async (userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminUpdateUserSettings', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminUpdateUserSettings', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminUpdateUserSettings', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/settings`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the settings of the requester user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the settings of the requester user
         * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserSettings: async (requestBody: OrganizationAdminUpdateUserSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userUpdateUserSettings', 'requestBody', requestBody)
            const localVarPath = `/v1/users/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the settings of the requested user in the same organization of the requester
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetUserSettings(organizationId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetUserSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetUserSettings(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the settings of the user related to the organization of the requester organization admin
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminUpdateUserSettings(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminUpdateUserSettings(userId, organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the settings of the requester user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetUserSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the settings of the requester user
         * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateUserSettings(requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateUserSettings(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSettingsApiFp(configuration)
    return {
        /**
         * Returns the settings of the requested user in the same organization of the requester
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetUserSettings(organizationId: string, userId: string, options?: any): AxiosPromise<UserGetUserSettingsResponse> {
            return localVarFp.organizationAdminGetUserSettings(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the settings of the user related to the organization of the requester organization admin
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateUserSettings(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminUpdateUserSettings(userId, organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the settings of the requester user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserSettings(options?: any): AxiosPromise<UserGetUserSettingsResponse> {
            return localVarFp.userGetUserSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the settings of the requester user
         * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserSettings(requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userUpdateUserSettings(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSettingsApi - interface
 * @export
 * @interface UserSettingsApi
 */
export interface UserSettingsApiInterface {
    /**
     * Returns the settings of the requested user in the same organization of the requester
     * @param {string} organizationId Organization ID
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApiInterface
     */
    organizationAdminGetUserSettings(organizationId: string, userId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetUserSettingsResponse>;

    /**
     * Updates the settings of the user related to the organization of the requester organization admin
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApiInterface
     */
    organizationAdminUpdateUserSettings(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the settings of the requester user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApiInterface
     */
    userGetUserSettings(options?: AxiosRequestConfig): AxiosPromise<UserGetUserSettingsResponse>;

    /**
     * Updates the settings of the requester user
     * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApiInterface
     */
    userUpdateUserSettings(requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI implements UserSettingsApiInterface {
    /**
     * Returns the settings of the requested user in the same organization of the requester
     * @param {string} organizationId Organization ID
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public organizationAdminGetUserSettings(organizationId: string, userId: string, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).organizationAdminGetUserSettings(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the settings of the user related to the organization of the requester organization admin
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public organizationAdminUpdateUserSettings(userId: string, organizationId: string, requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).organizationAdminUpdateUserSettings(userId, organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the settings of the requester user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public userGetUserSettings(options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).userGetUserSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the settings of the requester user
     * @param {OrganizationAdminUpdateUserSettingsRequest} requestBody OrganizationAdminUpdateUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public userUpdateUserSettings(requestBody: OrganizationAdminUpdateUserSettingsRequest, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).userUpdateUserSettings(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
