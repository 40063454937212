import type { DictionaryEntry } from "@Services";
import { useCallback, useEffect, useState } from "react";
import updateDictionaryEntries from "./updateDictionaryEntries";
import { useProject } from "@Hooks";

const useProjectDictionary = () => {
  const { project, loading, update } = useProject();
  const [dictionary, setDictionary] = useState<DictionaryEntry[]>([]);
  const [canvasOpen, setCanvasOpen] = useState(false);

  useEffect(() => {
    if (project) {
      setDictionary(project.dictionary.slice());
    }
  }, [project]);

  const addEntry = useCallback(
    async (entry: DictionaryEntry) => {
      if (!project) return false;
      const action = "add";
      return updateDictionaryEntries({ project, update, action, entry });
    },
    [project, update]
  );

  const deleteEntry = useCallback(
    async (index: number) => {
      if (!dictionary || !project) return false;
      const action = "remove";
      return updateDictionaryEntries({ project, update, action, index });
    },
    [dictionary, project, update]
  );

  return {
    dictionary,
    noData: !loading && !project,
    loading: {
      any: loading !== false,
      get: loading === "get",
      put: loading === "put",
    },
    actions: {
      addEntry,
      deleteEntry,
    },
    canvas: {
      isOpen: canvasOpen,
      open: () => setCanvasOpen(true),
      close: () => setCanvasOpen(false),
    },
  };
};

export default useProjectDictionary;
