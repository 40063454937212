import {
  AuthWrapper,
  Breadcrumb,
  OrganizationApiKeysListBreadcrumb,
  OrganizationBreadcrumb,
  OrganizationSpaceLinkBreadcrumb,
  OrganizationUserListBreadcrumbBreadcrumb,
  type MenuHorizontalElementProps,
} from "@Components";
import {
  ApiKeyDetail,
  ApiKeysListing,
  NewProject,
  Organization,
  OrganizationGeneralPage,
  OrganizationMember,
  OrganizationUsers,
  SpaceLinkListing,
  SpaceLinkDetails,
} from "@Pages";

import {
  organizationLastVisitedPageRegexp as GetOrganizationLastVisitedPageRegExp,
  ORGANIZATION_PATH,
  ORGANIZATION_PATH_API_KEYS,
  ORGANIZATION_PATH_GENERAL,
  ORGANIZATION_PATH_NEW_PROJECT,
  ORGANIZATION_PATH_SPACE_LINKS,
  ORGANIZATION_PATH_USERS,
} from "@Constants";
import { t } from "i18next";

const enterpriseLicense = `licence is Enterprise`;
const adminRole = `role in ["organization_admin", "super_admin"]`;

type Config = Omit<MenuHorizontalElementProps, "type"> & {
  roles?: string[];
};

const ORGANIZATION_BUTTONS: Array<Config> = [
  {
    name: "general",
    path: ORGANIZATION_PATH_GENERAL,
    icon: "bi bi-info-square-fill",
  },
  {
    name: "users",
    path: ORGANIZATION_PATH_USERS,
    partial: true,
    visibleControl: adminRole,
    recentPageSearch: GetOrganizationLastVisitedPageRegExp("users"),
    icon: "bi bi-people-fill",
  },
  {
    name: "api-keys",
    path: ORGANIZATION_PATH_API_KEYS,
    accessControl: enterpriseLicense,
    visibleControl: adminRole,
    partial: true,
    recentPageSearch: GetOrganizationLastVisitedPageRegExp("apikeys"),
    disabledTooltip: {
      content: t("components.menu-horizontal.enterprise-only"),
      place: "bottom",
    },
    icon: "bi bi-boxes",
  },
  {
    name: "space-links",
    path: ORGANIZATION_PATH_SPACE_LINKS,
    accessControl: enterpriseLicense,
    visibleControl: adminRole,
    partial: true,
    recentPageSearch: GetOrganizationLastVisitedPageRegExp("space-links"),
    disabledTooltip: {
      content: t("components.menu-horizontal.enterprise-only"),
      place: "bottom",
    },
    icon: "bi bi-broadcast-pin",
  },
];

export const OrganizationRoutes = [
  {
    path: ORGANIZATION_PATH,
    element: <AuthWrapper element={<Organization />} pageName="Organization" />,
    handle: {
      section: "organization",
      buttons: ORGANIZATION_BUTTONS,
      crumb: <OrganizationBreadcrumb />,
    },
    children: [
      {
        path: "general",
        element: <OrganizationGeneralPage />,
        handle: {
          crumb: (
            <Breadcrumb
              name="organization-general"
              to={ORGANIZATION_PATH_GENERAL}
            >
              {t("common.general")}
            </Breadcrumb>
          ),
        },
      },
      {
        path: "users",
        handle: {
          accessControl: adminRole,
          crumb: <OrganizationUserListBreadcrumbBreadcrumb />,
        },
        children: [
          {
            path: "",
            element: <OrganizationUsers />,
          },
          {
            path: ":userId",
            element: <OrganizationMember />,
            handle: {
              crumb: (
                <Breadcrumb name="organization-users-details" disabled>
                  {t("common.details")}
                </Breadcrumb>
              ),
            },
          },
        ],
      },
      {
        path: "apikeys",
        handle: {
          pageName: "Organization API Keys",
          accessControl: `${adminRole} and (${enterpriseLicense})`,
          crumb: <OrganizationApiKeysListBreadcrumb />,
        },
        children: [
          {
            path: "",
            element: <ApiKeysListing />,
          },
          {
            path: ":apiKeyId",
            element: <ApiKeyDetail />,
            handle: {
              crumb: (
                <Breadcrumb name="organization-apikeys-details" disabled>
                  {t("common.details")}
                </Breadcrumb>
              ),
            },
          },
        ],
      },
      {
        path: "spacelinks",
        handle: {
          pageName: "Organization Space Link",
          accessControl: `${adminRole} and (${enterpriseLicense})`,
          crumb: <OrganizationSpaceLinkBreadcrumb />,
        },
        children: [
          {
            path: "",
            element: <SpaceLinkListing />,
          },
          {
            path: ":spacelinksId",
            element: <SpaceLinkDetails />,
            handle: {
              crumb: (
                <Breadcrumb name="organization-space-link-details" disabled>
                  {t("common.details")}
                </Breadcrumb>
              ),
            },
          },
        ],
      },
    ],
  },
  {
    path: ORGANIZATION_PATH_NEW_PROJECT,
    element: <AuthWrapper element={<NewProject />} pageName="New project" />,
    handle: {
      accessControl: `not (role in ["security_reader"])`,
    },
  },
];
