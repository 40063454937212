import type { Issue, Nullable } from "@Interfaces";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";

export type IssueDetailsCanvasStoreProperties = {
  issueDetails: Nullable<Issue>;
  issueExlusionCanvasOpen: boolean;
};

export type IssueDetailsCanvasStore = IssueDetailsCanvasStoreProperties & {
  setIssueDetails: Dispatch<Nullable<Issue>>;
  setIssueExclusionCanvasOpen: Dispatch<boolean>;
  excludeIssueDetails: VoidFunction;
  reset: VoidFunction;
};

const initialState: IssueDetailsCanvasStoreProperties = {
  issueDetails: null,
  issueExlusionCanvasOpen: false,
};
export const useIssueDetailsCanvasStore = createStore<IssueDetailsCanvasStore>(
  (get, set) => ({
    ...initialState,
    setIssueDetails: issueDetails => set(state => ({ ...state, issueDetails })),
    excludeIssueDetails: () =>
      set(state => {
        if (!state.issueDetails) {
          return state;
        }

        const issueDetails = { ...state.issueDetails };

        issueDetails.excluded = true;
        return { ...state, issueDetails };
      }),
    setIssueExclusionCanvasOpen: issueExlusionCanvasOpen =>
      set(state => ({ ...state, issueExlusionCanvasOpen })),
    reset: () => set(state => ({ ...state, ...initialState })),
  })
);
