import { useState, useRef, useCallback, type MutableRefObject } from "react";

export function useStateRef<ValueType>(
  initialValue: ValueType
): [ValueType, (value: ValueType) => void, MutableRefObject<ValueType>] {
  const [state, setState] = useState<ValueType>(initialValue);
  const ref = useRef(initialValue);

  const setStateRef = useCallback((value: ValueType) => {
    setState(value);
    ref.current = value;
  }, []);

  return [state, setStateRef, ref];
}

export default useStateRef;
