import { Button, ComboBox, FormInput, RefreshListButton } from "@Components";
import type { Nullable } from "@Interfaces";
import { getFilterAllowedOptions, type AuthenticationProfile } from "@Services";
import { t } from "i18next";
import type { Dispatch, FC } from "react";
import { memo, useEffect, useMemo } from "react";
import { Col, Label, Row } from "reactstrap";
import { STATUS_CODE_OPTIONS } from "src/Utils/Forms/constants";
import type { Props } from "./PageFilters.i";
import { PageFiltersWrapper } from "./PageFiltersWrapper";
import { usePageFilters } from "./usePageFilters";

type FilterControlsObject = {
  caller: {
    visible: boolean;
  };
};

type HistoryFiltersProps = Props & {
  authenticationProfiles: Nullable<Array<AuthenticationProfile>>;
  clear: VoidFunction;
  filterControls: FilterControlsObject;
};

const getAuthenticationProfilesSelectOptions = (
  authenticationProfiles: Nullable<Array<AuthenticationProfile>>
) => {
  if (!authenticationProfiles) {
    return [{ label: t("common.loading"), value: "" }];
  }
  const result = authenticationProfiles.map(a => ({
    label: a.name,
    value: a.id,
  }));

  return [
    {
      label: t("common.all"),
      value: "",
    },
    ...result,
  ];
};

const checkFilterValue = (
  authenticationProfiles: Nullable<AuthenticationProfile[]>,
  getFilterValueFn: (k: string) => string,
  updateFiltersFn: Dispatch<Record<string, string>>
) => {
  let actualFilterValue = getFilterValueFn("attempt_sequence_auth_profile_id");

  if (!actualFilterValue) {
    return;
  }

  actualFilterValue = actualFilterValue.toLocaleLowerCase();

  if (!authenticationProfiles || !authenticationProfiles.length) {
    return;
  }

  const profileExists = authenticationProfiles?.find(
    authProfile =>
      authProfile.name.toLocaleLowerCase() === actualFilterValue ||
      authProfile.id === actualFilterValue
  );

  if (profileExists) {
    return;
  }

  // the actual filter value does not exists within the profile array
  // that means that the user moved into a new scan with different profiles
  // reset the filter value
  updateFiltersFn({
    attempt_sequence_auth_profile_id: "",
  });
};

export const HistoryFilters: FC<HistoryFiltersProps> = memo(
  ({
    updateFilters,
    refresh,
    clear,
    authenticationProfiles,
    filterControls,
  }) => {
    const { getValueOf } = usePageFilters();

    useEffect(() => {
      checkFilterValue(authenticationProfiles, getValueOf, updateFilters);
    }, [authenticationProfiles, getValueOf, updateFilters]);

    const staticFilters = useMemo(
      () => (
        <>
          <Col>
            <FormInput
              id="scan-history-attempt-method-select"
              type="select"
              options={getFilterAllowedOptions("verb")}
              label="common.method"
              value={getValueOf("attempt_method")}
              onChange={e => {
                updateFilters({
                  attempt_method: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <FormInput
              id="scan-history-attempt-path-input"
              type="text"
              label="scans.history.filters.attempt-path"
              value={getValueOf("attempt_path")}
              onChange={e => {
                updateFilters({
                  attempt_path: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Label for="scan-history-attempt-status-input">
              {t("common.status-code")}
            </Label>
            <ComboBox
              id="scan-history-attempt-status-input"
              bsSize="sm"
              value={getValueOf("attempt_status_code")}
              data={STATUS_CODE_OPTIONS}
              role="form-control"
              onChange={statusCode =>
                updateFilters({
                  attempt_status_code: statusCode,
                })
              }
            />
          </Col>
          <Col>
            <FormInput
              id="scan-history-attempt-resolve-select"
              type="select"
              label="common.resolved"
              options={getFilterAllowedOptions("resolved")}
              value={getValueOf("attempt_resolved")}
              onChange={e => {
                updateFilters({
                  attempt_resolved: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <FormInput
              id="scan-history-attempt-scope-select"
              type="select"
              options={getFilterAllowedOptions("scope")}
              label="common.scope"
              value={getValueOf("attempt_scope")}
              onChange={e => {
                updateFilters({
                  attempt_scope: e.target.value,
                });
              }}
            />
          </Col>
          {filterControls.caller.visible && (
            <Col>
              <FormInput
                id="scan-history-attempt-caller-select"
                type="select"
                options={getFilterAllowedOptions("caller")}
                label="common.caller"
                value={getValueOf("attempt_caller")}
                data-cy="http-history-input-caller"
                onChange={e => {
                  updateFilters({
                    attempt_caller: e.target.value,
                  });
                }}
              />
            </Col>
          )}
          <Col className="d-flex align-items-end">
            <RefreshListButton onClick={refresh} className={"me-3"} />
            <Button
              id="filters-clear-btn"
              type="button"
              iconClass="bi bi-eraser-fill"
              size={"md"}
              onClick={clear}
              color="danger"
              title={t("common.clear-filters")}
            />
          </Col>
        </>
      ),
      [clear, filterControls.caller.visible, getValueOf, refresh, updateFilters]
    );

    return (
      <PageFiltersWrapper className="w-100">
        <Row className={"w-100"}>
          <Col>
            <FormInput
              type="select"
              id="input-authentication-profile-name"
              label="scans.history.filters.attempt_sequence_auth_profile_id"
              value={getValueOf("attempt_sequence_auth_profile_id")}
              className="w-100"
              disabled={!authenticationProfiles}
              onChange={e => {
                updateFilters({
                  attempt_sequence_auth_profile_id: e.target.value,
                });
              }}
              options={getAuthenticationProfilesSelectOptions(
                authenticationProfiles
              )}
            />
          </Col>
          {staticFilters}
        </Row>
      </PageFiltersWrapper>
    );
  }
);
