import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, issueExclusion } from "src/Mock";
import type { PlainObject } from "@Interfaces";
import type { IssueExclusion } from "@Services";

export const editIssueExclusions = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId, issueExclusionId } =
    req.params as PlainObject<string>;
  const newObjData = await req.json<IssueExclusion>();

  if (!organizationId || !projectId || !issueExclusionId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const existingIssueIndex = issueExclusion.findIndex(
    i => i.id === issueExclusionId
  );

  if (existingIssueIndex < 0) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  issueExclusion[existingIssueIndex] = {
    ...issueExclusion[existingIssueIndex],
    ...newObjData,
  };

  return res(ctx.delay(delay), ctx.status(200));
};
