import type { Dispatch } from "react";
import { memo } from "react";
import type { Props as MainProps } from "./VerticalStepper.i";
import { t } from "i18next";
import { VerticalStep } from "./Components";

type Props = {
  items: MainProps["items"];
  activeIndex: number;
  setActiveIndex: Dispatch<number>;
};

export const VerticalStepperItems = memo((props: Props) => {
  const { items, activeIndex, setActiveIndex } = props;
  const onClick = (step: number) => {
    if (step < activeIndex) {
      setActiveIndex(step);
    }
  };
  return (
    <>
      {items.map(item => {
        const step = item.step;
        const classNames = item.className ? [item.className] : [];

        if (step < activeIndex) {
          classNames.push("visited");
        } else if (step === activeIndex) {
          classNames.push("active");
        } else if (step > activeIndex) {
          classNames.push("pe-none");
        }

        return (
          <VerticalStep
            name={t(item.name)}
            step={step}
            key={step}
            className={classNames.join(" ")}
            onClick={() => onClick(step)}
          />
        );
      })}
    </>
  );
});
