import { FormInput } from "../Form";
import { usePageFilters } from "./usePageFilters";
import type { ChangeEvent, FC } from "react";
import { useCallback } from "react";
import { getFilterAllowedOptions } from "@Services";
import type { Props } from "./PageFilters.i";
import { RefreshListButton } from "../Buttons";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

const statusOptions = getFilterAllowedOptions("enabled");
const connectedOptions = getFilterAllowedOptions("connection");

export const SpaceLinkFilters: FC<Props & { className?: string }> = ({
  refresh,
  updateFilters,
  className,
}) => {
  const { getValueOf } = usePageFilters();

  const updateFilter = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      updateFilters({ [name]: value });
    },
    [updateFilters]
  );
  return (
    <PageFiltersWrapper className={className}>
      <FormInput
        type="text"
        className="me-3"
        label="common.name"
        id="filter-space-link-name"
        name="space_link_name"
        value={getValueOf("space_link_name")}
        onChange={updateFilter}
      />
      <FormInput
        type="select"
        options={connectedOptions}
        className="me-3"
        label="common.connected"
        id="filter-space-link-connected"
        name="space_link_connected"
        value={getValueOf("space_link_connected")}
        onChange={updateFilter}
      />
      <FormInput
        type="select"
        options={statusOptions}
        className="me-3"
        label="common.status"
        id="filter-space-link-status"
        name="space_link_status"
        value={getValueOf("space_link_status")}
        onChange={updateFilter}
      />
      <div className="align-self-end">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
