import {
  ListItems,
  Paginator,
  ProfileRolesFilters,
  Section,
  type TableProps,
} from "@Components";
import { useOrganizationId } from "@Hooks";
import type { ListedRole } from "@Interfaces";
import { dateTimeFormat, mapHeaderItemsWithSorts } from "@Services";
import { BEM, joinClasses, pureComponent } from "@Utils";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { useProfileRoles } from "../../Hooks/useProfileRoles";
import { RoleOrganizationName } from "./RoleOrganizationName";
import { SwitchToRole } from "./SwitchToRole";

const TABLE_HEADERS: TableProps["headers"] = [
  {
    text: "",
  },
  {
    text: t(`common.role`),
    sortByKey: "role_value",
  },
  {
    text: t(`common.organization`),
  },
  {
    text: t("common.expires-at"),
    sortByKey: "role_expires_at",
  },
  /*{
    text: t("common.active"),
    sortByKey: "role_active",
  },*/
];

const getConfig = (roles: Array<ListedRole>, activeOrganizationId: string) => {
  if (!roles) {
    return [];
  }

  return roles.map((role, index) => {
    const items = [
      {
        jsx: <SwitchToRole role={role} />,
        fixedWidth: "3rem",
      },
      {
        jsx: (
          <>
            <p className="m-0">
              <b>{t(`roles.${role.role}`)}</b>
            </p>
            {role.organizationID === activeOrganizationId && (
              <div className="d-flex color-pink align-items-center font-size-caption">
                <i className="bi bi-info-square-fill me-1" />
                <span>{t("profile.current-role")}</span>
              </div>
            )}
          </>
        ),
        fixedWidth: "25%",
      },
      {
        jsx: <RoleOrganizationName role={role} />,
      },
      {
        jsx: (
          <span>
            {dateTimeFormat(role.expiresAt, { checkMaxEndDate: true })}
          </span>
        ),
        fixedWidth: "12rem",
      },
      /*{
        jsx: (
          <div className="flex-center form-switch">
            <Input type="switch" readOnly checked={role.active} />
          </div>
        ),
        fixedWidth: "5rem",
      },*/
    ];

    return {
      props: {
        className: joinClasses(
          `profile-role-table-row`,
          activeOrganizationId === role.organizationID && "active"
        ),
      },
      items,
    };
  });
};

export const ProfileDetailsPageRolesTab = memo(() => {
  const { response, loading, fetch, setFilters, search, defaultFilters } =
    useProfileRoles();
  const activeOrganizationId = useOrganizationId();

  const filters = useMemo(
    () => <ProfileRolesFilters updateFilters={setFilters} refresh={fetch} />,
    [fetch, setFilters]
  );

  const listItems = useMemo(() => {
    const headers = mapHeaderItemsWithSorts({
      items: TABLE_HEADERS,
      search,
      updateFunction: setFilters,
      defaults: defaultFilters,
    });

    return (
      <ListItems
        cardVersion={false}
        config={getConfig(response?.data ?? [], activeOrganizationId ?? "")}
        headers={headers}
        noDataMessage={"profile.no-roles-found"}
        showSkeleton={loading}
        setSorts={setFilters}
        defaultSorts={defaultFilters}
      />
    );
  }, [
    activeOrganizationId,
    defaultFilters,
    loading,
    response?.data,
    search,
    setFilters,
  ]);

  const paginator = useMemo(() => {
    return (
      <Paginator
        setFilters={setFilters}
        totalItems={response?.totalItems}
        showItemsPerPage={true}
        className="p-4"
      />
    );
  }, [response?.totalItems, setFilters]);

  return (
    <div
      className={joinClasses(BEM("profile-details", "container"), "card")}
      id="profile-details-roles-tab"
    >
      <Section removeLowerRadius>
        <div className="d-flex justify-content-between align-items-end">
          {filters}
        </div>
      </Section>
      {listItems}
      {paginator}
    </div>
  );
}, pureComponent);
