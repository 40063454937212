import { SingleOptionSection } from "@Components";
import { AuthContext } from "@Contexts";
import { t } from "i18next";
import { memo, useContext, useState } from "react";
import { UpdatePassword } from "../UpdatePassword";
import { DisableOtp, SetupOtp } from "./Components";

export const SecurityCard = memo(() => {
  const { user } = useContext(AuthContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const otpEnabled = user?.twoFactorAuthenticationEnabled || false;

  return (
    <>
      <SingleOptionSection
        title="common.password"
        description="profile.update-password-description"
        iconClass="bi-key-fill"
        buttonProps={[
          {
            iconClass: "bi bi-pencil-fill",
            color: "primary",
            onMouseDown: () => setUpdatingPassword(true),
            data: { cy: "update-psw-btn" },
            children: "common.update",
          },
        ]}
      />

      <SingleOptionSection
        title="common.2fa"
        description={
          otpEnabled
            ? "profile.disable-2fa-label"
            : "profile.authentication-description"
        }
        iconClass="bi-shield-fill-check"
        buttonProps={[
          {
            color: otpEnabled ? "danger" : "primary",
            iconClass: otpEnabled ? "bi bi-toggle-off" : "bi bi-toggle-on",
            onMouseDown: () => setShowOffcanvas(!showOffcanvas),
            data: { cy: "2fa-btn" },
            children: t(otpEnabled ? "common.disable" : "common.enable"),
          },
        ]}
      />

      <UpdatePassword
        isOpen={updatingPassword}
        setIsOpen={setUpdatingPassword}
      />

      {otpEnabled ? (
        <DisableOtp
          showOffcanvas={showOffcanvas}
          setShowOffcanvas={setShowOffcanvas}
        />
      ) : (
        <SetupOtp
          showOffcanvas={showOffcanvas}
          setShowOffcanvas={setShowOffcanvas}
        />
      )}
    </>
  );
});
