import { ThemeContext } from "@Contexts";
import type { Option, Options } from "@Interfaces";
import { useContext, type Dispatch, type FC } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

type ComboBoxDropdownProps = {
  show: boolean;
  options: Options<string>;
  onSelect: Dispatch<Option<string>>;
};

export const ComboBoxDropdown: FC<ComboBoxDropdownProps> = ({
  show,
  options,
  onSelect,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Dropdown
      isOpen={show}
      toggle={() => undefined}
      direction="down"
      size="sm"
      className="position-absolute top-0 left-0 w-100"
    >
      <DropdownToggle caret tabIndex={-1} />
      <DropdownMenu dark={theme === "dark"}>
        {options.map((o, i) => (
          <DropdownItem
            onClick={() => onSelect(o)}
            key={`${o.value}-${i}`}
            children={o.label}
            className="user-select-none"
          />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
