import { createStore } from "@Hooks";
import type { Nullable } from "@Interfaces";
import type { Dispatch } from "react";

export type SpaceLinkEditCanvasStoreProperties = {
  selectedSpaceLinkID: Nullable<string>;
};

export type SpaceLinkEditCanvasStore = SpaceLinkEditCanvasStoreProperties & {
  setSelectedSpaceLinkID: Dispatch<string>;
  reset: VoidFunction;
};

const initialState: SpaceLinkEditCanvasStoreProperties = {
  selectedSpaceLinkID: null,
};

export const useSpaceLinkEditCanvasStore =
  createStore<SpaceLinkEditCanvasStore>((get, set) => ({
    ...initialState,
    setSelectedSpaceLinkID: selectedSpaceLinkID =>
      set(store => ({ ...store, selectedSpaceLinkID })),
    reset: () => set(store => ({ ...store, ...initialState })),
  }));
