import { t } from "i18next";
import { DashboardCard, NoVulnerableData } from "../Components";
import type { DashboardCardProps } from "./DashboardCard.i";

export const IssueCountCard = ({
  issueCounter,
  showNoData,
}: DashboardCardProps<{ issueCounter?: number }>) => {
  return (
    <DashboardCard className="issue-count">
      {showNoData ? (
        <NoVulnerableData text={"common.no-issues"} direction="row" />
      ) : (
        <div className="d-inline-block">
          <span className="response-value">{issueCounter || 0}</span>
          <span>{t("common.issues")}</span>
        </div>
      )}
    </DashboardCard>
  );
};
