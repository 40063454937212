import { pureComponent } from "@Utils";
import type { CSSProperties } from "react";
import { memo } from "react";
import { CurtesyPage } from "../CurtesyPage";
import { t } from "i18next";

const style: CSSProperties = {
  lineHeight: "2.5rem",
};

export const DashboardError = memo(() => {
  return (
    <CurtesyPage>
      <div className="d-flex">
        <i
          className="bi bi-info-square-fill fs-1 color-severity-medium me-4"
          style={style}
        ></i>
        <div>
          <h2 className="white-space-pre-line m-0" style={style}>
            {t("common.something-went-wrong")}
          </h2>
          <span className="fs-5">{t("common.please-try-later")}</span>
        </div>
      </div>
    </CurtesyPage>
  );
}, pureComponent);
