import { ORGANIZATION_PATH_USERS } from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPage } from "@Services";
import { generateUrl } from "@Utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateToUserList = () => {
  const { organizationId } = useUrlParams();
  const navigate = useNavigate();
  const navigateToList = useCallback(() => {
    const to =
      lastVisitedPage("users") ||
      generateUrl(ORGANIZATION_PATH_USERS, [organizationId ?? ""]);
    navigate(to);
  }, [navigate, organizationId]);
  return navigateToList;
};
