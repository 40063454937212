import type { OrganizationId, UserId } from "@Interfaces";
import { API } from "@Services";
import type { PageState } from "./OrganizationMember.i";
import type { Dispatch } from "react";

export const getFetchPromise = (
  userId: UserId,
  organizationId: OrganizationId
) => {
  return API.auditLog().organizationAdminListUserAuditLogs(
    userId,
    organizationId,
    1,
    15,
    undefined,
    undefined,
    ["audit_log_path", "audit_log_status"],
    ["EQ", "EQ"],
    ["/v1/login", "200"]
  );
};

type HandleComponentStateArgs = {
  pageState: PageState;
  setPageState: Dispatch<PageState>;
  userFetched: boolean;
  loadingUser: boolean;
  loadingLogs: boolean;
  handleFetchLogs: VoidFunction;
};
export const handleComponentState = ({
  userFetched,
  loadingUser,
  loadingLogs,
  pageState,
  setPageState,
  handleFetchLogs,
}: HandleComponentStateArgs) => {
  switch (pageState) {
    case "LOADING_USER":
      if (!userFetched && !loadingUser) {
        setPageState("FINISHED"); // API Error
      } else if (userFetched) {
        handleFetchLogs(); // next page state
        setPageState("LOADING_LOG");
      }
      break;
    case "LOADING_LOG":
      if (!loadingLogs) {
        setPageState("FINISHED"); // next page state
      }
      break;
    case "FINISHED":
    default:
      break;
  }
};
