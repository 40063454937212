import { NewProjectContext } from "@Contexts";
import { isFilledString } from "@Services";
import { useContext } from "react";

export const useDetailsSection = () => {
  const { project, setProject } = useContext(NewProjectContext);

  const generator = (property: "name" | "description") => {
    const value = project[property];
    return {
      value,
      updateValue: (newValue: string) => {
        newValue !== value && setProject({ ...project, [property]: newValue });
      },
      invalid: !isFilledString(value),
    };
  };

  return {
    name: generator("name"),
    description: generator("description"),
  };
};
