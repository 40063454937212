import type { UserListProjectsResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, findProjectsByFilters } from "src/Mock";

export const getProjects = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const organizationId = req.params.organizationId;

  if (!organizationId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const { searchParams } = req.url;

  const listProjects = findProjectsByFilters({
    organizationId: `${organizationId}`,
    filters: searchParams,
  });

  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("size")) || 12;

  const start = pageSize * (page - 1);
  const end = start + pageSize;

  const response: UserListProjectsResponse = {
    data: listProjects.slice(start, end),
    page: page,
    size: pageSize,
    totalItems: listProjects.length,
    totalPages: Math.ceil(listProjects.length / pageSize),
  };

  return res(ctx.delay(delay), ctx.json(response));
};
