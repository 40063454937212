import type { Confidence, Issue } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  findIssuesOfOrganization,
  getRequestWithBug,
  getSequenceOfRequest,
} from "src/Mock";
import { vulnerabilities } from "src/Mock/seeder";

export const getIssue = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { params } = req;
  const organizationId = `${params.organizationId}`;
  const issueId = `${params.issueId}`;
  const listIssue = findIssuesOfOrganization(organizationId, [], []);
  const issue = listIssue.find(i => i.id === issueId);

  if (!issue) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const allConfidence: Confidence[] = [
    "certain",
    "firm",
    "tentative",
    "information",
    "false_positive",
  ];

  const confidence = allConfidence[Math.floor(Math.random() * 5)];

  const issueResponse: Issue = {
    category: issue.category,
    confidence,
    cvss: 8.5,
    cwes: issue.cwes,
    description: issue.description,
    endpoint: issue.endpoint,
    id: issue.id,
    name: issue.name,
    owaspTopTen: issue.owaspTopTen,
    remediation:
      vulnerabilities.find(v => v.name === issue.name)?.remediation || "",
    requestWithBug: getRequestWithBug(),
    sequenceOfRequest: getSequenceOfRequest(),
    severity: issue.severity,
    verb: issue.verb as Issue["verb"],
    extensionName: issue.name.toLowerCase().replaceAll(" ", "-"),
    isNew: issue.isNew,
    excluded: +issue.id === 1000116 /* necessary for e2e tests */,
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(issueResponse));
};
