export const getInputIconClass = (inputType: string) =>
  `bi-${
    {
      datetime: "calendar",
      integer: "123",
      phone_number: "telephone",
      company_name: "building",
      password: "key",
      email: "envelope",
      username: "person",
      coords: "geo-alt",
      float: "hash",
      url: "link",
      token: "shield-lock",
      base64url: "code",
      day: "calendar-day",
      other: "question-circle",
      unixtime: "clock",
      filepath: "file-earmark",
      countrycode: "flag",
      binary: "file-earmark-binary",
      year: "calendar2",
      city: "geo-alt",
      country: "map",
      hostname: "server",
      minute: "clock",
      month: "calendar-month",
      first_name: "person",
      gender: "gender-trans",
      hour: "clock",
      ip: "shield",
      last_name: "person",
      barcode: "upc",
      postcode: "mailbox",
      address: "house-door",
      ssn: "card-list",
      iban: "credit-card",
      user_agent: "laptop",
      plate: "car-front",
      credit_card_number: "credit-card",
      swift: "bank",
      airport_code: "airplane",
    }[inputType] || "question-circle"
  }`;
