import {
  useOrganizationAuditLogs,
  useOrganizationMember as useOrganizationMemberHook,
  useUrlParams,
} from "@Hooks";
import { useCallback, useEffect, useState } from "react";
import { getFetchPromise, handleComponentState } from "../functions";
import type { PageState } from "../OrganizationMember.i";

export const useOrganizationMember = () => {
  const [pageState, setPageState] = useState<PageState>("LOADING_USER");
  const { organizationId } = useUrlParams();

  const {
    user,
    loading: loadingUser,
    fetch: fetchUser,
  } = useOrganizationMemberHook();

  const {
    logs,
    loading: loadingLogs,
    fetch: fetchLogs,
  } = useOrganizationAuditLogs();

  const handleFetchLogs = useCallback(() => {
    if (!user?.id || !organizationId) return;
    const promise = getFetchPromise(user.id, organizationId);
    fetchLogs(promise);
  }, [fetchLogs, organizationId, user?.id]);

  useEffect(() => {
    handleComponentState({
      handleFetchLogs,
      loadingUser: !!loadingUser,
      loadingLogs,
      pageState,
      setPageState,
      userFetched: !!user,
    });
  }, [handleFetchLogs, loadingLogs, loadingUser, logs, pageState, user]);

  return {
    user,
    logs,
    userLoading: pageState === "LOADING_USER" || loadingUser,
    logsLoading: pageState === "LOADING_LOG" || loadingLogs,
    fetchUser,
  };
};
