import { issues } from "../data/issues";
import { issuesSeeder } from "./issues";

export const seeder = () => ({
  issue: (organizationId: string, config?: { replace?: boolean }) => {
    if (config?.replace && issues.has(organizationId)) {
      issues.delete(organizationId);
    }
    issuesSeeder(organizationId);
  },
});

export * from "./issues";
