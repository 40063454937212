import type { UserConfirmEnableTwoFactorAuthenticationRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";

export const userConfirmEnable2fa = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { otp } = (await req.json()) as {
    otp: string;
  } as UserConfirmEnableTwoFactorAuthenticationRequest;
  if (!otp) {
    return res(ctx.delay(delay), ctx.status(400));
  }
  return res(ctx.delay(delay), ctx.json({}));
};
