import type { InventoryInput } from "@Interfaces";

export const getNodeParameterIcon = (type: InventoryInput) => {
  switch (type) {
    case "password":
      return "bi bi-fingerprint";
    case "email":
      return "bi bi-envelope-at-fill";
    case "address":
      return "bi bi-geo-alt-fill";
    case "barcode":
      return "bi bi-upc";
    case "coords":
      return "bi bi-compass-fill";
    case "credit_card_number":
      return "bi bi-credit-card-2-back-fill";
    case "datetime":
      return "bi bi-calendar-date-fill";
    case "filepath":
      return "bi bi-folder-fill";
    case "gender":
      return "bi bi-gender-ambiguous";
    case "iban":
      return "bi bi-bank2";
    case "phone_number":
      return "bi bi-telephone-fill";
    case "url":
      return "bi bi-globe";
    default:
      return "bi bi-fonts"; // textual
  }
};
