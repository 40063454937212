import type { PropsWithChildren } from "react";
import React, { memo } from "react";
import { useProjectContext } from "./useProjectContext";
import type { ProjectContextType } from "./ProjectContext.i";

export const ProjectContext = React.createContext<ProjectContextType>({
  project: undefined,
  projectStatus: "",
  fetch: async () => false,
  update: async () => false,
  loading: false,
  notFound: false,
});

export const ProjectProvider = memo(({ children }: PropsWithChildren) => {
  const value = useProjectContext();
  return <ProjectContext.Provider value={value} children={children} />;
});
