import { AuthContext } from "@Contexts";
import { useContext, useEffect, useState } from "react";
import type { RoleName } from "@Interfaces";

export const useRole = () => {
  const { role } = useContext(AuthContext);
  return role?.role as RoleName;
};

export const useRoleName = () => {
  const { role } = useContext(AuthContext);
  const [roleName, setRoleName] = useState<RoleName | undefined>();

  useEffect(() => {
    setRoleName(role?.role as RoleName);
  }, [role]);

  return roleName;
};
