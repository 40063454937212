import type { Nullable } from "@Interfaces";
import { getPreference, setPreference } from "@Services";
import type { Dispatch } from "react";
import { useCallback, useState } from "react";

type Preference = Nullable<string>;

export const usePreference = (
  preferenceKey: string,
  defaultValue?: string
): [Preference, Dispatch<Preference>] => {
  const [preferenceValue, setPreferenceValue] = useState(() => {
    return getPreference(preferenceKey) || defaultValue || null;
  });

  const handleSetPreferenceValue = useCallback(
    (newValue: string | null) => {
      if (newValue === preferenceValue) return;
      setPreferenceValue(newValue);
      setPreference(preferenceKey, newValue);
    },
    [preferenceKey, preferenceValue]
  );

  return [preferenceValue, handleSetPreferenceValue];
};
