import {
  usePaginatedResult,
  useUpdateCacheFilters,
  useUrlParams,
} from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useMemo } from "react";
import type {
  FilterValues as PeriodValues,
  VulnerabilityType,
} from "@Interfaces";

export type ListOrganizationIssueParams = [
  string,
  number,
  number,
  undefined,
  undefined,
  Array<"issue_severity" | "period"> | undefined,
  Array<"EQ"> | undefined,
  Array<VulnerabilityType | PeriodValues> | undefined,
];

export type ListProjectIssueParams = [
  string,
  string,
  number,
  number,
  undefined,
  undefined,
  Array<"issue_severity" | "period"> | undefined,
  Array<"EQ"> | undefined,
  Array<VulnerabilityType | PeriodValues> | undefined,
];

const useAllIssues = () => {
  const defaultFilters = useMemo(() => getDefaultFilters("issues"), []);

  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      const APIIssues = API.issue();
      const apiParams = [
        args.organizationId,
        args.page,
        args.pageSize,
        args.sortBy,
        args.sortMode,
        args.criteria,
        args.operators,
        args.values,
      ];
      if (args.projectId) {
        apiParams.splice(1, 0, args.projectId);
        return APIIssues.issueListInProject(
          ...(apiParams as ListProjectIssueParams)
        );
      } else {
        return APIIssues.issueListInOrganization(
          ...(apiParams as ListOrganizationIssueParams)
        );
      }
    }, []),
  });

  const { projectId } = useUrlParams();
  const cacheKey = projectId ? "project" : "organization";
  const setFilters = useUpdateCacheFilters(`${cacheKey}.all-issues`);

  return {
    response,
    fetch,
    loading,
    setFilters,
  };
};

export default useAllIssues;
