import { memo } from "react";
import type { Props } from "./EditUser.i";
import { t } from "i18next";
import { Offcanvas } from "@Components";
import useEditUser from "./useEditUser";
import { FormGroup, Input, Label } from "reactstrap";

export const EditUser = memo((props: Props) => {
  const { isOpen, close, form, submitButton } = useEditUser(props);
  return (
    <Offcanvas
      id="update-user-canvas"
      isOpen={isOpen}
      toggle={close}
      title="common.edit-user"
      buttons={[submitButton]}
      children={
        <>
          <FormGroup>
            <Label for="edit-user-name-input">{t("common.name")}</Label>
            <Input
              type="text"
              id="edit-user-name-input"
              value={form.data.name}
              autoComplete="off"
              onChange={e => form.update("name", e.target.value)}
              onBlur={e => form.update("name", e.target.value.trim())}
              disabled={form.disabled}
              valid={form.validities.name}
              invalid={!form.validities.name}
            />
          </FormGroup>
          <div>
            <Label for="edit-user-surname-input">{t("common.surname")}</Label>
            <Input
              type="text"
              id="edit-user-surname-input"
              value={form.data.surname}
              onChange={e => form.update("surname", e.target.value)}
              onBlur={e => form.update("surname", e.target.value.trim())}
              disabled={form.disabled}
              valid={form.validities.surname}
              invalid={!form.validities.surname}
            />
          </div>
        </>
      }
    />
  );
});
