import { useContext, useEffect } from "react";
import { NewProjectExtrasContext } from "@Contexts";
import { useUpdatedOrganizationLicense } from "@Hooks";

export const useLicenseSection = () => {
  const { loading, license } = useUpdatedOrganizationLicense();
  const { projectExtras, setProjectExtras } = useContext(
    NewProjectExtrasContext
  );

  useEffect(() => {
    if (license && !projectExtras.license) {
      setProjectExtras({ ...projectExtras, license });
    }
  }, [license, projectExtras, setProjectExtras]);

  return {
    loading,
    license: projectExtras.license,
  };
};
