import { Button } from "@Components";
import {
  KA_NAVIGATE_TO_PROFILE,
  KA_NAV_PROFILE_MENU_LOGOUT,
  KA_NAV_PROFILE_MENU_TOGGLE,
} from "@Constants";
import { useKeyboardEvent } from "@Hooks";
import type { Organization } from "@Interfaces";
import { t } from "i18next";
import { memo, useCallback, useMemo } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink, useNavigate } from "react-router-dom";
import { getInitials } from "../../Functions";
import { NavProfileCardOrganizations } from "./Component/NavProfileCardOrganizations";
import useNavProfileCard from "./useNavProfileCard";

import "./NavProfileCard.style.scss";

export const NavProfileCard = memo(() => {
  const {
    actions,
    isOpen,
    activeOrganization,
    username,
    email,
    role,
    isProfilePage,
    loading,
    fetch,
    setOrganizationActive,
  } = useNavProfileCard();
  const navigate = useNavigate();
  const initials = useMemo(() => getInitials(username), [username]);
  const companyInitials = useMemo(
    () => getInitials(activeOrganization?.name ?? "", 3),
    [activeOrganization?.name]
  );

  const profileIconClasses = useMemo(() => {
    let classes = "profile-icon user-select-none";
    if (isProfilePage) classes += " active-page";
    if (initials.length === 3) classes += " long-name";
    return classes;
  }, [initials.length, isProfilePage]);

  const handleOutsideClick = useCallback(() => actions.close(), [actions]);
  const handleProfileClick = useCallback(() => actions.toggle(), [actions]);
  const handleOrganizationClick = useCallback(
    (organization: Organization, isActive: boolean) =>
      setOrganizationActive(organization),
    [setOrganizationActive]
  );

  const handleFilterOrganization = useCallback(
    (value: string) => fetch(value),
    [fetch]
  );

  const handleLogout = useCallback(() => {
    navigate("/login");
    actions.close();
  }, [actions, navigate]);

  useKeyboardEvent({
    action: KA_NAV_PROFILE_MENU_TOGGLE,
    onKeyDown: actions.toggle,
  });

  useKeyboardEvent({
    action: KA_NAV_PROFILE_MENU_LOGOUT,
    capture: { down: false },
    onKeyDown: event => {
      if (isOpen) {
        event.preventDefault();
        handleLogout();
      }
    },
  });

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div
        id="profile-card"
        data-cy="profile-card"
        className={`position-relative${isOpen ? " open" : ""}`}
      >
        <div
          className="profile card"
          onClick={handleProfileClick}
          data-tooltip-id="tooltip-nav"
          data-tooltip-content={t("common.account")}
          data-tooltip-place="right"
        >
          <span className={profileIconClasses}>{initials}</span>
          <span className="company-name">{companyInitials}</span>
        </div>
        {isOpen && (
          <div className="profile-menu">
            <div className="card p-3">
              <div className="d-flex align-items-center mb-3">
                <div className="profile" onClick={handleProfileClick}>
                  <span className={profileIconClasses}>{initials}</span>
                </div>
                <div className="ms-2 user-info">
                  <b>{username}</b>
                  <span>{email}</span>
                  <span className="font-size-caption font-style-italic color-pink">
                    <b>{t(`roles.${role?.role}`)}</b>
                  </span>
                </div>
              </div>

              <NavProfileCardOrganizations
                activeOrganization={activeOrganization}
                loading={loading}
                handleFilterOrganization={handleFilterOrganization}
                handleOrganizationClick={handleOrganizationClick}
                handlEscape={() => actions.close()}
              />

              <div className="d-flex mt-4">
                <NavLink to="/profile">
                  <Button
                    color="primary"
                    size="sm"
                    className="d-flex align-items-center"
                    data-cy={"nav-profile-button"}
                    onClick={handleOutsideClick}
                    title={KA_NAVIGATE_TO_PROFILE.getTitle()}
                    iconClass="bi bi-person-fill"
                    children="nav.profile"
                  />
                </NavLink>
                <Button
                  color="secondary"
                  size="sm"
                  onMouseDown={handleLogout}
                  data-cy={"nav-logout-button"}
                  className="d-flex align-items-center ms-2"
                  title={KA_NAV_PROFILE_MENU_LOGOUT.getTitle()}
                  iconClass="bi bi-box-arrow-left"
                  children="nav.logout"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
});
