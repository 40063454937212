import type { OrganizationId, ProjectId } from "@Interfaces";
import { openIssues } from "src/Mock/data";
import { findIssuesOfOrganization } from "./findIssuesOfOrganization";

export const getOpenIssues = (
  organizationId: OrganizationId,
  projectId: ProjectId = ""
) => {
  let organizationOpenIssues = openIssues.get(organizationId);
  if (organizationOpenIssues === undefined) {
    const organizationIssues = findIssuesOfOrganization(organizationId, [], []);
    const newIssues = organizationIssues.filter(i => i.isNew);
    openIssues.set(organizationId, newIssues);
    organizationOpenIssues = newIssues;
  }

  return organizationOpenIssues.filter(i => {
    return !projectId || i.projectID === projectId;
  });
};
