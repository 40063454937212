import type { ButtonProps } from "@Components";
import { DisplayRowProjectAuthentications } from "@Components";
import { pureComponent } from "@Utils";
import { memo, useMemo } from "react";
import { useProjectAuthenticationCards } from "../Hooks";
import { getAddButtonProps } from "../../Functions";

export const AuthenticationCards = memo(() => {
  const {
    isArchivedProject,
    authenticationSettings,
    handleOpenCanvas,
    handleAuthenticationEdit,
    handleAuthenticationClone,
    handleAuthenticationDelete,
  } = useProjectAuthenticationCards();

  const addAuthenticationButtonProps = useMemo<ButtonProps[]>(() => {
    return [
      getAddButtonProps({
        onClick: () => handleOpenCanvas("credentials", "insert"),
        children: "common.add-credentials",
        data: { cy: "button-add-credentials" },
      }),
      getAddButtonProps({
        onClick: () => handleOpenCanvas("static_token", "insert"),
        children: "common.add-token",
        data: { cy: "button-add-token" },
      }),
      getAddButtonProps({
        onClick: () => handleOpenCanvas("oauth_credentials", "insert"),
        children: "common.add-oauth-credentials",
        data: { cy: "button-add-oauth-credentials" },
      }),
    ];
  }, [handleOpenCanvas]);

  if (isArchivedProject) {
    return (
      <DisplayRowProjectAuthentications
        authenticationSettings={authenticationSettings}
      />
    );
  }

  return (
    <DisplayRowProjectAuthentications
      authenticationSettings={authenticationSettings}
      onDelete={handleAuthenticationDelete}
      onEdit={handleAuthenticationEdit}
      onClone={handleAuthenticationClone}
      buttonProps={addAuthenticationButtonProps}
    />
  );
}, pureComponent);
