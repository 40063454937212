import { Breadcrumb } from "@Components";
import {
  PROJECT_DETAILS_SCAN_URL,
  projectLastVisitedPageRegExp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type ProjectScanDetailsBreadcrumbProps = {
  disabled?: boolean;
};

export const ProjectScanDetailsBreadcrumb = ({
  disabled = false,
}: ProjectScanDetailsBreadcrumbProps) => {
  const { organizationId, projectId, scanId } = useUrlParams();
  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(projectLastVisitedPageRegExp("scans/:scanId")) ??
      generateUrl(PROJECT_DETAILS_SCAN_URL, [
        organizationId ?? "",
        projectId ?? "",
        scanId ?? "",
      ]);

  return (
    <Breadcrumb disabled={disabled} name={"project-scan-details"} to={to}>
      {t("common.scan-detail")}
    </Breadcrumb>
  );
};
