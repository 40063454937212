import { Button } from "@Components";
import type { Props } from "./RefreshListButton.i";

import "./style.scss";

export const RefreshListButton = ({ onClick, className }: Props) => (
  <Button
    color="primary"
    iconClass="arrow-repeat"
    className={className}
    outline
    id="filters-refresh-btn"
    onClick={onClick}
  />
);
