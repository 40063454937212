import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import moment from "moment-timezone";
import { dateTimeFormat } from "@Services";
import { t } from "i18next";
import type { Props } from "./TimeZone.i";

export const TimeZone = React.memo(({ value, disabled, onChange }: Props) => {
  return (
    <>
      <h6 className="color-pink" data-cy="current-time">
        {t("profile.current-time")}: {dateTimeFormat(undefined)}
      </h6>
      <FormGroup>
        <Label for="time-zone">{t("profile.time-zone")}</Label>
        <Input
          type="select"
          id="time-zone"
          bsSize="sm"
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {moment.tz.names().map(tz => (
            <option key={tz} value={tz}>
              {`${tz} (${moment.tz(tz).format("Z")})`}
            </option>
          ))}
        </Input>
      </FormGroup>
    </>
  );
});
