import type { DashboardChart } from "@Interfaces";
import { type DailySeverityCount } from "@Services";
import type {
  ChartEvent,
  ChartOptions,
  LegendElement,
  LegendItem,
  ScriptableContext,
  TooltipItem,
  TooltipLabelStyle,
} from "chart.js";
import { Chart } from "chart.js";
import { color as toColor } from "chart.js/helpers";
import moment from "moment-timezone";

const getMin = (data: DashboardChart): string => {
  const startLast90Days = moment().add(-90, "day").startOf("day");
  if (!data || !data.length) {
    return startLast90Days.toISOString();
  }
  const mFirstData = moment(data[0].date);
  const hasGap = mFirstData.diff(startLast90Days, "days") > 7;
  return hasGap ? startLast90Days.toISOString() : mFirstData.toISOString();
};

const getMax = (data: DashboardChart): string => {
  const now = moment();
  if (!data || !data.length) {
    return now.toISOString();
  }
  return data[data.length - 1].date;
};

interface StringKeyedObject {
  [key: string]: DailySeverityCount;
}

export const normalizeDataToWeeks = (data: DashboardChart): DashboardChart => {
  if (!data || !data.length) {
    return [];
  }
  let workingData = data.sort((a, b) =>
    moment(a.date).isBefore(b.date) ? -1 : 1
  );
  // check if we have more data then 90 days
  const startLast90Days = moment().add(-90, "day").startOf("day");
  const mFirstData = moment(workingData[0].date);
  const addingZeroFirst = mFirstData.isAfter(startLast90Days);
  // get the right last 90mdays from last 180 days
  if (!addingZeroFirst) {
    const index = workingData.findIndex(i =>
      moment(i.date).isAfter(startLast90Days)
    );
    workingData = workingData.slice(index);
  }

  const normData: StringKeyedObject = {};
  workingData.forEach(function (item) {
    const d = moment(item.date);
    const k = d.year() + "." + d.week();
    normData[k] = item;
  });
  let result: DashboardChart = [];
  if (addingZeroFirst) {
    result.push({
      date: mFirstData.add(-1, "day").startOf("day").toISOString(),
      severityCount: {
        high: 0,
        medium: 0,
        low: 0,
        information: 0,
      },
    });
  }
  result = result.concat(Object.values(normData));
  const lastData = result[result.length - 1];
  const now = moment();
  if (now.diff(moment(lastData.date), "hour") >= 23) {
    result.push({
      ...lastData,
      date: now.toISOString(),
    });
  }
  return result;
};

export const createGradient =
  (values: number[], maxValue: number) =>
  (context: ScriptableContext<"line">) => {
    const ctx = context.chart.ctx;
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      return;
    }
    const borderColor = context.dataset.borderColor as string;
    const color = toColor(borderColor);
    if (!maxValue) {
      return borderColor;
    }
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );

    let avg = values.reduce((a, b) => a + b, 0) / maxValue;
    if (values.length) {
      avg = avg / values.length;
    }

    gradient.addColorStop(1, color.alpha(0.5).rgbString());
    gradient.addColorStop(
      Math.max(avg - 0.1, avg / 2),
      color.alpha(0.1).rgbString()
    );
    gradient.addColorStop(Math.max(avg - 0.2, 0), "transparent");
    return gradient;
  };

export const getOptions = function (
  data: DashboardChart,
  period: string,
  isDark: boolean,
  onLegendItemClick: (chart: Chart<"line">) => void
) {
  const options: ChartOptions<"line"> = {
    transitions: {
      hide: {
        animation: {
          duration: 0,
        },
      },
      show: {
        animation: {
          duration: 0,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        borderJoinStyle: "round",
        tension: 0.2,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: isDark ? "rgba(255,255,255, 0.1)" : undefined,
        },
        ticks: {
          callback: value => (Number.isInteger(value) ? value : ""),
          color: isDark ? "whiteSmoke" : undefined,
        },
        stacked: true,
      },
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "MMM DD, YYYY",
          displayFormats: {
            month: "MMMM",
          },
        },
        ticks: {
          color: isDark ? "whiteSmoke" : undefined,
        },
        grid: {
          color: isDark ? "rgba(255,255,255, 0.1)" : undefined,
        },
        min: getMin(data),
        max: getMax(data),
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          color: isDark ? "whiteSmoke" : undefined,
          generateLabels(chart) {
            const items =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            for (const item of items) {
              item.borderRadius = 4;
              if (item.hidden) {
                item.hidden = false; // to force chart.js do not draw the strikethrough
                const asColor = toColor(item.fontColor as string);
                const fColor = isDark
                  ? asColor.darken(0.5)
                  : asColor.lighten(0.8);
                const { r, g, b, a } = fColor._rgb;
                item.fontColor = `rgba(${r}, ${g}, ${b}, ${a})`;
                item.fillStyle = item.fontColor;
                item.strokeStyle = item.fontColor;
              } else {
                item.fillStyle = item.strokeStyle;
              }
            }
            return items;
          },
        },
        onClick(
          e: ChartEvent,
          legendItem: LegendItem,
          legend: LegendElement<"line">
        ) {
          Chart.defaults.plugins.legend.onClick.apply(this, [
            e,
            legendItem,
            legend,
          ]);
          onLegendItemClick(legend.chart);
        },
      },
      tooltip: {
        mode: "index",
        position: "nearest",
        intersect: false,
        callbacks: {
          labelColor(context: TooltipItem<"line">) {
            const color = context.dataset.borderColor;
            return {
              backgroundColor: color,
              borderColor: color,
              borderWidth: 0,
            } as TooltipLabelStyle;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };
  return options;
};
