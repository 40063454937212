export function getInitials(name: string, len?: number) {
  const maxLen = 3;

  if (!name) {
    return "";
  }

  if (len && len > 2) {
    const lettereMaiuscole = name.match(/[A-Z]/g)?.join("");
    if (lettereMaiuscole?.length === len) {
      return lettereMaiuscole;
    } else {
      return name
        .toUpperCase()
        .replace(/[AEIOU]/g, "")
        .substring(0, len);
    }
  }

  const nameParts = name.split(" ");
  let initials = "";

  for (let i = 0; i < nameParts.length; i++) {
    const part = nameParts[i];
    if (part) {
      initials += part.charAt(0);
    }
  }

  if (initials.length === 1) {
    initials += name.substring(1, 2);
  }

  return initials.slice(0, maxLen);
}
