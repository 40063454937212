import { useManageApiResponse, useUrlParams } from "@Hooks";
import type {
  OrganizationAdminGetUserResponse,
  OrganizationAdminInviteRequest,
  OrganizationAdminUpdateUserRoleRequest,
  OrganizationAdminUpdateUserRequest,
} from "@Services";
import { API } from "@Services";
import { isStatusCodeSuccess } from "@Utils";
import { useCallback, useEffect, useState } from "react";

type UseOrganizationMemberProps = {
  autoFetch?: boolean;
};

type LoginType = "get" | "post" | "put" | false;

export const useOrganizationMember = (
  { autoFetch }: UseOrganizationMemberProps = { autoFetch: true }
) => {
  const [user, setUser] = useState<OrganizationAdminGetUserResponse>();
  const { organizationId, userId } = useUrlParams();
  const manageResponse = useManageApiResponse();
  const [loading, setLoading] = useState<LoginType>(() => {
    return userId && autoFetch ? "get" : false;
  });

  const fetch = useCallback(async () => {
    if (!organizationId || !userId) return false;
    setLoading("get");
    const { status } = await manageResponse({
      promise: API.user().organizationAdminGetUser(userId, organizationId),
      onSuccess: response => {
        setUser(response.data);
      },
    });
    setLoading(false);
    return status === 200;
  }, [organizationId, userId, manageResponse]);

  const updatePersonalData = useCallback(
    async (payload: OrganizationAdminUpdateUserRequest) => {
      if (!organizationId || !userId) return false;
      setLoading("put");
      const { status } = await manageResponse({
        errorMessage: "put-user",
        promise: API.user().organizationAdminUpdateUser(
          organizationId,
          userId,
          payload
        ),
      });
      setLoading(false);
      return isStatusCodeSuccess(status);
    },
    [manageResponse, organizationId, userId]
  );

  const updateRole = useCallback(
    async (payload: OrganizationAdminUpdateUserRoleRequest) => {
      if (!organizationId || !userId) return false;
      setLoading("put");
      const { status } = await manageResponse({
        errorMessage: "put-role",
        promise: API.role().organizationAdminUpdateRoleValue(
          userId,
          organizationId,
          payload
        ),
      });
      setLoading(false);
      return isStatusCodeSuccess(status);
    },
    [manageResponse, organizationId, userId]
  );

  const invite = useCallback(
    async (payload: OrganizationAdminInviteRequest) => {
      if (!organizationId) return false;
      setLoading("post");
      const { status } = await manageResponse({
        errorMessage: "invite-user",
        successMessage: "invite-user",
        promise: API.user().organizationAdminInvite(organizationId, payload),
      });
      setLoading(false);
      return isStatusCodeSuccess(status);
    },
    [organizationId, manageResponse]
  );

  useEffect(() => {
    if (autoFetch) fetch();
  }, [autoFetch, fetch]);

  return {
    user,
    loading,
    fetch,
    updatePersonalData,
    updateRole,
    invite,
  };
};
