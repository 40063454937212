import { NewProjectContext } from "@Contexts";
import type { MTLSCertificate } from "@Services";
import { useContext, useMemo } from "react";
import { useProject } from "./useProject";

const useProjectMtlsCertificates = () => {
  const { project } = useProject();
  const { project: newProject } = useContext(NewProjectContext);

  const mTLSCertificates = useMemo(() => {
    let collection: MTLSCertificate[] = [];

    if (project?.mTLSCertificates.length) {
      collection = [...project.mTLSCertificates];
    } else if (newProject.mTLSCertificates?.length) {
      collection = [...newProject.mTLSCertificates];
    }

    return collection;
  }, [newProject.mTLSCertificates, project?.mTLSCertificates]);

  return mTLSCertificates;
};

export const useGlobalMTLSCertificates = () => {
  const mTLSCertificates = useProjectMtlsCertificates();
  const result = useMemo(() => {
    return mTLSCertificates.filter(i => !i.associatedAuthSetting);
  }, [mTLSCertificates]);
  return result;
};
