import { useUrlParamsBindedString } from "@Hooks";
import { type InjectableContent } from "@Interfaces";
import { joinClasses } from "@Utils";
import { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import isEqual from "react-fast-compare";

type BreadcrumbProps = {
  name: string;
  children: InjectableContent;
  to?: string;
  disabled?: boolean;
};

export const Breadcrumb = memo(
  ({ name, to, children, disabled = false }: BreadcrumbProps) => {
    const parsedTo = useUrlParamsBindedString(to ?? "");
    const { pathname } = useLocation();
    const active = (parsedTo || "/") === pathname;
    return (
      <Link
        key={name}
        to={parsedTo ?? ""}
        data-cy={`breadcrumb-${name.split(".").pop()}`}
        className={joinClasses("h-100 m-0", (disabled || active) && "disabled")}
      >
        <button
          type="button"
          className={"menu-horizontal-element menu-horizontal-path"}
          disabled={disabled}
        >
          {children}
        </button>
      </Link>
    );
  },
  isEqual
);
