import type { ButtonProps } from "@Components";
import { useOrganizationContext, useProject } from "@Hooks";
import {
  equals,
  type AdvancedSettings,
  getDefaultAdvancedSettings,
} from "@Services";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";

const useAdvancedSettings = () => {
  const { project, loading, update } = useProject();
  const { advancedSettings } = useOrganizationContext();
  const [workingData, setWorkingData] = useState<AdvancedSettings>();

  useEffect(() => {
    setWorkingData(project?.advancedSettings);
  }, [project?.advancedSettings]);

  const formDirty =
    workingData && project?.advancedSettings
      ? !equals(workingData, project.advancedSettings)
      : false;

  const displayable = advancedSettings.enabled && !!workingData;

  const buttons = useMemo<ButtonProps[]>(() => {
    return [
      {
        children: "project.settings.reset-to-defaults",
        color: "secondary",
        size: "sm",
        disabled: !!loading,
        iconClass: "bi bi-stars",
        "data-cy": "button-reset-to-defaults",
        onClick: () => {
          if (
            project &&
            window.confirm(t(`project.settings.reset-to-defaults-confirmation`))
          ) {
            update({
              ...project,
              advancedSettings: getDefaultAdvancedSettings(),
            });
          }
        },
      },
      {
        children: "common.reset",
        color: "secondary",
        size: "sm",
        iconClass: "bi bi-arrow-counterclockwise",
        disabled: !formDirty || !!loading,
        "data-cy": "button-reset",
        onClick: () => setWorkingData(project?.advancedSettings),
      },
      {
        children: "common.save",
        color: "primary",
        size: "sm",
        disabled: !formDirty,
        loading: !!loading,
        iconClass: "bi bi-floppy",
        "data-cy": "button-save",
        onClick: async () => {
          if (project) {
            update({ ...project, advancedSettings: workingData }, true, {
              showSuccessToast: true,
            });
          }
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDirty, loading, project, update, workingData]);

  return {
    loading: loading === "get",
    hasError: !project && !loading,
    displayable,
    buttons,
    form: workingData
      ? {
          model: workingData,
          setModel: setWorkingData,
        }
      : undefined,
  };
};

export default useAdvancedSettings;
