import axios from "axios";

export const setAxiosAuth = (token: string | null) => {
  const value = token ? `Bearer ${token}` : null;
  axios.defaults.headers.common["Authorization"] = value;
};

export const hasAxiosAuth = () => {
  return !!axios.defaults.headers.common["Authorization"];
};
