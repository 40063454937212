import { memo } from "react";
import { DetailsSection, LicenseSection } from "./Components";
import { Card } from "reactstrap";
import "./style.scss";

export const NameAndLicenses = memo(() => {
  return (
    <div id="name-and-license">
      <Card className="p-3">
        <DetailsSection />
        <LicenseSection />
      </Card>
    </div>
  );
});
