import { equals, type Project } from "@Services";
import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { subset, validities } from "./functions";

const useProjectEditFormCanvas = (project: Project) => {
  const [originalFormData] = useState(() => subset(project));
  const [workingFormData, setWorkingFormData] = useState(originalFormData);
  const [formDirty, setFormDirty] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [validitiesObject, setValiditiesObject] = useState(() =>
    validities(workingFormData)
  );

  //

  useEffect(() => {
    setFormDirty(!equals(originalFormData, workingFormData));
    const validitiesObject = validities(workingFormData);
    setValiditiesObject(validitiesObject);
    setFormValid(Object.values(validitiesObject).every(valid => valid));
  }, [originalFormData, workingFormData]);

  return {
    data: {
      name: {
        value: workingFormData.name,
        setValue: (e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          setWorkingFormData({ ...workingFormData, name: newValue });
        },
        trim: () => {
          const newValue = workingFormData.name.trim();
          setWorkingFormData({ ...workingFormData, name: newValue });
        },
        invalid: !validitiesObject.name,
      },
      description: {
        value: workingFormData.description,
        setValue: (e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          setWorkingFormData({ ...workingFormData, description: newValue });
        },
        trim: () => {
          const newValue = workingFormData.description.trim();
          setWorkingFormData({ ...workingFormData, description: newValue });
        },
        invalid: !validitiesObject.description,
      },
      baseURL: {
        value: workingFormData.baseURL,
        setValue: (e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value.trim();
          setWorkingFormData({ ...workingFormData, baseURL: newValue });
        },
        invalid: !validitiesObject.baseURL,
      },
      isProductionURL: {
        value: workingFormData.isProductionURL,
        setValue: (e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.checked;
          setWorkingFormData({ ...workingFormData, isProductionURL: newValue });
        },
        invalid: !validitiesObject.isProductionURL,
      },
    },
    status: {
      dirty: formDirty,
      valid: formValid,
    },
    utils: {
      getWorkingFields: () => ({ ...workingFormData }),
      reset: () => {
        setWorkingFormData(subset(project));
        setValiditiesObject(validities(project));
      },
    },
  };
};

export default useProjectEditFormCanvas;
