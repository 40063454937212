import type { Dispatch, FC } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { FormInput } from "../../FormInput";
import { availableRoles } from "../constants";
import { useExpirationDate } from "../Hooks/useExpirationDate";
import { t } from "i18next";
import type { Options } from "@Interfaces";
import { DatePicker } from "@Components";
import type { SimpleChangeEvent } from "../UserForm.i";

type EmailRoleExpirationProps = {
  role: string;
  expiresAt: string;
  setUser: Dispatch<SimpleChangeEvent>;
  roleValid: boolean;
  disabled?: boolean;
};

const availableRoleOptions: Options = availableRoles.map(value => ({
  label: `roles.${value}`,
  value,
}));

export const RoleExpirationFields: FC<EmailRoleExpirationProps> = ({
  role,
  roleValid,
  expiresAt,
  disabled,
  setUser,
}) => {
  const {
    expiresDateEnabled,
    handleExpiresDateToggle,
    updateExpirationDate,
    isOrganizationAdmin,
  } = useExpirationDate(expiresAt, role, setUser);

  return (
    <>
      <FormGroup noMargin={isOrganizationAdmin}>
        <FormInput
          label="common.role"
          id="user-form-input-role"
          value={role}
          name="role"
          onChange={setUser}
          type="select"
          valid={roleValid}
          invalid={!roleValid}
          disabled={disabled}
          options={availableRoleOptions}
        />
      </FormGroup>
      {!isOrganizationAdmin && (
        <div>
          <FormGroup switch className="cursor-pointer">
            <Input
              type="checkbox"
              checked={expiresDateEnabled}
              id="user-form-toggle-expires-at"
              onChange={handleExpiresDateToggle}
              disabled={disabled}
            />
            <Label htmlFor="user-form-toggle-expires-at">
              {t("common.expires-at")}
            </Label>
          </FormGroup>
          <DatePicker
            onChange={updateExpirationDate}
            min={new Date()}
            id="user-form-input-expires-at"
            disabled={!expiresDateEnabled || disabled}
            value={new Date(expiresAt)}
          />
        </div>
      )}
    </>
  );
};
