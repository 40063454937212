import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { UpdateProjectData } from "@Interfaces";
import {
  API,
  runPolicyDateConverter,
  runPolicyDateDeconverter,
  type Project,
} from "@Services";
import { getProjectStatus, isStatusCodeSuccess } from "@Utils";
import { useCallback, useEffect, useState } from "react";
import type {
  FunctionUpdateProject,
  Loading,
  ProjectContextType,
} from "./ProjectContext.i";

export const useProjectContext = (): ProjectContextType => {
  const { organizationId, projectId } = useUrlParams();
  const [loading, setLoading] = useState<Loading>("get");
  const [project, setProject] = useState<Project>();
  const [notFound, setNotFound] = useState(false);
  const manageResponse = useManageApiResponse();

  useEffect(() => {
    if (!projectId) {
      setProject(undefined);
      setLoading("get");
      setNotFound(false);
    }
  }, [projectId]);

  const fetch = useCallback(async () => {
    if (!organizationId || !projectId) return false;
    setLoading("get");
    const { status } = await manageResponse({
      promise: API.project().projectGet(organizationId, projectId),
      onError: () => setProject(undefined),
      onSuccess: response => {
        const { project } = { ...response.data };
        project.runPolicy = runPolicyDateDeconverter(project.runPolicy);
        setProject(project);
      },
    });
    setNotFound(status === 404);
    setLoading(false);
    return isStatusCodeSuccess(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, projectId]);

  const update = useCallback<FunctionUpdateProject>(
    async (
      requestBody: UpdateProjectData,
      fetchOnSuccess = true,
      toastConfig
    ) => {
      if (!organizationId || !projectId) return false;
      setLoading("put");
      const { status } = await manageResponse({
        errorMessage: toastConfig?.labelKey ?? "put-project",
        statusErrorMessage: {
          "423": "project.errors.error-oas-update-due-to-pendings",
        },
        successMessage: toastConfig?.showSuccessToast
          ? toastConfig.labelKey ?? "put-project"
          : undefined,
        promise: API.project().projectUpdate(organizationId, projectId, {
          ...requestBody,
          runPolicy: runPolicyDateConverter(requestBody.runPolicy),
        }),
      });
      if (!isStatusCodeSuccess(status)) {
        setLoading(false);
        return false;
      }
      if (!fetchOnSuccess) {
        setLoading(false);
        return true;
      }
      return fetch();
    },
    [organizationId, projectId, manageResponse, fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    project,
    projectStatus: getProjectStatus(project),
    fetch,
    update,
    loading,
    notFound,
  };
};
