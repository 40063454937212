import { type FC } from "react";
import type { SectionDetailsProps } from "./UserSectionDetails.i";
import { ReinviteBox, UserBadges, UserData } from "..";
import { Button, GridContainer, Section } from "@Components";
import { EditUserCanvas } from "../EditUserCanvas";
import { DeleteUser } from "../DeleteUser/DeleteUser";
import { useUserSectionDetails } from "./useUserSectionDetails";

export const UserSectionDetails: FC<SectionDetailsProps> = ({
  user,
  fetchUser,
}) => {
  const { editingItself, canvasOpen, closeCanvas, showCanvas } =
    useUserSectionDetails();
  return (
    <GridContainer className="section-grid-container">
      <Section title="common.profile">
        <UserBadges
          creationDate={user.createdAt}
          isUserActive={user.roleActive}
          editingItself={editingItself}
          lastLogin={user.lastLogin}
        />
        <GridContainer>
          <UserData user={user} />
          <Button
            color="primary"
            children="common.edit"
            data-cy="edit-user-button"
            onClick={showCanvas}
            iconClass="bi bi-pencil-fill"
          />
        </GridContainer>
      </Section>
      <>
        {editingItself ? (
          <EditUserCanvas
            selfEditing={true}
            isOpen={canvasOpen}
            close={closeCanvas}
            user={user}
            onSubmit={fetchUser}
          />
        ) : (
          <>
            <EditUserCanvas
              selfEditing={false}
              isOpen={canvasOpen}
              close={closeCanvas}
              user={user}
              onSubmit={fetchUser}
            />
            <ReinviteBox hasLastLogin={!!user.lastLogin} />
            <DeleteUser subject={`${user.name} ${user.surname}`} />
          </>
        )}
      </>
    </GridContainer>
  );
};
