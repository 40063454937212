import type { Props } from "./IssueExclusionCard.i";
import {
  Button,
  ConfirmDialog,
  DisplaySection,
  MethodBadge,
  TrashButton,
} from "@Components";
import type { Method as MethodI } from "@Interfaces";
import { useArchivedProject } from "@Hooks";
import { memo, useState } from "react";

export const IssueExclusionCard = memo(
  ({ disabled, loadingDelete, deleteEntry, editEntry, ...issue }: Props) => {
    const isArchivedProject = useArchivedProject();
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    return (
      <div className="issue-exclusion-card editable-card">
        <div>
          <DisplaySection
            label={"common.extension-name"}
            value={issue.extensionName}
          />
          <DisplaySection
            className="mt-3"
            label={"common.path"}
            value={issue.path}
          />
          <DisplaySection
            label="common.methods"
            className="mt-3 display-methods"
            value={
              <>
                {issue.methods.map(m => (
                  <MethodBadge
                    type={m as MethodI}
                    key={m}
                    className="me-1 mb-1"
                  />
                ))}
              </>
            }
          />
          <DisplaySection
            className="mt-3"
            label={"common.false-positive"}
            value={
              issue.falsePositive ? (
                <i className="bi-check" />
              ) : (
                <i className="bi-x" />
              )
            }
          />
        </div>
        {(editEntry || deleteEntry) && (
          <div className="card-actions">
            <TrashButton
              onClick={() => setShowConfirmDialog(true)}
              loading={loadingDelete}
              disabled={disabled}
            />
            {!isArchivedProject && (
              <Button
                hiddenForReader
                type="button"
                iconClass="pencil"
                onClick={editEntry}
                disabled={disabled}
                color="primary"
              />
            )}
            {showConfirmDialog && (
              <ConfirmDialog
                title="confirmation-dialog.title.delete-configuration"
                description="confirmation-dialog.description.delete-configuration"
                cancelLabel="confirmation-dialog.cancel.delete-configuration"
                confirmLabel="confirmation-dialog.confirm.delete-configuration"
                onCancel={() => setShowConfirmDialog(false)}
                onConfirm={() => {
                  setShowConfirmDialog(false);
                  deleteEntry?.();
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
