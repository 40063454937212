import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import type { MTLSCertificateFormProps } from "../MTLSCertificatesForm.i";
import { isFilledString } from "@Services";
import { useMTLSCertificateDuplicationDetector } from "./useMTLSCertificateDuplicationDetector";

export const useMTLSCertificateForm = (props: MTLSCertificateFormProps) => {
  const {
    mtlsCertificateEntry,
    setMTLSCertificateEntry,
    setFormValid,
    globalMTLSCertificates,
    localMTLSCertificates,
  } = props;

  const handleChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setMTLSCertificateEntry(prev => ({ ...prev, [name]: value }));
    },
    [setMTLSCertificateEntry]
  );

  const duplicationError = useMTLSCertificateDuplicationDetector(
    mtlsCertificateEntry,
    localMTLSCertificates,
    globalMTLSCertificates
  );

  const validities = useMemo(() => {
    const { clientCert, clientKey, name } = mtlsCertificateEntry;
    const dangerMessage = !!(duplicationError?.severity === "danger");
    return {
      name: !dangerMessage && isFilledString(name),
      clientKey: isFilledString(clientKey),
      clientCert: isFilledString(clientCert),
    };
  }, [duplicationError?.severity, mtlsCertificateEntry]);

  useEffect(() => {
    if (!setFormValid) return;
    const valid = Object.values(validities).every(Boolean);
    setFormValid(valid);
  }, [setFormValid, validities]);

  return {
    ...props,
    setMTLSCertificateEntry: handleChange,
    validities,
    duplicationError,
  };
};
