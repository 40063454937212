import type { ApiKey } from "@Interfaces";
import { APIKEY_ROLES } from "../apikeys";

const model: ApiKey = {
  active: true,
  createdAt: "",
  expiresAt: "",
  id: "",
  name: "",
  role: APIKEY_ROLES[0].value,
};

export const getEmptyApiKey = (): ApiKey => {
  return JSON.parse(JSON.stringify(model));
};
