import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { t } from "i18next";
import type { Props } from "./UpdatePassword.i";
import { Offcanvas } from "@Components";
import useUpdatePasswordCmp from "./useUpdatePasswordCmp";
import { memo } from "react";
import { SimplePasswordInput } from "./Components";
import { PasswordInput } from "@Components";

export const UpdatePassword = memo(({ isOpen, setIsOpen }: Props) => {
  const {
    buttons,
    oldPassword,
    password,
    repeatPassword,
    closeCanvas,
    setPasswordValid,
  } = useUpdatePasswordCmp({ setIsOpen });

  return (
    <Offcanvas
      id="update-password-canvas"
      isOpen={isOpen}
      toggle={closeCanvas}
      title="profile.update-password"
      buttons={buttons}
      children={
        <Container fluid>
          <form>
            <Row>
              <Col xs={12}>
                <SimplePasswordInput
                  label="profile.old-password"
                  name="old-password"
                  value={oldPassword.value}
                  onChange={oldPassword.setValue}
                />
              </Col>
              <Col xs={12}>
                <hr className="mt-2 mb-3" />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="new-password">{t("profile.new-password")}</Label>
                  <PasswordInput
                    id="new-password"
                    autoComplete="new-password"
                    password={password.value}
                    setPassword={password.setValue}
                    setValidPassword={setPasswordValid}
                    bsSize="sm"
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <SimplePasswordInput
                  label="profile.repeat-password"
                  name="repeat-password"
                  value={repeatPassword.value}
                  onChange={repeatPassword.setValue}
                  valid={!!repeatPassword.value && repeatPassword.valid}
                  invalid={!!repeatPassword.value && !repeatPassword.valid}
                />
              </Col>
            </Row>
          </form>
        </Container>
      }
    />
  );
});
