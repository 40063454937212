import { t } from "i18next";
import { memo, useState } from "react";
import type { DisplaySectionProps } from "./DisplaySection.i";
import { LongTextOffcanvas } from "@Components";
import { joinClasses, slugify } from "@Utils";
import { DisplaySectionTooltip } from "./Components/DisplaySectionTooltip";
import { DisplaySectionContent } from "./Components/DisplaySectionContent";
import "./style.scss";

export const DisplaySection = memo(
  ({
    label,
    value,
    className = "",
    mask = false,
    tooltip,
    loading,
    title,
    truncate,
    onValueClick,
  }: DisplaySectionProps) => {
    const [showLongTextModal, setShowLongTextModal] = useState<false | string>(
      false
    );

    const truncateEnabled = !!truncate && typeof value === "string";
    const translatedLabel = t(label);
    const slugLabel = slugify(translatedLabel);

    return (
      <>
        <div
          className={joinClasses("display-section", className)}
          data-cy={`display-section-${slugLabel}`}
        >
          <p className="title text-truncate" title={translatedLabel}>
            {translatedLabel}
            <DisplaySectionTooltip tooltip={tooltip} />
          </p>
          <div
            role="textbox"
            className={joinClasses(mask && "form-control")}
            title={title}
            onClick={onValueClick}
          >
            <DisplaySectionContent
              setShowLongTextModal={setShowLongTextModal}
              truncateEnabled={truncateEnabled}
              value={value}
              loading={loading}
            />
          </div>
        </div>
        {truncateEnabled && (
          <LongTextOffcanvas
            showLongTextModal={showLongTextModal}
            setShowLongTextModal={setShowLongTextModal}
            text={value}
          />
        )}
      </>
    );
  }
);
