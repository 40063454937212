import type { AdvancedSettings } from "@Services";

const NANO_IN_SECOND = 1_000_000_000;

const ONE_SECOND = NANO_IN_SECOND;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

const model: AdvancedSettings = {
  clientTimeout: ONE_SECOND * 10,
  disableCompression: true,
  disableKeepAlives: true,
  enableDebugHeaders: true,
  exitOnCoverage: 0,
  exitOnFullCoverage: true,
  exitOnReachability: 0,
  exploitationPhaseTimeout: ONE_HOUR * 2,
  explorationPhaseTimeout: ONE_HOUR * 2,
  followRedirect: true,
  insecureSkipVerify: true,
  maxIteration: 10,
  maxRenderAttempts: 20,
  proxyUrl: "",
  userAgent: "Equixly/1.0 (API Security; ML-powered Testing)",
  requestThrottle: 0,
  requestJitterPercentage: 0,
  anonymousExploration: true,
  providerAttackStrength: "low",
  exploitOnlyResolvedNodes: true,
  includeAuthProfileOnlyForResolved: true,
  includeParametersInHash: false,
  providerMaxScanDuration: ONE_HOUR,
  authRefreshInterval: ONE_MINUTE * 25,
} as const;

export const getDefaultAdvancedSettings = () => {
  return Object.assign({}, model);
};
