import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { SpaceLinkFormProps } from "../SpaceLinkForm.i";
import moment from "moment";
import { isFilledString, isMaxEndDate, projectMaxEndDate } from "@Services";

export const useSpaceLinkForm = (props: SpaceLinkFormProps) => {
  const updateFunctions = useUpdateFunctions(props);
  const validities = useFormValidation(props);
  const expiresAtToggle = useExpiresAtFormState(props);
  const { spaceLink, disabled = false } = props;
  return {
    ...updateFunctions,
    ...expiresAtToggle,
    validities,
    spaceLink,
    disabled,
    editMode: !!spaceLink.id,
  };
};

const useExpiresAtFormState = ({
  spaceLink,
  setSpaceLink,
}: SpaceLinkFormProps) => {
  const [expiresAtChecked, setExpiresAtChecked] = useState(
    () => !isMaxEndDate(spaceLink.expiresAt)
  );
  const [previouseExpiresAt, setPreviousExpiresAt] = useState(() => {
    return expiresAtChecked
      ? spaceLink.expiresAt
      : moment().add(90, "days").format("YYYY-MM-DD");
  });

  const handleExpirationToggleChange = useCallback(
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      setExpiresAtChecked(checked);
      if (checked) {
        setSpaceLink(prev => ({ ...prev, expiresAt: previouseExpiresAt }));
      } else {
        setSpaceLink(prev => ({ ...prev, expiresAt: projectMaxEndDate }));
        if (!isMaxEndDate(spaceLink.expiresAt)) {
          setPreviousExpiresAt(spaceLink.expiresAt);
        }
      }
    },
    [previouseExpiresAt, spaceLink.expiresAt, setSpaceLink]
  );

  return { expiresAtChecked, handleExpirationToggleChange };
};

const useFormValidation = ({ spaceLink, setFormValid }: SpaceLinkFormProps) => {
  const validities = useMemo(
    () => ({
      name: isFilledString(spaceLink.name),
      expiresAt:
        moment(spaceLink.expiresAt).isValid() &&
        moment().isBefore(spaceLink.expiresAt, "date"),
    }),
    [spaceLink.expiresAt, spaceLink.name]
  );

  useEffect(() => {
    setFormValid?.(Object.values(validities).every(Boolean));
  }, [setFormValid, validities]);

  return validities;
};

const useUpdateFunctions = ({
  spaceLink,
  setSpaceLink,
}: SpaceLinkFormProps) => {
  const handleFieldUpdate = useCallback(
    ({ target: { name, value, checked } }: ChangeEvent<HTMLInputElement>) => {
      switch (name as keyof typeof spaceLink) {
        case "name":
          setSpaceLink(prev => ({ ...prev, [name]: value }));
          break;
        case "active":
        case "connected":
          setSpaceLink(prev => ({ ...prev, [name]: checked }));
          break;
        case "id":
        case "createdAt":
        case "expiresAt":
        default:
        // do nothing
      }
    },
    [setSpaceLink]
  );

  const handleExpiresAtUpdate = useCallback(
    (date: Date) => {
      setSpaceLink(prev => ({
        ...prev,
        expiresAt: moment(date).format("YYYY-MM-DD"),
      }));
    },
    [setSpaceLink]
  );

  return {
    handleExpiresAtUpdate,
    handleFieldUpdate,
  };
};
