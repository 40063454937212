import type { AuthenticationSettings } from "@Services";
import { DisplayTable, MethodBadge } from "@Components";
import type { Method as MethodType } from "@Interfaces";
import { t } from "i18next";
import { memo } from "react";

export const EndpointsTab = memo(
  ({ inputOperations }: AuthenticationSettings) => {
    return (
      <>
        {Object.values(inputOperations ?? {}).map(({ methods, path }, i) => {
          return (
            <div key={path} className={i ? "mt-2" : undefined}>
              <DisplayTable>
                <tbody>
                  <tr>
                    <td>{t("common.path")}</td>
                    <td>{path}</td>
                  </tr>
                  <tr>
                    <td className="align-middle">{t("common.methods")}</td>
                    <td className="opacity-100">
                      {methods.map(method => (
                        <MethodBadge
                          key={method}
                          type={method as MethodType}
                          className="me-2"
                        />
                      ))}
                    </td>
                  </tr>
                </tbody>
              </DisplayTable>
            </div>
          );
        })}
      </>
    );
  }
);
