import { Button, Meta, NoAuthPage } from "@Components";
import { pureComponent } from "@Utils";
import { t } from "i18next";
import type { FC } from "react";
import { memo } from "react";
import type { ErrorPageProps } from "./ErrorPage.i";

export const ErrorPage: FC<ErrorPageProps> = memo(props => {
  const handleOnClik = () => (window.location.href = "/");
  const errorKey = props.error?.message || "500";
  const { title, error, description } = getPageData(errorKey);
  return (
    <>
      <Meta pageName="500 Internal server error" />
      <NoAuthPage>
        <>
          <h1 className="text-center">{title}</h1>
          <h4 className="text-center text-danger">{error}</h4>
          <p className="text-center text-danger">{description}</p>
        </>
        <div className="mt-4">
          <Button
            color="primary"
            children="common.back-home"
            className="w-100"
            iconClass="bi bi-arrow-left-square-fill"
            onClick={handleOnClik}
          />
        </div>
      </NoAuthPage>
    </>
  );
}, pureComponent);

const getPageData = (key: string) => ({
  title: t([`error-page.${key}.title`, "error-page.500.title"]),
  error: t([`error-page.${key}.error`, "error-page.500.error"]),
  description: t([
    `error-page.${key}.description`,
    "error-page.500.description",
  ]),
});
