export const initSentry = () => {
  const SENTRY_DSN = process.env.SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN;
  const isProd = (process.env.NODE_ENV ?? "") === "production";

  const environment =
    process.env.ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT || "";

  if (SENTRY_DSN) {
    import("@sentry/react").then(Sentry => {
      Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: isProd ? 0.1 : 0.2,
        environment,
        replaysSessionSampleRate: isProd ? 0.1 : undefined,
        replaysOnErrorSampleRate: isProd ? 1.0 : undefined,
        integrations: isProd
          ? [
              Sentry.browserTracingIntegration(),
              Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
              }),
            ]
          : undefined,
      });
    });
  }
};
