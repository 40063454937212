import { PROJECT_DETAILS_SCAN_URL } from "@Constants";
import { useUpdateCacheFilters, useUrlParams } from "@Hooks";
import type { Setter } from "@Interfaces";
import {
  getDefaultFilters,
  mapHeaderItemsWithSorts,
  type ListedScan,
} from "@Services";
import { generateUrl } from "@Utils";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCardItemOrder,
  getConfig,
  getFullRowIndexes,
  getHeaders,
} from "./Functions";
import type { Props } from "./ScansTable.i";

const useScansTable = ({ forProject, scans, loading }: Props) => {
  const { organizationId } = useUrlParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const config = useMemo(() => {
    if (!scans.length) return [];
    let onClick: Setter<ListedScan> = () => undefined;
    if (organizationId) {
      onClick = (scan: ListedScan) => {
        const { projectID, id: scanID } = scan;
        const url = generateUrl(PROJECT_DETAILS_SCAN_URL, [
          organizationId,
          projectID,
          scanID,
        ]);
        navigate(url);
      };
    }
    return getConfig({
      scans,
      forProject,
      onClick,
    });
  }, [scans, forProject, organizationId, navigate]);

  const tablePros = useMemo(
    () => ({
      cardOrder: getCardItemOrder(forProject),
      fullRowIndexes: getFullRowIndexes(forProject),
    }),
    [forProject]
  );

  const cacheKey = forProject ? "project" : "organization";
  const updateFunction = useUpdateCacheFilters(`${cacheKey}.scans`);
  const defaultSorts = useMemo(() => getDefaultFilters("scans"), []);

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      items: getHeaders(forProject),
      search,
      updateFunction,
      defaults: defaultSorts,
    });
  }, [defaultSorts, forProject, search, updateFunction]);

  return {
    loading,
    config,
    headers,
    defaultSorts,
    setSorts: updateFunction,
    ...tablePros,
  };
};

export default useScansTable;
