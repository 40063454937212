import { CardDetail } from "@Components";
import { dateTimeFormat, equals } from "@Services";
import { t } from "i18next";
import type { FC } from "react";
import { memo } from "react";
import type { UserBadgesProps } from "./UserBadges.i";

export const UserBadges: FC<UserBadgesProps> = memo(cardsProps => {
  return (
    <div className="user-badges">
      <LastLoginCard {...cardsProps} />
      <IsActiveUserCard {...cardsProps} />
      <CreatedAtCard {...cardsProps} />
    </div>
  );
}, equals);

const LastLoginCard: FC<UserBadgesProps> = ({ lastLogin }) => {
  return (
    <CardDetail icon="file-break-fill" title="common.last-login">
      {dateTimeFormat(lastLogin, { trimTime: true })}
    </CardDetail>
  );
};

const IsActiveUserCard: FC<UserBadgesProps> = ({
  isUserActive,
  editingItself,
}) => {
  if (editingItself) return null;
  const children = isUserActive ? t("common.active") : t("common.disabled");
  const icon = isUserActive ? "check-circle" : "plus-circle";
  return (
    <CardDetail icon={icon} title="common.status">
      {children}
    </CardDetail>
  );
};

const CreatedAtCard: FC<UserBadgesProps> = ({ creationDate }) => {
  return (
    <CardDetail icon="calendar-check" title="common.created-at">
      {dateTimeFormat(creationDate, { trimTime: true })}
    </CardDetail>
  );
};
