import {
  Breadcrumb,
  ProjectAPIDefinitionsBreadcrumb,
  ProjectAPIDefinitionsInventoryBreadcrumb,
} from "@Components";
import { APIDefinition, APIDefinitionDetails, APIDefinitions } from "@Pages";
import { t } from "i18next";

export const ProjectApiDefinitionsRoutes = [
  {
    path: "",
    handle: {
      crumb: <ProjectAPIDefinitionsBreadcrumb />,
    },
    children: [
      {
        path: "",
        element: <APIDefinitions />,
      },
      {
        path: ":apiDefinition",
        children: [
          {
            path: "inventory",
            children: [
              {
                path: "",
                element: <APIDefinition />,
                handle: {
                  crumb: <ProjectAPIDefinitionsInventoryBreadcrumb disabled />,
                },
              },
              {
                path: ":inventoryId",
                element: <APIDefinitionDetails />,
                handle: {
                  crumb: [
                    <ProjectAPIDefinitionsInventoryBreadcrumb />,
                    <Breadcrumb
                      disabled
                      name={"project-api-definition-inventory-details"}
                    >
                      {t("common.details")}
                    </Breadcrumb>,
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
