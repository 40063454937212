import type { FC } from "react";
import { memo } from "react";
import type { FileDetailProps } from "./FileDetails.i";
import { Col, Container, Row } from "reactstrap";
import { PathInfo } from "../PathInfo";
import { DisplaySection } from "@Components";
import { t } from "i18next";
import { equals } from "@Services";

export const FileDetails: FC<FileDetailProps> = memo(
  ({ fileOAS, project, projextExtras, setProject }) => {
    const isValid = !fileOAS.error;
    return (
      <Container fluid id="file-details" className="card">
        <Row className="justify-content-center">
          <Col>
            {!isValid && (
              <Row>
                <Col>
                  <DisplaySection
                    label="project.api-definition.error-type"
                    value={`${t(fileOAS.error)}`}
                  />
                </Col>
              </Row>
            )}
            {isValid && (
              <Row id="oas-data-wrapper" className="flex-column flex-xxl-row ">
                <Col className="mb-4 mb-xxl-0">
                  <Row className="px-3">
                    <Col xs={12}>
                      <DisplaySection
                        label="common.title"
                        value={fileOAS.title}
                      />
                    </Col>
                    <Col xs={"auto"} xxl={12} className="flex-grow-1">
                      <DisplaySection
                        label="common.endpoints"
                        value={`${fileOAS.endpoints}`}
                      />
                    </Col>
                    <Col xs={"auto"} xxl={12} className="flex-grow-1">
                      <DisplaySection
                        label="common.version"
                        value={fileOAS.version}
                      />
                    </Col>
                    <Col xs={"auto"} xxl={12} className="flex-grow-1">
                      <DisplaySection
                        label="common.format"
                        value={fileOAS.format}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <PathInfo
                      project={project}
                      setProject={setProject}
                      projectExtras={projextExtras}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    );
  },
  equals
);
