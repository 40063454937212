import { joinClasses } from "@Utils";
import type { GridContainerProps } from "../../";
import type { CSSProperties } from "react";
import { clamp } from "@Services";

export const GridContainer = ({
  children,
  className: propsClassName,
  columns = 0,
  rows = 0,
  ...other
}: GridContainerProps) => {
  let styleProps: CSSProperties | undefined = undefined;

  const numberOfColumns = clamp(columns, 0, 12);
  const numberOfRows = clamp(rows, 0, Number.MAX_SAFE_INTEGER);

  if (numberOfColumns) {
    styleProps = styleProps ?? {};
    styleProps.gridTemplateColumns = `repeat(${numberOfColumns}, minmax(0, 1fr))`;
  }

  if (numberOfRows) {
    styleProps = styleProps ?? {};
    styleProps.gridTemplateRows = `repeat(${numberOfRows}, min-content)`;
  }

  const className = joinClasses(propsClassName, "grid-container");

  return (
    <div className={className} style={styleProps} {...other}>
      {children}
    </div>
  );
};
