import type { ListedProject, Project } from "@Services";

type ProjectData = Project | ListedProject;

export const getProjectStatus = (project?: ProjectData): string => {
  if (!project) {
    return "pending";
  }

  if (project.archivedAt) {
    return "archived";
  }

  return project.status;
};
