import { setPreference } from "@Services";
import { useCallback } from "react";

export const useResetCachedData = () => {
  const resetCachedData = useCallback(() => {
    // clear any stored preference that is related ot user login, not real preferences like themes or app zoom
    setPreference("organization-id", null);
    window.location.reload();
  }, []);
  return { resetCachedData };
};
