import { useContext, useState } from "react";
import { AuthContext } from "@Contexts";
import { useRole } from "@Hooks";

export const useProfileCard = () => {
  const { user, setUser } = useContext(AuthContext);
  const [showEditModal, setShowEditModal] = useState(false);
  const role = useRole();

  return {
    user: {
      model: user,
      setValue: setUser,
      role,
    },
    canvas: {
      isOpen: showEditModal,
      actions: {
        open: () => setShowEditModal(true),
        close: () => setShowEditModal(false),
      },
    },
  };
};
