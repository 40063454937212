import type { Dispatch, FC } from "react";
import { Switch } from "@Components";
import type { SimpleChangeEvent } from "../UserForm.i";

type EmailRoleExpirationProps = {
  active: boolean;
  setUser: Dispatch<SimpleChangeEvent>;
  disabled?: boolean;
};

export const ActiveField: FC<EmailRoleExpirationProps> = ({
  active,
  disabled,
  setUser,
}) => (
  <Switch
    checked={active}
    id="user-form-input-active"
    name="active"
    disabled={disabled}
    onChange={setUser}
    title="common.active"
  />
);
