import { memo } from "react";
import { t } from "i18next";
import { InfoBadge } from "../InfoBadge/InfoBadge";
import type { VulnerabilityType } from "@Interfaces";
import type { Props } from "./Vulnerabilities.i";

const severityOrder: { [key in VulnerabilityType]: number } = {
  high: 0,
  medium: 1,
  low: 2,
  information: 3,
};

type VulnerabilityEntry = [VulnerabilityType, number];

const Vulnerabilities = memo((props: Props) => {
  const vulnerabilityEntries = Object.entries(props) as VulnerabilityEntry[];
  const filteredEntries = vulnerabilityEntries.filter(([, count]) => count > 0);
  const hasVulnerabilities = filteredEntries.length > 0;

  return (
    <>
      {hasVulnerabilities ? (
        filteredEntries
          .sort((a, b) => severityOrder[a[0]] - severityOrder[b[0]])
          .map(([severity, count]) => (
            <InfoBadge
              key={severity}
              className="me-2"
              bgColor={`severity-${severity}`}
              type="severity"
            >
              <small>
                <b>{count}</b>&nbsp;
                <span>{t(`common.${severity}`)}</span>
              </small>
            </InfoBadge>
          ))
      ) : (
        <span className="mt-1" data-role="no-vulnerability-message">
          -
        </span>
      )}
    </>
  );
});

export { Vulnerabilities };
