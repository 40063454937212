import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { config } from "@Services";
import {
  delay,
  generateRandomToken,
  findActiveRolesByUserId,
  getAuthUserContext,
  getServiceUser,
  getUserByEmail,
} from "src/Mock";

const otpRequiredUsers = ["send-otp"];
const otpSetupRequiredUsers = ["setup-otp"];

export const login = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { email } = (await req.json()) as {
    email: string;
  };

  if (!email) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  let status = "";
  let user = getUserByEmail(email);
  const context = getAuthUserContext();
  context.reset();

  if (email === "setup-otp" || email === "send-otp") {
    // special users -> use service user instance
    user = getServiceUser();
  } else if (
    user === undefined ||
    findActiveRolesByUserId(user.id).length === 0
  ) {
    // unrelated email -> ERROR
    return res(ctx.delay(delay), ctx.status(400));
  }

  if (otpRequiredUsers.includes(email)) {
    // those users must provide an OTP
    status = config["otp"]["OTP_REQUIRED"];
    context.setLoginPhase("otp");
  } else if (otpSetupRequiredUsers.includes(email)) {
    // those users must enable the OTP control
    status = config["otp"]["OTP_SETUP_REQUIRED"];
    context.setLoginPhase("otp");
  } else {
    // valid user with no-otp
    status = config["otp"]["SUCCESS"];
    context.setLoginPhase("authenticated");
  }

  context.setUser(user);

  return res(
    ctx.delay(delay),
    ctx.json({
      status,
      token: generateRandomToken(),
    })
  );
};
