import type { Log, Logs, PaginateResult } from "@Interfaces";
import type { AxiosPromise } from "axios";
import { useCallback, useState } from "react";
import { useManageApiResponse } from "../http";
import { useUrlParams } from "../other";
import { isStatusCodeSuccess } from "@Utils";

type FetchCallback = AxiosPromise<PaginateResult<Log>>;

export const useOrganizationAuditLogs = () => {
  const [logs, setLogs] = useState<Logs>();
  const [loading, setLoading] = useState(false);

  const { organizationId } = useUrlParams();

  const manage = useManageApiResponse(setLoading);

  const fetch = useCallback(
    async (callback: FetchCallback, showToast = false) => {
      if (!organizationId) throw new Error("missing-organization-id");
      setLogs(undefined);
      const { status } = await manage({
        promise: callback,
        errorMessage: showToast ? "get-logs" : undefined,
        onSuccess: response => setLogs(response.data.data),
      });
      return isStatusCodeSuccess(status);
    },
    [manage, organizationId]
  );

  return {
    logs,
    loading,
    fetch,
  };
};
