import { AuthenticationSettingsForm } from "@Components";
import { pureComponent } from "@Utils";
import { memo } from "react";
import { useProjectAuthenticationCanvas } from "../Hooks";

export const AuthenticationOffCanvas = memo(() => {
  const { showCanvas, model, invalidNames, actions, loading } =
    useProjectAuthenticationCanvas();

  if (!model || !model.value) {
    return null;
  }

  return (
    <AuthenticationSettingsForm
      authenticationSettings={model.value.authenticationSettings}
      injectables={model.value.injectables}
      mTLSCertificates={model.value.mTLSCertificates}
      show={showCanvas}
      setShow={actions.closeCanvas}
      invalidAuthenticationSettingsNames={invalidNames}
      loading={loading}
      onSubmit={actions.handleFormSubmit}
    />
  );
}, pureComponent);
