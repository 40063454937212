import type { ExtensionSettings } from "src/Services/client";

export const isValidExtensionSettings = (
  tester: ExtensionSettings | undefined
) => {
  let isValid = tester !== undefined;

  if (tester && isValid) {
    // check [enabledCategories]
    const { enabledCategories } = tester;
    isValid = enabledCategories.length > 0;
  }

  return isValid;
};
