import { Button, Callout, FormInput, Offcanvas } from "@Components";
import type { Props } from "./DownloadCanvas.i";
import { t } from "i18next";
import { useDownloadScanReportCanvas } from "./useDownloadScanReportCanvas";

export const DownloadScanReportCanvas = (props: Props) => {
  const { canvasProps, ...data } = useDownloadScanReportCanvas(props);
  return (
    <Offcanvas
      {...canvasProps}
      children={
        <>
          <Button
            color="primary"
            onClick={data.getScanReport}
            children={t("scans.generate-report", {
              format: props.format.toUpperCase(),
            })}
            iconClass="download"
            className="w-100 p-3"
            loading={data.loading}
          />
          {!!data.scanReport && (
            <div className="mt-3">
              <Callout level="warning">
                {t("scans.download-report-warning")}
              </Callout>
              <div className="mt-3">
                <FormInput
                  type="password"
                  label={`ZIP ${t(`common.password`)}`}
                  value={data.scanReport.password}
                  id="input-zip-password"
                  data-cy="input-zip-password"
                  readOnly
                  canBeCopy
                  canBeSeen
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
};
