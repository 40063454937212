import { DownloadJsonButton, SettingsContainer } from "@Components";
import { equals, isLocalHost } from "@Services";
import type { FC } from "react";
import { memo, useEffect, useMemo, useRef } from "react";
import { FullAccessMode, ReadOnlyMode } from "./Components";
import { useProjectSettings } from "./useProjectSettings";
import type { Nullable } from "@Interfaces";
import { pureComponent } from "@Utils";
import hljs from "highlight.js";
import { useProject } from "@Hooks";

export const ProjectGeneral = memo(() => {
  const {
    canvasController,
    dangerZoneController,
    license,
    loadingStates,
    project,
    projectUpdate,
    isRoleReader,
    noData,
  } = useProjectSettings();

  const children = useMemo(() => {
    if (!project || !license) return null;
    if (isRoleReader) return <ReadOnlyMode project={project} />;
    return (
      <FullAccessMode
        project={project}
        setProject={projectUpdate}
        license={license}
        canvasController={canvasController}
        dangerZoneController={dangerZoneController}
      />
    );
  }, [
    canvasController,
    dangerZoneController,
    license,
    project,
    projectUpdate,
    isRoleReader,
  ]);

  return (
    <>
      <div id="project-generals">
        <SettingsContainer
          loading={loadingStates.get}
          section="settings"
          hasError={noData}
        >
          {children}
        </SettingsContainer>
      </div>
      <DownloadJsonButton data={project} />
      <DataViewer />
    </>
  );
}, equals);

export const DataViewer: FC = memo(() => {
  const { project } = useProject();
  const preRef = useRef<Nullable<HTMLPreElement>>(null);

  useEffect(() => {
    if (!project || !isLocalHost()) {
      return;
    }

    if (!preRef.current) {
      return;
    }

    preRef.current.innerHTML = hljs.highlight(
      JSON.stringify(project, null, 2),
      {
        language: "json",
      }
    ).value;
  }, [project]);

  if (!project || !isLocalHost()) {
    return null;
  }
  return (
    <pre
      className="my-4 color-white p-4 scrollbar-styled"
      style={{ minHeight: "20rem", backgroundColor: "black" }}
      ref={preRef}
    />
  );
}, pureComponent);
