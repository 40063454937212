import { Table, type TableProps } from "reactstrap";

interface Props extends TableProps {
  wrap?: boolean;
  wrapperClassName?: string;
}

export const DisplayTable = ({
  wrap = true,
  className = "",
  wrapperClassName = "",
  ...props
}: Props) => {
  const children = (
    <Table {...props} className={`display-table ${className}`.trimEnd()} />
  );
  return wrap ? (
    <div className={`display-table-wrapper ${wrapperClassName}`.trimEnd()}>
      {children}
    </div>
  ) : (
    children
  );
};
