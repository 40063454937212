import type { Injectable } from "@Services";
import { generateRandomUUID } from "../other";

const samples: Injectable[] = [
  {
    location: "header",
    name: "X-Session",
    value: generateRandomUUID().replaceAll("-", ""),
    associatedAuthSetting: "",
  },
  {
    location: "cookie",
    name: "user_session",
    value: `${generateRandomUUID()}${generateRandomUUID()}`.replaceAll("-", ""),
    associatedAuthSetting: "",
  },
  {
    location: "cookie",
    name: "user_session",
    value: `${generateRandomUUID()}${generateRandomUUID()}`.replaceAll("-", ""),
    associatedAuthSetting: "API JWT",
  },
];

export const generateRandomInjectables = (): Injectable[] => {
  return structuredClone(samples);
};
