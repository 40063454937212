import { ListItems } from "@Components";
import type { FC } from "react";
import { memo } from "react";
import isEqual from "react-fast-compare";
import { defaultSorts } from "../../constants";
import { useSpaceLinkTable } from "./Hooks/useSpaceLinkTable";
import type { SpaceLinkTableProps } from "./SpaceLinkTable.i";

const updateCardItemOrder = [0, 3, 2, 1, 4, 5];

export const SpaceLinkTable: FC<SpaceLinkTableProps> = memo(props => {
  const { config, headers, loading, updateFiltersFunction } =
    useSpaceLinkTable(props);
  return (
    <div id="space-links-table">
      <ListItems
        config={config}
        defaultSorts={defaultSorts}
        headers={headers}
        setSorts={updateFiltersFunction}
        showSkeleton={loading}
        cardVersion={false}
        updateCardItemOrder={updateCardItemOrder}
      />
    </div>
  );
}, isEqual);
