import { Accordion } from "@Components";
import { InjectablesTab } from "./InjectablesTab/InjectablesTab";
import { MTLSCertificatesTab } from "./MTLSCertificateTab/MTLSCertificatesTab";
import { memo } from "react";
import { pureComponent } from "@Utils";
import { t } from "i18next";
import { useAuthenticationSettingFormStore } from "@Stores";

export const InjectableAndCertificateAccordion = memo(() => {
  const { injectables, mTLSCertificates } = useAuthenticationSettingFormStore();

  const injectableHeader = `${t("common.injectable-parameters")} (${injectables.length})`;
  const mTLSCertificateHeader = `${t("common.mtls-certificates")} (${mTLSCertificates.length})`;

  return (
    <Accordion
      data={[
        {
          children: <InjectablesTab />,
          header: injectableHeader,
          key: "injectable-parameters",
        },
        {
          children: <MTLSCertificatesTab />,
          header: mTLSCertificateHeader,
          key: "mtls-certificates",
        },
      ]}
    />
  );
}, pureComponent);
