import { memo, useMemo } from "react";
import isEqual from "react-fast-compare";
import {
  IssueDetailsApiCall,
  IssueDetailsMainInfo,
  IssueDetailsNotFound,
  IssueExclusionEditCanvas,
} from "./Components";
import { useIssueDetails, useIssueDetailsTab } from "./Hooks";

import "./IssuesDetail.style.scss";
import { Tabs } from "@Components";

export const IssuesDetail = memo(() => {
  const { details, notFound } = useIssueDetails();
  const { tabs, activeTab, setActiveTab } = useIssueDetailsTab();

  const content = useMemo(() => {
    switch (activeTab) {
      case 1:
        return <IssueDetailsApiCall />;
      default:
        return <IssueDetailsMainInfo />;
    }
  }, [activeTab]);

  if (notFound) {
    return <IssueDetailsNotFound />;
  }

  if (!details) {
    return null;
  }

  return (
    <div id="issue-details">
      <Tabs activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
      <div id="issue-details__content" className="card">
        {content}
      </div>
      <IssueExclusionEditCanvas />
    </div>
  );
}, isEqual);
