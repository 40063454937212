import type { KeyAction } from "@Constants";
import {
  ALT_KEY,
  CTRL_KEY,
  type KeyboardCapture,
  type OnKeyPressCallback,
} from "@Constants";
import { useEffect, type Dispatch } from "react";

type UseKeyboardDownEventProps = {
  action?: KeyAction;
  capture?: KeyboardCapture;
  onKeyPress?: OnKeyPressCallback;
  onKeyDown?: Dispatch<KeyboardEvent>;
  onKeyUp?: Dispatch<KeyboardEvent>;
};

type KeyboardStateCapture = {
  action: KeyAction;
  press?: OnKeyPressCallback;
  up?: Dispatch<KeyboardEvent>;
  down?: Dispatch<KeyboardEvent>;
  capture: KeyboardCapture;
};

const keyState: Map<string, KeyboardStateCapture> = new Map();

const canCaptureKey = () => {
  const { activeElement } = document;
  if (!activeElement) {
    return true;
  }

  const tagName = activeElement.tagName.toLowerCase();
  const isEditable = (activeElement as HTMLElement).isContentEditable;
  return (
    tagName !== "input" &&
    tagName !== "textarea" &&
    tagName !== "select" &&
    !isEditable
  );
};

const handleGlobalKeyDown = (event: KeyboardEvent) => {
  if (!canCaptureKey()) {
    return;
  }

  const { ctrlKey, metaKey, altKey, shiftKey, key } = event;
  const osAgnosticCtrl = ctrlKey || metaKey;

  if (!key) {
    return;
  }

  const eventKey = `${osAgnosticCtrl ? `${CTRL_KEY}-` : ""}${altKey ? `${ALT_KEY}-` : ""}${shiftKey ? "shift-" : ""}${key.toLowerCase()}`;
  const callback = getCallback(eventKey);

  if (callback) {
    if (callback.capture.down) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    callback.press?.(true, event);
    callback.down?.(event);
  }
};

const handleGlobalKeyUp = (event: KeyboardEvent) => {
  if (!canCaptureKey()) {
    return;
  }

  const { ctrlKey, metaKey, altKey, shiftKey, key } = event;
  const osAgnosticCtrl = ctrlKey || metaKey;

  if (!key) {
    return;
  }

  const eventKey = `${osAgnosticCtrl ? `${CTRL_KEY}-` : ""}${altKey ? `${ALT_KEY}-` : ""}${shiftKey ? "shift-" : ""}${key.toLowerCase()}`;
  const callback = getCallback(eventKey);

  if (callback) {
    if (callback.capture.up) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    callback.press?.(false, event);
    callback.up?.(event);
  }
};

const handleVisibilityChange = () => {
  if (!document.hidden) {
    document.body.focus(); // this is needed for keyboard shortcuts;
  }
};

export const useKeyboardEvent = ({
  action,
  capture = {
    down: true,
    up: true,
  },
  onKeyPress,
  onKeyDown,
  onKeyUp,
}: UseKeyboardDownEventProps) => {
  useEffect(() => {
    if (!action) {
      return;
    }

    if (keyState.values.length === 0) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("keydown", handleGlobalKeyDown);
      window.addEventListener("keyup", handleGlobalKeyUp);
    }

    const eventKey = [action.getPrefix(), action.getKeyEventString()]
      .filter(e => !!e)
      .join(".");

    keyState.set(eventKey, {
      press: onKeyPress,
      down: onKeyDown,
      up: onKeyUp,
      action,
      capture: {
        up: true,
        down: true,
        ...capture,
      },
    });

    return () => {
      keyState.delete(eventKey);
    };
  }, [action, capture, onKeyDown, onKeyPress, onKeyUp]);
};
const getCallback = (eventKey: string): KeyboardStateCapture => {
  let result: Array<KeyboardStateCapture> = [];
  keyState.forEach((value, key) => {
    if (value.action.isEventKey(eventKey)) {
      result.push(value);
    }
  });

  result = result.sort((a, b) => (a.action.hasPriorityOver(b.action) ? -1 : 1));

  return result[0];
};
