import { Offcanvas, PathExclusionForm } from "@Components";
import type { Method } from "@Interfaces";
import { useFormCanvas } from "./Hooks";
import type { PathExclusionFormCanvasProps } from "./PathExclusion.i";

export const PathExclusionFormCanvas = (
  props: PathExclusionFormCanvasProps
) => {
  const { canvas, saveButton, entry, setEntry, validities } =
    useFormCanvas(props);

  return (
    <Offcanvas
      id="path-exclusion-form-canvas"
      isOpen={canvas.isOpen}
      toggle={canvas.close}
      title="common.path-exclusion"
      buttons={[saveButton.props]}
      className={canvas.loading ? "loading" : undefined}
      children={
        <PathExclusionForm
          path={entry.path}
          activeMethods={entry.methods as Method[]}
          setPath={path =>
            setEntry({
              ...entry,
              path,
            })
          }
          validities={validities}
          setActiveMethods={methods =>
            setEntry({
              ...entry,
              methods,
            })
          }
          gridColumns={1}
        />
      }
    />
  );
};
