import type { Dispatch, FC } from "react";
import { FormInput } from "../../FormInput";
import type { SimpleChangeEvent } from "../UserForm.i";

type EmailFieldProps = {
  email: string;
  setUser: Dispatch<SimpleChangeEvent>;
  emailValid: boolean;
  disabled?: boolean;
};

export const EmailField: FC<EmailFieldProps> = ({
  email,
  emailValid,
  disabled,
  setUser,
}) => (
  <FormInput
    label="common.email"
    id="user-form-input-email"
    value={email}
    name="email"
    onChange={setUser}
    type="text"
    valid={emailValid}
    invalid={!emailValid}
    disabled={disabled}
    autoComplete="off"
    autoTrim
  />
);
