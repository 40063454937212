import type { Nullable } from "@Interfaces";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";
import type {
  AuthenticationFormMode,
  AuthenticationSettingsFormModel,
  CanvasFlow,
} from "./Authentication.i";

type FormValue = Nullable<{
  value: AuthenticationSettingsFormModel;
  index: number;
}>;
export type AuthenticationStoreProperties = {
  formValue: FormValue;
  authenticationSettings: Array<AuthenticationSettingsFormModel>;
  canvasFlow: CanvasFlow;
  canvasMode: AuthenticationFormMode;
  loading: boolean;
};

export type AuthenticationStore = AuthenticationStoreProperties & {
  setFormValue: Dispatch<FormValue>;
  setAuthenticationSettings: Dispatch<Array<AuthenticationSettingsFormModel>>;
  addAuthenticationSetting: Dispatch<AuthenticationSettingsFormModel>;
  updateAuthenticationSetting: (
    value: AuthenticationSettingsFormModel,
    index: number
  ) => void;
  removeAuthenticationSettings: Dispatch<number>;
  setCanvasFlow: Dispatch<CanvasFlow>;
  setCanvasMode: Dispatch<AuthenticationFormMode>;
  setLoading: Dispatch<boolean>;
  resetCanvas: VoidFunction;
  reset: VoidFunction;
};

const initialState: AuthenticationStoreProperties = {
  formValue: null,
  authenticationSettings: [],
  canvasFlow: null,
  canvasMode: null,
  loading: true,
};

export const useAuthenticationStore = createStore<AuthenticationStore>(
  (get, set) => ({
    ...initialState,
    setFormValue: formValue => set(state => ({ ...state, formValue })),
    setAuthenticationSettings: authenticationSettings =>
      set(state => ({
        ...state,
        authenticationSettings,
      })),
    addAuthenticationSetting: setting =>
      set(state => ({
        ...state,
        authenticationSettings: [...state.authenticationSettings, setting],
      })),
    removeAuthenticationSettings: index =>
      set(state => {
        const nextSettings = state.authenticationSettings;
        nextSettings.splice(index, 1);
        return {
          ...state,
          authenticationSettings: nextSettings,
        };
      }),
    updateAuthenticationSetting: (
      value: AuthenticationSettingsFormModel,
      index: number
    ) =>
      set(state => {
        const nextAuthenticationSettings = [...state.authenticationSettings];
        nextAuthenticationSettings[index] = value;

        return {
          ...state,
          authenticationSettings: nextAuthenticationSettings,
        };
      }, true),
    setCanvasFlow: canvasFlow => set(state => ({ ...state, canvasFlow })),
    setCanvasMode: canvasMode => set(state => ({ ...state, canvasMode })),
    setLoading: loading => set(state => ({ ...state, loading })),
    resetCanvas: () =>
      set(state => ({ ...state, formValue: null, canvasFlow: null })),
    reset: () => set(state => ({ ...state, ...initialState })),
  })
);
