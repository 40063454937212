/* eslint-disable @typescript-eslint/no-var-requires */
import type { SwaggerClientResponse } from "@Interfaces";

type ResultType = {
  spec: SwaggerClientResponse | undefined;
  hasExternalReferences: boolean | undefined;
};

export const getSwaggerClient = async (props: {
  spec: any;
}): Promise<ResultType> => {
  const SwaggerClient = require("swagger-client").default;

  let hasExternalReferences = false;

  const options = {
    fetch: async () => {
      // prevent any HTTP call for references resolutions returning any empty object
      // this force the compiler to think that the request has been unsuccessfull
      hasExternalReferences = true;
      return {};
    },
  };

  try {
    const spec = await SwaggerClient.resolve({
      spec: props.spec,
      ...options,
    });
    return {
      spec,
      hasExternalReferences,
    };
  } catch (e: any) {
    return {
      spec: undefined,
      hasExternalReferences: undefined,
    };
  }
};
