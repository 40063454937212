import { ThemeContext } from "@Contexts";
import { BEM } from "@Utils";
import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  ReactFlow,
  SelectionMode,
} from "@xyflow/react";
import { memo, useContext } from "react";
import isEqual from "react-fast-compare";
import { useIssueDetailsApiCall } from "./Hooks";
import { ApiCallNode } from "./Components";
import { HTTPViewer } from "@Components";
import { t } from "i18next";

const NODE_TYPES = { default: ApiCallNode };

export const IssueDetailsApiCall = memo(() => {
  const { nodes, edges, selectedFlow, graphReady, handleNodeClick } =
    useIssueDetailsApiCall();
  const { theme } = useContext(ThemeContext);
  return (
    <div id={BEM("issue-details__content", "api-calls-container")}>
      <div>
        {graphReady && (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            selectionMode={SelectionMode.Partial}
            proOptions={{ hideAttribution: true }}
            onNodeClick={handleNodeClick}
            colorMode={theme}
            panOnDrag={[0]}
            fitView
            fitViewOptions={{
              maxZoom: 0.8,
            }}
            snapToGrid
            nodeTypes={NODE_TYPES}
          >
            {nodes.length > 5 && <MiniMap pannable zoomable zoomStep={1} />}
            <Controls orientation="horizontal" />
            <Background variant={BackgroundVariant.Cross} />
          </ReactFlow>
        )}
      </div>
      {selectedFlow && (
        <div>
          <HTTPViewer
            value={selectedFlow.request}
            title={t("common.request")}
          />
          <HTTPViewer
            value={selectedFlow.response}
            title={t("common.response")}
          />
        </div>
      )}
    </div>
  );
}, isEqual);
