import { Callout, FormInput, Offcanvas } from "@Components";
import type { Props } from "./ApiKeyCanvas.i";
import { useApiKeyCanvas } from "./useApiKeyCanvas";
import { CreateForm } from "./Components";
import { t } from "i18next";
import "./style.scss";

export const ApiKeyCanvas = (props: Props) => {
  const {
    closeCanvas,
    formData,
    isOpen,
    setFormData,
    validities,
    buttons,
    formDisabled,
    secretApiKey,
  } = useApiKeyCanvas(props);

  return (
    <Offcanvas
      isOpen={isOpen}
      title="organization.create-api-key"
      toggle={closeCanvas}
      buttons={buttons}
      id="api-key-canvas"
      exitLabel={secretApiKey ? "common.close" : "common.cancel"}
      children={
        <>
          <CreateForm
            formData={formData}
            setFormData={setFormData}
            validities={validities}
            disabled={formDisabled}
          />
          {!!secretApiKey && (
            <div>
              <Callout level="warning" className="my-3">
                {t("organization.create-api-key-warning")}
              </Callout>
              <FormInput
                type="password"
                label={t(`common.api-key`)}
                value={secretApiKey}
                id="input-api-key-value"
                data-cy="input-api-key-value"
                readOnly
                canBeCopy
                canBeSeen
              />
            </div>
          )}
        </>
      }
    />
  );
};
