export const getFiltersFromUrlParams = (sp: URLSearchParams) => {
  const page = sp.get("page") || "1";
  const size = sp.get("size") || "12";

  return {
    criteria: sp.getAll("filter_criteria"),
    values: sp.getAll("filter_values"),
    sortBy: sp.get("sort_by"),
    sortMode: sp.get("sort_mode"),
    operators: sp.getAll("filter_operators"),
    page: parseInt(page) || 1,
    size: parseInt(size) || 12,
  };
};
