import { getDefaultFilterValue } from "@Services";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const usePageFilters = () => {
  const [searchParams] = useSearchParams();

  return {
    getValueOf: useCallback(
      (k: string) => {
        return searchParams.get(k) || getDefaultFilterValue(k) || "";
      },
      [searchParams]
    ),
  };
};
