import { LoadingScreen } from "@Components";
import { isEqual } from "lodash";
import { memo } from "react";
import { AdditionalInfo } from "../AdditionalInfo";
import { APIDefinitionDetailsNotFound } from "../Components/APIDefinitionDetailsNotFound";
import { APIDefinitionDetailsSectionDetails } from "../Components/APIDefinitionDetailsSectionDetails";
import { useAPIDefinitionDetailsStore } from "../Store/APIDefinitionDetailsStore";

export const APIDefinitionGeneralTab = memo(() => {
  const { notFound, loading } = useAPIDefinitionDetailsStore();
  if (notFound) {
    return <APIDefinitionDetailsNotFound />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div id="api-definition-details-general-tab">
      <div className="content">
        <APIDefinitionDetailsSectionDetails />
        <AdditionalInfo />
      </div>
    </div>
  );
}, isEqual);
