import { Configuration, getAppAPIURL } from "@Services";

export const getApiUrl = () => {
  return getAppAPIURL() || "/v1/";
};

export const getClientConfig = (): Configuration => {
  return new Configuration({
    basePath: getApiUrl().replaceAll("/v1/", ""),
  });
};
