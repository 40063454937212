import { useManageApiResponse } from "@Hooks";
import { API } from "@Services";
import { isStatusCodeSuccess } from "@Utils";
import { t } from "i18next";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import validator from "validator";

export const useNewPasswordForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordCreated, setPasswordCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const manageResponse = useManageApiResponse(setLoading);

  const isValidEmail = validator.isEmail(email);

  const setNewPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const token = searchParams.get("token");
    if (!token) {
      setError(t("login.new-psw.token-empty"));
      return;
    }

    const { status } = await manageResponse({
      errorMessage: "login.new-psw.api-error",
      statusErrorMessage: {
        "422": "login.new-psw.token-expired",
      },
      promise: API.user().userConfirmPasswordReset({
        email,
        passwordConfirm: password,
        passwordNew: repeatPassword,
        token,
      }),
    });

    if (isStatusCodeSuccess(status)) {
      setPasswordCreated(true);
    }
  };

  const isFormValid =
    !!password && validPassword && password === repeatPassword && isValidEmail;

  return {
    loading,
    email,
    setEmail,
    password,
    setPassword,
    repeatPassword,
    setRepeatPassword,
    error,
    isValidEmail,
    setNewPassword,
    isFormValid,
    passwordCreated,
    validPassword,
    setValidPassword,
  };
};
