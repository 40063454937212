import { ItemNotFound, LoadingScreen } from "@Components";
import type { FC } from "react";
import { useNavigateToApiKeyList } from "../../Hooks/useNavigateToApiKeyList";

export const ErrorPage: FC = () => {
  const navigateToList = useNavigateToApiKeyList();
  return (
    <ItemNotFound message="errors.no-api-key" navigateToList={navigateToList} />
  );
};

export const LoadingPage: FC = () => {
  return <LoadingScreen />;
};
