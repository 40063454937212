import { useCallback, useEffect, useMemo, useState } from "react";
import type { ComboBoxProps } from "./ComboBox.i";
import type { Option, Options } from "@Interfaces";

interface Props {
  data: NonNullable<ComboBoxProps["data"]>;
  value: ComboBoxProps["value"];
  onChange: NonNullable<ComboBoxProps["onChange"]>;
}

const useComboBox = ({ value, data, onChange }: Props) => {
  const [options, setOptions] = useState<Options<string>>([]);
  const [inputValue, setInputValue] = useState<string>(value);
  const [showSuggestion, setShowSuggestion] = useState(false);

  useEffect(() => {
    setOptions([...data]);
  }, [data]);

  useEffect(() => {
    if (inputValue !== value) setInputValue(value);
  }, [inputValue, value]);

  const filteredOptions = useMemo(() => {
    let result = [...options];
    const trimmedValue = inputValue.trim();
    if (inputValue) {
      result = options.filter(o => o.label.includes(trimmedValue));
    }
    return result;
  }, [options, inputValue]);

  const handleInputKeyDown = useCallback(
    (value: string) => {
      setInputValue(value);
      onChange(value);
    },
    [onChange]
  );

  const handleOptionSelect = useCallback(
    ({ value }: Option<string>) => {
      handleInputKeyDown(value);
      setShowSuggestion(false);
    },
    [handleInputKeyDown]
  );

  return {
    options: filteredOptions,
    inputValue,
    handleInputKeyDown,
    handleOptionSelect,
    showSuggestion,
    setShowSuggestion,
  };
};

export default useComboBox;
