import { joinClasses } from "@Utils";
import { t } from "i18next";

export const NoVulnerableData = ({
  text = "common.no-data-classification",
  direction = "column",
}: {
  text?: string;
  direction?: "row" | "column";
}) => {
  const outerClassName = joinClasses(
    "no-vulnerable-data",
    `flex-${direction}`,
    direction === "row" ? "ps-3" : undefined
  );

  const innerClassName = joinClasses(
    direction === "column" ? "text-center" : undefined,
    direction === "row" ? "" : undefined
  );

  return (
    <div className={outerClassName}>
      <i className="bi-shield fs-2" />
      <div className={innerClassName}>{t(text)}</div>
    </div>
  );
};
