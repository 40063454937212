import { t } from "i18next";
import { NoVulnerableData, DashboardCard } from "../../Components";
import { useCallback, useMemo } from "react";
import { usePreference } from "@Hooks";
import { ViewSwitch } from "./ViewSwitch";
import type { MostCommonIssuesCardProps } from "./MostCommonIssues.i";
import { TableView } from "./TableView";
import { ChartView } from "./ChartView";
import type { DashboardCardProps } from "../DashboardCard.i";

const PREFERENCE_KEY = "dashboard-frequent-issues-chart";

export const MostCommonIssuesCard = ({
  mostCommonIssues,
  showNoData,
}: DashboardCardProps<MostCommonIssuesCardProps>) => {
  const [showChart, setShowChart] = usePreference(PREFERENCE_KEY, "true");

  const boolShowShart = showChart === "true";
  const handleSetShowChart = useCallback(
    (showChart: boolean) => setShowChart(`${showChart}`),
    [setShowChart]
  );

  const sortedData = useMemo(() => {
    if (!mostCommonIssues || showNoData) return null;
    const list = [...mostCommonIssues];
    list.sort((a, b) => b.count - a.count);
    return list.slice(0, 5);
  }, [mostCommonIssues, showNoData]);

  const hasData = Boolean(sortedData?.length);

  return (
    <DashboardCard className="titled most-common-issues">
      <span className="title">{t("common.most-common-issues")}</span>
      <>
        {hasData && (
          <ViewSwitch
            showChart={boolShowShart}
            setShowChart={handleSetShowChart}
          />
        )}
      </>
      <>
        {sortedData && sortedData.length ? (
          <>
            {boolShowShart ? (
              <ChartView mostCommonIssues={sortedData} />
            ) : (
              <TableView mostCommonIssues={sortedData} />
            )}
          </>
        ) : (
          <NoVulnerableData text="common.no-issues" />
        )}
      </>
    </DashboardCard>
  );
};
