import { API } from "@Services";
import { t } from "i18next";

export const handleSubmitForgotPassword = async (
  email: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsSentEmail: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    await API.user().userAskPasswordReset({
      email,
    });
    setIsSentEmail(true);
  } catch (error) {
    setError(t("login.forgot-psw.api-error") as string);
  }
};
