import {
  DisplayRowApiDefinition,
  DisplayRowProjectInfo,
  DisplayRowRunSchedule,
  DisplayRowSpaceLink,
} from "@Components";
import type { Project } from "@Services";
import type { FC } from "react";
import { useMemo } from "react";
import {
  getEditButtonProps,
  getSpaceLinksButtonProps,
} from "../../../Functions";
import type { CanvasController } from "../../ProjectGeneral.i";

type ProjectShowCaseProps = {
  project: Project;
  canvasController?: CanvasController;
};

export const ProjectShowCase: FC<ProjectShowCaseProps> = ({
  project,
  canvasController,
}) => {
  const editProjectInfoButton = useMemo(() => {
    return !canvasController
      ? undefined
      : getEditButtonProps({
          onMouseDown: () => canvasController.setCanvasType("project"),
          data: { cy: "edit-projectinfo" },
        });
  }, [canvasController]);

  const editApiDefinitionButton = useMemo(() => {
    return !canvasController
      ? undefined
      : getEditButtonProps({
          onMouseDown: () => canvasController.setCanvasType("service"),
          data: { cy: "button-edit-apidefinitions" },
        });
  }, [canvasController]);

  const editRunScheduleButton = useMemo(() => {
    return !canvasController
      ? undefined
      : getEditButtonProps({
          onMouseDown: () => canvasController.setCanvasType("schedule"),
          data: { cy: "edit-runschedule" },
        });
  }, [canvasController]);

  const editSpaceLinkButton = useMemo(() => {
    return !canvasController
      ? undefined
      : getSpaceLinksButtonProps(project, {
          onMouseDown: () => canvasController.setCanvasType("space-links"),
          data: { cy: "edit-space-links" },
        });
  }, [canvasController, project]);

  const apiDefinitionAdditionalProps = useMemo(
    () => ({
      label: "common.file-identifier",
      value: project.upload.fileName,
    }),
    [project.upload.fileName]
  );

  return (
    <>
      <DisplayRowProjectInfo
        project={project}
        buttonProps={editProjectInfoButton}
      />
      <DisplayRowApiDefinition
        fileName={apiDefinitionAdditionalProps}
        endpoints={project.endpoints}
        buttonProps={editApiDefinitionButton}
      />
      <DisplayRowSpaceLink
        buttonProps={editSpaceLinkButton}
        spaceLinkID={project.spaceLinkID}
      />
      <DisplayRowRunSchedule
        runPolicy={project.runPolicy}
        buttonProps={editRunScheduleButton}
      />
    </>
  );
};
