import { ExtensionCategoriesTable, Section } from "@Components";
import { NewProjectContext } from "@Contexts";
import type { Dispatch } from "react";
import { useContext, useEffect } from "react";
import {
  getScanExtensionCategories,
  type Category as ExtensionCategory,
} from "@Services";

export const SecurityChecks = () => {
  const { project, setProject } = useContext(NewProjectContext);

  useEffect(() => {
    if (!project.extensionSettings.enabledCategories.length) {
      const enabledCategories = Object.values(getScanExtensionCategories());
      setProject({
        ...project,
        extensionSettings: {
          enabledCategories,
        },
      });
    }
  }, [project, setProject]);

  const handleSetCategories: Dispatch<ExtensionCategory[]> = (c: any) => {
    const toUpdate = {
      extensionSettings: {
        enabledCategories: c,
      },
    };
    setProject({ ...project, ...toUpdate });
  };

  return (
    <div id="security-checks">
      <Section>
        <ExtensionCategoriesTable
          categories={project.extensionSettings.enabledCategories}
          setCategories={handleSetCategories}
        />
      </Section>
    </div>
  );
};
