import type {
  ParameterLinkEdgeData,
  ParameterLinkEdges,
  ParameterLinkNodeData,
  ParameterLinkNodes,
} from "@Components";
import { KA_INVENTORY_GRAPH_DESELECT_ACTION, nodesColors } from "@Constants";
import { useKeyboardEvent, useProject, useToast } from "@Hooks";
import type { Nullable } from "@Interfaces";
import { projectDataToUpdateProjectData } from "@Services";
import { getDagreLayoutedElements } from "@Utils";
import {
  useEdgesState,
  useNodesState,
  type Edge,
  type EdgeChange,
  type EdgeMarker,
  type Node,
  type NodeProps,
} from "@xyflow/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIDefinitionDetailsStore } from "../Store/APIDefinitionDetailsStore";
import { parseParameterLinksIntoNodesAndEdges } from "./utils";

export const useParameterLinksGraph = () => {
  const { loading, details } = useAPIDefinitionDetailsStore();
  const { project, update } = useProject();
  const toast = useToast();

  const [nodes, setNodes, onNodesChange] = useNodesState<
    Node<ParameterLinkNodeData | NodeProps>
  >([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [selectedItemId, setSelectedItemId] = useState<Nullable<string>>(null);

  const memoizedNodes = useMemo(() => nodes, [nodes]);
  const memoizedEdges = useMemo(() => edges, [edges]);

  const deleteUserParameterLink = useCallback(
    async (edge: Edge<ParameterLinkEdgeData>) => {
      if (!project || !edge.data) return false;

      const { parameterLink } = edge.data;
      const nextParameterLinks = [...(project?.parameterLinks ?? [])];
      nextParameterLinks.splice(nextParameterLinks.indexOf(parameterLink), 1);

      const requestBody = projectDataToUpdateProjectData(project);
      requestBody.parameterLinks = nextParameterLinks;
      const success = await update(requestBody);

      if (success) {
        toast({
          message: "successes.user-parameter-link-deleted",
          type: "success",
        });

        setSelectedItemId(null);
      }
      return success;
    },
    [project, toast, update]
  );

  const handleNodeClick = useCallback(
    (
      _event: Nullable<React.MouseEvent<Element>>,
      node: Nullable<Node<ParameterLinkNodeData | NodeProps>>
    ) => {
      if (!node && !selectedItemId) return;

      const nodeIdsToActivate = new Set<string>();
      const willNodeBeSelected = node
        ? !nodes.find(n => n.id === node.id)?.data.selected
        : false;

      setSelectedItemId(() => (willNodeBeSelected ? node?.id ?? null : null));

      const nextEdges = edges.map(edge => {
        const isEdgeAnimated =
          willNodeBeSelected &&
          (edge.source === node?.id || edge.target === node?.id);

        if (isEdgeAnimated) {
          nodeIdsToActivate.add(edge.source);
          nodeIdsToActivate.add(edge.target);
        }

        return {
          ...edge,
          animated: isEdgeAnimated,
          markerEnd: {
            ...(edge.markerEnd as EdgeMarker),
            color: isEdgeAnimated
              ? nodesColors.edge.marker.highlight
              : nodesColors.edge.marker.normal,
          },
        };
      });

      const nextNodes = nodes.map(n => ({
        ...n,
        data: {
          ...n.data,
          selected:
            willNodeBeSelected &&
            (n.id === node?.id || nodeIdsToActivate.has(n.id)),
        },
      }));

      setEdges(nextEdges);
      setNodes(nextNodes);
    },
    [edges, nodes, selectedItemId, setEdges, setNodes]
  );

  const handleEdgeChange = useCallback(
    async (changes: EdgeChange[]) => {
      const changesToApply = [];

      for (const edgeChange of changes) {
        if (edgeChange.type === "remove") {
          const edgeToDelete = edges.find(e => e.id === edgeChange.id);
          if (edgeToDelete) {
            const success = await deleteUserParameterLink(
              edgeToDelete as Edge<ParameterLinkEdgeData>
            );
            if (success) {
              changesToApply.push(edgeChange);
            }
          }
        }
      }

      onEdgesChange(changesToApply);
    },
    [deleteUserParameterLink, edges, onEdgesChange]
  );

  const generateLayout = useCallback(
    (nodes: ParameterLinkNodes, edges: ParameterLinkEdges) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getDagreLayoutedElements(nodes, edges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    },
    [setEdges, setNodes]
  );

  useEffect(() => {
    if (!project) return;
    const { nodes, edges } = parseParameterLinksIntoNodesAndEdges(
      project.parameterLinks,
      details
    );

    generateLayout(nodes, edges);
  }, [details, generateLayout, project, project?.parameterLinks]);

  useKeyboardEvent({
    action: KA_INVENTORY_GRAPH_DESELECT_ACTION,
    onKeyDown: () => handleNodeClick(null, null),
  });

  return {
    nodes: memoizedNodes,
    edges: memoizedEdges,
    loading,
    selectedItemId,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange: handleEdgeChange,
    handleNodeClick,
    handleOutsideClick: () => handleNodeClick(null, null),
    handleEdgeClick: (
      event: React.MouseEvent<Element>,
      edge: Edge<ParameterLinkEdgeData>
    ) =>
      handleNodeClick(
        event,
        edge.data?.sourceNode ?? edge.data?.targetNode ?? null
      ),
  };
};
