import { FormInput, type FormInputProps } from "@Components";
import { Col, FormGroup } from "reactstrap";
import { memo, useContext, useMemo } from "react";
import { AdvancedSettingsFormContext } from "../AdvancedSettingsFormContext";
import { t } from "i18next";

export const AdvancedSettingsInput = memo((props: FormInputProps) => {
  const { setAdvancedSettings } = useContext(AdvancedSettingsFormContext);

  const formDisabled = !setAdvancedSettings;

  const disabledProps: FormInputProps = useMemo(() => {
    const propsType = props.type;
    const isCheckbox = propsType === "checkbox";
    //
    const type = isCheckbox ? "checkbox" : "text";
    const readOnly = !isCheckbox; // text-type are readonly
    const disabled = isCheckbox; // checkbox-type are disabled
    const onChange = () => true; // do nothing
    let value = props.value;
    if (propsType === "select" && props.options) {
      const selected = props.options.find(option => value === option.value);
      value = selected ? t(selected.label) : value;
    }
    return { ...props, type, readOnly, disabled, onChange, value };
  }, [props]);

  return (
    <Col>
      <FormGroupWrapper type={props.type}>
        {formDisabled ? (
          <FormInput {...disabledProps} />
        ) : (
          <FormInput {...props} />
        )}
      </FormGroupWrapper>
    </Col>
  );
});

const FormGroupWrapper = memo(
  ({
    children,
    type,
  }: {
    type: FormInputProps["type"];
    children: JSX.Element;
  }) => {
    return type === "checkbox" ? (
      <FormGroup switch>{children}</FormGroup>
    ) : (
      children
    );
  }
);
