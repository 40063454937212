import type { HttpSearchObject } from "@Interfaces";
import { buildQueryString, fieldsWithLikeAvailable } from "@Services";

export const toQueryString = <T extends object>(filters: T) => {
  const filtersQueryObject: HttpSearchObject[] = [];
  Object.entries(filters).forEach(([filter_criteria, filter_value]) => {
    const filter_operator = fieldsWithLikeAvailable.includes(filter_criteria)
      ? "LIKE"
      : "EQ";

    filtersQueryObject.push({
      filter_operator,
      filter_criteria,
      filter_value,
    });
  });
  return buildQueryString(filtersQueryObject);
};
