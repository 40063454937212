import type { Props } from "./ServiceEditCanvas.i";
import { Offcanvas, ApiDefinition, SkeletonBox, Callout } from "@Components";
import useApiDefinitionEditCanvas from "./useApiDefinitionEditCanvas";
import { usePendingProject } from "@Hooks";
import { type Dispatch, type SetStateAction, useMemo } from "react";
import { t } from "i18next";
import type { ProjectExtras } from "@Interfaces";
import type { UserCreateProjectRequest } from "@Services";

type ApiDefinitionObject = {
  project: UserCreateProjectRequest;
  projectExtras: ProjectExtras;
  setProject: Dispatch<SetStateAction<UserCreateProjectRequest>>;
  setProjectExtras: Dispatch<SetStateAction<ProjectExtras>>;
  availableEndpoints: number;
};

const ApiDefinitionEditCanvasContent = ({
  apiDefinition,
}: {
  apiDefinition: ApiDefinitionObject;
}) => {
  const { loading: pendingProjectsLoading, pendingProjectExists } =
    usePendingProject();

  if (pendingProjectsLoading) {
    return (
      <SkeletonBox width="100%" height="150px">
        <rect x="0" y="0" rx="3" ry="3" width="100%" height={150} />
      </SkeletonBox>
    );
  }

  if (pendingProjectExists) {
    return (
      <Callout level="warning">
        {t("project.api-definition.update-project-disabled")}
      </Callout>
    );
  }

  return (
    <ApiDefinition
      project={apiDefinition.project}
      projectExtras={apiDefinition.projectExtras}
      setProject={apiDefinition.setProject}
      setProjectExtras={apiDefinition.setProjectExtras}
      availableEndpoints={apiDefinition.availableEndpoints}
      updatingProjectBaseURL={apiDefinition.project.baseURL}
    />
  );
};

export const ApiDefinitionEditCanvas = (props: Props) => {
  const { canvasProps, apiDefinition } = useApiDefinitionEditCanvas(props);

  const children = useMemo(() => {
    if (!canvasProps.isOpen) {
      return <></>;
    }

    return <ApiDefinitionEditCanvasContent apiDefinition={apiDefinition} />;
  }, [apiDefinition, canvasProps.isOpen]);

  return (
    <Offcanvas
      id="project-api-definition-canvas"
      isOpen={canvasProps.isOpen}
      className={canvasProps.loading ? "loading" : undefined}
      toggle={canvasProps.setClosed}
      buttons={[canvasProps.saveButton]}
      title="project.edit-canvas-titles.api-definition"
      size="md"
      children={children}
    />
  );
};
