import { useCallback, useEffect, useMemo, useState } from "react";
import type { MTLSCertificateCanvasFormProps } from "../MTLSCertificatesForm.i";
import { equals, getEmptyMtlsCertificate } from "@Services";
import type { ButtonProps } from "@Components";

export const useGlobalMTLSCertificateForm = (
  props: MTLSCertificateCanvasFormProps
) => {
  const { close, saveEntry, mtlsCertificateEntry } = props;

  const [entry, setEntry] = useState(getEmptyMtlsCertificate());
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (!mtlsCertificateEntry) return;
    setEntry(prev =>
      equals(prev, mtlsCertificateEntry) ? prev : mtlsCertificateEntry
    );
  }, [mtlsCertificateEntry]);

  const handleClose = useCallback(() => {
    close();
    setEntry(getEmptyMtlsCertificate());
  }, [close]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        color: "primary",
        children: "common.add",
        iconClass: "bi bi-plus-square-fill",
        loading,
        disabled: !formValid,
        data: { cy: "submit-mtls-certificates-canvas-button" },
        onClick: () => {
          setLoading(true);
          saveEntry(entry)
            .then(handleClose)
            .finally(() => setLoading(false));
        },
      },
    ],
    [entry, formValid, handleClose, loading, saveEntry]
  );

  return {
    ...props,
    entry,
    setEntry,
    close: handleClose,
    buttons,
    setFormValid,
    loading,
  };
};
