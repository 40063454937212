import { DisplayRow } from "@Components";
import { NewProjectContext } from "@Contexts";
import { getScanExtensionCategories } from "@Services";
import { memo, useContext } from "react";

export const SecurityChecksSection = memo(() => {
  const {
    project: {
      extensionSettings: { enabledCategories },
    },
  } = useContext(NewProjectContext);
  return (
    <DisplayRow title="common.security-checks">
      <div className="security-checks-container">
        {Object.values(getScanExtensionCategories()).map(c => (
          <div key={c}>
            {enabledCategories.includes(c) ? (
              <i className="bi bi-check-circle text-success" />
            ) : (
              <i className="bi bi-x-circle text-danger" />
            )}
            <span className="ps-2">{c}</span>
          </div>
        ))}
      </div>
    </DisplayRow>
  );
});
