import type { FileFormat, Spec } from "./types.i";
import YAML, { JSON_SCHEMA } from "js-yaml";

export const getSpec = (
  format: FileFormat,
  fileAsText: string
): Spec | undefined => {
  if (format === "json") {
    return JSON.parse(fileAsText);
  }
  if (format === "yaml") {
    return Object.assign({}, YAML.load(fileAsText, { schema: JSON_SCHEMA }));
  }

  return undefined;
};
