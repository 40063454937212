import type { License } from "@Interfaces";

export const getAvailableEndpointFromLicense = (
  license: License | undefined | null
) => {
  if (!license) {
    return 0;
  }
  return license.name === "MSSP"
    ? license.residual * license.size
    : license.residual;
};
