import { useEffect, useMemo, useState } from "react";
import type { CanvasCommonProps, RenderedCanvasProps } from "../Components.i";
import { getSaveButtonProps } from "../../../Functions";
import type { RunPolicy } from "@Services";
import { equals, projectDataToUpdateProjectData } from "@Services";
import moment from "moment-timezone";

const useScheduleEditCanvas = ({
  isOpen,
  project,
  setClosed,
  setProject: saveProject,
}: CanvasCommonProps) => {
  const [model, setModel] = useState<RunPolicy>(() => ({
    ...project.runPolicy,
  }));
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const submittable = useMemo(() => {
    return formValid && !equals(project.runPolicy, model);
  }, [formValid, model, project.runPolicy]);

  useEffect(() => {
    const projectRunPolicy = project.runPolicy;
    const runPolicy = { ...projectRunPolicy };
    const now = moment();
    if (moment(runPolicy.startDate).isBefore(now, "date")) {
      runPolicy.startDate = now.format("YYYY-MM-DD");
    }
    setModel({ ...runPolicy });
  }, [isOpen, project.runPolicy]);

  const saveButton = useMemo(() => {
    return getSaveButtonProps({
      loading,
      disabled: !submittable,
      onClick: () => {
        setLoading(true);
        saveProject({
          ...projectDataToUpdateProjectData(project),
          runPolicy: model,
        })
          .then(ok => {
            ok && setClosed();
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  }, [loading, submittable, project, saveProject, model, setClosed]);

  const canvasProps: RenderedCanvasProps = {
    isOpen,
    setClosed,
    saveButton,
    loading,
  };

  return {
    canvasProps,
    scheduleProps: {
      runPolicy: model,
      setRunPolicy: setModel,
      setFormValid,
    },
  };
};

export default useScheduleEditCanvas;
