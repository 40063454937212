import { LOGIN_PATH } from "@Constants";
import { useManageApiResponse, useOrganizationId, usePreference } from "@Hooks";
import type { License } from "@Interfaces";
import { API } from "@Services";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useUpdatedOrganizationLicense = () => {
  const organizationId = useOrganizationId();
  const [license, setLicense] = useState<License>();
  const [loading, setLoading] = useState(true);
  const manageResponse = useManageApiResponse(setLoading);
  const [, setLatestOrgId] = usePreference("organization-id");

  const navigate = useNavigate();

  const handleError = useCallback(() => {
    setLatestOrgId(null);
    navigate(LOGIN_PATH);
  }, [navigate, setLatestOrgId]);

  const fetch = useCallback(async () => {
    if (!organizationId) return;
    manageResponse({
      errorMessage: "get-license",
      promise: API.license().userGetLicense(organizationId),
      onSuccess: response => setLicense(response.data as License),
      onError: handleError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    license,
    loading,
  };
};
