import {
  AuthWrapper,
  Breadcrumb,
  type MenuHorizontalElementProps,
} from "@Components";
import {
  KA_PROFILE_NAVIGATE_SHORTCUT_ACTION,
  PROFILE_PATH,
  PROFILE_SHORTCUTS_PATH,
} from "@Constants";
import { Profile, ProfileDetailsPage, ProfileShortcutsPage } from "@Pages";
import { t } from "i18next";

export const PROFILE_BUTTONS: Array<MenuHorizontalElementProps> = [
  {
    name: "Profile",
    path: PROFILE_PATH,
    type: "button",
    icon: "bi bi-person-fill",
  },
  {
    name: "Shortcuts",
    path: PROFILE_SHORTCUTS_PATH,
    type: "button",
    shortcut: KA_PROFILE_NAVIGATE_SHORTCUT_ACTION,
    icon: "bi bi-keyboard-fill",
    customLabel: (
      <span title={KA_PROFILE_NAVIGATE_SHORTCUT_ACTION.getTitle()}>
        {t("components.menu-horizontal.profile-shortcuts-label")}
      </span>
    ),
  },
];

export const ProfileRoutes = [
  {
    path: "/profile",
    element: <AuthWrapper element={<Profile />} pageName="Profile" />,
    handle: {
      buttons: PROFILE_BUTTONS,
    },
    children: [
      {
        path: "",
        element: <ProfileDetailsPage />,
        handle: {
          crumb: (
            <Breadcrumb disabled name={"profile-page-breadcrumb"}>
              {t("common.profile")}
            </Breadcrumb>
          ),
        },
      },
      {
        path: "shortcuts",
        element: <ProfileShortcutsPage />,
        handle: {
          crumb: (
            <Breadcrumb disabled name={"profile-help-page-breadcrumb"}>
              {t("components.menu-horizontal.profile-shortcuts-label")}
            </Breadcrumb>
          ),
        },
      },
    ],
  },
];
