import { useHasAnyItemsGuard, usePaginatedResult, useUrlParams } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useMemo } from "react";
import type { Criteria, Operators, SortBy } from "./ApiKeyListing.i";

export const useApiKeyListing = () => {
  const { organizationId } = useUrlParams();

  const defaultFilters = useMemo(() => getDefaultFilters("apikeys"), []);

  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      return API.apiKeys().organizationAdminListApiKeysByOrganizationId(
        args.organizationId,
        args.page,
        args.pageSize,
        args.sortBy as SortBy,
        args.sortMode,
        args.criteria as Criteria,
        args.operators as Operators,
        args.values
      );
    }, []),
  });

  const { fetchingHasAnyItem, hasAnyItem } = useHasAnyItemsGuard({
    fetchCondition: Boolean(response && response.totalItems === 0),
    getResponse: useCallback(() => {
      if (!organizationId) throw new Error("Missing Organization ID");
      return API.apiKeys().organizationAdminListApiKeysByOrganizationId(
        organizationId,
        1,
        1
      );
    }, [organizationId]),
  });

  return {
    response,
    showCurtesyPage: !response?.totalItems && hasAnyItem === false,
    loading: loading || fetchingHasAnyItem,
    fetch,
  };
};
