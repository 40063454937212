export const getStatusIcon = (status = "") => {
  switch (status) {
    case "ready":
      return "bi-check";
    case "pending":
      return "bi-clock";
    case "error":
      return "bi-x-circle";
    case "archived":
      return "bi-archive";
    default:
      return "";
  }
};
