import { MainOutlet } from "@Components";
import { memo } from "react";
import { Container } from "reactstrap";

export const Home = memo(() => {
  return (
    <Container id="home" fluid>
      <MainOutlet />
    </Container>
  );
});

export default Home;
