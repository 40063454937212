import { ItemNotFound, LoadingScreen } from "@Components";
import type { FC } from "react";
import { useNavigateToUserList } from "../../Hooks/useNavigateToUserList";

export const ErrorPage: FC = () => {
  const navigateToList = useNavigateToUserList();
  return (
    <ItemNotFound message="errors.no-user" navigateToList={navigateToList} />
  );
};

export const LoadingPage: FC = () => {
  return <LoadingScreen />;
};
