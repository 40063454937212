import type { InventoryInput } from "@Interfaces";
import { getInputIconClass } from "@Services";
import { t } from "i18next";

export const getIcons = (params: InventoryInput[]) => {
  const icons: { label: string; icon: string }[] = [];

  params.forEach(p => {
    const icon = getInputIconClass(p);
    const existingIcon = icons.find(item => item.icon === icon);

    const label = t([`compliance.${p}`, p]);

    if (existingIcon) {
      existingIcon.label += `, ${label}`;
    } else {
      icons.push({ label, icon });
    }
  });

  return icons;
};
