import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  organizationHasProject,
  projectInfos,
  projects,
} from "src/Mock";
import type { OrganizationId, ProjectId } from "@Interfaces";
import moment from "moment-timezone";
import type { Project } from "@Services";

export const projectArchive = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params;
  const ownerCheck = organizationHasProject(
    organizationId as OrganizationId,
    projectId as ProjectId
  );
  if (!ownerCheck) return res(ctx.delay(delay), ctx.status(403));
  let index = projectInfos.findIndex(p => p.id === projectId);
  if (index < 0) return res(ctx.delay(delay), ctx.status(400));

  const archivedAt = moment().toISOString();

  const updated: Project = {
    ...projectInfos[index],
    archivedAt,
  };
  projectInfos.splice(index, 1, updated);

  index = projects.findIndex(p => p.id === projectId);
  projects[index].archivedAt = archivedAt;
  return res(ctx.delay(delay), ctx.status(200));
};
