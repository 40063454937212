import { type Dispatch, useEffect, useState } from "react";

export const useTimedState = <StateType>(
  defaultValue: StateType,
  resetDelay = 1_000
) => {
  const [value, setValue] = useState<StateType>(defaultValue);

  useEffect(() => {
    if (!value) {
      return;
    }

    const timeout = setTimeout(() => {
      setValue(defaultValue);
    }, resetDelay);

    return () => clearTimeout(timeout);
  });

  return [value, setValue] as [StateType, Dispatch<StateType>];
};
