import {
  DisplayRow,
  ExtensionCategoriesTable,
  SettingsContainer,
} from "@Components";
import { useArchivedProject } from "@Hooks";
import { useExtensionSettings } from "../Hooks";

export const ExtensionSettings = () => {
  const disabled = useArchivedProject();
  const { setExtensionSettings, loading, extensionSettings } =
    useExtensionSettings();

  return (
    <div id="project-extension-settings">
      <SettingsContainer
        loading={loading}
        section="extensions"
        hasError={!loading && !extensionSettings}
      >
        <DisplayRow
          title="common.security-checks"
          className="h-100 settings-card-container"
        >
          <ExtensionCategoriesTable
            categories={extensionSettings ?? []}
            setCategories={setExtensionSettings}
            disabled={disabled}
            hideTitle
          />
        </DisplayRow>
      </SettingsContainer>
    </div>
  );
};
