import type { ArcElement } from "chart.js";
import type { Context } from "chartjs-plugin-datalabels";
import type { DoughnutChartData } from "./DoughnutChart.i";

// PAY ATTENTION: the keys are the I18N keys of the data item for the chart
const severity: Record<string, number> = {
  "common.high": 3,
  "common.medium": 2,
  "common.low": 1,
  "common.information": 0,
};

/**
 * Sort the data in order to have the high severity as last sector.
 *
 * @param data array of data
 *
 */
export function sortData(data: DoughnutChartData[]) {
  data.sort((a, b) => {
    return severity[a.label] - severity[b.label];
  });
}

export function percentageLabel(value: number, ctx: Context) {
  if (!value) {
    return "";
  }
  const dataArr = ctx.chart.data.datasets[ctx.datasetIndex].data as number[];
  const sum = dataArr.reduce((prev, curr) => {
    prev += curr;
    return prev;
  }, 0);

  const valuepercentage = ((value / sum) * 100).toFixed(0);
  return `${valuepercentage}%`;
}

/**
 * Calculate the difference between the arcs in order to have different sectors.
 *
 * @param data arc element which are used to create the doughnut
 * @returns the gap in pixels
 */
export function gap(data: ArcElement[]) {
  const iRadius = data.reduce(function (pre, cur) {
    const { innerRadius } = cur.getProps(["innerRadius"], true);
    return Math.min(pre, innerRadius);
  }, Number.MAX_VALUE);
  const oRadius = data.reduce(function (pre, cur) {
    const { outerRadius } = cur.getProps(["outerRadius"], true);
    return Math.max(pre, outerRadius);
  }, 0);
  return (oRadius - iRadius) / data.length;
}

/**
 * Scriptable options for "display" option of Datalabels plugin.
 * Calculate if the labels can be shown outside the arc.
 * The last arc (high) is always shown because cannot overide any other label.
 *
 * @param ctx scriptable context provided by Datalabels plugin
 * @returns tru if the label must be shown otherwise false.
 */
export function displayLabels(ctx: Context): boolean {
  const data = ctx.chart.data.datasets[ctx.datasetIndex].data;
  // if high last index, always true
  if (ctx.dataIndex === data.length - 1) {
    return true;
  }
  const value = data[ctx.dataIndex] as number;
  const label = percentageLabel(value, ctx);
  // no label because value is 0%
  if (label.length === 0) {
    return false;
  }
  const metrics = ctx.chart.ctx.measureText(label);
  const meta = ctx.chart.getDatasetMeta(ctx.datasetIndex);
  const mdata = meta.data[ctx.dataIndex];
  // to calculate the space on the arc, use the final values (after animation)
  const { startAngle, endAngle, innerRadius } = mdata.getProps(
    ["startAngle", "endAngle", "innerRadius"],
    true
  );
  const distance = widthBetweenAngles(innerRadius, startAngle, endAngle);
  return metrics.width < distance;
}

/**
 * Calculate the width in pixels between 2 angles.
 *
 * @param radius radius of doughnut
 * @param startAngle start radial angle of the arc
 * @param endAngle end radial angle of the arc
 * @returns the pixels between 2 angles
 */
function widthBetweenAngles(
  radius: number,
  startAngle: number,
  endAngle: number
): number {
  const angle = Math.abs(startAngle - endAngle);
  const arcLen = radius * angle;
  return arcLen;
}
