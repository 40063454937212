import type { FileFormat } from "./types.i";

export const getFileFormat = (file: File): FileFormat | undefined => {
  let result: FileFormat | undefined = undefined;
  result ||= getFileFormFrom(file.name);
  result ||= getFileFormFrom(file.type);
  return result;
};

function getFileFormFrom(arg: string): FileFormat | undefined {
  const s = arg.toLocaleLowerCase();
  if (s.endsWith("json")) return "json";
  if (s.endsWith("yaml") || s.endsWith("yml")) return "yaml";
  return undefined;
}
