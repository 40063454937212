import type { SeverityCount } from "@Services";
import { t } from "i18next";
import { memo } from "react";

interface Props {
  severityCount: SeverityCount;
}

export const DoughnutSeverity = memo(({ severityCount }: Props) => {
  return (
    <div className="content w-100 d-flex flex-column align-items-center doughnut-severity">
      <table>
        <tbody>
          <tr className="color-severity-high">
            <td className="text-right">
              <h2>{severityCount?.high || 0}</h2>
            </td>
            <td>{t("common.high")}</td>
          </tr>
          <tr className="color-severity-medium">
            <td className="text-right">
              <h2>{severityCount?.medium || 0}</h2>
            </td>
            <td>{t("common.medium")}</td>
          </tr>
          <tr className="color-severity-low">
            <td className="text-right">
              <h2>{severityCount?.low || 0}</h2>
            </td>
            <td>{t("common.low")}</td>
          </tr>
          <tr className="color-severity-information">
            <td className="text-right">
              <h2>{severityCount?.information || 0}</h2>
            </td>
            <td>{t("common.information")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
