import type { MockRequestParams } from "@Interfaces";
import type { UserGetSpaceLinkResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, spaceLinks } from "src/Mock";

export const getSpaceLink = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, spaceLinkId } = req.params as MockRequestParams;

  const organizationSpaceLinks = spaceLinks.get(organizationId);

  const spaceLink = organizationSpaceLinks?.find(r => r.id === spaceLinkId);

  if (!spaceLink) {
    return res(ctx.status(404), ctx.delay(delay));
  }

  const response: UserGetSpaceLinkResponse = {
    spaceLink,
  };

  return res(ctx.status(200), ctx.delay(delay), ctx.json(response));
};
