import { Button, Offcanvas, SpaceLinkForm } from "@Components";
import { memo, type FC } from "react";
import type { SpaceLinkEditProps } from "./SpaceLinkEdit.i";
import { useSpaceLinkEdit } from "./useSpaceLinkEdit";
import isEqual from "react-fast-compare";

export const SpaceLinkEdit: FC<SpaceLinkEditProps> = memo(props => {
  const { canvas, form } = useSpaceLinkEdit(props);

  return (
    <>
      <Button
        color="primary"
        children="common.edit"
        iconClass="pencil"
        className="ms-3"
        onClick={canvas.open}
      />
      <Offcanvas
        isOpen={canvas.isOpen}
        title="organization.edit-space-link"
        toggle={canvas.close}
        buttons={canvas.buttons}
      >
        <SpaceLinkForm
          spaceLink={form.data}
          setSpaceLink={form.set}
          disabled={canvas.loading}
          setFormValid={form.setValid}
        />
      </Offcanvas>
    </>
  );
}, isEqual);
