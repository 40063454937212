import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  getAuthUserContext,
  getOrganizationSettings as getOrganizationSettingsFn,
} from "src/Mock";

export const getOrganizationSettings = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const context = getAuthUserContext();
  const user = context.getUser();
  const organizationId = req.params.organizationId.toString();

  if (!user || !organizationId) {
    return res(ctx.delay(delay), ctx.status(403));
  }

  const result = getOrganizationSettingsFn(organizationId);

  return res(ctx.delay(delay), ctx.json(result));
};
