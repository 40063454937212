import { Button, Section } from "@Components";
import { useMemo } from "react";
import {
  useOffCanvasAddParameterLinkStore,
  type ProducerLocation,
} from "../OffCanvasAddParameterLinkStore";

export const ProducingLocationSection = () => {
  const {
    producingParameterLocation,
    producingLocations,
    setProducingParameterLocation,
  } = useOffCanvasAddParameterLinkStore();

  const locationButtons = useMemo(() => {
    return ["body", "header", "cookie"].map(location => (
      <Button
        key={location}
        data={{ cy: `producing-location` }}
        className={"no-text-selection"}
        disabled={producingLocations.indexOf(location) < 0}
        onClick={() =>
          setProducingParameterLocation(location as ProducerLocation)
        }
        color={producingParameterLocation === location ? "pink" : "primary"}
      >
        {location}
      </Button>
    ));
  }, [
    producingLocations,
    producingParameterLocation,
    setProducingParameterLocation,
  ]);

  return (
    <Section
      title="inventory.create-new-parameter-link-step-1"
      data-cy="producer-location-section"
      className="mt-4"
    >
      <div className="producer-locations p-4">{locationButtons}</div>
    </Section>
  );
};
