import { HistoryFilters, Section } from "@Components";
import { memo, useMemo } from "react";
import { useGetAuthenticationProfiles } from "../../Hooks";
import type { Props } from "./HistoryFiltersContainer.i";
import isEqual from "react-fast-compare";
import { useHistoryStore } from "../../Store";

export const HistoryFiltersContainer = memo(
  ({ setFilters, refresh, clear }: Props) => {
    const { authenticationProfiles } = useGetAuthenticationProfiles();

    const { visibleFilters } = useHistoryStore();

    const filterControls = useMemo(
      () => ({
        caller: {
          visible: visibleFilters.caller,
        },
      }),
      [visibleFilters.caller]
    );

    return (
      <Section className={"border-none"}>
        <div className="d-flex justify-content-between align-items-end">
          <HistoryFilters
            authenticationProfiles={authenticationProfiles}
            updateFilters={setFilters}
            refresh={refresh}
            clear={clear}
            filterControls={filterControls}
          />
        </div>
      </Section>
    );
  },
  isEqual
);
