import { useResponsive } from "@Hooks";
import { baseMarginTop, cardHeight, gap, headerHeight } from "./constants";

export const InjectablesLoader = () => {
  const mobile = useResponsive("lg");

  const rows = 3;
  const columns = mobile ? 1 : 2;

  return (
    <>
      <rect x={0} y={0} rx="3" ry="3" width="100%" height={headerHeight} />
      <>
        {Array.from(Array(rows).keys()).map(row => {
          return Array.from(Array(columns).keys()).map(column => (
            <LoaderCard
              key={`${row}${column}`}
              row={row}
              column={column}
              colSize={mobile ? 1 : 2}
            />
          ));
        })}
      </>
    </>
  );
};

type CardProps = { column: number; row: number; colSize: 1 | 2 };

const LoaderCard = ({ colSize, column, row }: CardProps) => {
  const width = `${100 / colSize}%`;
  const x = `calc(${column} * (${width} + ${gap}px))`;
  const y = baseMarginTop + row * (gap + cardHeight);

  return <rect x={x} y={y} rx="3" ry="3" width={width} height={cardHeight} />;
};
