import { type Options } from "@Interfaces";

export const STATUS_CODE_OPTIONS: Options<string> = [
  { label: "200", value: "200" },
  { label: "201", value: "201" },
  { label: "204", value: "204" },
  { label: "400", value: "400" },
  { label: "401", value: "401" },
  { label: "403", value: "403" },
  { label: "404", value: "404" },
  { label: "405", value: "405" },
  { label: "500", value: "500" },
  { label: "501", value: "501" },
  { label: "503", value: "503" },
];
