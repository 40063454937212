import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, issueExclusion } from "src/Mock";
import type { PlainObject } from "@Interfaces";

export const deleteIssueExclusions = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId, issueExclusionId } =
    req.params as PlainObject<string>;

  if (!organizationId || !projectId || !issueExclusionId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const issue = issueExclusion.find(i => i.id === issueExclusionId);

  if (!issue) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const issueIndex = issueExclusion.findIndex(i => i.id === issueExclusionId);

  if (issueIndex < 0) {
    return res(ctx.delay(delay), ctx.status(500));
  }

  issueExclusion.splice(issueIndex, 1);

  return res(ctx.delay(delay), ctx.status(200));
};
