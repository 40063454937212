import {
  isFilledString,
  type AuthenticationSettings,
  type RefreshDefinition,
} from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import { useCallback, useEffect } from "react";
import type { AuthenticationSettingsFormProps } from "../AuthenticationSettingForm.i";

export const useAuthenticationSettingsForm = (
  props: AuthenticationSettingsFormProps
) => {
  const {
    authenticationSettings,
    injectables,
    mTLSCertificates,
    subFormValidities,
    mode,
    setAuthenticationSettings,
    setInjectables,
    setMTLSCertificates,
    setMode,
    reset,
    loading,
    setLoading,
  } = useAuthenticationSettingFormStore();

  useEffect(() => {
    setAuthenticationSettings(props.authenticationSettings);
    setInjectables(props.injectables);
    setMTLSCertificates(props.mTLSCertificates);
    setMode(props.authenticationSettings.name ? "edit" : "insert");
    return reset;
  }, [
    props.authenticationSettings,
    props.injectables,
    props.mTLSCertificates,
    reset,
    setAuthenticationSettings,
    setInjectables,
    setMTLSCertificates,
    setMode,
  ]);

  const { onSubmit, setShow } = props;

  const handleOnSubmit = useCallback(async () => {
    const success = await onSubmit(
      sanitize(authenticationSettings),
      injectables,
      mTLSCertificates
    );
    setShow(!success);
    return success;
  }, [
    authenticationSettings,
    injectables,
    mTLSCertificates,
    onSubmit,
    setShow,
  ]);

  return {
    authenticationSettings,
    subFormValidities,
    mode,
    handleOnSubmit,
    loading,
    setLoading,
  };
};

const sanitize = (settings: AuthenticationSettings) => {
  if (settings.authenticationFlow === "oauth_credentials") {
    if (settings.oAuthData?.grantType !== "password") {
      delete settings.oAuthData?.username;
      delete settings.oAuthData?.password;
    }
  }

  if (settings.refreshDefinition) {
    if (!isFilledString(settings.refreshDefinition.type as string)) {
      delete settings.refreshDefinition;
    } else {
      sanitizeRefreshDefinition(settings.refreshDefinition);
    }
  }

  return settings;
};
const sanitizeRefreshDefinition = (refreshDefinition: RefreshDefinition) => {
  switch (refreshDefinition.type) {
    case "internal":
      delete refreshDefinition.oAuthData;
      break;
    case "external":
      delete refreshDefinition.operation;
      delete refreshDefinition.tokenOutputKey;
      break;
  }
};
