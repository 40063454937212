import { createStore } from "@Hooks";
import type { NullableArray } from "@Interfaces";
import type { Injectable } from "@Services";
import type { Dispatch } from "react";

type InjectableStoreProperties = {
  injectables: NullableArray<Injectable>;
  loading: boolean;
  error: boolean;
};

type InjectableStore = InjectableStoreProperties & {
  setLoading: Dispatch<boolean>;
  setError: Dispatch<boolean>;
  setProjectInjectables: Dispatch<Injectable[]>;
  resetStore: VoidFunction;
};

const initialState: InjectableStoreProperties = {
  injectables: null,
  loading: false,
  error: false,
};

export const useInjectableStore = createStore<InjectableStore>((get, set) => ({
  ...initialState,
  setLoading: loading => {
    set(state => ({ ...state, loading }));
  },
  setError: error => {
    set(state => ({ ...state, error }));
  },
  setProjectInjectables: injectables => {
    set(state => ({
      ...state,
      injectables: injectables.filter(c => !c.associatedAuthSetting),
    }));
  },
  resetStore: () => {
    set(state => ({ ...state, ...initialState }));
  },
}));
