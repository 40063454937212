import {
  Button,
  OrganizationProjectFilters,
  Paginator,
  Section,
} from "@Components";
import { ORGANIZATION_PATH_NEW_PROJECT } from "@Constants";
import { usePendingProject, useUpdateCacheFilters, useUrlParams } from "@Hooks";
import { generateUrl } from "@Utils";
import { t } from "i18next";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectsListTable } from "../Table/ProjectsListTable";
import type { Props } from "./WithProjects.i";
import useWithProject from "./useWithProject";

export const WithProjects = memo(({ fetch, loading, response }: Props) => {
  const navigate = useNavigate();
  const { cardVersion, setCardVersion } = useWithProject();
  const { organizationId } = useUrlParams();
  const { loading: pendingProjectsLoading, pendingProjectExists } =
    usePendingProject(response?.data ?? []);
  const updateFilters = useUpdateCacheFilters("organization.projects");

  const handleNewProjectClick = useCallback(() => {
    navigate(
      generateUrl(ORGANIZATION_PATH_NEW_PROJECT, [organizationId ?? ""])
    );
  }, [navigate, organizationId]);

  return (
    <div id="projects-list-table">
      <Section title="project.list">
        <div className="d-flex justify-content-between align-items-end">
          <OrganizationProjectFilters
            refresh={fetch}
            updateFilters={updateFilters}
          />
          <Button
            children="project.new"
            color="primary"
            hiddenForReader
            iconClass="bi bi-plus-square-fill"
            onMouseDown={handleNewProjectClick}
            disabled={pendingProjectExists}
            loading={pendingProjectsLoading}
            data={{ cy: "new-project-button" }}
            tooltip={
              pendingProjectsLoading || !pendingProjectExists
                ? undefined
                : {
                    content: t("project.new-project-disabled"),
                    place: "bottom",
                  }
            }
          />
        </div>
      </Section>
      <ProjectsListTable
        projects={response?.data}
        loading={loading}
        cardVersion={cardVersion}
        setCardVersion={setCardVersion}
        setFilters={updateFilters}
      />
      <Paginator
        setFilters={updateFilters}
        totalItems={response?.totalItems}
        showItemsPerPage
      />
    </div>
  );
});
