import { DisplayTable } from "@Components";
import type { UserAuthToken } from "@Services";
import { isFilledString, type AuthenticationSettings } from "@Services";
import { t } from "i18next";

const TokenRow = ({
  token,
  tokenIndex,
}: {
  token: UserAuthToken;
  tokenIndex: number;
}) => {
  const data: UserAuthToken = {
    key: token.key,
    value: token.value,
    location: token.location,
    authenticationSchemeType: token.authenticationSchemeType,
  };
  return Object.entries(data)
    .filter(([key, value]) => isFilledString(value))
    .map(([key, value]) => (
      <tr key={key + value + tokenIndex}>
        <td className="text-end">{t(`common.${key}`)}</td>
        <td>{value}</td>
      </tr>
    ));
};

export const TokenTab = ({ userAuthTokens }: AuthenticationSettings) => {
  return (
    <DisplayTable>
      <tbody>
        {userAuthTokens?.map((token, tokenIndex) => {
          const result: Array<JSX.Element> = [
            <tr key={tokenIndex}>
              <td className="text-end">
                <b>Token #{tokenIndex + 1}</b>
              </td>
              <td>{/* EMPTY */}</td>
            </tr>,
          ];
          result.push(
            <TokenRow
              token={token}
              tokenIndex={tokenIndex}
              key={`token_row_${tokenIndex}`}
            />
          );
          return result;
        })}
      </tbody>
    </DisplayTable>
  );
};
