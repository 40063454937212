import { InfoBadge, MethodBadge, StatusCodeBadge } from "@Components";
import type { Method } from "@Interfaces";
import { BEM, joinClasses, limitString } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import React, { memo, useEffect } from "react";
import isEqual from "react-fast-compare";
import type { ApiCallNodeProps } from "../IssueDetailsApiCall.i";

// CustomNode component with types
export const ApiCallNode: React.FC<ApiCallNodeProps> = memo(
  ({ id: nodeId, data }) => {
    const updateNodeInternals = useUpdateNodeInternals();

    useEffect(() => {
      updateNodeInternals(nodeId);
    }, [data, nodeId, updateNodeInternals]);

    return (
      <div
        id={nodeId}
        className={joinClasses("api-call-node", data.selected && "selected")}
      >
        {!data.first && (
          <Handle
            type="target"
            position={Position.Top}
            id={`handle-target-${nodeId}`}
            isConnectable={false}
          >
            <i className="bi bi-arrow-down-square-fill" />
          </Handle>
        )}
        <div className={BEM("api-call-node", "title")}>
          <div className="flex-center">
            <InfoBadge className="me-2 index-badge" bgColor="custom">
              {data.index + 1}
            </InfoBadge>
            <span title={data.path}>{limitString(data.path, 30)}</span>
          </div>
          {data.verb && <MethodBadge type={data.verb as Method} />}
        </div>
        <div className={BEM("api-call-node", "property")}>
          <span>Status</span>
          <StatusCodeBadge code={+(data.status ?? "0")} />
        </div>
        {!data.last && (
          <Handle
            type="source"
            position={Position.Bottom}
            id={`handle-source-${nodeId}`}
            isConnectable={false}
          >
            <i className="bi bi-arrow-down-square-fill" />
          </Handle>
        )}
      </div>
    );
  },
  isEqual
);
