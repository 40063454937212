export const severityColors = {
  high: "#A50909",
  medium: "#F36B1E",
  low: "#d6b614",
  information: "#0d5aa7",
};

export const nodesColors = {
  edge: {
    marker: {
      normal: "#ced4da",
      highlight: "#d63384",
    },
  },
};
