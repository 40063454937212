import type { UpdateOrganizationSettings } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, organizationSetting } from "src/Mock";

export const updateOrganizationSettings = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const params: UpdateOrganizationSettings = await req.json();

  const organizationId = req.params.organizationId.toString();
  const settings = organizationSetting.get(organizationId);

  if (!settings) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  organizationSetting.set(organizationId, {
    ...settings,
    ...params,
  });

  return res(ctx.delay(delay), ctx.status(200));
};
