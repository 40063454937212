import { useMemo, useState } from "react";
import type { NewScanButtonProps } from "../NewScanButton.i";
import { useArchivedProject, useIsReader, useProject } from "@Hooks";

export const useButtonStatus = ({
  disabled: propsDisabled,
  loading: propsLoading,
}: NewScanButtonProps) => {
  const { project, loading: projectLoading, projectStatus } = useProject();
  const [loading, setLoading] = useState(false);

  const isReadonlyProject = useArchivedProject();
  const isReadonlyUser = useIsReader();

  const projectInvalidStatus = project && projectStatus !== "ready";

  const tooltip = useMemo(() => {
    if (projectInvalidStatus) return "errors.scan.project-invalid-state";
  }, [projectInvalidStatus]);

  return {
    scanButtonProps: {
      loading: loading || !!projectLoading || propsLoading,
      disabled: !project || projectInvalidStatus || propsDisabled,
      tooltip,
    },
    hidden: isReadonlyProject || isReadonlyUser,
    setLoading,
  };
};
