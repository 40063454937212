import { useManageApiResponse } from "@Hooks";
import type { Nullable } from "@Interfaces";
import { API, type IssueExclusion } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIssueDetailsCanvasIssueIds } from "../../../../../Hooks/useIssueDetailsCanvasIssueIds";
import { useIssueDetailsCanvasStore } from "../../../../../IssueDetailsCanvas.store";
import { isStatusCodeSuccess } from "@Utils";
import type { ButtonProps } from "@Components";

export const useIssueExclusionCanvas = () => {
  const {
    issueDetails,
    issueExlusionCanvasOpen,
    setIssueExclusionCanvasOpen,
    excludeIssueDetails,
  } = useIssueDetailsCanvasStore();
  const { organizationId, projectId } = useIssueDetailsCanvasIssueIds();
  const [loading, setLoading] = useState(false);
  const [issueExclusionForm, setIssueExclusionForm] =
    useState<Nullable<IssueExclusion>>(null);
  const manageResponse = useManageApiResponse(setLoading);

  useEffect(() => {
    if (!issueDetails) {
      setIssueExclusionForm(null);
      return;
    }

    setIssueExclusionForm({
      extensionName: issueDetails.extensionName,
      falsePositive: false,
      id: issueDetails.id,
      methods: [issueDetails.verb],
      path: issueDetails.endpoint,
    });
  }, [issueDetails]);

  const handleExcludeIssue = useCallback(async () => {
    if (!organizationId || !projectId || !issueExclusionForm) {
      return false;
    }

    const { status } = await manageResponse({
      errorMessage: "issue-exclusion-creation",
      promise: API.scanSettings().issueExclusionCreate(
        organizationId,
        projectId,
        issueExclusionForm
      ),
      successMessage: "issue-exclusion-creation",
    });

    if (isStatusCodeSuccess(status)) {
      setIssueExclusionCanvasOpen(false);
      excludeIssueDetails();
    }
  }, [
    excludeIssueDetails,
    issueExclusionForm,
    manageResponse,
    organizationId,
    projectId,
    setIssueExclusionCanvasOpen,
  ]);

  const saveButton = useMemo<Array<ButtonProps>>(
    () => [
      {
        id: "submit-authentication",
        color: "primary",
        iconClass: "bi bi-floppy",
        children: "common.save",
        disabled: !issueExclusionForm || !issueDetails,
        loading,
        onClick: handleExcludeIssue,
      },
    ],
    [issueExclusionForm, issueDetails, loading, handleExcludeIssue]
  );

  return {
    loading,
    issueExclusionForm,
    setIssueExclusionForm,
    saveButton,
    open: issueExlusionCanvasOpen,
    close: () => setIssueExclusionCanvasOpen(false),
  };
};
