import { DownloadScanReportCanvas, DropdownButtons } from "@Components";
import { useEffect, useState } from "react";
import { t } from "i18next";
import type { ReportFormat } from "@Services";
import { usePreference } from "@Hooks";

export const DownloadReportAction = () => {
  const [reportType, setReportType] = usePreference("scan-report-type");
  const [reportFormat, setReportFormat] = useState<ReportFormat>(() => {
    return reportType === "csv" ? "csv" : "pdf";
  });
  const [showCanvas, setShowCanvas] = useState(false);
  const initialIndex = reportType === "csv" ? 1 : 0;

  useEffect(() => {
    setReportType(reportFormat);
  }, [reportFormat, setReportType]);

  return (
    <>
      <DropdownButtons
        initialIndex={initialIndex}
        buttons={[
          {
            color: "primary",
            iconClass: "download",
            children: `PDF ${t("common.report")}`,
            onSelect: () => setReportFormat("pdf"),
            onClick: () => setShowCanvas(true),
            dropdownLabel: "PDF",
          },
          {
            color: "primary",
            iconClass: "download",
            children: `CSV ${t("common.report")}`,
            onSelect: () => setReportFormat("csv"),
            onClick: () => setShowCanvas(true),
            dropdownLabel: "CSV",
          },
        ]}
      />
      <DownloadScanReportCanvas
        isOpen={showCanvas}
        close={() => setShowCanvas(false)}
        format={reportFormat}
      />
    </>
  );
};
