import { useToast } from "@Hooks";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type ErrorsObject = Record<string, { found: boolean; message: string }>;

export const useErrors = () => {
  const toast = useToast();
  const [queryParams, setQueryParams] = useSearchParams();

  const errors: ErrorsObject = useMemo(
    () => ({
      "session-expired": {
        found: queryParams.get("session-expired") === "1",
        message: "common.session-expired",
      },
      "service-unavailable": {
        found: queryParams.get("service-unavailable") === "1",
        message: "common.service-unavailable",
      },
    }),
    [queryParams]
  );

  useEffect(() => {
    const toastMessage = getMessage(errors);

    if (!toastMessage) {
      return;
    }
    toast({
      message: toastMessage,
      type: "error",
    });
    const newQueryParams = new URLSearchParams(queryParams);
    Object.keys(errors).forEach(k => {
      newQueryParams.delete(k);
    });
    setQueryParams(newQueryParams);
  }, [errors, toast, queryParams, setQueryParams]);

  return { errors };
};

function getMessage(record: ErrorsObject) {
  return Object.values(record).reduce((res, value) => {
    if (res) return res;
    const { found, message } = value;
    return found ? message : res;
  }, "");
}
