import type { FC } from "react";
import type { InviteFormProps } from "./UserForm.i";
import { useUserForm } from "./Hooks/useUserForm";
import { Form, FormGroup } from "reactstrap";
import { useFormValidation } from "./Hooks/useFormValidation";
import { RoleExpirationFields } from "./Components/RoleExpirationFields";
import { NameField } from "./Components/NameField";
import { SurnameField } from "./Components/SurnameField";
import { EmailField } from "./Components/EmailField";

export const InviteOrganizationUserForm: FC<InviteFormProps> = ({
  setUser: propsSetUser,
  user: propsUser,
  disabled,
  setFormValid,
}) => {
  const { user, setUser } = useUserForm(propsUser, propsSetUser);
  const { validities } = useFormValidation(user, setFormValid);

  return (
    <Form onSubmit={undefined}>
      <FormGroup>
        <NameField
          name={user.name}
          nameValid={validities.name}
          setUser={setUser}
          disabled={disabled}
        />
      </FormGroup>
      <FormGroup>
        <SurnameField
          surname={user.surname}
          surnameValid={validities.surname}
          setUser={setUser}
          disabled={disabled}
        />
      </FormGroup>
      <FormGroup>
        <EmailField
          email={user.email}
          emailValid={validities.email}
          setUser={setUser}
          disabled={disabled}
        />
      </FormGroup>
      <FormGroup noMargin>
        <RoleExpirationFields
          expiresAt={user.expiresAt}
          role={user.role}
          roleValid={validities.role}
          setUser={setUser}
          disabled={disabled}
        />
      </FormGroup>
    </Form>
  );
};
