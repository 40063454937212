/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserGetChartOrganizationResponse } from '../model';
// @ts-ignore
import { UserGetChartProjectResponse } from '../model';
// @ts-ignore
import { UserGetSummaryProjectResponse } from '../model';
// @ts-ignore
import { UserSummaryOrganizationResponse } from '../model';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the chart data for the dashboard of the organization with the given id, excluding data from archived projects
         * @param {string} organizationId Organization ID
         * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetChartOrganization: async (organizationId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('dashboardGetChartOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/dashboard/chart`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the chart data for the dashboard of the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetChartProject: async (organizationId: string, projectId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('dashboardGetChartProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('dashboardGetChartProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/dashboard/chart`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the organization summary data for the dashboard, excluding data from archived projects
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetSummaryOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('dashboardGetSummaryOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/dashboard/summary`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project summary data for the dashboard
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetSummaryProject: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('dashboardGetSummaryProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('dashboardGetSummaryProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/dashboard/summary`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the chart data for the dashboard of the organization with the given id, excluding data from archived projects
         * @param {string} organizationId Organization ID
         * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetChartOrganization(organizationId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetChartOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetChartOrganization(organizationId, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the chart data for the dashboard of the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetChartProject(organizationId: string, projectId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetChartProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetChartProject(organizationId, projectId, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the organization summary data for the dashboard, excluding data from archived projects
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetSummaryOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSummaryOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetSummaryOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the project summary data for the dashboard
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetSummaryProject(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetSummaryProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetSummaryProject(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * Returns the chart data for the dashboard of the organization with the given id, excluding data from archived projects
         * @param {string} organizationId Organization ID
         * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetChartOrganization(organizationId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: any): AxiosPromise<UserGetChartOrganizationResponse> {
            return localVarFp.dashboardGetChartOrganization(organizationId, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the chart data for the dashboard of the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetChartProject(organizationId: string, projectId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: any): AxiosPromise<UserGetChartProjectResponse> {
            return localVarFp.dashboardGetChartProject(organizationId, projectId, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the organization summary data for the dashboard, excluding data from archived projects
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetSummaryOrganization(organizationId: string, options?: any): AxiosPromise<UserSummaryOrganizationResponse> {
            return localVarFp.dashboardGetSummaryOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project summary data for the dashboard
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetSummaryProject(organizationId: string, projectId: string, options?: any): AxiosPromise<UserGetSummaryProjectResponse> {
            return localVarFp.dashboardGetSummaryProject(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - interface
 * @export
 * @interface DashboardApi
 */
export interface DashboardApiInterface {
    /**
     * Returns the chart data for the dashboard of the organization with the given id, excluding data from archived projects
     * @param {string} organizationId Organization ID
     * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    dashboardGetChartOrganization(organizationId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: AxiosRequestConfig): AxiosPromise<UserGetChartOrganizationResponse>;

    /**
     * Returns the chart data for the dashboard of the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    dashboardGetChartProject(organizationId: string, projectId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: AxiosRequestConfig): AxiosPromise<UserGetChartProjectResponse>;

    /**
     * Returns the organization summary data for the dashboard, excluding data from archived projects
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    dashboardGetSummaryOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<UserSummaryOrganizationResponse>;

    /**
     * Returns the project summary data for the dashboard
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    dashboardGetSummaryProject(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetSummaryProjectResponse>;

}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI implements DashboardApiInterface {
    /**
     * Returns the chart data for the dashboard of the organization with the given id, excluding data from archived projects
     * @param {string} organizationId Organization ID
     * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetChartOrganization(organizationId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetChartOrganization(organizationId, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the chart data for the dashboard of the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {Array<'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetChartProject(organizationId: string, projectId: string, filterCriteria?: Array<'period'>, filterOperators?: Array<'EQ'>, filterValues?: Array<'last_7_days' | 'last_30_days' | 'last_90_days' | 'last_180_days' | 'last_year' | 'current_month' | 'current_quarter' | 'current_year'>, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetChartProject(organizationId, projectId, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the organization summary data for the dashboard, excluding data from archived projects
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetSummaryOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetSummaryOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project summary data for the dashboard
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetSummaryProject(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetSummaryProject(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}
