import { t } from "i18next";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion as BootstrapAccordion,
} from "reactstrap";
import type { AccordionProps } from "./Accordion.i";

import type { Nullable } from "@Interfaces";
import { joinClasses } from "@Utils";
import "./Accordion.style.scss";

export const Accordion = ({ data }: AccordionProps) => {
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const [activeTab, setActiveTab] = useState(
    data.find(d => d.active)?.key ?? ""
  );

  const children = useMemo(() => {
    return data.map(({ children, header: itemHeader, key, iconClass }) => {
      let header = <>{itemHeader}</>;
      if (typeof itemHeader === "string") header = <>{t(itemHeader)}</>;
      return (
        <AccordionItem key={key} data-accordion={`accordion-${key}`}>
          <AccordionHeader targetId={key} data-cy={`accordion-header-${key}`}>
            <div className="w-100 pe-2">
              {iconClass && <i className={joinClasses(iconClass, "me-2")} />}
              {header}
            </div>
            <i className="bi bi-chevron-up"></i>
          </AccordionHeader>
          <AccordionBody
            accordionId={key}
            children={children}
            data-cy={`accordion-body-${key}`}
          />
        </AccordionItem>
      );
    });
  }, [data]);

  const handleToggle = useCallback((targedId: string) => {
    setActiveTab(active => (targedId === active ? "" : targedId));
  }, []);

  return (
    <BootstrapAccordion
      open={activeTab}
      toggle={handleToggle}
      innerRef={containerRef}
    >
      {children}
    </BootstrapAccordion>
  );
};
