import type { HeaderItems } from "@Interfaces";

export const getHeaders = (): HeaderItems => [
  { text: "common.name", sortByKey: "project_name" },
  { text: "common.status" },
  { text: "common.created-at", sortByKey: "project_created_at" },
  { text: "common.last-scan" },
  { text: "common.open-issues" },
  { text: "common.next-run" },
];
