import type { UserListIssuesResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";
import {
  filterAndSortIssueByParams,
  findIssuesOfOrganization,
  getFiltersFromUrlParams,
} from "src/Mock/utils";

export const getOrganizationIssues = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as Record<string, string>;

  if (!organizationId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const { searchParams } = req.url;

  let issues = findIssuesOfOrganization(organizationId);
  issues = filterAndSortIssueByParams(issues, searchParams);

  const { page, size } = getFiltersFromUrlParams(searchParams);
  const offset = (page - 1) * size;
  const limit = offset + size;

  const response: UserListIssuesResponse = {
    data: issues.slice(offset, limit),
    page,
    size,
    totalItems: issues.length,
    totalPages: Math.ceil(issues.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
