import { createAppendFunction } from ".";

export interface Args {
  start: number;
  length: number;
  lowerBound: number;
  upperBound: number;
}

export const getBoundedNeighbor = ({
  start,
  length,
  lowerBound,
  upperBound,
}: Args): number[] => {
  const output = [] as number[];
  if (start !== lowerBound && start !== upperBound) {
    output.push(start);
  }

  let increment = 1;
  let cycles = 0;

  const add = createAppendFunction({
    start,
    length,
    lowerBound,
    upperBound,
    output,
  });

  while (cycles < length) {
    const previous = start - increment;
    const next = start + increment;

    add(previous, next);

    if (output.length === length) {
      break;
    }
    cycles++;
    increment++;
  }
  return output;
};
