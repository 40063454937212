import { AdvancedSettingsForm, SettingsContainer } from "@Components";
import { useGetData } from "./useGetData";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const AdvancedSettings = () => {
  const { advancedSettings, loading } = useSettingsStore();
  useGetData();

  return (
    <div id="scan-advanced-settings" className="p-4">
      <SettingsContainer loading={loading} section="settings">
        <>
          {!!advancedSettings && (
            <AdvancedSettingsForm advancedSettings={advancedSettings} />
          )}
        </>
      </SettingsContainer>
    </div>
  );
};
