import type { HeaderItems } from "@Interfaces";

export const getHeaders = (forProject: boolean): HeaderItems => {
  const headers: HeaderItems = [
    { text: "common.project" },
    { text: "common.status" },
    { text: "" }, // triggered frm
    { text: "common.startedAt", sortByKey: "scan_started_at" },
    { text: "common.issues" },
    { text: "common.completedAt", sortByKey: "scan_completed_at" },
    { text: "common.id" },
  ];
  if (forProject) {
    headers.splice(0, 1); // hide project name in table
  }
  return headers;
};
