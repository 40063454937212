import { LoadingScreen, SkeletonBox } from "@Components";
import { memo, useMemo } from "react";
import { Container as RContainer } from "reactstrap";
import {
  AuthenticationsLoader,
  DictionaryLoader,
  ExtensionsLoader,
  InjectablesLoader,
  IssueExclusionLoader,
  PathExclusionLoader,
  SettingsLoader,
} from "./Loaders";
import type { Props } from "./SettingsContainer.i";

export const SettingsContainer = memo(
  ({ children, loading, section, hasError }: Props) => {
    const loadingSection = useMemo(() => {
      switch (section) {
        case "authentication":
          return (
            <SkeletonBox width="100%" height="100%">
              <AuthenticationsLoader />
            </SkeletonBox>
          );
        case "dictionary":
          return (
            <SkeletonBox width="100%" height="100%">
              <DictionaryLoader />
            </SkeletonBox>
          );
        case "settings":
          return (
            <SkeletonBox width="100%" height="100%">
              <SettingsLoader />
            </SkeletonBox>
          );
        case "path-exclusion":
          return (
            <SkeletonBox width="100%" height="100%">
              <PathExclusionLoader />
            </SkeletonBox>
          );
        case "issue-exclusion":
          return (
            <SkeletonBox width="100%" height="100%">
              <IssueExclusionLoader />
            </SkeletonBox>
          );
        case "injectables":
          return (
            <SkeletonBox width="100%" height="100%">
              <InjectablesLoader />
            </SkeletonBox>
          );
        case "extensions":
          return (
            <SkeletonBox width="100%" height="100%">
              <ExtensionsLoader />
            </SkeletonBox>
          );
        case "parameter-links":
          return <LoadingScreen />;
      }
    }, [section]);

    if (hasError) {
      return null;
    }

    return (
      <RContainer id="main-container" fluid>
        <div className="tab-content">{loading ? loadingSection : children}</div>
      </RContainer>
    );
  }
);
