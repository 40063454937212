import { useCallback, useMemo, useState } from "react";
import { getAddButtonProps } from "../../../Functions";
import type { Props } from "./DictionaryFormCanvas.i";
import type { DictionaryEntry } from "@Services";

const defaultEntry: DictionaryEntry = {
  key: "",
  value: "",
};

const useDictionaryFormCanvas = ({ isOpen, close, addEntry }: Props) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [entry, setEntry] = useState<DictionaryEntry>({ ...defaultEntry });

  useCallback(() => {
    // on canvas close, reset form model
    if (!isOpen) setEntry({ ...defaultEntry });
  }, [isOpen]);

  const saveButton = useMemo(() => {
    return getAddButtonProps({
      disabled,
      loading,
      onClick: () => {
        setLoading(true);
        addEntry(entry).then(success => {
          if (success) {
            close();
          }
          setLoading(false);
        });
      },
    });
  }, [disabled, loading, addEntry, close, entry]);

  return {
    canvas: {
      isOpen,
      close,
      loading,
    },
    setEntry,
    saveButton: {
      props: saveButton,
      setDisabled,
    },
  };
};

export default useDictionaryFormCanvas;
