import type { Nullable } from "@Interfaces";
import { trimByChar } from "@Services";

export const limitString = (str: string, maxLength: number) => {
  if (!str) {
    return "";
  }

  if (str.length <= maxLength) {
    return str;
  }

  if (maxLength < 0) {
    return "";
  }

  if (maxLength <= 3) {
    return str;
  }

  const leftPartLength = Math.ceil((maxLength - 3) / 2);
  const rightPartLength = Math.floor((maxLength - 3) / 2);

  const leftPart = str.substring(0, leftPartLength);
  const rightPart = str.substring(str.length - rightPartLength);

  return leftPart + "..." + rightPart;
};

export const joinClasses = (
  ...classes: Array<string | boolean | undefined>
) => {
  return classes.filter(c => !!c).join(" ");
};

export const containsLikeQuery = (string: string, search: string) => {
  let escapedSearch = search.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  escapedSearch = escapedSearch.replace(/\s+/g, ".*");
  const regex = new RegExp(escapedSearch, "i"); // 'i' makes the search case-insensitive
  return regex.test(string);
};

export const BEM = (
  block: string,
  element?: Nullable<string>,
  modifier?: Nullable<string>
): string => {
  let className = block;

  if (element) {
    className += `__${element}`;
  }

  if (modifier) {
    className += ` ${className}--${modifier}`;
  }

  return className ?? "";
};

export const slugify = (arg: string) => {
  const result = arg
    .trim()
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .toLowerCase()
    .replace(/\W+/g, "-");
  return trimByChar(result, "-");
};

const getHash = (inputString: string) => {
  // Simple hash function (djb2)
  let hash = 5381;
  for (let i = 0; i < inputString.length; i++) {
    hash = (hash << 5) + hash + inputString.charCodeAt(i); // hash * 33 + c
  }
  return hash >>> 0; // Force to unsigned 32-bit integer
};

const CHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/" as const;
const toBase64 = (num: number) => {
  // Convert the hash number to a Base64-like string (shorter representation)
  let base64 = "";
  while (num > 0) {
    base64 = CHARS[num % 64] + base64;
    num = Math.floor(num / 64);
  }
  return base64;
};

export const hashString = (inputString: string) => {
  const hash = getHash(inputString); // Generate hash
  const base64String = toBase64(hash); // Convert hash to Base64-like string
  return base64String; // Return shorter identifier
};
