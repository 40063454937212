import { useManageApiResponse, useUrlParams } from "@Hooks";
import { useCallback, useEffect, useState } from "react";
import { useOrganizationSpaceLinkStore } from "@Stores";
import {
  fetchSpaceLinkAssociatedProjects,
  fetchSpaceLinkDetails,
} from "@Services";

export const useSpaceLinkDetails = () => {
  const {
    spaceLinkDetails,
    setSpaceLinkDetails,
    setAssociatedProjects,
    reset,
  } = useOrganizationSpaceLinkStore();
  const [fetching, setFetching] = useState(true);

  const { organizationId, spaceLinkId } = useUrlParams();

  const manage = useManageApiResponse();

  const fetchSpaceLinks = useCallback(async () => {
    if (!organizationId || !spaceLinkId) throw new Error("invalid-url-params");
    setFetching(true);
    const { status } = await manage({
      promise: fetchSpaceLinkDetails(organizationId, spaceLinkId),
      onSuccess: response => setSpaceLinkDetails(response.data.spaceLink),
      onError: () => setSpaceLinkDetails(null),
    });
    if (status === 200) {
      await manage({
        promise: fetchSpaceLinkAssociatedProjects(organizationId, spaceLinkId),
        onSuccess: response => {
          setAssociatedProjects(response.data.data);
        },
        onError: () => {
          setSpaceLinkDetails(null);
          setAssociatedProjects(null);
        },
      });
    }
    setFetching(false);
  }, [
    manage,
    organizationId,
    setAssociatedProjects,
    setSpaceLinkDetails,
    spaceLinkId,
  ]);

  const refreshSpaceLink = useCallback(() => {
    if (fetching) return;
    fetchSpaceLinks();
  }, [fetchSpaceLinks, fetching]);

  useEffect(() => {
    fetchSpaceLinks();
  }, [fetchSpaceLinks]);

  useEffect(() => reset, [reset]);

  return { spaceLink: spaceLinkDetails, fetching, refreshSpaceLink };
};
