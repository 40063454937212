import type { AuthMatrixResponse } from "@Interfaces";

export const getAuthorizationMatrix = () => {
  const mockData: AuthMatrixResponse = {
    operations: [
      {
        api_definition_id: "1",
        operation_id: "1",
        path: "/api/resource/image",
        path_item_id: "1",
        predicted_category_semantics: "create",
        profiles: [
          {
            id: "34rf34dfsdf2",
            resolved: true,
          },
          {
            id: "4r34fssdc2ds",
            resolved: false,
          },
          {
            id: "345g5tg43r34",
            resolved: true,
          },
          {
            id: "976856gg4f4r",
            resolved: false,
          },
          {
            id: "0dd4f34ssvfdv",
            resolved: true,
          },
          {
            id: "",
            resolved: true,
          },
        ],
        verb: "GET",
      },
      {
        api_definition_id: "2",
        operation_id: "2",
        path: "/api/resource/blog",
        path_item_id: "2",
        predicted_category_semantics: "read",
        profiles: [
          {
            id: "34rf34dfsdf2",
            resolved: false,
          },
          {
            id: "4r34fssdc2ds",
            resolved: false,
          },
          {
            id: "0dd4f34ssvfdv",
            resolved: false,
          },
          {
            id: "345g5tg43r34",
            resolved: true,
          },
          {
            id: "976856gg4f4r",
            resolved: false,
          },
          {
            id: "",
            resolved: true,
          },
        ],
        verb: "POST",
      },
      {
        api_definition_id: "3",
        operation_id: "3",
        path: "/api/auth/login",
        path_item_id: "3",
        predicted_category_semantics: "login",
        profiles: [
          {
            id: "34rf34dfsdf2",
            resolved: true,
          },
          {
            id: "4r34fssdc2ds",
            resolved: true,
          },
          {
            id: "976856gg4f4r",
            resolved: true,
          },
          {
            id: "",
            resolved: true,
          },
        ],
        verb: "PATCH",
      },
      {
        api_definition_id: "4",
        operation_id: "4",
        path: "/api/auth/report",
        path_item_id: "4",
        predicted_category_semantics: "read",
        profiles: [
          {
            id: "34rf34dfsdf2",
            resolved: false,
          },
          {
            id: "4r34fssdc2ds",
            resolved: true,
          },
          {
            id: "0dd4f34ssvfdv",
            resolved: true,
          },
          {
            id: "",
            resolved: true,
          },
        ],
        verb: "GET",
      },
    ],
    profiles: [
      {
        group: "1",
        id: "34rf34dfsdf2",
        name: "User1",
        role: "admin",
      },
      {
        group: "2",
        id: "4r34fssdc2ds",
        name: "User2",
        role: "user",
      },
      {
        group: "1",
        id: "0dd4f34ssvfdv",
        name: "User3",
        role: "admin",
      },
      {
        group: "1",
        id: "345g5tg43r34",
        name: "User4",
        role: "user",
      },
      {
        group: "1",
        id: "976856gg4f4r",
        name: "User5",
        role: "admin",
      },
      {
        group: "",
        id: "",
        name: "",
        role: "",
      },
    ],
  };

  return mockData;
};
