import type { UserListIssuesResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  filterAndSortIssueByParams,
  getFiltersFromUrlParams,
  getOpenIssues,
} from "src/Mock";

export const getProjectOpenIssues = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params as Record<string, string>;

  if (!organizationId || !projectId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const { searchParams } = req.url;

  let openIssues = getOpenIssues(organizationId, projectId);
  openIssues = filterAndSortIssueByParams(openIssues, searchParams);

  const { page, size } = getFiltersFromUrlParams(searchParams);
  const offset = (page - 1) * size;
  const limit = offset + size;

  const response: UserListIssuesResponse = {
    data: openIssues.slice(offset, limit),
    page,
    size,
    totalItems: openIssues.length,
    totalPages: Math.ceil(openIssues.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
