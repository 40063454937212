import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getSummary } from "src/Mock";

export const getDashboardSummary = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params as {
    organizationId: string;
    projectId: string | undefined;
  };
  return res(ctx.delay(delay), ctx.json(getSummary(organizationId, projectId)));
};
