import { useMemo } from "react";
import {
  DropdownItem,
  DropdownMenu as BootstrapDropdownMenu,
} from "reactstrap";
import type { DropDownButtonsMenuProps } from "../DropdownButtons.i";
import { t } from "i18next";

export const DropdownMenu = ({
  buttons,
  setActiveIndex,
  activeIndex,
}: DropDownButtonsMenuProps) => {
  const buttonsList = useMemo(() => {
    return buttons.map((b, i) => {
      const activeItem = i === activeIndex;
      const handleSelection = () => {
        b.onSelect?.();
        setActiveIndex(i);
      };
      return (
        <DropdownItem
          key={i}
          onClick={handleSelection}
          className={activeItem ? "dropdown-item--active" : undefined}
          children={
            <div className="dropdown-item-content">
              {activeItem && <i className="bi bi-check2-circle" />}
              <span>{t(b.dropdownLabel || b.children)}</span>
            </div>
          }
        />
      );
    });
  }, [activeIndex, buttons, setActiveIndex]);

  return <BootstrapDropdownMenu>{buttonsList}</BootstrapDropdownMenu>;
};
