import { memo } from "react";
import { CurtesyPage } from "../../CurtesyPage";
import { t } from "i18next";

export const ArchivedProject = memo((props: { projectName: string }) => (
  <CurtesyPage>
    <h1>
      {t("common.project-x-has-been-archived", { name: props.projectName })}
    </h1>
    <p>{t("common.no-data-available-for-display")}</p>
  </CurtesyPage>
));
