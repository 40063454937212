import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API } from "@Services";
import { useCallback, useEffect, useState } from "react";
import { useProjectSettingsStore } from "../ProjectSettings.store";

export const useIssueExclusion = () => {
  const { issueExclusions, loading, setIssueExclusions, setLoading } =
    useProjectSettingsStore();
  const [hasError, setHasError] = useState(false);
  const { organizationId, projectId } = useUrlParams();

  const manageResponse = useManageApiResponse(setLoading);

  const fetch = useCallback(async () => {
    if (!organizationId || !projectId) return;
    await manageResponse({
      errorMessage: "get-issue-exclusions",
      promise: API.scanSettings().listProjectIssueExclusions(
        organizationId,
        projectId
      ),
      onError: () => {
        setHasError(true);
      },
      onSuccess: exclusions => {
        if (exclusions) {
          setHasError(false);
          setIssueExclusions(exclusions.data.data);
        }
      },
    });
  }, [organizationId, projectId, manageResponse, setIssueExclusions]);

  useEffect(() => {
    if (!organizationId || !projectId) return;
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, projectId]);

  return {
    issueExclusions,
    loading,
    hasError,
    refresh: fetch,
  };
};
