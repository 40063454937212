import {
  ConfirmDialog,
  DisplaySection,
  MethodBadge,
  TrashButton,
} from "@Components";
import type { Method as MethodI } from "@Interfaces";
import type { Props } from "./PathExclusionCard.i";
import { useState } from "react";

export const PathExclusionCard = ({
  disabled,
  loading,
  methods,
  path,
  deleteEntry,
}: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  return (
    <div className="path-exclusion-card editable-card">
      <DisplaySection
        className="overflow-wrap-anywhere"
        label={"common.path"}
        value={path}
      />
      <div className="mt-auto">
        <DisplaySection
          label="common.methods"
          className="mt-3 display-methods"
          value={
            <>
              {methods.map(m => (
                <MethodBadge
                  type={m as MethodI}
                  key={m}
                  className="me-1 mb-1"
                />
              ))}
            </>
          }
        />
      </div>
      <div className="card-actions">
        {!!deleteEntry && (
          <TrashButton
            onClick={() => setShowConfirmDialog(true)}
            loading={loading}
            disabled={disabled}
          />
        )}
      </div>
      {deleteEntry && showConfirmDialog && (
        <ConfirmDialog
          title="confirmation-dialog.title.delete-configuration"
          description="confirmation-dialog.description.delete-configuration"
          cancelLabel="confirmation-dialog.cancel.delete-configuration"
          confirmLabel="confirmation-dialog.confirm.delete-configuration"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={() => {
            setShowConfirmDialog(false);
            deleteEntry();
          }}
        />
      )}
    </div>
  );
};

export default PathExclusionCard;
