import { t } from "i18next";
import { Offcanvas } from "../Offcanvas/OffCanvas";
import type { LongTextOffcanvasProps } from "./LongText.i";
import type { FC } from "react";

export const LongTextOffcanvas: FC<LongTextOffcanvasProps> = ({
  showLongTextModal,
  setShowLongTextModal,
  text,
}) => (
  <Offcanvas
    id="show-long-text"
    title={t(`common.${showLongTextModal}`)}
    isOpen={!!showLongTextModal}
    toggle={() => setShowLongTextModal(false)}
    exitLabel="common.close"
  >
    {showLongTextModal ? text : ""}
  </Offcanvas>
);
