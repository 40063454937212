import type { UserUpdateRequest } from "@Services";
export type FormData = Pick<UserUpdateRequest, "name" | "surname">;

export const defaultData: FormData = {
  name: "",
  surname: "",
};

export const defaultValidities: Record<keyof FormData, boolean> = {
  name: false,
  surname: false,
};
