import { memo, type FC } from "react";
import isEqual from "react-fast-compare";
import { DangerZone, GridContainer, Section } from "@Components";
import { SpaceLinkData } from "../SpaceLinkData";
import { SpaceLinkEdit, SpaceLinkAssociatedProject } from "../";
import type { SpaceLinkDetailsContentProps } from "./SpaceLinkDetailsContent.i";
import { useSpaceLinkDetailsContent } from "./useSpaceLinkDetailsContent";
import { t } from "i18next";

const alerts = [
  {
    level: "error" as const,
    children: t("organization.delete-associated-space-link"),
  },
];

export const SpaceLinkDetailsContent: FC<SpaceLinkDetailsContentProps> = memo(
  props => {
    const {
      editEnabled,
      spaceLink,
      hasAssociatedProjects,
      onSpaceLinkDelete,
      refresh,
      spaceLinkDelete,
    } = useSpaceLinkDetailsContent(props);

    const additionalAlerts = hasAssociatedProjects ? alerts : undefined;

    return (
      <div id="space-link-details">
        <GridContainer className="space-link-controls">
          <Section title="common.space-links">
            <GridContainer className="info-container">
              <SpaceLinkData />
              <>{editEnabled && <SpaceLinkEdit refresh={refresh} />}</>
            </GridContainer>
          </Section>

          <DangerZone
            confirmationText={spaceLink.name}
            subject={spaceLink.name}
            promise={spaceLinkDelete}
            onSuccess={onSpaceLinkDelete}
            target="space-link"
            toastErrorMessage="delete-space-link"
            toastSuccessMessage="delete-space-link"
            additionalAlerts={additionalAlerts}
          />
        </GridContainer>

        <Section title="common.projects">
          <SpaceLinkAssociatedProject />
        </Section>
      </div>
    );
  },
  isEqual
);
