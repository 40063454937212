import type { LoggedUser } from "@Interfaces";
import { getUserFromCache } from ".";

type LoginStatus = "guest" | "otp" | "authenticated";

let _user: LoggedUser | undefined = getUserFromCache();
let _loginStatus: LoginStatus = "guest";

const sessionKey = "user-email";

export const getAuthUserContext = () => ({
  getUser: () => _user,
  setUser: (user: LoggedUser | undefined) => {
    if (user) {
      localStorage.setItem(sessionKey, user.email);
      _user = user;
    } else {
      localStorage.removeItem(sessionKey);
      _user = undefined;
    }
  },
  getLoginPhase: () => _loginStatus,
  setLoginPhase: (loginStatus: LoginStatus) => {
    _loginStatus = loginStatus;
  },
  reset: () => {
    _user = undefined;
    _loginStatus = "guest";
  },
});
