const cache = () => {
  const cachedData: {
    [key: string]: string | null | number;
  } = {};

  // eslint-disable-next-line no-restricted-globals
  self.onmessage = ({ data: { type, key, data } }) => {
    if (type === "SET_CACHE") {
      cachedData[key] = data;
    }
    if (type === "GET_CACHE") {
      postMessage(cachedData[key]);
    }
  };
};

export default cache;
