import type { PlainObject } from "@Interfaces";

const MAIN_KEYWORD = "preferences";

export const getPreference = (
  key: string,
  defaultValue?: string
): string | null => {
  try {
    const preferences = readFromStorage();
    return preferences[key] || defaultValue || null;
  } catch (e) {
    return null;
  }
};

export const setPreference = (key: string, value: any): boolean => {
  try {
    const preferences = readFromStorage();
    preferences[key] = value;
    return writeIntoStorage(preferences);
  } catch (e) {
    return false;
  }
};

export const setPreferenceByRegex = (regex: RegExp, value: any): boolean => {
  try {
    const preferences = readFromStorage();
    const allKeys = Object.keys(preferences).filter(key => key.match(regex));

    for (const keyPref of allKeys) {
      preferences[keyPref] = value;
    }
    return writeIntoStorage(preferences);
  } catch (e) {
    return false;
  }
};

export const clearPreferencesByRegex = (regex: RegExp): boolean => {
  return setPreferenceByRegex(regex, null);
};

/**
 * Clear all preferences that starts with the given key
 * @param key the preferences prefix (without the dot separato)
 * @returns true | false the result of the operation
 */
export const clearAllPrefs = (key: string) => {
  return clearPreferencesByRegex(new RegExp(`^(${key}.)`, "gim"));
};

// utils

const readFromStorage = (): PlainObject<string> => {
  try {
    const prefs = localStorage.getItem(MAIN_KEYWORD);
    return JSON.parse(prefs ?? "");
  } catch (e) {
    return {};
  }
};

const writeIntoStorage = (preferences: PlainObject<string>): boolean => {
  try {
    const str = JSON.stringify(
      Object.fromEntries(Object.entries(preferences).filter(([, v]) => !!v))
    );
    localStorage.setItem(MAIN_KEYWORD, str);
    return true;
  } catch (e) {
    return false;
  }
};
