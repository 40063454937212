import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";
import { PARAMETER_GRAPHS_BY_PROJECT } from "src/Mock/data/parameter-graph";

export const getParameterGraph = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { params } = req;
  const { organizationId, projectId } = params;

  if (!organizationId || !projectId) {
    return res(ctx.delay(delay), ctx.status(403));
  }

  return res(
    ctx.delay(delay),
    ctx.status(200),
    ctx.json(
      PARAMETER_GRAPHS_BY_PROJECT[projectId as string] ?? {
        nodes: [],
        edges: [],
      }
    )
  );
};
