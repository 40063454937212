import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import { API, getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getHeaders } from "./Functions";
import { useTableConfig } from "./useTableConfig";

export const useApiDefinitions = () => {
  const defaultFilters = useMemo(() => {
    return getDefaultFilters("apidefinitions");
  }, []);

  const { fetch, loading, response } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      return API.apiDefinitions().apiDefinitionListInProject(
        args.organizationId,
        args.projectId,
        args.page,
        args.pageSize,
        args.sortBy as "api_definition_created_at" | undefined,
        args.sortMode
      );
    }, []),
  });

  const { config, longTextCanvas } = useTableConfig(response?.data);

  const { search } = useLocation();
  const updateFilters = useUpdateCacheFilters("project.api-definitions");
  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultFilters,
      items: getHeaders(),
      search,
      updateFunction: updateFilters,
    });
  }, [defaultFilters, search, updateFilters]);

  return {
    config,
    longTextCanvas,
    loading,
    fetch,
    headers,
    defaultFilters,
    updateFilters,
    response,
  };
};
