import { CYPRESS_RUNNING } from "@Constants";
import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API, type ListedProject } from "@Services";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

enum EPendingProjectStatus {
  UNKNOWN,
  EXISTS,
  NOT_EXISTS,
}

export const usePendingProject = (projects?: ListedProject[]) => {
  const { organizationId } = useUrlParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [pendingProjectStatus, setPendingProjectExists] =
    useState<EPendingProjectStatus>(EPendingProjectStatus.UNKNOWN);

  const manageResponse = useManageApiResponse();

  const fetchPendingProjects = useCallback(async () => {
    if (!organizationId) {
      throw new Error("organizationId is missing.");
    }
    manageResponse({
      promise: API.project().projectList(
        organizationId,
        1,
        1,
        "project_created_at",
        "desc",
        ["project_status"],
        ["EQ"],
        ["pending"]
      ),
      errorMessage: "get-projects",
      onSuccess: axiosResponse => {
        const { totalItems } = axiosResponse.data;
        setPendingProjectExists(
          totalItems > 0
            ? EPendingProjectStatus.EXISTS
            : EPendingProjectStatus.NOT_EXISTS
        );
        setLoading(false);
      },
      onError: () => {
        setPendingProjectExists(EPendingProjectStatus.UNKNOWN);
        setLoading(false);
      },
    });
  }, [manageResponse, organizationId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkPendingProjects = useCallback(
    debounce(
      (projects: ListedProject[]) => {
        setLoading(true);
        if (!projects.length) {
          // in this case we don't know the exact situation, we might have zero projects with or without a filter
          // but as we don't know, we need to look for a pending project with a specific query
          fetchPendingProjects();
          return;
        }

        if (projects.find(p => p.status === "pending")) {
          // we have a pending project whitin the loaded data
          setPendingProjectExists(EPendingProjectStatus.EXISTS);
          setLoading(false);
          return;
        }

        // worst case, we don't have a pending project inside the first pagination
        // let's try with a specific query
        fetchPendingProjects();
      },
      CYPRESS_RUNNING ? 0 : 1_000
    ),
    [fetchPendingProjects]
  );

  useEffect(() => {
    if (pendingProjectStatus === EPendingProjectStatus.NOT_EXISTS) {
      return;
    }

    const interval = setInterval(
      () => checkPendingProjects(projects ?? []),
      60_000
    );

    checkPendingProjects(projects ?? []);
    return () => clearInterval(interval);
  }, [checkPendingProjects, projects, pendingProjectStatus]);

  return {
    loading,
    pendingProjectExists: pendingProjectStatus === EPendingProjectStatus.EXISTS,
  };
};
