import type { ProjectId } from "@Interfaces";
import { projectInfos, projects } from "src/Mock/data";

export const getProjectById = (id: ProjectId) => {
  return projects.find(p => p.id === id);
};

export const getProjectInfoById = (id: ProjectId) => {
  return projectInfos.find(p => p.id === id);
};
