import { memo } from "react";
import { ScansPage } from "@Pages";
import { useScans } from "./useScans";

const HomeScans = memo(() => {
  const { fetch, loading, response } = useScans();
  return (
    <ScansPage
      forProject={false}
      loading={loading}
      refreshScans={fetch}
      response={response}
    />
  );
});

export default HomeScans;
