import { AuthContext } from "@Contexts";
import { memo, useContext, useMemo, useState } from "react";
import { SkeletonPage } from "../../Components";

import { AvatarGenerator, CubesSVG, Tabs, type TabProps } from "@Components";
import { BEM } from "@Utils";
import isEqual from "react-fast-compare";
import { ProfileDetailsPageGeneralTab } from "./Tabs/ProfileDetailsPageGeneralTab";
import { ProfileDetailsPageRolesTab } from "./Tabs/ProfileDetailsPageRolesTab/ProfileDetailsPageRolesTab";
import { ProfileDetailsPageSecurityTab } from "./Tabs/ProfileDetailsPageSecurityTab";
import { ProfileDetailsPageSettingsTab } from "./Tabs/ProfileDetailsPageSettingsTab";

const tabs: Array<TabProps> = [
  {
    label: "common.general",
    iconClass: "bi bi-info-square-fill",
  },
  {
    label: "common.security",
    iconClass: "bi bi-shield-fill",
  },
  {
    label: "common.roles",
    iconClass: "bi bi-patch-check-fill",
  },
  {
    label: "common.settings",
    iconClass: "bi bi-gear-wide-connected",
  },
];

const tabsComponentMapping = [
  <ProfileDetailsPageGeneralTab />,
  <ProfileDetailsPageSecurityTab />,
  <ProfileDetailsPageRolesTab />,
  <ProfileDetailsPageSettingsTab />,
];

export const ProfileDetailsPage = memo(() => {
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState<number>(0);

  const generalSection = useMemo(
    () =>
      user && (
        <div className={BEM("profile-details", "general-info")}>
          <AvatarGenerator user={user} />
          <div>
            <h1 className="color-pink m-0">
              {user.name} {user.surname}
            </h1>
            <span>{user.email}</span>
          </div>
        </div>
      ),
    [user]
  );

  if (!user) {
    return <SkeletonPage />;
  }

  return (
    <div id="profile-details" className="profile-details">
      <div className={BEM("profile-details", "wrapper")}>
        {generalSection}
        <Tabs
          className={BEM("profile-details", "tabs")}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
          updateUrl
        />
        {tabsComponentMapping[activeTab]}
      </div>
      <div className={BEM("profile-details", "background")}>
        <CubesSVG />
      </div>
    </div>
  );
}, isEqual);
