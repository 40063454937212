import type { Inventory } from "@Interfaces";

const getRandomCreatedFrom = () => {
  return Math.random() < 0.2 ? "FROM_EXTENSION" : "FROM_OAS";
};

export const inventoryItems: Inventory[] = [
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "register",
    dataFormatInputs: ["username", "password", "email"],
    dataFormatOutputs: ["token"],
    id: "1234",
    path: "/products",
    tags: ["authentication", "user registration"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "login",
    dataFormatInputs: ["username", "password"],
    dataFormatOutputs: ["token"],
    id: "5678",
    path: "/users",
    tags: ["authentication", "user login"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "logout",
    dataFormatInputs: ["token"],
    dataFormatOutputs: [],
    id: "9101",
    path: "/orders",
    tags: ["authentication", "user logout"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["username", "email", "coords"],
    dataFormatOutputs: [],
    id: "2345",
    path: "/customers",
    tags: ["user creation", "data creation"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: [],
    dataFormatOutputs: ["username", "email", "coords"],
    id: "6789",
    path: "/auth",
    tags: ["user data retrieval", "data reading"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["username", "email", "coords"],
    dataFormatOutputs: [],
    id: "1112",
    path: "/inventory",
    tags: ["user data update", "data updating"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "1314",
    path: "/payments",
    tags: ["user data deletion", "data deleting"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number"],
    dataFormatOutputs: ["datetime", "phone_number"],
    id: "1516",
    path: "/reviews",
    tags: ["product reviews", "feedback"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["datetime", "email"],
    dataFormatOutputs: ["datetime", "email"],
    id: "1718",
    path: "/reports",
    tags: ["report generation", "analytics"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["coords", "token"],
    dataFormatOutputs: ["coords", "token"],
    id: "1920",
    path: "/messages",
    tags: ["messaging service", "communication"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["url", "filepath"],
    dataFormatOutputs: ["url", "filepath"],
    id: "2122",
    path: "/notifications",
    tags: ["notification system", "alerts"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["ip", "hostname"],
    dataFormatOutputs: ["ip", "hostname"],
    id: "2324",
    path: "/settings",
    tags: ["user settings", "preferences"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["barcode", "postcode"],
    dataFormatOutputs: ["barcode", "postcode"],
    id: "2526",
    path: "/feedback",
    tags: ["customer feedback", "suggestions"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["user_agent", "plate"],
    dataFormatOutputs: ["user_agent", "plate"],
    id: "2728",
    path: "/dashboard",
    tags: ["user dashboard", "data visualization"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["ssn", "coords"],
    dataFormatOutputs: ["ssn", "coords"],
    id: "2929",
    path: "/analytics",
    tags: ["analytics", "data analysis"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },

  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["address", "email"],
    dataFormatOutputs: ["address", "email"],
    id: "3031",
    path: "/shipping",
    tags: ["shipping services", "logistics"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["phone_number", "iban"],
    dataFormatOutputs: ["phone_number", "iban"],
    id: "3233",
    path: "/billing",
    tags: ["billing system", "invoices"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["datetime", "phone_number"],
    dataFormatOutputs: ["datetime", "phone_number"],
    id: "3435",
    path: "/calendar",
    tags: ["event scheduling", "appointments"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "coords"],
    dataFormatOutputs: ["datetime", "coords"],
    id: "3637",
    path: "/news",
    tags: ["news updates", "media"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "4445",
    path: "/statistics",
    tags: ["data statistics", "metrics"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "4647",
    path: "/images",
    tags: ["image processing", "media"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },

  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "6061",
    path: "/catalog",
    tags: ["product catalog", "e-commerce"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "6263",
    path: "/auth2",
    tags: ["user authentication", "security"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "6465",
    path: "/search",
    tags: ["search engine", "query"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "6869",
    path: "/subscription",
    tags: ["subscription management", "renewal"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "8081",
    path: "/blog",
    tags: ["blog posts", "content"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: [],
    dataFormatOutputs: [],
    id: "8283",
    path: "/events",
    tags: ["event management", "calendar"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "8989",
    path: "/documents",
    tags: ["document management", "files"],
    verb: "POST",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "9393",
    path: "/media",
    tags: ["media management", "content"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "email", "username", "url"],
    id: "6588",
    path: "/surveys",
    tags: ["user feedback", "data collection"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "email", "username", "url"],
    id: "6853",
    path: "/image-gallery",
    tags: ["image gallery", "media"],
    verb: "PUT",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "11234",
    path: "/products",
    tags: ["ecommerce", "REST API"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "email", "username", "url"],
    id: "15678",
    path: "/users",
    tags: ["authentication", "user management"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime"],
    dataFormatOutputs: ["datetime"],
    id: "19101",
    path: "/orders",
    tags: ["order processing", "inventory management"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "12345",
    path: "/customers",
    tags: ["customer data", "REST API"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["email", "password"],
    dataFormatOutputs: ["email", "password"],
    id: "16789",
    path: "/auth",
    tags: ["authentication", "security"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "coords", "ip"],
    dataFormatOutputs: ["datetime", "coords", "ip"],
    id: "11112",
    path: "/inventory",
    tags: ["inventory data", "management"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "11314",
    path: "/payments",
    tags: ["payment processing", "financial"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["datetime", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "email", "username", "url"],
    id: "11516",
    path: "/reviews",
    tags: ["product reviews", "feedback"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "11718",
    path: "/reports",
    tags: ["report generation", "analytics"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "11920",
    path: "/messages",
    tags: ["messaging service", "communication"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "12122",
    path: "/notifications",
    tags: ["notification system", "alerts"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["email", "password"],
    dataFormatOutputs: ["email", "password"],
    id: "12324",
    path: "/settings",
    tags: ["user settings", "preferences"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "12526",
    path: "/feedback",
    tags: ["customer feedback", "suggestions"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "12728",
    path: "/dashboard",
    tags: ["user dashboard", "data visualization"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "12929",
    path: "/analytics",
    tags: ["analytics", "data analysis"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["credit_card_number", "iban"],
    dataFormatOutputs: ["credit_card_number", "iban"],
    id: "13031",
    path: "/shipping",
    tags: ["shipping services", "logistics"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["credit_card_number", "iban", "swift"],
    dataFormatOutputs: ["credit_card_number", "iban", "swift"],
    id: "13233",
    path: "/billing",
    tags: ["billing system", "invoices"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "13435",
    path: "/calendar",
    tags: ["event scheduling", "appointments"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "13637",
    path: "/news",
    tags: ["news updates", "media"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "14445",
    path: "/statistics",
    tags: ["data statistics", "metrics"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["credit_card_number", "iban", "swift"],
    dataFormatOutputs: ["credit_card_number", "iban", "swift"],
    id: "14647",
    path: "/images",
    tags: ["image processing", "media"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "email", "username", "password", "url"],
    dataFormatOutputs: ["datetime", "email", "username", "password", "url"],
    id: "16061",
    path: "/catalog",
    tags: ["product catalog", "e-commerce"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["email", "password"],
    dataFormatOutputs: ["email", "password"],
    id: "16263",
    path: "/auth2",
    tags: ["user authentication", "security"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "16465",
    path: "/search",
    tags: ["search engine", "query"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "16869",
    path: "/subscription",
    tags: ["subscription management", "renewal"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "update",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "18081",
    path: "/blog",
    tags: ["blog posts", "content"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "18283",
    path: "/events",
    tags: ["event management", "calendar"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: false,
    baseURL: "https://api.example.com",
    category: "create",
    dataFormatInputs: ["credit_card_number", "iban", "swift"],
    dataFormatOutputs: ["credit_card_number", "iban", "swift"],
    id: "18989",
    path: "/documents",
    tags: ["document management", "files"],
    verb: "PATCH",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "19393",
    path: "/media",
    tags: ["media management", "content"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "delete",
    dataFormatInputs: ["datetime", "phone_number", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "phone_number", "email", "username", "url"],
    id: "453457",
    path: "/surveys",
    tags: ["user feedback", "data collection"],
    verb: "DELETE",
    createdFrom: getRandomCreatedFrom(),
  },
  {
    authenticationRequired: true,
    baseURL: "https://api.example.com",
    category: "read",
    dataFormatInputs: ["datetime", "email", "username", "url"],
    dataFormatOutputs: ["datetime", "email", "username", "url"],
    id: "14345",
    path: "/image-gallery",
    tags: ["image gallery", "media"],
    verb: "GET",
    createdFrom: getRandomCreatedFrom(),
  },
];

export function getInventoryItemsByApiDefinitionId(apiDefinitionId: string) {
  const getIndex = (id: string) =>
    (id.split("") as unknown as number[]).reduce(
      (a, b) => Number(a) + Number(b)
    );
  const sum = getIndex(apiDefinitionId);
  return [...inventoryItems].filter(i => {
    const index = getIndex(i.id);
    return index > sum * 0.7 && index < sum * 1.3;
  });
}
