import { useCallback } from "react";
import { API } from "@Services";
import { useUrlParams } from "@Hooks";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_SPACE_LINKS } from "@Constants";
import type { SpaceLinkDetailsContentProps } from "./SpaceLinkDetailsContent.i";
import { useOrganizationSpaceLinkStore } from "@Stores";

export const useSpaceLinkDetailsContent = (
  props: SpaceLinkDetailsContentProps
) => {
  const { spaceLinkDetails: spaceLink, associatedProjects } =
    useOrganizationSpaceLinkStore();

  const { organizationId } = useUrlParams();
  const navigate = useNavigate();

  // at this point, store's spaceLink should always be populated
  if (!spaceLink) throw new Error("invalid-store-status");

  const spaceLinkDelete = useCallback(() => {
    if (!organizationId) throw new Error("invilid-url-params");
    if (!spaceLink?.id) throw new Error("missing-space-link-id");
    return API.spaceLink().spaceLinkDelete(organizationId, spaceLink.id);
  }, [spaceLink?.id, organizationId]);

  const onSpaceLinkDelete = useCallback(() => {
    if (!organizationId) return;
    navigate(generateUrl(ORGANIZATION_PATH_SPACE_LINKS, [organizationId]));
  }, [navigate, organizationId]);

  return {
    ...props,
    editEnabled: !spaceLink?.expired,
    spaceLink,
    spaceLinkDelete,
    onSpaceLinkDelete,
    hasAssociatedProjects: !!associatedProjects?.length,
  };
};
