import { DisplayRowProjectDictionary, SettingsContainer } from "@Components";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const Dictionary = () => {
  const { loading, dictionarySettings } = useSettingsStore();

  return (
    <div id="scan-dictionary" className="p-4">
      <SettingsContainer loading={loading} section="dictionary">
        <DisplayRowProjectDictionary
          dictionary={dictionarySettings.entries ?? []}
        />
      </SettingsContainer>
    </div>
  );
};
