import type { Dispatch, FC } from "react";
import { FormInput } from "../../FormInput";
import type { SimpleChangeEvent } from "../UserForm.i";

type NameSurnameProps = {
  name: string;
  setUser: Dispatch<SimpleChangeEvent>;
  nameValid: boolean;
  disabled?: boolean;
};

export const NameField: FC<NameSurnameProps> = ({
  name,
  nameValid,
  disabled,
  setUser,
}) => (
  <FormInput
    label="common.name"
    id="user-form-input-name"
    value={name}
    name="name"
    onChange={setUser}
    type="text"
    valid={nameValid}
    invalid={!nameValid}
    disabled={disabled}
    autoComplete="off"
    autoTrim
  />
);
