import { ErrorPlaceholder, LicenseCard, Section } from "@Components";
import { useUpdatedOrganizationLicense } from "@Hooks";
import { memo, useMemo } from "react";
import { Loader } from "@storybook/components";

export const OrganizationPlan = memo(() => {
  const { license, loading } = useUpdatedOrganizationLicense();

  const content = useMemo(() => {
    if (loading) {
      return <Loader />;
    }

    return license ? (
      <LicenseCard license={license} className="p-0 border-0 h-100" />
    ) : (
      <ErrorPlaceholder error="get-license" />
    );
  }, [license, loading]);

  return (
    <div id="organization-plan" className="h-100">
      <Section className="h-100" title="common.license" children={content} />
    </div>
  );
});
