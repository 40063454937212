import { getSwaggerClient, stringToBinary } from "@Services";
import type { ParserFunction } from "../../types.i";
import { parseOpenApi, unparsableEntityHandler } from "./functions";

export const swaggerParser: ParserFunction = async (inputSpec, textFile) => {
  const { spec, hasExternalReferences } = await getSwaggerClient({
    spec: inputSpec,
  });

  if (!spec) {
    unparsableEntityHandler();
    return;
  }

  const parsedData = parseOpenApi(spec);

  return {
    ...parsedData,
    oasUploadData: stringToBinary(textFile),
    hasExternalReferences,
  };
};
