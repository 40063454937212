import { useUrlParams } from "@Hooks";
import { API, equals, type IssueExclusion } from "@Services";
import { useEffect, useMemo, useState } from "react";
import { getSaveButtonProps } from "../Functions";

export const useEditCanvas = (
  close: VoidFunction,
  refresh: () => Promise<void>,
  issueExclusion?: IssueExclusion
) => {
  const [loading, setLoading] = useState(false);
  const [issueExclusionForm, setIssueExclusionForm] = useState(issueExclusion);
  const { organizationId, projectId } = useUrlParams();

  useEffect(() => {
    setIssueExclusionForm(issueExclusion);
  }, [issueExclusion]);

  const saveButton = useMemo(() => {
    return getSaveButtonProps({
      disabled: equals(issueExclusion || {}, issueExclusionForm || {}),
      loading,
      onClick: async () => {
        if (
          !issueExclusion ||
          !issueExclusionForm ||
          !organizationId ||
          !projectId
        )
          return;
        setLoading(true);
        await API.scanSettings().issueExclusionUpdate(
          organizationId,
          projectId,
          issueExclusion.id,
          issueExclusionForm
        );
        await refresh();
        close();
        setLoading(false);
      },
    });
  }, [
    loading,
    issueExclusion,
    organizationId,
    projectId,
    issueExclusionForm,
    close,
    refresh,
  ]);

  return {
    loading,
    issueExclusionForm,
    setIssueExclusionForm,
    saveButton: {
      props: saveButton,
    },
  };
};
