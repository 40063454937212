export const addLineToCode = (code: string) => {
  const splittedIntoLine = code.split("\n");

  // this is needed to understand how many digits we have for line numbers (e.g. maximum line is 1000 we have 4 digits)
  const lineMaxDigitCount = `${splittedIntoLine.length}`.length;

  return splittedIntoLine
    .map((code: string, lineNumber: number) =>
      `<span class="line-number" data-length="${lineMaxDigitCount}">${lineNumber + 1}</span>${code}`.trim()
    )
    .join("\n");
};

export const getFormattedHttp = (source: string) => {
  const splitters = ["\r\n\r\n", "\n\n"];

  const [headers, body] = splitters.reduce((res, splitter) => {
    if (res.length === 2) return res;
    const headersAndBody = source.split(splitter);
    if (headersAndBody.length === 2) return headersAndBody;
    return [];
  }, [] as string[]);

  let result = source;

  try {
    const bodyFormatted = JSON.stringify(JSON.parse(body), null, 2);
    result = [headers, bodyFormatted].join("\r\n\r\n");
  } catch (e) {
    // do nothing
  }

  return result.trim();
};
