import { t } from "i18next";
import { Button } from "@Components";
import type { Props } from "./NoApiKeys.i";

export const NoApiKeys = ({ openCanvas }: Props) => {
  return (
    <div
      id="no-api-key-container"
      className="h-100 d-flex align-items-center justify-content-center flex-column px-4"
    >
      <h1>{t(`organization.api-keys`)}</h1>
      <p className="text-center">{t(`organization.add-api-key-text`)}</p>
      <Button
        children="organization.add-api-key"
        color="primary"
        onClick={openCanvas}
        data-cy="button-add-api-key"
        iconClass="bi bi-plus-square-fill"
      />
    </div>
  );
};
