import { useValidDomain } from "@Hooks";
import type { Options } from "@Interfaces";
import type {
  AuthenticationSettingsSchemeType,
  UserAuthToken,
} from "@Services";
import { capitalizeString, isValid } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import { set } from "lodash";
import { type ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { locations } from "../../../../constants";

const EMPTY_TOKEN: UserAuthToken = {
  authenticationSchemeType: "" as AuthenticationSettingsSchemeType,
  key: "",
  location: "",
  value: "",
};

export const useOAuthCredentialsForm = () => {
  const {
    authenticationSettings,
    loading,
    setSubFormValidity,
    setAuthenticationSettings,
  } = useAuthenticationSettingFormStore();

  const { isDomainEnabled, enabledDomains } = useValidDomain();

  // Validation Logic
  const calculateValidities = useCallback(() => {
    const token: UserAuthToken =
      authenticationSettings.userAuthTokens?.[0] ?? EMPTY_TOKEN;
    return {
      authenticationSchemeType: !!token.authenticationSchemeType,
      tokenLocation: !!token.authenticationSchemeType,
      tokenEndpoint:
        !!authenticationSettings.oAuthData?.tokenEndpoint &&
        isValid("url", authenticationSettings.oAuthData?.tokenEndpoint) &&
        isDomainEnabled(authenticationSettings.oAuthData?.tokenEndpoint),
      revocationEndpoint:
        !authenticationSettings.oAuthData?.revocationEndpoint ||
        isDomainEnabled(authenticationSettings.oAuthData?.revocationEndpoint),
      clientID: !!authenticationSettings.oAuthData?.clientID,
      clientSecret: !!authenticationSettings.oAuthData?.clientSecret,
      tokenOutputKey: !!token.key,
      username:
        authenticationSettings.oAuthData?.grantType === "password"
          ? !!authenticationSettings.oAuthData.username
          : true,
      password:
        authenticationSettings.oAuthData?.grantType === "password"
          ? !!authenticationSettings.oAuthData.password
          : true,
    };
  }, [authenticationSettings, isDomainEnabled]);

  const { valid, validities } = useMemo(() => {
    const validities = calculateValidities();
    const valid = Object.values(validities).every(Boolean);
    return { valid, validities };
  }, [calculateValidities]);

  // Handle Input Updates
  const handleUpdate = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setAuthenticationSettings(prev => set(prev, name, value));
    },
    [setAuthenticationSettings]
  );

  // Location Options Generation
  const generateLocationOptions = useCallback((): Options => {
    const token: UserAuthToken =
      authenticationSettings.userAuthTokens?.[0] ?? EMPTY_TOKEN;
    const allOptions = locations.map(location => ({
      label: capitalizeString(location),
      value: location,
    }));

    switch (token.authenticationSchemeType) {
      case "api-key":
        return allOptions;
      case "http-basic":
      case "http-bearer":
        return allOptions.filter(({ value }) => value === "header");
      default:
        return [];
    }
  }, [authenticationSettings.userAuthTokens]);

  const locationOptions = useMemo(
    () => generateLocationOptions(),
    [generateLocationOptions]
  );

  // Update SubForm Validity
  useEffect(() => {
    setSubFormValidity("flow", valid);
  }, [setSubFormValidity, valid]);

  return {
    authenticationSettings,
    inputOperations: authenticationSettings.inputOperations,
    validities,
    locationOptions,
    enabledDomains,
    loading,
    handleUpdate,
  };
};
