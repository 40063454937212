import { setAxiosAuth, setTokenIntoCache } from "@Services";

export const storeJwtToken = async (token: string, callback?: VoidFunction) => {
  setAxiosAuth(token);
  setTokenIntoCache(token);
  callback?.();
};

export const clearJwtToken = async (callback?: VoidFunction) => {
  setAxiosAuth(null);
  setTokenIntoCache(null);
  callback?.();
};
