import type { Chart, ChartType } from "chart.js";
import { gap } from "./functions";

export interface SectorsSizePluginOptions {
  enabled?: boolean;
}

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType = ChartType> {
    sectorsSize: SectorsSizePluginOptions;
  }
}

export const SectorsSizePlugin = {
  id: "sectorsSize",

  defaults: {
    enabled: false,
  } as SectorsSizePluginOptions,

  beforeDatasetDraw(
    chart: Chart,
    args: any,
    options: SectorsSizePluginOptions
  ) {
    if (!options.enabled) {
      return;
    }
    const data = args.meta.data;
    const increment = gap(data);
    for (let i = 0; i < data.length; i++) {
      data[i].outerRadius = increment * (i + 1) + data[i].innerRadius;
    }
  },
};
