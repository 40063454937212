import { useManageApiResponse, useUrlParams } from "@Hooks";
import type {
  ExtensionSettings,
  UserUpdateExtensionSettingsRequest,
} from "@Services";
import { API, isValidExtensionSettings } from "@Services";
import { isStatusCodeSuccess } from "@Utils";
import type { AxiosPromise } from "axios";
import { useCallback, useEffect, useState } from "react";

type PromiseType = null | AxiosPromise<{
  extensionSettings: ExtensionSettings;
}>;

export const useExtensionSettings = (autoFetch = true) => {
  const { organizationId, projectId, scanId } = useUrlParams();
  const [loading, setLoading] = useState(autoFetch);
  const [response, setResponse] = useState<ExtensionSettings>();

  const manageFetch = useManageApiResponse(setLoading);
  const manageUpdate = useManageApiResponse();

  const fetch = useCallback(
    async (force = false) => {
      if (loading && !force) return;
      let promise: PromiseType = null;
      if (organizationId && projectId && scanId) {
        promise = API.scanSettings().getScanExtensionSettings(
          organizationId,
          projectId,
          scanId
        );
      } else if (organizationId && projectId) {
        promise = API.scanSettings().getProjectExtensionSettings(
          organizationId,
          projectId
        );
      } else {
        return; // invalid arguments
      }
      const { data } = await manageFetch({
        promise,
        errorMessage: "get-extension-settings",
      });
      setResponse(data?.extensionSettings);
    },
    [loading, manageFetch, organizationId, projectId, scanId]
  );

  const update = useCallback(
    async (requestBody: UserUpdateExtensionSettingsRequest) => {
      if (!organizationId || !projectId) return false;

      if (!isValidExtensionSettings(requestBody.extensionSettings)) {
        return false;
      }

      const { status } = await manageUpdate({
        promise: API.scanSettings().updateExtensionSettings(
          organizationId,
          projectId,
          requestBody
        ),
        errorMessage: "put-extension-settings",
      });
      return isStatusCodeSuccess(status);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, organizationId, projectId]
  );

  useEffect(() => {
    if (autoFetch) {
      fetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFetch]);

  return {
    response,
    loading,
    fetch,
    update,
  };
};
