import { Tabs } from "@Components";
import { memo, useEffect, useMemo } from "react";
import isEqual from "react-fast-compare";
import { AdvancedSettings } from "./AdvancedSettings";
import { Authentication } from "./Authentication";
import { Dictionary } from "./Dictionary";
import { ExtensionSettings } from "./ExtensionSettings";
import { ProjectGeneral } from "./General";
import { useExtensionSettings, useIssueExclusion, useTabs } from "./Hooks";
import { InjectableParameters } from "./InjectableParameters";
import { IssueExclusion } from "./IssueExclusion";
import { MTLSCertificates } from "./MTLSCertificates";
import { Page } from "./pagination";
import { UserParameterLinks } from "./ParameterLinks";
import { PathExclusion } from "./PathExclusion";
import { useProjectSettingsStore } from "./ProjectSettings.store";
import "./ProjectSettings.style.scss";

export const ProjectSettings = memo(() => {
  const { tabs, activeTab, setActiveTab } = useTabs();
  const { refresh: refreshIssueExlusions } = useIssueExclusion();
  const { reset: resetProjectSettingsStore } = useProjectSettingsStore();
  useExtensionSettings();

  useEffect(() => resetProjectSettingsStore, [resetProjectSettingsStore]);

  const activeTabContent = useMemo(() => {
    switch (activeTab) {
      case Page.General:
        return <ProjectGeneral />;
      case Page.Authentication:
        return <Authentication />;
      case Page.Dictionary:
        return <Dictionary />;
      case Page.PathExclusion:
        return <PathExclusion />;
      case Page.AdvancedSettings:
        return <AdvancedSettings />;
      case Page.InjectableParameters:
        return <InjectableParameters />;
      case Page.MTLSCertificate:
        return <MTLSCertificates />;
      case Page.IssueExclusion:
        return <IssueExclusion refresh={refreshIssueExlusions} />;
      case Page.ParameterLinks:
        return <UserParameterLinks />;
      case Page.SecurityChecks:
        return <ExtensionSettings />;
    }
  }, [activeTab, refreshIssueExlusions]);

  return (
    <div id="project-settings" className="card">
      <div className="content">{activeTabContent}</div>
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        updateUrl
        vertical
      />
    </div>
  );
}, isEqual);
