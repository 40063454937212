import type { MTLSCertificate } from "@Services";
import { t } from "i18next";
import { useMemo } from "react";

type ErrorType = {
  message: string;
  severity: "danger" | "warning";
};

const detect = (i: MTLSCertificate, j: MTLSCertificate) => {
  return i.name === j.name;
};

export const useMTLSCertificateDuplicationDetector = (
  entry: MTLSCertificate,
  localCertificates?: MTLSCertificate[],
  globalCertificates?: MTLSCertificate[]
) => {
  const error = useMemo<ErrorType | null>(() => {
    if (localCertificates?.find(i => detect(i, entry))) {
      return {
        message: t("validation.x-already-in-use", { x: t("common.name") }),
        severity: "danger",
      };
    }

    if (globalCertificates?.find(i => detect(i, entry))) {
      return {
        message: t("common.override-information-mtls-certificate"),
        severity: "warning",
      };
    }

    return null;
  }, [entry, globalCertificates, localCertificates]);

  return error;
};
