import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getRole } from "src/Mock";

export const getUserRoles = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, userId } = req.params as {
    [key: string]: string;
  };
  const role = getRole({ organizationId, userId });
  return res(ctx.delay(delay), ctx.status(role ? 200 : 404), ctx.json(role));
};
