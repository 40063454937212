// --------------------------
// - USER SETTINGS

import type { OrganizationId, OrganizationSettings } from "@Interfaces";

// --------------------------
export const usersSetting = [
  {
    id: "1",
    userID: "11", // Response backend user_id
    timeZone: "Europe/Rome", // Response backend time_zone
    notification: true,
  },
  {
    id: "2",
    userID: "12",
    timeZone: "Europe/Rome",
    notification: true,
  },
  {
    id: "3",
    userID: "13",
    timeZone: "Europe/Rome",
    notification: true,
  },
  {
    id: "4",
    userID: "14",
    timeZone: "Europe/Rome",
    notification: true,
  },
  {
    id: "5",
    userID: "17",
    timeZone: "Europe/Rome",
    notification: true,
  },
  {
    id: "6",
    userID: "18",
    timeZone: "Europe/Rome",
    notification: true,
  },
];

// --------------------------
// - ORGANIZATION SETTINGS
// --------------------------

export const organizationSetting = new Map<
  OrganizationId,
  OrganizationSettings
>();
