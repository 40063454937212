import { GridItem } from "../../GridContainer";

type DashboardCardProps = {
  children: JSX.Element | JSX.Element[] | null;
  className?: string;
};

export const DashboardCard = ({
  children,
  className = "",
}: DashboardCardProps) => {
  return (
    <GridItem className={`${className} dashboard-card`.trimStart()}>
      {children}
    </GridItem>
  );
};
