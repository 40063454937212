import type { UserDataProps } from "./UserData.i";
import { dateTimeFormat, getFilterAllowedOptions } from "@Services";
import { useMemo } from "react";

export const useUserData = ({
  name,
  surname,
  role,
  email,
  expiresAt,
}: UserDataProps["user"]) => {
  const roleValue = useMemo(() => {
    const result = getFilterAllowedOptions("user_role").find(
      r => r.value === role
    );
    return result?.label || "-";
  }, [role]);

  const expiresAtValue = useMemo(() => {
    const result = dateTimeFormat(expiresAt, {
      checkMaxEndDate: true,
      trimTime: true,
    });
    return result === "-" ? "" : result;
  }, [expiresAt]);

  return {
    name,
    surname,
    email,
    role: roleValue,
    expiresAt: expiresAtValue,
  };
};
