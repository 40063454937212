import { useProject } from "@Hooks";
import {
  type MTLSCertificate,
  projectDataToUpdateProjectData,
} from "@Services";
import { type Dispatch, useCallback } from "react";
import { GlobalMTLSCertificateForm } from "@Components";

export const AddMTLSCertificate = (props: {
  canvasOpen: boolean;
  setCanvasOpen: Dispatch<boolean>;
}) => {
  const { project, update } = useProject();

  const handleOnSubmit = useCallback(
    async (newCertificate: MTLSCertificate) => {
      if (!project) return false;
      const requestBody = projectDataToUpdateProjectData(project);
      const mTLSCertificates = structuredClone(project.mTLSCertificates ?? []);
      mTLSCertificates.push(newCertificate);
      requestBody.mTLSCertificates = mTLSCertificates;
      return update(requestBody);
    },
    [project, update]
  );

  const setCanvasOpen = props.setCanvasOpen;
  const closeCanvas = useCallback(() => {
    setCanvasOpen(false);
  }, [setCanvasOpen]);

  return (
    <GlobalMTLSCertificateForm
      isOpen={props.canvasOpen}
      saveEntry={handleOnSubmit}
      close={closeCanvas}
    />
  );
};
