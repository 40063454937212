import { memo, useMemo } from "react";
import { useUIStore } from "@Stores";
import { type MenuHorizontalElementProps } from "../../MenuHorizontal.i";
import { MenuHorizontalContainer } from "../MenuHorizontalContainer";
import { MenuHorizontalElement } from "../MenuHorizontalElement/MenuHorizontalElement";

const renderButtonsByMatches = (buttons: Array<MenuHorizontalElementProps>) => {
  if (!buttons.length) {
    return (
      <MenuHorizontalElement
        key={"0"}
        name="placeholder"
        type={"button"}
        path="/"
        className="invisible pe-none"
      />
    );
  }

  return buttons
    .filter(b => !b.hidden)
    .map(button => (
      <MenuHorizontalElement
        {...button}
        path={button.pathWithSearch}
        key={button.name}
        type={"button"}
      />
    ));
};

export const ButtonsMenu = memo(() => {
  const { buttons } = useUIStore();

  const buttonsTemplate = useMemo(
    () => renderButtonsByMatches(buttons),
    [buttons]
  );

  return (
    <MenuHorizontalContainer
      flexGrow={1}
      justifyContent={"end"}
      className="menu-horizontal-buttons"
    >
      {buttonsTemplate}
    </MenuHorizontalContainer>
  );
});
