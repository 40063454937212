import { Breadcrumb } from "@Components";
import {
  ORGANIZATION_PATH_SPACE_LINKS,
  organizationLastVisitedPageRegexp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type OrganizationApiKeysBreadcrumb = {
  disabled?: boolean;
};

export const OrganizationSpaceLinkBreadcrumb = ({
  disabled = false,
}: OrganizationApiKeysBreadcrumb) => {
  const { organizationId } = useUrlParams();
  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(
        organizationLastVisitedPageRegexp("spacelinks")
      ) ?? generateUrl(ORGANIZATION_PATH_SPACE_LINKS, [organizationId ?? ""]);
  return (
    <Breadcrumb disabled={disabled} name="organization-space-links" to={to}>
      {t("common.space-links")}
    </Breadcrumb>
  );
};
