import { GridContainer, Section } from "@Components";
import { t } from "i18next";
import { ReinviteButton } from "./ReinviteButton";
import { memo, type FC } from "react";
import { equals } from "@Services";

export const ReinviteBox: FC<{ hasLastLogin?: boolean }> = memo(
  ({ hasLastLogin }) => {
    if (hasLastLogin) return null;
    return (
      <Section title="organization.re-invite">
        <GridContainer>
          <span className="white-space-pre-line">
            {t("organization.re-invite-user-info")}
          </span>
          <ReinviteButton />
        </GridContainer>
      </Section>
    );
  },
  equals
);
