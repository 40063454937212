import type { Attempt, Nullable } from "@Interfaces";
import { useUrlParams } from "@Hooks";
import { API } from "@Services";
import { createCache } from "@Utils";
import { useEffect } from "react";
import { useHistoryStore } from "../Store/HistoryStore";

const attemptCache = createCache<Attempt>();

const getAttemptAPI = async (
  organizationId: string | undefined,
  projectId: string | undefined,
  scanId: string | undefined,
  attemptId: string | undefined,
  onSuccess: (attemptDetails: Nullable<Attempt>) => unknown
) => {
  if (!organizationId || !projectId || !scanId || !attemptId) {
    onSuccess(null);
    return;
  }

  const cachedData = attemptCache.getData(attemptId);
  if (cachedData) {
    onSuccess(cachedData);
    return;
  }

  const result = await API.attempts().attemptGet(
    organizationId,
    projectId,
    scanId,
    attemptId
  );

  if (!result || !result.data) {
    onSuccess(null);
    return;
  }

  const { data } = result;

  attemptCache.push(attemptId, data);
  onSuccess(data as Attempt);
};

export const useGetAttempt = () => {
  const { selectedListedAttempt, attempt, setAttempt } = useHistoryStore();
  const { organizationId, projectId, scanId } = useUrlParams();

  useEffect(() => {
    if (!selectedListedAttempt) {
      return;
    }

    setAttempt(null);
    getAttemptAPI(
      organizationId,
      projectId,
      scanId,
      selectedListedAttempt.id,
      setAttempt
    );
  }, [selectedListedAttempt, organizationId, setAttempt, projectId, scanId]);

  return {
    attempt,
  };
};
