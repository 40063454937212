import type { OrganizationId, ProjectId } from "@Interfaces";
import { ProjectOrganization } from "src/Mock/data";

export const organizationHasProject = (
  organizationId: OrganizationId,
  projectId: ProjectId
) => {
  return !!ProjectOrganization.find(
    x => x.organizationId === organizationId && x.projectId === projectId
  );
};
