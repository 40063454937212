import { MaintenanceModeManager } from "@Managers";
import { MainOutlet } from "../MainOutlet";

export const Root = () => {
  return (
    <>
      <MainOutlet />
      <MaintenanceModeManager />
    </>
  );
};
