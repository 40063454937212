import { GlobalInjectableParameterFormCanvas } from "@Components";
import { useProject } from "@Hooks";
import type { Injectable } from "@Services";
import { projectDataToUpdateProjectData } from "@Services";
import { useCallback, type Dispatch } from "react";

export const AddInjectableParameter = (props: {
  canvasOpen: boolean;
  setCanvasOpen: Dispatch<boolean>;
}) => {
  const { canvasOpen, setCanvasOpen } = props;

  const { project, update } = useProject();

  const close = useCallback(() => {
    setCanvasOpen(false);
  }, [setCanvasOpen]);

  const handleSave = useCallback(
    async (newInjectable: Injectable) => {
      if (!project) return false;
      const requestBody = projectDataToUpdateProjectData(project);
      const injectables = structuredClone(project.injectables ?? []);
      injectables.push(newInjectable);
      requestBody.injectables = injectables;
      return await update(requestBody);
    },
    [project, update]
  );

  return (
    <GlobalInjectableParameterFormCanvas
      close={close}
      isOpen={canvasOpen}
      saveEntry={handleSave}
    />
  );
};
