import { memo } from "react";
import { FormInput, GridContainer, Section } from "@Components";
import { useDetailsSection } from "./useDetailsSection";

export const DetailsSection = memo(() => {
  const { name, description } = useDetailsSection();
  return (
    <Section title="project.details">
      <GridContainer rows={2}>
        <FormInput
          label="common.name"
          type="text"
          id="name"
          data-cy="new-project-input-name"
          bsSize="sm"
          value={name.value}
          invalid={name.invalid}
          onChange={e => name.updateValue(e.target.value)}
          autoComplete="false"
          autoTrim
        />
        <FormInput
          label="common.description"
          type="textarea"
          id="description"
          data-cy="new-project-input-description"
          bsSize="sm"
          rows={4}
          value={description.value}
          invalid={description.invalid}
          onChange={e => description.updateValue(e.target.value)}
          autoTrim
          autoComplete="false"
        />
      </GridContainer>
    </Section>
  );
});
