import { t } from "i18next";
import { OpenCanvasButton } from "./OpenCanvasButton";
import { memo } from "react";
import { pureComponent } from "@Utils";

export const NoSpaceLinks = memo(() => {
  return (
    <div id="no-space-link-container" className="flex-center">
      <h1>{t("organization.space-links")}</h1>
      <p className="text-center">{t("organization.add-space-link-text")}</p>
      <OpenCanvasButton label="organization.add-space-link" />
    </div>
  );
}, pureComponent);
