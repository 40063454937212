import {
  LoadingScreen,
  NoParameterLinks,
  parameterLinksGraphNodeTypes,
  type ParameterLinksGraphEdgeData,
} from "@Components";
import { ThemeContext } from "@Contexts";
import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  ReactFlow,
  SelectionMode,
  type Edge,
} from "@xyflow/react";
import { memo, useContext } from "react";
import { Card } from "reactstrap";
import { useAPIDefinitionGraph } from "../../Hooks/useAPIDefinitionGraph";

export const APIDefinitionGraph = memo(() => {
  const {
    nodes,
    edges,
    loading,
    selectedItemId,
    onNodesChange,
    onEdgesChange,
    handleNodeClick,
    handleOutsideClick,
    handleEdgeClick,
  } = useAPIDefinitionGraph();

  const { theme } = useContext(ThemeContext);

  return (
    <div
      id="api-definition-graph"
      className={selectedItemId ? "with-node-selected" : ""}
    >
      <Card className="p-4 border-tl-0 w-100 h-100">
        {loading ? (
          <LoadingScreen />
        ) : nodes.length && edges.length ? (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={handleNodeClick}
            onPaneClick={handleOutsideClick}
            onEdgeClick={(e, edge) =>
              handleEdgeClick(e, edge as Edge<ParameterLinksGraphEdgeData>)
            }
            selectionMode={SelectionMode.Partial}
            proOptions={{ hideAttribution: true }}
            nodeTypes={parameterLinksGraphNodeTypes}
            colorMode={theme}
            panOnDrag={[0]}
            fitView
            fitViewOptions={{
              maxZoom: 1,
            }}
            snapToGrid
          >
            <MiniMap pannable zoomable zoomStep={1} />
            <Controls orientation="horizontal" />
            <Background variant={BackgroundVariant.Cross} />
          </ReactFlow>
        ) : (
          <NoParameterLinks />
        )}
      </Card>
    </div>
  );
});
