import { t } from "i18next";
import { DashboardCard, NoVulnerableData } from "../Components";
import type { DashboardCardProps } from "./DashboardCard.i";

export const EndpointCountCard = ({
  endpointsCounter,
  showNoData,
}: DashboardCardProps<{
  endpointsCounter?: number;
}>) => {
  return (
    <DashboardCard className="endpoint-count">
      {showNoData ? (
        <NoVulnerableData text="common.no-endpoints" direction="row" />
      ) : (
        <div className="d-inline-block">
          <span className="response-value">{endpointsCounter}</span>
          <span>{t("common.endpoints")}</span>
        </div>
      )}
    </DashboardCard>
  );
};
