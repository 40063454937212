import { memo } from "react";
import { CubesSVG, Template } from "@Components";
import { FullLogoWhite } from "@Assets";
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";
import type { Props } from "./NoAuthPage.i";
import "./style.scss";

export const NoAuthPage = memo(({ children }: Props) => {
  return (
    <Template type="login">
      <div id="no-auth-page">
        <CubesSVG />
        <Container fluid>
          <div className="content">
            <form id="login-box">
              <div className="w-100">
                <div className="text-center mb-5">
                  <Link to="/">
                    <img src={FullLogoWhite} alt="Logo" className="logo" />
                  </Link>
                </div>
                <Card>
                  <CardBody className="py-5 px-3">{children}</CardBody>
                </Card>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </Template>
  );
});
