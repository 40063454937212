import {
  DashboardError,
  NoProjects,
  NoScans,
  GridContainer,
} from "@Components";
import { DashboardLoader } from "../../Components";
import { useDashboardOrganization } from "./useDashboardOrganization";
import { memo, useMemo } from "react";
import {
  EndpointCountCard,
  IssueCountCard,
  MostCommonIssuesCard,
  MostVulnerableProjectCard,
  OpenIssuesCard,
  OwaspTopTenCard,
  RiskLevelCard,
  WeeklyTrendCard,
} from "../../Cards";
import { pureComponent } from "@Utils";

export const DashboardOrganization = memo(() => {
  const { chartData, summary, loading, issueCounter } =
    useDashboardOrganization();
  const content = useMemo(() => {
    switch (true) {
      case loading:
        return <DashboardLoader />;
      case !summary || !chartData:
        return <DashboardError />;
      case !summary?.totalProjectsCount:
        return <NoProjects />;
      case !summary?.summary.totalScansCount:
        return <NoScans />;
      default:
        const hasAnyCompletedScan = !!summary?.summary.completedScansCount;
        return (
          <GridContainer className="dashboard organization">
            <RiskLevelCard
              riskLevel={summary?.summary.riskLevel}
              showNoData={!hasAnyCompletedScan}
            />
            <EndpointCountCard
              endpointsCounter={summary?.summary.endpoints || 0}
              showNoData={false} // alwais display the card content
            />
            <IssueCountCard
              issueCounter={issueCounter}
              showNoData={!hasAnyCompletedScan}
            />
            <OpenIssuesCard
              severityCount={summary?.summary.severityCount}
              showNoData={!hasAnyCompletedScan}
            />
            <WeeklyTrendCard
              vulnerabilities={chartData?.dailySeverityCount}
              showNoData={!hasAnyCompletedScan}
            />
            <MostCommonIssuesCard
              mostCommonIssues={
                summary?.summary.mostFrequentIssuesSeverityCount
              }
              showNoData={!hasAnyCompletedScan}
            />
            <OwaspTopTenCard
              owaspTopTenEntries={summary?.mostFrequentOWASPTopTenCount}
              showNoData={!hasAnyCompletedScan}
            />
            <MostVulnerableProjectCard
              vulnerableProjects={summary?.mostVulnerableProjects}
              showNoData={!hasAnyCompletedScan}
            />
          </GridContainer>
        );
    }
  }, [chartData, issueCounter, loading, summary]);
  return content;
}, pureComponent);
