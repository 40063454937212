import { FormInput } from "../Form";
import { getFilterAllowedOptions } from "@Services";
import { useMemo } from "react";
import type { Props as MainProps } from "./PageFilters.i";
import { RefreshListButton } from "../Buttons";
import { usePageFilters } from "./usePageFilters";
import type { IssueListingType } from "@Interfaces";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

interface Props extends MainProps {
  issueListingType: IssueListingType;
}

export const IssuesFilters = ({
  updateFilters,
  refresh,
  issueListingType,
}: Props) => {
  const { getValueOf } = usePageFilters();
  const [periodOptions, severityOptions] = useMemo(() => {
    return [
      getFilterAllowedOptions("period"),
      getFilterAllowedOptions("severity"),
    ];
  }, []);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-issue-severity"
        className="me-3"
        label="common.severity"
        type="select"
        options={severityOptions}
        value={getValueOf("issue_severity")}
        onChange={e => {
          updateFilters({
            issue_severity: e.target.value,
          });
        }}
      />

      <>
        {issueListingType === "all" && (
          <FormInput
            id="select-issue-period"
            className="me-3"
            label="components.date-filter.period-label"
            type="select"
            options={periodOptions}
            value={getValueOf("period")}
            onChange={e => {
              updateFilters({
                period: e.target.value,
              });
            }}
          />
        )}
      </>
      <div className="d-flex align-items-end me-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
