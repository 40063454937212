import { Button, ConfirmDialog } from "@Components";
import { equals } from "@Services";
import { APP_ZOOM_PERCENTAGE } from "@Stores";
import {
  BaseEdge,
  EdgeLabelRenderer,
  type EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from "@xyflow/react";
import { memo, useCallback, useState } from "react";

export const DeleteUserParameterLinkEdge: React.FC<EdgeProps> = memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  }: EdgeProps) => {
    const { setEdges } = useReactFlow();
    const [edgePath] = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    const handleEdgeClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setShowConfirmDialog(true);
      },
      []
    );

    const handleDeleteEdge = useCallback(() => {
      setShowConfirmDialog(false);
      setEdges(edges => edges.filter(edge => edge.id !== id));
    }, [id, setEdges]);

    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />

        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${targetX - 24 * APP_ZOOM_PERCENTAGE}px,${targetY}px)`,
              zIndex: 20,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <Button
              hiddenForReader
              className="edgebutton"
              onClick={handleEdgeClick}
              color={"danger"}
              iconClass="bi bi-trash3-fill"
              data={{ cy: `button-delete-${id}` }}
              style={{ transform: "scale(0.5)" }}
            />
          </div>
        </EdgeLabelRenderer>

        {showConfirmDialog && (
          <ConfirmDialog
            title="confirmation-dialog.title.delete-parameter-link-node"
            description="confirmation-dialog.description.delete-parameter-link-node"
            cancelLabel="confirmation-dialog.cancel.delete-parameter-link-node"
            confirmLabel="confirmation-dialog.confirm.delete-parameter-link-node"
            onCancel={() => setShowConfirmDialog(false)}
            onConfirm={handleDeleteEdge}
          />
        )}
      </>
    );
  },
  equals
);
