import { t } from "i18next";
import type {
  DataClassification,
  DataClassificationCountEntry,
} from "@Services";
import { DashboardCard, NoVulnerableData } from "../Components";
import type { DashboardCardProps } from "./DashboardCard.i";
import { useMemo } from "react";
import { Table } from "reactstrap";

export const DataClassificationCard = ({
  dataClassification,
  showNoData,
}: DashboardCardProps<{
  dataClassification?: DataClassification;
}>) => {
  const content = useMemo(() => {
    if (showNoData || !dataClassification) {
      return <NoVulnerableData />;
    }

    const { inputsCount, outputsCount } = dataClassification;

    const inputObject = dataClassificationEntryToObject(inputsCount);
    const outputObject = dataClassificationEntryToObject(outputsCount);

    const tableRowsData = Object.keys({ ...inputObject, ...outputObject })
      .map(key => {
        const input = inputObject[key] || 0;
        const output = outputObject[key] || 0;
        const total = input + output;
        return { input, output, total, key };
      })
      .sort((a, b) => b.total - a.total) // descending order
      .splice(0, 7);

    return (
      <Table>
        <thead>
          <tr>
            <th>{t("common.parameter")}</th>
            <th className="text-end">{t("common.requests")}</th>
            <th className="text-end">{t("common.responses")}</th>
          </tr>
        </thead>
        <tbody>
          {tableRowsData.map(({ key, input, output, total }) => (
            <tr key={`${key}-${total}`}>
              <td>{t(`compliance.${key}`)}</td>
              <td className="text-end">{input}</td>
              <td className="text-end">{output}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }, [dataClassification, showNoData]);

  return (
    <DashboardCard className="titled data-classification">
      <span className="title">{t("common.data-classification")}</span>
      {content}
    </DashboardCard>
  );
};

function dataClassificationEntryToObject(
  entries: DataClassificationCountEntry[]
) {
  const result: Record<string, number> = {};
  return entries.reduce(
    (res, data) => ({ ...res, [data.name]: data.count }),
    result
  );
}
