import { SkeletonBox } from "@Components";

export const SkeletonPage = () => (
  <SkeletonBox width="100%" height="650">
    <rect x="0" y="30" rx="3" ry="3" width="66%" height="450" />
    <rect x="68%" y="30" rx="3" ry="3" width="32%" height="450" />
    <rect x="0" y="500" rx="3" ry="3" width="32%" height="250" />
    <rect x="34%" y="500" rx="3" ry="3" width="32%" height="250" />
    <rect x="68%" y="500" rx="3" ry="3" width="32%" height="250" />
  </SkeletonBox>
);
