import type { Dispatch, SetStateAction } from "react";
import { useCallback } from "react";
import type { InviteOrEditUserModel } from "@Interfaces";
import type { SimpleChangeEvent } from "../UserForm.i";

export const useUserForm = (
  user: InviteOrEditUserModel,
  setUser: Dispatch<SetStateAction<InviteOrEditUserModel>>
) => {
  const updateUser = useCallback(
    ({ target: { name, value, checked } }: SimpleChangeEvent) => {
      switch (name as keyof InviteOrEditUserModel) {
        case "name":
        case "surname":
        case "email":
        case "expiresAt":
        case "role":
          setUser(current => ({ ...current, [name]: value }));
          break;
        case "active":
          setUser(current => ({ ...current, [name]: checked }));
          break;
      }
    },
    [setUser]
  );

  return { user, setUser: updateUser };
};
