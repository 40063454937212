import type { OrganizationId } from "@Interfaces";
import { API } from "@Services";
import { DASHBOARD_ISSUE_PERIOD } from "../../constants";

export const fetchSummaryAndChart = (organizationId: OrganizationId) => {
  return Promise.all([
    API.dashboard().dashboardGetSummaryOrganization(organizationId),
    API.dashboard().dashboardGetChartOrganization(
      organizationId,
      ["period"],
      ["EQ"],
      [DASHBOARD_ISSUE_PERIOD]
    ),
  ]);
};
