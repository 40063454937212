import { Section, withMouseCursorTooltip } from "@Components";
import { type Parameter } from "@Services";
import { BEM, limitString } from "@Utils";
import { useMemo } from "react";
import { Button } from "reactstrap";
import { Parameter as ParameterDetails } from "../../../Parameter";
import { useOffCanvasAddParameterLinkStore } from "../OffCanvasAddParameterLinkStore";

type AdditionalDetailsProps = {
  parameter: Parameter;
};

const AdditionalDetails = ({
  parameter,
  ...otherProps
}: AdditionalDetailsProps) => {
  return (
    <div
      className={BEM("parameter-path-section", "additional-details")}
      {...otherProps}
    >
      <div className={BEM("parameter-path-section", "title")}>
        <span>{parameter.name}</span>
      </div>

      <ParameterDetails parameter={parameter} />
    </div>
  );
};

const ProducingParameterButton = ({
  parameter,
  producingParameterPath,
  onClick,
}: {
  parameter: Parameter;
  producingParameterPath: string;
  onClick: (parameter: Parameter) => unknown;
}) => (
  <Button
    data-cy={`producing-parameter-path`}
    className={"no-text-selection w-100"}
    onClick={() => onClick(parameter)}
    color={producingParameterPath === parameter.pathName ? "pink" : "primary"}
  >
    <span>{limitString(parameter.name, 20)}</span>
  </Button>
);

export const ProducingParameterPathSection = () => {
  const {
    producingParameters,
    activeStep,
    producingParameterPath,
    setProducingParameterPath,
  } = useOffCanvasAddParameterLinkStore();

  const parameterButtons = useMemo(() => {
    return producingParameters.map(parameter => {
      const ParameterButton = withMouseCursorTooltip(
        ProducingParameterButton,
        AdditionalDetails,
        { parameter }
      );

      return (
        <ParameterButton
          key={parameter.name}
          parameter={parameter}
          producingParameterPath={producingParameterPath ?? ""}
          onClick={setProducingParameterPath}
        />
      );
    });
  }, [producingParameters, producingParameterPath, setProducingParameterPath]);

  if (activeStep < 1) {
    return null;
  }

  return (
    <Section
      title="inventory.create-new-parameter-link-step-2"
      data-cy="producer-path-section"
      className="mt-4"
    >
      <div className="producer-path p-4">{parameterButtons}</div>
    </Section>
  );
};
