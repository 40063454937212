import type { LicenseName, RBACCondition, RoleName } from "@Interfaces";

// Pre-compile regular expressions
const licenseRegex = /licen[cs]e is (\w+)/i;
const rolesRegex = /role[s]? in \[(.+?)\]/i;
const orRegex = /or\s*\((.+)\)/i;
const notRegex = /not\s*\((.+)\)/i;
const andRegex = /and\s*\((.+)\)/i;

export const parseCondition = (conditionStr: string): RBACCondition => {
  const condition: RBACCondition = {};

  // Match and process the or condition first
  let orMatch = orRegex.exec(conditionStr);
  while (orMatch) {
    condition.or = orMatch[1]
      .split(/ or /)
      .map(cond => parseCondition(cond.trim()));
    conditionStr = conditionStr.replace(orMatch[0], "").trim();
    orMatch = orRegex.exec(conditionStr);
  }

  // Match and process the not condition
  let notMatch = notRegex.exec(conditionStr);
  while (notMatch) {
    condition.not = parseCondition(notMatch[1].trim());
    conditionStr = conditionStr.replace(notMatch[0], "").trim();
    notMatch = notRegex.exec(conditionStr);
  }

  // Match and process the and condition
  let andMatch = andRegex.exec(conditionStr);
  while (andMatch) {
    condition.and = andMatch[1]
      .split(/ and /)
      .map(cond => parseCondition(cond.trim()));
    conditionStr = conditionStr.replace(andMatch[0], "").trim();
    andMatch = andRegex.exec(conditionStr);
  }

  // Match and process the license condition
  const licenseMatch = licenseRegex.exec(conditionStr);
  if (licenseMatch) {
    condition.licenseType = licenseMatch[1] as LicenseName;
    conditionStr = conditionStr.replace(licenseMatch[0], "").trim();
  }

  // Match and process the role condition
  const rolesMatch = rolesRegex.exec(conditionStr);
  if (rolesMatch) {
    condition.roles = rolesMatch[1]
      .split(",")
      .map(role => role.trim().replace(/['"]+/g, "")) as RoleName[];
  }

  return condition;
};

export const checkRBACCondition = (
  condition: RBACCondition,
  roles: string[],
  licenseName?: LicenseName
): boolean => {
  // Check license type
  if (condition.licenseType && condition.licenseType !== licenseName) {
    return false;
  }

  // Check roles
  if (condition.roles && !condition.roles.some(role => roles.includes(role))) {
    return false;
  }

  // Check 'not' condition
  if (condition.not && checkRBACCondition(condition.not, roles, licenseName)) {
    return false;
  }

  // Check 'or' condition
  if (
    condition.or &&
    !condition.or.some(cond => checkRBACCondition(cond, roles, licenseName))
  ) {
    return false;
  }

  // Check 'and' condition
  if (
    condition.and &&
    !condition.and.every(cond => checkRBACCondition(cond, roles, licenseName))
  ) {
    return false;
  }

  return true;
};
