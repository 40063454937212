import { NewProjectContext } from "@Contexts";
import { useGlobalInjectables } from "@Hooks";
import { type Injectable, equals } from "@Services";
import { useCallback, useContext, useState } from "react";

export const useInjectableParameters = () => {
  const { setProject } = useContext(NewProjectContext);

  const [isOpen, setIsOpen] = useState(false);

  const addEntry = useCallback(
    async (newEntry: Injectable) => {
      setProject(prev => {
        const injectables = structuredClone(prev.injectables ?? []);
        injectables.push(newEntry);
        return { ...prev, injectables };
      });
      return true;
    },
    [setProject]
  );

  const removeEntry = useCallback(
    async (injectable: Injectable) => {
      setProject(prev => {
        const index = prev.injectables?.findIndex(i => equals(i, injectable));
        if (index === undefined || index < 0) return prev; // no update
        const injectables = structuredClone(prev.injectables ?? []);
        injectables.splice(index, 1);
        return { ...prev, injectables };
      });
    },
    [setProject]
  );

  const openCanvas = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeCanvas = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    injectableEntries: useGlobalInjectables(),
    actions: {
      addEntry,
      removeEntry,
    },
    canvas: {
      isOpen,
      open: openCanvas,
      close: closeCanvas,
    },
  };
};
