import { getFilterAllowedOptions } from "@Services";
import { useMemo } from "react";
import { RefreshListButton } from "../Buttons";
import { FormInput } from "../Form";
import type { Props } from "./PageFilters.i";
import { PageFiltersWrapper } from "./PageFiltersWrapper";
import { usePageFilters } from "./usePageFilters";

export const ProfileRolesFilters = ({ updateFilters, refresh }: Props) => {
  const { getValueOf } = usePageFilters();

  const [rolesOptions] = useMemo(
    () => [getFilterAllowedOptions("user_role")],
    []
  );

  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-profile-role-value"
        label="common.role"
        type="select"
        options={rolesOptions}
        value={getValueOf("role_value")}
        onChange={e => {
          updateFilters({
            role_value: e.target.value,
          });
        }}
      />
      <div className="d-flex align-items-end ms-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
