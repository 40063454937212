import { InfoBadge, MethodBadge } from "@Components";
import type { Method as MethodI } from "@Interfaces";
import { dateTimeFormat, type ListedAttempt } from "@Services";
import { t } from "i18next";
import { type TableProps } from "reactstrap";
import { StatusCodeBadge } from "src/Components/Badge/StatusCodeBadge";
import { defaultFormatWithSeconds } from "src/Services/date/dateTimeFormat";
import { limitString, prettyFormatDiff } from "src/Utils";

const calculateDuration = (attempt: ListedAttempt) => {
  const { startedAt, endedAt } = attempt;
  return prettyFormatDiff(startedAt, endedAt);
};

export const getHeaders = (
  selectedAttemptIndex: number,
  visibleFilters: Record<"caller", boolean>
): TableProps["headers"] => {
  let headers: TableProps["headers"] = [
    {
      text: t(`scans.history.table.headers.index`),
      sortByKey: "attempt_index",
    },
    {
      text: t(`scans.history.table.headers.path`),
    },
  ];

  if (selectedAttemptIndex < 0) {
    headers = [
      ...headers,
      {
        text: t(`common.status-code`),
        sortByKey: "attempt_status_code",
      },
      {
        text: t(`scans.history.table.headers.response-resolved`),
      },
      {
        text: t(`common.started-at`),
        sortByKey: "attempt_started",
      },
      {
        text: t(`common.duration`),
      },
      {
        text: t(`common.auth-profile`),
      },
      {
        text: t(`common.scope`),
      },
    ];
    if (visibleFilters.caller) {
      headers = [
        ...headers,
        {
          text: t(`common.caller`),
        },
      ];
    }
  }

  return headers;
};

export const getConfig = (
  attempts: ListedAttempt[] | undefined,
  selectedAttemptIndex: number,
  scanBaseURL: string,
  visibleFilters: Record<"caller", boolean>,
  onClick: (attempt: ListedAttempt) => void
) => {
  if (!attempts) {
    return [];
  }

  const attemptSelected = selectedAttemptIndex > -1;

  return attempts.map((attempt, index) => {
    let items = [
      {
        jsx: <>{attempt.index}</>,
        fixedWidth: "2rem",
      },
      {
        jsx: (
          <div className="path">
            <MethodBadge type={attempt.method as MethodI} className="me-3" />
            <span
              className={"text-truncate"}
              title={`${scanBaseURL}${attempt.path}`}
            >
              {limitString(attempt.path, attemptSelected ? 30 : 80)}
            </span>
          </div>
        ),
      },
    ];

    if (!attemptSelected) {
      items = [
        ...items,
        {
          jsx: <StatusCodeBadge code={attempt.statusCode} />,
        },
        {
          jsx: attempt.responseResolved ? (
            <i className="bi bi-check-circle-fill color-success"></i>
          ) : (
            <i className="bi bi-x-circle-fill color-failure"></i>
          ),
        },
        {
          jsx: (
            <>
              {dateTimeFormat(
                attempt.startedAt,
                undefined,
                defaultFormatWithSeconds
              )}
            </>
          ),
        },
        {
          jsx: <div>{`${calculateDuration(attempt)}`}</div>,
        },
        {
          jsx: <InfoBadge>{attempt.authProfileName}</InfoBadge>,
        },
        {
          jsx: <div>{attempt.scope}</div>,
        },
      ];
      if (visibleFilters.caller) {
        items = [
          ...items,
          {
            jsx: <div>{attempt.caller}</div>,
          },
        ];
      }
    }

    return {
      props: {
        className: `history-attempts-table-row${selectedAttemptIndex === index ? " active" : ""}`,
        onMouseDown: () => {
          onClick(attempt);
        },
      },
      items,
    };
  });
};
