import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

type ListUserRolesParameters = [
  number,
  number,
  "role_value" | "role_active" | "role_expires_at" | undefined,
  "asc" | "desc" | undefined,
  Array<"role_value"> | undefined,
  Array<"EQ"> | undefined,
  Array<string>,
];

export const useProfileRoles = () => {
  const defaultFilters = useMemo(() => getDefaultFilters("profile-roles"), []);
  const { search } = useLocation();
  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      const apiParams = [
        args.page,
        args.pageSize,
        args.sortBy,
        args.sortMode,
        args.criteria,
        args.operators,
        args.values,
      ];

      return API.role().userListRoles(
        ...(apiParams as ListUserRolesParameters)
      );
    }, []),
  });

  const setFilters = useUpdateCacheFilters("profile-roles");

  return {
    defaultFilters,
    response,
    fetch,
    loading,
    setFilters,
    search,
  };
};
