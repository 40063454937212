import { memo, useEffect } from "react";
import hljs from "highlight.js/lib/core";
import httpFn from "highlight.js/lib/languages/http";
import jsonFn from "highlight.js/lib/languages/json";
import type { LanguageFn } from "highlight.js";

const AVAILABLE_LANGUAGES_MAPPING: { [key: string]: LanguageFn } = {
  http: httpFn,
  json: jsonFn,
};

const AVAILABLE_LANGUAGES_KEYS = Object.keys(AVAILABLE_LANGUAGES_MAPPING);

/**
 * @description this component is used to centralize highlight.js languages
 */
export const Highlight = memo(() => {
  useEffect(() => {
    AVAILABLE_LANGUAGES_KEYS.map(languageKey =>
      hljs.registerLanguage(
        languageKey,
        AVAILABLE_LANGUAGES_MAPPING[languageKey]
      )
    );

    return () => {
      AVAILABLE_LANGUAGES_KEYS.map(languageKey =>
        hljs.unregisterLanguage(languageKey)
      );
    };
  }, []);
  return null;
});

export default Highlight;
