import { useEffect, useMemo, useState } from "react";
import type { Props } from "./EditUser.i";
import { API, equals, isFilledString, isValid } from "@Services";
import type { ButtonProps } from "@Components";
import { useManageApiResponse } from "@Hooks";
import { defaultData, defaultValidities } from "./defaults";

const useEditUser = ({ isOpen, close, user, updateUser }: Props) => {
  const [fetching, setFetching] = useState(false);
  const manageResponse = useManageApiResponse(setFetching);
  const [formData, setFormData] = useState(defaultData);
  const [validities, setValidities] = useState(defaultValidities);

  useEffect(() => {
    setFormData({
      name: user.name,
      surname: user.surname,
    });
  }, [user, isOpen]);

  const { formDirty, formValid } = useMemo(() => {
    const { name, surname } = formData;

    const trimmedFormData: typeof formData = {
      name: name.trim(),
      surname: surname.trim(),
    };

    const comparator: typeof formData = {
      name: user.name,
      surname: user.surname,
    };

    const validities = {
      name: isFilledString(name) && isValid("name", name.trim()),
      surname: isFilledString(surname) && isValid("name", surname.trim()),
    };

    setValidities(validities);

    return {
      formDirty: !equals(trimmedFormData, comparator),
      formValid: Object.values(validities).every(Boolean),
    };
  }, [formData, user]);

  const submitButton: ButtonProps = useMemo(
    () => ({
      children: "common.save",
      disabled: !formValid || !formDirty,
      loading: fetching,
      color: "primary",
      iconClass: "bi bi-floppy",
      onClick: async () => {
        const { status } = await manageResponse({
          errorMessage: "put-user",
          promise: API.user().userUpdate({
            name: formData.name.trim(),
            surname: formData.surname.trim(),
          }),
        });
        const updated = status === 200;
        if (updated) {
          close();
          updateUser({ ...user, ...formData });
        }
      },
      data: {
        cy: "edit-user-btn",
      },
    }),
    [
      formData,
      formValid,
      formDirty,
      fetching,
      close,
      manageResponse,
      user,
      updateUser,
    ]
  );

  return {
    isOpen,
    close,
    submitButton,
    form: {
      data: formData,
      validities,
      update: (key: keyof typeof formData, value: string) => {
        setFormData({ ...formData, [key]: value });
      },
      disabled: fetching,
    },
  };
};

export default useEditUser;
