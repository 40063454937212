import {
  useGlobalInjectables,
  useGlobalMTLSCertificates,
  useOrganizationContext,
  useProject,
} from "@Hooks";
import { getProjectStatus } from "@Utils";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProjectSettingsStore } from "../ProjectSettings.store";

export const useTabs = () => {
  const { project } = useProject();
  const { advancedSettings } = useOrganizationContext();
  const injectables = useGlobalInjectables();
  const mTLSCertificates = useGlobalMTLSCertificates();
  const [activeTab, setActiveTab] = useState(0);
  const { issueExclusions, extensionSettings, loading } =
    useProjectSettingsStore();

  const tabs = useMemo(() => {
    const tabs = [
      {
        label: "common.general",
      },
      {
        label: "common.authentication",
        counter: project?.authenticationSettings.length || 0,
      },
      {
        label: "common.dictionary",
        counter: project?.dictionary.length || 0,
      },
      {
        label: "common.path-exclusion",
        counter: project?.pathExclusion.length || 0,
      },
      {
        label: "common.issue-exclusion",
        counter: issueExclusions.length,
        loading,
      },
      {
        label: "common.injectable-parameters",
        counter: injectables.length,
      },
      {
        label: "common.mtls-certificate",
        counter: mTLSCertificates.length || 0,
      },
      {
        label: "common.security-checks",
        counter: extensionSettings.length,
        loading,
      },
      {
        label: "common.user-parameter-links",
        counter: project?.parameterLinks.length,
      },
    ];

    const status = getProjectStatus(project);

    if (status !== "archived" && advancedSettings.enabled) {
      tabs.push({ label: "common.advanced-settings" });
    }
    return tabs;
  }, [
    advancedSettings.enabled,
    extensionSettings.length,
    injectables.length,
    issueExclusions.length,
    loading,
    mTLSCertificates.length,
    project,
  ]);

  const { hash } = useLocation();
  const navigate = useNavigate();

  // at page render, add #general hash if missing
  useEffect(() => {
    if (!hash && activeTab === 0) {
      navigate({ hash: "#general" }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tabs,
    activeTab,
    setActiveTab,
  };
};
