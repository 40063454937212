import { memo } from "react";
import type { Props } from "./Details.i";

import "./style.scss";

export const Details = memo(({ details, className = "" }: Props) => {
  const headers = Object.keys(details);
  const values = Object.values(details);
  return (
    <table className={`table-details ${className}`}>
      <thead>
        <tr>
          {headers.map(h => (
            <th style={{ width: `${100 / headers.length}%` }} key={h}>
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {values.map((v, i) => (
            <td key={headers[i]}>{v}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
});
