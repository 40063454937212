import {
  type NewParameterLinkNodeData,
  type NodeParameterData,
  Section,
  SourceAPIDefinitionNode,
  TargetAPIDefinitionNode,
} from "@Components";
import { ThemeContext } from "@Contexts";
import type { InventoryDetail, InventoryInput, Nullable } from "@Interfaces";
import type { Parameter } from "@Services";
import { useUIStore } from "@Stores";
import {
  Background,
  BackgroundVariant,
  type Edge,
  MarkerType,
  type Node,
  Position,
  ReactFlow,
} from "@xyflow/react";
import { useContext, useEffect, useMemo, useRef } from "react";
import {
  NEW_PARAMETER_LINK_NODE_ITEM_HEIGHT,
  NEW_PARAMETER_LINK_NODE_TITLE_HEIGHT,
  NEW_PARAMETER_LINK_NODE_WIDTH,
} from "@Components";

import {
  type ProducerLocation,
  useOffCanvasAddParameterLinkStore,
} from "../OffCanvasAddParameterLinkStore";

const nodeTypes = {
  sourceAPIDefinition: SourceAPIDefinitionNode,
  targetAPIDefinition: TargetAPIDefinitionNode,
};

const createNode = (
  inventoryItem: InventoryDetail,
  parameter: Parameter,
  appZoomPercentage: number,
  isProducing: boolean
) => ({
  id: inventoryItem.id,
  position: { x: 100 * appZoomPercentage * (isProducing ? -1 : 1), y: 0 },
  targetPosition: Position.Left,
  sourcePosition: Position.Right,
  data: {
    name: inventoryItem.name,
    path: inventoryItem.path,
    verb: inventoryItem.verb,
    parameters: {
      [parameter.in as ProducerLocation]: [
        {
          name: parameter.name,
          type: parameter.type as InventoryInput,
          input: parameter.usage === "input",
          output: parameter.usage === "output",
          data: parameter,
        } as NodeParameterData,
      ],
    },
    isConnected: true,
  } as NewParameterLinkNodeData,
  type: isProducing ? "sourceAPIDefinition" : "targetAPIDefinition",
  width: NEW_PARAMETER_LINK_NODE_WIDTH * appZoomPercentage,
  height:
    (NEW_PARAMETER_LINK_NODE_TITLE_HEIGHT +
      NEW_PARAMETER_LINK_NODE_ITEM_HEIGHT * 2) *
    appZoomPercentage,
  origin: isProducing ? [1.0, 0.0] : [0.0, 0.0],
});

export const SummarySection = () => {
  const {
    activeStep,
    consumingInventory,
    producingInventory,
    producingParameter,
    consumingParameter,
  } = useOffCanvasAddParameterLinkStore();
  const { appZoomPercentage } = useUIStore();
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const { theme } = useContext(ThemeContext);
  const isActive = activeStep >= 5;

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const { current: container } = containerRef;

    if (!container) {
      return;
    }

    container.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [isActive]);

  const nodes = useMemo(() => {
    if (!isActive) return;
    if (!producingInventory || !consumingInventory) return [];
    if (!producingParameter || !consumingParameter) return [];

    return [
      createNode(
        producingInventory,
        producingParameter,
        appZoomPercentage,
        true
      ),
      createNode(
        consumingInventory,
        consumingParameter,
        appZoomPercentage,
        false
      ),
    ] as Array<Node<NewParameterLinkNodeData>>;
  }, [
    isActive,
    producingInventory,
    consumingInventory,
    producingParameter,
    consumingParameter,
    appZoomPercentage,
  ]);

  const edges = useMemo(() => {
    if (!isActive) return;
    if (!producingInventory || !consumingInventory) return [];
    if (!producingParameter || !consumingParameter) return [];

    return [
      {
        id: `${producingInventory.id}_${consumingInventory.id}`,
        source: producingInventory.id,
        sourceHandle: `handle-source-${producingInventory.id}_${producingParameter.name}`,
        target: consumingInventory.id,
        targetHandle: `handle-target-${consumingInventory.id}_${consumingParameter.name}`,
        animated: true,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 10,
          height: 10,
          color: "#d63384",
        },
        style: {
          strokeWidth: 2,
          stroke: "#d63384",
        },
        data: {},
      },
    ] as Array<Edge>;
  }, [
    consumingInventory,
    consumingParameter,
    producingInventory,
    producingParameter,
    isActive,
  ]);

  if (!isActive) {
    return null;
  }

  return (
    <div ref={containerRef}>
      <Section
        title="inventory.create-new-parameter-link-step-6"
        data-cy="summary-section"
        className="mt-4"
      >
        <div className="w-100" style={{ height: "20rem" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            proOptions={{ hideAttribution: true }}
            colorMode={theme}
            panOnDrag={[0]}
            nodeTypes={nodeTypes}
            fitView
            fitViewOptions={{
              maxZoom: 1,
            }}
            snapToGrid
          >
            <Background variant={BackgroundVariant.Cross} />
          </ReactFlow>
        </div>
      </Section>
    </div>
  );
};
