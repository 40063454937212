import { DisplayRowSpaceLink, Section, SpaceLinkEditCanvas } from "@Components";
import { memo } from "react";
import { EMPTY_PROJECT, type ApiDefinitionProps } from "./ApiDefinition.i";
import { BoxFile, FileDetails, Upload } from "./Components";
import { useApiDefinition } from "./Hooks";
import { handleFileSelected } from "./functions";
import { equals } from "@Services";
import "./ApiDefinition.style.scss";

export const ApiDefinition = memo((props: ApiDefinitionProps) => {
  const {
    fileOAS,
    project,
    projectExtras,
    editSpaceLinkButton,
    spaceLinkCanvasOpen,
    setProject,
    setProjectExtras,
    getPathsLimit,
    handleCloseSpaceLinkCanvas,
    handleSetSpaceLink,
  } = useApiDefinition(props);

  const canShowSpaceLink =
    props.newProject &&
    fileOAS &&
    !fileOAS?.error &&
    projectExtras.license?.name === "Enterprise";

  return (
    <div id="api-definition">
      <Section>
        {fileOAS ? (
          <>
            <BoxFile
              fileOAS={fileOAS}
              setProject={setProject}
              setProjectExtras={setProjectExtras}
            />
            <FileDetails
              fileOAS={fileOAS}
              project={project}
              setProject={setProject}
              projextExtras={projectExtras}
            />
            {canShowSpaceLink && (
              <>
                <div className="card p-4 mt-3" data-cy="new-project-space-link">
                  <DisplayRowSpaceLink
                    buttonProps={editSpaceLinkButton}
                    spaceLinkID={project.spaceLinkID ?? ""}
                  />
                </div>
                {/* todo we need to create a fake project in order to accomodate target interface for the modal*/}
                <SpaceLinkEditCanvas
                  isOpen={spaceLinkCanvasOpen}
                  project={{ ...EMPTY_PROJECT, ...project }}
                  setClosed={handleCloseSpaceLinkCanvas}
                  setProject={handleSetSpaceLink}
                />
              </>
            )}
          </>
        ) : (
          <Upload
            handleFileSelected={file =>
              handleFileSelected(
                file,
                getPathsLimit,
                setProject,
                setProjectExtras
              )
            }
          />
        )}
      </Section>
    </div>
  );
}, equals);
