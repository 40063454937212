import type { UserCreateIssueExclusionRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, issueExclusion } from "src/Mock";

export const newIssueExclusion = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const payload = (await req.json()) as UserCreateIssueExclusionRequest;
  const { extensionName, falsePositive, methods, path } = payload;

  const id = String(
    issueExclusion.reduce(function (pre, cur) {
      const val = Number(cur.id);
      return Math.max(pre, val);
    }, 0) + 1
  );

  issueExclusion.push({
    id,
    extensionName,
    falsePositive,
    methods,
    path,
  });

  return res(ctx.delay(delay), ctx.status(200));
};
