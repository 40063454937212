import { ItemNotFound } from "@Components";
import { ORGANIZATION_PATH_SPACE_LINKS } from "@Constants";
import { useUrlParams } from "@Hooks";
import { generateUrl, pureComponent } from "@Utils";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

export const SpaceLinkNotFound = memo(() => {
  const navigate = useNavigate();

  const { organizationId } = useUrlParams();

  const destination = generateUrl(ORGANIZATION_PATH_SPACE_LINKS, [
    organizationId ?? "",
  ]);

  return (
    <ItemNotFound
      message="errors.get-space-link"
      navigateToList={() => navigate(destination)}
    />
  );
}, pureComponent);
