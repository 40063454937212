import { t } from "i18next";

interface Props {
  title: string;
  children: JSX.Element;
}

export const FormSection = ({ title, children }: Props) => {
  return (
    <div className="form-section">
      <p className="title">{t(`project.run-schedule.${title}`)}</p>
      <div className="px-1">{children}</div>
    </div>
  );
};
