import { Breadcrumb, ProjectScanDetailsBreadcrumb } from "@Components";
import { APIDefinitionDetails, ScanDetails } from "@Pages";
import { t } from "i18next";
import { ProjectScans } from "src/Pages/Project/Components";

export const ProjectScanRoutes = [
  {
    path: "", // scans
    element: <ProjectScans />,
  },
  {
    path: ":scanId",
    children: [
      {
        path: "",
        element: <ScanDetails />,
        handle: {
          crumb: <ProjectScanDetailsBreadcrumb disabled />,
        },
      },
      {
        path: "inventory/:inventoryId",
        element: <APIDefinitionDetails />,
        handle: {
          crumb: [
            <ProjectScanDetailsBreadcrumb />,
            <Breadcrumb name="project-scan-inventory-details" disabled>
              {t("common.inventory-detail")}
            </Breadcrumb>,
          ],
        },
      },
    ],
  },
];
