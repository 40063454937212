import type { InventoryItemParameter } from "@Interfaces";

export const parameters: InventoryItemParameter[] = [
  {
    description:
      "User authentication token for secure access. Available authentication system : OAuth2",
    statusCode: "200",
    format: "JWT",
    in: "header",
    name: "Authorization",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/auth",
    required: true,
    schemaComposition: "",
    schemaReference: "Bearer <token>",
    type: "string",
    usage: "This token is required to access protected endpoints.",
    evaluation: "????",
  },
  {
    description: "Filter criteria for custom queries",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "filter",
    not: "Optional",
    oracleFormat: "String",
    oracleType: "Query",
    pathName: "/filter",
    required: false,
    schemaComposition: "FilterSchema",
    schemaReference:
      'e.g., "user-name", "user-surname", "user-email", "user-role", "user-created-at"',
    type: "string",
    usage: "Specify filter criteria for custom queries.",
    evaluation: "????",
  },
  {
    description: "Version of the API to use",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "apiVersion",
    not: "",
    oracleFormat: "String",
    oracleType: "Query",
    pathName: "/version",
    required: false,
    schemaComposition: "VersionSchema",
    schemaReference: 'e.g., "v1"',
    type: "string",
    usage: "Specify the version of the API you want to use.",
    evaluation: "????",
  },
  {
    description: "Page number for result pagination",
    statusCode: "200",
    format: "integer",
    in: "path",
    name: "page",
    not: "",
    oracleFormat: "Integer",
    oracleType: "Query",
    pathName: "/",
    required: false,
    schemaComposition: "",
    schemaReference: "e.g., 1, 2, 3, ...",
    type: "number",
    usage: "Enter the page number for paginating results.",
    evaluation: "????",
  },
  {
    description: "Sorting order for the result set",
    statusCode: "200",
    format: "string",
    in: "cookie",
    name: "sort",
    not: "",
    oracleFormat: "String",
    oracleType: "Query",
    pathName: "/user",
    required: false,
    schemaComposition: "UserDetailsSchema",
    schemaReference: 'e.g., "asc" (ascending) or "desc" (descending)',
    type: "string",
    usage: "Specify the sorting order for the returned results.",
    evaluation: "????",
  },
  {
    description: "Data of the object to create or update",
    statusCode: "200",
    format: "JSON",
    in: "body",
    name: "data",
    not: "",
    oracleFormat: "Object",
    oracleType: "Data",
    pathName: "%3Croot-param%3E",
    required: true,
    schemaComposition: "ObjectSchema",
    schemaReference: 'e.g., { "key": "value" }',
    type: "object",
    usage:
      "Provide the data of the object to create or update in the request body.",
    evaluation: "????",
  },
  {
    description: "Unique identifier for the resource",
    statusCode: "200",
    format: "UUID",
    in: "path",
    name: "id",
    not: "",
    oracleFormat: "String",
    oracleType: "Identifier",
    pathName: "/resource/{id}",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "123e4567-e89b-12d3-a456-426614174000"',
    type: "string",
    usage: "Specify the unique identifier for the resource.",
    evaluation: "????",
  },
  {
    description: "Locale setting for the request",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "locale",
    not: "",
    oracleFormat: "String",
    oracleType: "Setting",
    pathName: "/settings",
    required: false,
    schemaComposition: "LocaleSchema",
    schemaReference: 'e.g., "en-US", "fr-FR"',
    type: "string",
    usage: "Specify the locale setting for the request.",
    evaluation: "????",
  },
  {
    description: "Date and time of the request",
    statusCode: "200",
    format: "ISO 8601",
    in: "query",
    name: "timestamp",
    not: "",
    oracleFormat: "String",
    oracleType: "DateTime",
    pathName: "/time",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "2021-12-31T23:59:59Z"',
    type: "string",
    usage: "Specify the date and time of the request.",
    evaluation: "????",
  },
  {
    description: "Maximum number of results to return",
    statusCode: "200",
    format: "integer",
    in: "query",
    name: "limit",
    not: "",
    oracleFormat: "Integer",
    oracleType: "Query",
    pathName: "/results",
    required: false,
    schemaComposition: "",
    schemaReference: "e.g., 10, 20, 50",
    type: "number",
    usage: "Specify the maximum number of results to return.",
    evaluation: "????",
  },
  {
    description: "Authorization token for third-party API",
    statusCode: "200",
    format: "Bearer Token",
    in: "header",
    name: "ThirdPartyAuthorization",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/thirdparty/auth",
    required: true,
    schemaComposition: "",
    schemaReference: "Bearer <thirdparty-token>",
    type: "string",
    usage: "This token is required to access third-party API endpoints.",
    evaluation: "????",
  },
  {
    description: "Session ID for user session tracking",
    statusCode: "200",
    format: "UUID",
    in: "cookie",
    name: "sessionId",
    not: "",
    oracleFormat: "String",
    oracleType: "Session",
    pathName: "/session",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "abc123"',
    type: "string",
    usage: "Provide the session ID for user session tracking.",
    evaluation: "????",
  },
  {
    description: "Client IP address",
    statusCode: "200",
    format: "IPv4",
    in: "header",
    name: "Client-IP",
    not: "",
    oracleFormat: "String",
    oracleType: "Network",
    pathName: "/network",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "192.168.1.1"',
    type: "string",
    usage: "Specify the client's IP address.",
    evaluation: "????",
  },
  {
    description: "User-agent string of the client",
    statusCode: "200",
    format: "string",
    in: "header",
    name: "User-Agent",
    not: "",
    oracleFormat: "String",
    oracleType: "ClientInfo",
    pathName: "/client",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "Mozilla/5.0"',
    type: "string",
    usage: "Specify the user-agent string of the client.",
    evaluation: "????",
  },
  {
    description: "E-mail address for notifications",
    statusCode: "200",
    format: "email",
    in: "query",
    name: "email",
    not: "",
    oracleFormat: "String",
    oracleType: "Contact",
    pathName: "/notifications",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "user@example.com"',
    type: "string",
    usage: "Specify the e-mail address for receiving notifications.",
    evaluation: "????",
  },
  {
    description: "Access token for API",
    statusCode: "200",
    format: "Bearer Token",
    in: "header",
    name: "AccessToken",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/api/access",
    required: true,
    schemaComposition: "",
    schemaReference: "Bearer <access-token>",
    type: "string",
    usage: "This token is required for accessing API endpoints.",
    evaluation: "????",
  },
  {
    description: "OAuth 2.0 Authorization code",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "code",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/oauth2/callback",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "abc123"',
    type: "string",
    usage: "Provide the OAuth 2.0 authorization code.",
    evaluation: "????",
  },
  {
    description: "Timestamp for the event",
    statusCode: "200",
    format: "ISO 8601",
    in: "query",
    name: "eventTime",
    not: "",
    oracleFormat: "String",
    oracleType: "DateTime",
    pathName: "/event",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "2021-12-31T23:59:59Z"',
    type: "string",
    usage: "Specify the timestamp for the event.",
    evaluation: "????",
  },
  {
    description: "List of IDs for bulk operations",
    statusCode: "200",
    format: "Array of UUIDs",
    in: "body",
    name: "ids",
    not: "",
    oracleFormat: "Array",
    oracleType: "BulkOperation",
    pathName: "/bulk",
    required: true,
    schemaComposition: "ArraySchema",
    schemaReference: 'e.g., ["123e4567-e89b-12d3-a456-426614174000"]',
    type: "array",
    usage: "Provide a list of IDs for bulk operations.",
    evaluation: "????",
  },
  {
    description: "Callback URL for asynchronous operations",
    statusCode: "200",
    format: "URL",
    in: "query",
    name: "callbackUrl",
    not: "",
    oracleFormat: "String",
    oracleType: "URL",
    pathName: "/callback",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "https://example.com/callback"',
    type: "string",
    usage: "Specify the callback URL for asynchronous operations.",
    evaluation: "????",
  },
  {
    description: "Access control level for the resource",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "acl",
    not: "",
    oracleFormat: "String",
    oracleType: "Control",
    pathName: "/acl",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "read", "write", "admin"',
    type: "string",
    usage: "Specify the access control level for the resource.",
    evaluation: "????",
  },
  {
    description: "Refresh token for obtaining new access tokens",
    statusCode: "200",
    format: "JWT",
    in: "body",
    name: "refreshToken",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/token/refresh",
    required: true,
    schemaComposition: "",
    schemaReference: "Bearer <refresh-token>",
    type: "string",
    usage: "Provide the refresh token to obtain a new access token.",
    evaluation: "????",
  },
  {
    description: "Flag to indicate a dry run",
    statusCode: "200",
    format: "boolean",
    in: "query",
    name: "dryRun",
    not: "",
    oracleFormat: "Boolean",
    oracleType: "Operation",
    pathName: "/operation",
    required: false,
    schemaComposition: "",
    schemaReference: "e.g., true or false",
    type: "boolean",
    usage: "Specify whether to perform a dry run of the operation.",
    evaluation: "????",
  },
  {
    description: "Currency code for transactions",
    statusCode: "200",
    format: "ISO 4217",
    in: "body",
    name: "currency",
    not: "",
    oracleFormat: "String",
    oracleType: "Finance",
    pathName: "/transactions",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "USD", "EUR"',
    type: "string",
    usage: "Specify the currency code for the transactions.",
    evaluation: "???",
  },
  {
    description: "Time zone for the request",
    statusCode: "200",
    format: "string",
    in: "body",
    name: "timeZone",
    not: "",
    oracleFormat: "String",
    oracleType: "Setting",
    pathName: "/timezone",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "UTC", "America/New_York"',
    type: "string",
    usage: "Specify the time zone for the request.",
    evaluation: "???",
  },
  {
    description: "Debug mode flag",
    statusCode: "200",
    format: "boolean",
    in: "query",
    name: "debug",
    not: "",
    oracleFormat: "Boolean",
    oracleType: "Setting",
    pathName: "/debug",
    required: false,
    schemaComposition: "",
    schemaReference: "e.g., true or false",
    type: "boolean",
    usage: "Specify whether to enable debug mode.",
    evaluation: "???",
  },
  {
    description: "Customer ID for the request",
    statusCode: "200",
    format: "UUID",
    in: "query",
    name: "customerId",
    not: "",
    oracleFormat: "String",
    oracleType: "Identifier",
    pathName: "/customer",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "123e4567-e89b-12d3-a456-426614174000"',
    type: "string",
    usage: "Specify the customer ID for the request.",
    evaluation: "???",
  },
  {
    description: "Signature for request verification",
    statusCode: "200",
    format: "HMAC",
    in: "header",
    name: "Signature",
    not: "",
    oracleFormat: "String",
    oracleType: "Security",
    pathName: "/verify",
    required: true,
    schemaComposition: "",
    schemaReference: "HMAC-SHA256 signature",
    type: "string",
    usage: "Provide the HMAC signature for request verification.",
    evaluation: "???",
  },
  {
    description: "Webhook event type",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "eventType",
    not: "",
    oracleFormat: "String",
    oracleType: "Event",
    pathName: "/webhook",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "order.created", "payment.failed"',
    type: "string",
    usage: "Specify the type of webhook event.",
    evaluation: "???",
  },
  {
    description: "User's language preference",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "language",
    not: "",
    oracleFormat: "String",
    oracleType: "Setting",
    pathName: "/language",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "en", "fr", "es"',
    type: "string",
    usage: "Specify the user's language preference.",
    evaluation: "???",
  },
  {
    description: "Country code for the request",
    statusCode: "200",
    format: "ISO 3166-1 alpha-2",
    in: "query",
    name: "country",
    not: "",
    oracleFormat: "String",
    oracleType: "Location",
    pathName: "/country",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "US", "FR", "ES"',
    type: "string",
    usage: "Specify the country code for the request.",
    evaluation: "???",
  },
  {
    description: "Timezone offset for the request",
    statusCode: "200",
    format: "integer",
    in: "query",
    name: "tzOffset",
    not: "",
    oracleFormat: "Integer",
    oracleType: "Setting",
    pathName: "/timezone",
    required: false,
    schemaComposition: "",
    schemaReference: "e.g., -5, 0, 3",
    type: "number",
    usage: "Specify the timezone offset for the request.",
    evaluation: "???",
  },
  {
    description: "Search query string",
    statusCode: "200",
    format: "string",
    in: "query",
    name: "search",
    not: "",
    oracleFormat: "String",
    oracleType: "Query",
    pathName: "/search",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "keyword1, keyword2"',
    type: "string",
    usage: "Provide the search query string.",
    evaluation: "???",
  },
  {
    description: "Content type of the request",
    statusCode: "200",
    format: "string",
    in: "header",
    name: "Content-Type",
    not: "",
    oracleFormat: "String",
    oracleType: "Header",
    pathName: "/content",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "application/json"',
    type: "string",
    usage: "Specify the content type of the request.",
    evaluation: "???",
  },
  {
    description: "Client secret for API authentication",
    statusCode: "200",
    format: "string",
    in: "header",
    name: "Client-Secret",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/auth",
    required: true,
    schemaComposition: "",
    schemaReference: "Client secret string",
    type: "string",
    usage: "Provide the client secret for API authentication.",
    evaluation: "???",
  },
  {
    description: "Customer's date of birth",
    statusCode: "200",
    format: "ISO 8601",
    in: "query",
    name: "dob",
    not: "",
    oracleFormat: "String",
    oracleType: "PersonalInfo",
    pathName: "/customer",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., "1990-01-01"',
    type: "string",
    usage: "Specify the customer's date of birth.",
    evaluation: "???",
  },
  {
    description: "Metadata for the request",
    statusCode: "200",
    format: "JSON",
    in: "body",
    name: "metadata",
    not: "",
    oracleFormat: "Object",
    oracleType: "Metadata",
    pathName: "/metadata",
    required: false,
    schemaComposition: "",
    schemaReference: 'e.g., { "key": "value" }',
    type: "object",
    usage: "Provide additional metadata for the request.",
    evaluation: "???",
  },
  {
    description: "API key for service access",
    statusCode: "200",
    format: "string",
    in: "header",
    name: "API-Key",
    not: "",
    oracleFormat: "String",
    oracleType: "Authentication",
    pathName: "/service",
    required: true,
    schemaComposition: "",
    schemaReference: "API key string",
    type: "string",
    usage: "Provide the API key for accessing the service.",
    evaluation: "???",
  },
  {
    description: "Flag to indicate if the response should be compressed",
    statusCode: "200",
    format: "boolean",
    in: "body",
    name: "compress",
    not: "",
    oracleFormat: "Boolean",
    oracleType: "Setting",
    pathName: "/response",
    required: false,
    schemaComposition: "",
    schemaReference: "e.g., true or false",
    type: "boolean",
    usage: "Specify whether the response should be compressed.",
    evaluation: "???",
  },
  {
    description: "ID of the item to delete",
    statusCode: "200",
    format: "UUID",
    in: "body",
    name: "itemId",
    not: "",
    oracleFormat: "String",
    oracleType: "Identifier",
    pathName: "/item/{itemId}",
    required: true,
    schemaComposition: "",
    schemaReference: 'e.g., "123e4567-e89b-12d3-a456-426614174000"',
    type: "string",
    usage: "Specify the ID of the item to delete.",
    evaluation: "???",
  },
];
