import type { NewProjectData, Nullable } from "@Interfaces";
import type { Project } from "@Services";

/**
 * This function return a deep-cloned object of {source} removing the authentication-settings
 * (identified by index) and any project entity related to it
 * @param {T extends ProjectData | NewProjectData} source
 * @param {number} index
 * @returns {T}
 */
export const handleAuthenticationRemovedFromProject = <
  T extends Project | NewProjectData,
>(
  source: T,
  index: number
): Nullable<T> => {
  const authenticationSettings = source.authenticationSettings[index];
  if (!authenticationSettings) {
    return null;
  }
  const result = structuredClone(source);
  const [removedAuthSettings] = result.authenticationSettings.splice(index, 1);
  const associatedAuthSetting = removedAuthSettings.name;
  if (result.injectables) {
    result.injectables = result.injectables.filter(
      e => e.associatedAuthSetting !== associatedAuthSetting
    );
    if (!result.injectables.length) {
      result.injectables = undefined;
    }
  }
  if (result.mTLSCertificates) {
    result.mTLSCertificates = result.mTLSCertificates.filter(
      e => e.associatedAuthSetting !== associatedAuthSetting
    );
    if (!result.mTLSCertificates.length) {
      result.mTLSCertificates = undefined;
    }
  }
  return result;
};
