import type { PlainObject } from "@Interfaces";
import { getRandomBetweenMinMax } from "src/Mock/helpers";

export const generateRandomJWT = () => {
  const header = { alg: "none", typ: "JWT" };
  const payload: PlainObject<any> = {};
  const payloadLength = getRandomBetweenMinMax(2, 8);
  for (let i = 0; i < payloadLength; i++) {
    payload[`random_value_${i}`] = getRandomBetweenMinMax(100000, 200000);
  }
  const header64 = btoa(JSON.stringify(header));
  const payload64 = btoa(JSON.stringify(payload));
  const signature = btoa(`${header64}.${payload64}`);

  return [header64, payload64, signature].join(".");
};
