import { pureComponent } from "@Utils";
import { memo, useMemo } from "react";
import { useSpaceLinkDetails } from "./Hooks/useSpaceLinkDetails";
import { SpaceLinkDetailsContent, SpaceLinkNotFound } from "./Components";
import { LoadingScreen } from "@Components";

export const SpaceLinkDetails = memo(() => {
  const { fetching, spaceLink, refreshSpaceLink } = useSpaceLinkDetails();

  const content = useMemo(() => {
    if (fetching) {
      return <LoadingScreen />;
    }
    if (!spaceLink) {
      return <SpaceLinkNotFound />;
    }
    return <SpaceLinkDetailsContent refresh={refreshSpaceLink} />;
  }, [fetching, refreshSpaceLink, spaceLink]);

  return <div id="organization-space-link-details">{content}</div>;
}, pureComponent);
