import { Breadcrumb } from "@Components";
import {
  PROJECT_DETAILS_SCANS_URL,
  projectLastVisitedPageRegExp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type ProjectScanListBreadcrumbProps = {
  disabled?: boolean;
};

export const ProjectScanListBreadcrumb = ({
  disabled = false,
}: ProjectScanListBreadcrumbProps) => {
  const { organizationId, projectId } = useUrlParams();

  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(projectLastVisitedPageRegExp("scans")) ??
      generateUrl(PROJECT_DETAILS_SCANS_URL, [
        organizationId ?? "",
        projectId ?? "",
      ]);

  return (
    <Breadcrumb disabled={disabled} name={"project-scans"} to={to}>
      {t("common.list-scans")}
    </Breadcrumb>
  );
};
