import { useResponsive } from "@Hooks";
import { HeaderLoader } from "./HeaderLoader";
import { baseMarginTop, cardHeight, gap } from "./constants";

export const PathExclusionLoader = () => {
  const mobile = useResponsive("md");

  const rows = 2;
  const columns = mobile ? 1 : 3;

  return (
    <>
      <HeaderLoader />
      <>
        {Array.from(Array(rows).keys()).map(row => {
          return Array.from(Array(columns).keys()).map(column => (
            <LoaderCard
              key={`${row}${column}`}
              row={row}
              column={column}
              colSize={mobile ? 1 : 3}
            />
          ));
        })}
      </>
    </>
  );
};

type CardProps = { column: number; row: number; colSize: 1 | 3 };

const LoaderCard = ({ colSize, column, row }: CardProps) => {
  const width = `${96 / colSize}%`;
  const x = `calc(${column} * (${width} + ${gap}px))`;
  const y = baseMarginTop + row * (gap + cardHeight);

  return <rect x={x} y={y} rx="3" ry="3" width={width} height={cardHeight} />;
};
