import type { Props } from "./VerticalStepper.i";
import "./index.scss";
import { PageContext } from "@Contexts";
import { VerticalStepperItems } from "./VerticalStepperItems";
import { useContext } from "react";

export { type Props as VerticalStepperProps };

export const VerticalStepper = ({ items, className }: Props) => {
  const { page, setPage } = useContext(PageContext);
  return (
    <div id="vertical-stepper" className={className}>
      <nav id="vertical-stepper-cards">
        <VerticalStepperItems
          items={items}
          activeIndex={page}
          setActiveIndex={setPage}
        />
      </nav>
    </div>
  );
};
