import { type Project, isValid } from "@Services";

type FormData = Pick<
  Project,
  "name" | "description" | "baseURL" | "isProductionURL"
>;

type FormDataValidities = {
  [key in keyof FormData]: boolean;
};

export const subset = (project: Project): FormData => ({
  name: project.name,
  description: project.description,
  baseURL: project.baseURL,
  isProductionURL: project.isProductionURL,
});

export const validities = (data: FormData): FormDataValidities => ({
  name: !!data.name,
  description: !!data.description,
  baseURL: isValid("url", data.baseURL),
  isProductionURL: typeof data.isProductionURL === "boolean",
});
