import { BEM, joinClasses } from "@Utils";
import { memo } from "react";
import { OrganizationInfo, OrganizationPlan } from "../Components";
import isEqual from "react-fast-compare";

export const OrganizationGeneralPageGeneralTab = memo(
  ({ active }: { active: boolean }) => {
    return (
      <div
        className={joinClasses(
          BEM("organization-details", "container"),
          "card",
          active && "active"
        )}
        id="organization-details-general-tab"
      >
        <OrganizationInfo />
        <OrganizationPlan />
      </div>
    );
  },
  isEqual
);
