import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendOtpHandler } from "./functions";

const useSendOtp = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setError("");
    }
    if (otp.length === 6) {
      sendOtpHandler(otp, setError, navigate, setLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return {
    setOtp,
    loading,
    error,
  };
};

export default useSendOtp;
