/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { SkeletonBox } from ".";
import { useResponsive } from "@Hooks";

const Charts = ({ y }: { y: string }) => {
  const mobileOrTablet = useResponsive("md");

  return mobileOrTablet ? (
    <>
      <rect x="50" y={y} rx="3" ry="3" width="100%" height="200" />
      <circle cx="50%" cy={parseInt(y, 10) + 400} r="22%" />
    </>
  ) : (
    <>
      <rect x="50" y={y} rx="3" ry="3" width="70%" height="300" />
      <circle cx="85%" cy={parseInt(y, 10) + 150} r="10%" />
    </>
  );
};

const HomeChart = ({ forProject }: { forProject: boolean }) => {
  const mobileOrTablet = useResponsive("md");

  return (
    <SkeletonBox width="100%" height={mobileOrTablet ? "700" : "1000"}>
      <rect x="0" y="20" rx="3" ry="3" width="24%" height="100" />
      <rect x="25.5%" y="20" rx="3" ry="3" width="24%" height="100" />
      <rect x="50.5%" y="20" rx="3" ry="3" width="24%" height="100" />
      <rect x="76%" y="20" rx="3" ry="3" width="24%" height="100" />
      <Charts y="250" />
      <rect x="0" y="650" rx="3" ry="3" width="49.5%" height="300" />
      {forProject ? (
        <rect x="51%" y="650" rx="3" ry="3" width="49%" height="300" />
      ) : (
        <>
          <rect x="51%" y="650" rx="3" ry="3" width="23.5%" height="300" />
          <rect x="76%" y="650" rx="3" ry="3" width="24%" height="300" />
        </>
      )}
    </SkeletonBox>
  );
};
export default HomeChart;
