import type { OrganizationId } from "@Interfaces";
import findProjectsByOrganizationId from "./findProjectsByOrganizationId";
import type { ListedProject } from "@Services";
import moment from "moment-timezone";
import type { ProjectListCriteria } from "src/Pages/ProjectsList/ProjectsList.i";
import { ProjectSpaceLink } from "src/Mock";

type Args = {
  organizationId: OrganizationId;
  filters: URLSearchParams;
};

export const findProjectsByFilters = ({ organizationId, filters }: Args) => {
  let projectPool = findProjectsByOrganizationId(organizationId);
  const criteria = filters.getAll("filter_criteria");
  const values = filters.getAll("filter_values");
  const predicates: Array<(p: ListedProject) => boolean> = [];

  criteria.forEach((c, i) => {
    const v = values[i] ? values[i].toLowerCase() : values[i];
    if (!v) return;
    switch (c as ProjectListCriteria) {
      case "project_name":
        predicates.push(p => p.name.includes(v));
        break;
      case "project_status":
        predicates.push(p => p.status.includes(v));
        break;
      case "project_space_link_id":
        predicates.push(
          p => ProjectSpaceLink.has(p.id) && ProjectSpaceLink.get(p.id) === v
        );
        break;
      default:
        return;
    }
  });

  projectPool = projectPool.filter(p => predicates.every(r => r(p)));
  const allowedSortBy = ["project_name", "project_created_at"];
  const allowedSortMode = ["desc", "asc"];
  const sortBy = filters.get("sort_by") || allowedSortBy[0];
  const sortMode = filters.get("sort_mode") || allowedSortMode[0];

  if (allowedSortBy.includes(sortBy) && allowedSortMode.includes(sortMode)) {
    if (sortBy === "project_name") {
      projectPool.sort((a, b) => {
        return a.name.localeCompare(b.name) * (sortMode === "asc" ? 1 : -1);
      });
    } else if (sortBy === "project_created_at") {
      projectPool.sort((a, b) => {
        return (
          moment(a.createdAt).diff(b.createdAt) * (sortMode === "asc" ? -1 : 1)
        );
      });
    }
  }

  return projectPool;
};
