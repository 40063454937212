import { useCallback, useEffect, useMemo, useState } from "react";
import type { InjectableCanvasPropsFormProps } from "../InjectableParameterForm.i";
import { equals, getEmptyInjectablesEntry } from "@Services";
import type { ButtonProps } from "src/Components/Buttons";
import { useGlobalInjectables } from "@Hooks";

export const useGlobalInjectableParameterFormCanvas = (
  props: InjectableCanvasPropsFormProps
) => {
  const { close, saveEntry, injectableEntry } = props;

  const [entry, setEntry] = useState(() =>
    getEmptyInjectablesEntry(injectableEntry)
  );
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    close();
    setEntry(getEmptyInjectablesEntry());
  }, [close]);

  useEffect(() => {
    const newEntry = getEmptyInjectablesEntry(injectableEntry);
    setEntry(current => (equals(current, newEntry) ? current : newEntry));
  }, [injectableEntry]);

  const submitButtons = useMemo<ButtonProps[]>(
    () => [
      {
        color: "primary",
        children: "common.add",
        iconClass: "bi bi-plus-square-fill",
        disabled: !formValid,
        loading,
        data: { cy: "submit-injectable-canvas-button" },
        onClick: () => {
          setLoading(true);
          saveEntry(entry)
            .then(() => handleClose())
            .finally(() => setLoading(false));
        },
      },
    ],
    [entry, formValid, handleClose, loading, saveEntry]
  );

  const localInjectables = useGlobalInjectables();

  return {
    ...props,
    entry,
    setEntry,
    setFormValid,
    loading,
    submitButtons,
    close: handleClose,
    localInjectables,
  };
};
