export const puncts = [
  "U+0021",
  "U+0022",
  "U+0023",
  "U+0025",
  "U+0026",
  "U+0027",
  "U+0028",
  "U+0029",
  "U+002A",
  "U+002C",
  "U+002D",
  "U+002E",
  "U+002F",
  "U+003A",
  "U+003B",
  "U+003F",
  "U+0040",
  "U+005B",
  "U+005C",
  "U+005D",
  "U+005F",
  "U+007B",
  "U+007D",
  "U+00A1",
  "U+00A7",
  "U+00AB",
  "U+00B6",
  "U+00B7",
  "U+00BB",
  "U+00BF",
  "U+037E",
  "U+0387",
  "U+055A",
  "U+055B",
  "U+055C",
  "U+055D",
  "U+055E",
  "U+055F",
  "U+0589",
  "U+058A",
  "U+05BE",
  "U+05C0",
  "U+05C3",
  "U+05C6",
  "U+05F3",
  "U+05F4",
  "U+0609",
  "U+060A",
  "U+060C",
  "U+060D",
  "U+061B",
  "U+061D",
  "U+061E",
  "U+061F",
  "U+066A",
  "U+066B",
  "U+066C",
  "U+066D",
  "U+06D4",
  "U+0700",
  "U+0701",
  "U+0702",
  "U+0703",
  "U+0704",
  "U+0705",
  "U+0706",
  "U+0707",
  "U+0708",
  "U+0709",
  "U+070A",
  "U+070B",
  "U+070C",
  "U+070D",
  "U+07F7",
  "U+07F8",
  "U+07F9",
  "U+0830",
  "U+0831",
  "U+0832",
  "U+0833",
  "U+0834",
  "U+0835",
  "U+0836",
  "U+0837",
  "U+0838",
  "U+0839",
  "U+083A",
  "U+083B",
  "U+083C",
  "U+083D",
  "U+083E",
  "U+085E",
  "U+0964",
  "U+0965",
  "U+0970",
  "U+09FD",
  "U+0A76",
  "U+0AF0",
  "U+0C77",
  "U+0C84",
  "U+0DF4",
  "U+0E4F",
  "U+0E5A",
  "U+0E5B",
  "U+0F04",
  "U+0F05",
  "U+0F06",
  "U+0F07",
  "U+0F08",
  "U+0F09",
  "U+0F0A",
  "U+0F0B",
  "U+0F0C",
  "U+0F0D",
  "U+0F0E",
  "U+0F0F",
  "U+0F10",
  "U+0F11",
  "U+0F12",
  "U+0F14",
  "U+0F3A",
  "U+0F3B",
  "U+0F3C",
  "U+0F3D",
  "U+0F85",
  "U+0FD0",
  "U+0FD1",
  "U+0FD2",
  "U+0FD3",
  "U+0FD4",
  "U+0FD9",
  "U+0FDA",
  "U+104A",
  "U+104B",
  "U+104C",
  "U+104D",
  "U+104E",
  "U+104F",
  "U+10FB",
  "U+1360",
  "U+1361",
  "U+1362",
  "U+1363",
  "U+1364",
  "U+1365",
  "U+1366",
  "U+1367",
  "U+1368",
  "U+1400",
  "U+166E",
  "U+169B",
  "U+169C",
  "U+16EB",
  "U+16EC",
  "U+16ED",
  "U+1735",
  "U+1736",
  "U+17D4",
  "U+17D5",
  "U+17D6",
  "U+17D8",
  "U+17D9",
  "U+17DA",
  "U+1800",
  "U+1801",
  "U+1802",
  "U+1803",
  "U+1804",
  "U+1805",
  "U+1806",
  "U+1807",
  "U+1808",
  "U+1809",
  "U+180A",
  "U+1944",
  "U+1945",
  "U+1A1E",
  "U+1A1F",
  "U+1AA0",
  "U+1AA1",
  "U+1AA2",
  "U+1AA3",
  "U+1AA4",
  "U+1AA5",
  "U+1AA6",
  "U+1AA8",
  "U+1AA9",
  "U+1AAA",
  "U+1AAB",
  "U+1AAC",
  "U+1AAD",
  "U+1B5A",
  "U+1B5B",
  "U+1B5C",
  "U+1B5D",
  "U+1B5E",
  "U+1B5F",
  "U+1B60",
  "U+1B7D",
  "U+1B7E",
  "U+1BFC",
  "U+1BFD",
  "U+1BFE",
  "U+1BFF",
  "U+1C3B",
  "U+1C3C",
  "U+1C3D",
  "U+1C3E",
  "U+1C3F",
  "U+1C7E",
  "U+1C7F",
  "U+1CC0",
  "U+1CC1",
  "U+1CC2",
  "U+1CC3",
  "U+1CC4",
  "U+1CC5",
  "U+1CC6",
  "U+1CC7",
  "U+1CD3",
  "U+2010",
  "U+2011",
  "U+2012",
  "U+2013",
  "U+2014",
  "U+2015",
  "U+2016",
  "U+2017",
  "U+2018",
  "U+2019",
  "U+201A",
  "U+201B",
  "U+201C",
  "U+201D",
  "U+201E",
  "U+201F",
  "U+2020",
  "U+2021",
  "U+2022",
  "U+2023",
  "U+2024",
  "U+2025",
  "U+2026",
  "U+2027",
  "U+2030",
  "U+2031",
  "U+2032",
  "U+2033",
  "U+2034",
  "U+2035",
  "U+2036",
  "U+2037",
  "U+2038",
  "U+2039",
  "U+203A",
  "U+203B",
  "U+203C",
  "U+203D",
  "U+203E",
  "U+203F",
  "U+2040",
  "U+2041",
  "U+2042",
  "U+2043",
  "U+2045",
  "U+2046",
  "U+2047",
  "U+2048",
  "U+2049",
  "U+204A",
  "U+204B",
  "U+204C",
  "U+204D",
  "U+204E",
  "U+204F",
  "U+2050",
  "U+2051",
  "U+2053",
  "U+2054",
  "U+2055",
  "U+2056",
  "U+2057",
  "U+2058",
  "U+2059",
  "U+205A",
  "U+205B",
  "U+205C",
  "U+205D",
  "U+205E",
  "U+207D",
  "U+207E",
  "U+208D",
  "U+208E",
  "U+2308",
  "U+2309",
  "U+230A",
  "U+230B",
  "U+2329",
  "U+232A",
  "U+2768",
  "U+2769",
  "U+276A",
  "U+276B",
  "U+276C",
  "U+276D",
  "U+276E",
  "U+276F",
  "U+2770",
  "U+2771",
  "U+2772",
  "U+2773",
  "U+2774",
  "U+2775",
  "U+27C5",
  "U+27C6",
  "U+27E6",
  "U+27E7",
  "U+27E8",
  "U+27E9",
  "U+27EA",
  "U+27EB",
  "U+27EC",
  "U+27ED",
  "U+27EE",
  "U+27EF",
  "U+2983",
  "U+2984",
  "U+2985",
  "U+2986",
  "U+2987",
  "U+2988",
  "U+2989",
  "U+298A",
  "U+298B",
  "U+298C",
  "U+298D",
  "U+298E",
  "U+298F",
  "U+2990",
  "U+2991",
  "U+2992",
  "U+2993",
  "U+2994",
  "U+2995",
  "U+2996",
  "U+2997",
  "U+2998",
  "U+29D8",
  "U+29D9",
  "U+29DA",
  "U+29DB",
  "U+29FC",
  "U+29FD",
  "U+2CF9",
  "U+2CFA",
  "U+2CFB",
  "U+2CFC",
  "U+2CFE",
  "U+2CFF",
  "U+2D70",
  "U+2E00",
  "U+2E01",
  "U+2E02",
  "U+2E03",
  "U+2E04",
  "U+2E05",
  "U+2E06",
  "U+2E07",
  "U+2E08",
  "U+2E09",
  "U+2E0A",
  "U+2E0B",
  "U+2E0C",
  "U+2E0D",
  "U+2E0E",
  "U+2E0F",
  "U+2E10",
  "U+2E11",
  "U+2E12",
  "U+2E13",
  "U+2E14",
  "U+2E15",
  "U+2E16",
  "U+2E17",
  "U+2E18",
  "U+2E19",
  "U+2E1A",
  "U+2E1B",
  "U+2E1C",
  "U+2E1D",
  "U+2E1E",
  "U+2E1F",
  "U+2E20",
  "U+2E21",
  "U+2E22",
  "U+2E23",
  "U+2E24",
  "U+2E25",
  "U+2E26",
  "U+2E27",
  "U+2E28",
  "U+2E29",
  "U+2E2A",
  "U+2E2B",
  "U+2E2C",
  "U+2E2D",
  "U+2E2E",
  "U+2E30",
  "U+2E31",
  "U+2E32",
  "U+2E33",
  "U+2E34",
  "U+2E35",
  "U+2E36",
  "U+2E37",
  "U+2E38",
  "U+2E39",
  "U+2E3A",
  "U+2E3B",
  "U+2E3C",
  "U+2E3D",
  "U+2E3E",
  "U+2E3F",
  "U+2E40",
  "U+2E41",
  "U+2E42",
  "U+2E43",
  "U+2E44",
  "U+2E45",
  "U+2E46",
  "U+2E47",
  "U+2E48",
  "U+2E49",
  "U+2E4A",
  "U+2E4B",
  "U+2E4C",
  "U+2E4D",
  "U+2E4E",
  "U+2E4F",
  "U+2E52",
  "U+2E53",
  "U+2E54",
  "U+2E55",
  "U+2E56",
  "U+2E57",
  "U+2E58",
  "U+2E59",
  "U+2E5A",
  "U+2E5B",
  "U+2E5C",
  "U+2E5D",
  "U+3001",
  "U+3002",
  "U+3003",
  "U+3008",
  "U+3009",
  "U+300A",
  "U+300B",
  "U+300C",
  "U+300D",
  "U+300E",
  "U+300F",
  "U+3010",
  "U+3011",
  "U+3014",
  "U+3015",
  "U+3016",
  "U+3017",
  "U+3018",
  "U+3019",
  "U+301A",
  "U+301B",
  "U+301C",
  "U+301D",
  "U+301E",
  "U+301F",
  "U+3030",
  "U+303D",
  "U+30A0",
  "U+30FB",
  "U+A4FE",
  "U+A4FF",
  "U+A60D",
  "U+A60E",
  "U+A60F",
  "U+A673",
  "U+A67E",
  "U+A6F2",
  "U+A6F3",
  "U+A6F4",
  "U+A6F5",
  "U+A6F6",
  "U+A6F7",
  "U+A874",
  "U+A875",
  "U+A876",
  "U+A877",
  "U+A8CE",
  "U+A8CF",
  "U+A8F8",
  "U+A8F9",
  "U+A8FA",
  "U+A8FC",
  "U+A92E",
  "U+A92F",
  "U+A95F",
  "U+A9C1",
  "U+A9C2",
  "U+A9C3",
  "U+A9C4",
  "U+A9C5",
  "U+A9C6",
  "U+A9C7",
  "U+A9C8",
  "U+A9C9",
  "U+A9CA",
  "U+A9CB",
  "U+A9CC",
  "U+A9CD",
  "U+A9DE",
  "U+A9DF",
  "U+AA5C",
  "U+AA5D",
  "U+AA5E",
  "U+AA5F",
  "U+AADE",
  "U+AADF",
  "U+AAF0",
  "U+AAF1",
  "U+ABEB",
  "U+FD3E",
  "U+FD3F",
  "U+FE10",
  "U+FE11",
  "U+FE12",
  "U+FE13",
  "U+FE14",
  "U+FE15",
  "U+FE16",
  "U+FE17",
  "U+FE18",
  "U+FE19",
  "U+FE30",
  "U+FE31",
  "U+FE32",
  "U+FE33",
  "U+FE34",
  "U+FE35",
  "U+FE36",
  "U+FE37",
  "U+FE38",
  "U+FE39",
  "U+FE3A",
  "U+FE3B",
  "U+FE3C",
  "U+FE3D",
  "U+FE3E",
  "U+FE3F",
  "U+FE40",
  "U+FE41",
  "U+FE42",
  "U+FE43",
  "U+FE44",
  "U+FE45",
  "U+FE46",
  "U+FE47",
  "U+FE48",
  "U+FE49",
  "U+FE4A",
  "U+FE4B",
  "U+FE4C",
  "U+FE4D",
  "U+FE4E",
  "U+FE4F",
  "U+FE50",
  "U+FE51",
  "U+FE52",
  "U+FE54",
  "U+FE55",
  "U+FE56",
  "U+FE57",
  "U+FE58",
  "U+FE59",
  "U+FE5A",
  "U+FE5B",
  "U+FE5C",
  "U+FE5D",
  "U+FE5E",
  "U+FE5F",
  "U+FE60",
  "U+FE61",
  "U+FE63",
  "U+FE68",
  "U+FE6A",
  "U+FE6B",
  "U+FF01",
  "U+FF02",
  "U+FF03",
  "U+FF05",
  "U+FF06",
  "U+FF07",
  "U+FF08",
  "U+FF09",
  "U+FF0A",
  "U+FF0C",
  "U+FF0D",
  "U+FF0E",
  "U+FF0F",
  "U+FF1A",
  "U+FF1B",
  "U+FF1F",
  "U+FF20",
  "U+FF3B",
  "U+FF3C",
  "U+FF3D",
  "U+FF3F",
  "U+FF5B",
  "U+FF5D",
  "U+FF5F",
  "U+FF60",
  "U+FF61",
  "U+FF62",
  "U+FF63",
  "U+FF64",
  "U+FF65",
  "U+10100",
  "U+10101",
  "U+10102",
  "U+1039F",
  "U+103D0",
  "U+1056F",
  "U+10857",
  "U+1091F",
  "U+1093F",
  "U+10A50",
  "U+10A51",
  "U+10A52",
  "U+10A53",
  "U+10A54",
  "U+10A55",
  "U+10A56",
  "U+10A57",
  "U+10A58",
  "U+10A7F",
  "U+10AF0",
  "U+10AF1",
  "U+10AF2",
  "U+10AF3",
  "U+10AF4",
  "U+10AF5",
  "U+10AF6",
  "U+10B39",
  "U+10B3A",
  "U+10B3B",
  "U+10B3C",
  "U+10B3D",
  "U+10B3E",
  "U+10B3F",
  "U+10B99",
  "U+10B9A",
  "U+10B9B",
  "U+10B9C",
  "U+10EAD",
  "U+10F55",
  "U+10F56",
  "U+10F57",
  "U+10F58",
  "U+10F59",
  "U+10F86",
  "U+10F87",
  "U+10F88",
  "U+10F89",
  "U+11047",
  "U+11048",
  "U+11049",
  "U+1104A",
  "U+1104B",
  "U+1104C",
  "U+1104D",
  "U+110BB",
  "U+110BC",
  "U+110BE",
  "U+110BF",
  "U+110C0",
  "U+110C1",
  "U+11140",
  "U+11141",
  "U+11142",
  "U+11143",
  "U+11174",
  "U+11175",
  "U+111C5",
  "U+111C6",
  "U+111C7",
  "U+111C8",
  "U+111CD",
  "U+111DB",
  "U+111DD",
  "U+111DE",
  "U+111DF",
  "U+11238",
  "U+11239",
  "U+1123A",
  "U+1123B",
  "U+1123C",
  "U+1123D",
  "U+112A9",
  "U+1144B",
  "U+1144C",
  "U+1144D",
  "U+1144E",
  "U+1144F",
  "U+1145A",
  "U+1145B",
  "U+1145D",
  "U+114C6",
  "U+115C1",
  "U+115C2",
  "U+115C3",
  "U+115C4",
  "U+115C5",
  "U+115C6",
  "U+115C7",
  "U+115C8",
  "U+115C9",
  "U+115CA",
  "U+115CB",
  "U+115CC",
  "U+115CD",
  "U+115CE",
  "U+115CF",
  "U+115D0",
  "U+115D1",
  "U+115D2",
  "U+115D3",
  "U+115D4",
  "U+115D5",
  "U+115D6",
  "U+115D7",
  "U+11641",
  "U+11642",
  "U+11643",
  "U+11660",
  "U+11661",
  "U+11662",
  "U+11663",
  "U+11664",
  "U+11665",
  "U+11666",
  "U+11667",
  "U+11668",
  "U+11669",
  "U+1166A",
  "U+1166B",
  "U+1166C",
  "U+116B9",
  "U+1173C",
  "U+1173D",
  "U+1173E",
  "U+1183B",
  "U+11944",
  "U+11945",
  "U+11946",
  "U+119E2",
  "U+11A3F",
  "U+11A40",
  "U+11A41",
  "U+11A42",
  "U+11A43",
  "U+11A44",
  "U+11A45",
  "U+11A46",
  "U+11A9A",
  "U+11A9B",
  "U+11A9C",
  "U+11A9E",
  "U+11A9F",
  "U+11AA0",
  "U+11AA1",
  "U+11AA2",
  "U+11B00",
  "U+11B01",
  "U+11B02",
  "U+11B03",
  "U+11B04",
  "U+11B05",
  "U+11B06",
  "U+11B07",
  "U+11B08",
  "U+11B09",
  "U+11C41",
  "U+11C42",
  "U+11C43",
  "U+11C44",
  "U+11C45",
  "U+11C70",
  "U+11C71",
  "U+11EF7",
  "U+11EF8",
  "U+11F43",
  "U+11F44",
  "U+11F45",
  "U+11F46",
  "U+11F47",
  "U+11F48",
  "U+11F49",
  "U+11F4A",
  "U+11F4B",
  "U+11F4C",
  "U+11F4D",
  "U+11F4E",
  "U+11F4F",
  "U+11FFF",
  "U+12470",
  "U+12471",
  "U+12472",
  "U+12473",
  "U+12474",
  "U+12FF1",
  "U+12FF2",
  "U+16A6E",
  "U+16A6F",
  "U+16AF5",
  "U+16B37",
  "U+16B38",
  "U+16B39",
  "U+16B3A",
  "U+16B3B",
  "U+16B44",
  "U+16E97",
  "U+16E98",
  "U+16E99",
  "U+16E9A",
  "U+16FE2",
  "U+1BC9F",
  "U+1DA87",
  "U+1DA88",
  "U+1DA89",
  "U+1DA8A",
  "U+1DA8B",
  "U+1E95E",
  "U+1E95F",
];
