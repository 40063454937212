import { ThemeContext } from "@Contexts";
import { t } from "i18next";
import { useContext } from "react";

export const DashboardLoader = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <section className="flex-center w-100 h-100">
      <div
        className={`spinner-border ${theme === "dark" ? " text-light" : "text-dark"}`}
        role="status"
      >
        <span className="sr-only"></span>
      </div>
      <h1 className="my-0 ms-3">{t("common.loading-dashboard-content")}</h1>
    </section>
  );
};
