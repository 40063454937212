import type { ConfirmPasswordResetRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getUserByEmail } from "src/Mock";

export const confirmPasswordReset = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { email, passwordConfirm, passwordNew, token } = (await req.json()) as {
    email: string;
    passwordConfirm: string;
    passwordNew: string;
    token: string;
  } as ConfirmPasswordResetRequest;
  const user = getUserByEmail(email);
  if (!user || passwordNew !== passwordConfirm || !token) {
    return res(ctx.delay(delay), ctx.status(400));
  }
  return res(ctx.delay(delay), ctx.json({}));
};
