import { useCallback, useMemo, useState } from "react";
import type { EditUserCanvasProps } from "../EditUserCanvas.i";
import { equals } from "@Services";
import { useToast, useUrlParams } from "@Hooks";
import { useSaveUserForm } from "./useSaveUserForm";

export const useEditUserCanvas = ({
  close,
  isOpen,
  onSubmit,
  selfEditing,
  user: propsUser,
}: EditUserCanvasProps) => {
  const [user, setUser] = useState({ ...propsUser });

  const [canvasLoading, setCanvasLoading] = useState(false);
  const formDirty = useMemo(() => !equals(propsUser, user), [propsUser, user]);
  const [formValid, setFormValid] = useState(false);
  const { organizationId, userId } = useUrlParams();

  const toast = useToast();

  const handleClose = useCallback(() => {
    close();
    setUser({ ...propsUser });
  }, [close, propsUser]);

  const getPromises = useSaveUserForm(propsUser, user);

  const onFullySuccessfullEdit = useCallback(() => {
    toast({ message: "successes.put-user" });
    onSubmit();
    handleClose();
  }, [handleClose, onSubmit, toast]);

  const onPartialSuccessEdit = useCallback(() => {
    onSubmit();
    handleClose();
  }, [handleClose, onSubmit]);

  const handleSubmit = useCallback(() => {
    if (!userId || !organizationId) throw new Error("missing-url-params");
    setCanvasLoading(true);
    getPromises()
      .then(responses => {
        if (!responses.length) return;
        const { length } = responses;
        const successReqCount = responses.reduce((total, response) => {
          return (total += +response);
        }, 0);
        if (successReqCount === 0) return; // everythings FAILED
        if (successReqCount === length) return onFullySuccessfullEdit();
        return onPartialSuccessEdit();
      })
      .finally(() => setCanvasLoading(false));
  }, [
    getPromises,
    onFullySuccessfullEdit,
    onPartialSuccessEdit,
    organizationId,
    userId,
  ]);

  return {
    isOpen,
    close: handleClose,
    selfEditing,
    user,
    setUser,
    formValid,
    setFormValid,
    formDirty,
    canvasLoading,
    handleSubmit,
  };
};
