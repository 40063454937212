import type { TableProps } from "@Components";
import { MethodBadge } from "@Components";
import type { Method as MethodI, Nullable } from "@Interfaces";
import type { AuthProfileInfo, AuthResolvedOperation } from "@Services";
import { limitString } from "@Utils";
import { t } from "i18next";

export const getHeaders = (profiles: Nullable<Array<AuthProfileInfo>>) => {
  if (!profiles) return [];

  const output: TableProps["headers"] = [
    { text: t(`common.endpoint`), sortByKey: "auth_matrix_path" },
    { text: t(`common.category`), sortByKey: "auth_matrix_category" },
  ];

  if (profiles.length && profiles.length) {
    profiles.forEach(user => {
      let name = user.name || t("common.anonymous");

      if (name.length >= 15) {
        name = name.substring(0, 12) + "...";
      }

      output.push({
        text: name,
        tooltip: user.name ? (
          <>
            <div>{`${t("common.name")}: ${user.name}`}</div>
            <div>{`${t("common.role")}: ${user.role}`}</div>
            <div>{`${t("common.group")}: ${user.group}`}</div>
          </>
        ) : undefined,
      });
    });
  }

  return output;
};

export const getConfig = (
  profiles: Nullable<Array<AuthProfileInfo>>,
  operations: Nullable<Array<AuthResolvedOperation>>
) => {
  if (!operations) {
    return [];
  }

  return operations.map(data => {
    const items = [
      {
        jsx: (
          <div className="d-flex align-items-center">
            <MethodBadge className="me-2" type={data.verb as MethodI} />
            <span title={data.path}>{limitString(data.path, 50)}</span>
          </div>
        ),
      },
      {
        jsx: <div>{data.predicted_category_semantics}</div>,
      },
    ];

    if (profiles && profiles.length) {
      profiles.forEach(user => {
        const userData = data.profiles.find(u => u.id === user.id);
        const notUsedAPI = typeof userData === "undefined";
        const resolved = userData?.resolved;

        items.push({
          jsx: notUsedAPI ? (
            <div
              data-tooltip-id="tooltip"
              data-tooltip-content={t("common.not-used-api")}
            >
              <i className="bi-dash" />
            </div>
          ) : resolved ? (
            <i className="bi-check" />
          ) : (
            <div
              data-tooltip-id="tooltip"
              data-tooltip-content={t("common.api-with-error")}
            >
              <i className="bi-x" />
            </div>
          ),
        });
      });
    }

    return {
      props: {},
      items,
    };
  });
};
