import { useAuthenticationSettingFormStore } from "@Stores";
import { memo, type FC } from "react";
import { FormGroup } from "reactstrap";
import { AdditionalDataForm } from "./AdditionalDataForm";
import { AdditionalDataTable } from "./AdditionalDataTable";

export const AdditionalDataTab: FC = memo(() => {
  const { authenticationSettings } = useAuthenticationSettingFormStore();

  const additionalData = authenticationSettings.oAuthData?.additionalData ?? {};
  const noMargin = Object.keys(additionalData).length === 0;

  return (
    <>
      <FormGroup noMargin={noMargin}>
        <AdditionalDataForm />
      </FormGroup>
      <AdditionalDataTable />
    </>
  );
});
