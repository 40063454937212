import { FormInput, FormMessage, GridContainer } from "@Components";
import type { InjectableFormProps } from "../InjectableParameterForm.i";
import type { FC } from "react";
import { useInjectableParameterForm } from "./useInjectableParameterForm";
import type { Options } from "@Interfaces";
import { InjectableLocation, capitalizeString } from "@Services";

const locationOptions: Options = Object.values(InjectableLocation).map(
  location => ({
    label: capitalizeString(location),
    value: location,
  })
);

export const InjectableParameterForm: FC<InjectableFormProps> = props => {
  const {
    injectableEntry,
    setInjectableEntry,
    validities,
    disabled,
    injectableNameError,
  } = useInjectableParameterForm(props);
  return (
    <GridContainer columns={1} rows={3} id="injectable-parameter-form">
      <div>
        <FormInput
          type="text"
          bsSize="sm"
          disabled={disabled}
          label="common.name"
          name="name"
          id="injectable-parameter-name"
          data-cy="injectable-parameter-input-name"
          value={injectableEntry.name}
          onChange={setInjectableEntry}
          valid={validities.name}
          invalid={!validities.name}
          required
          autoTrim
        />
        {injectableNameError && (
          <FormMessage
            color={injectableNameError.severity}
            message={injectableNameError.message}
          />
        )}
      </div>
      <FormInput
        type="select"
        bsSize="sm"
        disabled={disabled}
        name="location"
        label="common.location"
        id="injectable-parameter-location"
        data-cy="injectable-parameter-input-location"
        value={injectableEntry.location}
        options={locationOptions}
        valid={validities.location}
        invalid={!validities.location}
        onChange={setInjectableEntry}
        required
      />
      <FormInput
        type="textarea"
        bsSize="sm"
        disabled={disabled}
        label="common.value"
        name="value"
        id="injectable-parameter-value"
        data-cy="injectable-parameter-input-value"
        value={injectableEntry.value}
        onChange={setInjectableEntry}
        valid={validities.value}
        invalid={!validities.value}
        required
        autoTrim
      />
    </GridContainer>
  );
};
