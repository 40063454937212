import type { Dispatch } from "react";
import { useEffect, useState } from "react";
import { isValid } from "@Services";
import type { NewProjectData } from "@Interfaces";

type HookProps = {
  project: NewProjectData;
  baseUrlSuggestions: string[];
  updateBaseURL: Dispatch<string>;
  originalBaseURL?: string; // in project settings is the original project baseURL, else undefined
};

const isValidUrl = (url: string) => isValid("url", url);

export const useDefaultBaseUrl = ({
  project,
  baseUrlSuggestions,
  updateBaseURL,
  originalBaseURL,
}: HookProps) => {
  const [storedProjectBaseURL] = useState(originalBaseURL);
  const [urlInjectable, setUrlInjectable] = useState(false);
  const { oasUploadData, baseURL } = project;

  useEffect(() => {
    if (urlInjectable && !baseURL && oasUploadData.length) {
      // (injection) evaluating the baseURL
      let newBaseURL = storedProjectBaseURL;
      newBaseURL ||= baseUrlSuggestions.find(isValidUrl);
      newBaseURL ||= "";
      updateBaseURL(newBaseURL);
      setUrlInjectable(false);
    }
    if (!oasUploadData.length) {
      // the next time a file is provided, do one of the following :
      // - no storedProjectBaseURL (create project) => inject baseURL
      // - no baseURL (create/update project) => inject baseURL
      // - storedProjectBaseURL & baseURL => NOT inject baseURL
      setUrlInjectable(!storedProjectBaseURL || !baseURL);
    }
  }, [
    baseUrlSuggestions,
    storedProjectBaseURL,
    oasUploadData,
    baseURL,
    updateBaseURL,
    urlInjectable,
  ]);
};
