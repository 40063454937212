import {
  HOME_PATH,
  ORGANIZATION_PATH_GENERAL,
  PROJECTS_PATH,
} from "@Constants";
import type { NavbarConfigurationItem } from "./Nav.i";

export const navConfig: NavbarConfigurationItem[] = [
  {
    to: HOME_PATH,
    label: "nav.dashboard",
    icon: "bi bi-house-fill",
    section: "homepage",
  },
  {
    to: PROJECTS_PATH,
    prefix: "projects",
    label: "nav.project-list",
    icon: "bi bi-braces-asterisk",
    section: "projects",
  },
  {
    to: ORGANIZATION_PATH_GENERAL,
    label: "nav.organization",
    icon: "bi bi-diagram-3-fill",
    section: "organization",
  },
];
