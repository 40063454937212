export const findIdFromRestUrl = (pathname: string, needle: string) => {
  const splitted = pathname.split("/");
  const index = splitted.indexOf(needle);
  return index < 0 ? undefined : splitted[index + 1];
};

export const findOrganizationIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "organizations");
};

export const findUserIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "users");
};

export const findProjectIdFromUrl = (pathname: string) => {
  return (
    findIdFromRestUrl(pathname, "projects") ??
    findIdFromRestUrl(pathname, "project")
  );
};

export const findIssueIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "issues");
};

export const findHistoryIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "history");
};

export const findApiDefinitionFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "api-definitions");
};

export const findScanIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "scans");
};

export const findApiKeyIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "apikeys");
};

export const findInventoryFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "inventory");
};

export const findSpaceLinkFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "spacelinks");
};
