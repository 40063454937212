import { Button, Offcanvas } from "@Components";
import { BEM, addLineToCode, getFormattedHttp, joinClasses } from "@Utils";
import hljs from "highlight.js/lib/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { ActivityView } from "../ActivityView";
import { ClipboardBadge } from "../Badge";
import type { Props } from "./HTTPViewer.i";
import "./style.scss";

const opts = { language: "http" };

export const HTTPViewer = ({
  value,
  showTitleBar = true,
  title,
  className,
}: Props) => {
  const outputRef = useRef<HTMLPreElement>(null);
  const [canvasOpen, setCanvasOpen] = useState(false);
  const localizedTitle = typeof title === "string" ? title : "common.http-code";

  useEffect(() => {
    if (!outputRef.current) {
      return;
    }
    const formattedHttp = getFormattedHttp(value);
    const highlightResult = hljs.highlight(formattedHttp, opts);
    outputRef.current.innerHTML = addLineToCode(highlightResult.value);
  }, [value, outputRef]);

  useEffect(() => {
    if (!canvasOpen) {
      return;
    }

    const timeout = setTimeout(() => {
      if (!outputRef.current) {
        return;
      }
      const offCanvasPre = document.querySelector(".http-viewer-offcanvas-pre");
      if (offCanvasPre) {
        offCanvasPre.innerHTML = outputRef.current.innerHTML;
      }
    }, 0); /* wait next frame */

    return () => clearTimeout(timeout);
  }, [canvasOpen, outputRef]);

  const handleToggleCanvas = useCallback(() => {
    setCanvasOpen(co => !co);
  }, []);

  return (
    <>
      <div className={joinClasses("http-code", className)}>
        <ActivityView
          bodyContent={<pre ref={outputRef} />}
          showTitleBar={showTitleBar}
          titleContent={
            <div className={BEM("http-code", "title-bar")}>
              <span>{title}</span>
              <div>
                <Button
                  iconClass="bi bi-arrows-fullscreen"
                  color={"primary"}
                  onClick={handleToggleCanvas}
                />
                <ClipboardBadge value={value} />
              </div>
            </div>
          }
        />
      </div>
      <Offcanvas
        className="full-height"
        isOpen={canvasOpen}
        title={localizedTitle}
        toggle={handleToggleCanvas}
        exitLabel="common.close"
        children={
          <div className="http-code on-canvas">
            <pre className="http-viewer-offcanvas-pre" />
            <ClipboardBadge value={value} />
          </div>
        }
      />
    </>
  );
};
