import type { ListedIssue } from "@Services";
import { findProjectsByOrganizationId } from "../utils";
import { issues } from "../data/issues";
import { getRandomBetweenMinMax, randomOwaspTopTen } from "../helpers";
import type { Method } from "@Interfaces";
import moment from "moment-timezone";

type VulnerabilityDetail = {
  name: string;
  description: string;
  severity: string;
  remediation: string;
};

export const vulnerabilities: VulnerabilityDetail[] = [
  {
    name: "Broken Object Level Authorization",
    description: `## Broken Object Level Authorization (BOLA)

BOLA is a critical security vulnerability that occurs when an application or API fails to properly enforce access control rules for specific objects. This allows unauthorized users to access sensitive data or perform actions that they should not be able to do.

BOLA vulnerabilities can have severe consequences for an organization, including:

* **Data breaches:** Unauthorized access to sensitive data can lead to financial losses, reputational damage, and privacy violations.
* **Malicious actions:** Attackers can manipulate data, delete critical information, or even take control of the application or API.
* **Downtime:** Vulnerabilities can cause system outages, disrupting operations and impacting users.

Organizations must take steps to mitigate BOLA vulnerabilities to protect their data and systems from unauthorized access and manipulation.`,
    severity: "high",
    remediation: `BOLA vulnerabilities can be mitigated by implementing a comprehensive remediation strategy that includes the following measures:

1. **Thorough input validation:**
    - Validate all incoming data to ensure it is in the correct format and does not contain malicious code.
    - Employ a variety of validation techniques to cover all potential risks, including checking for the correct format, length, and content of data.
    - Utilize automated tools to expedite and simplify input data validation.
    - Conduct thorough testing of input data using diverse testing methods, such as fuzz testing and boundary testing.

2. **Strong access control mechanisms:**
    - Enforce role-based access control (RBAC) to assign permissions based on user roles and restrict access to specific objects.
    - Utilize access control lists (ACLs) to define granular access privileges for specific users or groups.
    - Implement context-aware access control to dynamically adjust permissions based on user context, such as location or device, enhancing security.

3. **Effective error handling:**
    - Provide generic error messages that do not disclose sensitive information or provide clues about the underlying vulnerability.
    - Mask sensitive details in error messages to prevent attackers from gaining insights into the application's architecture or access control mechanisms.
    - Securely log errors in a centralized location for analysis and troubleshooting.

4. **Regular security assessments:**
    - Conduct periodic security assessments to identify and address newly discovered BOLA vulnerabilities promptly.
    - Employ automated security scanners and penetration testing techniques to thoroughly scan applications and APIs for potential vulnerabilities.
    - Prioritize critical areas such as sensitive data, high-risk actions, and commonly targeted components.

5. **Continuous monitoring and testing:**
    - Integrate BOLA vulnerability scanning and testing into CI/CD pipelines to ensure continuous monitoring and remediation.
    - Automate vulnerability detection and remediation processes to address issues swiftly and prevent further exploitation.
    - Employ security monitoring tools to detect anomalies and suspicious activity that could indicate BOLA exploitation attempts.

6. **Employee training:**
    - Provide ongoing security awareness training to educate employees about BOLA risks and best practices for secure coding, input validation, and error handling.
    - Highlight the importance of reporting potential vulnerabilities and suspicious activity to the security team.
    - Foster a culture of security within the organization to encourage responsible disclosure and incident response.

By implementing these remediation measures and adopting continuous monitoring practices, organizations can effectively mitigate BOLA risks, safeguard their data and systems from unauthorized access and manipulation, and maintain a robust security posture.`,
  },
  {
    name: "Broken Authentication",
    description:
      "Broken authentication refers to weaknesses in the authentication process that can allow unauthorized users to gain access to protected resources.",
    severity: "high",
    remediation:
      "Improve authentication mechanisms, use strong password policies, implement multi-factor authentication (MFA), and regularly audit user accounts for suspicious activity.",
  },
  {
    name: "Broken Object Property Level Authorization",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    severity: "medium",
    remediation:
      "Enhance object property level authorization checks to ensure that users can only access authorized properties of objects.",
  },
  {
    name: "Unrestricted Resource Consumption",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    severity: "medium",
    remediation:
      "Implement resource usage limits and rate limiting to prevent excessive resource consumption by individual users or requests.",
  },
  {
    name: "Broken Function Level Authorization",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    severity: "medium",
    remediation:
      "Review and enhance access control mechanisms for functions to ensure that only authorized users can execute them.",
  },
  {
    name: "Unrestricted Access to Sensitive Business Flows",
    description:
      "Unrestricted access to sensitive business flows can result in unauthorized users being able to interact with critical business processes.",
    severity: "medium",
    remediation:
      "Implement access controls and role-based permissions to restrict access to sensitive business flows based on user roles and privileges.",
  },
  {
    name: "Server Side Request Forgery",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    severity: "medium",
    remediation:
      "Validate and sanitize user-input URLs, use whitelists for allowed domains, and employ network-level protections against SSRF attacks.",
  },
  {
    name: "Security Misconfiguration",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    severity: "low",
    remediation:
      "Conduct regular security audits, review and secure configuration files, and follow security best practices for server and application configurations.",
  },
  {
    name: "Improper Inventory Management",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    severity: "low",
    remediation:
      "Implement proper inventory management processes and tracking systems to improve efficiency and accuracy.",
  },
  {
    name: "Unsafe Consumption of APIs",
    description:
      "Unsafe consumption of APIs can result in vulnerabilities related to how an application interacts with external APIs, potentially exposing it to risks.",
    severity: "low",
    remediation:
      "Review and secure API integrations, validate input data, and implement proper error handling to mitigate API-related risks.",
  },
  {
    name: "XML External Entity (XXE) Attacks",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    severity: "high",
    remediation:
      "Disable external entity references, validate and sanitize XML input, and use modern XML parsers with XXE protection features.",
  },
  {
    name: "Missing CORS Headers",
    description:
      "Missing Cross-Origin Resource Sharing (CORS) headers can lead to security issues when interacting with web resources from different origins.",
    severity: "low",
    remediation:
      "Configure appropriate CORS headers to restrict cross-origin requests and prevent unauthorized access to resources.",
  },
  {
    name: "JSON - Use of Weak Encryption",
    description:
      "The use of weak encryption in JSON data can result in data being inadequately protected, leading to potential security breaches.",
    severity: "high",
    remediation:
      "Upgrade encryption algorithms to stronger and more secure methods, and ensure proper key management and data protection practices.",
  },
  {
    name: "Failure to Invalidate Session",
    description:
      "Failure to invalidate sessions properly can result in security issues where users can continue to access resources even after logging out.",
    severity: "low",
    remediation:
      "Implement session timeout mechanisms, securely invalidate sessions upon logout, and follow session management best practices.",
  },
  {
    name: "Unencrypted communications",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    severity: "information",
    remediation:
      "Applications should use transport-level encryption (SSL/TLS) to protect all communications passing between the client and the server. The Strict-Transport-Security HTTP header should be used to ensure that clients refuse to access the server over an insecure connection.",
  },
  {
    name: "Input returned in response (reflected)",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    severity: "information",
    remediation:
      "Applications should avoid to reflect the user input as output if not strictly necessary and in case encoded it in the right way to avoid client side attacks.",
  },
];

export function issuesSeeder(organizationID: string): void {
  const orgProjects = findProjectsByOrganizationId(organizationID).filter(
    p => p.status !== "pending"
  );
  const cachedIssues = issues;

  if (orgProjects.length === 0) {
    // -------------------------------------
    // no project -> no issues
    // -------------------------------------
    cachedIssues.set(organizationID, []);
  } else if (!cachedIssues.has(organizationID)) {
    // -------------------------------------
    // no cache -> create issues
    // -------------------------------------
    const issuesCount = orgProjects.length * 30;

    const randomProjectData = () => {
      const r = Math.floor(Math.random() * orgProjects.length);
      const p = orgProjects[r];
      return [p.id, p.name];
    };

    const randomEndpoint = () => {
      const baseResources = [
        "/users",
        "/books",
        "/orders",
        "/stats",
        "/comments",
        "/products",
      ];

      const specificResources = [
        "/details",
        "/logs",
        "/updates",
        "/history",
        "/notifications",
      ];

      const withIDs = [
        "/{userID}",
        "/{bookID}",
        "/{orderID}",
        "/{statID}",
        "/{commentID}",
        "/{productID}",
      ];

      // Get a random base resource
      const base =
        baseResources[Math.floor(Math.random() * baseResources.length)];

      // Decide whether to add a specific resource or ID or keep the base only
      const dice = Math.floor(Math.random() * 4);
      if (dice === 1) {
        // Return base with a specific resource
        const specific =
          specificResources[
            Math.floor(Math.random() * specificResources.length)
          ];
        return `${base}${specific}`;
      } else if (dice === 2) {
        // Return base with an ID
        const id = withIDs[Math.floor(Math.random() * withIDs.length)];
        return `${base}${id}`;
      } else if (dice === 3) {
        // Return base with an ID followed by a specific resource
        const id = withIDs[Math.floor(Math.random() * withIDs.length)];
        const specific =
          specificResources[
            Math.floor(Math.random() * specificResources.length)
          ];
        return `${base}${id}${specific}`;
      } else {
        // Return just the base resource
        return base;
      }
    };

    const randomCategory = () => {
      const cateogories = [
        "Malicious Input Handling",
        "Access Control",
        "Session Management",
        "Authentication",
        "Error Handling",
        "Authentication",
        "Configuration and Deployments",
      ];
      const r = Math.floor(Math.random() * cateogories.length);
      return cateogories[r];
    };

    const randomVulnerabilityDetail = (): VulnerabilityDetail => {
      const r = Math.floor(Math.random() * vulnerabilities.length);
      return vulnerabilities[r];
    };

    let newIssueCounter = orgProjects.length * getRandomBetweenMinMax(1, 15);

    const issues: ListedIssue[] = [];
    for (let index = issuesCount; index > 0; index--) {
      const [projectID, projectName] = randomProjectData();

      // Fetch the vulnerability detail which includes both description and severity
      const vulnerabilityDetail = randomVulnerabilityDetail();

      const methods: Method[] = ["GET", "POST", "PUT", "DELETE", "PATCH"];

      let selectedMethod: Method;
      if (Math.random() < 0.9) {
        selectedMethod = methods[Math.floor(Math.random() * 3)];
      } else {
        selectedMethod = methods[Math.floor(Math.random() * 2) + 3];
      }
      const days = Math.trunc(index / 5);
      const time = moment().add(-days, "day");
      const day = time.startOf("day").toISOString();

      issues.push({
        id: `${organizationID}000${index}`,
        scanID: `${getRandomBetweenMinMax(25, 100)}`,
        severity: vulnerabilityDetail.severity,
        projectID,
        projectName,
        cwes: Array.from({ length: Math.floor(Math.random() * 5) + 1 }, () =>
          getRandomBetweenMinMax(100, 500).toString()
        ),
        endpoint: randomEndpoint(),
        category: randomCategory(),
        description: vulnerabilityDetail.description,
        owaspTopTen: Math.random() > 0.1 ? randomOwaspTopTen() : "",
        name: vulnerabilityDetail.name,
        verb: selectedMethod,
        createdAt: day,
        cvss: +(Math.random() * 10).toFixed(2),
        isNew: newIssueCounter-- > 0,
      });
    }

    cachedIssues.set(organizationID, issues);
  }
}
