import type { ValidityState } from "@Interfaces";
import { projectMaxEndDate, recurrences, type RunPolicy } from "@Services";
import type { MomentInput } from "moment-timezone";
import moment from "moment-timezone";

export const dateToFormattedString = (d: Date) => {
  return moment(d).format("YYYY-MM-DD");
};

export const getValidities = (
  runPolicy: RunPolicy,
  minStartDate: MomentInput
): ValidityState<RunPolicy> => {
  const { businessHours, endDate, recurrence, startDate } = runPolicy;

  const r = recurrence;

  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);
  const momentMinStartDate = moment(minStartDate);

  const result: ValidityState<RunPolicy> = {
    businessHours: businessHours !== undefined,
    recurrence: (recurrences as string[]).includes(r),
    startDate: false,
    endDate: false,
  };

  if (recurrence === "one_shot") {
    result.startDate = momentMinStartDate.isSameOrBefore(
      momentStartDate,
      "date"
    );
    result.endDate = projectMaxEndDate === runPolicy.endDate;
    return result; // <= EXIT VALIDATION
  }

  if (recurrence === "none") {
    result.startDate = projectMaxEndDate === runPolicy.startDate;
    result.endDate = projectMaxEndDate === runPolicy.endDate;
    return result; // <= EXIT VALIDATION
  }

  // recurrence = weekly | monthly | yearly

  result.startDate =
    momentMinStartDate.isValid() &&
    momentStartDate.isValid() &&
    momentMinStartDate.isSameOrBefore(momentStartDate, "date");

  result.endDate = momentEndDate.isValid();

  return result;
};
