import type { OrganizationAdminInviteRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  roles,
  users,
  findRolesByUserId,
  getUserByEmail,
} from "src/Mock";
import moment from "moment-timezone";

export const userInvite = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as Record<string, string>;

  const payload = (await req.json()) as OrganizationAdminInviteRequest;

  const { email, role, expiresAt } = payload;

  const user = getUserByEmail(email);

  if (user) {
    const activeRoles = findRolesByUserId(user.id);
    if (activeRoles.find(role => role.organizationID === organizationId)) {
      // user already in organization
      return res(ctx.delay(delay), ctx.status(400));
    }
  }

  const maxUsedUserId = Math.max(...users.map(u => Number(u.id)));
  const id = `${maxUsedUserId + 1}`;

  users.push({
    id,
    twoFactorAuthenticationEnabled: true,
    active: true,
    roleActive: true,
    createdAt: moment().format("YYYY-MM-DD"),
    lastLogin: "", // default : never logged
    ...payload,
  });

  roles.push({
    id,
    role,
    organizationID: organizationId,
    active: true,
    expiresAt,
  });

  return res(ctx.delay(delay), ctx.status(200));
};
