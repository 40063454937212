import { ExtensionCategoriesTable, SettingsContainer } from "@Components";
import { useMemo } from "react";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const ExtensionsSettings = () => {
  const { loading, extensionSettings } = useSettingsStore();

  const content = useMemo(() => {
    return (
      <SettingsContainer loading={loading} section="extensions">
        <div className="p-4">
          <ExtensionCategoriesTable
            categories={extensionSettings.categories ?? []}
            disabled
          />
        </div>
      </SettingsContainer>
    );
  }, [extensionSettings, loading]);

  return <div id="scan-extension-settings">{content}</div>;
};
