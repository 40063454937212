import type { ButtonProps } from "@Components";
import { useIssueListStore } from "@Stores";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIssueDetailsCanvasExcludeButton } from "./useIssueDetailsCanvasExcludeButton";
import { useIssueDetailsCanvasIssueIds } from "./useIssueDetailsCanvasIssueIds";
import { useIssueDetailsCanvasShareButton } from "./useIssueDetailsCanvasShareButton";
import { useIssueDetailsCanvasStore } from "../IssueDetailsCanvas.store";

export const useIssueDetailsCanvas = () => {
  const { organizationId, projectId, scanId } = useIssueDetailsCanvasIssueIds();
  const { excludeButton } = useIssueDetailsCanvasExcludeButton();
  const { openIssueId, setOpenIssueId, clearOpenIssueId } = useIssueListStore();
  const { shareButton } = useIssueDetailsCanvasShareButton();
  const { reset } = useIssueDetailsCanvasStore();

  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenIssueIdChange = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    if (openIssueId) {
      searchParams.set("isp_issueId", openIssueId);
    } else {
      searchParams.delete("isp_issueId");
    }
    navigate(`${location.pathname}?${searchParams}${location.hash}`, {
      replace: false,
    });
  }, [
    location.hash,
    location.pathname,
    location.search,
    navigate,
    openIssueId,
  ]);

  useEffect(() => {
    handleOpenIssueIdChange();
  }, [handleOpenIssueIdChange]);

  useEffect(() => {
    if (!organizationId || !projectId || !scanId) {
      clearOpenIssueId();
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("isp_issueId")) {
      setOpenIssueId(
        searchParams.getAll("isp_issueId")[0],
        scanId,
        projectId,
        organizationId
      );
    }
  }, [
    clearOpenIssueId,
    location.search,
    setOpenIssueId,
    organizationId,
    projectId,
    scanId,
  ]);

  useEffect(() => reset, [reset]);

  const closeCanvas = useCallback(() => {
    clearOpenIssueId();
  }, [clearOpenIssueId]);

  return {
    canvasOpen: !!openIssueId,
    canvasButtons: [excludeButton, shareButton] as Array<ButtonProps>,
    closeCanvas,
  };
};
