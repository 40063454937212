import { AuthenticationSettingsForm } from "@Components";
import { useAuthenticationCanvas } from "../Hooks";
import isEqual from "react-fast-compare";
import { memo } from "react";

export const AuthenticationOffCanvas = memo(() => {
  const { formValue, showCanvas, invalidNames, handleSubmit, hideCanvas } =
    useAuthenticationCanvas();

  if (!formValue) {
    return null;
  }

  return (
    <AuthenticationSettingsForm
      authenticationSettings={formValue.value.authenticationSettings}
      injectables={formValue.value.injectables ?? []}
      mTLSCertificates={formValue.value.mTLSCertificates ?? []}
      show={showCanvas}
      setShow={hideCanvas}
      loading={false}
      onSubmit={handleSubmit}
      invalidAuthenticationSettingsNames={invalidNames}
    />
  );
}, isEqual);
