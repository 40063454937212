import { Breadcrumb } from "@Components";
import {
  PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH,
  projectLastVisitedPageRegExp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type ProjectAPIDefinitionsInventoryBreadcrumbProps = {
  disabled?: boolean;
};

export const ProjectAPIDefinitionsInventoryBreadcrumb = ({
  disabled = false,
}: ProjectAPIDefinitionsInventoryBreadcrumbProps) => {
  const { organizationId, projectId, apiDefinitionId } = useUrlParams();

  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(
        projectLastVisitedPageRegExp(
          "api-definitions/:apiDefinitionId/inventory"
        )
      ) ??
      generateUrl(PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH, [
        organizationId ?? "",
        projectId ?? "",
        apiDefinitionId ?? "",
      ]);
  return (
    <Breadcrumb disabled={disabled} name={"project-api-definitions"} to={to}>
      {t("common.inventory")}
    </Breadcrumb>
  );
};
