import React, { memo } from "react";
import { useDropzone } from "react-dropzone";
import type { Props } from "./Upload.i";
import { t } from "i18next";

export const Upload = memo(({ handleFileSelected }: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/json": [".json"],
      "text/yaml": [".yaml", ".yml"],
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 0) {
        handleFileSelected(acceptedFiles[0]); // Passa solo il primo file
      }
    },
    multiple: false,
  });

  return (
    <div {...getRootProps()} className="upload-file">
      <input {...getInputProps()} />
      <p>{t("project.drag-and-drop")}</p>
      <i className="bi-upload" />
      <p>{t("project.valid-formats")}</p>
    </div>
  );
});
