import { FormInput, Section } from "@Components";
import { APP_THEME_VALUES, APP_ZOOM_VALUES } from "@Constants";
import { ThemeContext, type Theme } from "@Contexts";
import { usePreference } from "@Hooks";
import { useUIStore } from "@Stores";
import { useCallback, useContext, type ChangeEvent } from "react";

export const StyleCard = () => {
  const { setTheme } = useContext(ThemeContext);
  const { setAppZoom } = useUIStore();
  const [appZoom, setPreferenceAppZoom] = usePreference("appZoom", "100");
  const [prefTheme] = usePreference("theme", "system");

  const handleSelectAppTheme = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTheme(e.target.value as Theme);
    },
    [setTheme]
  );

  const handleSelectAppZoom = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAppZoom(+e.target.value);
      setPreferenceAppZoom(e.target.value);
      document.documentElement.style.setProperty(
        "--app-zoom",
        `${e.target.value}%`
      );
    },
    [setAppZoom, setPreferenceAppZoom]
  );

  return (
    <Section title="profile.theme-title" id="profile-style-card">
      <FormInput
        type="select"
        id="app-theme"
        data-cy="app-theme-select"
        defaultValue={prefTheme ?? "system"}
        label="profile.theme"
        options={APP_THEME_VALUES}
        onChange={handleSelectAppTheme}
      ></FormInput>

      <FormInput
        type="select"
        id="app-zoom"
        data-cy="app-zoom-select"
        defaultValue={appZoom ? +appZoom : 100}
        label="profile.app-zoom"
        className="mt-3"
        options={APP_ZOOM_VALUES}
        onChange={handleSelectAppZoom}
      />
    </Section>
  );
};
