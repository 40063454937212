import { ApiKeyCanvas, ApiKeysShowCase, NoApiKeys } from "./Components";
import { useApiKeyCanvas } from "./useApiKeyCanvas";
import { useApiKeyListing } from "./useApiKeyListing";

export const ApiKeysListing = () => {
  const { canvasOpen, openCanvas, closeCanvas } = useApiKeyCanvas();
  const { response, fetch, loading, showCurtesyPage } = useApiKeyListing();

  if (loading || (response && !showCurtesyPage)) {
    return (
      <>
        <ApiKeysShowCase
          response={response}
          openCanvas={openCanvas}
          loading={loading}
          refresh={fetch}
        />
        <ApiKeyCanvas
          open={canvasOpen}
          closeCanvas={closeCanvas}
          onSuccess={fetch}
        />
      </>
    );
  }

  if (response && showCurtesyPage) {
    return (
      <>
        <NoApiKeys openCanvas={openCanvas} />
        <ApiKeyCanvas
          open={canvasOpen}
          closeCanvas={closeCanvas}
          onSuccess={fetch}
        />
      </>
    );
  }

  return null;
};
