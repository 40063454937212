import { memo } from "react";

import { MainOutlet } from "@Components";
import "./style.scss";

export const Profile = memo(() => {
  return (
    <div id="profile">
      <MainOutlet />
    </div>
  );
});
