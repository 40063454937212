import { Section } from "@Components";
import { t } from "i18next";
import { memo } from "react";
import type { OrganizationSettings } from "@Interfaces";

export type EnabledDomainsProps = {
  scope: OrganizationSettings["scope"];
};

export const EnabledDomains = memo(({ scope }: EnabledDomainsProps) => (
  <Section title="common.enabled-domains" className="h-100">
    {scope.length ? (
      <>
        {scope[0] === "*" ? (
          t("common.all-domains-enabled")
        ) : (
          <table className="main-table table table-borderless">
            <tbody className="striped">
              {scope.map(s => (
                <tr key={s}>
                  <td>{s}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    ) : (
      <>{t("common.no-domains-enabled")}</>
    )}
  </Section>
));
