import { t } from "i18next";
import {
  type ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Input, Label } from "reactstrap";

import "./style.scss";
import { useIsReader } from "@Hooks";

type SwitchProps = {
  title?: string;
  checked: boolean;
  id: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  showLabel?: boolean;
  disabledForReader?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const Switch = memo(
  ({
    title,
    id,
    name,
    checked,
    disabled,
    className = "",
    showLabel = true,
    disabledForReader = false,
    onChange,
  }: SwitchProps) => {
    const [isChecked, setIsChecked] = useState(checked);
    const isReader = useIsReader();
    const evaluatedDisabled = disabled || (disabledForReader && isReader);

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!evaluatedDisabled && onChange) {
          setIsChecked(!checked);
          onChange(e);
        }
      },
      [checked, evaluatedDisabled, onChange]
    );

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    return (
      <div className={`switch-component ${className}`.trimEnd()}>
        {title && <p className="mb-1">{t(title)}</p>}
        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            id={id}
            name={name}
            checked={isChecked}
            disabled={evaluatedDisabled}
            onChange={handleOnChange}
          />
          {showLabel && (
            <Label className="form-check-label" for={id}>
              {checked ? t("common.enabled") : t("common.disabled")}
            </Label>
          )}
        </div>
      </div>
    );
  }
);
