import { t } from "i18next";
import type { Action, Args } from "../../Table.i";
import type { HeaderItem } from "@Interfaces";

export const getThWidth = (columnDistribution: Action, data?: Args) => {
  switch (columnDistribution) {
    case "auto":
      return "auto";
    case "equals":
      return equalsHandler(data?.columnsLength);
    case "calculate":
      return calculateHandler({ ...data });
  }
};

export const getTextLabel = (
  translateHeaders: boolean | undefined,
  label: HeaderItem
) => {
  const labelText = typeof label === "string" ? label : label.text;
  return translateHeaders ? t(labelText) || labelText : labelText;
};

function equalsHandler(columnsLength: Args["columnsLength"]) {
  _columnLengthCheck(columnsLength);
  columnsLength = columnsLength || 1;
  return `${100 / columnsLength}%`;
}

function calculateHandler({ columnSizes, columnsLength, index }: Args) {
  _columnSizesCheck(columnSizes);
  _columnLengthCheck(columnsLength);
  if (columnSizes?.length !== columnsLength) {
    invalidConfiguration(
      `Headers length (${columnsLength}) diverge from table columns length ${columnSizes?.length}`
    );
    return "";
  }
  _indexCheck(index, columnSizes?.length || 0);
  columnSizes = columnSizes || [];
  index = index || 0;
  return `${columnSizes[index]}%`;
}

// Checks handlers

function _columnLengthCheck(columnsLength: Args["columnsLength"]) {
  if (!columnsLength || columnsLength < 0) {
    invalidArgument("columnsLength", columnsLength);
  }
}
function _columnSizesCheck(columnSizes: Args["columnSizes"]) {
  if (!columnSizes || !columnSizes.length) {
    invalidArgument("columnSizes", columnSizes);
  }
}
function _indexCheck(index: Args["index"], maxExcluded: number) {
  if (index == null || index < 0 || index >= maxExcluded) {
    invalidArgument("index", index);
  }
}

// Error handlers
function invalidArgument(
  invalidField: string,
  value: string | number | number[] | undefined
) {
  const message = `INVALID-ARGUMENT : ${invalidField} [${value}]`;
  throw new Error(message);
}

function invalidConfiguration(message: string) {
  message = `INVALID-CONFIGURATION : ${message}`;
  throw new Error(message);
}
