import { DisplayRowProjectPathExclusion, SettingsContainer } from "@Components";
import { useArchivedProject } from "@Hooks";
import { memo } from "react";
import { getAddButtonProps } from "../Functions";
import { usePathExclusion } from "./Hooks";
import { PathExclusionFormCanvas } from "./PathExclusionFormCanvas";

export const PathExclusion = memo(() => {
  const { pathExclusion, loading, actions, canvas, noData } =
    usePathExclusion();
  const isArchivedProject = useArchivedProject();

  return (
    <div id="path-exclusion">
      <SettingsContainer
        loading={loading.get}
        section="path-exclusion"
        hasError={noData}
      >
        <DisplayRowProjectPathExclusion
          pathExclusion={pathExclusion}
          deleteEntry={actions.deleteEntry}
          buttonProps={
            isArchivedProject
              ? undefined
              : getAddButtonProps({ onClick: canvas.open })
          }
        />
        <PathExclusionFormCanvas
          isOpen={canvas.isOpen}
          close={canvas.close}
          addEntry={actions.addEntry}
        />
      </SettingsContainer>
    </div>
  );
});
