import { MTLSCertificateCard, ProjectSettingsCardContainer } from "@Components";
import type { MTLSCertificate } from "@Services";
import { joinClasses } from "@Utils";
import { memo, useCallback, useMemo, useState } from "react";
import { DisplayRow } from "..";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";

type DisplayRowProjectMTLSCertificatesProps = {
  mtlsCertificates: Array<MTLSCertificate>;
  deleteEntry?: (i: MTLSCertificate) => Promise<boolean>;
} & Pick<MainProps, "buttonProps">;

export const DisplayRowProjectMTLSCertificates = memo(
  ({
    mtlsCertificates,
    deleteEntry,
    buttonProps,
  }: DisplayRowProjectMTLSCertificatesProps) => {
    const [deletingIndex, setDeletingIndex] = useState<number>();

    const getOnDeleteFunction: (i: number) => VoidFunction | undefined =
      useCallback(
        (i: number) => {
          if (!deleteEntry) return undefined;
          return async () => {
            setDeletingIndex(i);
            await deleteEntry(mtlsCertificates[i]);
            setDeletingIndex(undefined);
          };
        },
        [deleteEntry, mtlsCertificates]
      );

    const cardsContainer = useMemo(
      () => (
        <ProjectSettingsCardContainer
          cardType="mtls-certificate"
          emptyLabel="common.no-mtls-certificates"
        >
          {mtlsCertificates.map((c, i) => {
            const handleOnDelete = getOnDeleteFunction(i);
            return (
              <MTLSCertificateCard
                mTLSCertificate={c}
                key={`${i}-${c.name}`}
                onDelete={handleOnDelete}
                loading={deletingIndex === i}
                disabled={deletingIndex !== undefined}
              />
            );
          })}
        </ProjectSettingsCardContainer>
      ),
      [deletingIndex, getOnDeleteFunction, mtlsCertificates]
    );

    return (
      <DisplayRow
        title="common.mtls-certificate"
        buttonProps={buttonProps}
        className={joinClasses(
          "h-100",
          mtlsCertificates.length <= 0 && "no-data"
        )}
      >
        {cardsContainer}
      </DisplayRow>
    );
  }
);
