import {
  InfoBadge,
  type IssuesTableProps,
  MethodBadge,
  NotificationBadge,
} from "@Components";
import type { Method as MethodI, Setter } from "@Interfaces";
import { t } from "i18next";
import { dateTimeFormat, type ListedIssue } from "@Services";
import { limitString } from "@Utils";

export const getConfig = (
  issues: IssuesTableProps["issues"] = [],
  openIssueId: string,
  forProject: boolean,
  onClick: Setter<ListedIssue>
) =>
  issues.map(issue => {
    const items = [
      {
        jsx: (
          <>
            <MethodBadge type={issue.verb as MethodI} />
            <span className="ms-2" title={issue.endpoint}>
              {limitString(issue.endpoint, 53)}
            </span>
          </>
        ),
      },
      {
        jsx: (
          <InfoBadge
            bgColor={`severity-${issue.severity}`}
            children={<small>{t(`common.${issue.severity}`)}</small>}
            type="severity"
          />
        ),
      },
      {
        jsx: <>{dateTimeFormat(issue.createdAt, { trimTime: true })}</>,
      },
      {
        jsx: (
          <>
            {issue.name}
            {issue.isNew && <NotificationBadge display="sup" type="new" />}
          </>
        ),
      },
      {
        jsx: <>{issue.category}</>,
      },
      {
        jsx: (
          <>
            {issue.owaspTopTen ? (
              <InfoBadge children={issue.owaspTopTen} />
            ) : (
              <></>
            )}
          </>
        ),
      },
    ];

    if (!forProject) {
      items.unshift({
        jsx: <>{issue.projectName}</>,
      });
    }

    return {
      props: {
        className: openIssueId === issue.id ? "selected" : "active",
        onClick: () => onClick(issue),
      },
      items,
    };
  });
