import type { PropsWithChildren } from "react";
import React, { memo, useState } from "react";
import type {
  Nullable,
  Organization,
  Organizations,
  Settings,
} from "@Interfaces";
import type { Role } from "@Services";
import {
  API,
  type UserGetOrganizationResponse,
  config,
  type User,
} from "@Services";
import { useManageApiResponse } from "@Hooks";
import moment from "moment-timezone";

type AuthContextType = {
  user: Nullable<User>;
  settings: Nullable<Settings>;
  role: Nullable<Role>;
  organization: Nullable<Organization>;
  organizations: Nullable<Organizations>;
  setUser: (user: User) => void;
  setSettings: (settings: Settings, dbUpdate?: boolean) => void;
  setRole: (role: Role) => void;
  setOrganizations: (organizations: Organizations) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  settings: null,
  role: null,
  organization: null,
  organizations: null,
  setUser: () => true,
  setSettings: () => true,
  setRole: () => true,
  setOrganizations: () => true,
});

export const AuthProvider = memo(({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<Nullable<User>>(null);
  const [settings, setSettings] = useState<Nullable<Settings>>(null);
  const [role, setRole] = useState<Nullable<Role>>(null);
  const [organization, setOrganization] =
    useState<Nullable<Organization>>(null);
  const [organizations, setOrganizations] =
    useState<Nullable<Organizations>>(null);
  const manageResponse = useManageApiResponse();

  const handleSetRole = async (role: Role) => {
    // as a side effect, when we load a role, we load the organization details
    setRole(role);

    if (role) {
      manageResponse<UserGetOrganizationResponse>({
        promise: API.organization().userGetOrganization(role.organizationID),
        errorMessage: "get-role-organization",
        onSuccess: axiosResponse => {
          const response = axiosResponse.data;
          if (response) {
            setOrganization(response);
          }
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        role,
        user,
        settings,
        organization,
        organizations,
        setUser,
        setSettings: (newSettings, dbUpdate) => {
          newSettings.timeZone = newSettings.timeZone || config.defaultTimeZone;
          setSettings(newSettings);
          // on login or settings change, set user timezone in moment instance
          moment.tz.setDefault(newSettings.timeZone);
          if (dbUpdate) {
            manageResponse({
              promise: () =>
                API.userSettings().userUpdateUserSettings({
                  notification:
                    newSettings.notification ?? settings?.notification,
                  timeZone: newSettings.timeZone ?? settings?.timeZone,
                }),
              errorMessage: "update-settings",
            });
          }
        },
        setRole: handleSetRole,
        setOrganizations,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});
