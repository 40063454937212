import moment from "moment-timezone";

type FieldArg = "end-date" | "available-endpoints";
type ValueArg = string | number;

// return true if the provided field should be displayed with red/danger color
// end-date : less then 30 day from expiration
// available-endpoints : less then 25 available
export const hasCriticalValue = (field: FieldArg, value: ValueArg): boolean => {
  switch (field) {
    case "end-date": {
      const endDate = moment(value);
      if (!endDate.isValid) return false;
      return moment().add(30, "days").isAfter(endDate);
    }
    case "available-endpoints": {
      return Number(value) <= 25;
    }
  }
};
