import {
  DisplayRowProjectMTLSCertificates,
  SettingsContainer,
} from "@Components";
import { AddMTLSCertificate } from "./AddMTLSCertificate";
import { useMTLSCertificates } from "./useMTLSCertificates";
import { useArchivedProject } from "@Hooks";
import { useEffect, useMemo } from "react";

export const MTLSCertificates = () => {
  const {
    deleteMTLSCertificate,
    error,
    loading,
    mTLSCertificates,
    canvasOpen,
    setCanvasOpen,
    resetStore,
  } = useMTLSCertificates();

  const archivedProject = useArchivedProject();
  useEffect(() => resetStore, [resetStore]);

  const children = useMemo(() => {
    return archivedProject ? (
      <DisplayRowProjectMTLSCertificates
        mtlsCertificates={mTLSCertificates ?? []}
      />
    ) : (
      <DisplayRowProjectMTLSCertificates
        deleteEntry={deleteMTLSCertificate}
        mtlsCertificates={mTLSCertificates ?? []}
        buttonProps={{
          color: "primary",
          children: "common.add",
          iconClass: "bi bi-plus-square-fill",
          onClick: () => setCanvasOpen(true),
          hiddenForReader: true,
          data: { cy: "show-mtls-certificates-canvas-button" },
        }}
      />
    );
  }, [archivedProject, deleteMTLSCertificate, mTLSCertificates, setCanvasOpen]);

  return (
    <div id="project-mtls-certificates">
      <SettingsContainer
        loading={loading}
        section="injectables"
        hasError={error}
        children={children}
      />
      <AddMTLSCertificate
        canvasOpen={canvasOpen}
        setCanvasOpen={setCanvasOpen}
      />
    </div>
  );
};
