/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const VirtualRole = {
    VirtualRoleSecurityReader: 'api_security_reader',
    VirtualRoleUser: 'api_user',
    VirtualRoleOrganizationAdmin: 'api_organization_admin'
} as const;

export type VirtualRole = typeof VirtualRole[keyof typeof VirtualRole];



