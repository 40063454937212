import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { isFilledString } from "@Services";
import type { MainFormProps } from "./MainForm.i";
import { useAuthenticationSettingFormStore } from "@Stores";

export const useMainForm = (props: MainFormProps) => {
  const {
    authenticationSettings,
    setAuthenticationSettings,
    setSubFormValidity,
    mode,
    loading,
  } = useAuthenticationSettingFormStore();

  const nameDuplicated = useMemo(() => {
    return (
      mode === "insert" &&
      !!authenticationSettings.name &&
      props.invalidNames.includes(authenticationSettings.name)
    );
  }, [authenticationSettings.name, mode, props.invalidNames]);

  const validities = useMemo(
    () => ({
      name: !nameDuplicated && isFilledString(authenticationSettings.name),
      description: isFilledString(authenticationSettings.description),
    }),
    [
      authenticationSettings.description,
      authenticationSettings.name,
      nameDuplicated,
    ]
  );

  useEffect(() => {
    setSubFormValidity("main", Object.values(validities).every(Boolean));
  }, [setSubFormValidity, validities]);

  const handleUpdate = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setAuthenticationSettings(prev => ({
        ...prev,
        [name]: value,
      }));
    },
    [setAuthenticationSettings]
  );

  return {
    handleUpdate,
    authenticationSettings,
    validities,
    nameDuplicated,
    nameEditable: mode === "insert",
    loading,
  };
};
