import type { AdvancedSettings } from "@Services";
import type { ChangeEventHandler } from "react";
import type { Props } from "./AdvancedSettingsForm.i";

export const toInteger = (arg: string | number): number => {
  let result = 0;
  if (arg && typeof arg === "string") {
    result = parseInt(arg, 10);
  } else if (arg && typeof arg === "number") {
    result = arg;
  }
  return result;
};

type Args = {
  key: keyof AdvancedSettings;
  props: Props;
  //
  mult?: number;
};

export const getOnChangeHandler = ({
  key,
  props,
  mult = 1,
}: Args): {
  onChange: ChangeEventHandler<HTMLInputElement>;
} => {
  if (!props.setAdvancedSettings) return { onChange: () => true };
  return {
    onChange: e => {
      let integer = toInteger(e.target.value);
      if (e.target.min) {
        integer = Math.max(toInteger(e.target.min), integer);
      }
      if (e.target.max) {
        integer = Math.min(toInteger(e.target.max), integer);
      }
      props.setAdvancedSettings &&
        props.setAdvancedSettings({
          ...props.advancedSettings,
          [key]: integer * mult,
        });
    },
  };
};
