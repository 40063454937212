import { type TabProps } from "@Components";
import { useState } from "react";

const TABS: Array<TabProps> = [
  {
    label: "common.details",
    iconClass: "bi bi-info-square-fill",
  },
  {
    label: "issue.endpoint-interactions",
    iconClass: "bi bi-diagram-2-fill",
  },
];

export const useIssueDetailsTab = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return {
    tabs: TABS,
    activeTab,
    setActiveTab,
  };
};
