import { memo } from "react";
import { ListItems, Offcanvas, Paginator } from "@Components";
import { Container } from "reactstrap";
import { useApiDefinitions } from "./useApiDefinitions";
import { t } from "i18next";

import "./style.scss";

export const APIDefinitions = memo(() => {
  const {
    config,
    loading,
    longTextCanvas,
    defaultFilters,
    headers,
    updateFilters,
    response,
  } = useApiDefinitions();

  return (
    <Container fluid id="api-definitions">
      <h2 className="mb-3 color-pink fs-6">{t("common.api-definitions")}</h2>
      <ListItems
        headers={headers}
        showSkeleton={loading}
        config={config}
        cardVersion={false}
        noDataMessage="common.no-api-definition-found"
        updateCardItemOrder={[0, 1, 2, 4, 3, 5]}
        clickable
        setSorts={updateFilters}
        defaultSorts={defaultFilters}
      />
      <Paginator setFilters={updateFilters} totalItems={response?.totalItems} />

      <Offcanvas
        isOpen={longTextCanvas.showMoreCanvasProps !== undefined}
        title={`common.${longTextCanvas.showMoreCanvasProps?.[0]}`}
        children={<>{longTextCanvas.showMoreCanvasProps?.[1]}</>}
        toggle={() => {
          longTextCanvas.setShowMoreCanvasProps(undefined);
        }}
      />
    </Container>
  );
});
