/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ListAuditLogsResponse } from '../model';
// @ts-ignore
import { OrganizationAdminGetLiveLogsInOrganizationResponse } from '../model';
// @ts-ignore
import { OrganizationAdminGetLiveLogsInProjectResponse } from '../model';
// @ts-ignore
import { OrganizationAdminGetLiveLogsInScanResponse } from '../model';
/**
 * AuditLogApi - axios parameter creator
 * @export
 */
export const AuditLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists the live logs in an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetLiveLogsInOrganization: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInOrganization', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInOrganization', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/live-logs`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the live logs in a project of an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetLiveLogsInProject: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/live-logs`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the live logs in a scan of an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetLiveLogsInScan: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInScan', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInScan', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('organizationAdminGetLiveLogsInScan', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/live-logs`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the audit logs of the API key resource
         * @param {string} apiKeyId API key ID
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListApikeysAuditLogs: async (apiKeyId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('organizationAdminListApikeysAuditLogs', 'apiKeyId', apiKeyId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminListApikeysAuditLogs', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/apikeys/{api_key_id}/audit-logs`
                .replace(`{${"api_key_id"}}`, encodeURIComponent(String(apiKeyId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the audit logs of the user resource
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListUserAuditLogs: async (userId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminListUserAuditLogs', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminListUserAuditLogs', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/audit-logs`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the audit logs of the requester
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListAuditLogs: async (page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/audit-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the audit logs of the requester, concerning the given organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListAuditLogsInOrganization: async (organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('userListAuditLogsInOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/audit-logs`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApi - functional programming interface
 * @export
 */
export const AuditLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists the live logs in an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetLiveLogsInOrganization(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminGetLiveLogsInOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetLiveLogsInOrganization(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the live logs in a project of an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetLiveLogsInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminGetLiveLogsInProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetLiveLogsInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the live logs in a scan of an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetLiveLogsInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminGetLiveLogsInScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetLiveLogsInScan(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the audit logs of the API key resource
         * @param {string} apiKeyId API key ID
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminListApikeysAuditLogs(apiKeyId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAuditLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminListApikeysAuditLogs(apiKeyId, organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the audit logs of the user resource
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminListUserAuditLogs(userId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAuditLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminListUserAuditLogs(userId, organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the audit logs of the requester
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userListAuditLogs(page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAuditLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userListAuditLogs(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the audit logs of the requester, concerning the given organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userListAuditLogsInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAuditLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userListAuditLogsInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogApi - factory interface
 * @export
 */
export const AuditLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogApiFp(configuration)
    return {
        /**
         * Lists the live logs in an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetLiveLogsInOrganization(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: any): AxiosPromise<OrganizationAdminGetLiveLogsInOrganizationResponse> {
            return localVarFp.organizationAdminGetLiveLogsInOrganization(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the live logs in a project of an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetLiveLogsInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: any): AxiosPromise<OrganizationAdminGetLiveLogsInProjectResponse> {
            return localVarFp.organizationAdminGetLiveLogsInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the live logs in a scan of an organization with advanced settings
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetLiveLogsInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: any): AxiosPromise<OrganizationAdminGetLiveLogsInScanResponse> {
            return localVarFp.organizationAdminGetLiveLogsInScan(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the audit logs of the API key resource
         * @param {string} apiKeyId API key ID
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListApikeysAuditLogs(apiKeyId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<ListAuditLogsResponse> {
            return localVarFp.organizationAdminListApikeysAuditLogs(apiKeyId, organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the audit logs of the user resource
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListUserAuditLogs(userId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<ListAuditLogsResponse> {
            return localVarFp.organizationAdminListUserAuditLogs(userId, organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the audit logs of the requester
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListAuditLogs(page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<ListAuditLogsResponse> {
            return localVarFp.userListAuditLogs(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the audit logs of the requester, concerning the given organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListAuditLogsInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<ListAuditLogsResponse> {
            return localVarFp.userListAuditLogsInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogApi - interface
 * @export
 * @interface AuditLogApi
 */
export interface AuditLogApiInterface {
    /**
     * Lists the live logs in an organization with advanced settings
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    organizationAdminGetLiveLogsInOrganization(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminGetLiveLogsInOrganizationResponse>;

    /**
     * Lists the live logs in a project of an organization with advanced settings
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    organizationAdminGetLiveLogsInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminGetLiveLogsInProjectResponse>;

    /**
     * Lists the live logs in a scan of an organization with advanced settings
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    organizationAdminGetLiveLogsInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminGetLiveLogsInScanResponse>;

    /**
     * Lists the audit logs of the API key resource
     * @param {string} apiKeyId API key ID
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    organizationAdminListApikeysAuditLogs(apiKeyId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ListAuditLogsResponse>;

    /**
     * Lists the audit logs of the user resource
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    organizationAdminListUserAuditLogs(userId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ListAuditLogsResponse>;

    /**
     * Lists the audit logs of the requester
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    userListAuditLogs(page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ListAuditLogsResponse>;

    /**
     * Lists the audit logs of the requester, concerning the given organization
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    userListAuditLogsInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ListAuditLogsResponse>;

}

/**
 * AuditLogApi - object-oriented interface
 * @export
 * @class AuditLogApi
 * @extends {BaseAPI}
 */
export class AuditLogApi extends BaseAPI implements AuditLogApiInterface {
    /**
     * Lists the live logs in an organization with advanced settings
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public organizationAdminGetLiveLogsInOrganization(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).organizationAdminGetLiveLogsInOrganization(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the live logs in a project of an organization with advanced settings
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public organizationAdminGetLiveLogsInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).organizationAdminGetLiveLogsInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the live logs in a scan of an organization with advanced settings
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'live_log_level'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'live_log_level'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public organizationAdminGetLiveLogsInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'live_log_level', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'live_log_level'>, filterOperators?: Array<'EQ' | 'NEQ'>, filterValues?: Array<'panic' | 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).organizationAdminGetLiveLogsInScan(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the audit logs of the API key resource
     * @param {string} apiKeyId API key ID
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public organizationAdminListApikeysAuditLogs(apiKeyId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).organizationAdminListApikeysAuditLogs(apiKeyId, organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the audit logs of the user resource
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public organizationAdminListUserAuditLogs(userId: string, organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).organizationAdminListUserAuditLogs(userId, organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the audit logs of the requester
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public userListAuditLogs(page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).userListAuditLogs(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the audit logs of the requester, concerning the given organization
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'audit_log_path' | 'audit_log_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public userListAuditLogsInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'audit_log_path' | 'audit_log_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'audit_log_method' | 'audit_log_path' | 'audit_log_status' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).userListAuditLogsInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
