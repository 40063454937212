import { memo } from "react";
import { Container } from "reactstrap";

import "./style.scss";
import { useTabs } from "./Hooks/useTabs";
import { Tabs } from "@Components";
import { APIDefinitionList } from "./Components/APIDefinitionList";
import { APIDefinitionGraph } from "./Components/APIDefinitionGraph/APIDefinitionGraph";

export const APIDefinition = memo(() => {
  const { tabs, activeTab, setActiveTab } = useTabs();
  return (
    <Container fluid id="inventory-api-definition">
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        updateUrl={true}
      />
      {activeTab === 0 && <APIDefinitionList />}
      {activeTab === 1 && <APIDefinitionGraph />}
    </Container>
  );
});
