import { MainOutlet } from "@Components";
import React from "react";
import { Container } from "reactstrap";

export const Organization = React.memo(() => {
  return (
    <Container fluid id="organization">
      <MainOutlet />
    </Container>
  );
});
