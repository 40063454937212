import type { Injectable } from "@Services";

const emptyModel: Injectable = {
  location: "header",
  name: "",
  value: "",
  associatedAuthSetting: "",
} as const;

export const getEmptyInjectablesEntry = (
  initial: Partial<Injectable> = {}
): Injectable => {
  return Object.assign({}, emptyModel, { ...initial });
};
