import { API } from "@Services";
import type { AxiosResponse } from "axios";
import { t } from "i18next";

interface VerifyOtpProps {
  otp: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback: (token: string) => void;
  enable2fa?: boolean;
  withAuth?: boolean;
}

export const verifyOtp = async ({
  otp,
  setError,
  setLoading,
  successCallback,
  enable2fa,
  withAuth,
}: VerifyOtpProps) => {
  if (!otp) {
    setError(t("login.otp.insert-valid-otp") as string);
  }

  try {
    setLoading(true);

    let response: AxiosResponse;
    if (enable2fa) {
      if (withAuth) {
        response = await API.user().userConfirmEnable2fa({ otp });
      } else {
        response = await API.login().confirmEnable2fa({ otp });
      }
    } else {
      response = await API.login().verifyOtp({ otp });
    }

    if (response?.status === 200) {
      successCallback(response.data.authenticationToken);
    } else {
      setError(t("login.otp.send-otp-error") as string);
    }
  } catch (error) {
    setError(t("login.otp.send-otp-error") as string);
  }
  setLoading(false);

  return true;
};
