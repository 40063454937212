import { getDaysBetween } from "@Utils";
import moment from "moment-timezone";

export const ARCHIVED_PROJECT_DAYS_DURATION = 30;

export const getRemaingDaysBeforeDelete = (archivedAt: string): number => {
  if (!archivedAt) return Number.MAX_SAFE_INTEGER;

  const deleteAllowedAt = moment(archivedAt)
    .add(ARCHIVED_PROJECT_DAYS_DURATION, "day")
    .startOf("day");

  return getDaysBetween(deleteAllowedAt);
};
