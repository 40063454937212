import { t } from "i18next";

interface Props {
  message: string;
  color: "danger" | "warning";
  innerClassName?: string;
}

export const FormMessage = ({ innerClassName, color, message }: Props) => (
  <div className="mt-2">
    <small className={`text-${color} ${innerClassName}`}>{t(message)}</small>
  </div>
);
