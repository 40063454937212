import { HeaderLoader } from "./HeaderLoader";
import { baseMarginTop, cardHeight, gap, headerHeight } from "./constants";

export const SettingsLoader = () => {
  return (
    <>
      {Array.from(Array(3).keys()).map(row => (
        <LoaderCard row={row} key={row} />
      ))}
    </>
  );
};

const LoaderCard = ({ row }: { row: number }) => {
  const y = row * (headerHeight + 2 * gap + cardHeight);
  return (
    <>
      <HeaderLoader y={y} />
      <rect
        x="0"
        y={y + baseMarginTop}
        rx="3"
        ry="3"
        width="100%"
        height={cardHeight}
      />
    </>
  );
};
