import {
  Button,
  DisplayTable,
  FormInput,
  GridContainer,
  TrashButton,
} from "@Components";
import type { Options } from "@Interfaces";
import { isFilledString, type UserAuthToken } from "@Services";
import { t } from "i18next";
import { memo, useMemo } from "react";
import isEqual from "react-fast-compare";
import { FormGroup } from "reactstrap";
import { schemes } from "../../../../constants";
import { useUserTokensForm } from "../Hooks";

const schemeOptions: Options = schemes.map(scheme => ({
  label: scheme.toUpperCase(),
  value: scheme,
}));

const TokenRow = ({
  token,
  tokenIndex,
}: {
  token: UserAuthToken;
  tokenIndex: number;
}) => {
  const data: UserAuthToken = {
    key: token.key,
    value: token.value,
    location: token.location,
    authenticationSchemeType: token.authenticationSchemeType,
  };
  return Object.entries(data)
    .filter(([key, value]) => isFilledString(value))
    .map(([key, value]) => (
      <tr key={key + value + tokenIndex}>
        <td className="text-end">{t(`common.${key}`)}</td>
        <td>{value}</td>
      </tr>
    ));
};

export const UserTokensForm = memo(() => {
  const {
    authenticationSettings,
    loading,
    formValue,
    locationOptions,
    validities,
    formValid,
    handleSave,
    handleChange,
    handleDelete,
  } = useUserTokensForm();

  const table = useMemo(() => {
    return authenticationSettings.userAuthTokens?.map((token, tokenIndex) => (
      <FormGroup
        className="flex-center"
        data-cy="tokens-list"
        key={`token.${tokenIndex}`}
      >
        <DisplayTable>
          <tbody>
            <TokenRow token={token} tokenIndex={tokenIndex} />
          </tbody>
        </DisplayTable>
        <div className="ps-3">
          <TrashButton
            onClick={() => handleDelete(tokenIndex)}
            disabled={loading}
          />
        </div>
      </FormGroup>
    ));
  }, [authenticationSettings.userAuthTokens, handleDelete, loading]);

  return (
    <>
      <div className="text-end">
        <Button
          color="primary"
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!formValid}
          onClick={handleSave}
          size="sm"
          data={{ cy: "add-token-btn" }}
        />
      </div>
      <FormGroup noMargin>
        <FormGroup>
          <GridContainer columns={3}>
            <FormInput
              type="select"
              label="common.scheme"
              name="authenticationSchemeType"
              id="authentication-setting-schema-type"
              value={formValue.authenticationSchemeType}
              onChange={handleChange}
              options={schemeOptions}
              bsSize="sm"
              invalid={!validities.authenticationSchemeType}
              disabled={loading}
              data-cy="authentication-settings-input-scheme-type"
            />
            <FormInput
              type="select"
              label="common.location"
              name="location"
              id="authentication-setting-token-location"
              value={formValue.location}
              onChange={handleChange}
              options={locationOptions}
              bsSize="sm"
              invalid={!validities.location}
              disabled={loading}
              data-cy="authentication-settings-input-token-location"
            />
            <FormInput
              type="text"
              label="common.key"
              name="key"
              id="authentication-setting-token-output-key"
              value={formValue.key}
              onChange={handleChange}
              bsSize="sm"
              valid={validities.key}
              invalid={!validities.key}
              disabled={loading}
              data-cy="authentication-settings-input-token-output-key"
            />
          </GridContainer>
          <FormInput
            className="mt-3"
            type="textarea"
            label="common.value"
            name="value"
            id="authentication-setting-token-output-value"
            value={formValue.value}
            onChange={handleChange}
            bsSize="sm"
            rows={4}
            valid={validities.value}
            invalid={!validities.value}
            disabled={loading}
            data-cy="authentication-settings-input-token-output-value"
            required
          />
        </FormGroup>
      </FormGroup>
      {table}
    </>
  );
}, isEqual);
