import { KA_SHIFT_Q } from "@Constants";
import { useKeyboardEvent, useUpdateCacheFilters } from "@Hooks";
import { useCallback, useEffect } from "react";
import type { StoreToggableFilter } from "../Store";
import { useHistoryStore } from "../Store";
import { getPreference, setPreference } from "@Services";
import { useLocation } from "react-router-dom";

const toggableFiltersMap: Record<StoreToggableFilter, string> = {
  caller: "scans.history.filters.attempt_caller",
};

export const useDynamicFiltersControl = () => {
  const { visibleFilters, setVisibleFilters } = useHistoryStore();
  const setSearchParams = useUpdateCacheFilters("project.scans.history");
  const { search } = useLocation();

  /**
   * This function update the status for a specific filter inside the page
   * example : handleVisibleFiltersChange("test", false) : will remove the "test" field visibility
   * example : handleVisibleFiltersChange("test", false) : will restore the "test" field visibility
   * NOTE : each toggable field should have it's implementation in the <FilterComponent>
   */
  const handleVisibleFiltersChange = useCallback<typeof setVisibleFilters>(
    (filterName, filterDisplayed) => {
      // update store state
      setVisibleFilters(filterName, filterDisplayed);
      // update view preference in local storage
      const preferenceKey = toggableFiltersMap[filterName];
      setPreference(preferenceKey, filterDisplayed ? "true" : null);
      // remove filter value from local storage
      if (!filterDisplayed) {
        const [urlFilterName] = preferenceKey.split(".").slice(-1);
        setSearchParams({ [urlFilterName]: "" });
      }
    },
    [setSearchParams, setVisibleFilters]
  );

  /**
   * This function evaluate at page render if the toggable fields should be displayed or not
   *  - search URL contains the field name ? Display it
   *  - localStorage is setted to "true" ? Display it
   *  - hide the value
   */
  useEffect(() => {
    if (getPreference(toggableFiltersMap.caller) === "true") {
      handleVisibleFiltersChange("caller", true);
      return;
    }
    if (new URLSearchParams(search).has("attempt_caller")) {
      handleVisibleFiltersChange("caller", true);
      return;
    }
    handleVisibleFiltersChange("caller", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create KEYBOARD ACTION handler...
  const onShiftQPress = useCallback(() => {
    handleVisibleFiltersChange("caller", !visibleFilters.caller);
  }, [handleVisibleFiltersChange, visibleFilters.caller]);
  // ... and apply it
  useKeyboardEvent({
    action: KA_SHIFT_Q,
    onKeyDown: onShiftQPress,
  });
};
