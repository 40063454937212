import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getOrUpdateExtensionSettings } from "src/Mock";
import { type UserGetScanExtensionSettingsResponse } from "@Services";

export const getExtensionSettings = (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { projectId, scanId } = req.params as Record<string, string>;

  const response: UserGetScanExtensionSettingsResponse = {
    extensionSettings: {
      enabledCategories: getOrUpdateExtensionSettings(projectId, scanId),
    },
  };

  return res(ctx.delay(delay), ctx.json(response));
};
