import type { PlainObject } from "@Interfaces";
import type {
  OrganizationAdminCreateAPIKeysRequest,
  OrganizationAdminCreateAPIKeysResponse,
} from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  OrganizationAPIKeys,
  apiKeys,
  delay,
  generateRandomUUID,
} from "src/Mock";

export const apiKeyPost = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as PlainObject<string>;

  if (!organizationId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const body = (await req.json()) as OrganizationAdminCreateAPIKeysRequest;

  const idGenerator = apiKeys.reduce((current, apiKey) => {
    return Math.max(current, Number(apiKey.id));
  }, 0);

  const newApiKeyId = `${idGenerator + 1}`;

  apiKeys.unshift({
    id: newApiKeyId,
    active: true,
    createdAt: moment().toISOString(),
    ...body,
  });

  OrganizationAPIKeys.push({
    organizationId,
    apiKeyId: newApiKeyId,
  });

  const response: OrganizationAdminCreateAPIKeysResponse = {
    apiKey: generateRandomUUID(),
  };

  return res(ctx.delay(delay), ctx.json(response), ctx.status(200));
};
