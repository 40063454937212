import type { ProjectExtras } from "@Interfaces";
import React, { useCallback } from "react";

interface Props {
  children: JSX.Element;
}

const DEFAULT_PROJECT_EXTRA: ProjectExtras = {
  license: null,
  oasFile: null,
  runPolicyValid: true,
} as const;

const makeEmptyOptions: () => ProjectExtras = () => ({
  ...DEFAULT_PROJECT_EXTRA,
});

export const NewProjectExtrasContext = React.createContext({
  projectExtras: makeEmptyOptions(),
  setProjectExtras: (() => undefined) as React.Dispatch<
    React.SetStateAction<ProjectExtras>
  >,
  reset: () => {
    /* do nothing */
  },
});

export const NewProjectExtrasProvider = ({ children }: Props) => {
  const [projectExtras, setProjectExtras] = React.useState(makeEmptyOptions);

  const reset = useCallback(() => {
    const defaults = makeEmptyOptions();
    setProjectExtras({ ...defaults });
  }, []);

  return (
    <NewProjectExtrasContext.Provider
      value={{ projectExtras, setProjectExtras, reset }}
      children={children}
    />
  );
};
