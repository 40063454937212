export type Cache<T> = Record<string, T>;
export type CacheHandler<T> = {
  getData: (id: string) => T | undefined;
  push: (id: string, data: T) => void;
  length: () => number;
};

export function createCache<T>(cacheSize = 9): CacheHandler<T> {
  const cache: Cache<T> = {};

  function getData(id: string): T | undefined {
    return cache[id];
  }

  function push(id: string, data: T) {
    const count = Object.keys(cache).length;
    if (count >= cacheSize) {
      const firstKey = Object.keys(cache)[0];
      delete cache[firstKey];
    }
    cache[id] = data;
  }

  function length() {
    return Object.keys(cache).length;
  }

  return {
    getData,
    push,
    length,
  };
}
