import * as postman2openapi from "postman2openapi";
import type { ParserFunction } from "../../types.i";
import { parseOpenApi, unparsableEntityHandler } from "./functions";
import { getSwaggerClient, stringToBinary } from "@Services";

export const postmanParser: ParserFunction = async inputSpec => {
  const transpilled = postman2openapi.transpile(inputSpec);
  const newTextFile = JSON.stringify(transpilled);
  const { spec, hasExternalReferences } = await getSwaggerClient({
    spec: transpilled,
  });

  if (!spec) {
    unparsableEntityHandler();
    return;
  }

  const parsedData = parseOpenApi(spec);

  return {
    ...parsedData,
    oasUploadData: stringToBinary(newTextFile),
    hasExternalReferences,
  };
};
