import ReactDOMServer from "react-dom/server";
import { t } from "i18next";
import { Vulnerabilities } from "@Components";
import type { ConfigProps } from "../ScansTable.i";
import { displayEndDate, formatDate } from "./getConfigUtils";
import { scanStatusMapper, showScanVulnerabilites } from "@Services";

export const getConfig = ({ scans, forProject, onClick }: ConfigProps) => {
  return scans.map(scan => {
    const items = [
      {
        jsx: (
          <div className="d-flex align-items-center">
            <div className="d-inline-flex align-items-center status-circle me-1">
              <i className={`circle ${scanStatusMapper[scan.status].color}`} />
            </div>
            <span className="status-label">
              {t(scanStatusMapper[scan.status].label)}
            </span>
          </div>
        ),
      },
      {
        props: {
          className: "w-0 ps-2 pe-3",
        },
        jsx: (
          <div className="d-flex align-items-center">
            <div>
              <i
                data-tooltip-id="tooltip"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <span className="color-pink">
                    {scan.triggeredBy === "API"
                      ? t("scans.triggered-by-api")
                      : t("scans.triggered-by-scheduler")}
                  </span>
                )}
                className={
                  scan.triggeredBy === "API" ? "bi-upload" : "bi-calendar-check"
                }
              />
            </div>
          </div>
        ),
      },
      {
        jsx: <>{formatDate(scan.startedAt)}</>,
      },
      {
        jsx: showScanVulnerabilites(scan) ? (
          <Vulnerabilities {...scan.severityCount} />
        ) : (
          <></>
        ),
      },
      {
        jsx: <>{displayEndDate(scan)}</>,
      },
      {
        jsx: <>{scan.id.substring(scan.id.length - 12)}</>,
      },
    ];

    if (!forProject) {
      items.unshift({
        jsx: <>{scan.projectName}</>,
      });
    }

    return {
      props: {
        className: "scan-list-row active",
        onClick: () => onClick(scan),
      },
      items,
    };
  });
};
