import type { ValidityState } from "@Interfaces";
import { isFilledString, type PathExclusionEntry as PEE } from "@Services";
import { useEffect, useMemo, useState } from "react";
import { getAddButtonProps } from "../../Functions";
import type { PathExclusionFormCanvasProps } from "../PathExclusion.i";

const defaultEntry: PEE = {
  path: "",
  methods: [],
};

export const useFormCanvas = ({
  isOpen,
  close,
  addEntry,
}: PathExclusionFormCanvasProps) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [entry, setEntry] = useState<PEE>({ ...defaultEntry });
  const [validities, setValidities] = useState<ValidityState<PEE>>({
    methods: false,
    path: false,
  });

  useEffect(() => {
    const validityObject = {
      methods: entry.methods.length > 0,
      path: isFilledString(entry.path),
    };
    setValidities(validityObject);

    const validityObjectOk = Object.values(validityObject).every(Boolean);
    setDisabled(!validityObjectOk);
  }, [entry]);

  const saveButton = useMemo(() => {
    return getAddButtonProps({
      disabled,
      loading,
      onClick: () => {
        setLoading(true);
        addEntry(entry).then(success => {
          if (success) {
            setEntry({ ...defaultEntry });
            close();
          }
          setLoading(false);
        });
      },
    });
  }, [disabled, loading, addEntry, close, entry]);

  return {
    canvas: {
      isOpen,
      close: () => {
        setEntry({ ...defaultEntry });
        close();
      },
      loading,
    },
    entry,
    setEntry,
    validities,
    saveButton: {
      props: saveButton,
      setDisabled,
    },
  };
};
