import { useOrganizationSettings } from "@Hooks";
import type { OrganizationContextType } from "./OrganizationContext";
import { useEffect, useState } from "react";

type Output = Pick<OrganizationContextType, "advancedSettings">;

export const useAdvancedSettings = (): Output => {
  const { organizationSettings } = useOrganizationSettings();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const newValue = !!organizationSettings?.advancedSettings;
    setEnabled(newValue);
  }, [organizationSettings?.advancedSettings]);

  return {
    advancedSettings: {
      enabled,
    },
  };
};
