import type { OrganizationAdminUpdateUserSettingsRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAuthUserContext, usersSetting } from "src/Mock";

export const settingsPut = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { notification, timeZone } = (await req.json()) as {
    notification: boolean;
    timeZone: string;
  } as OrganizationAdminUpdateUserSettingsRequest;
  const context = getAuthUserContext();
  const user = context.getUser();
  if (!user) {
    return res(ctx.delay(delay), ctx.status(400));
  }
  const settings = usersSetting.find(item => item.userID === user?.id);
  if (settings) {
    settings.notification = notification;
    settings.timeZone = timeZone;
  }
  return res(ctx.delay(delay), ctx.json({}));
};
