/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserGetInventoryItemResponse } from '../model';
// @ts-ignore
import { UserGetParameterGraphResponse } from '../model';
// @ts-ignore
import { UserListInventoryItemsResponse } from '../model';
/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the inventory item with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} inventoryItemId Inventory Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryGet: async (organizationId: string, projectId: string, inventoryItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryGet', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('inventoryGet', 'projectId', projectId)
            // verify required parameter 'inventoryItemId' is not null or undefined
            assertParamExists('inventoryGet', 'inventoryItemId', inventoryItemId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/inventory/{inventory_item_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"inventory_item_id"}}`, encodeURIComponent(String(inventoryItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the parameter graph according to the current user parameter links and latest API definition of the project with the given ID
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryGetParameterGraph: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryGetParameterGraph', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('inventoryGetParameterGraph', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/parameter-graph`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the inventory items in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} apiDefinitionId API Definition ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'inventory_item_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryListInProject: async (organizationId: string, projectId: string, apiDefinitionId: string, page?: number, size?: number, sortBy?: 'inventory_item_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryListInProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('inventoryListInProject', 'projectId', projectId)
            // verify required parameter 'apiDefinitionId' is not null or undefined
            assertParamExists('inventoryListInProject', 'apiDefinitionId', apiDefinitionId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/api-definitions/{api_definition_id}/inventory`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"api_definition_id"}}`, encodeURIComponent(String(apiDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the inventory item with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} inventoryItemId Inventory Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryGet(organizationId: string, projectId: string, inventoryItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetInventoryItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryGet(organizationId, projectId, inventoryItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the parameter graph according to the current user parameter links and latest API definition of the project with the given ID
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryGetParameterGraph(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetParameterGraphResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryGetParameterGraph(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the inventory items in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} apiDefinitionId API Definition ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'inventory_item_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryListInProject(organizationId: string, projectId: string, apiDefinitionId: string, page?: number, size?: number, sortBy?: 'inventory_item_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListInventoryItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryListInProject(organizationId, projectId, apiDefinitionId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryApiFp(configuration)
    return {
        /**
         * Returns the inventory item with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} inventoryItemId Inventory Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryGet(organizationId: string, projectId: string, inventoryItemId: string, options?: any): AxiosPromise<UserGetInventoryItemResponse> {
            return localVarFp.inventoryGet(organizationId, projectId, inventoryItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the parameter graph according to the current user parameter links and latest API definition of the project with the given ID
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryGetParameterGraph(organizationId: string, projectId: string, options?: any): AxiosPromise<UserGetParameterGraphResponse> {
            return localVarFp.inventoryGetParameterGraph(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the inventory items in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} apiDefinitionId API Definition ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'inventory_item_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryListInProject(organizationId: string, projectId: string, apiDefinitionId: string, page?: number, size?: number, sortBy?: 'inventory_item_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListInventoryItemsResponse> {
            return localVarFp.inventoryListInProject(organizationId, projectId, apiDefinitionId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryApi - interface
 * @export
 * @interface InventoryApi
 */
export interface InventoryApiInterface {
    /**
     * Returns the inventory item with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} inventoryItemId Inventory Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    inventoryGet(organizationId: string, projectId: string, inventoryItemId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetInventoryItemResponse>;

    /**
     * Returns the parameter graph according to the current user parameter links and latest API definition of the project with the given ID
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    inventoryGetParameterGraph(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetParameterGraphResponse>;

    /**
     * Returns the inventory items in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} apiDefinitionId API Definition ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'inventory_item_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    inventoryListInProject(organizationId: string, projectId: string, apiDefinitionId: string, page?: number, size?: number, sortBy?: 'inventory_item_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListInventoryItemsResponse>;

}

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI implements InventoryApiInterface {
    /**
     * Returns the inventory item with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} inventoryItemId Inventory Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryGet(organizationId: string, projectId: string, inventoryItemId: string, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryGet(organizationId, projectId, inventoryItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the parameter graph according to the current user parameter links and latest API definition of the project with the given ID
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryGetParameterGraph(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryGetParameterGraph(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the inventory items in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} apiDefinitionId API Definition ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'inventory_item_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public inventoryListInProject(organizationId: string, projectId: string, apiDefinitionId: string, page?: number, size?: number, sortBy?: 'inventory_item_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'inventory_item_verb' | 'inventory_item_path' | 'inventory_item_category' | 'inventory_item_authentication_expected'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return InventoryApiFp(this.configuration).inventoryListInProject(organizationId, projectId, apiDefinitionId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
