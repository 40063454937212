import { FormGroup, Input, Label } from "reactstrap";
import { Button, DisplayTable, GridContainer, GridItem } from "@Components";
import type { DevModeProps } from "./LoginCard.i";
import { generatedCommitInfo } from "@Services";
import { useState, type ChangeEvent } from "react";
import type { Header } from "@Interfaces";

export const DevMode = ({
  devSettings,
  headers,
  handleDevSettingChange,
  handleHeaderChange,
  handleDevSubmit,
}: DevModeProps) => {
  const [localHeaders, setLocalHeaders] = useState<Header[]>(headers);

  const handleLocalHeaderChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newHeaders = [...localHeaders];
    newHeaders[index] = { ...newHeaders[index], [name]: value };
    setLocalHeaders(newHeaders);
    handleHeaderChange(newHeaders);
  };

  const addHeaderField = () => {
    const newHeaders = [...localHeaders, { key: "", value: "" }];
    setLocalHeaders(newHeaders);
    handleHeaderChange(newHeaders);
  };

  const removeHeaderField = (index: number) => {
    const newHeaders = localHeaders.filter((_, idx) => idx !== index);
    setLocalHeaders(newHeaders);
    handleHeaderChange(newHeaders);
  };

  return (
    <>
      <hr className="my-3"></hr>
      <section className="d-flex align-items-center justify-content-between">
        <h6>Developer Settings</h6>
      </section>

      <FormGroup className="d-flex align-items-center">
        <Label for="enableMock" className="m-0">
          Enable Mock
        </Label>
        <Input
          className="mx-2 my-0"
          type="checkbox"
          id="enableMock"
          name="enableMock"
          checked={devSettings.enableMock === "1"}
          onChange={handleDevSettingChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="backendURI">Backend API URI</Label>
        <Input
          type="text"
          id="backendURI"
          name="backendURI"
          value={devSettings.backendURI}
          onChange={handleDevSettingChange}
          disabled={devSettings.enableMock === "1"}
        />
      </FormGroup>
      <FormGroup>
        <Label>Custom Headers</Label>
        {localHeaders.map((header, index) => (
          <div key={index} className="d-flex align-items-center mb-2">
            <Input
              type="text"
              placeholder="Header Key"
              name="key"
              value={header.key}
              onChange={e => handleLocalHeaderChange(index, e)}
              className="mr-2"
            />
            <Input
              type="text"
              placeholder="Header Value"
              name="value"
              value={header.value}
              onChange={e => handleLocalHeaderChange(index, e)}
              className="mr-2"
            />
            <Button
              color="danger"
              iconClass="bi bi-trash"
              onClick={() => removeHeaderField(index)}
              className="ml-2"
            />
          </div>
        ))}
        <Button
          color="secondary"
          onClick={addHeaderField}
          iconClass="bi bi-plus-square-fill"
        >
          Add Header
        </Button>
      </FormGroup>

      <FormGroup className="my-4">
        <h6>Maintenance Mode</h6>
        <GridContainer columns={2}>
          <GridItem>
            <Label>Start</Label>
            <Input
              type="text"
              placeholder="1725270424000"
              name="maintenanceModeStart"
              value={devSettings.maintenanceModeStart}
              onChange={handleDevSettingChange}
            />
          </GridItem>
          <GridItem>
            <Label>End</Label>
            <Input
              type="text"
              placeholder="1725270424000"
              name="maintenanceModeEnd"
              value={devSettings.maintenanceModeEnd}
              onChange={handleDevSettingChange}
            />
          </GridItem>
          <GridItem>
            <Label>Lock Start</Label>
            <Input
              type="text"
              placeholder="1725270424000"
              name="maintenanceModeLockStart"
              value={devSettings.maintenanceModeLockStart}
              onChange={handleDevSettingChange}
            />
          </GridItem>
          <GridItem>
            <Label>Lock End</Label>
            <Input
              type="text"
              placeholder="1725270424000"
              name="maintenanceModeLockEnd"
              value={devSettings.maintenanceModeLockEnd}
              onChange={handleDevSettingChange}
            />
          </GridItem>
        </GridContainer>
      </FormGroup>
      <Button
        color="primary"
        onClick={handleDevSubmit}
        iconClass="bi bi-floppy"
      >
        Set Dev Settings
      </Button>
      <DisplayTable wrapperClassName="mt-3">
        <tbody>
          <tr>
            <td>Version</td>
            <td>{generatedCommitInfo.gitTag}</td>
          </tr>
          <tr>
            <td>Commit</td>
            <td>{generatedCommitInfo.gitCommitHash}</td>
          </tr>
        </tbody>
      </DisplayTable>
    </>
  );
};
