import type { OrganizationId, ProjectId } from "@Interfaces";
import { API } from "@Services";
import { DASHBOARD_ISSUE_PERIOD } from "../../constants";

export const fetchSummaryAndChart = async (
  organizationId: OrganizationId,
  projectId: ProjectId
) => {
  return Promise.all([
    API.dashboard().dashboardGetSummaryProject(organizationId, projectId),
    API.dashboard().dashboardGetChartProject(
      organizationId,
      projectId,
      ["period"],
      ["EQ"],
      [DASHBOARD_ISSUE_PERIOD]
    ),
  ]);
};
