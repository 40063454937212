import { getPathnameParams } from "../urls";

export const replaceUrlParams = (url: string) => {
  const pathNameParams = getPathnameParams(window.location.href) as {
    [key: string]: string;
  };

  for (const param of Object.keys(pathNameParams)) {
    url = url.replace(`:${param}`, pathNameParams[param]);
  }

  return url;
};
