import {
  AuthWrapper,
  Breadcrumb,
  DashboardProject,
  ProjectIssueListBreadcrumb,
  ProjectBreadcrumb,
  ProjectScanListBreadcrumb,
  OrganizationBreadcrumb,
} from "@Components";
import {
  PROJECTS_PATH,
  PROJECT_DETAILS_API_DEFINITIONS_PATH,
  PROJECT_DETAILS_ISSUES_URL,
  PROJECT_DETAILS_SCANS_URL,
  PROJECT_DETAILS_SETTINGS_URL,
  PROJECT_DETAILS_URL,
  projectLastVisitedPageRegExp,
} from "@Constants";
import {
  ProjectPage,
  ProjectsList,
  ProjectIssues,
  ProjectSettings,
} from "@Pages";
import { t } from "i18next";
import type { MenuHorizontalElementProps } from "src/Components/MenuHorizontal";
import { ProjectApiDefinitionsRoutes } from "./ProjectApiDefinitionsRoutes";
import { ProjectScanRoutes } from "./ProjectScanRoutes";

export const PROJECT_BUTTONS: Array<MenuHorizontalElementProps> = [
  {
    name: "dashboard",
    path: PROJECT_DETAILS_URL,
    type: "button",
    icon: "bi bi-speedometer",
  },
  {
    name: "scans",
    path: PROJECT_DETAILS_SCANS_URL,
    partial: true,
    type: "button",
    recentPageSearch: projectLastVisitedPageRegExp("scans"),
    icon: "bi bi-ui-checks-grid",
  },
  {
    name: "issues",
    path: PROJECT_DETAILS_ISSUES_URL,
    type: "button",
    recentPageSearch: projectLastVisitedPageRegExp("issues"),
    icon: "bi bi-exclamation-square-fill",
  },
  {
    name: "inventory",
    path: PROJECT_DETAILS_API_DEFINITIONS_PATH,
    partial: true,
    type: "button",
    recentPageSearch: projectLastVisitedPageRegExp("api-definition"),
    icon: "bi bi-boxes",
  },
  {
    name: "settings",
    path: PROJECT_DETAILS_SETTINGS_URL,
    type: "button",
    recentPageSearch: projectLastVisitedPageRegExp("settings"),
    icon: "bi bi-gear-wide-connected",
  },
];

export const ProjectRoutes = [
  {
    path: PROJECTS_PATH,
    element: <AuthWrapper element={<ProjectPage />} pageName="Project" />,
    handle: {
      section: "projects",
    },
    children: [
      {
        path: "",
        element: <ProjectsList />,
        handle: {
          crumb: [
            <OrganizationBreadcrumb />,
            <Breadcrumb disabled name={"projects-breadcrumb"}>
              {t("common.projects")}
            </Breadcrumb>,
          ],
        },
      },
      {
        path: ":projectId",
        handle: {
          buttons: PROJECT_BUTTONS,
          crumb: <ProjectBreadcrumb />,
        },
        children: [
          {
            path: "",
            element: <DashboardProject />,
            handle: {
              crumb: (
                <Breadcrumb name={"project-dashboard"} to={PROJECT_DETAILS_URL}>
                  {t("common.dashboard")}
                </Breadcrumb>
              ),
            },
          },
          {
            path: "scans",
            children: ProjectScanRoutes,
            handle: {
              crumb: <ProjectScanListBreadcrumb />,
            },
          },
          {
            path: "issues", // issues
            element: <ProjectIssues />,
            handle: {
              crumb: <ProjectIssueListBreadcrumb disabled />,
            },
          },
          {
            path: "api-definitions",
            children: ProjectApiDefinitionsRoutes,
          },
          {
            path: "settings",
            element: <ProjectSettings />,
            handle: {
              crumb: (
                <Breadcrumb
                  name={"project-settings"}
                  to={PROJECT_DETAILS_SETTINGS_URL}
                >
                  {t("common.settings")}
                </Breadcrumb>
              ),
            },
          },
        ],
      },
    ],
  },
];
