import {
  useCallback,
  useEffect,
  useState,
  type ChangeEvent,
  type Dispatch,
} from "react";
import type { MomentInput } from "moment-timezone";
import moment from "moment-timezone";
import { isMaxEndDate, projectMaxEndDate } from "@Services";
import type { SimpleChangeEvent } from "../UserForm.i";

export const useExpirationDate = (
  expiresAt: string,
  role: string,
  dispatch: Dispatch<SimpleChangeEvent>
) => {
  const [expiresDateEnabled, setExpiresDateEnabled] = useState(
    !isMaxEndDate(expiresAt)
  );
  const [prevExpiresDate, setPrevExpiresDate] = useState(
    isMaxEndDate(expiresAt) ? "" : expiresAt
  );

  const updateExpirationDate = useCallback(
    (expiration: MomentInput) => {
      if (!expiration) return;
      const asMoment = moment(expiration);
      if (!asMoment.isValid()) return;
      const expiresAt = asMoment.format("YYYY-MM-DD");
      dispatch({
        target: {
          value: expiresAt,
          name: "expiresAt",
          checked: false,
        },
      });
      if (!isMaxEndDate(expiresAt)) {
        setPrevExpiresDate(expiresAt);
      }
    },
    [dispatch]
  );

  const getDefaultExpirationDate = useCallback(
    () => prevExpiresDate || moment().add(3, "months"),
    [prevExpiresDate]
  );

  const handleExpiresDateToggle = useCallback(
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      setExpiresDateEnabled(checked);
      if (checked) {
        // expires-at must be provided
        updateExpirationDate(getDefaultExpirationDate());
      } else {
        // set as project_max_end_data
        updateExpirationDate(projectMaxEndDate);
      }
    },
    [getDefaultExpirationDate, updateExpirationDate]
  );

  const isOrganizationAdmin = role === "organization_admin";
  useEffect(() => {
    if (isOrganizationAdmin) {
      // role = organization admin => expiration date = 2099-12-31
      updateExpirationDate(projectMaxEndDate);
    } else if (expiresDateEnabled) {
      // role = organization admin => expiration date > today
      updateExpirationDate(getDefaultExpirationDate());
    }
  }, [
    expiresDateEnabled,
    getDefaultExpirationDate,
    isOrganizationAdmin,
    updateExpirationDate,
  ]);

  return {
    isOrganizationAdmin,
    updateExpirationDate,
    expiresDateEnabled,
    handleExpiresDateToggle,
    prevExpiresDate,
  };
};
