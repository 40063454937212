import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getSingleInventory } from "src/Mock";

export const getSingleInventoryAction = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { params } = req;
  const { status, inventoryItem } = getSingleInventory(
    `${params.organizationId}`,
    `${params.inventoryItemId}`
  );

  return res(
    ctx.delay(delay),
    ctx.status(status),
    ctx.json(inventoryItem ?? null)
  );
};
