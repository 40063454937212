/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactElement } from "react";
import { createContext } from "react";
import { useLicenseContext } from "./useLicenseContext";
import { useAdvancedSettings } from "./useAdvancedSettings";
import type { LicenseName } from "@Interfaces";

export type OrganizationContextType = {
  license: {
    name: LicenseName | undefined;
    loading: boolean;
  };
  advancedSettings: {
    enabled: boolean;
  };
};

export const OrganizationContext = createContext<OrganizationContextType>({
  license: { name: undefined, loading: false },
  advancedSettings: { enabled: false },
});

interface Props {
  children: ReactElement;
}

export const OrganizationProvider = ({ children }: Props) => {
  const value = useOrganizationProvider();
  return <OrganizationContext.Provider children={children} value={value} />;
};

const useOrganizationProvider = (): OrganizationContextType => ({
  ...useLicenseContext(),
  ...useAdvancedSettings(),
});
