import { useArchivedProject } from "@Hooks";
import { getAddButtonProps } from "../Functions";
import { DictionaryFormCanvas } from "./Components";
import useProjectDictionary from "./useProjectDictionary";
import { DisplayRowProjectDictionary, SettingsContainer } from "@Components";

export const Dictionary = () => {
  const { dictionary, loading, actions, canvas, noData } =
    useProjectDictionary();
  const isArchivedProject = useArchivedProject();

  return (
    <div id="project-dictionary">
      <SettingsContainer
        loading={loading.get}
        section="dictionary"
        hasError={noData}
      >
        <DisplayRowProjectDictionary
          dictionary={dictionary}
          deleteEntry={actions.deleteEntry}
          buttonProps={
            isArchivedProject
              ? undefined
              : getAddButtonProps({ onClick: canvas.open })
          }
        />
        <DictionaryFormCanvas
          isOpen={canvas.isOpen}
          close={canvas.close}
          addEntry={actions.addEntry}
        />
      </SettingsContainer>
    </div>
  );
};
