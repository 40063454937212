import { useState, type Dispatch, useCallback } from "react";
import type { MomentInput } from "moment-timezone";
import type { RunPolicy } from "@Services";
import { getDefaultProjectRunPolicy, projectMaxEndDate } from "@Services";
import moment from "moment-timezone";

interface Props {
  runPolicy: RunPolicy;
  setRunPolicy: Dispatch<RunPolicy>;
  minStartDate: MomentInput;
}

export const useDisabledRunPolicy = ({
  runPolicy,
  setRunPolicy,
  minStartDate,
}: Props) => {
  const [previousRunPolicy, setPreviousRunPolicy] = useState<RunPolicy>();

  const disableRunPolicy = useCallback(() => {
    if (!previousRunPolicy && runPolicy.recurrence !== "none") {
      setPreviousRunPolicy(runPolicy);
      setRunPolicy({
        ...runPolicy,
        startDate: projectMaxEndDate,
        endDate: projectMaxEndDate,
        recurrence: "none",
      });
    }
  }, [previousRunPolicy, runPolicy, setRunPolicy]);

  const restoreRunPolicy = useCallback(() => {
    if (previousRunPolicy) {
      setRunPolicy(previousRunPolicy);
      setPreviousRunPolicy(undefined);
    } else {
      const defaultRunPolicy = getDefaultProjectRunPolicy({
        startDate: moment(minStartDate).format("YYYY-MM-DD"),
      });
      setRunPolicy(defaultRunPolicy);
    }
  }, [minStartDate, previousRunPolicy, setRunPolicy]);

  return { disableRunPolicy, restoreRunPolicy };
};
