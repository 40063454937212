import type { Props } from "../Paginator.i";

type Args = Pick<Props, "show" | "totalItems">;

export const showPaginator = ({ show, totalItems }: Args) => {
  let result = false;
  if (show === "default" && totalItems) {
    result = totalItems > 0;
  } else if (typeof show === "boolean") {
    result = show;
  }
  return result;
};
