import { useEffect, useState } from "react";

export const useDebounce = <T>(value: T, wait = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timerId = setTimeout(() => {
      value !== debouncedValue && setDebouncedValue(value);
    }, wait);
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, wait]);

  return debouncedValue;
};
