import { memo } from "react";
import {
  DisplayRowProjectIssueExclusion,
  SettingsContainer,
} from "@Components";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const IssueExclusion = memo(() => {
  const { loading, issueExclusionSettings } = useSettingsStore();
  return (
    <div id="scan-issue-exclusion" className="p-4">
      <SettingsContainer loading={loading} section="issue-exclusion">
        <DisplayRowProjectIssueExclusion
          issueExclusions={issueExclusionSettings.issues ?? []}
        />
      </SettingsContainer>
    </div>
  );
});
