import type { RunPolicy } from "@Services";
import { toRFC3339NanoFormat } from "@Services";
import moment from "moment-timezone";

/**
 *
 * @param runPolicy
 * @returns a new RunPolicy instance whose dates are in format RFC3339Nano
 */
export const runPolicyDateConverter = (runPolicy: RunPolicy): RunPolicy => {
  const { startDate, endDate } = runPolicy;
  const result: RunPolicy = { ...runPolicy };
  if (result.startDate) {
    result.startDate = toRFC3339NanoFormat(startDate);
  }
  if (result.endDate) {
    result.endDate = toRFC3339NanoFormat(endDate);
  }
  return result;
};

/**
 *
 * @param runPolicy
 * @returns a new RunPolicy instance whose dates are in format YYYY-MM-DD
 */
export const runPolicyDateDeconverter = (runPolicy: RunPolicy): RunPolicy => {
  const { startDate, endDate } = runPolicy;
  const result: RunPolicy = { ...runPolicy };
  if (result.startDate) {
    result.startDate = moment(startDate).format("YYYY-MM-DD");
  }
  if (result.endDate) {
    result.endDate = moment(endDate).format("YYYY-MM-DD");
  }
  return result;
};
