import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  projects,
  projectInfos,
  findProjectsByOrganizationId,
} from "src/Mock";
import type { PlainObject } from "@Interfaces";

export const projectDelete = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params as PlainObject<string>;

  if (!organizationId || !projectId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const organizationProjects = findProjectsByOrganizationId(organizationId);

  if (!organizationProjects.some(p => p.id === projectId)) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const projectIndex = projects.findIndex(p => p.id === projectId);
  const projectInfosIndex = projectInfos.findIndex(p => p.id === projectId);

  if (projectIndex < 0 || projectInfosIndex < 0) {
    return res(ctx.delay(delay), ctx.status(500));
  }

  projects.splice(projectIndex, 1);
  projectInfos.splice(projectInfosIndex, 1);

  return res(ctx.delay(delay), ctx.status(200));
};
