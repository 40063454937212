import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { SpaceLink } from "@Services";
import { API, fetchSpaceLinkDetails } from "@Services";
import { useCallback } from "react";
import { useScanDetailsStore } from "../Store/ScanDetailsStore";
import { createCache, isStatusCodeSuccess } from "@Utils";
import { t } from "i18next";
import type { OrganizationId } from "@Interfaces";
import { AxiosError } from "axios";

const spaceLinkNames = createCache<string>();

export const useFetchDetails = () => {
  const { organizationId, projectId, scanId } = useUrlParams();
  const manageResponse = useManageApiResponse();

  const {
    setLoading,
    setScanDetails,
    setNotFound,
    setAssociatedSpaceLinkName,
  } = useScanDetailsStore();

  const fetchFunction = useCallback(async () => {
    if (!organizationId || !projectId || !scanId) return;

    setLoading(true);

    let spaceLinkID = "";

    try {
      const promise = API.scan().scanGet(organizationId, projectId, scanId);

      const { data, status } = await manageResponse({ promise });
      if (!data || !isStatusCodeSuccess(status)) throw new Error("not-found");

      setScanDetails(data);

      spaceLinkID = data.scan.spaceLinkID;
    } catch (e) {
      setNotFound(true);
    }

    const spaceLinkName = await fetchSpaceLink(organizationId, spaceLinkID);
    setAssociatedSpaceLinkName(spaceLinkName);

    setLoading(false);
  }, [
    manageResponse,
    organizationId,
    projectId,
    scanId,
    setAssociatedSpaceLinkName,
    setLoading,
    setNotFound,
    setScanDetails,
  ]);

  return fetchFunction;
};

export const fetchSpaceLink = async (
  organizationId: OrganizationId,
  spaceLinkID: SpaceLink["id"]
) => {
  // default - return empty string
  if (!spaceLinkID) return "";

  try {
    let spaceLinkName = spaceLinkNames.getData(spaceLinkID);

    if (!spaceLinkName) {
      const details = await fetchSpaceLinkDetails(organizationId, spaceLinkID);
      spaceLinkName = details.data.spaceLink.name;
    }

    spaceLinkNames.push(spaceLinkID, spaceLinkName);

    return spaceLinkName;
  } catch (e) {
    let errorName = "-";
    if (e instanceof AxiosError && e.response?.status === 404) {
      errorName = t("errors.deleted-space-link");
    }
    return errorName;
  }
};
