import { type Nullable } from "@Interfaces";
import { replaceUrlParams } from "./replaceUrlParams";

const checkIfUrlContainsType = (url: string, page: string) => {
  return url.endsWith(`#${page}`) || url.split("?")[0].endsWith(`/${page}`);
};

export type LastVisitedPageType =
  | "scans"
  | "issues"
  | "api-definitions"
  | "inventory"
  | "history"
  | "apikeys"
  | "users"
  | null;

export const lastVisitedPage = (
  type: LastVisitedPageType = null
): Nullable<string> => {
  try {
    const pages = sessionStorage.getItem("recentPages");
    const pagesArr = pages ? (JSON.parse(pages) as string[]) : [];

    if (type && pagesArr) {
      return (
        pagesArr.find(pageUrl => checkIfUrlContainsType(pageUrl, type)) ?? null
      );
    }

    return pagesArr[0] ?? null;
  } catch (error) {
    return null;
  }
};

const createModifiedTest = (test: RegExp) => {
  const modifiedPattern = replaceUrlParams(test.source);
  return new RegExp(modifiedPattern, test.flags);
};

const removeTrailingSlash = (url: string) => url.replace(/\/$/, "");

export const lastVisitedPageByRegexp = (test: RegExp) => {
  const pages = sessionStorage.getItem("recentPages");
  if (!pages) return undefined;

  const pagesArr = JSON.parse(pages) as string[];
  const modifiedTest = createModifiedTest(test);

  // Find the result and remove trailing slash if it exists
  const result = pagesArr.find(page => modifiedTest.test(page));
  return result ? removeTrailingSlash(result) : undefined;
};

export const actualVisitedPageByRegexp = (test: RegExp) => {
  const modifiedTest = createModifiedTest(test);

  // Get the current origin dynamically and find the result
  const baseUrl = window.location.origin;
  const url = window.location.href.replace(baseUrl, "");
  const result = modifiedTest.test(url) ? url : null;
  return result ? removeTrailingSlash(result) : undefined;
};
