import { Logo } from "@Assets";
import { useOrganizationId } from "@Hooks";
import { t } from "i18next";
import { memo, useCallback, useMemo, useState } from "react";
import { Link, NavLink, useMatches } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { NavItem, Nav as RSNav } from "reactstrap";
import { type Match } from "../Router/Router.i";
import type { Props } from "./Nav.i";
import { navConfig } from "./items";
import { MaintenanceModeIcon, NavProfileCard } from "./Components";
import { getPreference } from "@Services";
import "./Nav.style.scss";

export { type Props as NavProps };

export const Nav = memo(() => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const projectSearchQuery = getPreference("organization.projects.search");
  const organizationId = useOrganizationId();
  const matches = useMatches() as Match[];
  const section = matches[1].handle?.section; // the first match is always the navigation root

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const navItems = useMemo(
    () =>
      navConfig.map(item => {
        let destination = item.to;

        if (item.prefix === "projects" && projectSearchQuery) {
          destination = `${destination}${projectSearchQuery}`;
        }

        destination = destination.replace(
          ":organizationId",
          organizationId ?? ""
        );

        return (
          <NavItem
            key={`nav-item-${item.to}`}
            className="my-2"
            data-cy={item.label.replace("nav.", "")}
            data-tooltip-id="tooltip-nav"
            data-tooltip-content={t(item.label) || ""}
            data-tooltip-place="right"
          >
            <NavLink
              to={`${destination}`}
              className={section === item.section ? "active-page" : ""}
            >
              <i className={item.icon} />
              <span className="title-mobile">{t(item.label)}</span>
            </NavLink>
          </NavItem>
        );
      }),
    [projectSearchQuery, section, organizationId]
  );

  const logoNavItem = useMemo(
    () => (
      <NavItem className="d-flex justify-content-between align-center logo">
        <Link to={`/`}>
          <img src={Logo} alt="Logo" width={30} />
        </Link>
      </NavItem>
    ),
    []
  );

  return (
    <>
      <div
        className={`mobile-menu-overlay ${isMobileMenuOpen ? "open" : ""}`}
        onClick={toggleMobileMenu}
      />
      <div id="nav" className={`min-vh-100 ${isMobileMenuOpen ? "open" : ""}`}>
        <RSNav className="sidebar flex-nowrap" vertical>
          <div className="nav-items">
            {logoNavItem}
            {navItems}
          </div>
          <div className="nav-items">
            <MaintenanceModeIcon />
            <NavItem
              className="d-flex justify-content-between align-center docs mb-2"
              data-tooltip-id="tooltip-nav"
              data-tooltip-content={t("common.documentation")}
              data-tooltip-place="right"
            >
              <Link to={`https://docs.equixly.com/`} target="_blank">
                <i className="bi bi-book-half color-pink"></i>
              </Link>
            </NavItem>
            <NavProfileCard />
          </div>
        </RSNav>
        <Tooltip id="tooltip-nav" className="tooltip-nav" />
      </div>
    </>
  );
});
