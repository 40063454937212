import type { VulnerabilityType } from "@Interfaces";
import type { SeverityCount } from "../client";
import { severityColors } from "@Constants";

export const getDoughnutChartData = (
  severityCount: SeverityCount | undefined
) => {
  return [
    {
      label: "common.high",
      value: severityCount?.high || 0,
      color: severityColors["high"],
      severity: "high" as VulnerabilityType,
    },
    {
      label: "common.medium",
      value: severityCount?.medium || 0,
      color: severityColors["medium"],
      severity: "medium" as VulnerabilityType,
    },
    {
      label: "common.low",
      value: severityCount?.low || 0,
      color: severityColors["low"],
      severity: "low" as VulnerabilityType,
    },
    {
      label: "common.information",
      value: severityCount?.information || 0,
      color: severityColors["information"],
      severity: "low" as VulnerabilityType,
    },
  ];
};
