import { memo, useCallback, useMemo, useState } from "react";
import type { StopScanCanvasProps } from "./StopScanAction.i";
import type { ButtonProps } from "@Components";
import { Offcanvas } from "@Components";
import { Alert, Input } from "reactstrap";
import { t } from "i18next";
import { useProject } from "@Hooks";

export const StopScanCanvas = memo(
  ({ isOpen, stopScan, close }: StopScanCanvasProps) => {
    const [projectNameInput, setProjectNameInput] = useState("");
    const [loading, setLoading] = useState(false);
    const { project } = useProject();

    const saveHandler = useCallback(async () => {
      setLoading(true);
      stopScan().then(stopped => {
        if (stopped) {
          // do nothing. user will be redirect to listing
        } else {
          setLoading(false);
        }
      });
    }, [stopScan]);

    const confirmButton: ButtonProps = useMemo(() => {
      return {
        color: "primary",
        children: `common.terminate-scan`,
        disabled: projectNameInput !== project?.name,
        loading: loading,
        iconClass: "bi bi-stop-circle-fill",
        onClick: saveHandler,
        id: `confirm-save-btn`,
      };
    }, [saveHandler, projectNameInput, project?.name, loading]);

    return (
      <Offcanvas
        toggle={close}
        title="common.stop-scan"
        isOpen={isOpen}
        id="stop-scan-canvas"
        buttons={[confirmButton]}
        children={
          <div className="border-radius">
            <Alert
              color="warning"
              children={
                <div className="d-flex align-items-center">
                  <i className="bi-exclamation-triangle" />
                  <span className="ms-3">
                    {t("components.danger-zone.delete-warning")}
                  </span>
                </div>
              }
            />
            <p className="mb-4 canvas-description">
              {t("common.stop-scan-message")}
            </p>
            <p>
              {t("components.danger-zone.delete-input-message", {
                text: project?.name || "",
              })}
            </p>
            <Input
              id="danger-zone-canvas-input"
              disabled={false}
              value={projectNameInput}
              onChange={e => setProjectNameInput(e.target.value)}
              valid={projectNameInput === project?.name}
              invalid={projectNameInput !== project?.name}
              autoCorrect="off"
            />
          </div>
        }
      />
    );
  }
);
