import {
  getClientConfig,
  LoginApi,
  AuditLogApi,
  DashboardApi,
  DocsApi,
  HealthApi,
  LicenseApi,
  OrganizationApi,
  OrganizationSettingsApi,
  ProjectApi,
  RoleApi,
  SubscriptionTypeApi,
  UserApi,
  UserSettingsApi,
  IssueApi,
  ScanApi,
  InventoryApi,
  ApiDefinitionApi,
  ApiKeyApi,
  ScanSettingsApi,
  AttemptApi,
  SpaceLinkApi,
} from "@Services";

export const API = {
  apiDefinitions: () => new ApiDefinitionApi(getClientConfig()),
  apiKeys: () => new ApiKeyApi(getClientConfig()),
  auditLog: () => new AuditLogApi(getClientConfig()),
  attempts: () => new AttemptApi(getClientConfig()),
  dashboard: () => new DashboardApi(getClientConfig()),
  docs: () => new DocsApi(getClientConfig()),
  health: () => new HealthApi(getClientConfig()),
  inventory: () => new InventoryApi(getClientConfig()),
  issue: () => new IssueApi(getClientConfig()),
  license: () => new LicenseApi(getClientConfig()),
  login: () => new LoginApi(getClientConfig()),
  organization: () => new OrganizationApi(getClientConfig()),
  organizationSettings: () => new OrganizationSettingsApi(getClientConfig()),
  project: () => new ProjectApi(getClientConfig()),
  spaceLink: () => new SpaceLinkApi(getClientConfig()),
  role: () => new RoleApi(getClientConfig()),
  scan: () => new ScanApi(getClientConfig()),
  scanSettings: () => new ScanSettingsApi(getClientConfig()),
  subscriptionType: () => new SubscriptionTypeApi(getClientConfig()),
  user: () => new UserApi(getClientConfig()),
  userSettings: () => new UserSettingsApi(getClientConfig()),
};
