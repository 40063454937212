import { createStore } from "@Hooks";
import type { Nullable, Organization, Organizations } from "@Interfaces";
import type { Dispatch } from "react";

type NavProfileStoreProperties = {
  open: boolean;
  loading: boolean;
  organizations: Nullable<Organizations>;
  defaultOrganizations: Nullable<Organizations>;
};

type NavProfileStore = NavProfileStoreProperties & {
  toggle: VoidFunction;
  setOpen: Dispatch<boolean>;
  setLoading: Dispatch<boolean>;
  resetOrganizations: VoidFunction;
  setOrganizations: (
    organizations: Nullable<Organizations>,
    activeOrganization?: Nullable<Organization>
  ) => void;
  reset: VoidFunction;
};

const initialState: NavProfileStoreProperties = {
  open: false,
  loading: false,
  organizations: null,
  defaultOrganizations: null,
};

export const useNavProfileStore = createStore<NavProfileStore>((get, set) => ({
  ...initialState,
  toggle: () => set(state => ({ ...state, open: !state.open })),
  setOpen: open =>
    set(state => {
      if (!open) {
        return { ...state, open, organizations: state.defaultOrganizations }; // reset to default organizations when closing
      }
      return { ...state, open };
    }),
  setLoading: loading => set(state => ({ ...state, loading })),
  resetOrganizations: () =>
    set(state => ({ ...state, organizations: state.defaultOrganizations })),
  setOrganizations: (organizations, activeOrganization) =>
    set(state => {
      const nextOrganizations = organizations ? { ...organizations } : null;
      if (activeOrganization && nextOrganizations) {
        nextOrganizations.data = nextOrganizations.data.filter(
          o => o.id !== activeOrganization.id
        );
      }

      return {
        ...state,
        organizations: nextOrganizations,
        defaultOrganizations: state.defaultOrganizations ?? nextOrganizations,
      };
    }),
  reset: () => set(state => ({ ...state, ...initialState }), true),
}));
