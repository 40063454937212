import type { Props } from "./ExtensionCategoriesTable.i";
import type { Category } from "@Services";
import { getScanExtensionCategories } from "@Services";
import { t } from "i18next";
import { useCallback, useMemo } from "react";
import { TableEntry } from "./TableEntry";
import "./style.scss";
import { useIsReader } from "@Hooks";

const availableCategories = getScanExtensionCategories();

export const ExtensionCategoriesTable = (props: Props) => {
  const isReader = useIsReader();
  const activeCategories = useMemo(
    () => new Set(props.categories ?? []),
    [props.categories]
  );

  const getToggleHandler = useCallback(
    (isSelected: boolean, category: Category) => {
      if (isReader) {
        return undefined;
      }

      if (!props.setCategories || props.disabled) {
        return undefined;
      }
      if (activeCategories.size === 1 && isSelected) {
        return undefined; // at least one should be selected
      }
      return () => {
        if (isSelected) {
          activeCategories.delete(category);
        } else {
          activeCategories.add(category);
        }
        const newActiveCategories = Array.from(activeCategories.values());
        props.setCategories?.(newActiveCategories);
      };
    },
    [activeCategories, props, isReader]
  );

  const categoryCardsJSX = useMemo(() => {
    return Object.entries(availableCategories).map(
      ([translationKey, category]) => {
        const isSelected = activeCategories.has(category);
        return (
          <TableEntry
            key={translationKey}
            selected={isSelected}
            translationKey={translationKey}
            value={category}
            onToggle={getToggleHandler(isSelected, category)}
            disabled={props.disabled}
          />
        );
      }
    );
  }, [activeCategories, getToggleHandler, props.disabled]);

  return (
    <div className="extension-categories-table">
      {!props.hideTitle && (
        <h6 className="title">{t("common.security-checks")}</h6>
      )}
      <div className="category-container">{categoryCardsJSX}</div>
    </div>
  );
};
