import { useMemo, useState } from "react";
import type { Props } from "./DictionaryForm.i";
import type { DictionaryEntry } from "@Services";
import { getEmptyDictionaryEntry, isFilledString } from "@Services";
import type { ValidityState } from "@Interfaces";

export const useDictionaryForm = ({
  addEntry,
  onFormValidityChange,
  onModelUpdate,
  keywords,
  disabled,
}: Props) => {
  const [data, setData] = useState(getEmptyDictionaryEntry);
  const setValue = (property: keyof typeof data) => {
    return (value: string) => {
      if (disabled) return;
      data[property] = value;
      if (onModelUpdate) onModelUpdate(data);
      setData({ ...data });
    };
  };
  const [validities, setValidities] = useState<ValidityState<DictionaryEntry>>({
    key: false,
    value: false,
  });

  const formValid = useMemo(() => {
    const validityState = {
      key: isFilledString(data.key),
      value: data.value !== undefined,
    };
    setValidities({ ...validityState });
    const formValid = Object.values(validityState).every(Boolean);
    if (onFormValidityChange) {
      onFormValidityChange(formValid);
    }
    return formValid;
  }, [data, onFormValidityChange]);

  return {
    // inputs
    key: {
      value: data.key,
      setValue: setValue("key"),
      valid: validities.key,
    },
    value: {
      value: data.value,
      setValue: setValue("value"),
      valid: validities.value,
    },
    // save button
    saveButton: {
      show: typeof addEntry === "function",
      disabled: !formValid,
      onClick: () => {
        if (addEntry) {
          addEntry(data);
          setData(getEmptyDictionaryEntry);
        }
      },
    },
    // combo box
    comboBoxData: useMemo(() => {
      return (keywords ?? []).map(key => ({ label: key, value: key }));
    }, [keywords]),
  };
};
