import { useIsReader, useKeyboardEvent } from "@Hooks";
import { t } from "i18next";
import { Spinner } from "reactstrap";
import type { ButtonProps } from "./Button.i";
import { useButtonCmp } from "./useButtonCmp";
import { useCallback, useRef } from "react";
import ReactDomServer from "react-dom/server";
import { joinClasses } from "@Utils";
import "./Button.style.scss";
export const Button = ({
  className: inputClasses,
  hiddenForReader,
  keyAction,
  tooltip,
  ...props
}: ButtonProps) => {
  const { loading, iconClass, children, data, className, disabled, ...rest } =
    useButtonCmp(props);

  const { onMouseDown } = props;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useKeyboardEvent({
    action: keyAction,
    onKeyDown: () => {
      if (buttonRef.current) {
        const event = new MouseEvent("mousedown", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        buttonRef.current.dispatchEvent(event);
      }
    },
  });

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onMouseDown) {
        return;
      }

      const { button } = event;
      if (button === 0) {
        // left mouse click, avoid mouse down for other mouse buttons
        onMouseDown(event);
      }
    },
    [onMouseDown]
  );

  if (useIsReader() && hiddenForReader) {
    return null;
  }

  const tooltipProps = tooltip
    ? {
        "data-tooltip-id": "tooltip",
        "data-tooltip-place": tooltip.place,
        "data-tooltip-html": ReactDomServer.renderToStaticMarkup(
          <>{tooltip.content}</>
        ),
      }
    : null;

  return (
    <button
      {...data}
      {...rest}
      {...tooltipProps}
      ref={buttonRef}
      onMouseDown={disabled ? undefined : handleMouseDown}
      title={rest.title || (keyAction ? t(keyAction.getTitle()) : undefined)}
      className={`${inputClasses ?? ""} ${className ?? ""}`.trim()}
      disabled={disabled}
    >
      <span
        title={props.title}
        className={joinClasses(
          loading && "invisible",
          "text-nowrap d-flex align-items-center justify-content-center"
        )}
      >
        {!!iconClass && (
          <i className={joinClasses(iconClass, !!children && "me-2")} />
        )}
        {!!children && <span>{t(children)}</span>}
      </span>

      {loading && <Spinner size={"sm"} className="loading-spinner" />}
    </button>
  );
};
