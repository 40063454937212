import type { UserCreateProjectRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { createProject, delay, findProjectsByOrganizationId } from "src/Mock";

export const projectPost = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const requestData = await req.json<UserCreateProjectRequest>();
  const { name, description } = requestData;
  const organizationId = req.params.organizationId;
  const valid = name && description && organizationId;
  let status = 200;

  const listProjects = findProjectsByOrganizationId(organizationId as string);

  const anyListedPendingProject = listProjects.some(
    p => p.status === "pending"
  );

  if (anyListedPendingProject) {
    return res(ctx.delay(delay), ctx.status(423));
  }

  if (valid) {
    try {
      createProject(`${organizationId}`, requestData);
    } catch (e) {
      status = 500;
    }
  } else {
    status = 400;
  }
  return res(ctx.delay(delay), ctx.status(status));
};
