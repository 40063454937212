import type { UserGetParameterGraphResponse } from "@Services";

export const PARAMETER_GRAPHS: UserGetParameterGraphResponse = {
  nodes: [
    {
      inventoryIdemID: "5d913c42-b80a-427d-98d1-a765fc4956ce",
      name: "get_workshop_qr_code",
      path: "/workshop/api/shop/return_qr_code",
      verb: "GET",
    },
    {
      inventoryIdemID: "db0d0173-1089-4353-b853-9a80b932e80e",
      name: "get_workshop_users_all",
      path: "/workshop/api/management/users/all",
      verb: "GET",
    },
    {
      inventoryIdemID: "05e9ffbf-d3ca-4d3e-91a7-d9c7993ba40c",
      name: "forgot_password",
      path: "/identity/api/auth/forget-password",
      verb: "POST",
    },
    {
      inventoryIdemID: "fd257656-a5d5-4672-9369-7d9ba2cdf6bd",
      name: "signup",
      path: "/identity/api/auth/signup",
      verb: "POST",
    },
    {
      inventoryIdemID: "7e0b9188-5e0f-4081-a84e-597f76924dee",
      name: "apply_coupon",
      path: "/workshop/api/shop/apply_coupon",
      verb: "POST",
    },
    {
      inventoryIdemID: "07097f31-f2f1-44b4-b03f-a0f3079be351",
      name: "contact_mechanic",
      path: "/workshop/api/merchant/contact_mechanic",
      verb: "POST",
    },
    {
      inventoryIdemID: "aac2df4c-effb-461c-bafb-92b56c80d53b",
      name: "check_otp_v2",
      path: "/identity/api/auth/v2/check-otp",
      verb: "POST",
    },
    {
      inventoryIdemID: "e2f5ce13-d5e1-414c-926d-b69bbaa8d968",
      name: "create_service_report",
      path: "/workshop/api/mechanic/receive_report",
      verb: "GET",
    },
    {
      inventoryIdemID: "3342cac7-3297-4d0d-afe6-3630b3b0f315",
      name: "upload_profile_video",
      path: "/identity/api/v2/user/videos",
      verb: "POST",
    },
    {
      inventoryIdemID: "595283da-b663-4999-83c3-6cffdc9c0972",
      name: "vehicle_resend_email",
      path: "/identity/api/v2/vehicle/resend_email",
      verb: "POST",
    },
    {
      inventoryIdemID: "4d6b41e2-ca12-4b42-86cb-fdab2fecf64c",
      name: "add_vehicle",
      path: "/identity/api/v2/vehicle/add_vehicle",
      verb: "POST",
    },
    {
      inventoryIdemID: "8a4a58d4-8402-428f-acc9-bff1adbbe635",
      name: "login",
      path: "/identity/api/auth/login",
      verb: "POST",
    },
    {
      inventoryIdemID: "c820f3c2-afca-43a0-bf3e-441e0b1b7e44",
      name: "login_with_token_v2_7",
      path: "/identity/api/auth/v2.7/user/login-with-token",
      verb: "POST",
    },
    {
      inventoryIdemID: "30fab892-5384-48eb-9049-3ca448af39da",
      name: "get_service_requests_for_mechanic",
      path: "/workshop/api/mechanic/service_requests",
      verb: "GET",
    },
    {
      inventoryIdemID: "421fd979-46f3-471f-8349-dd748616a61f",
      name: "reset_password",
      path: "/identity/api/v2/user/reset-password",
      verb: "POST",
    },
    {
      inventoryIdemID: "d3448a43-2841-4e9a-b108-876978ba4cff",
      name: "get_mechanics",
      path: "/workshop/api/mechanic/",
      verb: "GET",
    },
    {
      inventoryIdemID: "75f3b150-d336-4a2a-a40e-93b2b257c22b",
      name: "verify_email_token",
      path: "/identity/api/v2/user/verify-email-token",
      verb: "POST",
    },
    {
      inventoryIdemID: "3e4ade3d-dc0b-4248-9469-6c38f6295b1c",
      name: "add_new_coupon",
      path: "/community/api/v2/coupon/new-coupon",
      verb: "POST",
    },
    {
      inventoryIdemID: "0b1e3d3d-346d-4a8c-9f66-6d6628dd77f8",
      name: "update_profile_pic",
      path: "/identity/api/v2/user/pictures",
      verb: "POST",
    },
    {
      inventoryIdemID: "03cf4d30-6b1c-4576-9dd9-11931a12ae39",
      name: "convert_profile_video",
      path: "/identity/api/v2/user/videos/convert_video",
      verb: "GET",
    },
    {
      inventoryIdemID: "0904df3c-19a4-468a-9a61-695d5d9d23c8",
      name: "get_recent_posts",
      path: "/community/api/v2/community/posts/recent",
      verb: "GET",
    },
    {
      inventoryIdemID: "daff0b66-0220-4142-80ec-9c82693d34be",
      name: "return_order",
      path: "/workshop/api/shop/orders/return_order",
      verb: "POST",
    },
    {
      inventoryIdemID: "549b60c9-d40e-4c60-9441-8d7730a9434a",
      name: "validate_coupon",
      path: "/community/api/v2/coupon/validate-coupon",
      verb: "POST",
    },
    {
      inventoryIdemID: "e3beffc6-b13e-45ea-b658-886e920251d0",
      name: "mechanic_signup",
      path: "/workshop/api/mechanic/signup",
      verb: "POST",
    },
    {
      inventoryIdemID: "41423e99-2c37-4749-8005-a27b7fe8016e",
      name: "change_email",
      path: "/identity/api/v2/user/change-email",
      verb: "POST",
    },
    {
      inventoryIdemID: "9d65a284-6bc1-405b-9412-88f0177e46ff",
      name: "create_order",
      path: "/workshop/api/shop/orders",
      verb: "POST",
    },
    {
      inventoryIdemID: "86dffb6d-37e5-4d99-97c0-2086a048aba6",
      name: "create_post",
      path: "/community/api/v2/community/posts",
      verb: "POST",
    },
    {
      inventoryIdemID: "03ea1761-a616-479c-bd84-9643c5e9061d",
      name: "get_post",
      path: "/community/api/v2/community/posts/{postId}",
      verb: "GET",
    },
    {
      inventoryIdemID: "d717e002-3359-43cd-a2a4-297e380604a3",
      name: "get_dashboard",
      path: "/identity/api/v2/user/dashboard",
      verb: "GET",
    },
    {
      inventoryIdemID: "e5934fa6-d4ee-4bf3-bf4e-325fa6d78187",
      name: "post_comment",
      path: "/community/api/v2/community/posts/{postId}/comment",
      verb: "POST",
    },
    {
      inventoryIdemID: "22356c14-70cc-4357-a8d9-0423b68f6607",
      name: "get_report_byID",
      path: "/workshop/api/mechanic/mechanic_report",
      verb: "GET",
    },
    {
      inventoryIdemID: "adc5e2e3-9c07-4eb5-9b9c-5b410b172e6f",
      name: "get_order_byID",
      path: "/workshop/api/shop/orders/{order_id}",
      verb: "GET",
    },
    {
      inventoryIdemID: "dd790170-6299-4ec3-9466-962e47e25895",
      name: "update_order",
      path: "/workshop/api/shop/orders/{order_id}",
      verb: "PUT",
    },
    {
      inventoryIdemID: "4fb6f631-639d-44b8-97bc-b08210dd4180",
      name: "delete_profile_video",
      path: "/identity/api/v2/user/videos/{video_id}",
      verb: "DELETE",
    },
    {
      inventoryIdemID: "2ab85b36-7e45-4523-8dae-f794e4e23d80",
      name: "get_profile_video",
      path: "/identity/api/v2/user/videos/{video_id}",
      verb: "GET",
    },
    {
      inventoryIdemID: "2f908b2d-e242-40fc-88cc-98a05c932ed5",
      name: "update_profile_video",
      path: "/identity/api/v2/user/videos/{video_id}",
      verb: "PUT",
    },
    {
      inventoryIdemID: "76d9bc53-bd16-4b41-95dd-d81a061188c3",
      name: "admin_delete_profile_video",
      path: "/identity/api/v2/admin/videos/{video_id}",
      verb: "DELETE",
    },
    {
      inventoryIdemID: "13641378-6727-4b22-8394-7eff6ed95766",
      name: "get_products",
      path: "/workshop/api/shop/products",
      verb: "GET",
    },
    {
      inventoryIdemID: "a67078e2-f8fa-4689-bae8-0d25c71b40f0",
      name: "add_new_product",
      path: "/workshop/api/shop/products",
      verb: "POST",
    },
    {
      inventoryIdemID: "effe78c3-e702-4979-9b89-ae8cda60a2ca",
      name: "check_otp_v3",
      path: "/identity/api/auth/v3/check-otp",
      verb: "POST",
    },
    {
      inventoryIdemID: "80743c7b-1072-48a6-a15f-34e344d81863",
      name: "login_with_token",
      path: "/identity/api/auth/v4.0/user/login-with-token",
      verb: "POST",
    },
    {
      inventoryIdemID: "8a138a88-f5cb-4607-9ae4-1e393e0527b7",
      name: "get_vehicles",
      path: "/identity/api/v2/vehicle/vehicles",
      verb: "GET",
    },
    {
      inventoryIdemID: "0fc5febd-3092-4139-8eb9-c9312ad3ced9",
      name: "get_location",
      path: "/identity/api/v2/vehicle/{vehicleId}/location",
      verb: "GET",
    },
    {
      inventoryIdemID: "174df7cb-230b-409e-bb00-e075a4203d39",
      name: "get_orders",
      path: "/workshop/api/shop/orders/all",
      verb: "GET",
    },
  ],
  edges: [
    {
      producingInventoryItemName: "get_products",
      inputName: "price",
      consumingInventoryItemName: "add_new_product",
      outputName: "price",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_recent_posts",
      inputName: "vehicleId",
      consumingInventoryItemName: "get_location",
      outputName: "vehicleid",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_mechanics",
      inputName: "old_email",
      consumingInventoryItemName: "change_email",
      outputName: "email",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_mechanics",
      inputName: "email",
      consumingInventoryItemName: "reset_password",
      outputName: "email",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_order_byID",
      inputName: "amount",
      consumingInventoryItemName: "apply_coupon",
      outputName: "amount",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_report_byID",
      inputName: "vin",
      consumingInventoryItemName: "create_service_report",
      outputName: "vin",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_mechanics",
      inputName: "mechanic_code",
      consumingInventoryItemName: "create_service_report",
      outputName: "mechanic_code",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_mechanics",
      inputName: "order_id",
      consumingInventoryItemName: "get_order_byID",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_products",
      inputName: "product_id",
      consumingInventoryItemName: "create_order",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "add_new_product",
      inputName: "order_id",
      consumingInventoryItemName: "return_order",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_report_byID",
      inputName: "id",
      consumingInventoryItemName: "update_profile_video",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_recent_posts",
      inputName: "conversion_params",
      consumingInventoryItemName: "update_profile_video",
      outputName: "title",
      origin: "engine",
    },
    {
      producingInventoryItemName: "create_post",
      inputName: "postId",
      consumingInventoryItemName: "post_comment",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_vehicles",
      inputName: "vin",
      consumingInventoryItemName: "contact_mechanic",
      outputName: "vin",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_order_byID",
      inputName: "report_id",
      consumingInventoryItemName: "get_report_byID",
      outputName: "order_id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "post_comment",
      inputName: "vehicleId",
      consumingInventoryItemName: "get_location",
      outputName: "vehicleid",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_workshop_users_all",
      inputName: "old_email",
      consumingInventoryItemName: "change_email",
      outputName: "email",
      origin: "engine",
    },
    {
      producingInventoryItemName: "forgot_password",
      inputName: "new_email",
      consumingInventoryItemName: "change_email",
      outputName: "message",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_report_byID",
      inputName: "conversion_params",
      consumingInventoryItemName: "update_profile_video",
      outputName: "number",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_workshop_users_all",
      inputName: "video_id",
      consumingInventoryItemName: "update_profile_video",
      outputName: "email",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_order_byID",
      inputName: "report_id",
      consumingInventoryItemName: "get_report_byID",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "add_new_product",
      inputName: "id",
      consumingInventoryItemName: "update_profile_video",
      outputName: "id",
      origin: "engine",
    },
    {
      producingInventoryItemName: "get_vehicles",
      inputName: "vin",
      consumingInventoryItemName: "create_service_report",
      outputName: "vin",
      origin: "engine",
    },
  ],
};

export const PARAMETER_GRAPHS_BY_PROJECT: {
  [key: string]: UserGetParameterGraphResponse;
} = {
  "1": PARAMETER_GRAPHS,
};
