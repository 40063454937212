import type { ScanDetail } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  APIDefinitions,
  ScanSpaceLink,
  delay,
  getAndCreateScans,
  getRandomBetweenMinMax,
} from "src/Mock";

export const getScan = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { params } = req;

  const scanId = `${params.scanId}`;
  const organizationId = `${params.organizationId}`;
  const listScans = getAndCreateScans(organizationId);
  const scan = listScans.find(s => s.id === scanId);

  if (!scan) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const randomSettingsCounter = (expected: number) => {
    return Math.random() > 0.15 ? expected : 0;
  };

  const randomApiDefinitionID =
    APIDefinitions[getRandomBetweenMinMax(0, APIDefinitions.length)].id;

  const scanResponse: ScanDetail = {
    scan: {
      apiDefinitionID: randomApiDefinitionID,
      baseURL: "https://example.com/api/v2",
      completedAt: scan.completedAt,
      exploitation: scan.exploitation,
      id: scan.id,
      isProductionURL: Math.random() > 0.5,
      lastError: "",
      severityCount: scan.severityCount,
      startedAt: scan.startedAt,
      status: scan.status,
      triggeredBy: scan.triggeredBy,
      spaceLinkID: ScanSpaceLink.get(scan.id) || "",
    },
    settingsCount: {
      authenticationSettingsCount: randomSettingsCounter(2),
      dictionaryCount: randomSettingsCounter(2),
      pathExclusionsCount: randomSettingsCounter(3),
      issueExclusionsCount: randomSettingsCounter(1),
      userInjectablesCount: randomSettingsCounter(2),
      extensionSettingsEnabledCategoriesCount: randomSettingsCounter(4),
      mtlsCertificatesCount: randomSettingsCounter(4),
      parameterLinksCount: randomSettingsCounter(2),
    },
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(scanResponse));
};
