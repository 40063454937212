// --------------------------
// - ORGANIZATIONS

import type { Organization } from "@Interfaces";

// --------------------------
export const organizations: Organization[] = [
  {
    id: "1",
    name: "BlueMoon Solutions",
    address: "Via Monte Napoleone 16 - 37100 Verona VR - Italy",
    email: "master@organization1.com",
    fiscalCode: "123456789",
  },
  {
    id: "2",
    name: "SilverPeak Ventures",
    address: "Via Vittorio Veneto 24 - 36100 Vicenza VI - Italy",
    email: "principal@organization2.com",
    fiscalCode: "987654321",
  },
  {
    id: "3",
    name: "McCullough LLC",
    address: "653 Dina Valley",
    email: "Otto26@organization3.com",
    fiscalCode: "10745490012",
  },
  {
    id: "4",
    name: "Leffler, Stamm and Wisoky",
    address: "160 Wiegand Centers",
    email: "Harrison.Schuster-Will85@organization4.com",
    fiscalCode: "2039485761",
  },
  {
    id: "5",
    name: "Nienow LLC",
    address: "7355 Shawna Loop",
    email: "Virginie.Oberbrunner@organization5.com",
    fiscalCode: "3849203847",
  },
  {
    id: "6",
    name: "Schulist Inc",
    address: "3541 Margarete Hill",
    email: "Eliane14@organization6.com",
    fiscalCode: "1230938495",
  },
  {
    id: "7",
    name: "Barrows - Bauch",
    address: "6354 Bartoletti Well",
    email: "Eloise_Lubowitz@organization7.com",
    fiscalCode: "5720384958",
  },
  {
    id: "8",
    name: "Schulist, McClure and Schmeler",
    address: "4785 Johns Trafficway",
    email: "Carley_Hayes@organization8.com",
    fiscalCode: "8374920348",
  },
  {
    id: "9",
    name: "Brown, Murray and Macejkovic",
    address: "11622 O'Reilly Inlet",
    email: "Maritza94@organization9.com",
    fiscalCode: "5847392034",
  },
  {
    id: "10",
    name: "Boyle, Yundt and Johns",
    address: "8669 Feest Valley",
    email: "Lilliana.Christiansen@organization10.com",
    fiscalCode: "1029384756",
  },
  {
    id: "11",
    name: "Schmeler - Mitchell",
    address: "255 Luciano Rue",
    email: "Krystel.Metz-Heaney@organization11.com",
    fiscalCode: "5647382910",
  },
  {
    id: "12",
    name: "Skiles Inc",
    address: "400 Marty Place",
    email: "Ed18@organization12.com",
    fiscalCode: "8392018475",
  },
  {
    id: "13",
    name: "Swift Innovations",
    address: "12 Meadow Lane - 12020 Troy NY - USA",
    email: "info@organization13.com",
    fiscalCode: "9483756457",
  },
  {
    id: "14",
    name: "Gotham Tech",
    address: "89 King Street - 10014 New York NY - USA",
    email: "support@organization14.com",
    fiscalCode: "2938475601",
  },
  {
    id: "15",
    name: "Horizon Consulting",
    address: "53 Avenue du Maine - 75014 Paris - France",
    email: "contact@organization15.com",
    fiscalCode: "9483720193",
  },
  {
    id: "16",
    name: "Quantum Dynamics",
    address: "1234 Elm Street - 94103 San Francisco CA - USA",
    email: "admin@organization16.com",
    fiscalCode: "1938475620",
  },
  {
    id: "17",
    name: "Vertex Solutions",
    address: "1 Oxford Street - 02138 Cambridge MA - USA",
    email: "hello@organization17.com",
    fiscalCode: "2839475610",
  },
  {
    id: "18",
    name: "Pinnacle Enterprises",
    address: "77 Sunset Boulevard - 90028 Los Angeles CA - USA",
    email: "info@organization18.com",
    fiscalCode: "3749203847",
  },
  {
    id: "19",
    name: "Elevation Group",
    address: "250 Park Avenue - 10003 New York NY - USA",
    email: "inquiry@organization19.com",
    fiscalCode: "1238475903",
  },
  {
    id: "20",
    name: "Apex Holdings",
    address: "99 Bishopsgate - EC2M 3XD London - UK",
    email: "contact@organization20.com",
    fiscalCode: "9384751029",
  },
  {
    id: "21",
    name: "Summit Innovations",
    address: "200 Queens Road - 3004 Melbourne VIC - Australia",
    email: "support@organization21.com",
    fiscalCode: "8475029384",
  },
  {
    id: "22",
    name: "Zenith Technologies",
    address: "5000 Legacy Drive - 75024 Plano TX - USA",
    email: "info@organization22.com",
    fiscalCode: "7483920184",
  },
  {
    id: "23",
    name: "Nebula Industries",
    address: "80 Collins Street - 3000 Melbourne VIC - Australia",
    email: "hello@organization23.com",
    fiscalCode: "3749203847",
  },
  {
    id: "24",
    name: "Voyager Solutions",
    address: "500 Park Avenue - 10022 New York NY - USA",
    email: "admin@organization24.com",
    fiscalCode: "7483929102",
  },
  {
    id: "25",
    name: "Odyssey Enterprises",
    address: "10 Downing Street - SW1A 2AA London - UK",
    email: "contact@organization25.com",
    fiscalCode: "1827394850",
  },
  {
    id: "26",
    name: "Cosmos Ventures",
    address: "1600 Amphitheatre Parkway - 94043 Mountain View CA - USA",
    email: "info@organization26.com",
    fiscalCode: "1029384756",
  },
  {
    id: "27",
    name: "Infinity Solutions",
    address: "221B Baker Street - NW1 6XE London - UK",
    email: "support@organization27.com",
    fiscalCode: "3847203847",
  },
  {
    id: "28",
    name: "Everest Enterprises",
    address: "1 Market Street - 94105 San Francisco CA - USA",
    email: "info@organization28.com",
    fiscalCode: "3749203847",
  },
  {
    id: "29",
    name: "Skyline Technologies",
    address: "120 Broadway - 10271 New York NY - USA",
    email: "hello@organization29.com",
    fiscalCode: "7483920184",
  },
  {
    id: "30",
    name: "Pioneer Group",
    address: "350 Fifth Avenue - 10118 New York NY - USA",
    email: "contact@organization30.com",
    fiscalCode: "1847592038",
  },
  {
    id: "31",
    name: "Avalanche Systems",
    address: "500 Fifth Avenue - 10110 New York NY - USA",
    email: "info@organization31.com",
    fiscalCode: "9374850921",
  },
  {
    id: "32",
    name: "Lighthouse Ventures",
    address: "400 Atlantic Avenue - 02210 Boston MA - USA",
    email: "support@organization32.com",
    fiscalCode: "1029384756",
  },
  {
    id: "33",
    name: "Radiant Technologies",
    address: "300 Boylston Street - 02116 Boston MA - USA",
    email: "hello@organization33.com",
    fiscalCode: "8492018475",
  },
  {
    id: "34",
    name: "Peak Performance",
    address: "75 Arlington Street - 02116 Boston MA - USA",
    email: "contact@organization34.com",
    fiscalCode: "8475920183",
  },
  {
    id: "35",
    name: "Beacon Enterprises",
    address: "100 Beacon Street - 02108 Boston MA - USA",
    email: "info@organization35.com",
    fiscalCode: "8374651920",
  },
  {
    id: "36",
    name: "Frontier Solutions",
    address: "200 Clarendon Street - 02116 Boston MA - USA",
    email: "support@organization36.com",
    fiscalCode: "1029384756",
  },
  {
    id: "37",
    name: "Vertex Dynamics",
    address: "50 Milk Street - 02109 Boston MA - USA",
    email: "hello@organization37.com",
    fiscalCode: "5847921038",
  },
  {
    id: "38",
    name: "Orion Systems",
    address: "250 Stuart Street - 02116 Boston MA - USA",
    email: "contact@organization38.com",
    fiscalCode: "3849201837",
  },
  {
    id: "39",
    name: "Atlas Holdings",
    address: "100 Summer Street - 02110 Boston MA - USA",
    email: "info@organization39.com",
    fiscalCode: "8475918374",
  },
  {
    id: "40",
    name: "Nova Innovations",
    address: "200 Berkeley Street - 02116 Boston MA - USA",
    email: "support@organization40.com",
    fiscalCode: "1938475620",
  },
  {
    id: "41",
    name: "Polaris Enterprises",
    address: "225 Franklin Street - 02110 Boston MA - USA",
    email: "hello@organization41.com",
    fiscalCode: "1029384756",
  },
  {
    id: "42",
    name: "Equinox Solutions",
    address: "300 Congress Street - 02210 Boston MA - USA",
    email: "contact@organization42.com",
    fiscalCode: "3849201837",
  },
  {
    id: "43",
    name: "Summit Ventures",
    address: "200 State Street - 02109 Boston MA - USA",
    email: "info@organization43.com",
    fiscalCode: "8475918374",
  },
  {
    id: "44",
    name: "Zenith Dynamics",
    address: "50 Post Office Square - 02109 Boston MA - USA",
    email: "support@organization44.com",
    fiscalCode: "1938475620",
  },
  {
    id: "45",
    name: "Horizon Systems",
    address: "100 Oliver Street - 02110 Boston MA - USA",
    email: "hello@organization45.com",
    fiscalCode: "1029384756",
  },
  {
    id: "46",
    name: "Nimbus Technologies",
    address: "200 High Street - 02110 Boston MA - USA",
    email: "contact@organization46.com",
    fiscalCode: "3849201837",
  },
  {
    id: "47",
    name: "Ascend Enterprises",
    address: "300 A Street - 02210 Boston MA - USA",
    email: "info@organization47.com",
    fiscalCode: "8475918374",
  },
  {
    id: "48",
    name: "Aurora Ventures",
    address: "225 Binney Street - 02142 Cambridge MA - USA",
    email: "support@organization48.com",
    fiscalCode: "1938475620",
  },
  {
    id: "49",
    name: "Titan Solutions",
    address: "100 Technology Square - 02139 Cambridge MA - USA",
    email: "hello@organization49.com",
    fiscalCode: "1029384756",
  },
  {
    id: "50",
    name: "Optimus Innovations",
    address: "150 Broadway - 02142 Cambridge MA - USA",
    email: "contact@organization50.com",
    fiscalCode: "3849201837",
  },
];
