import { Button, Meta, NoAuthPage } from "@Components";
import { HOME_PATH } from "@Constants";
import { usePreference } from "@Hooks";
import { generateUrl } from "@Utils";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

export const NotFound = React.memo(() => {
  const [organizationId] = usePreference("organization-id");

  return (
    <>
      <Meta pageName="Page Not Found" />
      <NoAuthPage>
        <h1 className="text-center">404</h1>
        <div className="flex-center">
          <i className="bi bi-exclamation-square-fill color-severity-medium me-2"></i>
          <h6 className="text-center color-severity-medium m-0">
            {t("errors.404.not-found")}
          </h6>
        </div>
        <div className="mt-4">
          <Link
            to={
              organizationId
                ? generateUrl(HOME_PATH, [organizationId])
                : "/login"
            }
          >
            <Button
              color="primary"
              children="common.back-home"
              className="w-100"
              iconClass="bi bi-arrow-left-square-fill"
            />
          </Link>
        </div>
      </NoAuthPage>
    </>
  );
});
