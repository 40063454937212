import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import type { Inventory, InventoryDetail, Nullable } from "@Interfaces";
import {
  API,
  getDefaultFilters,
  type ListedInventoryItem,
  mapHeaderItemsWithSorts,
} from "@Services";
import { type Dispatch, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useParameterLinksTableConfig } from "./useParameterLinksTableConfig";
import { getHeaders } from "../Components/functions";
import { useOffCanvasAddParameterLinkStore } from "../OffCanvasAddParameterLinkStore";

type Criteria =
  | (
      | "inventory_item_path"
      | "inventory_item_verb"
      | "inventory_item_category"
      | "inventory_item_authentication_expected"
    )[]
  | undefined;

type Operators = ("LIKE" | "EQ" | "NEQ")[] | undefined;
type Values = string[] | undefined;

const getAllCriterias = (
  item: Nullable<InventoryDetail>,
  criteria: Criteria
) => {
  const result = criteria ?? [];

  if (item) {
    result.push("inventory_item_path");
  }

  return result;
};

const getAllOperators = (
  item: Nullable<InventoryDetail>,
  operators: Operators
) => {
  const result = operators ?? [];

  if (item) {
    result.push("NEQ");
  }

  return result;
};

const getAllValues = (item: Nullable<InventoryDetail>, values: Values) => {
  const result = values ?? [];

  if (item) {
    result.push(item.path);
  }

  return result;
};

export const useParameterLinksAPIDefinitionList = (
  onItemSelect: Dispatch<ListedInventoryItem>
) => {
  const { producingInventory } = useOffCanvasAddParameterLinkStore();
  const defaultFilters = getDefaultFilters("apiDefinitions");
  const { search } = useLocation();

  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(
      args => {
        return API.inventory().inventoryListInProject(
          args.organizationId,
          args.projectId,
          args.apiDefinitionId,
          args.page,
          args.pageSize,
          args.sortBy as "inventory_item_path" | undefined,
          args.sortMode,
          /* we need to filter out the actual producing inventory item, so we join user filters with exclusion ones */
          getAllCriterias(producingInventory, args.criteria as Criteria),
          getAllOperators(producingInventory, args.operators as Operators),
          getAllValues(producingInventory, args.values)
        );
      },
      [producingInventory]
    ),
  });

  const updateFilters = useUpdateCacheFilters(
    "project.inventory.api-definition"
  );
  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultFilters,
      items: getHeaders(),
      search,
      updateFunction: updateFilters,
    });
  }, [defaultFilters, search, updateFilters]);

  const config = useParameterLinksTableConfig(
    response ? (response.data as Inventory[]) : [],
    onItemSelect
  );

  return {
    defaultFilters,
    response,
    fetch,
    loading,
    config,
    headers,
    updateFilters,
  };
};
