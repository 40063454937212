import type { MTLSCertificate } from "@Services";
import { MTLSCertificateDisplayTable } from "@Components";
import { memo, useMemo } from "react";
import { joinClasses } from "@Utils";

export const MTLSCertificateTab = memo(
  ({ mtlsCertificates }: { mtlsCertificates: MTLSCertificate[] }) => {
    const children = useMemo(() => {
      return mtlsCertificates.map((c, i) => (
        <div
          className={joinClasses(!!i && "mt-4", "card px-4 py-3")}
          key={`${i}-${c.name}`}
        >
          <MTLSCertificateDisplayTable mtlsCertificate={c} />
        </div>
      ));
    }, [mtlsCertificates]);

    return <>{children}</>;
  }
);
