import type { TabsProps } from "@Components";
import { getPreference, showScanVulnerabilites } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useScanDetailsStore } from "../Store/ScanDetailsStore";
import { SCAN_PREFIX } from "../../constants";

const settingsSectionHashes = [
  "#authentication",
  "#dictionary",
  "#path-exclusion",
  "#issue-exclusion",
  "#injectable-parameters",
  "#advanced-settings",
  "#security-checks",
];

export type ScanDetailsPageTabs =
  | "general"
  | "issues"
  | "auth-matrix"
  | "history"
  | "settings"
  | "inventory";

const scanDetailsPageTabsIndexes: Record<ScanDetailsPageTabs, number> = {
  general: 0,
  issues: 1,
  "auth-matrix": 2,
  history: 3,
  inventory: 4,
  settings: 5,
} as const;

export const useTabs = () => {
  const { issueCount, details } = useScanDetailsStore();
  const [activeTab, setActiveTab] = useState<ScanDetailsPageTabs>("general");

  const navigate = useNavigate();
  const updateUrlSearch = useCallback(
    (tabname: ScanDetailsPageTabs) => {
      const opt = { replace: true };

      switch (tabname) {
        case "auth-matrix":
        case "history":
        case "issues":
        case "inventory":
          const pref = getPreference(`${SCAN_PREFIX}.${tabname}.search`);
          navigate({ search: pref || undefined }, opt);
          break;
        default:
          navigate({ search: undefined }, opt);
          break;
      }
    },
    [navigate]
  );

  const tabs = useMemo<TabsProps["tabs"]>(() => {
    const canShowIssueCount = showScanVulnerabilites(details?.scan);
    return [
      {
        label: "common.general",
        onClick: () => updateUrlSearch("general"),
      },
      {
        label: "common.issues",
        counter: canShowIssueCount ? issueCount : undefined,
        onClick: () => updateUrlSearch("issues"),
      },
      {
        label: "common.authorization-matrix",
        onClick: () => updateUrlSearch("auth-matrix"),
      },
      {
        label: "common.history",
        onClick: () => updateUrlSearch("history"),
      },
      {
        label: "common.inventory",
        onClick: () => updateUrlSearch("inventory"),
      },
      {
        label: "common.settings",
        onClick: () => updateUrlSearch("settings"),
      },
    ];
  }, [details, issueCount, updateUrlSearch]);

  const { hash } = useLocation();

  const getTabIndex = (tab: ScanDetailsPageTabs) => {
    return scanDetailsPageTabsIndexes[tab] ?? 0;
  };

  const updateActiveTab = (index: number) => {
    const keys: ScanDetailsPageTabs[] = Object.keys(
      scanDetailsPageTabsIndexes
    ) as ScanDetailsPageTabs[];

    for (const key of keys) {
      const keyIndex: number = scanDetailsPageTabsIndexes[key];

      if (keyIndex === index) {
        setActiveTab(key);
        return;
      }
    }

    setActiveTab("general");
  };

  useEffect(() => {
    if (settingsSectionHashes.includes(hash)) {
      setActiveTab("settings");
    }
  }, [hash]);

  return {
    activeTab,
    updateActiveTab,
    getTabIndex,
    tabs,
  };
};
