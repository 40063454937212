import { type Edge, MarkerType } from "@xyflow/react";
import { nodesColors } from "../colors";

export const BASE_EDGE: Partial<Edge> = {
  animated: false,
  type: "smoothstep",
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 10,
    height: 10,
    color: nodesColors.edge.marker.normal,
  },
  style: {
    strokeWidth: 2,
  },
};
