import type { InviteOrEditUserModel } from "@Interfaces";
import { projectMaxEndDate } from "@Services";

const model: InviteOrEditUserModel = {
  active: true,
  email: "",
  name: "",
  surname: "",
  role: "security_reader",
  expiresAt: projectMaxEndDate,
};

export const getFormInitialState = () => {
  return Object.assign({}, model);
};
