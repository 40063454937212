import { getDefaultFilterValue } from "@Services";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePreference } from "./usePreference";

export const useUpdateCacheFilters = (propsCacheKey: string) => {
  const cacheKey = propsCacheKey.replace(/.search$/, "") + ".search";
  const [cacheValue, setCacheValue] = usePreference(cacheKey);

  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    // on page refresh, update the cache value with new url
    if (search !== cacheValue) {
      let newSearchParams = new URLSearchParams(search);
      newSearchParams = removeEmptyParamValues(newSearchParams);
      newSearchParams = removeDefaultParamValues(newSearchParams);
      newSearchParams.delete("page");
      setCacheValue(newSearchParams.size ? `?${newSearchParams}` : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFunction: Dispatch<SetStateAction<Record<string, string>>> =
    useCallback(
      update => {
        let searchParams = new URLSearchParams(search);

        const value =
          typeof update === "function"
            ? update(Object.fromEntries(searchParams))
            : update;

        Object.entries(value).forEach(([k, v]) => {
          searchParams.set(k, v);
        });

        searchParams = removeEmptyParamValues(searchParams);
        searchParams = removeDefaultParamValues(searchParams);

        const page = searchParams.get("page");
        searchParams.delete("page");

        // write in cache
        const newCacheValue = searchParams.size ? `?${searchParams}` : "";
        setCacheValue(newCacheValue);
        // write in URL
        if (page && page !== "1") {
          searchParams.set("page", page);
        }
        const newSearchUrl = searchParams.size ? `?${searchParams}` : "";
        navigate(`${pathname}${newSearchUrl}${hash}`);
      },
      [hash, navigate, pathname, search, setCacheValue]
    );

  return updateFunction;
};

function removeEmptyParamValues(entry: URLSearchParams) {
  const result = new URLSearchParams();
  entry.forEach((v, k) => {
    if (v) result.append(k, v);
  });
  return result;
}

function removeDefaultParamValues(entry: URLSearchParams) {
  const result = new URLSearchParams();
  entry.forEach((v, k) => {
    const defaultValue = getDefaultFilterValue(k);
    if (defaultValue !== v) {
      result.append(k, v);
    }
  });
  return result;
}
