/* eslint-disable no-loop-func */
import { useUrlParams } from "@Hooks";
import type { ListedAPIDefinition } from "@Services";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from "./Functions";

export const useTableConfig = (items: ListedAPIDefinition[] = []) => {
  const navigate = useNavigate();
  const { projectId, organizationId } = useUrlParams();
  const [showMoreCanvasProps, setShowMoreCanvasProps] =
    useState<[string, string]>();

  const config = useMemo(() => {
    return organizationId && projectId
      ? getConfig(
          items,
          organizationId,
          projectId,
          navigate,
          setShowMoreCanvasProps
        )
      : [];
  }, [items, navigate, organizationId, projectId]);

  return {
    config,
    longTextCanvas: { showMoreCanvasProps, setShowMoreCanvasProps },
  };
};
