import type { SeverityCount } from "@Services";

export const getTotalIssueFromSeverityCount = (
  severityCount: SeverityCount | undefined | null
) => {
  return Object.values(severityCount || {}).reduce(
    (sum, issueCounter) => sum + issueCounter,
    0
  );
};
