import { projectMaxEndDate, recurrences, type RunPolicy } from "@Services";
import { getRandomBetweenMinMax } from "src/Mock/helpers";
import moment from "moment-timezone";

export const generateRandomRunPolicy = (): RunPolicy => {
  const recurrence = recurrences[getRandomBetweenMinMax(0, recurrences.length)];

  const runPolicy: RunPolicy = {
    businessHours: false,
    endDate: "",
    recurrence,
    startDate: "",
  };

  if (recurrence === "none") {
    return runPolicy;
  }

  let offset = getRandomBetweenMinMax(10, 50);
  runPolicy.startDate = moment().subtract(offset, "days").format("YYYY-MM-DD");

  if (recurrence === "one_shot") {
    runPolicy.endDate = projectMaxEndDate;
    return runPolicy;
  }

  offset = getRandomBetweenMinMax(10, 50);
  runPolicy.endDate = moment().add(offset, "days").format("YYYY-MM-DD");

  return runPolicy;
};
