import { memo } from "react";
import { CurtesyPage } from "../../CurtesyPage";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { Button } from "@Components";
import { generateUrl } from "@Utils";
import { PROJECT_DETAILS_SETTINGS_URL } from "@Constants";
import { pureComponent } from "src/Utils/Components";
import { useUrlParams } from "@Hooks";

export const ErrorProject = memo((props: { projectName: string }) => {
  const { organizationId, projectId } = useUrlParams();
  return (
    <CurtesyPage>
      <h1 className="text-center">
        {t("common.project-x-has-errors", { name: props.projectName })}
      </h1>
      <p>{t("common.no-data-available-for-display")}</p>
      <Link
        to={generateUrl(PROJECT_DETAILS_SETTINGS_URL, [
          organizationId ?? "",
          projectId ?? "",
        ])}
      >
        <Button
          color="primary"
          children={"common.settings"}
          iconClass="bi bi-gear-wide-connected"
        />
      </Link>
    </CurtesyPage>
  );
}, pureComponent);
