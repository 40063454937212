import { type Props } from "./RunPolicyForm.i";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import type { RunPolicy } from "@Services";
import { projectMaxEndDate, recurrences } from "@Services";
import { getValidities } from "./functions";

export const useRunPolicyForm = ({
  runPolicy,
  setRunPolicy,
  startDateOffset = 0,
  setValid = () => undefined,
  disclaimer = "project.run-schedule.disclaimer",
  ...rest
}: Props) => {
  const minStartDate = useMemo(() => {
    const result = moment().add(startDateOffset, "days");
    return result.startOf("day");
  }, [startDateOffset]);

  const [model, setModel] = useState<RunPolicy>(() => ({
    ...runPolicy,
    recurrence: recurrences.find(r => r === runPolicy.recurrence) ?? "none",
    startDate: runPolicy.startDate || minStartDate.format("YYYY-MM-DD"),
  }));

  const updateRunPolicy = useCallback(
    (updatedRunPolicy: RunPolicy) => {
      setModel(updatedRunPolicy);
      setRunPolicy(updatedRunPolicy);
    },
    [setRunPolicy]
  );

  // can be and empty string or a string date (not projectMaxEndDate)
  const [previousEndDate, setPreviousEndDate] = useState("");
  // (true) endDate is provided manually => show filled endDate input
  // (false) policy has no endDate => show disabled endDate input
  // it's initial value depend on runPolicy endDate at render
  const [provideEndDate, setProvideEndDate] = useState(() => {
    const { endDate } = model;
    return Boolean(endDate && endDate !== projectMaxEndDate);
  });

  const { formValid, validities } = useMemo(() => {
    const validities = getValidities(runPolicy, minStartDate);
    const formValid = Object.values(validities).every(Boolean);
    return { validities, formValid };
  }, [minStartDate, runPolicy]);

  // form validation
  useEffect(() => {
    setValid(formValid);
  }, [formValid, setValid]);

  // update previousEndDate on model.endDate change
  useEffect(() => {
    const endDate = model.endDate;
    if (endDate && endDate !== projectMaxEndDate) {
      setPreviousEndDate(endDate);
    }
  }, [model.endDate]);

  // on recurrency change (radio input)
  const toggleEndDateInput = useCallback(
    (displayEndDate: boolean) => {
      const updatedRunPolicy = { ...model };
      if (displayEndDate) {
        if (provideEndDate) {
          updatedRunPolicy.endDate = previousEndDate;
          updateRunPolicy({ ...updatedRunPolicy });
        }
      } else {
        updatedRunPolicy.endDate = projectMaxEndDate;
        updateRunPolicy({ ...updatedRunPolicy });
      }
      return updatedRunPolicy;
    },
    [model, previousEndDate, provideEndDate, updateRunPolicy]
  );

  return {
    ...rest,
    disclaimer,
    //
    runPolicy: model,
    setRunPolicy: updateRunPolicy,
    //
    validities,
    toggleEndDateInput,
    //
    minStartDate,
    //
    provideEndDate,
    setProvideEndDate: useCallback(
      (checked: boolean) => {
        const newEndDate = checked ? previousEndDate : projectMaxEndDate;
        updateRunPolicy({ ...model, endDate: newEndDate });
        setProvideEndDate(checked);
      },
      [previousEndDate, updateRunPolicy, model]
    ),
    //
    datePickersData: useMemo(() => {
      const { startDate: sd, endDate: ed } = model;
      return {
        startDate: sd ? new Date(sd) : null,
        endDate: ed ? new Date(ed) : null,
        minStartDate: minStartDate.toDate(),
      };
    }, [model, minStartDate]),
  };
};
