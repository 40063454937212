import { Button, MethodBadge, Offcanvas, Section } from "@Components";
import { useManageApiResponse, useToast, useUrlParams } from "@Hooks";
import type { InventoryDetail, Method } from "@Interfaces";
import { API, type ListedInventoryItem } from "@Services";
import { BEM, limitString } from "@Utils";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { decodeIntentoryDetailItem } from "../../../functions";
import { ParameterLinksAPIDefinitionList } from "../Components/ParameterLinksAPIDefinitionList";
import { useOffCanvasAddParameterLinkStore } from "../OffCanvasAddParameterLinkStore";

export const ConsumingItemSection = () => {
  const {
    producingInventory,
    consumingInventory,
    setConsumingInventory,
    activeStep,
  } = useOffCanvasAddParameterLinkStore();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const manageResponse = useManageApiResponse(setLoading);
  const { projectId, organizationId } = useUrlParams();

  const [searchPathCanvasOpen, setSearchPathCanvasOpen] =
    useState<boolean>(false);

  const fetchItem = useCallback(
    async (itemId: string) => {
      await manageResponse({
        promise: API.inventory().inventoryGet(
          organizationId ?? "",
          projectId ?? "",
          itemId
        ),
        onSuccess: response => {
          const content = response.data as InventoryDetail;
          const decodedContent = decodeIntentoryDetailItem(content);
          setConsumingInventory(decodedContent);
          setSearchPathCanvasOpen(false);
        },
        onError: () => {
          setConsumingInventory(null);
        },
      });
    },
    [manageResponse, organizationId, projectId, setConsumingInventory]
  );

  const handleItemSelected = useCallback(
    (item: ListedInventoryItem) => {
      if (item.id === producingInventory?.id) {
        toast({
          message: "inventory.cannot-select-consumer-as-producer",
          type: "error",
        });

        return;
      }

      fetchItem(item.id);
    },
    [fetchItem, producingInventory?.id, toast]
  );

  if (activeStep < 2) {
    return null;
  }

  return (
    <>
      <Section
        title="inventory.create-new-parameter-link-step-3"
        data-cy="consuming-inventory-item-section"
        className="mt-4"
      >
        <div className="consuming-inventory-item p-4">
          <div className={BEM("consuming-inventory-item", "value")}>
            {loading}
            {consumingInventory ? (
              <>
                <MethodBadge
                  type={consumingInventory.verb as Method}
                  className="me-2"
                />
                <span title={consumingInventory.path}>
                  {limitString(consumingInventory.path, 50)}
                </span>
              </>
            ) : (
              t("inventory.no-consuming-endpoint-selected")
            )}
          </div>
          <Button
            iconClass="bi bi-search"
            color="primary"
            onClick={() => setSearchPathCanvasOpen(true)}
            data={{ cy: "consuming-inventory-item-search-button" }}
          />
        </div>
      </Section>

      <Offcanvas
        id="consuming-inventory-item-search"
        title={"inventory.search-for-consuming-path"}
        isOpen={searchPathCanvasOpen}
        toggle={setSearchPathCanvasOpen}
        size="md"
      >
        <ParameterLinksAPIDefinitionList onItemSelect={handleItemSelected} />
      </Offcanvas>
    </>
  );
};
