import type { ButtonProps } from "@Components";
import { isFilledString, type Project } from "@Services";

type ButtonPropsNoColorAndIcon = Omit<ButtonProps, "color" | "iconClass">;

const base: ButtonProps = { color: "primary", hiddenForReader: true };

export const getEditButtonProps = (
  buttonsProps: Partial<ButtonPropsNoColorAndIcon> = {}
) => {
  const buttonProps: ButtonProps = {
    ...base,
    iconClass: "pencil",
    children: "common.edit",
    ...buttonsProps,
  };
  return buttonProps;
};

export const getSpaceLinksButtonProps = (
  project: Project,
  buttonsProps: Partial<ButtonPropsNoColorAndIcon> = {}
) => {
  const isAlreadyConnected = isFilledString(project.spaceLinkID);
  const buttonProps: ButtonProps = {
    ...base,
    iconClass: isAlreadyConnected ? "pencil" : "bi bi-link-45deg",
    children: isAlreadyConnected ? "common.edit" : "common.connect",
    ...buttonsProps,
  };
  return buttonProps;
};

export const getSaveButtonProps = (
  buttonsProps: Partial<ButtonPropsNoColorAndIcon> = {}
) => {
  const buttonProps: ButtonProps = {
    ...base,
    iconClass: "floppy",
    children: "common.save",
    ...buttonsProps,
  };
  return buttonProps;
};

export const getAddButtonProps = (
  buttonsProps: Partial<ButtonPropsNoColorAndIcon> = {}
) => {
  const buttonProps: ButtonProps = {
    ...base,
    iconClass: "plus-square-fill",
    children: "common.add",
    ...buttonsProps,
  };
  return buttonProps;
};
