import React from "react";
import { Helmet } from "react-helmet-async";
import type { Props } from "./Meta.i";

export const Meta = ({ pageName }: Props) => {
  return (
    <Helmet>
      <title>{pageName} | A Virtual Hacker to Secure your API.</title>
      <meta
        name="description"
        content="Equixly helps eliminate blind spots testing your running APIs."
      />
      <meta property="og:title" content="Secure APIs" />
      <meta
        property="og:description"
        content="Equixly puts a virtual hacker into the application development lifecycle (CI/CD) to spot business logic security flaws."
      />
      <meta
        property="og:image"
        content="https://equixely.com/logo-equixely-og.png"
      />
      <meta property="og:url" content="https://equixely.com" />
    </Helmet>
  );
};
