import { HOME_SCANS_URL } from "@Constants";
import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API, lastVisitedPage } from "@Services";
import { generateUrl } from "@Utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useActions = () => {
  const { organizationId, projectId, scanId } = useUrlParams();
  const manageResponse = useManageApiResponse();

  const navigate = useNavigate();

  const stopScan = useCallback(async () => {
    let result = false;
    if (organizationId && projectId && scanId) {
      const { status } = await manageResponse({
        promise: API.scan().scanStop(organizationId, projectId, scanId),
        errorMessage: "scan-stop",
        successMessage: "scan-stop",
        onSuccess: () => {
          let to = lastVisitedPage("scans");
          to ||= generateUrl(HOME_SCANS_URL, [organizationId ?? ""]);
          navigate(to);
        },
      });
      result = status === 200;
    }
    return result;
  }, [organizationId, projectId, scanId, manageResponse, navigate]);

  return {
    stopScan,
  };
};
