/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserGetOrganizationResponse } from '../model';
// @ts-ignore
import { UserGetRelatedOrganizationsResponse } from '../model';
/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes all the things associated with the organization, deleting also users that would remain without roles
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminDeleteOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminDeleteOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the organization if the requester is authorized
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('userGetOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the organizations related to the requester
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'organization_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'organization_name'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetOrganizationsRelatedToRequester: async (page?: number, size?: number, sortBy?: 'organization_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'organization_name'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes all the things associated with the organization, deleting also users that would remain without roles
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminDeleteOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminDeleteOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the organization if the requester is authorized
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the organizations related to the requester
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'organization_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'organization_name'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetOrganizationsRelatedToRequester(page?: number, size?: number, sortBy?: 'organization_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'organization_name'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetRelatedOrganizationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetOrganizationsRelatedToRequester(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * Deletes all the things associated with the organization, deleting also users that would remain without roles
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminDeleteOrganization(organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminDeleteOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the organization if the requester is authorized
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetOrganization(organizationId: string, options?: any): AxiosPromise<UserGetOrganizationResponse> {
            return localVarFp.userGetOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the organizations related to the requester
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'organization_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'organization_name'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetOrganizationsRelatedToRequester(page?: number, size?: number, sortBy?: 'organization_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'organization_name'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserGetRelatedOrganizationsResponse> {
            return localVarFp.userGetOrganizationsRelatedToRequester(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - interface
 * @export
 * @interface OrganizationApi
 */
export interface OrganizationApiInterface {
    /**
     * Deletes all the things associated with the organization, deleting also users that would remain without roles
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationAdminDeleteOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the organization if the requester is authorized
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    userGetOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetOrganizationResponse>;

    /**
     * Returns the organizations related to the requester
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'organization_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'organization_name'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    userGetOrganizationsRelatedToRequester(page?: number, size?: number, sortBy?: 'organization_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'organization_name'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserGetRelatedOrganizationsResponse>;

}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI implements OrganizationApiInterface {
    /**
     * Deletes all the things associated with the organization, deleting also users that would remain without roles
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationAdminDeleteOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationAdminDeleteOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the organization if the requester is authorized
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public userGetOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).userGetOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the organizations related to the requester
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'organization_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'organization_name'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public userGetOrganizationsRelatedToRequester(page?: number, size?: number, sortBy?: 'organization_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'organization_name'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).userGetOrganizationsRelatedToRequester(page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
