import type { HeaderItem, HeaderItems } from "@Interfaces";
import type { Dispatch } from "react";

interface Args {
  search: string;
  updateFunction: Dispatch<Record<string, string>>;
  items: HeaderItems;
  defaults: {
    sortBy: string;
    sortMode: string;
  };
}

export const mapHeaderItemsWithSorts = ({
  items,
  search,
  updateFunction,
  defaults,
}: Args) => {
  const searchParams = new URLSearchParams(search);
  const sortBy = searchParams.get("sortBy") || defaults.sortBy;
  const sortMode = searchParams.get("sortMode") || defaults.sortMode;
  const isAcendingSortMode = sortMode === "asc";

  return items.map(header => {
    if (typeof header === "string" || !header.sortByKey) {
      return header;
    }

    const newHeader: HeaderItem = { ...header };
    const { sortByKey } = header;

    // curently using the same filters as header specify
    if (sortBy === sortByKey) {
      newHeader.iconClass = isAcendingSortMode
        ? "caret-up-fill"
        : "caret-down-fill";
      newHeader.onClick = () => {
        updateFunction({
          sortBy: sortByKey,
          sortMode: isAcendingSortMode ? "desc" : "asc",
        });
      };
    } else {
      newHeader.iconClass = "caret-down";
      newHeader.onClick = () => {
        updateFunction({
          sortBy: sortByKey,
          sortMode: "desc",
        });
      };
    }

    return newHeader;
  });
};
