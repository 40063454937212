import type { FC } from "react";
import React, { memo } from "react";
import type { PathInfoProps } from "./PathInfo.i";
import { t } from "i18next";
import { Label } from "reactstrap";
import { ComboBox, Switch } from "@Components";
import ReactDomServer from "react-dom/server";
import { usePathInfo } from "./usePathInfo";

export const PathInfo: FC<PathInfoProps> = memo(props => {
  const {
    baseURL,
    baseUrlOptions,
    isValidUrl,
    enabledDomains,
    domainEnabled,
    handleProjectChange,
    isProductionURL,
  } = usePathInfo(props);

  return (
    <div id="path-info" className="p-4 border-radius">
      <div id="select-base-url">
        <Label for="baseUrl">{t("project.base-url")}</Label>
        <ComboBox
          id="baseUrl"
          bsSize="sm"
          value={baseURL}
          invalid={!isValidUrl}
          data={baseUrlOptions}
          onChange={newBaseUrl => handleProjectChange({ baseURL: newBaseUrl })}
          onBlur={() => handleProjectChange({ baseURL: baseURL.trim() })}
        />
      </div>
      {!!baseURL && !domainEnabled && enabledDomains?.length && (
        <p className="out-of-scope-message">
          <span
            className="color-severity-medium"
            data-tooltip-id="tooltip"
            data-tooltip-html={ReactDomServer.renderToStaticMarkup(
              <>
                <b className="pb-1">{t("common.enabled-domains")}</b>
                {enabledDomains.map(d => (
                  <div key={d}>{d}</div>
                ))}
              </>
            )}
          >
            {t("common.domain-not-enabled")}
            <i className="ms-2 bi bi-info-circle"></i>
          </span>
        </p>
      )}
      <div className="mt-3">
        <Switch
          title="project.production-url"
          id="isProductionUrlSwitch"
          checked={isProductionURL}
          onChange={e => {
            const isProductionURL = e.target.checked;
            handleProjectChange({ isProductionURL });
          }}
        />
      </div>
    </div>
  );
});
