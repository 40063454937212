import { useMemo } from "react";
import type { MostCommonIssuesCardProps } from "./MostCommonIssues.i";
import { t } from "i18next";
import { Table } from "reactstrap";
import { InfoBadge } from "@Components";

export const TableView = ({
  mostCommonIssues,
}: Required<MostCommonIssuesCardProps>) => {
  const cardContent = useMemo(() => {
    return mostCommonIssues.map(({ name, count, severity }, i) => (
      <tr key={`${i}-${name}`}>
        <td>{name}</td>
        <td className="text-center">
          <InfoBadge bgColor={`severity-${severity}`}>
            {t(`common.${severity}`)}
          </InfoBadge>
        </td>
        <td className="text-end">{count}</td>
      </tr>
    ));
  }, [mostCommonIssues]);

  return (
    <Table data-cy="most-common-issue-table">
      <thead>
        <tr>
          <th>{t("common.issues")}</th>
          <th className="text-center">{t("common.severity")}</th>
          <th className="text-end">{t("common.count")}</th>
        </tr>
      </thead>
      <tbody>{cardContent}</tbody>
    </Table>
  );
};
