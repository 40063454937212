// --------------------------
// - issues
// --------------------------

import type { OrganizationId } from "@Interfaces";
import type { ListedIssue } from "@Services";
import { stringToBinary } from "@Services";

export const issues: Map<OrganizationId, ListedIssue[]> = new Map();
export const openIssues: Map<OrganizationId, ListedIssue[]> = new Map();

const mainRequest = `POST /api/user/123 HTTP/1.1
Host: example.com
Content-Type: application/x-www-form-urlencoded
Content-Length: 27

field1=value1&field2=value2`;

const mainResponse = `HTTP/1.1 500 Internal Server Error
Content-Type: application/json

{
  "error": "An error occurred while processing the request."
}`;

export const getRequest = () => {
  return stringToBinary(btoa(mainRequest));
};

export const getResponse = () => {
  return stringToBinary(btoa(mainResponse));
};

export const getRequestWithBug = () => {
  return stringToBinary(
    btoa(
      JSON.stringify({
        response: mainResponse,
        request: mainRequest,
      })
    )
  );
};

export const getSequenceOfRequest = () => {
  return stringToBinary(
    btoa(
      JSON.stringify([
        {
          request: `POST /api/login HTTP/1.1
Host: example.com
Content-Type: application/json
Content-Length: 34

{"username": "user123", "password": "pass456"}`,
          response: `HTTP/1.1 200 OK
Content-Type: application/json

{
    "status": "success",
    "message": "Login successful",
    "token": "423dd3sdasdasdasdasd"
}`,
        },
        {
          request: `GET /api/user/123 HTTP/1.1
Host: example.com
Authorization: Bearer 423dd3sdasdasdasdasd`,
          response: `HTTP/1.1 200 OK
Content-Type: application/json

{
    "id": 123,
    "username": "user123",
    "email": "user123@example.com"
}`,
        },
        {
          request: `PUT /api/user/123/update-settings HTTP/1.1
Host: example.com
Authorization: Bearer 423dd3sdasdasdasdasd
Content-Type: application/json

{
    "id": 123,
    "timezone": "UTC"
}`,
          response: `HTTP/1.1 200 OK
Content-Type: application/json

{
    "status": "1"
}`,
        },
        {
          request: `INVALID /api/logout HTTP/1.1
Host: example.com
Authorization: Bearer 423dd3sdasdasdasdasd`,
          response: `HTTP/1.1 204 No Content`,
        },
      ])
    )
  );
};

export const listIssues = [
  {
    name: "SQL Injection",
    type: "high",
  },
  {
    name: "Cross-Site Scripting (XSS)",
    type: "high",
  },
  {
    name: "Insecure Direct Object References (IDOR)",
    type: "high",
  },
  {
    name: "Unauthorized Access to Data or Functionality",
    type: "medium",
  },
  {
    name: "Cross-Site Request Forgery (CSRF)",
    type: "medium",
  },
  {
    name: "Vulnerable Authentication and Session Management",
    type: "medium",
  },
  {
    name: "Exposure of Sensitive Data",
    type: "low",
  },
  {
    name: "Access Control Vulnerabilities",
    type: "low",
  },
  {
    name: "Input Validation Issues",
    type: "low",
  },
  {
    name: "Security Misconfigurations",
    type: "low",
  },

  {
    name: "Unencrypted communications",
    type: "information",
  },
  {
    name: "Input returned in response (reflected)",
    type: "information",
  },
];

issues.set("1", [
  {
    id: "1000120",
    scanID: "58",
    severity: "high",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["383", "147", "105", "411"],
    endpoint: "/users/{bookID}/notifications",
    category: "Session Management",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API04:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "POST",
    createdAt: "2024-07-13T22:00:00.000Z",
    cvss: 2.83,
    isNew: true,
  },
  {
    id: "1000119",
    scanID: "94",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["195", "269", "253", "101", "425"],
    endpoint: "/comments",
    category: "Authentication",
    description:
      "Missing Cross-Origin Resource Sharing (CORS) headers can lead to security issues when interacting with web resources from different origins.",
    owaspTopTen: "API09:2023",
    name: "Missing CORS Headers",
    verb: "POST",
    createdAt: "2024-07-14T22:00:00.000Z",
    cvss: 1.66,
    isNew: true,
  },
  {
    id: "1000118",
    scanID: "96",
    severity: "information",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["410", "369"],
    endpoint: "/products/history",
    category: "Malicious Input Handling",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    owaspTopTen: "API02:2023",
    name: "Unencrypted communications",
    verb: "PUT",
    createdAt: "2024-07-14T22:00:00.000Z",
    cvss: 4.06,
    isNew: true,
  },
  {
    id: "1000117",
    scanID: "38",
    severity: "low",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["346", "152"],
    endpoint: "/books/notifications",
    category: "Error Handling",
    description:
      "Missing Cross-Origin Resource Sharing (CORS) headers can lead to security issues when interacting with web resources from different origins.",
    owaspTopTen: "",
    name: "Missing CORS Headers",
    verb: "PATCH",
    createdAt: "2024-07-14T22:00:00.000Z",
    cvss: 0.45,
    isNew: true,
  },
  {
    id: "1000116",
    scanID: "82",
    severity: "low",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["192", "152", "391", "493", "180"],
    endpoint: "/products/logs",
    category: "Authentication",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API10:2023",
    name: "Improper Inventory Management",
    verb: "POST",
    createdAt: "2024-07-14T22:00:00.000Z",
    cvss: 1.93,
    isNew: true,
  },
  {
    id: "1000115",
    scanID: "55",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["498"],
    endpoint: "/comments/{bookID}",
    category: "Configuration and Deployments",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "API09:2023",
    name: "Broken Function Level Authorization",
    verb: "PATCH",
    createdAt: "2024-07-14T22:00:00.000Z",
    cvss: 6.53,
    isNew: true,
  },
  {
    id: "1000114",
    scanID: "44",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["449", "486", "464"],
    endpoint: "/books/{commentID}/logs",
    category: "Session Management",
    description:
      "Unrestricted access to sensitive business flows can result in unauthorized users being able to interact with critical business processes.",
    owaspTopTen: "API05:2023",
    name: "Unrestricted Access to Sensitive Business Flows",
    verb: "POST",
    createdAt: "2024-07-15T22:00:00.000Z",
    cvss: 3.86,
    isNew: true,
  },
  {
    id: "1000113",
    scanID: "46",
    severity: "high",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["123", "343"],
    endpoint: "/comments/{bookID}",
    category: "Configuration and Deployments",
    description:
      "The use of weak encryption in JSON data can result in data being inadequately protected, leading to potential security breaches.",
    owaspTopTen: "API01:2023",
    name: "JSON - Use of Weak Encryption",
    verb: "GET",
    createdAt: "2024-07-15T22:00:00.000Z",
    cvss: 8.3,
    isNew: true,
  },
  {
    id: "1000112",
    scanID: "44",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["246", "444", "499", "375"],
    endpoint: "/stats/history",
    category: "Authentication",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API07:2023",
    name: "Broken Object Property Level Authorization",
    verb: "PUT",
    createdAt: "2024-07-15T22:00:00.000Z",
    cvss: 7.72,
    isNew: true,
  },
  {
    id: "1000111",
    scanID: "86",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["389", "263"],
    endpoint: "/stats/{statID}/logs",
    category: "Error Handling",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API03:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-15T22:00:00.000Z",
    cvss: 8.47,
    isNew: true,
  },
  {
    id: "1000110",
    scanID: "35",
    severity: "information",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["263", "245", "191", "411"],
    endpoint: "/books/{bookID}/notifications",
    category: "Authentication",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API10:2023",
    name: "Input returned in response (reflected)",
    verb: "DELETE",
    createdAt: "2024-07-15T22:00:00.000Z",
    cvss: 3.04,
    isNew: true,
  },
  {
    id: "1000109",
    scanID: "85",
    severity: "high",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["211", "291", "311"],
    endpoint: "/stats/{userID}",
    category: "Malicious Input Handling",
    description:
      "Broken authentication refers to weaknesses in the authentication process that can allow unauthorized users to gain access to protected resources.",
    owaspTopTen: "API01:2023",
    name: "Broken Authentication",
    verb: "PUT",
    createdAt: "2024-07-16T22:00:00.000Z",
    cvss: 8.3,
    isNew: true,
  },
  {
    id: "1000108",
    scanID: "82",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["458", "137"],
    endpoint: "/comments/{commentID}/history",
    category: "Error Handling",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "",
    name: "Server Side Request Forgery",
    verb: "GET",
    createdAt: "2024-07-16T22:00:00.000Z",
    cvss: 6.43,
    isNew: true,
  },
  {
    id: "1000107",
    scanID: "39",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["410"],
    endpoint: "/books/{commentID}/notifications",
    category: "Session Management",
    description:
      "Unsafe consumption of APIs can result in vulnerabilities related to how an application interacts with external APIs, potentially exposing it to risks.",
    owaspTopTen: "API01:2023",
    name: "Unsafe Consumption of APIs",
    verb: "GET",
    createdAt: "2024-07-16T22:00:00.000Z",
    cvss: 4.87,
    isNew: true,
  },
  {
    id: "1000106",
    scanID: "94",
    severity: "information",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["496", "461", "443", "442"],
    endpoint: "/products/{productID}/history",
    category: "Access Control",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "",
    name: "Input returned in response (reflected)",
    verb: "PATCH",
    createdAt: "2024-07-16T22:00:00.000Z",
    cvss: 5.39,
    isNew: true,
  },
  {
    id: "1000105",
    scanID: "82",
    severity: "information",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["315", "180"],
    endpoint: "/stats/notifications",
    category: "Authentication",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    owaspTopTen: "API01:2023",
    name: "Unencrypted communications",
    verb: "PUT",
    createdAt: "2024-07-16T22:00:00.000Z",
    cvss: 3.35,
    isNew: true,
  },
  {
    id: "1000104",
    scanID: "50",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["352", "219"],
    endpoint: "/books/logs",
    category: "Authentication",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API06:2023",
    name: "Broken Object Property Level Authorization",
    verb: "GET",
    createdAt: "2024-07-17T22:00:00.000Z",
    cvss: 1.77,
    isNew: true,
  },
  {
    id: "1000103",
    scanID: "66",
    severity: "low",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["260", "309", "369", "173"],
    endpoint: "/products/{statID}",
    category: "Malicious Input Handling",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "API10:2023",
    name: "Security Misconfiguration",
    verb: "POST",
    createdAt: "2024-07-17T22:00:00.000Z",
    cvss: 4.12,
    isNew: true,
  },
  {
    id: "1000102",
    scanID: "88",
    severity: "high",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["197"],
    endpoint: "/users/notifications",
    category: "Access Control",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API06:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "PUT",
    createdAt: "2024-07-17T22:00:00.000Z",
    cvss: 5.9,
    isNew: true,
  },
  {
    id: "1000101",
    scanID: "31",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["455", "419", "114"],
    endpoint: "/products/{productID}",
    category: "Authentication",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API02:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "POST",
    createdAt: "2024-07-17T22:00:00.000Z",
    cvss: 0.98,
    isNew: true,
  },
  {
    id: "1000100",
    scanID: "66",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["151", "105", "196"],
    endpoint: "/users/{productID}/history",
    category: "Authentication",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "",
    name: "Broken Object Property Level Authorization",
    verb: "PUT",
    createdAt: "2024-07-17T22:00:00.000Z",
    cvss: 7.83,
    isNew: true,
  },
  {
    id: "100099",
    scanID: "52",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["295"],
    endpoint: "/books/details",
    category: "Malicious Input Handling",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API05:2023",
    name: "Broken Object Property Level Authorization",
    verb: "DELETE",
    createdAt: "2024-07-18T22:00:00.000Z",
    cvss: 3.99,
    isNew: true,
  },
  {
    id: "100098",
    scanID: "75",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["417", "381"],
    endpoint: "/stats/{statID}",
    category: "Access Control",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "",
    name: "Broken Function Level Authorization",
    verb: "GET",
    createdAt: "2024-07-18T22:00:00.000Z",
    cvss: 7.62,
    isNew: true,
  },
  {
    id: "100097",
    scanID: "93",
    severity: "high",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["312", "281", "111", "227", "265"],
    endpoint: "/stats/details",
    category: "Session Management",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API01:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "DELETE",
    createdAt: "2024-07-18T22:00:00.000Z",
    cvss: 6.33,
    isNew: true,
  },
  {
    id: "100096",
    scanID: "97",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["488", "281"],
    endpoint: "/books/{commentID}/history",
    category: "Error Handling",
    description:
      "Failure to invalidate sessions properly can result in security issues where users can continue to access resources even after logging out.",
    owaspTopTen: "API04:2023",
    name: "Failure to Invalidate Session",
    verb: "GET",
    createdAt: "2024-07-18T22:00:00.000Z",
    cvss: 3.48,
    isNew: true,
  },
  {
    id: "100095",
    scanID: "63",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["199", "257"],
    endpoint: "/books/history",
    category: "Authentication",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API07:2023",
    name: "Unrestricted Resource Consumption",
    verb: "GET",
    createdAt: "2024-07-18T22:00:00.000Z",
    cvss: 2.53,
    isNew: true,
  },
  {
    id: "100094",
    scanID: "63",
    severity: "low",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["203"],
    endpoint: "/comments/{bookID}/history",
    category: "Malicious Input Handling",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API05:2023",
    name: "Improper Inventory Management",
    verb: "PUT",
    createdAt: "2024-07-19T22:00:00.000Z",
    cvss: 2.87,
    isNew: true,
  },
  {
    id: "100093",
    scanID: "96",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["363", "144", "252"],
    endpoint: "/products/details",
    category: "Session Management",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API03:2023",
    name: "Unrestricted Resource Consumption",
    verb: "PUT",
    createdAt: "2024-07-19T22:00:00.000Z",
    cvss: 1.35,
    isNew: true,
  },
  {
    id: "100092",
    scanID: "79",
    severity: "high",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["339", "154", "255", "388"],
    endpoint: "/orders/updates",
    category: "Access Control",
    description:
      "## Broken Object Level Authorization (BOLA)\n\nBOLA is a critical security vulnerability that occurs when an application or API fails to properly enforce access control rules for specific objects. This allows unauthorized users to access sensitive data or perform actions that they should not be able to do.\n\nBOLA vulnerabilities can have severe consequences for an organization, including:\n\n* **Data breaches:** Unauthorized access to sensitive data can lead to financial losses, reputational damage, and privacy violations.\n* **Malicious actions:** Attackers can manipulate data, delete critical information, or even take control of the application or API.\n* **Downtime:** Vulnerabilities can cause system outages, disrupting operations and impacting users.\n\nOrganizations must take steps to mitigate BOLA vulnerabilities to protect their data and systems from unauthorized access and manipulation.",
    owaspTopTen: "",
    name: "Broken Object Level Authorization",
    verb: "PUT",
    createdAt: "2024-07-19T22:00:00.000Z",
    cvss: 9.88,
    isNew: true,
  },
  {
    id: "100091",
    scanID: "96",
    severity: "low",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["294", "368", "311", "264", "112"],
    endpoint: "/books",
    category: "Access Control",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API05:2023",
    name: "Improper Inventory Management",
    verb: "GET",
    createdAt: "2024-07-19T22:00:00.000Z",
    cvss: 8.04,
    isNew: true,
  },
  {
    id: "100090",
    scanID: "45",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["290"],
    endpoint: "/books",
    category: "Malicious Input Handling",
    description:
      "## Broken Object Level Authorization (BOLA)\n\nBOLA is a critical security vulnerability that occurs when an application or API fails to properly enforce access control rules for specific objects. This allows unauthorized users to access sensitive data or perform actions that they should not be able to do.\n\nBOLA vulnerabilities can have severe consequences for an organization, including:\n\n* **Data breaches:** Unauthorized access to sensitive data can lead to financial losses, reputational damage, and privacy violations.\n* **Malicious actions:** Attackers can manipulate data, delete critical information, or even take control of the application or API.\n* **Downtime:** Vulnerabilities can cause system outages, disrupting operations and impacting users.\n\nOrganizations must take steps to mitigate BOLA vulnerabilities to protect their data and systems from unauthorized access and manipulation.",
    owaspTopTen: "API04:2023",
    name: "Broken Object Level Authorization",
    verb: "GET",
    createdAt: "2024-07-19T22:00:00.000Z",
    cvss: 2.25,
    isNew: true,
  },
  {
    id: "100089",
    scanID: "74",
    severity: "information",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["398", "376", "186"],
    endpoint: "/books",
    category: "Error Handling",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API04:2023",
    name: "Input returned in response (reflected)",
    verb: "GET",
    createdAt: "2024-07-20T22:00:00.000Z",
    cvss: 0.97,
    isNew: true,
  },
  {
    id: "100088",
    scanID: "86",
    severity: "high",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["136", "138", "171", "341"],
    endpoint: "/comments/{bookID}",
    category: "Session Management",
    description:
      "## Broken Object Level Authorization (BOLA)\n\nBOLA is a critical security vulnerability that occurs when an application or API fails to properly enforce access control rules for specific objects. This allows unauthorized users to access sensitive data or perform actions that they should not be able to do.\n\nBOLA vulnerabilities can have severe consequences for an organization, including:\n\n* **Data breaches:** Unauthorized access to sensitive data can lead to financial losses, reputational damage, and privacy violations.\n* **Malicious actions:** Attackers can manipulate data, delete critical information, or even take control of the application or API.\n* **Downtime:** Vulnerabilities can cause system outages, disrupting operations and impacting users.\n\nOrganizations must take steps to mitigate BOLA vulnerabilities to protect their data and systems from unauthorized access and manipulation.",
    owaspTopTen: "API02:2023",
    name: "Broken Object Level Authorization",
    verb: "GET",
    createdAt: "2024-07-20T22:00:00.000Z",
    cvss: 6.41,
    isNew: true,
  },
  {
    id: "100087",
    scanID: "30",
    severity: "low",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["354", "440", "301"],
    endpoint: "/books/details",
    category: "Authentication",
    description:
      "Failure to invalidate sessions properly can result in security issues where users can continue to access resources even after logging out.",
    owaspTopTen: "API01:2023",
    name: "Failure to Invalidate Session",
    verb: "POST",
    createdAt: "2024-07-20T22:00:00.000Z",
    cvss: 3.06,
    isNew: true,
  },
  {
    id: "100086",
    scanID: "39",
    severity: "low",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["460", "455", "141", "148", "246"],
    endpoint: "/orders/{bookID}/logs",
    category: "Malicious Input Handling",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "",
    name: "Security Misconfiguration",
    verb: "PUT",
    createdAt: "2024-07-20T22:00:00.000Z",
    cvss: 7.03,
    isNew: true,
  },
  {
    id: "100085",
    scanID: "35",
    severity: "low",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["312", "156", "193", "210", "392"],
    endpoint: "/products/notifications",
    category: "Authentication",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "API06:2023",
    name: "Security Misconfiguration",
    verb: "POST",
    createdAt: "2024-07-20T22:00:00.000Z",
    cvss: 3.98,
    isNew: true,
  },
  {
    id: "100084",
    scanID: "73",
    severity: "low",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["465", "244"],
    endpoint: "/stats/details",
    category: "Configuration and Deployments",
    description:
      "Failure to invalidate sessions properly can result in security issues where users can continue to access resources even after logging out.",
    owaspTopTen: "API03:2023",
    name: "Failure to Invalidate Session",
    verb: "GET",
    createdAt: "2024-07-21T22:00:00.000Z",
    cvss: 1.66,
    isNew: true,
  },
  {
    id: "100083",
    scanID: "62",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["227"],
    endpoint: "/stats/{commentID}/history",
    category: "Session Management",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "API01:2023",
    name: "Security Misconfiguration",
    verb: "POST",
    createdAt: "2024-07-21T22:00:00.000Z",
    cvss: 7.56,
    isNew: true,
  },
  {
    id: "100082",
    scanID: "57",
    severity: "high",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["260", "457", "359"],
    endpoint: "/users/{bookID}/details",
    category: "Malicious Input Handling",
    description:
      "## Broken Object Level Authorization (BOLA)\n\nBOLA is a critical security vulnerability that occurs when an application or API fails to properly enforce access control rules for specific objects. This allows unauthorized users to access sensitive data or perform actions that they should not be able to do.\n\nBOLA vulnerabilities can have severe consequences for an organization, including:\n\n* **Data breaches:** Unauthorized access to sensitive data can lead to financial losses, reputational damage, and privacy violations.\n* **Malicious actions:** Attackers can manipulate data, delete critical information, or even take control of the application or API.\n* **Downtime:** Vulnerabilities can cause system outages, disrupting operations and impacting users.\n\nOrganizations must take steps to mitigate BOLA vulnerabilities to protect their data and systems from unauthorized access and manipulation.",
    owaspTopTen: "API09:2023",
    name: "Broken Object Level Authorization",
    verb: "GET",
    createdAt: "2024-07-21T22:00:00.000Z",
    cvss: 7.58,
    isNew: true,
  },
  {
    id: "100081",
    scanID: "95",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["360", "139"],
    endpoint: "/users/{userID}",
    category: "Authentication",
    description:
      "Broken authentication refers to weaknesses in the authentication process that can allow unauthorized users to gain access to protected resources.",
    owaspTopTen: "API08:2023",
    name: "Broken Authentication",
    verb: "POST",
    createdAt: "2024-07-21T22:00:00.000Z",
    cvss: 9.46,
    isNew: true,
  },
  {
    id: "100080",
    scanID: "71",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["237", "284", "319", "402"],
    endpoint: "/comments/{commentID}",
    category: "Authentication",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API01:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "POST",
    createdAt: "2024-07-21T22:00:00.000Z",
    cvss: 3.96,
    isNew: true,
  },
  {
    id: "100079",
    scanID: "66",
    severity: "low",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["258"],
    endpoint: "/stats/{userID}/history",
    category: "Configuration and Deployments",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API04:2023",
    name: "Improper Inventory Management",
    verb: "PUT",
    createdAt: "2024-07-22T22:00:00.000Z",
    cvss: 0.43,
    isNew: true,
  },
  {
    id: "100078",
    scanID: "34",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["239", "169"],
    endpoint: "/stats/{statID}",
    category: "Error Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API04:2023",
    name: "Unrestricted Resource Consumption",
    verb: "PUT",
    createdAt: "2024-07-22T22:00:00.000Z",
    cvss: 2.24,
    isNew: true,
  },
  {
    id: "100077",
    scanID: "98",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["209", "423", "268", "244"],
    endpoint: "/comments/updates",
    category: "Error Handling",
    description:
      "Failure to invalidate sessions properly can result in security issues where users can continue to access resources even after logging out.",
    owaspTopTen: "API05:2023",
    name: "Failure to Invalidate Session",
    verb: "PUT",
    createdAt: "2024-07-22T22:00:00.000Z",
    cvss: 3.43,
    isNew: true,
  },
  {
    id: "100076",
    scanID: "78",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["419", "472", "320", "128"],
    endpoint: "/books/{commentID}/details",
    category: "Configuration and Deployments",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API10:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-22T22:00:00.000Z",
    cvss: 2.64,
    isNew: true,
  },
  {
    id: "100075",
    scanID: "29",
    severity: "high",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["168", "112"],
    endpoint: "/products/{userID}",
    category: "Authentication",
    description:
      "Broken authentication refers to weaknesses in the authentication process that can allow unauthorized users to gain access to protected resources.",
    owaspTopTen: "API02:2023",
    name: "Broken Authentication",
    verb: "PUT",
    createdAt: "2024-07-22T22:00:00.000Z",
    cvss: 7.06,
    isNew: true,
  },
  {
    id: "100074",
    scanID: "52",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["310", "215", "398", "294"],
    endpoint: "/products",
    category: "Session Management",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API01:2023",
    name: "Broken Object Property Level Authorization",
    verb: "PUT",
    createdAt: "2024-07-23T22:00:00.000Z",
    cvss: 3.94,
    isNew: true,
  },
  {
    id: "100073",
    scanID: "27",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["236", "183", "257", "110", "362"],
    endpoint: "/stats/history",
    category: "Session Management",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API07:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-23T22:00:00.000Z",
    cvss: 4.84,
    isNew: true,
  },
  {
    id: "100072",
    scanID: "53",
    severity: "information",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["351"],
    endpoint: "/books",
    category: "Access Control",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    owaspTopTen: "",
    name: "Unencrypted communications",
    verb: "PUT",
    createdAt: "2024-07-23T22:00:00.000Z",
    cvss: 3.22,
    isNew: true,
  },
  {
    id: "100071",
    scanID: "59",
    severity: "high",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["487", "254", "189", "477", "274"],
    endpoint: "/orders/details",
    category: "Session Management",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API01:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "GET",
    createdAt: "2024-07-23T22:00:00.000Z",
    cvss: 6.26,
    isNew: true,
  },
  {
    id: "100070",
    scanID: "93",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["111", "373", "419"],
    endpoint: "/products",
    category: "Session Management",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API06:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-23T22:00:00.000Z",
    cvss: 3.93,
    isNew: true,
  },
  {
    id: "100069",
    scanID: "60",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["215", "398", "477", "132"],
    endpoint: "/products",
    category: "Malicious Input Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API09:2023",
    name: "Unrestricted Resource Consumption",
    verb: "POST",
    createdAt: "2024-07-24T22:00:00.000Z",
    cvss: 2.36,
    isNew: true,
  },
  {
    id: "100068",
    scanID: "75",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["343", "103", "471", "318", "214"],
    endpoint: "/comments/{bookID}",
    category: "Session Management",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "API09:2023",
    name: "Broken Function Level Authorization",
    verb: "POST",
    createdAt: "2024-07-24T22:00:00.000Z",
    cvss: 3.12,
    isNew: false,
  },
  {
    id: "100067",
    scanID: "81",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["484", "273", "184", "357", "119"],
    endpoint: "/orders/{orderID}/history",
    category: "Authentication",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API06:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "POST",
    createdAt: "2024-07-24T22:00:00.000Z",
    cvss: 7.68,
    isNew: false,
  },
  {
    id: "100066",
    scanID: "73",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["142", "247", "469", "373", "209"],
    endpoint: "/orders/updates",
    category: "Authentication",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "API06:2023",
    name: "Broken Function Level Authorization",
    verb: "GET",
    createdAt: "2024-07-24T22:00:00.000Z",
    cvss: 2.23,
    isNew: false,
  },
  {
    id: "100065",
    scanID: "98",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["295"],
    endpoint: "/products/{orderID}",
    category: "Error Handling",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "API09:2023",
    name: "Security Misconfiguration",
    verb: "POST",
    createdAt: "2024-07-24T22:00:00.000Z",
    cvss: 3.86,
    isNew: false,
  },
  {
    id: "100064",
    scanID: "38",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["435", "268", "106"],
    endpoint: "/comments/{productID}",
    category: "Authentication",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "API02:2023",
    name: "Broken Function Level Authorization",
    verb: "POST",
    createdAt: "2024-07-25T22:00:00.000Z",
    cvss: 4.03,
    isNew: false,
  },
  {
    id: "100063",
    scanID: "70",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["402", "469", "404", "274"],
    endpoint: "/stats/{commentID}",
    category: "Authentication",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "API02:2023",
    name: "Server Side Request Forgery",
    verb: "POST",
    createdAt: "2024-07-25T22:00:00.000Z",
    cvss: 4.58,
    isNew: false,
  },
  {
    id: "100062",
    scanID: "54",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["249", "354", "117"],
    endpoint: "/users/logs",
    category: "Malicious Input Handling",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API09:2023",
    name: "Improper Inventory Management",
    verb: "POST",
    createdAt: "2024-07-25T22:00:00.000Z",
    cvss: 7.27,
    isNew: false,
  },
  {
    id: "100061",
    scanID: "72",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["270", "401", "158", "497", "419"],
    endpoint: "/books/{orderID}/updates",
    category: "Configuration and Deployments",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API09:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-25T22:00:00.000Z",
    cvss: 0.89,
    isNew: false,
  },
  {
    id: "100060",
    scanID: "56",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["117", "305", "160"],
    endpoint: "/comments",
    category: "Session Management",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API02:2023",
    name: "Unrestricted Resource Consumption",
    verb: "DELETE",
    createdAt: "2024-07-25T22:00:00.000Z",
    cvss: 0.21,
    isNew: false,
  },
  {
    id: "100059",
    scanID: "46",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["131", "135"],
    endpoint: "/stats/updates",
    category: "Malicious Input Handling",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "API05:2023",
    name: "Server Side Request Forgery",
    verb: "POST",
    createdAt: "2024-07-26T22:00:00.000Z",
    cvss: 9.18,
    isNew: false,
  },
  {
    id: "100058",
    scanID: "39",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["196", "160"],
    endpoint: "/users/{userID}",
    category: "Session Management",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API06:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-26T22:00:00.000Z",
    cvss: 5.55,
    isNew: false,
  },
  {
    id: "100057",
    scanID: "42",
    severity: "low",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["393", "259", "410"],
    endpoint: "/users/{commentID}/logs",
    category: "Access Control",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "API09:2023",
    name: "Security Misconfiguration",
    verb: "DELETE",
    createdAt: "2024-07-26T22:00:00.000Z",
    cvss: 8.78,
    isNew: false,
  },
  {
    id: "100056",
    scanID: "62",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["254"],
    endpoint: "/stats/history",
    category: "Session Management",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "",
    name: "Broken Function Level Authorization",
    verb: "POST",
    createdAt: "2024-07-26T22:00:00.000Z",
    cvss: 5.12,
    isNew: false,
  },
  {
    id: "100055",
    scanID: "68",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["116", "493", "169"],
    endpoint: "/stats/{userID}",
    category: "Session Management",
    description:
      "Unrestricted access to sensitive business flows can result in unauthorized users being able to interact with critical business processes.",
    owaspTopTen: "API04:2023",
    name: "Unrestricted Access to Sensitive Business Flows",
    verb: "POST",
    createdAt: "2024-07-26T22:00:00.000Z",
    cvss: 2.96,
    isNew: false,
  },
  {
    id: "100054",
    scanID: "51",
    severity: "information",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["432", "495"],
    endpoint: "/products",
    category: "Session Management",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    owaspTopTen: "API09:2023",
    name: "Unencrypted communications",
    verb: "PATCH",
    createdAt: "2024-07-27T22:00:00.000Z",
    cvss: 0.91,
    isNew: false,
  },
  {
    id: "100053",
    scanID: "83",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["316", "464"],
    endpoint: "/books/{commentID}",
    category: "Access Control",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API03:2023",
    name: "Improper Inventory Management",
    verb: "POST",
    createdAt: "2024-07-27T22:00:00.000Z",
    cvss: 5.78,
    isNew: false,
  },
  {
    id: "100052",
    scanID: "49",
    severity: "low",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["405", "215", "225", "145"],
    endpoint: "/comments",
    category: "Error Handling",
    description:
      "Missing Cross-Origin Resource Sharing (CORS) headers can lead to security issues when interacting with web resources from different origins.",
    owaspTopTen: "API04:2023",
    name: "Missing CORS Headers",
    verb: "POST",
    createdAt: "2024-07-27T22:00:00.000Z",
    cvss: 4.39,
    isNew: false,
  },
  {
    id: "100051",
    scanID: "72",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["461", "182", "371", "337", "177"],
    endpoint: "/products/{userID}/notifications",
    category: "Authentication",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API01:2023",
    name: "Broken Object Property Level Authorization",
    verb: "GET",
    createdAt: "2024-07-27T22:00:00.000Z",
    cvss: 4.2,
    isNew: false,
  },
  {
    id: "100050",
    scanID: "51",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["134", "253", "375", "353"],
    endpoint: "/stats/notifications",
    category: "Access Control",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API03:2023",
    name: "Unrestricted Resource Consumption",
    verb: "GET",
    createdAt: "2024-07-27T22:00:00.000Z",
    cvss: 4.19,
    isNew: false,
  },
  {
    id: "100049",
    scanID: "25",
    severity: "high",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["493", "171", "295", "206"],
    endpoint: "/users/logs",
    category: "Authentication",
    description:
      "Broken authentication refers to weaknesses in the authentication process that can allow unauthorized users to gain access to protected resources.",
    owaspTopTen: "API06:2023",
    name: "Broken Authentication",
    verb: "POST",
    createdAt: "2024-07-28T22:00:00.000Z",
    cvss: 8.53,
    isNew: false,
  },
  {
    id: "100048",
    scanID: "89",
    severity: "information",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["227", "185", "445", "260"],
    endpoint: "/users/logs",
    category: "Malicious Input Handling",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API02:2023",
    name: "Input returned in response (reflected)",
    verb: "PUT",
    createdAt: "2024-07-28T22:00:00.000Z",
    cvss: 5.23,
    isNew: false,
  },
  {
    id: "100047",
    scanID: "43",
    severity: "low",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["431", "243", "361", "126", "205"],
    endpoint: "/users/{userID}",
    category: "Malicious Input Handling",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API10:2023",
    name: "Improper Inventory Management",
    verb: "PUT",
    createdAt: "2024-07-28T22:00:00.000Z",
    cvss: 9.47,
    isNew: false,
  },
  {
    id: "100046",
    scanID: "56",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["408", "194", "383"],
    endpoint: "/orders/logs",
    category: "Authentication",
    description:
      "Improper inventory management vulnerabilities relate to issues in how an application manages its inventory, which can lead to inefficiencies.",
    owaspTopTen: "API01:2023",
    name: "Improper Inventory Management",
    verb: "POST",
    createdAt: "2024-07-28T22:00:00.000Z",
    cvss: 4.74,
    isNew: false,
  },
  {
    id: "100045",
    scanID: "45",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["485", "125", "368"],
    endpoint: "/products",
    category: "Authentication",
    description:
      "This vulnerability pertains to broken object property level authorization, which may result in unauthorized access to specific object properties.",
    owaspTopTen: "API05:2023",
    name: "Broken Object Property Level Authorization",
    verb: "POST",
    createdAt: "2024-07-28T22:00:00.000Z",
    cvss: 2.65,
    isNew: false,
  },
  {
    id: "100044",
    scanID: "39",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["270", "394", "298"],
    endpoint: "/products/{statID}",
    category: "Configuration and Deployments",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "API06:2023",
    name: "Server Side Request Forgery",
    verb: "PUT",
    createdAt: "2024-07-29T22:00:00.000Z",
    cvss: 3.89,
    isNew: false,
  },
  {
    id: "100043",
    scanID: "87",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["211", "420", "457", "239", "406"],
    endpoint: "/comments/{commentID}/logs",
    category: "Error Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API10:2023",
    name: "Unrestricted Resource Consumption",
    verb: "GET",
    createdAt: "2024-07-29T22:00:00.000Z",
    cvss: 3.93,
    isNew: false,
  },
  {
    id: "100042",
    scanID: "99",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["492", "225"],
    endpoint: "/stats/{commentID}/details",
    category: "Error Handling",
    description:
      "Unrestricted access to sensitive business flows can result in unauthorized users being able to interact with critical business processes.",
    owaspTopTen: "API09:2023",
    name: "Unrestricted Access to Sensitive Business Flows",
    verb: "PUT",
    createdAt: "2024-07-29T22:00:00.000Z",
    cvss: 1.5,
    isNew: false,
  },
  {
    id: "100041",
    scanID: "29",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["346", "234", "329", "427", "342"],
    endpoint: "/users",
    category: "Malicious Input Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API05:2023",
    name: "Unrestricted Resource Consumption",
    verb: "PUT",
    createdAt: "2024-07-29T22:00:00.000Z",
    cvss: 5.24,
    isNew: false,
  },
  {
    id: "100040",
    scanID: "43",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["422", "370"],
    endpoint: "/books/logs",
    category: "Authentication",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "",
    name: "Unrestricted Resource Consumption",
    verb: "GET",
    createdAt: "2024-07-29T22:00:00.000Z",
    cvss: 7.44,
    isNew: false,
  },
  {
    id: "100039",
    scanID: "95",
    severity: "information",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["391", "169", "422", "114", "446"],
    endpoint: "/orders",
    category: "Configuration and Deployments",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API03:2023",
    name: "Input returned in response (reflected)",
    verb: "POST",
    createdAt: "2024-07-30T22:00:00.000Z",
    cvss: 6.7,
    isNew: false,
  },
  {
    id: "100038",
    scanID: "79",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["314", "245", "144", "240", "102"],
    endpoint: "/stats/updates",
    category: "Access Control",
    description:
      "Unsafe consumption of APIs can result in vulnerabilities related to how an application interacts with external APIs, potentially exposing it to risks.",
    owaspTopTen: "API10:2023",
    name: "Unsafe Consumption of APIs",
    verb: "POST",
    createdAt: "2024-07-30T22:00:00.000Z",
    cvss: 7.52,
    isNew: false,
  },
  {
    id: "100037",
    scanID: "99",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["470", "400", "455"],
    endpoint: "/users",
    category: "Authentication",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "",
    name: "Unrestricted Resource Consumption",
    verb: "POST",
    createdAt: "2024-07-30T22:00:00.000Z",
    cvss: 7.5,
    isNew: false,
  },
  {
    id: "100036",
    scanID: "98",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["364", "334", "315", "191"],
    endpoint: "/stats/updates",
    category: "Error Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API02:2023",
    name: "Unrestricted Resource Consumption",
    verb: "PUT",
    createdAt: "2024-07-30T22:00:00.000Z",
    cvss: 9.84,
    isNew: false,
  },
  {
    id: "100035",
    scanID: "32",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["437", "413", "342", "407"],
    endpoint: "/books",
    category: "Configuration and Deployments",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "API08:2023",
    name: "Server Side Request Forgery",
    verb: "PUT",
    createdAt: "2024-07-30T22:00:00.000Z",
    cvss: 7.49,
    isNew: false,
  },
  {
    id: "100034",
    scanID: "63",
    severity: "information",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["138", "291", "247", "285", "121"],
    endpoint: "/products/history",
    category: "Session Management",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API07:2023",
    name: "Input returned in response (reflected)",
    verb: "POST",
    createdAt: "2024-07-31T22:00:00.000Z",
    cvss: 2.51,
    isNew: false,
  },
  {
    id: "100033",
    scanID: "74",
    severity: "low",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["408", "336", "346", "135", "300"],
    endpoint: "/orders/{productID}/notifications",
    category: "Authentication",
    description:
      "Unsafe consumption of APIs can result in vulnerabilities related to how an application interacts with external APIs, potentially exposing it to risks.",
    owaspTopTen: "API06:2023",
    name: "Unsafe Consumption of APIs",
    verb: "GET",
    createdAt: "2024-07-31T22:00:00.000Z",
    cvss: 3.34,
    isNew: false,
  },
  {
    id: "100032",
    scanID: "70",
    severity: "information",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["253", "109", "272"],
    endpoint: "/users/details",
    category: "Session Management",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API04:2023",
    name: "Input returned in response (reflected)",
    verb: "POST",
    createdAt: "2024-07-31T22:00:00.000Z",
    cvss: 0.27,
    isNew: false,
  },
  {
    id: "100031",
    scanID: "45",
    severity: "high",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["497", "479", "196"],
    endpoint: "/comments/{orderID}/updates",
    category: "Access Control",
    description:
      "The use of weak encryption in JSON data can result in data being inadequately protected, leading to potential security breaches.",
    owaspTopTen: "API09:2023",
    name: "JSON - Use of Weak Encryption",
    verb: "POST",
    createdAt: "2024-07-31T22:00:00.000Z",
    cvss: 8.03,
    isNew: false,
  },
  {
    id: "100030",
    scanID: "59",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["310", "288", "395", "244"],
    endpoint: "/orders/{statID}/details",
    category: "Session Management",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "API08:2023",
    name: "Server Side Request Forgery",
    verb: "PUT",
    createdAt: "2024-07-31T22:00:00.000Z",
    cvss: 9.27,
    isNew: false,
  },
  {
    id: "100029",
    scanID: "63",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["285", "216", "321", "143", "230"],
    endpoint: "/books/{statID}/details",
    category: "Access Control",
    description:
      "Missing Cross-Origin Resource Sharing (CORS) headers can lead to security issues when interacting with web resources from different origins.",
    owaspTopTen: "API09:2023",
    name: "Missing CORS Headers",
    verb: "PUT",
    createdAt: "2024-08-01T22:00:00.000Z",
    cvss: 7.66,
    isNew: false,
  },
  {
    id: "100028",
    scanID: "74",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["197"],
    endpoint: "/books/logs",
    category: "Malicious Input Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API02:2023",
    name: "Unrestricted Resource Consumption",
    verb: "POST",
    createdAt: "2024-08-01T22:00:00.000Z",
    cvss: 0.02,
    isNew: false,
  },
  {
    id: "100027",
    scanID: "75",
    severity: "information",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["204", "287"],
    endpoint: "/products/{statID}",
    category: "Configuration and Deployments",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    owaspTopTen: "API02:2023",
    name: "Unencrypted communications",
    verb: "PUT",
    createdAt: "2024-08-01T22:00:00.000Z",
    cvss: 2.63,
    isNew: false,
  },
  {
    id: "100026",
    scanID: "35",
    severity: "information",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["149"],
    endpoint: "/products",
    category: "Access Control",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API06:2023",
    name: "Input returned in response (reflected)",
    verb: "GET",
    createdAt: "2024-08-01T22:00:00.000Z",
    cvss: 7.03,
    isNew: false,
  },
  {
    id: "100025",
    scanID: "96",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["410", "133", "212", "152"],
    endpoint: "/comments/{statID}",
    category: "Error Handling",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "",
    name: "Server Side Request Forgery",
    verb: "PATCH",
    createdAt: "2024-08-01T22:00:00.000Z",
    cvss: 0.19,
    isNew: false,
  },
  {
    id: "100024",
    scanID: "57",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["464", "480", "197"],
    endpoint: "/books",
    category: "Session Management",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "API07:2023",
    name: "Broken Function Level Authorization",
    verb: "GET",
    createdAt: "2024-08-02T22:00:00.000Z",
    cvss: 6.6,
    isNew: false,
  },
  {
    id: "100023",
    scanID: "32",
    severity: "information",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["297", "377", "337", "302"],
    endpoint: "/stats/logs",
    category: "Session Management",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API10:2023",
    name: "Input returned in response (reflected)",
    verb: "PATCH",
    createdAt: "2024-08-02T22:00:00.000Z",
    cvss: 1.41,
    isNew: false,
  },
  {
    id: "100022",
    scanID: "85",
    severity: "high",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["228", "229", "400", "323", "490"],
    endpoint: "/stats",
    category: "Error Handling",
    description:
      "The use of weak encryption in JSON data can result in data being inadequately protected, leading to potential security breaches.",
    owaspTopTen: "API07:2023",
    name: "JSON - Use of Weak Encryption",
    verb: "DELETE",
    createdAt: "2024-08-02T22:00:00.000Z",
    cvss: 0.69,
    isNew: false,
  },
  {
    id: "100021",
    scanID: "26",
    severity: "information",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["198"],
    endpoint: "/users",
    category: "Malicious Input Handling",
    description:
      "The application allows users to connect to it over unencrypted connections. An attacker suitably positioned to view a legitimate user's network traffic could record and monitor their interactions with the application and obtain any information the user supplies. Furthermore, an attacker able to modify traffic could use the application as a platform for attacks against its users and third-party websites. Unencrypted connections have been exploited by ISPs and governments to track users, and to inject adverts and malicious content.",
    owaspTopTen: "API07:2023",
    name: "Unencrypted communications",
    verb: "GET",
    createdAt: "2024-08-02T22:00:00.000Z",
    cvss: 1.33,
    isNew: false,
  },
  {
    id: "100020",
    scanID: "52",
    severity: "high",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["177", "131", "285", "310"],
    endpoint: "/stats/{commentID}",
    category: "Session Management",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API08:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "POST",
    createdAt: "2024-08-02T22:00:00.000Z",
    cvss: 9.67,
    isNew: false,
  },
  {
    id: "100019",
    scanID: "57",
    severity: "high",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["116", "354"],
    endpoint: "/stats",
    category: "Error Handling",
    description:
      "## Broken Object Level Authorization (BOLA)\n\nBOLA is a critical security vulnerability that occurs when an application or API fails to properly enforce access control rules for specific objects. This allows unauthorized users to access sensitive data or perform actions that they should not be able to do.\n\nBOLA vulnerabilities can have severe consequences for an organization, including:\n\n* **Data breaches:** Unauthorized access to sensitive data can lead to financial losses, reputational damage, and privacy violations.\n* **Malicious actions:** Attackers can manipulate data, delete critical information, or even take control of the application or API.\n* **Downtime:** Vulnerabilities can cause system outages, disrupting operations and impacting users.\n\nOrganizations must take steps to mitigate BOLA vulnerabilities to protect their data and systems from unauthorized access and manipulation.",
    owaspTopTen: "API08:2023",
    name: "Broken Object Level Authorization",
    verb: "POST",
    createdAt: "2024-08-03T22:00:00.000Z",
    cvss: 2.74,
    isNew: false,
  },
  {
    id: "100018",
    scanID: "50",
    severity: "information",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["418", "424", "248"],
    endpoint: "/books/{statID}/logs",
    category: "Access Control",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API03:2023",
    name: "Input returned in response (reflected)",
    verb: "POST",
    createdAt: "2024-08-03T22:00:00.000Z",
    cvss: 2.13,
    isNew: false,
  },
  {
    id: "100017",
    scanID: "77",
    severity: "information",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["170", "139", "416"],
    endpoint: "/products",
    category: "Access Control",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API09:2023",
    name: "Input returned in response (reflected)",
    verb: "POST",
    createdAt: "2024-08-03T22:00:00.000Z",
    cvss: 8.57,
    isNew: false,
  },
  {
    id: "100016",
    scanID: "61",
    severity: "low",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["398"],
    endpoint: "/comments/{userID}/logs",
    category: "Authentication",
    description:
      "Missing Cross-Origin Resource Sharing (CORS) headers can lead to security issues when interacting with web resources from different origins.",
    owaspTopTen: "API06:2023",
    name: "Missing CORS Headers",
    verb: "POST",
    createdAt: "2024-08-03T22:00:00.000Z",
    cvss: 9,
    isNew: false,
  },
  {
    id: "100015",
    scanID: "97",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["444", "293", "337"],
    endpoint: "/orders/logs",
    category: "Session Management",
    description:
      "Unrestricted access to sensitive business flows can result in unauthorized users being able to interact with critical business processes.",
    owaspTopTen: "API02:2023",
    name: "Unrestricted Access to Sensitive Business Flows",
    verb: "GET",
    createdAt: "2024-08-03T22:00:00.000Z",
    cvss: 7.01,
    isNew: false,
  },
  {
    id: "100014",
    scanID: "36",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["108", "351"],
    endpoint: "/comments/{userID}",
    category: "Configuration and Deployments",
    description:
      "Failure to invalidate sessions properly can result in security issues where users can continue to access resources even after logging out.",
    owaspTopTen: "API09:2023",
    name: "Failure to Invalidate Session",
    verb: "PUT",
    createdAt: "2024-08-04T22:00:00.000Z",
    cvss: 8.46,
    isNew: false,
  },
  {
    id: "100013",
    scanID: "34",
    severity: "medium",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["459", "398", "254", "489"],
    endpoint: "/products/history",
    category: "Error Handling",
    description:
      "Unrestricted access to sensitive business flows can result in unauthorized users being able to interact with critical business processes.",
    owaspTopTen: "API01:2023",
    name: "Unrestricted Access to Sensitive Business Flows",
    verb: "PUT",
    createdAt: "2024-08-04T22:00:00.000Z",
    cvss: 6.59,
    isNew: false,
  },
  {
    id: "100012",
    scanID: "57",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["157"],
    endpoint: "/products/{commentID}",
    category: "Authentication",
    description:
      "Broken function level authorization vulnerabilities involve improper access control for specific functions, which can allow unauthorized users to perform actions they shouldn't.",
    owaspTopTen: "API08:2023",
    name: "Broken Function Level Authorization",
    verb: "GET",
    createdAt: "2024-08-04T22:00:00.000Z",
    cvss: 2.13,
    isNew: false,
  },
  {
    id: "100011",
    scanID: "47",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["443", "456", "397"],
    endpoint: "/stats/{orderID}/updates",
    category: "Authentication",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API10:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "PUT",
    createdAt: "2024-08-04T22:00:00.000Z",
    cvss: 8.31,
    isNew: false,
  },
  {
    id: "100010",
    scanID: "84",
    severity: "high",
    projectID: "1",
    projectName: "Project alpha",
    cwes: ["354"],
    endpoint: "/users/{commentID}",
    category: "Malicious Input Handling",
    description:
      "The use of weak encryption in JSON data can result in data being inadequately protected, leading to potential security breaches.",
    owaspTopTen: "",
    name: "JSON - Use of Weak Encryption",
    verb: "PUT",
    createdAt: "2024-08-04T22:00:00.000Z",
    cvss: 1.1,
    isNew: false,
  },
  {
    id: "10009",
    scanID: "52",
    severity: "low",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["162"],
    endpoint: "/stats/logs",
    category: "Error Handling",
    description:
      "Security misconfiguration vulnerabilities involve improper configuration settings that can expose security weaknesses.",
    owaspTopTen: "API06:2023",
    name: "Security Misconfiguration",
    verb: "PUT",
    createdAt: "2024-08-05T22:00:00.000Z",
    cvss: 3.37,
    isNew: false,
  },
  {
    id: "10008",
    scanID: "26",
    severity: "medium",
    projectID: "4",
    projectName: "Project beta",
    cwes: ["311", "357", "357"],
    endpoint: "/stats/{orderID}",
    category: "Malicious Input Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API01:2023",
    name: "Unrestricted Resource Consumption",
    verb: "GET",
    createdAt: "2024-08-05T22:00:00.000Z",
    cvss: 5.14,
    isNew: false,
  },
  {
    id: "10007",
    scanID: "93",
    severity: "high",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["170", "488", "248"],
    endpoint: "/stats/notifications",
    category: "Configuration and Deployments",
    description:
      "XML External Entity (XXE) attacks involve the exploitation of XML parsing vulnerabilities, which can lead to information disclosure or denial of service.",
    owaspTopTen: "API08:2023",
    name: "XML External Entity (XXE) Attacks",
    verb: "POST",
    createdAt: "2024-08-05T22:00:00.000Z",
    cvss: 8.22,
    isNew: false,
  },
  {
    id: "10006",
    scanID: "54",
    severity: "medium",
    projectID: "2",
    projectName: "Project gamma",
    cwes: ["365"],
    endpoint: "/comments",
    category: "Session Management",
    description:
      "Server-side request forgery (SSRF) vulnerabilities occur when an application can be tricked into making unauthorized requests to internal resources.",
    owaspTopTen: "",
    name: "Server Side Request Forgery",
    verb: "DELETE",
    createdAt: "2024-08-05T22:00:00.000Z",
    cvss: 3.16,
    isNew: false,
  },
  {
    id: "10005",
    scanID: "68",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["209", "444", "381", "230", "303"],
    endpoint: "/products",
    category: "Access Control",
    description:
      "Unsafe consumption of APIs can result in vulnerabilities related to how an application interacts with external APIs, potentially exposing it to risks.",
    owaspTopTen: "API09:2023",
    name: "Unsafe Consumption of APIs",
    verb: "POST",
    createdAt: "2024-08-05T22:00:00.000Z",
    cvss: 2.66,
    isNew: false,
  },
  {
    id: "10004",
    scanID: "64",
    severity: "information",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["367", "439", "253"],
    endpoint: "/users/{userID}",
    category: "Session Management",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API02:2023",
    name: "Input returned in response (reflected)",
    verb: "POST",
    createdAt: "2024-08-06T22:00:00.000Z",
    cvss: 3.11,
    isNew: false,
  },
  {
    id: "10003",
    scanID: "67",
    severity: "low",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["195", "228"],
    endpoint: "/products",
    category: "Configuration and Deployments",
    description:
      "Unsafe consumption of APIs can result in vulnerabilities related to how an application interacts with external APIs, potentially exposing it to risks.",
    owaspTopTen: "API03:2023",
    name: "Unsafe Consumption of APIs",
    verb: "PUT",
    createdAt: "2024-08-06T22:00:00.000Z",
    cvss: 8.85,
    isNew: false,
  },
  {
    id: "10002",
    scanID: "39",
    severity: "medium",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["406", "380", "101", "348", "391"],
    endpoint: "/orders/{statID}/history",
    category: "Malicious Input Handling",
    description:
      "Unrestricted resource consumption occurs when an application does not properly limit resource usage, potentially leading to performance issues or denial of service.",
    owaspTopTen: "API04:2023",
    name: "Unrestricted Resource Consumption",
    verb: "PUT",
    createdAt: "2024-08-06T22:00:00.000Z",
    cvss: 1.07,
    isNew: false,
  },
  {
    id: "10001",
    scanID: "57",
    severity: "information",
    projectID: "5",
    projectName: "Project epsilon",
    cwes: ["485", "137", "329", "197"],
    endpoint: "/books/{productID}/details",
    category: "Configuration and Deployments",
    description:
      "Reflection of input arises when data is copied from a request and echoed into the application's immediate response. Input being returned in application responses is not a vulnerability in its own right. However, it is a prerequisite for many client-side vulnerabilities, including cross-site scripting, open redirection, content spoofing, and response header injection.",
    owaspTopTen: "API05:2023",
    name: "Input returned in response (reflected)",
    verb: "PUT",
    createdAt: "2024-08-06T22:00:00.000Z",
    cvss: 8.05,
    isNew: false,
  },
]);
