import { useHasAnyItemsGuard, usePaginatedResult, useUrlParams } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useMemo } from "react";
import type {
  ProjectListCriteria,
  ProjectListOperator,
  ProjectListSort,
} from "./ProjectsList.i";

export const useProjects = () => {
  const { organizationId } = useUrlParams();

  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters: useMemo(() => getDefaultFilters("projects"), []),
    getResponse: useCallback(
      ({ page, pageSize, sortBy, sortMode, ...rest }) => {
        if (!organizationId) throw new Error("invalid-organization-id");
        return API.project().projectList(
          organizationId,
          page,
          pageSize,
          sortBy as ProjectListSort,
          sortMode,
          rest.criteria as ProjectListCriteria[],
          rest.operators as ProjectListOperator[],
          rest.values
        );
      },
      [organizationId]
    ),
  });

  const { fetchingHasAnyItem, hasAnyItem } = useHasAnyItemsGuard({
    fetchCondition: response?.totalItems === 0,
    getResponse: useCallback(() => {
      if (!organizationId) throw new Error("invalid-organization-id");
      return API.project().projectList(organizationId, 1, 1);
    }, [organizationId]),
  });

  return {
    response,
    loading: loading || fetchingHasAnyItem,
    showCurtesyPage: hasAnyItem === false,
    fetch,
  };
};
