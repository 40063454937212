import { t } from "i18next";
import type { ParserOutcome } from "./types.i";

type Options = {
  pathsLimit: number;
};

export const testParsingResult = (
  result: ParserOutcome,
  { pathsLimit }: Options
) => {
  if (result.hasExternalReferences) {
    throw new Error("error-external-references");
  }

  const { totalPaths } = result;

  if (totalPaths < 1) {
    throw new Error("error-oas-nopaths");
  }

  if (totalPaths > pathsLimit) {
    const msg = t("project.errors.error-licence-limit", {
      paths: `${totalPaths}`,
      limit: `${pathsLimit}`,
    });

    throw new Error(msg);
  }
};
