import { HeaderLoader } from "./HeaderLoader";
import { cardHeight, gap, baseMarginTop } from "./constants";

export const AuthenticationsLoader = () => {
  return (
    <>
      <HeaderLoader />
      <>
        {Array.from(Array(3).keys()).map(row => (
          <LoaderCard row={row} key={row} />
        ))}
      </>
    </>
  );
};

const LoaderCard = ({ row }: { row: number }) => {
  const y = baseMarginTop + row * (gap + cardHeight);
  return (
    <rect
      x="0"
      y={`${y}`}
      rx="3"
      ry="3"
      width="100%"
      height={`${cardHeight}`}
    />
  );
};
