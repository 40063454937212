import { Callout } from "@Components";
import { useMaintenanceModeStore } from "@Stores";
import { pureComponent } from "@Utils";
import { t } from "i18next";
import moment from "moment-timezone";
import { memo, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const MaintenancePage = memo(() => {
  const { maintenanceModeEnd, maintenanceModeInProgress } =
    useMaintenanceModeStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (!maintenanceModeInProgress) {
      navigate("/");
    }
  }, [maintenanceModeInProgress, navigate]);

  const endDate = useMemo(
    () => moment(maintenanceModeEnd).format("dddd, MMMM Do YYYY, h:mm:ss a"),
    [maintenanceModeEnd]
  );

  return (
    <div
      className="w-100 h-100 d-flex justify-content-center flex-column"
      style={{ padding: "20rem" }}
    >
      <Callout
        level="warning"
        iconClass="bi bi-exclamation-square-fill font-size-h5"
      >
        <h5 className="m-0 h-100 d-flex align-items-center">
          {t("maintenance-mode.title")}
        </h5>
      </Callout>
      <h5
        className="mt-4"
        dangerouslySetInnerHTML={{
          __html: t("maintenance-mode.description", { endDate }),
        }}
      />
    </div>
  );
}, pureComponent);
