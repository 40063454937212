import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAuthUserContext, usersSetting } from "src/Mock";

export const settingsGet = (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const context = getAuthUserContext();
  const user = context.getUser();

  if (!user) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const result = usersSetting.find(setting => {
    return setting.userID === user.id;
  });

  const status = result ? 200 : 404;

  return res(ctx.delay(delay), ctx.status(status), ctx.json(result));
};
