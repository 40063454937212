import { API, clearJwtToken, hasAxiosAuth } from "@Services";
import { useEffect } from "react";

const logoff = async function () {
  if (hasAxiosAuth()) {
    try {
      await API.user().userLogout();
    } catch (error) {
      // to avoid possible issue on logoff
    }
  }
  clearJwtToken();
};

export const useLogOff = () => {
  useEffect(() => {
    logoff();
  }, []);
};
