import { usePaginatedResult } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback } from "react";
import type { CriteriaType, OperatorsType, SortByType } from "./Scans.i";

export const useScans = () => {
  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters: getDefaultFilters("scans"),
    getResponse: useCallback(args => {
      return API.scan().scanListInOrganization(
        args.organizationId,
        args.page,
        args.pageSize,
        args.sortBy as SortByType,
        args.sortMode,
        args.criteria as CriteriaType,
        args.operators as OperatorsType,
        args.values
      );
    }, []),
  });
  return {
    response,
    loading,
    fetch,
  };
};
