import { PROJECT_DETAILS_SCAN_URL } from "@Constants";
import { useTimedState, useToast } from "@Hooks";
import { useIssueListStore } from "@Stores";
import { generateUrl } from "@Utils";
import { useMemo } from "react";
import { useIssueDetailsCanvasIssueIds } from "./useIssueDetailsCanvasIssueIds";
import { useLocation } from "react-router-dom";

export const useIssueDetailsCanvasShareButton = () => {
  const { organizationId, projectId, scanId } = useIssueDetailsCanvasIssueIds();
  const { openIssueId } = useIssueListStore();
  const [shared, setShared] = useTimedState<boolean>(false);
  const toast = useToast();
  const location = useLocation();

  const shareableUrl = useMemo(
    () =>
      `${window.location.origin}${generateUrl(PROJECT_DETAILS_SCAN_URL, [
        organizationId ?? "",
        projectId ?? "",
        scanId ?? "",
      ])}?isp_issueId=${openIssueId}${location.hash}`,
    [location.hash, openIssueId, organizationId, projectId, scanId]
  );

  const shareButton = useMemo(
    () => ({
      color: "pink",
      children: "common.share",
      iconClass: shared ? "bi bi-check" : "bi bi-share-fill",
      onClick: () => {
        navigator.clipboard.writeText(shareableUrl);
        setShared(true);
        toast({
          message: "common.element-copied-clipboard",
          type: "success",
        });
      },
      data: {
        cy: "issue-share-button",
      },
    }),
    [setShared, shareableUrl, shared, toast]
  );

  return {
    shareButton,
  };
};
