import moment from "moment-timezone";
import { isMaxEndDate } from "./isMaxEndDate";

type Options = {
  trimTime?: boolean;
  checkMaxEndDate?: boolean;
};

// alternatives :
// - YYYY, ddd MMM DD hh:mm
export const defaultFormat = "ddd MMM DD, YYYY HH:mm";
export const defaultFormatWithSeconds = "ddd MMM DD, YYYY HH:mm:ss";
export const defaultTrimTimeFormat = "ddd MMM DD, YYYY";

export const dateTimeFormat = (
  date: moment.MomentInput,
  options: Options = {},
  format = ""
) => {
  const parsedDateTime = moment(date);
  if (
    !parsedDateTime.isValid() ||
    (options?.checkMaxEndDate && isMaxEndDate(parsedDateTime))
  ) {
    return "-";
  }

  const applyFormat =
    format || (options?.trimTime ? defaultTrimTimeFormat : defaultFormat);

  return parsedDateTime.format(applyFormat);
};
