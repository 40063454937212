import type { Issue, Nullable } from "@Interfaces";
import { binaryArrayToString } from "@Services";
import type { Flow } from "./IssueDetail.i";

const FLOW_LIMIT = 8;

export const getFlow = (details: Nullable<Issue>) => {
  if (!details) {
    return [];
  }

  // parse request with BUG
  let request;
  let response;
  try {
    const requestWithBug = JSON.parse(
      binaryArrayToString(details.requestWithBug)
    );
    request = requestWithBug.request;
    response = requestWithBug.response;
  } catch (error) {
    return [];
  }
  // parse sequence requests and responses
  let flow: Flow;
  try {
    flow = JSON.parse(binaryArrayToString(details.sequenceOfRequest)) as Flow;
  } catch (error) {
    flow = [];
  }

  flow = flow.filter(f => f.response);

  const flowLen = flow.length;
  flow = flow.slice(Math.max(flowLen - FLOW_LIMIT + 1, 0), flowLen);

  flow.push({
    request,
    response,
  });

  return flow;
};
