import { headerHeight } from "./constants";

type Props = {
  y?: string | number;
};

export const HeaderLoader = ({ y }: Props = { y: 0 }) => (
  <>
    <rect x="0" y={y} rx="3" ry="3" width="88%" height={headerHeight} />
    <rect x="90%" y={y} rx="3" ry="3" width="10%" height={headerHeight} />
  </>
);
