import { ListItems } from "@Components";
import { getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import type { TableProps } from "./AuthorizationMatrix.i";
import { useAuthorizationMatrixStore } from "./Store/AuthorizationMatrixStore";
import { getConfig, getHeaders } from "./functions";

export const Table = ({ setFilters }: TableProps) => {
  const { search } = useLocation();
  const { operations, profiles, loading } = useAuthorizationMatrixStore();
  const defaultFilters = useMemo(() => getDefaultFilters("authMatrix"), []);

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      items: getHeaders(profiles),
      search,
      updateFunction: setFilters,
      defaults: defaultFilters,
    });
  }, [defaultFilters, search, setFilters, profiles]);

  return (
    <ListItems
      cardVersion={false}
      config={getConfig(profiles, operations)}
      headers={headers}
      noDataMessage="common.no-auth-matrix-found"
      showSkeleton={loading}
      className="auth-matrix-list-table"
      setSorts={setFilters}
      defaultSorts={defaultFilters}
    />
  );
};
