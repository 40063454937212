import {
  useUpdatedOrganizationLicense,
  useProject,
  useUrlParams,
  useIsReader,
} from "@Hooks";
import { API } from "@Services";
import { useCallback, useMemo, useState } from "react";
import type {
  CanvasController,
  CanvasType,
  DangerZoneController,
} from "./ProjectGeneral.i";

export const useProjectSettings = () => {
  const isRoleReader = useIsReader();

  const {
    loading: projectLoading,
    project,
    update: projectUpdate,
  } = useProject();

  const { loading: licenseLoading, license } = useUpdatedOrganizationLicense();
  const { organizationId } = useUrlParams();
  const [canvasType, setCanvasType] = useState<CanvasType>();

  const archiveProject = useCallback(() => {
    if (!organizationId || !project) throw new Error("invalid-identifiers");
    return API.project().projectArchive(organizationId, project.id);
  }, [organizationId, project]);

  const deleteProject = useCallback(() => {
    if (!organizationId || !project) throw new Error("invalid-identifiers");
    return API.project().projectDelete(organizationId, project.id);
  }, [organizationId, project]);

  const loadingStates = useMemo(
    () => ({
      any: projectLoading !== false || licenseLoading,
      get: projectLoading === "get" || licenseLoading,
      put: projectLoading === "put",
      license: licenseLoading,
    }),
    [licenseLoading, projectLoading]
  );

  const canvasController: CanvasController = useMemo(
    () => ({
      canvasType,
      setCanvasType,
    }),
    [canvasType]
  );

  const dangerZoneController: DangerZoneController = useMemo(
    () => ({
      archiveProject,
      deleteProject,
    }),
    [archiveProject, deleteProject]
  );

  return {
    project,
    projectUpdate,
    noData: !projectLoading && !project,
    license,
    isRoleReader,
    loadingStates,
    canvasController,
    dangerZoneController,
  };
};
