import type { Nullable, NullableArray } from "@Interfaces";
import type {
  AdvancedSettings,
  AuthenticationSettings,
  DictionaryEntry,
  Injectable,
  IssueExclusion,
  MTLSCertificate,
  PathExclusionEntry,
} from "@Services";
import type { AuthenticationSettingsFormModel } from "@Pages";
import { type Category } from "@Services";
import { createStore } from "@Hooks";
import type { Dispatch } from "react";

export type SettingsStoreProperties = {
  dataLoaded: boolean;
  organizationId: string | undefined;
  projectId: string | undefined;
  scanId: string | undefined;
  extensionSettings: {
    categories: NullableArray<Category>;
  };
  authenticationSettings: NullableArray<AuthenticationSettingsFormModel>;
  dictionarySettings: {
    entries: NullableArray<DictionaryEntry>;
  };
  pathExclusionSettings: {
    paths: NullableArray<PathExclusionEntry>;
  };
  issueExclusionSettings: {
    issues: NullableArray<IssueExclusion>;
  };
  injectableParametersSettings: {
    injectables: NullableArray<Injectable>;
  };
  mTLSCertificatesSettings: {
    mTLSCertificates: NullableArray<MTLSCertificate>;
  };
  advancedSettings: Nullable<AdvancedSettings>;
  loading: boolean;
};

export type SettingsState = SettingsStoreProperties & {
  setDataLoaded: Dispatch<boolean>;
  setUriParameters: (
    organizationId: string | undefined,
    projectId: string | undefined,
    scanId: string | undefined
  ) => void;
  setExtensionSettings: (categories: NullableArray<Category>) => void;
  setAuthenticationSettings: (
    authentications: Array<AuthenticationSettings>
  ) => void;
  setDictionarySettings: (entries: NullableArray<DictionaryEntry>) => void;
  setPathExclusionSettings: (paths: NullableArray<PathExclusionEntry>) => void;
  setIssueExclusionSettings: (issues: NullableArray<IssueExclusion>) => void;
  setInjectableParametersSettings: (
    injectables: NullableArray<Injectable>
  ) => void;
  setMTSLCertificateSettings: (
    mTLSCertificates: NullableArray<MTLSCertificate>
  ) => void;
  setAdvancedSettings: (advancedSettings: AdvancedSettings) => void;
  setLoading: (loading: boolean) => void;
  reset: VoidFunction;
};

const initialState: SettingsStoreProperties = {
  dataLoaded: false,
  organizationId: undefined,
  projectId: undefined,
  scanId: undefined,
  extensionSettings: {
    categories: null,
  },
  authenticationSettings: null,
  dictionarySettings: {
    entries: null,
  },
  pathExclusionSettings: {
    paths: null,
  },
  issueExclusionSettings: {
    issues: null,
  },
  injectableParametersSettings: {
    injectables: null,
  },
  mTLSCertificatesSettings: {
    mTLSCertificates: null,
  },
  advancedSettings: null,
  loading: true,
};

export const useSettingsStore = createStore<SettingsState>((get, set) => ({
  ...initialState,
  setDataLoaded: dataLoaded => {
    set(state => ({
      ...state,
      dataLoaded,
    }));
  },
  setUriParameters: (
    organizationId: string | undefined,
    projectId: string | undefined,
    scanId: string | undefined
  ) =>
    set(state => ({
      ...state,
      organizationId: organizationId,
      projectId,
      scanId,
    })),
  setExtensionSettings: categories =>
    set(state => ({
      ...state,
      extensionSettings: { ...state.extensionSettings, categories },
    })),
  setAuthenticationSettings: authentications =>
    set(state => {
      const authenticationSettings: Array<AuthenticationSettingsFormModel> = [];
      const { injectableParametersSettings, mTLSCertificatesSettings } = state;

      authentications.forEach(authenticationSetting => {
        const thisInjectables = (
          injectableParametersSettings.injectables ?? []
        ).filter(i => i.associatedAuthSetting === authenticationSetting.name);
        const thisMtlsCertificates = (
          mTLSCertificatesSettings.mTLSCertificates ?? []
        ).filter(m => m.associatedAuthSetting === authenticationSetting.name);

        authenticationSettings.push({
          authenticationSettings: authenticationSetting,
          injectables: thisInjectables ?? [],
          mTLSCertificates: thisMtlsCertificates ?? [],
        });
      });

      return {
        ...state,
        authenticationSettings,
      };
    }),
  setDictionarySettings: entries =>
    set(state => ({
      ...state,
      dictionarySettings: { ...state.dictionarySettings, entries },
    })),
  setPathExclusionSettings: paths =>
    set(state => ({
      ...state,
      pathExclusionSettings: { ...state.pathExclusionSettings, paths },
    })),
  setIssueExclusionSettings: issues =>
    set(state => ({
      ...state,
      issueExclusionSettings: { ...state.issueExclusionSettings, issues },
    })),
  setInjectableParametersSettings: injectables =>
    set(state => ({
      ...state,
      injectableParametersSettings: {
        ...state.injectableParametersSettings,
        injectables,
      },
    })),
  setMTSLCertificateSettings: mTLSCertificates =>
    set(state => ({
      ...state,
      mTLSCertificatesSettings: {
        ...state.mTLSCertificatesSettings,
        mTLSCertificates,
      },
    })),
  setAdvancedSettings: advancedSettings =>
    set(state => ({ ...state, advancedSettings })),
  setLoading: loading => set(state => ({ ...state, loading })),
  reset: () => set(state => ({ ...state, ...initialState })),
}));
