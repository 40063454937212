import {
  DisplayRowProjectIssueExclusion,
  SettingsContainer,
} from "@Components";
import { memo } from "react";
import { IssueExclusionEditCanvas } from "./IssueExclusionEditCanvas";
import { useIssueExclusion } from "./useIssueExclusion";

type IssueExclusionProps = {
  refresh: () => Promise<void>;
};
export const IssueExclusion = memo(({ refresh }: IssueExclusionProps) => {
  const { editIndex, actions, canvas, loading, issueExclusions } =
    useIssueExclusion(refresh);

  return (
    <div id="issue-exclusion">
      <SettingsContainer loading={loading} section="issue-exclusion">
        <DisplayRowProjectIssueExclusion
          issueExclusions={issueExclusions}
          deleteEntry={actions.deleteEntry}
          editEntry={actions.editEntry}
        />
      </SettingsContainer>
      <IssueExclusionEditCanvas
        isOpen={canvas.isOpen}
        close={canvas.close}
        issueExclusion={
          editIndex !== undefined ? issueExclusions[editIndex] : undefined
        }
        refresh={refresh}
      />
    </div>
  );
});
