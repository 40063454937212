import { ButtonBar, ConfirmDialog, GridContainer } from "@Components";
import {
  KA_NEW_PROJECT_ADD_ACTION,
  KA_NEW_PROJECT_CANCEL_ACTION,
} from "@Constants";
import { t } from "i18next";
import { memo, useState } from "react";
import { ProjectNameLabel } from "./Components";
import useBottomBar from "./useBottomBar";
import { useContinueButtonDisabled } from "./useContinueButtonDisabled";

export const BottomBar = memo(() => {
  const { actions, navigation, project, status } = useBottomBar();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const { disabled: continueButtonDisabled, error: continueButtonError } =
    useContinueButtonDisabled();

  return (
    <div id="bottom-bar">
      {continueButtonError && (
        <div className="next-disabled-tip">
          <i className="bi bi-exclamation-diamond-fill me-2"></i>
          <span>{t(continueButtonError)}</span>
        </div>
      )}
      <GridContainer>
        <ProjectNameLabel name={project.name} />
        <ButtonBar
          buttons={[
            {
              onMouseDown: () => setShowConfirmDialog(true),
              color: "transparent",
              children: "common.cancel",
              data: { cy: "btn-cancel" },
              iconClass: "bi bi-x-square-fill",
              className: "btn-cancel",
              keyAction: KA_NEW_PROJECT_CANCEL_ACTION,
            },
            {
              show: !navigation.isFirstPage,
              onMouseDown: navigation.previousPage,
              color: "secondary",
              children: "common.previous",
              data: { cy: "btn-previous" },
              iconClass: "bi bi-arrow-left-square-fill",
            },
            {
              show: !navigation.isLastPage,
              onMouseDown: navigation.nextPage,
              color: "primary",
              disabled: continueButtonDisabled,
              children: "common.next",
              data: { cy: "btn-next" },
              iconClass: "bi bi-arrow-right-square-fill",
              className: "btn-next",
            },
            {
              show: navigation.isLastPage,
              onMouseDown: actions.onCreate,
              color: "primary",
              loading: status.loading,
              children: "common.add",
              data: { cy: "btn-add" },
              iconClass: "bi bi-floppy-fill",
              keyAction: KA_NEW_PROJECT_ADD_ACTION,
            },
          ]}
        />
      </GridContainer>

      {showConfirmDialog && (
        <ConfirmDialog
          title={"new-project.confirm.cancel.title"}
          description={"new-project.confirm.cancel.description"}
          cancelLabel={"new-project.confirm.cancel.cancel"}
          confirmLabel={"new-project.confirm.cancel.confirm"}
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={actions.onCancel}
        />
      )}
    </div>
  );
});
