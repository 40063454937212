import { memo, type FC } from "react";
import type { SpaceLinksListingContentProps } from "./SpaceLinksListingContentProps.i";
import { Paginator, SpaceLinkFilters, Section } from "@Components";
import { SpaceLinkTable } from "../SpaceLinkTable/SpaceLinkTable";
import isEqual from "react-fast-compare";

export const SpaceLinkListingContent: FC<SpaceLinksListingContentProps> = memo(
  ({
    spaceLinks,
    fetch,
    updateSearchFilter,
    loading,
    totalItems,
    selectedSpaceLinkID,
    projectSpaceLinkID,
    handleSpaceLinkSelect,
  }) => {
    return (
      <>
        <Section>
          <div className="d-flex justify-content-between align-items-end">
            <SpaceLinkFilters
              refresh={fetch}
              updateFilters={updateSearchFilter}
              className="p-normal"
            />
          </div>
        </Section>
        <SpaceLinkTable
          spaceLinks={spaceLinks}
          loading={loading}
          selectedSpaceLinkID={selectedSpaceLinkID}
          projectSpaceLinkID={projectSpaceLinkID}
          updateFiltersFunction={updateSearchFilter}
          handleSpaceLinkSelect={handleSpaceLinkSelect}
        />
        <Paginator totalItems={totalItems} setFilters={updateSearchFilter} />
      </>
    );
  },
  isEqual
);
