import type { Log, UserId } from "@Interfaces";
import moment from "moment-timezone";

const userAuditLogsMap = new Map<UserId, Log[]>();

userAuditLogsMap.set(
  "11",
  Array.from({ length: 4 }, (_, i) => ({
    createdAt: moment().subtract(i, "days").toISOString(),
    id: `10-${i}`,
    ipAddress: "192.168.1.1",
    method: "",
    path: "/v1/login",
    requesterRole: "",
    userAgent: `Mozilla/5.0 (Windows NT 6.1; WOW64; rv:54.0) Gecko/20100101 Firefox/54.0`,
    userID: "11",
    resourceID: "",
    status: 0,
    organization_id: "1",
  }))
);

userAuditLogsMap.set(
  "12",
  Array.from({ length: 15 }, (_, i) => ({
    createdAt: moment().subtract(i, "days").toISOString(),
    id: `11-${i}`,
    ipAddress: "192.168.1.1",
    method: "",
    path: "/v1/login",
    requesterRole: "",
    userAgent: `Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/602.3.12 (KHTML, like Gecko) Version/10.1.2 Safari/602.3.12`,
    userID: "12",
    resourceID: "",
    status: 0,
    organization_id: "1",
  }))
);

export const userAuditLogs = userAuditLogsMap;
