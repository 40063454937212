import { useExtensionSettings as useApiExtensionSettings } from "@Hooks";
import type { Category } from "@Services";
import { useCallback, useEffect } from "react";
import { useProjectSettingsStore } from "../ProjectSettings.store";

export const useExtensionSettings = () => {
  const { extensionSettings, loading, setExtensionsCategories, setLoading } =
    useProjectSettingsStore();
  const {
    response,
    update,
    fetch,
    loading: apiExtensionSettingsLoading,
  } = useApiExtensionSettings();

  useEffect(() => {
    setExtensionsCategories(response?.enabledCategories ?? []);
  }, [response, setExtensionsCategories]);

  useEffect(() => {
    setLoading(apiExtensionSettingsLoading);
  }, [apiExtensionSettingsLoading, setLoading]);

  const handleSetExtensionSettings = useCallback(
    (newCategories: Category[]) => {
      setExtensionsCategories(newCategories);
      const promise = update({
        extensionSettings: {
          enabledCategories: newCategories,
        },
      });
      promise.then(ok => {
        ok || fetch(); // something wrong. fetch latest api status
      }, fetch);
    },
    [fetch, setExtensionsCategories, update]
  );

  return {
    extensionSettings,
    setExtensionSettings: handleSetExtensionSettings,
    loading,
  };
};
