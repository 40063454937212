import { memo, type FC } from "react";
import type { SpaceLinksListingContentProps } from "./SpaceLinksListingContentProps.i";
import { SpaceLinksErrorPage } from "..";
import { Paginator, SpaceLinkFilters, Section } from "@Components";
import { SpaceLinkTable } from "../SpaceLinkTable/SpaceLinkTable";
import isEqual from "react-fast-compare";
import { NoSpaceLinks } from "..";
import { OpenCanvasButton } from "../OpenCanvasButton";

export const SpaceLinkListingContent: FC<SpaceLinksListingContentProps> = memo(
  ({
    spaceLinks,
    fetch,
    updateSearchFilter,
    loading,
    totalItems,
    hasAnyItem,
    hasError,
  }) => {
    if (hasError) {
      return <SpaceLinksErrorPage />;
    }

    if (!loading && !hasAnyItem) {
      return <NoSpaceLinks />;
    }

    return (
      <>
        <Section title="organization.space-links">
          <div className="d-flex justify-content-between align-items-end">
            <SpaceLinkFilters
              refresh={fetch}
              updateFilters={updateSearchFilter}
            />
            <OpenCanvasButton label="common.create" />
          </div>
        </Section>
        <SpaceLinkTable
          spaceLinks={spaceLinks}
          loading={loading}
          updateFiltersFunction={updateSearchFilter}
        />
        <Paginator totalItems={totalItems} setFilters={updateSearchFilter} />
      </>
    );
  },
  isEqual
);
