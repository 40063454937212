export const getCurrentOS = () => {
  const { platform, userAgent } = navigator;
  const platformUpper = platform.toUpperCase();
  const userAgentUpper = userAgent.toUpperCase();

  if (platformUpper.includes("MAC")) return "MAC";
  if (platformUpper.includes("WIN")) return "WINDOWS";
  if (platformUpper.includes("LINUX") || userAgentUpper.includes("LINUX"))
    return "LINUX";
  if (userAgentUpper.includes("ANDROID")) return "ANDROID";
  if (/IPHONE|IPAD|IPOD/.test(userAgentUpper)) return "IOS";
  return "OTHER";
};

export const CLIENT_OS = getCurrentOS();
