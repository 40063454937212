import { MAINTENANCE_PATH } from "@Constants";
import {
  getMaintenanceModeEnd,
  getMaintenanceModeLockEnd,
  getMaintenanceModeLockStart,
  getMaintenanceModeStart,
} from "@Services";
import { useMaintenanceModeStore } from "@Stores";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useMaintenanceModeManager = () => {
  const { maintenanceModeInProgress, setMaintenanceModeRanges } =
    useMaintenanceModeStore();

  const navigate = useNavigate();
  const location = useLocation();

  const updateMaintenanceMode = useCallback(() => {
    setMaintenanceModeRanges(
      getMaintenanceModeStart(),
      getMaintenanceModeEnd(),
      getMaintenanceModeLockStart(),
      getMaintenanceModeLockEnd()
    );
  }, [setMaintenanceModeRanges]);

  useEffect(() => {
    if (
      maintenanceModeInProgress &&
      !location.pathname.endsWith(MAINTENANCE_PATH)
    ) {
      navigate(MAINTENANCE_PATH);
    }
    updateMaintenanceMode();
  }, [maintenanceModeInProgress, location, navigate, updateMaintenanceMode]);

  useEffect(() => {
    updateMaintenanceMode();
  }, [setMaintenanceModeRanges, updateMaintenanceMode]);
};
