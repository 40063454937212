import React from "react";
import { Table as BootstrapTable } from "reactstrap";
import type { Props } from "./Table.i";
import { TBody, THead } from "./Components";
import { getClassName } from "./functions";

import "./styles.scss";

export const Table = React.memo(
  ({
    headers = [],
    children,
    columnSizes,
    columnDistribution = "auto",
    translateHeaders = true,
    striped = true,
    className,
    showSkeleton = false,
    skeletonRowCount = 12,
    noDataMessage,
  }: Props) => (
    <div className="table-container">
      <BootstrapTable
        borderless={true}
        striped={false}
        className={`main-table ${getClassName({ className, showSkeleton })}`}
      >
        <THead
          headers={headers}
          columnDistribution={columnDistribution}
          columnSizes={columnSizes}
          translateHeaders={translateHeaders}
          showSkeleton={showSkeleton}
        />
        <TBody
          striped={striped}
          children={children}
          colSpan={headers.length}
          noDataMessage={noDataMessage}
          showSkeleton={showSkeleton}
          skeletonRowCount={skeletonRowCount}
        />
      </BootstrapTable>
    </div>
  )
);

export { type Props as TableProps };
