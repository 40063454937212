/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserCreateIssueExclusionRequest } from '../model';
// @ts-ignore
import { UserGetProjectAdvancedSettingsResponse } from '../model';
// @ts-ignore
import { UserGetProjectExtensionSettingsResponse } from '../model';
// @ts-ignore
import { UserGetScanAdvancedSettingsResponse } from '../model';
// @ts-ignore
import { UserGetScanExtensionSettingsResponse } from '../model';
// @ts-ignore
import { UserListIssueExclusionsInProjectResponse } from '../model';
// @ts-ignore
import { UserListIssueExclusionsInScanResponse } from '../model';
// @ts-ignore
import { UserListProjectAuthenticationSettingsResponse } from '../model';
// @ts-ignore
import { UserListProjectDictionaryResponse } from '../model';
// @ts-ignore
import { UserListProjectInjectablesResponse } from '../model';
// @ts-ignore
import { UserListProjectMTLSCertificatesResponse } from '../model';
// @ts-ignore
import { UserListProjectParameterLinksResponse } from '../model';
// @ts-ignore
import { UserListScanAuthenticationSettingsResponse } from '../model';
// @ts-ignore
import { UserListScanDictionaryResponse } from '../model';
// @ts-ignore
import { UserListScanInjectablesResponse } from '../model';
// @ts-ignore
import { UserListScanMTLSCertificatesResponse } from '../model';
// @ts-ignore
import { UserListScanPathExclusionsResponse } from '../model';
// @ts-ignore
import { UserUpdateExtensionSettingsRequest } from '../model';
// @ts-ignore
import { UserUpdateIssueExclusionRequest } from '../model';
/**
 * ScanSettingsApi - axios parameter creator
 * @export
 */
export const ScanSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the advanced settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAdvancedSettings: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getProjectAdvancedSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectAdvancedSettings', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/advanced-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the current extension settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectExtensionSettings: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getProjectExtensionSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectExtensionSettings', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/extension-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the advanced settings for the specified scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScanAdvancedSettings: async (organizationId: string, projectId: string, scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getScanAdvancedSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getScanAdvancedSettings', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScanAdvancedSettings', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/advanced-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the extension settings used for the specified scan, caching the result
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScanExtensionSettings: async (organizationId: string, projectId: string, scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getScanExtensionSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getScanExtensionSettings', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScanExtensionSettings', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/extension-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new issue exclusion in the organization and project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserCreateIssueExclusionRequest} requestBody UserCreateIssueExclusionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueExclusionCreate: async (organizationId: string, projectId: string, requestBody: UserCreateIssueExclusionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueExclusionCreate', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueExclusionCreate', 'projectId', projectId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('issueExclusionCreate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/issue-exclusions`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the project with the given project id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} issueExclusionId Issue Exclusion ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueExclusionDelete: async (organizationId: string, projectId: string, issueExclusionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueExclusionDelete', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueExclusionDelete', 'projectId', projectId)
            // verify required parameter 'issueExclusionId' is not null or undefined
            assertParamExists('issueExclusionDelete', 'issueExclusionId', issueExclusionId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/issue-exclusions/{issue_exclusion_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"issue_exclusion_id"}}`, encodeURIComponent(String(issueExclusionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} issueExclusionId Issue Exclusion ID
         * @param {UserUpdateIssueExclusionRequest} requestBody UserUpdateIssueExclusionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueExclusionUpdate: async (organizationId: string, projectId: string, issueExclusionId: string, requestBody: UserUpdateIssueExclusionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueExclusionUpdate', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueExclusionUpdate', 'projectId', projectId)
            // verify required parameter 'issueExclusionId' is not null or undefined
            assertParamExists('issueExclusionUpdate', 'issueExclusionId', issueExclusionId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('issueExclusionUpdate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/issue-exclusions/{issue_exclusion_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"issue_exclusion_id"}}`, encodeURIComponent(String(issueExclusionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the authentication settings for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectAuthenticationSettings: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listProjectAuthenticationSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectAuthenticationSettings', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/authentication-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the dictionary for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectDictionary: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listProjectDictionary', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectDictionary', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/dictionary`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the injectables for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectInjectables: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listProjectInjectables', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectInjectables', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/injectables`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the issue exclusions for the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectIssueExclusions: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listProjectIssueExclusions', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectIssueExclusions', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/issue-exclusions`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the MTLS certificates for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectMtlsCertificates: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listProjectMtlsCertificates', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectMtlsCertificates', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/mtls-certificates`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the parameter links for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectParameterLinks: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listProjectParameterLinks', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectParameterLinks', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/parameter-links`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the authentication settings for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanAuthenticationSettings: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanAuthenticationSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanAuthenticationSettings', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanAuthenticationSettings', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/authentication-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the dictionary for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanDictionary: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanDictionary', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanDictionary', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanDictionary', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/dictionary`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the injectables for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanInjectables: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanInjectables', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanInjectables', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanInjectables', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/injectables`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the issue exclusions in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanIssueExclusions: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanIssueExclusions', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanIssueExclusions', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanIssueExclusions', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/issue-exclusions`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the MTLS certificates for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanMtlsCertificates: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanMtlsCertificates', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanMtlsCertificates', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanMtlsCertificates', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/mtls-certificates`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the parameter links for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanParameterLinks: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanParameterLinks', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanParameterLinks', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanParameterLinks', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/parameter-links`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the path exclusions for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'path_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanPathExclusions: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'path_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listScanPathExclusions', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listScanPathExclusions', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('listScanPathExclusions', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/path-exclusions`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the extension settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserUpdateExtensionSettingsRequest} requestBody UserUpdateExtensionSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtensionSettings: async (organizationId: string, projectId: string, requestBody: UserUpdateExtensionSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateExtensionSettings', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateExtensionSettings', 'projectId', projectId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateExtensionSettings', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/extension-settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScanSettingsApi - functional programming interface
 * @export
 */
export const ScanSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScanSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the advanced settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectAdvancedSettings(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetProjectAdvancedSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectAdvancedSettings(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the current extension settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectExtensionSettings(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetProjectExtensionSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectExtensionSettings(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the advanced settings for the specified scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScanAdvancedSettings(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetScanAdvancedSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScanAdvancedSettings(organizationId, projectId, scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the extension settings used for the specified scan, caching the result
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScanExtensionSettings(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetScanExtensionSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScanExtensionSettings(organizationId, projectId, scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new issue exclusion in the organization and project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserCreateIssueExclusionRequest} requestBody UserCreateIssueExclusionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueExclusionCreate(organizationId: string, projectId: string, requestBody: UserCreateIssueExclusionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueExclusionCreate(organizationId, projectId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the project with the given project id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} issueExclusionId Issue Exclusion ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueExclusionDelete(organizationId: string, projectId: string, issueExclusionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueExclusionDelete(organizationId, projectId, issueExclusionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} issueExclusionId Issue Exclusion ID
         * @param {UserUpdateIssueExclusionRequest} requestBody UserUpdateIssueExclusionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueExclusionUpdate(organizationId: string, projectId: string, issueExclusionId: string, requestBody: UserUpdateIssueExclusionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueExclusionUpdate(organizationId, projectId, issueExclusionId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the authentication settings for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectAuthenticationSettings(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectAuthenticationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectAuthenticationSettings(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the dictionary for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectDictionary(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectDictionary(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the injectables for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectInjectables(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectInjectablesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectInjectables(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the issue exclusions for the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectIssueExclusions(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListIssueExclusionsInProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectIssueExclusions(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the MTLS certificates for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectMtlsCertificates(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectMTLSCertificatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectMtlsCertificates(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the parameter links for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectParameterLinks(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectParameterLinksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectParameterLinks(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the authentication settings for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanAuthenticationSettings(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScanAuthenticationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanAuthenticationSettings(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the dictionary for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanDictionary(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScanDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanDictionary(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the injectables for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanInjectables(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScanInjectablesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanInjectables(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the issue exclusions in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanIssueExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListIssueExclusionsInScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanIssueExclusions(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the MTLS certificates for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanMtlsCertificates(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScanMTLSCertificatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanMtlsCertificates(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the parameter links for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanParameterLinks(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectParameterLinksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanParameterLinks(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists the path exclusions for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'path_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScanPathExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'path_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScanPathExclusionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScanPathExclusions(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the extension settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserUpdateExtensionSettingsRequest} requestBody UserUpdateExtensionSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExtensionSettings(organizationId: string, projectId: string, requestBody: UserUpdateExtensionSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetScanExtensionSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExtensionSettings(organizationId, projectId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScanSettingsApi - factory interface
 * @export
 */
export const ScanSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScanSettingsApiFp(configuration)
    return {
        /**
         * Gets the advanced settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAdvancedSettings(organizationId: string, projectId: string, options?: any): AxiosPromise<UserGetProjectAdvancedSettingsResponse> {
            return localVarFp.getProjectAdvancedSettings(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the current extension settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectExtensionSettings(organizationId: string, projectId: string, options?: any): AxiosPromise<UserGetProjectExtensionSettingsResponse> {
            return localVarFp.getProjectExtensionSettings(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the advanced settings for the specified scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScanAdvancedSettings(organizationId: string, projectId: string, scanId: string, options?: any): AxiosPromise<UserGetScanAdvancedSettingsResponse> {
            return localVarFp.getScanAdvancedSettings(organizationId, projectId, scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the extension settings used for the specified scan, caching the result
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScanExtensionSettings(organizationId: string, projectId: string, scanId: string, options?: any): AxiosPromise<UserGetScanExtensionSettingsResponse> {
            return localVarFp.getScanExtensionSettings(organizationId, projectId, scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new issue exclusion in the organization and project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserCreateIssueExclusionRequest} requestBody UserCreateIssueExclusionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueExclusionCreate(organizationId: string, projectId: string, requestBody: UserCreateIssueExclusionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.issueExclusionCreate(organizationId, projectId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the project with the given project id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} issueExclusionId Issue Exclusion ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueExclusionDelete(organizationId: string, projectId: string, issueExclusionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.issueExclusionDelete(organizationId, projectId, issueExclusionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} issueExclusionId Issue Exclusion ID
         * @param {UserUpdateIssueExclusionRequest} requestBody UserUpdateIssueExclusionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueExclusionUpdate(organizationId: string, projectId: string, issueExclusionId: string, requestBody: UserUpdateIssueExclusionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.issueExclusionUpdate(organizationId, projectId, issueExclusionId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the authentication settings for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectAuthenticationSettings(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectAuthenticationSettingsResponse> {
            return localVarFp.listProjectAuthenticationSettings(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the dictionary for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectDictionary(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectDictionaryResponse> {
            return localVarFp.listProjectDictionary(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the injectables for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectInjectables(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectInjectablesResponse> {
            return localVarFp.listProjectInjectables(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the issue exclusions for the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectIssueExclusions(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListIssueExclusionsInProjectResponse> {
            return localVarFp.listProjectIssueExclusions(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the MTLS certificates for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectMtlsCertificates(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectMTLSCertificatesResponse> {
            return localVarFp.listProjectMtlsCertificates(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the parameter links for the specified project, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectParameterLinks(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectParameterLinksResponse> {
            return localVarFp.listProjectParameterLinks(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the authentication settings for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanAuthenticationSettings(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScanAuthenticationSettingsResponse> {
            return localVarFp.listScanAuthenticationSettings(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the dictionary for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanDictionary(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScanDictionaryResponse> {
            return localVarFp.listScanDictionary(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the injectables for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanInjectables(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScanInjectablesResponse> {
            return localVarFp.listScanInjectables(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the issue exclusions in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanIssueExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListIssueExclusionsInScanResponse> {
            return localVarFp.listScanIssueExclusions(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the MTLS certificates for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanMtlsCertificates(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScanMTLSCertificatesResponse> {
            return localVarFp.listScanMtlsCertificates(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the parameter links for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanParameterLinks(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectParameterLinksResponse> {
            return localVarFp.listScanParameterLinks(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the path exclusions for the specified scan, according to pagination and filters
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'path_exclusion_path'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScanPathExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'path_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScanPathExclusionsResponse> {
            return localVarFp.listScanPathExclusions(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the extension settings for the specified project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserUpdateExtensionSettingsRequest} requestBody UserUpdateExtensionSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtensionSettings(organizationId: string, projectId: string, requestBody: UserUpdateExtensionSettingsRequest, options?: any): AxiosPromise<UserGetScanExtensionSettingsResponse> {
            return localVarFp.updateExtensionSettings(organizationId, projectId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScanSettingsApi - interface
 * @export
 * @interface ScanSettingsApi
 */
export interface ScanSettingsApiInterface {
    /**
     * Gets the advanced settings for the specified project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    getProjectAdvancedSettings(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetProjectAdvancedSettingsResponse>;

    /**
     * Gets the current extension settings for the specified project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    getProjectExtensionSettings(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetProjectExtensionSettingsResponse>;

    /**
     * Gets the advanced settings for the specified scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    getScanAdvancedSettings(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetScanAdvancedSettingsResponse>;

    /**
     * Gets the extension settings used for the specified scan, caching the result
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    getScanExtensionSettings(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetScanExtensionSettingsResponse>;

    /**
     * Creates a new issue exclusion in the organization and project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserCreateIssueExclusionRequest} requestBody UserCreateIssueExclusionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    issueExclusionCreate(organizationId: string, projectId: string, requestBody: UserCreateIssueExclusionRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Deletes the project with the given project id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} issueExclusionId Issue Exclusion ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    issueExclusionDelete(organizationId: string, projectId: string, issueExclusionId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} issueExclusionId Issue Exclusion ID
     * @param {UserUpdateIssueExclusionRequest} requestBody UserUpdateIssueExclusionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    issueExclusionUpdate(organizationId: string, projectId: string, issueExclusionId: string, requestBody: UserUpdateIssueExclusionRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Lists the authentication settings for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listProjectAuthenticationSettings(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectAuthenticationSettingsResponse>;

    /**
     * Lists the dictionary for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listProjectDictionary(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectDictionaryResponse>;

    /**
     * Lists the injectables for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listProjectInjectables(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectInjectablesResponse>;

    /**
     * Returns the issue exclusions for the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listProjectIssueExclusions(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListIssueExclusionsInProjectResponse>;

    /**
     * Lists the MTLS certificates for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listProjectMtlsCertificates(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectMTLSCertificatesResponse>;

    /**
     * Lists the parameter links for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listProjectParameterLinks(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectParameterLinksResponse>;

    /**
     * Lists the authentication settings for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanAuthenticationSettings(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScanAuthenticationSettingsResponse>;

    /**
     * Lists the dictionary for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanDictionary(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScanDictionaryResponse>;

    /**
     * Lists the injectables for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanInjectables(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScanInjectablesResponse>;

    /**
     * Returns the issue exclusions in the scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanIssueExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListIssueExclusionsInScanResponse>;

    /**
     * Lists the MTLS certificates for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanMtlsCertificates(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScanMTLSCertificatesResponse>;

    /**
     * Lists the parameter links for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanParameterLinks(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectParameterLinksResponse>;

    /**
     * Lists the path exclusions for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'path_exclusion_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    listScanPathExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'path_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScanPathExclusionsResponse>;

    /**
     * Updates the extension settings for the specified project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserUpdateExtensionSettingsRequest} requestBody UserUpdateExtensionSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApiInterface
     */
    updateExtensionSettings(organizationId: string, projectId: string, requestBody: UserUpdateExtensionSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<UserGetScanExtensionSettingsResponse>;

}

/**
 * ScanSettingsApi - object-oriented interface
 * @export
 * @class ScanSettingsApi
 * @extends {BaseAPI}
 */
export class ScanSettingsApi extends BaseAPI implements ScanSettingsApiInterface {
    /**
     * Gets the advanced settings for the specified project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public getProjectAdvancedSettings(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).getProjectAdvancedSettings(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the current extension settings for the specified project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public getProjectExtensionSettings(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).getProjectExtensionSettings(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the advanced settings for the specified scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public getScanAdvancedSettings(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).getScanAdvancedSettings(organizationId, projectId, scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the extension settings used for the specified scan, caching the result
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public getScanExtensionSettings(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).getScanExtensionSettings(organizationId, projectId, scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new issue exclusion in the organization and project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserCreateIssueExclusionRequest} requestBody UserCreateIssueExclusionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public issueExclusionCreate(organizationId: string, projectId: string, requestBody: UserCreateIssueExclusionRequest, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).issueExclusionCreate(organizationId, projectId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the project with the given project id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} issueExclusionId Issue Exclusion ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public issueExclusionDelete(organizationId: string, projectId: string, issueExclusionId: string, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).issueExclusionDelete(organizationId, projectId, issueExclusionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} issueExclusionId Issue Exclusion ID
     * @param {UserUpdateIssueExclusionRequest} requestBody UserUpdateIssueExclusionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public issueExclusionUpdate(organizationId: string, projectId: string, issueExclusionId: string, requestBody: UserUpdateIssueExclusionRequest, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).issueExclusionUpdate(organizationId, projectId, issueExclusionId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the authentication settings for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listProjectAuthenticationSettings(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listProjectAuthenticationSettings(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the dictionary for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listProjectDictionary(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listProjectDictionary(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the injectables for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listProjectInjectables(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listProjectInjectables(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the issue exclusions for the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listProjectIssueExclusions(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listProjectIssueExclusions(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the MTLS certificates for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listProjectMtlsCertificates(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listProjectMtlsCertificates(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the parameter links for the specified project, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listProjectParameterLinks(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listProjectParameterLinks(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the authentication settings for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'authentication_settings_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanAuthenticationSettings(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_settings_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanAuthenticationSettings(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the dictionary for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'dictionary_entry_key'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanDictionary(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'dictionary_entry_key', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanDictionary(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the injectables for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_injectable_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanInjectables(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_injectable_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanInjectables(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the issue exclusions in the scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_exclusion_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanIssueExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_exclusion_false_positive' | 'issue_exclusion_extension_name' | 'issue_exclusion_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanIssueExclusions(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the MTLS certificates for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'mtls_certificate_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanMtlsCertificates(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'mtls_certificate_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanMtlsCertificates(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the parameter links for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanParameterLinks(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'user_parameter_link_entry_consuming_operation_verb' | 'user_parameter_link_entry_consuming_operation_uri' | 'user_parameter_link_entry_producing_operation_verb' | 'user_parameter_link_entry_producing_operation_uri', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanParameterLinks(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the path exclusions for the specified scan, according to pagination and filters
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'path_exclusion_path'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public listScanPathExclusions(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'path_exclusion_path', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).listScanPathExclusions(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the extension settings for the specified project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserUpdateExtensionSettingsRequest} requestBody UserUpdateExtensionSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanSettingsApi
     */
    public updateExtensionSettings(organizationId: string, projectId: string, requestBody: UserUpdateExtensionSettingsRequest, options?: AxiosRequestConfig) {
        return ScanSettingsApiFp(this.configuration).updateExtensionSettings(organizationId, projectId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
