import type { AuthMatrixResponse } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAuthorizationMatrix } from "src/Mock";

export const getScanAuthorizationMatrix = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;

  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const sortMode = sP.get("sort_mode");
  const sortBy = sP.get("sort_by");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  const { organizationId, projectId, scanId } = req.params as Record<
    string,
    string
  >;

  if (!organizationId || !projectId || !scanId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const { operations, profiles } = getAuthorizationMatrix();

  const offset = (page - 1) * size;
  const limit = offset + size;

  const responseData = operations
    .filter(a => {
      if (!filters?.auth_matrix_verb) return true;
      return a.verb === filters.auth_matrix_verb;
    })
    .filter(a => {
      if (!filters?.auth_matrix_path) return true;
      return a.path
        .toLowerCase()
        .includes(filters.auth_matrix_path.toLowerCase());
    });

  if (sortBy === "auth_matrix_path") {
    responseData.sort((a, b) => {
      return a.path.localeCompare(b.path) * (sortMode === "asc" ? -1 : 1);
    });
  }

  if (sortBy === "auth_matrix_category") {
    responseData.sort((a, b) => {
      return (
        a.predicted_category_semantics.localeCompare(b.path) *
        (sortMode === "asc" ? -1 : 1)
      );
    });
  }

  const data: AuthMatrixResponse = {
    operations: responseData.slice(offset, limit),
    profiles,
  };

  return res(
    ctx.delay(delay),
    ctx.status(200),
    ctx.json({
      data,
      page,
      size,
      totalItems: responseData.length,
      totalPages: Math.ceil(responseData.length / size),
    })
  );
};
