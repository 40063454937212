import "./style.scss";
import type { MTLSCertificate } from "@Services";
import {
  DisplaySection,
  TrashButton,
  Accordion,
  type AccordionProps,
  ConfirmDialog,
} from "@Components";
import { useMemo, useState } from "react";

export const MTLSCertificateCard = (props: {
  mTLSCertificate: MTLSCertificate;
  onDelete?: VoidFunction;
  loading?: boolean;
  disabled?: boolean;
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const AccordionComponent = useMemo(() => {
    const accordionPropsData: AccordionProps["data"] = [];

    if (props.mTLSCertificate.clientKey) {
      accordionPropsData.push({
        children: <pre>{props.mTLSCertificate.clientKey}</pre>,
        header: "common.private-key",
        key: "private-key",
      });
    }

    if (props.mTLSCertificate.clientCert) {
      accordionPropsData.push({
        children: <pre>{props.mTLSCertificate.clientCert}</pre>,
        header: "common.client-certificate",
        key: "client-certificate",
      });
    }

    if (props.mTLSCertificate.caCert) {
      accordionPropsData.push({
        children: <pre>{props.mTLSCertificate.caCert}</pre>,
        header: "common.certificate-authority",
        key: "certificate-authority",
      });
    }
    return <Accordion data={accordionPropsData} />;
  }, [props.mTLSCertificate]);

  return (
    <div className="mtls-certificate-card editable-card">
      <DisplaySection
        label="common.name"
        value={props.mTLSCertificate.name}
        className="mb-3"
      />
      {AccordionComponent}
      {props.onDelete && (
        <div className="card-actions">
          <TrashButton
            onClick={() => setShowConfirmDialog(true)}
            disabled={props.disabled}
            loading={props.loading}
          />
          {showConfirmDialog && (
            <ConfirmDialog
              title="confirmation-dialog.title.delete-configuration"
              description="confirmation-dialog.description.delete-configuration"
              cancelLabel="confirmation-dialog.cancel.delete-configuration"
              confirmLabel="confirmation-dialog.confirm.delete-configuration"
              onCancel={() => setShowConfirmDialog(false)}
              onConfirm={() => {
                setShowConfirmDialog(false);
                props.onDelete?.();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
