import { ProjectSettingsCardContainer, AuthenticationCard } from "@Components";
import { pureComponent } from "@Utils";
import { memo } from "react";
import { useAuthenticationCards } from "../Hooks";

export const AuthenticationCards = memo(() => {
  const {
    authenticationSettings,
    handleAuthenticationSetting,
    handleAuthenticationClone,
    handleAuthenticationEdit,
  } = useAuthenticationCards();

  return (
    <ProjectSettingsCardContainer
      cardType="authentication"
      emptyLabel="project.settings.no-auth-settings"
    >
      {authenticationSettings.map((a, i) => (
        <AuthenticationCard
          authenticationSettings={a.authenticationSettings}
          onEdit={() => handleAuthenticationEdit(i)}
          onClone={() => handleAuthenticationClone(i)}
          onDelete={() => handleAuthenticationSetting(i)}
          injectables={a.injectables ?? []}
          mtlsCertificates={a.mTLSCertificates ?? []}
          key={`card-${i}`}
        />
      ))}
    </ProjectSettingsCardContainer>
  );
}, pureComponent);
