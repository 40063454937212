import type { NewProjectData } from "@Interfaces";
import { projectMaxEndDate } from "@Services";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useCallback, useState } from "react";

const makeEmptyProject = (): NewProjectData => ({
  advancedSettings: undefined,
  authenticationSettings: [],
  baseURL: "",
  description: "",
  dictionary: [],
  injectables: [],
  mTLSCertificates: [],
  isProductionURL: false,
  name: "",
  oasUploadData: [],
  pathExclusion: [],
  runPolicy: {
    businessHours: false,
    endDate: projectMaxEndDate,
    recurrence: "none",
    startDate: projectMaxEndDate,
  },
  extensionSettings: {
    enabledCategories: [],
  },
});

export const NewProjectContext = createContext({
  project: makeEmptyProject(),
  setProject: (() => null) as Dispatch<SetStateAction<NewProjectData>>,
  reset: () => {
    /* do nothing */
  },
});

export const NewProjectProvider = ({ children }: { children: JSX.Element }) => {
  const [project, setProject] = useState<NewProjectData>(makeEmptyProject);

  const reset = useCallback(() => {
    const defaults = makeEmptyProject();
    setProject({ ...defaults });
  }, []);

  return (
    <NewProjectContext.Provider
      value={{ project, setProject, reset }}
      children={children}
    />
  );
};
