import type { Role } from "@Services";
import { containsLikeQuery } from "@Utils";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  findRolesByUserId,
  getAuthUserContext,
  organizations,
} from "src/Mock";

export const getOrganizations = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const context = getAuthUserContext();
  const user = context.getUser();

  if (!user) {
    return res(ctx.delay(delay), ctx.status(403));
  }

  const size = 10;
  const page = 1;

  const { searchParams: sP } = req.url;

  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  const userRoles = findRolesByUserId(user.id)
    .filter((item: Role) => item.active)
    .map((item: Role) => item.organizationID);

  const result = organizations.filter(item => {
    if (!userRoles.includes(item.id)) {
      return false;
    }

    if (filters?.organization_name) {
      return containsLikeQuery(item.name, filters.organization_name);
    }

    return true;
  });

  result.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    }

    if (a.id < b.id) {
      return -1;
    }

    return 0;
  });

  const offset = (page - 1) * size;
  const limit = offset + size;
  const data = result.slice(offset, limit);

  return res(
    ctx.delay(delay),
    ctx.json({
      data,
      page,
      size,
      totalItems: result.length,
      totalPages: Math.ceil(result.length / size),
    })
  );
};
