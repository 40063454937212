import { FormInput } from "../Form";
import type { Props } from "./PageFilters.i";
import { RefreshListButton } from "../Buttons";
import { usePageFilters } from "./usePageFilters";
import { useMemo } from "react";
import { getFilterAllowedOptions } from "@Services";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

export const ApiKeyFilters = ({ updateFilters, refresh }: Props) => {
  const { getValueOf } = usePageFilters();
  const [roleOptions, enabledOptions] = useMemo(
    () => [
      getFilterAllowedOptions("api_key_role"),
      getFilterAllowedOptions("enabled"),
    ],
    []
  );

  return (
    <PageFiltersWrapper>
      <FormInput
        id="api-key-name"
        label="common.name"
        type="text"
        className="me-3"
        value={getValueOf("api_key_name")}
        onChange={e => {
          updateFilters({
            api_key_name: e.target.value,
          });
        }}
      />
      <FormInput
        id="api_key_role_value"
        label="common.role"
        type="select"
        className="me-3"
        value={getValueOf("api_key_role_value")}
        options={roleOptions}
        onChange={e => {
          updateFilters({
            api_key_role_value: e.target.value,
          });
        }}
      />
      <FormInput
        id="api_key_role_status"
        label="common.status"
        type="select"
        className="me-3"
        value={getValueOf("api_key_role_status")}
        options={enabledOptions}
        onChange={e => {
          updateFilters({
            api_key_role_status: e.target.value,
          });
        }}
      />

      <div className="d-flex align-items-end me-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
