import { ListItems } from "@Components";
import type { FC } from "react";
import { memo } from "react";
import { defaultSorts } from "../../../../constants";
import isEqual from "react-fast-compare";
import type { SpaceLinkTableProps } from "./SpaceLinkTable.i";
import { useSpaceLinkTable } from "./Hooks/useSpaceLinkTable";

const updateCardItemOrder = [0, 3, 2, 1, 4, 5];

export const SpaceLinkTable: FC<SpaceLinkTableProps> = memo(props => {
  const {
    cardVersion,
    config,
    headers,
    loading,
    setCardVersion,
    updateFiltersFunction,
  } = useSpaceLinkTable(props);
  return (
    <div id="space-links-table">
      <ListItems
        config={config}
        defaultSorts={defaultSorts}
        headers={headers}
        setSorts={updateFiltersFunction}
        showSkeleton={loading}
        cardVersion={cardVersion}
        setCardVersion={setCardVersion}
        updateCardItemOrder={updateCardItemOrder}
      />
    </div>
  );
}, isEqual);
