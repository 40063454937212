import type { OrganizationAdminListUsersResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, findUsersByFilters } from "src/Mock";

export const getUsers = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  if (!req.params.organizationId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const { searchParams } = req.url;

  const data = findUsersByFilters({
    organizationId: `${req.params.organizationId}`,
    searchParams,
  });

  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("size")) || 12;

  const start = pageSize * (page - 1);
  const end = start + pageSize;

  const response: OrganizationAdminListUsersResponse = {
    data: data.slice(start, end),
    page: page,
    size: pageSize,
    totalItems: data.length,
    totalPages: Math.ceil(data.length / pageSize),
  };

  return res(ctx.delay(delay), ctx.json(response));
};
