import { useCallback, useEffect, useMemo, useState } from "react";
import type { ButtonProps } from "@Components";
import { projectDataToUpdateProjectData } from "@Services";
import { useSpaceLinkEditCanvasStore } from "../SpaceLinkEditCanvas.store";
import type {
  CanvasCommonProps,
  SpaceLinkCanvasProps,
} from "../SpaceLinkEditCanvas.i";

export const useSpaceLinkEditCanvas = ({
  isOpen,
  setClosed,
  project,
  setProject: update,
}: CanvasCommonProps) => {
  const { selectedSpaceLinkID, setSelectedSpaceLinkID, reset } =
    useSpaceLinkEditCanvasStore();
  const [canvasLoading, setCanvasLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const closeCanvas = useCallback(() => {
    setClosed();
  }, [setClosed]);

  const connectButton: ButtonProps = useMemo(
    () => ({
      loading: canvasLoading,
      disabled:
        !selectedSpaceLinkID || selectedSpaceLinkID === project.spaceLinkID,
      iconClass: "bi bi-link-45deg",
      children: "common.connect",
      color: "primary",
      onClick: () => {
        setCanvasLoading(true);
        update({
          ...projectDataToUpdateProjectData(project),
          spaceLinkID: selectedSpaceLinkID ?? "",
        })
          .then(ok => {
            ok && closeCanvas();
          })
          .finally(() => {
            setCanvasLoading(false);
          });
      },
    }),
    [canvasLoading, closeCanvas, project, selectedSpaceLinkID, update]
  );

  const disconnectButton: ButtonProps | undefined = useMemo(
    () =>
      project.spaceLinkID
        ? {
            loading: canvasLoading,
            disabled: false,
            iconClass: "bi bi-x-square-fill",
            children: "common.disconnect",
            color: "danger",
            onClick: () => {
              setCanvasLoading(true);
              update({
                ...projectDataToUpdateProjectData(project),
                spaceLinkID: "",
              })
                .then(ok => {
                  ok && closeCanvas();
                })
                .finally(() => {
                  setCanvasLoading(false);
                });
            },
          }
        : undefined,
    [canvasLoading, closeCanvas, project, update]
  );

  const handleSpaceLinkSelect = useCallback(
    (selectedSpaceLinkID: string) => {
      setSelectedSpaceLinkID(selectedSpaceLinkID);
    },
    [setSelectedSpaceLinkID]
  );

  const canvasProps: SpaceLinkCanvasProps = {
    isOpen,
    saveButton: connectButton,
    disconnectButton,
    setClosed: closeCanvas,
    loading: canvasLoading,
  };

  return {
    canvasProps,
    selectedSpaceLinkID,
    handleSpaceLinkSelect,
  };
};
