import { memo, useMemo } from "react";
import {
  Button,
  OrganizationUsersFilters,
  Paginator,
  Section,
} from "@Components";
import { SidebarNewUser, OrganizationTable } from "./Components";
import { useOrganizationList } from "./useOrganizationList";
import { useUpdateCacheFilters } from "@Hooks";

export const OrganizationUsers = memo(() => {
  const updateFilters = useUpdateCacheFilters("organization.users");
  const { fetch, loading, response, sidebarOpen, setSidebarOpen } =
    useOrganizationList();

  const table = useMemo(
    () => (
      <OrganizationTable
        users={response?.data}
        loading={loading}
        refreshUsers={fetch}
      />
    ),
    [fetch, loading, response?.data]
  );

  const paginator = useMemo(
    () => (
      <Paginator
        setFilters={updateFilters}
        totalItems={response?.totalItems}
        showItemsPerPage
      />
    ),
    [response?.totalItems, updateFilters]
  );

  const sidebar = useMemo(
    () => (
      <SidebarNewUser
        isOpen={sidebarOpen}
        toggle={setSidebarOpen}
        onUserInvited={fetch}
      />
    ),
    [fetch, setSidebarOpen, sidebarOpen]
  );

  return (
    <div id="organization-users">
      <Section title="organization.users">
        <div className="d-flex justify-content-between align-items-end">
          <OrganizationUsersFilters
            updateFilters={updateFilters}
            refresh={fetch}
          />
          <Button
            color="primary"
            onClick={() => setSidebarOpen(true)}
            children="organization.invite-user"
            data-cy="invite-user-button"
            iconClass="bi bi-person-fill-add"
            className="ms-3"
          />
        </div>
      </Section>
      {table}
      {paginator}
      {sidebar}
    </div>
  );
});
