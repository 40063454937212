import { memo } from "react";
import {
  DisplayRowProjectAuthentications,
  SettingsContainer,
} from "@Components";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const Authentication = memo(() => {
  const { loading, authenticationSettings } = useSettingsStore();
  return (
    <div id="scan-authentication" className="p-4">
      <SettingsContainer loading={loading} section="authentication">
        <DisplayRowProjectAuthentications
          authenticationSettings={authenticationSettings ?? []}
        />
      </SettingsContainer>
    </div>
  );
});
