import { memo } from "react";
import { useScanDetailsStore } from "../../Store/ScanDetailsStore";
import { DownloadReportAction, StopScanAction } from "./Components";
import { showReportButton } from "@Services";

export const Actions = memo(() => {
  const { details } = useScanDetailsStore();

  if (!details) {
    return null;
  }

  const { scan } = details;

  const showStopBtn = scan.status.startsWith("run_");
  const showReportBtn = showReportButton(scan);

  return (
    <div id="actions" className="d-flex">
      {showStopBtn && (
        <div className="ms-2">
          <StopScanAction />
        </div>
      )}
      {showReportBtn && (
        <div className="ms-2">
          <DownloadReportAction />
        </div>
      )}
    </div>
  );
});
