import { DatePicker } from "@Components";
import { recurrences as allRecurrences } from "@Services";
import { t } from "i18next";
import { useMemo } from "react";
import { Card, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { Switch } from "../Switch";
import { FormSection } from "./Components";
import { dateToFormattedString } from "./functions";
import type { Props } from "./RunPolicyForm.i";
import "./style.scss";
import { useDisabledRunPolicy } from "./useDisabledRunPolicy";
import { useRunPolicyForm } from "./useRunPolicyForm";

export const RunPolicyForm = (props: Props) => {
  const {
    runPolicy,
    setRunPolicy,
    datePickersData,
    validities,
    toggleEndDateInput,
    minStartDate,
    provideEndDate,
    setProvideEndDate,
    disclaimer,
  } = useRunPolicyForm(props);

  const { disableRunPolicy, restoreRunPolicy } = useDisabledRunPolicy({
    runPolicy,
    setRunPolicy,
    minStartDate,
  });

  const recurrences = useMemo(() => {
    return allRecurrences.filter(r => r !== "none");
  }, []);

  const isOneShot = runPolicy.recurrence === "one_shot";
  const policyDisabled = runPolicy.recurrence === "none";

  return (
    <form id="run-policy-form">
      <FormSection
        title="title"
        children={
          <Switch
            checked={!policyDisabled}
            id="run-policy-toggle"
            onChange={e => {
              const isNewPolicyDisabled = !e.target.checked;
              if (isNewPolicyDisabled) disableRunPolicy();
              else restoreRunPolicy();
            }}
          />
        }
      />
      <hr />

      {policyDisabled ? (
        <Card className="disclaimer-card">
          <i className="bi bi-info-circle" />
          <span className="disclaimer">{t(disclaimer)}</span>
        </Card>
      ) : (
        <>
          <FormSection
            title="choose-recurrence"
            children={
              <div className="d-flex">
                {recurrences.map(r => (
                  <span className="d-flex" key={r}>
                    <Input
                      type="radio"
                      id={`run-policy-recurrence-${r}`}
                      name="run-policy-recurrence"
                      className="cursor-pointer"
                      checked={r === runPolicy.recurrence}
                      value={r}
                      onChange={() => {
                        const oneShot = r === "one_shot";
                        const updatedRunPolicy = toggleEndDateInput(!oneShot);
                        setRunPolicy({ ...updatedRunPolicy, recurrence: r });
                      }}
                    />
                    <Label
                      for={`run-policy-recurrence-${r}`}
                      className="cursor-pointer ps-2 my-0 me-4"
                      children={t(`recurrences.${r}`)}
                    />
                  </span>
                ))}
              </div>
            }
          />
          <FormSection
            title={isOneShot ? "choose-run-date" : "choose-start-end-date"}
            children={
              <Container fluid>
                <Row>
                  <Col xs={12} xl={6}>
                    <Label for="run-policy-start-date">
                      {t("common.start-date")}
                    </Label>
                    <DatePicker
                      id="run-policy-start-date"
                      value={datePickersData.startDate}
                      min={datePickersData.minStartDate}
                      max={datePickersData.endDate}
                      invalid={!validities.startDate}
                      onChange={date => {
                        setRunPolicy({
                          ...runPolicy,
                          startDate: dateToFormattedString(date),
                        });
                      }}
                    />
                  </Col>
                  {!isOneShot && (
                    <Col xs={12} xl={6} className="mt-3 mt-xl-0">
                      <div>
                        <FormGroup switch className="mb-0">
                          <Input
                            type="checkbox"
                            id="run-policy-end-date-toggle"
                            checked={provideEndDate}
                            className="cursor-pointer"
                            onChange={e => {
                              setProvideEndDate(e.target.checked);
                              toggleEndDateInput(e.target.checked);
                            }}
                          />
                          <Label
                            children={t("common.end-date")}
                            for="run-policy-end-date-toggle"
                            className="ps-2 cursor-pointer"
                          />
                        </FormGroup>
                      </div>
                      <DatePicker
                        id="run-policy-end-date"
                        value={datePickersData.endDate}
                        min={datePickersData.startDate}
                        disabled={!provideEndDate}
                        invalid={!validities.endDate}
                        onChange={date => {
                          setRunPolicy({
                            ...runPolicy,
                            endDate: dateToFormattedString(date),
                          });
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Container>
            }
          />
        </>
      )}
    </form>
  );
};
