import { createStore } from "@Hooks";
import type { Attempt, Nullable } from "@Interfaces";
import {
  ZERO_UUID,
  type AuthenticationProfile,
  type ListedAttempt,
} from "@Services";
import { t } from "i18next";
import type { Dispatch } from "react";

export type StoreToggableFilter = "caller";

export type HistoryStoreProperties = {
  loading: boolean;
  authenticationProfiles: Nullable<Array<AuthenticationProfile>>;
  listedAttempts: Nullable<Array<ListedAttempt>>;
  selectedListedAttempt: Nullable<ListedAttempt>;
  selectedListedAttemptIndex: number;
  attempt: Nullable<Attempt>;
  totalAttempts: number;
  visibleFilters: Record<StoreToggableFilter, boolean>;
};

export type HistoryStore = HistoryStoreProperties & {
  setAuthenticationProfiles: (
    authenticationProfiles: Nullable<Array<AuthenticationProfile>>
  ) => void;
  setListedAttempts: (
    listedAttempts: Array<ListedAttempt>,
    totalAttempts: number
  ) => void;
  selectNextListedAttempt: Dispatch<void>;
  selectPreviousListedAttempt: Dispatch<void>;
  setSelectedListedAttempt: (attempt: Nullable<ListedAttempt>) => void;
  clearSelectedListedAttempt: VoidFunction;
  setAttempt: (attempt: Nullable<Attempt>) => void;
  setLoading: (loading: boolean) => void;
  setVisibleFilters: (filter: StoreToggableFilter, show: boolean) => void;
  reset: VoidFunction;
};

const initialState: HistoryStoreProperties = {
  loading: true,
  authenticationProfiles: null,
  listedAttempts: null,
  selectedListedAttempt: null,
  selectedListedAttemptIndex: -1,
  attempt: null,
  totalAttempts: 0,
  visibleFilters: {
    caller: false,
  },
};

export const useHistoryStore = createStore<HistoryStore>((get, set) => ({
  ...initialState,
  setAuthenticationProfiles: authenticationProfiles =>
    set(state => {
      const sanitizedAuthenticationProfiles = authenticationProfiles ?? [];
      const anonymousLabel = t("common.anonymous");

      for (const profile of sanitizedAuthenticationProfiles) {
        if (profile.name === "") {
          profile.name = anonymousLabel;
        }

        if (profile.id === "") {
          profile.id = ZERO_UUID;
        }
      }

      return {
        ...state,
        authenticationProfiles: sanitizedAuthenticationProfiles,
      };
    }),
  setListedAttempts: (listedAttempts, totalAttempts) =>
    set(state => {
      const sanitizedListedAttempt = [...listedAttempts];
      const anonymousLabel = t("common.anonymous");

      for (const attempt of sanitizedListedAttempt) {
        if (attempt.authProfileName === "") {
          attempt.authProfileName = anonymousLabel;
        }
      }

      return {
        ...state,
        listedAttempts: sanitizedListedAttempt,
        totalAttempts,
      };
    }),
  setSelectedListedAttempt: selectedAttempt =>
    set(state => {
      if (selectedAttempt === state.selectedListedAttempt) {
        return {
          ...state,
          selectedListedAttempt: null,
          selectedListedAttemptIndex: -1,
        };
      }

      return {
        ...state,
        selectedListedAttempt: selectedAttempt,
        selectedListedAttemptIndex: selectedAttempt
          ? (state.listedAttempts ?? []).indexOf(selectedAttempt)
          : -1,
      };
    }),
  selectNextListedAttempt: () =>
    set(state => {
      return shift(state, 1);
    }),
  selectPreviousListedAttempt: () =>
    set(state => {
      return shift(state, -1);
    }),
  clearSelectedListedAttempt: () =>
    set(state => ({
      ...state,
      selectedListedAttempt: null,
      selectedListedAttemptIndex: -1,
    })),
  setLoading: loading => set(state => ({ ...state, loading })),
  setAttempt: attempt => set(state => ({ ...state, attempt })),
  setVisibleFilters: (filter, show) => {
    set(state => {
      const visibleFilters = { ...state.visibleFilters };
      visibleFilters[filter] = show;
      return { ...state, visibleFilters };
    });
  },
  reset: () => set(state => ({ ...state, ...initialState })),
}));

const shift = (state: HistoryStore, direction: number) => {
  if (!state.listedAttempts) {
    return state;
  }

  const actualIndex = state.selectedListedAttemptIndex;
  const nextIndex = Math.max(
    Math.min(state.listedAttempts.length - 1, actualIndex + direction),
    0
  );
  const nextSelectedListedAttempt = state.listedAttempts[nextIndex];
  return {
    ...state,
    selectedListedAttemptIndex: nextIndex,
    selectedListedAttempt: nextSelectedListedAttempt,
  };
};
