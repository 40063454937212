import type { Recurrence } from "@Interfaces";
import { projectMaxEndDate, type RunPolicy } from "@Services";

const defaultRecurrenct: Recurrence = "monthly";

const model: RunPolicy = {
  businessHours: false,
  endDate: projectMaxEndDate,
  recurrence: `${defaultRecurrenct}`,
  startDate: "",
};

export const getDefaultProjectRunPolicy = (
  base: Partial<RunPolicy> = {}
): RunPolicy => Object.assign({}, { ...model, ...base });
