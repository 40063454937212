import type { Injectable, UserListScanInjectablesResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, generateRandomInjectables } from "src/Mock";

export const scanInjectables = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;

  const { organizationId, projectId, scanId } = req.params as Record<
    string,
    string
  >;

  if (!organizationId || !projectId || !scanId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const data: Injectable[] = generateRandomInjectables();

  const response: UserListScanInjectablesResponse = {
    data,
    page,
    size,
    totalItems: data.length,
    totalPages: Math.ceil(data.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
