import { ProjectEditCanvas } from "../../ProjectEditCanvas";
import { ScheduleEditCanvas } from "../../ScheduleEditCanvas";
import { ApiDefinitionEditCanvas } from "../../ApiDefinitionEditCanvas";
import type { FullAccessModeProps } from "./FullAccessMode.i";
import { useFullAccessMode } from "./useFullAccessMode";
import { type FC } from "react";
import { ProjectShowCase } from "../ProjectShowcase";
import { DangerZoneContainer } from "../DangerZoneContainer";
import { SpaceLinkEditCanvas } from "@Components";

export const FullAccessMode: FC<FullAccessModeProps> = props => {
  const {
    canvasController,
    closeCanvas,
    dangerZoneController,
    license,
    project,
    updateAndRefresh,
    updateOnly,
  } = useFullAccessMode(props);

  const projectArchived = !!project.archivedAt;
  const nullableCanvasController = projectArchived
    ? undefined
    : canvasController;

  return (
    <>
      <>
        {/* Project showcase */}
        <ProjectShowCase
          project={project}
          canvasController={nullableCanvasController}
        />
      </>
      <>
        {/* Canvas */}
        <ProjectEditCanvas
          isOpen={canvasController.canvasType === "project"}
          setClosed={closeCanvas}
          project={project}
          setProject={updateAndRefresh}
        />
        <ScheduleEditCanvas
          isOpen={canvasController.canvasType === "schedule"}
          setClosed={closeCanvas}
          project={project}
          setProject={updateAndRefresh}
        />
        <ApiDefinitionEditCanvas
          isOpen={canvasController.canvasType === "service"}
          setClosed={closeCanvas}
          project={project}
          setProject={updateOnly}
          license={license}
        />
        <SpaceLinkEditCanvas
          isOpen={canvasController.canvasType === "space-links"}
          setClosed={closeCanvas}
          project={project}
          setProject={updateAndRefresh}
        />
      </>
      {/* Danger Zone */}
      <DangerZoneContainer
        project={project}
        dangerZoneController={dangerZoneController}
      />
    </>
  );
};
