import { Switch } from "src/Components/Form";
import type { TableEntryProps } from "./ExtensionCategoriesTable.i";
import { t } from "i18next";

export const TableEntry = (props: TableEntryProps) => {
  const description = t(`extensions.${props.translationKey}.description`);
  return (
    <div
      className="category-wrapper card"
      onClick={props.onToggle}
      data-selected={props.selected || undefined}
      data-disabled={props.disabled || undefined}
    >
      <div className="d-flex align-items-center">
        <h6 className="name flex-grow-1">
          {t(`extensions.${props.translationKey}.value`)}
        </h6>
        <div className="flex-center flex-grow-0">
          {!props.disabled && (
            <Switch
              disabledForReader
              checked={props.selected}
              id={`category-checkbox-${props.translationKey}`}
              showLabel={false}
              className="m-0"
            />
          )}
          {description && (
            <span className="ms-2">
              <i
                className="bi bi-info-circle pe-auto cursor-pointer"
                data-tooltip-id="tooltip"
                data-tooltip-content={description}
                data-tooltip-place="left-start"
                data-tooltip-class-name="opacity-100"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
