import {
  useManageApiResponse,
  useOrganization,
  usePreference,
  useUrlParams,
} from "@Hooks";
import type {
  OrganizationSettings,
  UpdateOrganizationSettings,
} from "@Interfaces";
import { API } from "@Services";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useOrganizationSettings = () => {
  const [organizationSettings, setOrganizationSettings] =
    useState<OrganizationSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { organizationId } = useUrlParams();
  const organization = useOrganization();
  const navigate = useNavigate();
  const [prefOrganizationId, setPrefOrganizationId] =
    usePreference("organization-id");

  const manageResponse = useManageApiResponse();

  const fetchOrganizationSettings = useCallback(async () => {
    try {
      if (!organizationId) return;
      await manageResponse<OrganizationSettings>({
        errorMessage: "organization-settings-error",
        promise:
          API.organizationSettings().userGetOrganizationSettings(
            organizationId
          ),
        onSuccess: response => {
          setOrganizationSettings(response.data);
        },
      });
    } finally {
      setLoading(false);
    }
  }, [manageResponse, organizationId]);

  const updateOrganizationSettings = useCallback(
    async (newFields: UpdateOrganizationSettings) => {
      if (!organizationId) return;
      setLoadingUpdate(true);
      manageResponse({
        errorMessage: "update-organization-settings-error",
        promise:
          API.organizationSettings().organizationAdminUpdateOrganizationSettings(
            organizationId,
            newFields
          ),
        onSuccess: response => {
          if (response.status === 200) {
            fetchOrganizationSettings().finally(() => setLoadingUpdate(false));
          }
        },
        onError: () => {
          setLoadingUpdate(false);
        },
      });
    },
    [manageResponse, fetchOrganizationSettings, organizationId]
  );

  useEffect(() => {
    fetchOrganizationSettings();
  }, [fetchOrganizationSettings]);

  return {
    organization,
    organizationSettings,
    loading,
    loadingUpdate,
    dangerZone: organization
      ? {
          onSuccess: () => {
            if (prefOrganizationId) setPrefOrganizationId(null);
            navigate("/login");
          },
          promise: () => {
            return API.organization().organizationAdminDeleteOrganization(
              organization?.id ?? ""
            );
          },
        }
      : undefined,
    updateOrganizationSettings,
  };
};
