import type { PaginateResult } from "@Interfaces";
import type { AxiosPromise } from "axios";
import { useCallback, useEffect, useReducer } from "react";
import { initialState, reducer } from "./reducer";
import { useToast } from "@Hooks";

export const useHasAnyItemsGuard = <T>(props: {
  fetchCondition: boolean;
  getResponse: () => AxiosPromise<PaginateResult<T>>;
}) => {
  const { fetchCondition, getResponse } = props;
  const showToast = useToast();

  const [state, dispatch] = useReducer(reducer, initialState);

  const doApiCall = useCallback(async () => {
    try {
      const response = await getResponse();
      dispatch({
        type: "SET_HAS_ANY_ITEM",
        payload: !!response.data.totalItems,
      });
    } catch (e) {
      showToast({ type: "error", message: "errors.retrieving-data" });
    } finally {
      dispatch({ type: "FINISH_FETCHING" });
    }
  }, [getResponse, showToast]);

  useEffect(() => {
    if (fetchCondition && !state.fetched && !state.fetching) {
      dispatch({ type: "START_FETCHING" });
      doApiCall();
    }
  }, [doApiCall, fetchCondition, state.fetched, state.fetching]);

  const { fetched, fetching, hasAnyItem } = state;
  return {
    hasAnyItem,
    fetchingHasAnyItem: fetching || (fetchCondition && !fetched),
  };
};
