import type { HeaderItems, Inventory } from "@Interfaces";
import ReactDOMServer from "react-dom/server";

export const getTooltips = (row: Inventory) => {
  const tagsTooltipProps =
    row?.tags && row?.tags[2]
      ? {
          "data-tooltip-id": "tooltip",
          "data-tooltip-html": ReactDOMServer.renderToStaticMarkup(
            <>
              <span className="color-pink">Tags: </span> {row.tags.join(", ")}
            </>
          ),
        }
      : {};

  return {
    tagsTooltipProps,
  };
};

export const getHeaders = (): HeaderItems => {
  return [
    { text: "common.endpoint", sortByKey: "inventory_item_path" },
    "common.category",
    "common.tags",
    "common.inputs",
    "common.outputs",
    "common.auth",
  ];
};
