import { useProject, useUrlParams } from "@Hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchSummaryAndChart } from "./functions";
import type { ProjectDashboardData } from "../../Dashboard.i";
import { getTotalIssueFromSeverityCount } from "@Services";

export const useDashboardProject = () => {
  const { organizationId } = useUrlParams();
  const { project, loading: projectLoading, projectStatus } = useProject();

  const projectArchived = projectStatus === "archived";
  const projectError = projectStatus === "error";

  const [loading, setLoading] = useState(!projectArchived && !projectError);
  const [data, setData] = useState<ProjectDashboardData>({
    chartData: null,
    summary: null,
  });

  const summaryFetchable = !projectArchived && !projectError;

  useEffect(() => {
    if (projectLoading) return; // waiting project to be fetched
    if (!project || projectArchived || projectError) {
      setLoading(false); // stop fetching procedure
    }
  }, [project, projectArchived, projectError, projectLoading]);

  const fetchDashboardData = useCallback(() => {
    if (!organizationId) throw new Error("invalid-organization-id");
    if (!project?.id || !summaryFetchable) return;
    setLoading(true);
    fetchSummaryAndChart(organizationId, project?.id)
      .then(responses => {
        const [summaryResponse, chartDataResponse] = responses;
        setData({
          summary: summaryResponse.data,
          chartData: chartDataResponse.data,
        });
      })
      .finally(() => setLoading(false));
  }, [organizationId, project?.id, summaryFetchable]);

  const issueCounter = useMemo(
    () => getTotalIssueFromSeverityCount(data?.summary?.summary.severityCount),
    [data?.summary?.summary.severityCount]
  );

  useEffect(fetchDashboardData, [fetchDashboardData]);

  return {
    loading,
    ...data,
    issueCounter,
    projectArchived,
    projectError,
    projectName: project?.name,
  };
};
