import type { UpdateProjectData } from "@Interfaces";
import type { Project } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  findProjectsByOrganizationId,
  organizationHasProject,
  projectInfos,
  projects,
  ProjectSpaceLink,
} from "src/Mock";

export const projectPut = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params as Record<string, string>;

  const listProjects = findProjectsByOrganizationId(organizationId);

  const anyListedPendingProject = listProjects.some(
    p => p.status === "pending"
  );

  if (anyListedPendingProject) {
    return res(ctx.delay(delay), ctx.status(422));
  }

  const newProjectData = await req.json<UpdateProjectData>();

  const ownerCheck = organizationHasProject(organizationId, projectId);

  if (!ownerCheck) return res(ctx.delay(delay), ctx.status(403));

  const index = projectInfos.findIndex(p => p.id === projectId);

  if (index < 0) return res(ctx.delay(delay), ctx.status(400));

  const updated: Project = {
    ...projectInfos[index],
    ...newProjectData,
  };

  projectInfos.splice(index, 1, updated);
  ProjectSpaceLink.set(projectId, updated.spaceLinkID);

  if (newProjectData.oasUploadData) {
    // uploading the oas file will make the project in "pending" status
    projectInfos[index].status = "pending";
    projects[index].status = "pending";
  }

  return res(ctx.delay(delay), ctx.status(200));
};
