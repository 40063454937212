import { Button } from "@Components";
import { pureComponent } from "@Utils";
import { memo } from "react";
import { Card } from "reactstrap";
import { AuthenticationCards, AuthenticationOffCanvas } from "./Components";
import { useAuthentication } from "./Hooks/useAuthentication";

export const Authentication = memo(() => {
  const { handleOpenCanvas } = useAuthentication();

  return (
    <section id="authentication">
      <Card className="p-4 border-tl-0">
        <div className="w-100 d-flex flex-row justify-content-end border-tl-0">
          <Button
            className="me-2"
            color="primary"
            children="common.add-credentials"
            iconClass="bi bi-plus-square-fill"
            data={{ cy: "button-add-credentials" }}
            onClick={() => {
              handleOpenCanvas("credentials", "insert");
            }}
          />
          <Button
            className="me-2"
            color="primary"
            children="common.add-token"
            iconClass="bi bi-plus-square-fill"
            data={{ cy: "button-add-static-token" }}
            onClick={() => {
              handleOpenCanvas("static_token", "insert");
            }}
          />
          <Button
            color="primary"
            children="common.add-oauth-credentials"
            iconClass="bi bi-plus-square-fill"
            data={{ cy: "button-add-oauth-credentials" }}
            onClick={() => {
              handleOpenCanvas("oauth_credentials", "insert");
            }}
          />
        </div>

        <AuthenticationCards />
      </Card>
      <AuthenticationOffCanvas />
    </section>
  );
}, pureComponent);
