import { API } from "@Services";
import { useCallback, useContext, useMemo, useState } from "react";
import type { Props as MainProps } from "./UpdatePassword.i";
import { AuthContext } from "@Contexts";
import type { PlainObject } from "@Interfaces";
import type { ButtonProps } from "@Components";
import { useManageApiResponse } from "@Hooks";

const defaults: PlainObject<string> = {
  password: "",
  repeatPassword: "",
  oldPassword: "",
};

type Props = Pick<MainProps, "setIsOpen">;

const useUpdatePasswordCmp = ({ setIsOpen }: Props) => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState(defaults);
  const { oldPassword, password, repeatPassword } = formData;
  const [loading, setLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const manageResponse = useManageApiResponse(setLoading);

  const validity = useMemo(
    () => ({
      oldPassword: !!oldPassword,
      password: !!passwordValid,
      repeatPassword: password === repeatPassword,
    }),
    [oldPassword, password, repeatPassword, passwordValid]
  );

  const enableSave = !Object.values(validity).includes(false);

  const reset = useCallback(() => {
    setIsOpen(false);
    setFormData(defaults);
  }, [setIsOpen, setFormData]);

  const update = useCallback(async () => {
    if (!enableSave || !user) return;
    const { status } = await manageResponse({
      errorMessage: "psw-update",
      promise: API.user().userUpdate({
        name: user.name,
        surname: user.surname,
        passwordNew: password,
        passwordNewConfirm: repeatPassword,
        passwordOld: oldPassword,
      }),
      successMessage: "psw-update",
    });
    status === 200 && reset();
  }, [
    enableSave,
    user,
    manageResponse,
    password,
    repeatPassword,
    oldPassword,
    reset,
  ]);

  return {
    oldPassword: {
      value: oldPassword,
      setValue: (oldPassword: string) =>
        setFormData({ ...formData, oldPassword }),
    },
    password: {
      value: password,
      setValue: (password: string) => setFormData({ ...formData, password }),
      setPasswordValid,
    },
    repeatPassword: {
      value: repeatPassword,
      setValue: (repeatPassword: string) =>
        setFormData({ ...formData, repeatPassword }),
      valid: validity.repeatPassword,
    },
    buttons: useMemo(() => {
      return [
        {
          children: "common.save",
          color: "primary",
          disabled: !enableSave,
          loading,
          onClick: update,
          iconClass: "bi bi-floppy",
          data: {
            cy: "confirm-psw-btn",
          },
        },
      ] as ButtonProps[];
    }, [enableSave, loading, update]),
    closeCanvas: reset,
    setPasswordValid,
  };
};

export default useUpdatePasswordCmp;
