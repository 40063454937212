import type { FC } from "react";
import type { MTLSCertificateCanvasFormProps } from "../MTLSCertificatesForm.i";
import { useGlobalMTLSCertificateForm } from "./useGlobalMTLSCertificateForm";
import { MTLSCertificatesForm } from "../MTLSCertificateForm/MTLSCertificateForm";
import { Offcanvas } from "@Components";
import { useGlobalMTLSCertificates } from "@Hooks";

export const GlobalMTLSCertificateForm: FC<
  MTLSCertificateCanvasFormProps
> = props => {
  const { buttons, entry, close, isOpen, loading, setEntry, setFormValid } =
    useGlobalMTLSCertificateForm(props);

  const globalMTLSCertificates = useGlobalMTLSCertificates();

  return (
    <Offcanvas
      isOpen={isOpen}
      title="common.new-mtls-certificate"
      toggle={close}
      buttons={buttons}
      id="mtls-certificates-form-canvas"
    >
      <MTLSCertificatesForm
        mtlsCertificateEntry={entry}
        setMTLSCertificateEntry={setEntry}
        disabled={loading}
        setFormValid={setFormValid}
        localMTLSCertificates={globalMTLSCertificates}
      />
    </Offcanvas>
  );
};
