import { API } from "../api";
import { storeJwtToken } from "./storeJwtToken";

export const refreshToken = async (logout: () => void) => {
  let newToken: string;
  let status = true;

  try {
    newToken = (await API.user().userRefreshToken()).data.token;
    storeJwtToken(newToken);
  } catch (error) {
    status = false;
    logout(); // API error
  }

  return status;
};
