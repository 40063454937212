import type { AskEnableTwoFactorAuthenticationResponse } from "@Services";
import { API } from "@Services";
import { useEffect, useState, useCallback } from "react";
import { useManageApiResponse } from "@Hooks";

interface Props {
  token?: string | null;
  withAuth?: boolean;
  autoFetch?: boolean;
}

export const useQrCode = ({ token, withAuth, autoFetch = true }: Props) => {
  const [qrCodeImg, setQrCodeImg] = useState("");
  const [loading, setLoading] = useState(true);
  const manageResponse = useManageApiResponse();

  const fetchData = useCallback(async () => {
    if (token || withAuth) {
      let response: AskEnableTwoFactorAuthenticationResponse | undefined;
      if (withAuth) {
        response = (
          await manageResponse<AskEnableTwoFactorAuthenticationResponse>({
            promise: API.user().userAskEnable2fa(),
            errorMessage: "login-otp-get-qr-err",
          })
        ).data;
      } else {
        response = (
          await manageResponse<AskEnableTwoFactorAuthenticationResponse>({
            promise: API.login().askEnable2fa(),
            errorMessage: "login-otp-get-qr-err",
          })
        ).data;
      }

      if (response?.image) {
        setQrCodeImg(response?.image);
      }

      setLoading(false);
    }
  }, [token, withAuth, manageResponse]);

  useEffect(() => {
    if (autoFetch) {
      fetchData();
    }
  }, [fetchData, autoFetch]);

  return { loading, qrCodeImg, fetchData };
};
