import { t } from "i18next";
import { Callout, LicenseCard, Section, SkeletonSection } from "@Components";
import { memo } from "react";
import { useLicenseSection } from "./useLicenseSection";

export const LicenseSection = memo(() => {
  const { license, loading } = useLicenseSection();

  if (loading) {
    return <SkeletonSection height={300} unit="px" />;
  }

  if (!license) {
    return (
      <div className="px-1 mt-3">
        <Callout level="warning">{t("project.no-licenses")}</Callout>
      </div>
    );
  }

  return (
    <Section title="common.license">
      <LicenseCard license={license} direction="row" />
    </Section>
  );
});
