import validator from "validator";
import ipaddr from "ipaddr.js";
import type { IsURLOptions } from "validator/lib/isURL";

const IPv4Localhosts = [
  "127.0.0.0/8", // IPv4 loopback
  "10.0.0.0/8", // RFC1918
  "172.16.0.0/12", // RFC1918
  "192.168.0.0/16", // RFC1918
  "169.254.0.0/16", // RFC3927 link-local
];

interface Options {
  allowLocalhost?: boolean;
  isUrlOptions?: IsURLOptions;
}

export const isURL = (url: string, options: Options): boolean => {
  try {
    const hostnameOrIpAddress = getHostname(url);
    const allowLocalHost = options.allowLocalhost ?? false;

    if (validator.isIP(hostnameOrIpAddress, "4")) {
      let valid = true;
      if (!allowLocalHost) {
        const address = ipaddr.parse(hostnameOrIpAddress);
        valid = IPv4Localhosts.every(subnet => {
          const parsed = ipaddr.parseCIDR(subnet);
          return !address.match(parsed);
        });
      }
      return valid;
    }

    if (validator.isIP(hostnameOrIpAddress, "6")) {
      return true;
    }

    if (validator.isURL(url, options.isUrlOptions)) {
      let valid = true;
      if (!allowLocalHost) {
        valid = !hostnameOrIpAddress.toLowerCase().startsWith("localhost");
      }
      return valid;
    }

    return false;
  } catch {
    return false;
  }
};

//

const getHostname = (url: string): string => {
  try {
    return new URL(url).hostname;
  } catch (e) {
    // break when passing an invalid url (like an IP address)
    return url;
  }
};
