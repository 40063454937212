import { getAppAPIHeaders } from "@Services";
import axios from "axios";

export const setCustomHeaders = () => {
  const headers = getAppAPIHeaders();
  headers.forEach((header: { key: string; value: string }) => {
    if (header.key && header.value) {
      axios.defaults.headers.common[header.key] = header.value;
    }
  });
};
