import type { UpdateProjectData } from "@Interfaces";
import type { DictionaryEntry, Project } from "@Services";
import { projectDataToUpdateProjectData } from "@Services";

type Args = {
  project: Project;
  update: (project: UpdateProjectData) => Promise<boolean>;
  action: "add" | "remove";
} & (
  | {
      action: "add";
      entry: DictionaryEntry;
    }
  | {
      action: "remove";
      index: number;
    }
);

const updateDictionaryEntries = (args: Args) => {
  const { project, update, action } = args;
  const dictionary = project.dictionary.slice();

  if (action === "add") {
    const { entry } = args;
    dictionary.unshift(entry);
  }

  if (action === "remove") {
    const { index } = args;
    dictionary.splice(index, 1);
  }

  const payload: UpdateProjectData = {
    ...projectDataToUpdateProjectData(project),
    dictionary,
  };

  return update(payload);
};

export default updateDictionaryEntries;
