import { AuthContext } from "@Contexts";
import { findOrganizationIdFromUrl } from "@Services";
import { useContext, useMemo } from "react";
import type { AccessRolesProps } from "../AuthWrapper.i";
import { useLocation } from "react-router-dom";

export const useAuthGuards = (permits: AccessRolesProps = {}) => {
  const { role } = useContext(AuthContext);
  const { pathname } = useLocation();

  const hasValidRole = useMemo(() => {
    return role?.active ?? false;
  }, [role]);

  const hasValidOrganization = useMemo(() => {
    const urlOrganizationId = findOrganizationIdFromUrl(pathname);
    return !urlOrganizationId || role?.organizationID === urlOrganizationId;
  }, [pathname, role?.organizationID]);

  const guards = { hasValidRole, hasValidOrganization };

  return Object.entries(guards).every(([, v]) => {
    return Boolean(v);
  });
};
