import type { TabsProps } from "@Components";
import { NewProjectContext } from "@Contexts";
import { useGlobalInjectables, useGlobalMTLSCertificates } from "@Hooks";
import { useContext, useMemo, useState } from "react";
import { useProjectAdvancedSettings } from "./useProjectAdvancedSettings";

export const useSettings = () => {
  const { project } = useContext(NewProjectContext);
  const [activeTab, setActiveTab] = useState(0);

  const authentications = project.authenticationSettings.length;
  const dictionaryEntries = project.dictionary.length;
  const pathExclusions = project.pathExclusion.length;
  const injectables = useGlobalInjectables().length;
  const mtlsCertificates = useGlobalMTLSCertificates().length;

  const baseTabs = useMemo<TabsProps["tabs"]>(
    () => [
      {
        label: "common.authentication",
        counter: authentications,
      },
      {
        label: "common.dictionary",
        counter: dictionaryEntries,
      },
      {
        label: "common.path-exclusion",
        counter: pathExclusions,
      },
      {
        label: "common.injectable-parameters",
        counter: injectables,
      },
      {
        label: "common.mtls-certificate",
        counter: mtlsCertificates,
      },
    ],
    [
      authentications,
      dictionaryEntries,
      injectables,
      pathExclusions,
      mtlsCertificates,
    ]
  );

  const tabs = useProjectAdvancedSettings(baseTabs);

  return { tabs, activeTab, setActiveTab };
};
