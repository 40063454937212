import React from "react";
import { WorkerBuilder, Worker } from "@Services";

let workerInstance: WorkerBuilder;
if (typeof WorkerBuilder === "function") {
  workerInstance = new WorkerBuilder(Worker);
} else {
  // mock for jest
  workerInstance = {
    postMessage: () => true,
  } as unknown as WorkerBuilder;
}

// Crea il contesto del worker
export const WorkerContext = React.createContext(workerInstance);

// Componente Provider del contesto del worker
export const WorkerProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <WorkerContext.Provider value={workerInstance}>
      {children}
    </WorkerContext.Provider>
  );
};
