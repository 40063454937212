import { BEM, joinClasses } from "@Utils";
import type { ActivityViewProps } from "./ActivityView.i";
import "./styles.scss";

export const ActivityView = ({
  bodyContent,
  titleContent,
  showTitleBar,
  className,
}: ActivityViewProps) => {
  const withTitlebar = titleContent ?? showTitleBar;
  return (
    <div
      className={joinClasses(
        "activity-view",
        withTitlebar && "with-title-bar",
        className
      )}
    >
      {withTitlebar ? (
        <div className={BEM("activity-view", "title-bar")}>{titleContent}</div>
      ) : null}
      <div className={BEM("activity-view", "content")}>{bodyContent}</div>
    </div>
  );
};
