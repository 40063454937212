import type { ApiKey } from "@Interfaces";
import { clamp } from "@Services";
import { getDaysBetween } from "@Utils";

export const getApiKeyExtraData = (apiKey: ApiKey) => {
  const daysBetween = getDaysBetween(apiKey.expiresAt);

  let remainingDays = Infinity;
  if (Number.isFinite(daysBetween)) {
    remainingDays = clamp(daysBetween, 0, Number.MAX_SAFE_INTEGER);
  }

  const isExpired = remainingDays <= 0;

  const status = apiKey.active ? "enabled" : "disabled";

  return {
    status,
    isExpired,
    remainingDays,
  };
};
