import type { MenuHorizontalElementProps } from "@Components";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";

export let APP_ZOOM = 100;
export let APP_ZOOM_PERCENTAGE = 1;

export type UIStoreProperties = {
  buttons: Array<MenuHorizontalElementProps>;
  appZoom: number;
  appZoomPercentage: number;
};

export type UIStore = UIStoreProperties & {
  setButtons: (buttons: Array<MenuHorizontalElementProps>) => void;
  setAppZoom: Dispatch<number>;
};

const initialState: UIStoreProperties = {
  buttons: [],
  appZoom: 100,
  appZoomPercentage: 1,
};

export const useUIStore = createStore<UIStore>((get, set) => ({
  ...initialState,
  setButtons: buttons => set(state => ({ ...state, buttons }), true),
  setAppZoom: appZoom =>
    set(state => {
      APP_ZOOM = appZoom;
      APP_ZOOM_PERCENTAGE = appZoom / 100;
      return { ...state, appZoom, appZoomPercentage: appZoom / 100 };
    }),
}));
