import type { Dispatch } from "react";
import { Button } from "@Components";

export const ViewSwitch = ({
  showChart,
  setShowChart,
}: {
  showChart: boolean;
  setShowChart: Dispatch<boolean>;
}) => (
  <div className="view-switch">
    <Button
      iconClass="bar-chart"
      color={showChart ? "primary" : "secondary"}
      onClick={() => setShowChart(true)}
      size="sm"
      className={showChart ? "active" : ""}
    />
    <Button
      iconClass="list"
      color={showChart ? "secondary" : "primary"}
      onClick={() => setShowChart(false)}
      size="sm"
      className={`${showChart ? "" : "active"}`}
    />
  </div>
);
