import { useMemo, type FC } from "react";
import type { SidebarNewUserProps } from "./SidebarNewUser.i";
import type { ButtonProps } from "@Components";
import { Offcanvas } from "@Components";
import { InviteOrganizationUserForm } from "src/Components/Form/UserForm";
import { useSidebarNewUser } from "./useSidebarNewUser";

export const SidebarNewUser: FC<SidebarNewUserProps> = props => {
  const {
    handleOnClose,
    handleSubmit,
    isCanvasLoading,
    isFormValid,
    isOpen,
    setIsFormValid,
    setUser,
    user,
  } = useSidebarNewUser(props);

  const submitButton: ButtonProps[] = useMemo(() => {
    return [
      {
        color: "primary",
        children: "organization.invite",
        loading: isCanvasLoading,
        disabled: !isFormValid,
        onClick: handleSubmit,
        iconClass: "bi bi-person-fill-add",
        data: { cy: "submit-user-canvas" },
      },
    ];
  }, [handleSubmit, isCanvasLoading, isFormValid]);

  return (
    <Offcanvas
      isOpen={isOpen}
      title={"organization.invite-user"}
      toggle={handleOnClose}
      buttons={submitButton}
      id="invite-user-canvas"
    >
      <InviteOrganizationUserForm
        user={user}
        setUser={setUser}
        disabled={isCanvasLoading}
        setFormValid={setIsFormValid}
      />
    </Offcanvas>
  );
};
