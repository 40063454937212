import { KA_INVENTORY_GRAPH_DESELECT_ACTION, nodesColors } from "@Constants";
import { useKeyboardEvent, useManageApiResponse, useUrlParams } from "@Hooks";
import type { Nullable } from "@Interfaces";
import { API, type UserGetParameterGraphResponse } from "@Services";
import { getDagreLayoutedElements } from "@Utils";
import {
  useEdgesState,
  useNodesState,
  type Edge,
  type EdgeMarker,
  type Node,
  type NodeProps,
} from "@xyflow/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { parseResponseToNodesAndEdges } from "./utils";
import type {
  ParameterLinksGraphEdgeData,
  ParameterLinksGraphEdges,
  ParameterLinksGraphNodes,
  ParameterLinksGraphNodeData,
} from "@Components";

export const useAPIDefinitionGraph = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState<
    Node<ParameterLinksGraphNodeData | NodeProps>
  >([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [loading, setLoading] = useState(true);
  const { organizationId, projectId } = useUrlParams();
  const [selectedItemId, setSelectedItemId] = useState<Nullable<string>>(null);
  const memoizedNodes = useMemo(() => nodes, [nodes]);
  const memoizedEdges = useMemo(() => edges, [edges]);

  useKeyboardEvent({
    action: KA_INVENTORY_GRAPH_DESELECT_ACTION,
    onKeyDown: () => handleNodeClick(null, null),
  });

  const manageResponse = useManageApiResponse(setLoading);

  const generateLayout = useCallback(
    (nodes: ParameterLinksGraphNodes, edges: ParameterLinksGraphEdges) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getDagreLayoutedElements(nodes, edges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    },
    [setEdges, setNodes]
  );

  const fetchParameterGraph = useCallback(async () => {
    await manageResponse<UserGetParameterGraphResponse>({
      promise: API.inventory().inventoryGetParameterGraph(
        organizationId ?? "",
        projectId ?? ""
      ),
      errorMessage: "get-parameter-graph",
      onSuccess: axiosResponse => {
        const response = axiosResponse.data;
        if (response) {
          const [nodes, edges] = parseResponseToNodesAndEdges(response);
          generateLayout(nodes, edges);
        }
      },
    });
  }, [generateLayout, manageResponse, organizationId, projectId]);

  useEffect(() => {
    fetchParameterGraph();
  }, [fetchParameterGraph]);

  const handleNodeClick = useCallback(
    (
      event: Nullable<React.MouseEvent<Element>>,
      node: Nullable<Node<ParameterLinksGraphNodeData | NodeProps>>
    ) => {
      if (!node && !selectedItemId) {
        return; // nothing to do
      }

      const nodeIdsToActivate = new Set<string>();
      const willNodeBeSelected = node
        ? !nodes.find(n => n.id === node.id)?.data.selected
        : false;

      setSelectedItemId(prevSelectedItemId => {
        if (!willNodeBeSelected) {
          return null;
        }
        return node ? (node.data.selected ? null : node.id) : null;
      });

      const nextEdges = edges.map(edge => {
        const isEdgeAnimated =
          willNodeBeSelected &&
          (edge.source === node?.id || edge.target === node?.id);
        if (isEdgeAnimated) {
          nodeIdsToActivate.add(edge.source);
          nodeIdsToActivate.add(edge.target);
        }
        return {
          ...edge,
          animated: isEdgeAnimated,
          markerEnd: {
            ...(edge.markerEnd as EdgeMarker),
            color: isEdgeAnimated
              ? nodesColors.edge.marker.highlight
              : nodesColors.edge.marker.normal,
          },
        };
      });

      const nextNodes = nodes.map(n => {
        const isSelected =
          willNodeBeSelected &&
          (n.id === node?.id || nodeIdsToActivate.has(n.id));

        return {
          ...n,
          data: {
            ...n.data,
            selected: isSelected,
          },
        };
      });

      setEdges(nextEdges);
      setNodes(nextNodes);
    },
    [edges, nodes, selectedItemId, setEdges, setNodes]
  );

  return {
    nodes: memoizedNodes,
    edges: memoizedEdges,
    loading,
    selectedItemId,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    handleNodeClick,
    handleOutsideClick: () => handleNodeClick(null, null),
    handleEdgeClick: (
      event: React.MouseEvent<Element>,
      edge: Edge<ParameterLinksGraphEdgeData>
    ) =>
      handleNodeClick(
        event,
        edge.data?.sourceNode ?? edge.data?.targetNode ?? null
      ),
  };
};
