import type { ChangeEvent, KeyboardEvent } from "react";
import { useEffect, useRef } from "react";
import type { Props } from "./OtpInput.i";
import { handleInputChange, handleInputKeyPress } from "./functions";

const useOtpInput = ({ setOtp, disabled }: Props) => {
  const refs = useRef<HTMLInputElement[]>([]);

  const onChange = (event: ChangeEvent<HTMLInputElement>, index: number) =>
    handleInputChange(refs.current, event, index, setOtp);

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>, index: number) =>
    handleInputKeyPress(refs.current, event, index);

  useEffect(() => {
    if (!disabled && refs.current.length && !refs.current[0].value) {
      refs.current[0].focus();
    }
  }, [refs.current.length, disabled]);

  return {
    refs,
    onChange,
    onKeyPress,
  };
};

export default useOtpInput;
