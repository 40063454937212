import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import { handleSubmitForgotPassword } from "./functions";
import validator from "validator";
import { t } from "i18next";
import { Button } from "@Components";

export const ForgotPassword = React.memo(() => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [isSentEmail, setIsSentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const isValidEmail = validator.isEmail(email);

  const handleSendNewPassword = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setLoading(true);
    await handleSubmitForgotPassword(email, setError, setIsSentEmail);
    setLoading(false);
  };

  return isSentEmail ? (
    <>
      <h4 className="m-0 text-center">{t("login.forgot-psw.success-msg")}</h4>
      <div className="mt-5">
        <Link to="/login" className="d-grid gap-2 no-underline">
          <Button
            color="primary"
            children="login.back"
            iconClass="bi bi-arrow-left-square-fill"
          />
        </Link>
      </div>
    </>
  ) : (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to="/login">
          <Button color="secondary" iconClass="chevron-left" />
        </Link>
        <h4 className="m-0">{t("login.forgot-psw.recovery")}</h4>
      </div>
      <FormGroup>
        <Label for="email">{t("login.forgot-psw.insert-email")}</Label>
        <Input
          type="email"
          id="email"
          bsSize="sm"
          value={email}
          autoComplete="email"
          onChange={event => setEmail(event.target.value)}
          valid={!!email && isValidEmail}
          invalid={!!email && !isValidEmail}
        />
      </FormGroup>
      {error && (
        <div className="alert alert-danger mt-3" role="alert">
          {error}
        </div>
      )}
      <div className="mt-3 d-grid gap-2">
        <Button
          color="primary"
          onClick={handleSendNewPassword}
          disabled={!isValidEmail}
          loading={loading}
          children="login.forgot-psw.submit"
          iconClass="bi bi-arrow-right-square-fill"
          data={{ cy: "submit-btn" }}
        />
      </div>
    </>
  );
});
