import { Button } from "@Components";
import { useOrganizationId, usePreference } from "@Hooks";
import type { ListedRole } from "@Interfaces";
import { memo, useCallback } from "react";

export const SwitchToRole = memo(({ role }: { role: ListedRole }) => {
  const selectedOrganizationId = useOrganizationId();
  const [, setLatestOrgId] = usePreference("organization-id");

  const handleSwitchOrganization = useCallback(() => {
    setLatestOrgId(role.organizationID);
    window.location.href = "/";
  }, [role.organizationID, setLatestOrgId]);

  if (role.organizationID === selectedOrganizationId) {
    return null;
  }

  return (
    <Button
      color="primary"
      id="profile-switch-organization"
      data-cy="profile-roles-table-switch-organization"
      iconClass="bi bi-arrow-left-right"
      disabled={!role.active}
      onMouseDown={handleSwitchOrganization}
    />
  );
});
