import type { UserGetAttemptResponse } from "@Services";
import type { Attempt } from "src/Interfaces/attempt";

export const AttemptMock: UserGetAttemptResponse = {
  request:
    "POST /microservicebrokenauth/register HTTP/1.1\r\nHost: localhost:5656\r\nContent-Type: application/json\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: <nil>\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
  response:
    'HTTP/1.1 200 OK\r\nContent-Length: 33\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:35 GMT\r\n\r\n{"Status":"Success","UserID":105}',
};

export const getRandomAttemptMock = () => {
  return {
    request: MOCK_REQUESTS[Math.floor(Math.random() * MOCK_REQUESTS.length)],
    response: MOCK_RESPONSE[Math.floor(Math.random() * MOCK_RESPONSE.length)],
  } as Attempt;
};

const MOCK_REQUESTS = [
  'POST /microservicebola/pandao/api/addorder HTTP/1.1\r\nHost: local.equixly.com:5656\r\nContent-Type: application/json\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: refresh user1\r\nX-Equixly-Sequence-Namespace: 5e16329e-0ea7-4be9-83f7-099fa73f1d0e\r\n\r\n{\r\n"c_name":"New York City",\r\n"shipping_address":"07658 Michael Flat Suite 779",\r\n"value":"IlIGvJDdwnRV"\r\n}\r\n\r\n',
  "POST /microservicebrokenauth/register HTTP/1.1\r\nHost: localhost:5656\r\nContent-Type: application/json\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: <nil>\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
  "POST /microservicebrokenauth/login HTTP/1.1\r\nHost: localhost:5656\r\nContent-Type: application/json\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: <nil>\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
  "GET /microservicebrokenauth/adminpanel/tokenvalidate HTTP/1.1\r\nHost: localhost:5656\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: AP_45639490\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
  "GET /microservicebrokenauth/adminpanel/tokenvalidate HTTP/1.1\r\nHost: localhost:5656\r\nAuthorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InVzZXIxQGV4YW1wbGUuY29tIiwiZXhwIjoxNzE1MzI4NTIxLCJ1c2VySUQiOjIsInV1aWQiOiI2MzcwNmI1Yi00YTFlLTQwYzMtOTc4Ny00MTZiOGFmYTQxNTEifQ.JWqv7SucYVeA8ACojhq8jIYgF9kqOrGp6I0WByJtM3Y\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-AuthDetector-Iteration: http - bearer\r\n\r\n",
  "GET /microservicebrokenauth/checkexp HTTP/1.1\r\nHost: localhost:5656\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: AP_45639490\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
  "GET /microservicebrokenauth/checkexp HTTP/1.1\r\nHost: localhost:5656\r\nAuthorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InVzZXIxQGV4YW1wbGUuY29tIiwiZXhwIjoxNzE1MzI4NTIxLCJ1c2VySUQiOjIsInV1aWQiOiI2MzcwNmI1Yi00YTFlLTQwYzMtOTc4Ny00MTZiOGFmYTQxNTEifQ.JWqv7SucYVeA8ACojhq8jIYgF9kqOrGp6I0WByJtM3Y\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-AuthDetector-Iteration: http - bearer\r\n\r\n",
  "GET /microservicebrokenauth/logout HTTP/1.1\r\nHost: localhost:5656\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: AP_45639490\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
  "GET /microservicebrokenauth/logout HTTP/1.1\r\nHost: localhost:5656\r\nAuthorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InVzZXIxQGV4YW1wbGUuY29tIiwiZXhwIjoxNzE1MzI4NTIxLCJ1c2VySUQiOjIsInV1aWQiOiI2MzcwNmI1Yi00YTFlLTQwYzMtOTc4Ny00MTZiOGFmYTQxNTEifQ.JWqv7SucYVeA8ACojhq8jIYgF9kqOrGp6I0WByJtM3Y\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-AuthDetector-Iteration: http - bearer\r\n\r\n",
  "GET /microservicebrokenauth/docs/index.html HTTP/1.1\r\nHost: localhost:5656\r\nUser-Agent: Equixly/1.0 (API Security; ML-powered Testing)\r\nX-Equixly-Auth-Profile: AP_45639490\r\nX-Equixly-Sequence-Namespace: d7a7e1a1-3f25-4f36-8db8-28bfddab9d4c\r\n\r\n",
];

const MOCK_RESPONSE = [
  'HTTP/1.1 200 OK\r\nContent-Length: 33\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:35 GMT\r\n\r\n{"Status":"Success","UserID":105}',
  'HTTP/1.1 200 OK\r\nContent-Length: 274\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:41 GMT\r\n\r\n{"message":"Logged in successfully","authorization":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InVzZXIxQGV4YW1wbGUuY29tIiwiZXhwIjoxNzE1MzI4NTIxLCJ1c2VySUQiOjIsInV1aWQiOiI2MzcwNmI1Yi00YTFlLTQwYzMtOTc4Ny00MTZiOGFmYTQxNTEifQ.JWqv7SucYVeA8ACojhq8jIYgF9kqOrGp6I0WByJtM3Y"}',
  'HTTP/1.1 400 Bad Request\r\nContent-Length: 40\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:47 GMT\r\n\r\n{"error":"Authorization token required"}',
  'HTTP/1.1 200 OK\r\nContent-Length: 46\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:47 GMT \r\n\r\n{"Message":"Access to admin panel authorized"}',
  'HTTP/1.1 400 Bad Request\r\nContent-Length: 40\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:52 GMT\r\n\r\n{"error":"Authorization token required"}',
  'HTTP/1.1 200 OK\r\nContent-Length: 28\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:52 GMT\r\n\r\n{"Message":"Token is valid"}',
  'HTTP/1.1 400 Bad Request\r\nContent-Length: 40\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:53 GMT\r\n\r\n{"error":"Authorization token required"}',
  'HTTP/1.1 200 OK\r\nContent-Length: 37\r\nContent-Type: application/json; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:53 GMT\r\n\r\n{"message":"Logged out successfully"}',
  'HTTP/1.1 200 OK\r\nContent-Type: text/html; charset=utf-8\r\nDate: Tue, 07 May 2024 08:08:54 GMT\r\nContent-Length: 1024\r\n\r\n\n<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <title>Swagger UI</title>\n  <link rel="stylesheet" type="text/css" href="./swagger-ui.css" >\n  <link rel="icon" type="image/png" href="./favicon-32x32.png" sizes="32x32" />\n  <link rel="icon" type="image/png" href="./favicon-16x16.png" sizes="16x16" />\n  <style>\n    html\n    {\n        box-sizing: border-box;\n        overflow: -moz-scrollbars-vertical;\n        overflow-y: scroll;\n    }\n    *,\n    *:before,\n    *:after\n    {\n        box-sizing: inherit;\n    }\n\n    body {\n      margin:0;\n      background: #fafafa;\n    }\n  </style>\n</head>\n\n<body>\n\n<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position:absolute;width:0;height:0">\n  <defs>\n    <symbol viewBox="0 0 20 20" id="unlocked">\n          <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V6h2v-.801C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18...[truncated]',
];
