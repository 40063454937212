import { GridContainer } from "@Components";
import { t } from "i18next";

type CardType =
  | "authentication"
  | "dictionary"
  | "injectable"
  | "path-exclusion"
  | "issue-exclusion"
  | "mtls-certificate";

export const ProjectSettingsCardContainer = (props: {
  children: JSX.Element[];
  emptyLabel?: string;
  cardType?: CardType;
}) => {
  const cardSize = getCardSize(props.cardType);
  if (!props.children.length) {
    return props.emptyLabel ? (
      <ProjectSettingsCardContainerEmptyMessage label={props.emptyLabel} />
    ) : null;
  }
  return (
    <GridContainer
      children={props.children}
      className={`settings-card-container ${cardSize}`}
    />
  );
};

function getCardSize(cardType?: CardType) {
  switch (cardType) {
    case "authentication":
    case "mtls-certificate":
    case "issue-exclusion":
      return "normal";
    case "dictionary":
    case "path-exclusion":
    case "injectable":
      return "small";
    default:
      return "";
  }
}

const ProjectSettingsCardContainerEmptyMessage = ({
  label,
}: {
  label: string;
}) => (
  <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
    <i className="bi bi-info-square-fill font-size-h3 color-gray mb-4"></i>
    <h4
      className="text-center color-gray"
      style={{
        maxWidth: "75%",
        whiteSpace: "break-spaces",
        lineHeight: "2.5rem",
      }}
    >
      <i>{t(label)}</i>
    </h4>
  </div>
);
