import { Offcanvas } from "@Components";
import { pureComponent } from "@Utils";
import { memo } from "react";
import { IssuesDetail } from "./Components";
import { useIssueDetailsCanvas } from "./Hooks";

export const IssueDetailsCanvas = memo(() => {
  const { canvasOpen, canvasButtons, closeCanvas } = useIssueDetailsCanvas();
  return (
    <Offcanvas
      className="full-height unstiled"
      isOpen={canvasOpen}
      title="common.issue-detail"
      toggle={closeCanvas}
      children={<IssuesDetail />}
      size="md"
      buttons={canvasButtons}
      exitLabel="common.close"
    />
  );
}, pureComponent);
