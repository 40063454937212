import type { HeaderItems } from "@Interfaces";

export const getHeaders = (forProject: boolean): HeaderItems => {
  const output: HeaderItems = [
    { text: "common.project" },
    { text: "common.endpoint", sortByKey: "issue_path" },
    { text: "common.severity", sortByKey: "issue_severity" },
    { text: "common.created", sortByKey: "issue_created_at" },
    { text: "common.name" },
    { text: "common.category" },
  ];

  if (forProject) {
    output.shift();
  }

  output.push("OWASP");
  return output;
};
