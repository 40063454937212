import type { Dispatch } from "react";
import { useCallback } from "react";
import type { NewScanButtonProps } from "../NewScanButton.i";
import { API, type UserCreateScanRequest } from "@Services";
import { useManageApiResponse, useToast, useUrlParams } from "@Hooks";
import { isStatusCodeSuccess } from "@Utils";

type HookProps = NewScanButtonProps & {
  setLoading: Dispatch<boolean>;
};

const errorMessageMap: Record<number, string> = {
  402: "errors.scan.insufficient-tokens",
  409: "errors.scan.license-quantity-is-zero",
  423: "errors.scan.scan-already-running",
  429: "errors.scan.scanned-projects-limit-reached",
};

export const useApiCall = ({
  onAPIsuccess,
  showSuccessToast,
  setLoading,
}: HookProps) => {
  const manageResponse = useManageApiResponse(setLoading);
  const { organizationId, projectId } = useUrlParams();
  const toast = useToast();

  const handleSubmit = useCallback(
    async (requestBody: UserCreateScanRequest) => {
      if (!organizationId || !projectId) return;
      const { status } = await manageResponse({
        promise: API.scan().createScan(organizationId, projectId, requestBody),
        successMessage: showSuccessToast ? "create-scan" : undefined,
        onSuccess: onAPIsuccess,
      });

      if (status && !isStatusCodeSuccess(status)) {
        const message = errorMessageMap[status] ?? "errors.create-scan";
        toast({ message });
      }
    },
    [
      manageResponse,
      onAPIsuccess,
      organizationId,
      projectId,
      showSuccessToast,
      toast,
    ]
  );

  return handleSubmit;
};
