import { createStore } from "@Hooks";
import type { Nullable, NullableArray } from "@Interfaces";
import type { ListedProject, SpaceLink } from "@Services";
import { getDaysBetween } from "@Utils";
import type { Dispatch } from "react";

type SpaceLinkWithExpiration = SpaceLink & {
  expired: boolean;
};

type OrganizationSpaceLinkStoreData = {
  canvasOpen: boolean;
  hasAnyItem: Nullable<boolean>;
  contentLoaded: boolean;
  spaceLinkDetails: Nullable<SpaceLinkWithExpiration>;
  spaceLinkList: NullableArray<SpaceLink>;
  associatedProjects: NullableArray<ListedProject>;
};

type OrganizationSpaceLinkStore = OrganizationSpaceLinkStoreData & {
  setCanvasOpen: Dispatch<boolean>;
  setHasAnyItem: Dispatch<boolean>;
  setContentLoaded: Dispatch<boolean>;
  setSpaceLinkDetails: Dispatch<Nullable<SpaceLink>>;
  setSpaceLinkList: Dispatch<NullableArray<SpaceLink>>;
  setAssociatedProjects: Dispatch<NullableArray<ListedProject>>;
  reset: VoidFunction;
};

const INITIAL_STATE: OrganizationSpaceLinkStoreData = {
  canvasOpen: false,
  hasAnyItem: null,
  contentLoaded: false,
  spaceLinkDetails: null,
  spaceLinkList: null,
  associatedProjects: null,
};

export const useOrganizationSpaceLinkStore =
  createStore<OrganizationSpaceLinkStore>((get, set) => ({
    ...INITIAL_STATE,
    setCanvasOpen: canvasOpen => {
      set(state => ({ ...state, canvasOpen }));
    },
    setHasAnyItem: hasAnyItem => {
      set(state => ({ ...state, hasAnyItem }));
    },
    setContentLoaded: contentLoaded => {
      set(state => ({ ...state, contentLoaded }));
    },
    setSpaceLinkDetails: spaceLinkDetails => {
      if (spaceLinkDetails === null) {
        set(state => ({ ...state, spaceLinkDetails: null, expired: null }));
        return;
      }
      const remainingDays = getDaysBetween(spaceLinkDetails.expiresAt);
      const expired = Number.isFinite(remainingDays) && remainingDays <= 0;
      const newSpaceLinkDetails: SpaceLinkWithExpiration = {
        ...spaceLinkDetails,
        expired,
      };
      set(state => ({ ...state, spaceLinkDetails: newSpaceLinkDetails }));
    },
    setSpaceLinkList: spaceLinkList => {
      set(state => ({ ...state, spaceLinkList }));
    },
    setAssociatedProjects: associatedProjects => {
      set(state => ({ ...state, associatedProjects }));
    },
    reset: () => {
      set(state => ({ ...state, ...INITIAL_STATE }));
    },
  }));
