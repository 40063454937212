import React, { useMemo } from "react";
import { Input } from "reactstrap";
import { t } from "i18next";
import type { Props } from "./PageSizeSelector.i";
import type { Options } from "@Interfaces";

export const PageSizeSelector = React.memo(
  ({ pageSize, pageSizes, setPageSize }: Props) => {
    const options: Options<number> = useMemo(() => {
      const pageLabel = t("common.page").toLowerCase();
      const result = pageSizes.map(size => ({
        label: `${size} / ${pageLabel}`,
        value: size,
      }));
      result.sort(({ value: v1 }, { value: v2 }) => v1 - v2);
      return result;
    }, [pageSizes]);
    return (
      <Input
        className="page-size-select"
        type="select"
        onChange={e => setPageSize(+e.target.value)}
        value={pageSize}
        children={options.map(({ label, value }) => (
          <option value={value} children={label} key={label} />
        ))}
      />
    );
  }
);
