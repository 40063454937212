import type { OrganizationAdminUpdateUserRoleRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getUserById, roles } from "src/Mock";

export const updateUserRoles = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { userId, organizationId } = req.params as {
    [key: string]: string;
  };
  const request: OrganizationAdminUpdateUserRoleRequest = await req.json();

  const user = getUserById(userId);
  if (!user) {
    return res(ctx.delay(delay), ctx.status(400));
  }
  const userRoleIndex = roles.findIndex(
    item => item.id === userId && item.organizationID === organizationId
  );

  if (userRoleIndex < 0) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  roles.splice(userRoleIndex, 1, { ...roles[userRoleIndex], ...request });

  return res(ctx.delay(delay), ctx.status(200));
};
