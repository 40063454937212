import { useContext, useEffect } from "react";
import { useLocation, useMatches } from "react-router-dom";
import { type MenuHorizontalElementProps } from "../../Components/MenuHorizontal";
import type { Match } from "../../Components/Router/Router.i";
import type { LicenseName, Nullable } from "@Interfaces";
import { checkRBACCondition, parseCondition } from "@Utils";
import { AuthContext } from "@Contexts";
import { useOrganizationContext } from "@Hooks";
import {
  actualVisitedPageByRegexp,
  lastVisitedPageByRegexp,
  type Role,
} from "@Services";
import { useUIStore } from "@Stores";

const checkButtonAuth = (
  button: MenuHorizontalElementProps,
  licenceName?: LicenseName,
  role?: Nullable<Role>
) => {
  if (button.visibleControl) {
    const visible = checkRBACCondition(
      parseCondition(button.visibleControl),
      role ? [role.role] : [],
      licenceName
    );

    button.hidden = !visible;
    if (button.hidden) return;
  }

  if (button.accessControl) {
    button.disabled = !checkRBACCondition(
      parseCondition(button.accessControl),
      role ? [role.role] : [],
      licenceName
    );
  }
};

const checkButtonSearch = (button: MenuHorizontalElementProps) => {
  // by default, the actual path is the target one
  button.pathWithSearch = button.path;
  if (button.recentPageSearch) {
    // else we look for actual path search url or we get the last visited one in the history
    const searchValue =
      actualVisitedPageByRegexp(button.recentPageSearch) ??
      lastVisitedPageByRegexp(button.recentPageSearch);
    if (searchValue) {
      button.pathWithSearch = searchValue;
    }
  }
};

const getButtonsFromMatches = (matches: Array<Match>) => {
  return matches.reduce((acc, match) => {
    const { handle } = match;
    if (handle && handle.buttons) {
      const { buttons } = handle;
      if (Array.isArray(buttons)) {
        acc.push(...buttons);
      } else if (typeof buttons === "function") {
        acc.push(...buttons(match.data));
      }
    }
    return acc;
  }, [] as Array<MenuHorizontalElementProps>);
};

const processButtons = (
  buttons: Array<MenuHorizontalElementProps>,
  licenceName?: LicenseName,
  role?: Nullable<Role>
) => {
  buttons.forEach(button => {
    checkButtonAuth(button, licenceName, role);
    if (!button.hidden) {
      checkButtonSearch(button);
    }
  });
};

export const UIManager = () => {
  const matches = useMatches() as Array<Match>;
  const { pathname } = useLocation();
  const { setButtons } = useUIStore();
  const { license } = useOrganizationContext();
  const { role } = useContext(AuthContext);

  useEffect(() => {
    if (license.loading || !role) return;

    const buttons = getButtonsFromMatches(matches);
    processButtons(buttons, license.name, role);
    setButtons(buttons);
  }, [matches, license, role, pathname, setButtons]);

  return null;
};
