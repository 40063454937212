import type { MockRequestParams } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, spaceLinks } from "src/Mock";

export const deleteSpaceLink = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, spaceLinkId } = req.params as MockRequestParams;

  const organizationSpaceLinks = spaceLinks.get(organizationId) ?? [];

  const index = organizationSpaceLinks.findIndex(r => r.id === spaceLinkId);

  if (index === undefined || index < 0) {
    return res(ctx.status(404), ctx.delay(delay));
  }

  const spaceLink = organizationSpaceLinks[index];

  if (spaceLink.name.includes("Undeletable")) {
    return res(ctx.status(500), ctx.delay(delay));
  }

  organizationSpaceLinks.splice(index, 1);

  spaceLinks.set(organizationId, organizationSpaceLinks);

  return res(ctx.status(200), ctx.delay(delay));
};
