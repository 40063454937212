import { memo, type FC } from "react";
import type { DisplaySectionProps } from "../DisplaySection.i";
import { t } from "i18next";
import ReactDomServer from "react-dom/server";

export const DisplaySectionTooltip: FC<Pick<DisplaySectionProps, "tooltip">> =
  memo(
    ({ tooltip }) => {
      if (!tooltip) return null;
      const tooltipContent = typeof tooltip === "string" ? t(tooltip) : tooltip;
      const tooltipJSX = <>{tooltipContent}</>;
      return (
        <i
          className="bi-info-circle ms-2"
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDomServer.renderToStaticMarkup(tooltipJSX)}
          data-tooltip-place="left"
        />
      );
    },
    (prev, current) => prev.tooltip === current.tooltip
  );
