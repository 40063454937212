import type { MockRequestParams } from "@Interfaces";
import type { OrganizationAdminUpdateSpaceLinkRequest } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, spaceLinks } from "src/Mock";

export const editSpaceLink = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, spaceLinkId } = req.params as MockRequestParams;

  const organizationSpaceLinks = spaceLinks.get(organizationId) ?? [];

  const index = organizationSpaceLinks.findIndex(r => r.id === spaceLinkId);

  if (index === undefined || index < 0) {
    return res(ctx.status(404), ctx.delay(delay));
  }

  const body = (await req.json()) as OrganizationAdminUpdateSpaceLinkRequest;

  let spaceLink = organizationSpaceLinks[index];

  spaceLink = { ...spaceLink, ...body };

  organizationSpaceLinks.splice(index, 1, spaceLink);

  spaceLinks.set(organizationId, organizationSpaceLinks);

  return res(ctx.status(200), ctx.delay(delay));
};
