import type { Method } from "@Interfaces";
import { t } from "i18next";
import type { ChangeEvent, Dispatch } from "react";
import { memo, useCallback, useState } from "react";
import isEqual from "react-fast-compare";
import { FormGroup, Input, Label } from "reactstrap";

type SelectionTogglerProps = {
  options: Method[];
  checked: boolean;
  setChecked: Dispatch<boolean>;
  currentSelection: Method[];
  setCurrentSelection: Dispatch<Method[]>;
  disabled?: boolean;
};

export const SelectionToggler = memo(
  ({
    checked,
    setChecked,
    options,
    currentSelection,
    setCurrentSelection,
    disabled,
  }: SelectionTogglerProps) => {
    const [previousSelection, setPreviousSelection] = useState([
      ...currentSelection,
    ]);

    const handleOnChange = useCallback(
      ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setChecked(checked);
        if (checked) {
          setPreviousSelection([...currentSelection]);
          setCurrentSelection([...options]);
        } else {
          setCurrentSelection([...previousSelection]);
        }
      },
      [
        currentSelection,
        options,
        previousSelection,
        setChecked,
        setCurrentSelection,
      ]
    );

    return (
      <FormGroup className="all-methods-input-wrapper" switch>
        <Input
          type="checkbox"
          id="method-combox-box-select-all-checkbox"
          checked={checked}
          onChange={handleOnChange}
          disabled={disabled}
        />
        <Label
          className="px-2 m-0"
          for="method-combox-box-select-all-checkbox"
          children={t("common.select-all")}
        />
      </FormGroup>
    );
  },
  isEqual
);
