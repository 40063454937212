import { Button, Section } from "@Components";
import { useMemo } from "react";
import {
  useOffCanvasAddParameterLinkStore,
  type ConsumerLocation,
} from "../OffCanvasAddParameterLinkStore";

export const ConsumingLocationSection = () => {
  const {
    consumingParameterLocation,
    consumingLocations,
    setConsumingParameterLocation,
    activeStep,
  } = useOffCanvasAddParameterLinkStore();

  const locationButtons = useMemo(() => {
    return ["body", "header", "cookie", "query", "path"].map(location => (
      <Button
        key={location}
        data={{ cy: `consuming-location` }}
        className={"no-text-selection"}
        disabled={consumingLocations.indexOf(location) < 0}
        onClick={() =>
          setConsumingParameterLocation(location as ConsumerLocation)
        }
        color={consumingParameterLocation === location ? "pink" : "primary"}
      >
        {location}
      </Button>
    ));
  }, [
    consumingLocations,
    consumingParameterLocation,
    setConsumingParameterLocation,
  ]);

  if (activeStep < 3) {
    return null;
  }

  return (
    <Section
      title="inventory.create-new-parameter-link-step-4"
      data-cy="consuming-location-section"
      className="mt-4"
    >
      <div className="consuming-locations p-4">{locationButtons}</div>
    </Section>
  );
};
