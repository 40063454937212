import { PROJECTS_PATH } from "@Constants";
import { useToast, useUrlParams, useValidDomain } from "@Hooks";
import type { NewProjectData, ProjectExtras } from "@Interfaces";
import type { UserCreateProjectRequest } from "@Services";
import {
  getAvailableEndpointFromLicense,
  isValid,
  projectDataToUpdateProjectData,
} from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSaveButtonProps } from "../../../Functions";
import type { RenderedCanvasProps } from "../Components.i";
import type { Props } from "./ServiceEditCanvas.i";
import { generateUrl } from "@Utils";

const useApiDefinitionEditCanvas = ({
  isOpen,
  setClosed,
  project: propsProject,
  setProject: saveProject,
  license,
}: Props) => {
  const toast = useToast();
  const { organizationId } = useUrlParams();
  const defaultProjectValue = useMemo<NewProjectData>(() => {
    const result: NewProjectData = {
      authenticationSettings: propsProject.authenticationSettings.slice(),
      baseURL: propsProject.baseURL,
      description: propsProject.description,
      dictionary: propsProject.dictionary.slice(),
      isProductionURL: propsProject.isProductionURL,
      name: propsProject.name,
      runPolicy: propsProject.runPolicy,
      oasUploadData: [],
      pathExclusion: propsProject.pathExclusion.slice(),
      extensionSettings: {
        enabledCategories: [],
      },
    };
    // advanced settings must be added ONLY is consistent
    // setting undefined means to set the values to the default
    if (propsProject.advancedSettings) {
      result.advancedSettings = propsProject.advancedSettings;
    }
    return result;
  }, [propsProject]);

  const defaultProjectExtrasValue = useMemo<ProjectExtras>(() => {
    return {
      baseUrlSuggestions: [],
      license,
      oasFile: null,
      runPolicyValid: false,
      parser: "",
    };
  }, [license]);

  const [project, setProject] = useState<UserCreateProjectRequest>(() => ({
    ...defaultProjectValue,
  }));

  const [projectExtras, setProjectExtras] = useState<ProjectExtras>(() => ({
    ...defaultProjectExtrasValue,
  }));

  const closeCanvas = useCallback(() => {
    setClosed();
    setProject({ ...defaultProjectValue });
    setProjectExtras({ ...defaultProjectExtrasValue });
  }, [
    setClosed,
    setProject,
    defaultProjectValue,
    setProjectExtras,
    defaultProjectExtrasValue,
  ]);

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { isDomainEnabled } = useValidDomain();

  useEffect(() => {
    let invalid = !!projectExtras.oasFile?.error;
    invalid ||= project.oasUploadData.length === 0;
    invalid ||= !isValid("url", project.baseURL);
    invalid ||= !isDomainEnabled(project.baseURL);
    setDisabled(invalid);
  }, [project, setDisabled, isDomainEnabled, projectExtras.oasFile?.error]);

  const navigate = useNavigate();

  const saveButton = getSaveButtonProps({
    disabled,
    loading,
    data: { cy: "submit-api-definition" },
    onClick: () => {
      setLoading(true);
      saveProject({
        ...projectDataToUpdateProjectData(propsProject),
        isProductionURL: project.isProductionURL,
        baseURL: project.baseURL,
        oasUploadData: project.oasUploadData,
      })
        .then(ok => {
          if (ok) {
            navigate(generateUrl(PROJECTS_PATH, [organizationId ?? ""]));
            toast({
              message: "successes.put-api-definition-project",
              type: "success",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  // while updating an existing project oas file, the available endpoints
  // ared evaluated as sum of current project consumption plus residual fomr license
  const availableEndpoints = useMemo(() => {
    return (
      propsProject.endpoints +
      getAvailableEndpointFromLicense(projectExtras.license)
    );
  }, [projectExtras.license, propsProject.endpoints]);

  const canvasProps: RenderedCanvasProps = {
    isOpen,
    saveButton,
    setClosed: closeCanvas,
    loading,
  };

  return {
    canvasProps,
    apiDefinition: {
      project,
      projectExtras,
      setProject,
      setProjectExtras,
      availableEndpoints,
    },
    //
  };
};

export default useApiDefinitionEditCanvas;
