import type { ColProps } from "reactstrap";

const mt = "mt-4";
const mtXL0 = `${mt} mt-xl-0`;

const colProps = ({ xs = 12, md = 12, xl = 4 }: ColProps = {}): ColProps => {
  return { xs, md, xl };
};

export const newProjectSummaryGrid = {
  project: {
    name: colProps(),
    desc: { ...colProps({ xl: 8 }), className: mtXL0 },
    other: { ...colProps(), className: mt },
  },
  service: {
    endpoints: colProps(),
    fileIdentifier: { ...colProps({ xl: 8 }), className: mtXL0 },
  },
  schedule: {
    startDate: colProps(),
    standard: { ...colProps(), className: mtXL0 },
  },
};
