import type { ProjectId, ScanId } from "@Interfaces";
import type { Category } from "@Services";
import { getScanExtensionCategories } from "@Services";
import { extensionsSettings } from "src/Mock/data";

export const getOrUpdateExtensionSettings = (
  projectId: ProjectId,
  scanId?: ScanId,
  newData?: Category[]
) => {
  const cacheKey = `${projectId}-${scanId}`;

  const cacheKeyFound = extensionsSettings.has(cacheKey);
  const hasNewData = Array.isArray(newData);

  if (cacheKeyFound && hasNewData) {
    // updating
    extensionsSettings.set(cacheKey, newData);
    return [...newData];
  }

  const cachedExtensions = extensionsSettings.get(cacheKey);
  const hasCachedData = Array.isArray(cachedExtensions);

  if (cacheKeyFound && hasCachedData) {
    // get (already created)
    const result = extensionsSettings.get(cacheKey) ?? [];
    return result;
  }

  if (!cacheKeyFound && !hasCachedData) {
    // get (first time)
    const validExtensions = getScanExtensionCategories();
    let extensions = Object.values(validExtensions);
    extensions = extensions.filter(() => Math.random() < 0.33);
    if (!extensions.length) {
      extensions.push(validExtensions[0]); // at least one must be selected
    }
    extensionsSettings.set(cacheKey, extensions);
    return extensions;
  }

  throw new Error("Invalid configuration");
};
