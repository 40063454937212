export const Pages = {
  NameAndLicense: 1,
  ApiDefinition: 2,
  Settings: 3,
  Extensions: 4,
  Schedule: 5,
  Summary: 6,
};

export const verticalStepperItems = [
  { step: 1, name: `components.vertical-stepper.name-and-license` },
  { step: 2, name: `common.api-definition` },
  { step: 3, name: `common.settings` },
  { step: 4, name: `components.vertical-stepper.security-checks` },
  { step: 5, name: `components.vertical-stepper.schedule` },
  { step: 6, name: `components.vertical-stepper.summary` },
];
