import { DisplayTable } from "@Components";
import type { RefreshDefinition } from "@Services";
import { capitalizeString } from "@Services";
import { getNanoInSecondsFormatted } from "@Utils";
import { t } from "i18next";
import { useMemo } from "react";

export const RefreshDefinitionTab = (refreshDefinition: RefreshDefinition) => {
  const data = useMemo(() => {
    let data: { [key: string]: string } = {
      "common.type": capitalizeString(refreshDefinition.type),
    };

    switch (refreshDefinition.type) {
      case "internal":
        data = {
          ...data,
          "common.path": refreshDefinition.operation?.path ?? "-",
          "common.method":
            refreshDefinition.operation?.methods.join(" ") ?? "-",
          "project.authentication-and-dictionary.token-output-key":
            refreshDefinition.tokenOutputKey ?? "-",
          "project.authentication-and-dictionary.refresh-interval-read":
            getNanoInSecondsFormatted(refreshDefinition.refreshInterval),
        };
        break;
      case "external":
        data = {
          ...data,
          "common.token-endpoint":
            refreshDefinition.oAuthData?.tokenEndpoint ?? "-",
          "project.authentication-and-dictionary.refresh-token":
            refreshDefinition.oAuthData?.refreshToken ?? "-",
          "common.client-id": refreshDefinition.oAuthData?.clientID ?? "-",
          "common.client-secret":
            refreshDefinition.oAuthData?.clientSecret ?? "-",
          "common.scope": refreshDefinition.oAuthData?.scope ?? "-",
          "project.authentication-and-dictionary.refresh-interval-read":
            getNanoInSecondsFormatted(refreshDefinition.refreshInterval),
        };
        break;
    }

    return data;
  }, [refreshDefinition]);

  return (
    <DisplayTable>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key + value}>
            <td className="text-end">{t(key)}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </DisplayTable>
  );
};
