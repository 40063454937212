import { storeJwtToken, verifyOtp } from "@Services";
import { t } from "i18next";
import type { NavigateFunction } from "react-router-dom";

export const setupOtpHandler = async (
  otp: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!otp) {
    setError(t("login.otp.insert-valid-otp") as string);
  }

  verifyOtp({
    otp,
    setError,
    setLoading,
    successCallback: (token: string) => {
      storeJwtToken(token, () => navigate("/"));
    },
    enable2fa: true,
  });
};
