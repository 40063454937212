import { FormInput, MethodsComboBox, Offcanvas, Switch } from "@Components";
import { useEditCanvas } from "./useEditCanvas";
import type { IssueExclusionFormCanvasProps } from "./IssueExclusion.i";
import { FormGroup } from "reactstrap";
import { t } from "i18next";
import type { Method } from "@Interfaces";

export const IssueExclusionEditCanvas = ({
  isOpen,
  issueExclusion,
  close,
  refresh,
}: IssueExclusionFormCanvasProps) => {
  const { issueExclusionForm, setIssueExclusionForm, loading, saveButton } =
    useEditCanvas(close, refresh, issueExclusion);

  return (
    <Offcanvas
      id="issue-exclusion-edit-canvas"
      isOpen={isOpen}
      toggle={close}
      title="common.issue-exclusion"
      buttons={[
        {
          ...saveButton.props,
          iconClass: "bi bi-floppy",
          children: "common.save",
        },
      ]}
      className={loading ? "loading" : undefined}
      children={
        issueExclusionForm ? (
          <div id="issue-exclusion-form">
            <FormGroup>
              <FormInput
                label="common.extension-name"
                type="text"
                id="issue-exclusion-extensionName"
                value={issueExclusionForm.extensionName}
                bsSize="sm"
                readOnly
              />
            </FormGroup>
            <hr></hr>
            <FormGroup>
              <FormInput
                autoTrim
                label="common.path"
                type="text"
                id="issue-exclusion-name"
                value={issueExclusionForm.path}
                bsSize="sm"
                onChange={e =>
                  setIssueExclusionForm({
                    ...issueExclusionForm,
                    path: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <span className="form-label">{t("common.methods")}</span>
              <MethodsComboBox
                methods={["GET", "POST", "PATCH", "PUT", "DELETE"]}
                activeMethods={issueExclusionForm.methods as Method[]}
                setActiveMethods={methods =>
                  setIssueExclusionForm({
                    ...issueExclusionForm,
                    methods: methods as Method[],
                  })
                }
                addAll
                minSelected={1}
              />
            </FormGroup>
            <FormGroup noMargin>
              <Switch
                title="common.false-positive"
                id="issue-exclusion-false-positive"
                checked={issueExclusionForm.falsePositive}
                showLabel={false}
                onChange={() =>
                  setIssueExclusionForm({
                    ...issueExclusionForm,
                    falsePositive: !issueExclusionForm.falsePositive,
                  })
                }
              />
            </FormGroup>
          </div>
        ) : (
          <></>
        )
      }
    />
  );
};
