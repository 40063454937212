import { joinClasses } from "@Utils";
import { t } from "i18next";
import { memo, useCallback, useMemo, useState } from "react";
import { ButtonSelection } from "./Components/ButtonSelection";
import { SelectionToggler } from "./Components/SelectionToggler";
import type { MethodsComboBoxProps } from "./MethodsComboBox.i";

export const MethodsComboBox = memo(
  ({
    methods,
    activeMethods,
    setActiveMethods,
    addAll,
    disabled,
    minSelected = 0,
    required = false,
  }: MethodsComboBoxProps) => {
    const [allMethodsFlag, setAllMethodFlag] = useState(
      !!addAll && activeMethods.length === methods.length
    );

    const indexMapper = useMemo(() => {
      const indexes = new Map<string, number>();
      methods.forEach((k, i) => indexes.set(k, i));
      return indexes;
    }, [methods]);

    const handleSetActiveMethods: typeof setActiveMethods = useCallback(
      newActiveMethods => {
        const newState = [...newActiveMethods];
        newState.sort((methodA, methodB) => {
          const indexA = indexMapper.get(methodA) || 0;
          const indexB = indexMapper.get(methodB) || 0;
          return indexA - indexB;
        });
        setActiveMethods(newState);
      },
      [indexMapper, setActiveMethods]
    );

    return (
      <div className="methods-combo-box" data-cy="methods-combo-box">
        <ButtonSelection
          currentSelection={activeMethods}
          setCurrentSelection={handleSetActiveMethods}
          options={methods}
          disabled={allMethodsFlag || disabled}
          minSelected={minSelected}
        />
        {addAll && (
          <SelectionToggler
            checked={allMethodsFlag}
            setChecked={setAllMethodFlag}
            currentSelection={activeMethods}
            setCurrentSelection={handleSetActiveMethods}
            options={methods}
            disabled={disabled}
          />
        )}
        {required && !activeMethods.length && (
          <i
            className={joinClasses(
              "ms-2 bi cursor-pointer",
              "bi-exclamation-circle text-danger"
            )}
            data-tooltip-id="tooltip"
            data-tooltip-place="top"
            data-tooltip-content={t("common.required")}
          />
        )}
      </div>
    );
  }
);
