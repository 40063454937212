import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";
import report from "./report.json";

export const getScanReport = (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  // WARNING : THE BACK-END IS NOT RESPONDING A NUMBER[] AS DECLARED, USING A BASE64 STRING INSTEAD
  // THIS ERROR WILL BE FIXED IN THE FUTURE
  return res(ctx.delay(delay * 5), ctx.json(report));
};
