import { pureComponent } from "@Utils";
import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup } from "reactstrap";
import { useLogin } from "./useLogin";
import { t } from "i18next";
import { Button, FormInput } from "@Components";
import { useDevSetting } from "./useDevSetting";
import { useResetCachedData } from "@Hooks";
import { DevMode } from "./DevMode";

export const LoginCard = memo(() => {
  const { resetCachedData } = useResetCachedData();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { handleSubmit } = useLogin();
  const {
    devMode,
    devSettings,
    headers,
    handleDevSettingChange,
    handleHeaderChange,
    handleDevSubmit,
  } = useDevSetting();

  return (
    <>
      <FormGroup>
        <FormInput
          label="login.label-username"
          type="text"
          id="username"
          bsSize="sm"
          value={username}
          autoComplete="username"
          onChange={event => setUsername(event.target.value)}
          autoTrim
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          label="common.password"
          type="password"
          id="password"
          bsSize="sm"
          value={password}
          autoComplete="current-password"
          onChange={event => setPassword(event.target.value)}
          canBeSeen
        />
      </FormGroup>
      <div className="mt-3">
        <Link to="/forgot-password">{t("login.forgot-password")}</Link>
      </div>
      {error && (
        <div className="alert alert-danger mt-3" role="alert">
          {error}
        </div>
      )}
      <div className="mt-3 d-grid gap-2">
        <Button
          type="submit"
          color="primary"
          onClick={e =>
            handleSubmit(e, username, password, setError, setLoading)
          }
          loading={loading}
          children="login.submit"
          iconClass="bi bi-box-arrow-in-right"
        />
      </div>
      {error && (
        <div id="clear-cached-data" data-cy="clear-cached-data">
          <div className="d-flex flex-column align-items-center w-100 m-0">
            <span className="font-size-caption mt-5">
              {t("login.clear-cached-data.title")}
            </span>
            <span
              onMouseDown={resetCachedData}
              className="font-size-caption mt-1 interactable"
              color={"danger"}
            >
              {t("login.clear-cached-data.action")}
            </span>
          </div>
        </div>
      )}
      {devMode && (
        <DevMode
          devSettings={devSettings}
          headers={headers}
          handleDevSettingChange={handleDevSettingChange}
          handleHeaderChange={handleHeaderChange}
          handleDevSubmit={handleDevSubmit}
        />
      )}
    </>
  );
}, pureComponent);
