import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API, isScanRunning, type ListedScan } from "@Services";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const pollingTimer = 15_000;

const tooltips = {
  scanInProgress: t("errors.scan.scan-already-running"),
  tryLater: t("errors.retrieving-data"),
};

const containsRunningScan = (list: ListedScan[]) => {
  return list.some(isScanRunning);
};

export const useNewScanButtonConfig = (response?: ListedScan[]) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [scanInProgress, setScanInProgress] = useState<boolean | null>(null); // null means "unknown"

  const fetchHasRunningScans = useFetchApiForRunningScan();

  const effectCleanUp = useCallback(() => {
    intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  const pollingRequired = useMemo(() => {
    // if current response is fetched AND do not contains any "running" scan, initiate polling
    return !!response && !containsRunningScan(response);
  }, [response]);

  useEffect(() => {
    if (!pollingRequired) return effectCleanUp;

    const fetchScans = async () => {
      const hasRunningScan = await fetchHasRunningScans();
      setScanInProgress(hasRunningScan);
    };

    fetchScans();

    intervalRef.current = setInterval(fetchScans, pollingTimer);

    return effectCleanUp;
  }, [effectCleanUp, fetchHasRunningScans, pollingRequired]);

  const tooltip = useMemo(() => {
    if (scanInProgress === null && !response) {
      return tooltips.tryLater;
    }
    if (scanInProgress === true || !pollingRequired) {
      return tooltips.scanInProgress;
    }
  }, [pollingRequired, response, scanInProgress]);

  return {
    disabled: !response || scanInProgress !== false,
    setScanInProgress,
    tooltip,
  };
};

//

const useFetchApiForRunningScan = () => {
  const { organizationId, projectId } = useUrlParams();

  const manage = useManageApiResponse();

  const fetchRunnigScanCount = useCallback(async () => {
    if (!organizationId || !projectId) return false;
    const { data } = await manage({
      promise: API.scan().scanListInProject(
        organizationId,
        projectId,
        1,
        1,
        undefined,
        undefined,
        ["scan_status", "scan_status", "scan_status"],
        ["EQ", "EQ", "EQ"],
        ["pending", "run_exploitation_phase", "run_exploration_phase"]
      ),
    });
    return !!data?.totalItems;
  }, [manage, organizationId, projectId]);

  return fetchRunnigScanCount;
};
