import {
  DictionaryCard,
  DictionaryForm,
  ProjectSettingsCardContainer,
} from "@Components";
import { useMemo } from "react";
import { useDictionary } from "./useDictionary";
import { Card } from "reactstrap";

export const Dictionary = () => {
  const { addEntry, entries, pool, removeEntry } = useDictionary();

  const cards = useMemo(() => {
    return entries.map((d, i) => (
      <DictionaryCard
        key={d.key + i}
        entry={d}
        deleteEntry={() => removeEntry(i)}
      />
    ));
  }, [entries, removeEntry]);

  return (
    <section id="dictionary">
      <Card className="border-tl-0 p-4">
        <DictionaryForm addEntry={addEntry} keywords={pool} />
        <ProjectSettingsCardContainer
          cardType="dictionary"
          emptyLabel="project.settings.no-dictionary-settings"
        >
          {cards}
        </ProjectSettingsCardContainer>
      </Card>
    </section>
  );
};
