import { Tabs } from "@Components";
import { memo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AdvancedSettings } from "../AdvancedSettings";
import { Authentication } from "../Authentication";
import { Dictionary } from "../Dictionary";
import { ErrorView } from "../ErrorView/ErrorView";
import { ExtensionsSettings } from "../ExtensionsSettings";
import { InjectableParameters } from "../InjectableParameters";
import { IssueExclusion } from "../IssueExclusion";
import { MTSLCertificates } from "../MTLSCertificates";
import { NoSettings } from "../NoSettings";
import { PathExclusion } from "../PathExclusion";
import { useScanDetailsSettings } from "./Hooks/useScanDetailSettings";
import "./style.scss";
import { useTabs } from "./useTabs";

const componentMap = [
  Authentication,
  Dictionary,
  PathExclusion,
  IssueExclusion,
  InjectableParameters,
  MTSLCertificates,
  ExtensionsSettings,
  AdvancedSettings,
];

const ContentLoader = () => {
  const { error } = useScanDetailsSettings();
  if (error) {
    throw new Error("NO DATA");
  }
  return null;
};

export const Settings = memo(() => {
  const { activeTab, setActiveTab, tabs } = useTabs();
  const ActiveComponent = componentMap[activeTab] ?? NoSettings;

  return (
    <ErrorBoundary fallback={<ErrorView />}>
      <div id="scan-settings" className="h-100">
        <ContentLoader />
        <div className="d-flex flex-grow-1 h-100">
          <div className="w-100 overflow-y-auto">
            <ActiveComponent />
          </div>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            vertical
            updateUrl
          />
        </div>
      </div>
    </ErrorBoundary>
  );
});
