/**
 * Check if an element is partially visible or completely invisible within its parent.
 *
 * @param {HTMLElement} element - The element to check.
 * @param {HTMLElement} parent - The parent element.
 * @returns {boolean} - True if the element is partially visible or completely invisible, false if it is fully visible.
 */
export const isElementPartiallyVisible = (
  element: HTMLElement,
  parent: HTMLElement
) => {
  if (!element || !parent) {
    throw new Error("Both element and parent are required");
  }

  const elementRect = element.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  // Check if the element is completely outside the parent's bounds
  const isOutside =
    elementRect.bottom <= parentRect.top ||
    elementRect.top >= parentRect.bottom ||
    elementRect.right <= parentRect.left ||
    elementRect.left >= parentRect.right;

  if (isOutside) {
    return true;
  }

  // Check if the element is partially visible
  const isPartiallyVisible =
    elementRect.top < parentRect.top ||
    elementRect.bottom > parentRect.bottom ||
    elementRect.left < parentRect.left ||
    elementRect.right > parentRect.right;

  return isPartiallyVisible;
};

/**
 * Check if an element is fully visible.
 *
 * @param {HTMLElement} element - The element to check.
 * @param {HTMLElement} parent - The parent element.
 * @returns {boolean} - True if the element is fully visible.
 */
export const isElementFullyVisible = (
  element: HTMLElement,
  parent: HTMLElement
) => {
  if (!element || !parent) {
    throw new Error("Both element and parent are required");
  }

  const elementRect = element.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  const isFullyVisible =
    elementRect.top + 10 >= parentRect.top &&
    elementRect.bottom - 10 <= parentRect.bottom &&
    elementRect.left + 10 >= parentRect.left &&
    elementRect.right - 10 <= parentRect.right;

  return isFullyVisible;
};
