import { usePreference } from "@Hooks";

export enum ENewProjectButtonState {
  PENDING,
  DISABLED,
  ENABLED,
}

export const useWithProject = () => {
  const [preference, setPreference] = usePreference(
    "project-card-version",
    "true" // default as cards
  );

  return {
    cardVersion: preference === "true",
    setCardVersion: (cardVersion: boolean) => setPreference(`${cardVersion}`),
  };
};

export default useWithProject;
