import type { UserListRolesResponse } from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAuthUserContext, roles } from "src/Mock";

export const getRoles = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const context = getAuthUserContext();
  const user = context.getUser();

  let result = roles.filter(role => {
    if (!role.expiresAt || moment().isSameOrAfter(role.expiresAt, "date")) {
      return false; // expired roles are not returned
    }
    if (!role.active) {
      return false; // disabled roles are not returned
    }
    return role.id === user?.id;
  });

  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;

  const offset = (page - 1) * size;
  const limit = offset + size;

  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  result = result.filter(role => {
    let bConditionMet = true;

    if (filters?.role_value) {
      bConditionMet &&= role.role === filters?.role_value;
    }

    return bConditionMet;
  });

  const sortBy = sP.get("sort_by");
  const sortMode = sP.get("sort_mode");

  if (sortBy) {
    switch (sortBy) {
      case "role_value": {
        result.sort((a, b) =>
          sortMode === "desc"
            ? a.role < b.role
              ? 1
              : -1
            : a.role < b.role
              ? -1
              : 1
        );
        break;
      }
      case "role_expires_at": {
        result.sort((a, b) => {
          if (sortMode === "desc") {
            return moment(b.expiresAt).diff(moment(a.expiresAt));
          } else {
            return moment(a.expiresAt).diff(moment(b.expiresAt));
          }
        });
        break;
      }
      case "role_active": {
        result.sort((a, b) =>
          sortMode === "desc"
            ? +a.active < +b.active
              ? 1
              : -1
            : +a.active < +b.active
              ? -1
              : 1
        );
        break;
      }
    }
  }

  const responseData = result.slice(offset, limit);

  const response: UserListRolesResponse = {
    data: responseData,
    page,
    size,
    totalItems: result.length,
    totalPages: Math.ceil(result.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
