import type { ButtonProps } from "@Components";
import { Offcanvas } from "@Components";
import { useMemo, type FC } from "react";
import type { AuthenticationSettingsFormProps } from "./AuthenticationSettingForm.i";
import {
  DataViewer,
  FlowForm,
  InjectableAndCertificateAccordion,
  MainForm,
} from "./Components";
import { useAuthenticationSettingsForm } from "./Hooks/useAuthenticationSettingsForm";

import "./AuthenticationSettingsForm.style.scss";

export const AuthenticationSettingsForm: FC<
  AuthenticationSettingsFormProps
> = props => {
  const { handleOnSubmit, mode, subFormValidities, loading, setLoading } =
    useAuthenticationSettingsForm(props);

  const buttons = useMemo<ButtonProps[]>(() => {
    return [
      {
        color: "primary",
        disabled: !Object.values(subFormValidities).every(Boolean),
        iconClass: mode === "insert" ? "plus-square-fill" : "floppy",
        children: mode === "insert" ? "common.add" : "common.save",
        data: { cy: "authentication-settings-button-submit" },
        loading,
        onClick: () => {
          setLoading(true);
          handleOnSubmit().finally(() => setLoading(false));
        },
      },
    ];
  }, [handleOnSubmit, loading, mode, setLoading, subFormValidities]);

  return (
    <Offcanvas
      isOpen={props.show}
      title="common.authentication-setting"
      toggle={props.setShow}
      buttons={buttons}
      id="authentication-settings-form"
    >
      <MainForm invalidNames={props.invalidAuthenticationSettingsNames} />
      <FlowForm />
      <InjectableAndCertificateAccordion />
      <DataViewer />
    </Offcanvas>
  );
};
