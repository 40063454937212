import {
  isScanRunning,
  type ListedScan,
  type UserCreateScanResponse,
} from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  generateRandomUUID,
  getOrganizationLicense,
  organizationHasProject,
  projects,
  scans,
} from "src/Mock";

export const createScan = (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId, projectId } = req.params as Record<string, string>;

  const response: UserCreateScanResponse = {};

  if (!organizationHasProject(organizationId, projectId)) {
    return res(ctx.delay(delay), ctx.status(403), ctx.json(response));
  }

  const index = projects.findIndex(p => p.id === projectId);
  const project = projects[index];

  if (project.status !== "ready" || !!project.archivedAt) {
    // feature valid for not archived project in "ready" status
    return res(ctx.delay(delay), ctx.status(403), ctx.json(response));
  }

  const license = getOrganizationLicense(organizationId);

  if (!license) {
    return res(ctx.delay(delay), ctx.status(403), ctx.json(response));
  }

  const licenseName = license.name;

  if (license.quantity === 0) {
    return res(ctx.delay(delay), ctx.status(409), ctx.json(response));
  } else if (licenseName === "MSSP" && !license.residual) {
    return res(ctx.delay(delay), ctx.status(402), ctx.json(response));
  } else if (["Standard", "Startup"].includes(licenseName)) {
    // Faking organizationscan counter limit reached
    if (license.residual < 40) {
      return res(ctx.delay(delay), ctx.status(429), ctx.json(response));
    }
  }

  const organizationScans = scans.get(organizationId) ?? [];

  const projectHasRunningScan = (s: ListedScan) => {
    return s.projectID === projectId && isScanRunning(s);
  };

  if (organizationScans.some(projectHasRunningScan)) {
    return res(ctx.delay(delay), ctx.status(423));
  }

  const scan: ListedScan = {
    id: generateRandomUUID(),
    severityCount: {
      high: 0,
      information: 0,
      low: 0,
      medium: 0,
    },
    startedAt: moment().toISOString(),
    completedAt: "",
    status: "pending",
    triggeredBy: "API",
    exploitation: false,
    projectID: project.id,
    projectName: project.name,
    lastError: "",
  };

  organizationScans.unshift(scan);

  scans.set(organizationId, organizationScans);

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
