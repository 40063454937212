import { Button, ComboBox, FormInput } from "@Components";
import { t } from "i18next";
import { Col, Label, Row } from "reactstrap";
import type { Props } from "./DictionaryForm.i";
import { useDictionaryForm } from "./useDictionaryForm";
export const DictionaryForm = (props: Props) => {
  const { key, value, comboBoxData, saveButton } = useDictionaryForm(props);
  return (
    <div id="dictionary-form">
      <Row>
        <Col>
          <Label for="dictionary-key" children={t("common.key")} />
          <ComboBox
            id="dictionary-key"
            value={key.value}
            bsSize="sm"
            data={comboBoxData}
            onChange={key.setValue}
            onBlur={() => key.setValue(key.value.trim())}
            valid={key.valid}
            invalid={!key.valid}
          />
        </Col>
        <Col>
          <FormInput
            type="text"
            label="common.value"
            id="dictionary-value"
            bsSize="sm"
            value={value.value}
            onChange={e => value.setValue(e.target.value)}
            onBlur={e => value.setValue(e.target.value.trim())}
            placeholder={t("common.empty-string").toLowerCase()}
          />
        </Col>
      </Row>
      {saveButton.show && (
        <Row className="mt-3">
          <Col xs={12} className="text-end">
            <Button
              id="add-dictionary-button"
              children="common.add"
              iconClass="bi bi-plus-square-fill"
              color="primary"
              disabled={saveButton.disabled}
              onClick={saveButton.onClick}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
