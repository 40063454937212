import { Callout, Offcanvas } from "@Components";
import { t } from "i18next";
import { memo } from "react";
import isEqual from "react-fast-compare";
import { ConsumingLocationSection } from "./Sections/ConsumingLocationSection";
import { ConsumingParameterPathSection } from "./Sections/ConsumingParameterPathSection";
import { ConsumingItemSection } from "./Sections/ConsumingItemSection";
import { ProducingLocationSection } from "./Sections/ProducingLocationSection";
import { ProducingParameterPathSection } from "./Sections/ProducingParameterPathSection";
import { SummarySection } from "./Sections/SummarySection";
import "./style.scss";
import { useOffCanvasAddParameterLink } from "./useOffCanvasAddParameterLink";

type OffCanvasAddParameterLinkProps = {
  onClose: VoidFunction;
};

export const OffCanvasAddParameterLink = memo(
  ({ onClose }: OffCanvasAddParameterLinkProps) => {
    const { saveButton } = useOffCanvasAddParameterLink(onClose);
    return (
      <Offcanvas
        id="parameter-link-add-canvas"
        data-cy="parameter-link-add-canvas"
        isOpen={true}
        title="inventory.create-new-parameter-link"
        toggle={onClose}
        buttons={[saveButton]}
      >
        <div id="inventory-details-add-parameter-link">
          <Callout level="warning">
            <p className="m-0">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("inventory.callout-info-producer"),
                }}
              />
              <i
                className="bi-info-circle ms-2"
                data-tooltip-id="tooltip"
                data-tooltip-content={t(
                  "documentation.parameter-links-producer"
                )}
                data-tooltip-place="left"
              />
            </p>
          </Callout>
          <ProducingLocationSection />
          <ProducingParameterPathSection />
          <ConsumingItemSection />
          <ConsumingLocationSection />
          <ConsumingParameterPathSection />
          <SummarySection />
        </div>
      </Offcanvas>
    );
  },
  isEqual
);
