import type { FocusEvent } from "react";
import type { FormInputProps } from "./FormInput.i";

export const handleAutoTrim = (
  focusEvent: FocusEvent<HTMLInputElement>,
  inputProps: FormInputProps
) => {
  const { onBlur, onChange, autoTrim } = inputProps;

  onBlur?.(focusEvent);

  if (!autoTrim || !onChange) {
    return;
  }

  const { value } = focusEvent.target;
  const trimmedValue = value.trim();

  if (value === trimmedValue) {
    return;
  }

  const changeEvent = { ...focusEvent };
  changeEvent.target.value = trimmedValue;
  onChange(changeEvent);
};
