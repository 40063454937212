import { useUrlParams } from "@Hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OrganizationDashboardData } from "../../Dashboard.i";
import { fetchSummaryAndChart } from "./functions";
import { getTotalIssueFromSeverityCount } from "@Services";

export const useDashboardOrganization = () => {
  const { organizationId } = useUrlParams();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<OrganizationDashboardData>({
    chartData: null,
    summary: null,
  });

  const fetchDashboardData = useCallback(() => {
    if (!organizationId) throw new Error("invalid-organization-id");
    setLoading(true);
    fetchSummaryAndChart(organizationId)
      .then(responses => {
        const [summaryResponse, chartDataResponse] = responses;
        setData({
          summary: summaryResponse.data,
          chartData: chartDataResponse.data,
        });
      })
      .finally(() => setLoading(false));
  }, [organizationId]);

  useEffect(fetchDashboardData, [fetchDashboardData]);

  const issueCounter = useMemo(
    () => getTotalIssueFromSeverityCount(data?.summary?.summary.severityCount),
    [data?.summary?.summary.severityCount]
  );

  return {
    loading,
    ...data,
    issueCounter,
  };
};
