/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserListAPIDefinitionsResponse } from '../model';
/**
 * ApiDefinitionApi - axios parameter creator
 * @export
 */
export const ApiDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the API definitions in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'api_definition_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDefinitionListInProject: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'api_definition_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiDefinitionListInProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiDefinitionListInProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/api-definitions`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria.join(COLLECTION_FORMATS.csv);
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiDefinitionApi - functional programming interface
 * @export
 */
export const ApiDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the API definitions in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'api_definition_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDefinitionListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'api_definition_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListAPIDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDefinitionListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiDefinitionApi - factory interface
 * @export
 */
export const ApiDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiDefinitionApiFp(configuration)
    return {
        /**
         * Returns the API definitions in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'api_definition_created_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDefinitionListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'api_definition_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListAPIDefinitionsResponse> {
            return localVarFp.apiDefinitionListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiDefinitionApi - interface
 * @export
 * @interface ApiDefinitionApi
 */
export interface ApiDefinitionApiInterface {
    /**
     * Returns the API definitions in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'api_definition_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiDefinitionApiInterface
     */
    apiDefinitionListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'api_definition_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListAPIDefinitionsResponse>;

}

/**
 * ApiDefinitionApi - object-oriented interface
 * @export
 * @class ApiDefinitionApi
 * @extends {BaseAPI}
 */
export class ApiDefinitionApi extends BaseAPI implements ApiDefinitionApiInterface {
    /**
     * Returns the API definitions in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'api_definition_created_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<string>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiDefinitionApi
     */
    public apiDefinitionListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'api_definition_created_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<string>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ApiDefinitionApiFp(this.configuration).apiDefinitionListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
