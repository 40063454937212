import type { DatePickerProps } from "./DatePicker.i";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import { isMaxEndDate } from "@Services";
import "./style.scss";
import ReactDatePicker from "react-datepicker";
import type { Nullable } from "@Interfaces";

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  invalid,
  disabled,
  hideValue,
  max,
  min,
  format = "dd/MM/yyyy",
  id,
  name,
}) => {
  // `value` same as `projextMaxEndDate` will disable the component automatically
  const maxEndDate = useMemo(() => isMaxEndDate(value), [value]);

  const className = useMemo(() => {
    const result = ["form-control"];
    invalid && result.push("border-danger");
    (hideValue || maxEndDate) && result.push("hidden-text");
    return result.join(" ");
  }, [maxEndDate, hideValue, invalid]);

  const isDisabled = disabled || hideValue || maxEndDate;

  const handleOnChange = useCallback(
    (date: Nullable<Date>) => {
      if (!date || isDisabled) return;
      onChange(date);
    },
    [isDisabled, onChange]
  );

  const [minDate, maxDate] = useMemo(
    () => [
      min ? moment(min).toDate() : undefined,
      max ? moment(max).toDate() : undefined,
    ],
    [min, max]
  );

  return (
    <div className="custom-date-picker">
      <ReactDatePicker
        showIcon
        id={id}
        name={name}
        className={className}
        disabled={isDisabled}
        selected={value}
        onChange={handleOnChange}
        dateFormat={format}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};
