import type { UserGetLicenseResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getOrganizationLicense } from "src/Mock";

export const getLicenses = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as Record<string, string>;

  let response: UserGetLicenseResponse | undefined = undefined;

  if (!organizationId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  response = getOrganizationLicense(organizationId);

  if (!response) {
    return res(ctx.delay(delay), ctx.status(403), ctx.json(response));
  }

  return res(ctx.delay(delay), ctx.json(response));
};
