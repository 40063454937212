import { joinClasses, pureComponent } from "@Utils";
import { memo, useMemo } from "react";
import { AllIssues, OpenIssues, Tabs } from "./Components";
import { useIssueList } from "./Hooks";
import { IssueDetailsCanvas } from "./Components/IssueDetailCanvas";
import "./IssueList.style.scss";

export const IssuesList = memo(({ forScan }: { forScan?: boolean }) => {
  const { activeTab, setActiveTab, openIssueCount, forProject } =
    useIssueList();

  const tabs = useMemo(
    () =>
      forScan ? (
        <></>
      ) : (
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          openIssueCount={openIssueCount}
          forProject={forProject}
        />
      ),
    [activeTab, forProject, forScan, openIssueCount, setActiveTab]
  );

  const content = useMemo(() => {
    if (activeTab === 1) {
      return <AllIssues />;
    }
    return <OpenIssues />;
  }, [activeTab]);

  return (
    <div id="issues" className={joinClasses(forScan && "for-scan")}>
      {tabs}
      {content}
      <IssueDetailsCanvas />
    </div>
  );
}, pureComponent);
