import type { ScanId } from "@Interfaces";
import type { UserReportScanResponse } from "@Services";
import { binaryArrayToString } from "@Services";

interface Args {
  scanId: ScanId;
  data: UserReportScanResponse["data"] | string;
}

function getContentFromResponse(data: Args["data"]): string {
  // WARNING : THE BACK-END IS NOT RESPONDING A NUMBER[] AS DECLARED, USING A BASE64 STRING INSTEAD
  // THIS ERROR WILL BE FIXED IN THE FUTURE
  if (typeof data === "string") {
    return data;
  } else {
    return binaryArrayToString(data);
  }
}

export const downloadReport = ({ scanId, data }: Args) => {
  const contentBase64 = getContentFromResponse(data);
  const linkSource = `data:application/zip;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = `${scanId}.zip`;
  downloadLink.click();
};
