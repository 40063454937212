import type { ParameterLinkEdges, ParameterLinkNodeData } from "@Components";
import { BASE_EDGE } from "@Constants";
import type { InventoryDetail, Nullable } from "@Interfaces";
import { type ParameterLinksEntry } from "@Services";
import { hashString } from "@Utils";
import type { Edge } from "@xyflow/react";
import { type Node, type NodeProps } from "@xyflow/react";

const EMPTY_DATA = {
  nodes: [
    {
      id: "no-data",
      position: { x: 0, y: 0 },
      type: "noData",
    } as Node<NodeProps>,
  ],
  edges: [],
};

export const parseParameterLinksIntoNodesAndEdges = (
  parameterLinks: Array<ParameterLinksEntry>,
  details: Nullable<InventoryDetail>
) => {
  if (!details) {
    return EMPTY_DATA;
  }

  const relevantParameterLinks = filterRelevantParameterLinks(
    parameterLinks,
    details
  );

  if (!relevantParameterLinks.length) {
    return EMPTY_DATA;
  }

  const { producingNodes, consumingNodes, edges } = processParameterLinks(
    relevantParameterLinks
  );

  const nodes = mergeNodes([...producingNodes, ...consumingNodes]);

  return { nodes, edges };
};

const filterRelevantParameterLinks = (
  parameterLinks: Array<ParameterLinksEntry>,
  details: InventoryDetail
): Array<ParameterLinksEntry> => {
  return parameterLinks.filter(
    p =>
      (p.consumingOperationVerb === details.verb &&
        p.consumingOperationURI === details.path) ||
      (p.producingOperationVerb === details.verb &&
        p.producingOperationURI === details.path)
  );
};

const processParameterLinks = (parameterLinks: Array<ParameterLinksEntry>) => {
  const producingNodes: Record<string, Node<ParameterLinkNodeData>> = {};
  const consumingNodes: Record<string, Node<ParameterLinkNodeData>> = {};
  let edges: ParameterLinkEdges = [];
  const producingParamsAdded: Set<string> = new Set<string>();
  const consumingParamsAdded: Set<string> = new Set<string>();

  parameterLinks.forEach((parameterLink, index) => {
    const producingKey = generateKey(
      parameterLink.producingOperationURI,
      parameterLink.producingOperationVerb
    );
    const consumingKey = generateKey(
      parameterLink.consumingOperationURI,
      parameterLink.consumingOperationVerb
    );

    addOrUpdateNode(
      producingNodes,
      producingKey,
      parameterLink,
      false,
      producingParamsAdded
    );
    addOrUpdateNode(
      consumingNodes,
      consumingKey,
      parameterLink,
      true,
      consumingParamsAdded
    );

    producingParamsAdded.add(parameterLink.producingParameterPath);
    consumingParamsAdded.add(parameterLink.consumingParameterPath);

    edges.push(
      createEdge(
        producingKey,
        consumingKey,
        parameterLink,
        index,
        producingNodes,
        consumingNodes
      )
    );
  });

  edges = sanitizeEdges(edges);
  return {
    producingNodes: Object.values(producingNodes),
    consumingNodes: Object.values(consumingNodes),
    edges,
  };
};

const generateKey = (uri: string, verb: string): string => {
  return `${uri}_${verb}`.replace(/\//g, "");
};

const addOrUpdateNode = (
  nodeMap: Record<string, Node<ParameterLinkNodeData>>,
  key: string,
  parameterLink: ParameterLinksEntry,
  isConsumer: boolean,
  paramsAdded: Set<string>
) => {
  const nodeData = isConsumer
    ? createNodeData(
        parameterLink.consumingOperationURI,
        parameterLink.consumingOperationVerb,
        true,
        parameterLink.consumingParameterPath
      )
    : createNodeData(
        parameterLink.producingOperationURI,
        parameterLink.producingOperationVerb,
        false,
        parameterLink.producingParameterPath
      );

  if (!nodeMap[key]) {
    nodeMap[key] = {
      id: key,
      position: { x: 0, y: 0 },
      type: "default",
      data: nodeData,
    };
  } else if (!paramsAdded.has(nodeData.parameters[0].name)) {
    nodeMap[key].data.parameters.push(nodeData.parameters[0]);
  }
};

const createNodeData = (
  uri: string,
  verb: string,
  isConsumer: boolean,
  parameterPath: string
): ParameterLinkNodeData => ({
  name: uri,
  path: uri,
  verb,
  consumer: isConsumer,
  selected: false,
  parameters: [
    {
      input: isConsumer,
      output: !isConsumer,
      name: parameterPath,
      type: "token",
    },
  ],
});

const createEdge = (
  sourceKey: string,
  targetKey: string,
  parameterLink: ParameterLinksEntry,
  index: number,
  producingNodes: Record<string, Node<ParameterLinkNodeData>>,
  consumingNodes: Record<string, Node<ParameterLinkNodeData>>
): ParameterLinkEdges[number] => ({
  ...BASE_EDGE,
  id: hashString(
    `${sourceKey}_${parameterLink.producingParameterPath}_${parameterLink.producingOperationVerb}-${targetKey}_${parameterLink.consumingParameterPath}_${parameterLink.consumingParameterPath}`
  ),
  source: sourceKey,
  sourceHandle: `handle-source-${sourceKey}_${parameterLink.producingParameterPath}`,
  target: targetKey,
  targetHandle: `handle-target-${targetKey}_${parameterLink.consumingParameterPath}`,
  type: "deleteUserParameterLink",
  data: {
    sourceNode: producingNodes[sourceKey],
    targetNode: consumingNodes[targetKey],
    parameterLink,
    parameterLinkIndex: index,
  },
});

const mergeNodes = (
  nodes: Node<ParameterLinkNodeData>[]
): Node<ParameterLinkNodeData>[] => {
  const nodesByKey: Record<string, Node<ParameterLinkNodeData>> = {};

  nodes.forEach(node => {
    if (nodesByKey[node.id]) {
      nodesByKey[node.id].data.parameters.push(...node.data.parameters);
    } else {
      nodesByKey[node.id] = node;
    }
  });

  return Object.values(nodesByKey);
};

const sanitizeEdges = (edges: ParameterLinkEdges): ParameterLinkEdges => {
  if (!edges) {
    return [];
  }

  const seenItems: Record<string, Edge> = {};

  edges.forEach(edge => {
    const existingOne = seenItems[edge.id];
    if (!existingOne) {
      seenItems[edge.id] = edge;
    }
  });

  return Object.values(seenItems);
};
