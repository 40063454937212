import { Offcanvas } from "@Components";
import useProjectEditCanvas from "./useProjectEditCanvas";
import type { CanvasCommonProps } from "../Components.i";
import { FormGroup, Input, Label } from "reactstrap";
import { t } from "i18next";

export const ProjectEditCanvas = (props: CanvasCommonProps) => {
  const { canvasProps, form } = useProjectEditCanvas(props);

  return (
    <Offcanvas
      id="project-edit-canvas"
      isOpen={canvasProps.isOpen}
      className={canvasProps.loading ? "loading" : undefined}
      toggle={canvasProps.setClosed}
      buttons={[canvasProps.saveButton]}
      title="project.edit-canvas-titles.project"
      children={
        <>
          <FormGroup>
            <Label children={t("common.name")} for="edit-project-name" />
            <Input
              id="edit-project-name"
              type="text"
              value={form.name.value}
              onChange={form.name.setValue}
              invalid={form.name.invalid}
              onBlur={form.name.trim}
            />
          </FormGroup>
          <FormGroup>
            <Label
              children={t("common.description")}
              for="edit-project-description"
            />
            <Input
              id="edit-project-description"
              type="textarea"
              rows={6}
              value={form.description.value}
              onChange={form.description.setValue}
              invalid={form.description.invalid}
              onBlur={form.description.trim}
            />
          </FormGroup>
          <FormGroup>
            <Label
              children={t("common.base-url")}
              for="edit-project-base-url"
            />
            <Input
              id="edit-project-base-url"
              type="text"
              value={form.baseURL.value}
              onChange={form.baseURL.setValue}
              invalid={form.baseURL.invalid}
            />
          </FormGroup>
          <FormGroup switch>
            <Label
              children={t("project.production-url")}
              for="edit-project-production-url"
            />
            <Input
              id="edit-project-production-url"
              type="checkbox"
              checked={form.isProductionURL.value}
              onChange={form.isProductionURL.setValue}
              invalid={form.isProductionURL.invalid}
            />
          </FormGroup>
        </>
      }
    />
  );
};
