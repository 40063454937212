import { DisplaySection, GridContainer } from "@Components";
import { dateTimeFormat } from "@Services";
import { t } from "i18next";
import { memo, type FC } from "react";
import isEqual from "react-fast-compare";
import { useOrganizationSpaceLinkStore } from "@Stores";
import { getDaysBetween } from "@Utils";

const trimTime = true;

const labels = {
  enabled: t("common.enabled"),
  disabled: t("common.disabled"),
};

type ExpirationAndStatusFieldsProps = {
  expiresAt: string;
  active: boolean;
};

export const SpaceLinkData = memo(() => {
  const { spaceLinkDetails: spaceLink } = useOrganizationSpaceLinkStore();

  if (!spaceLink) return null;

  const { name, createdAt, active, expiresAt } = spaceLink;

  return (
    <GridContainer>
      <DisplaySection mask label="common.name" value={name} />
      <DisplaySection
        mask
        label="common.created-at"
        value={dateTimeFormat(createdAt, { trimTime })}
      />
      <ExpirationAndStatusFields expiresAt={expiresAt} active={active} />
    </GridContainer>
  );
}, isEqual);

const ExpirationAndStatusFields: FC<ExpirationAndStatusFieldsProps> = ({
  expiresAt,
  active,
}) => {
  const daysRemaining = getDaysBetween(expiresAt);
  const expired = daysRemaining <= 0;

  if (expired) {
    return (
      <>
        <DisplaySection
          mask
          label="common.expires-at"
          value={dateTimeFormat(expiresAt, { trimTime })}
        />
        <DisplaySection
          mask
          label="common.status"
          value={t("common.expired")}
        />
      </>
    );
  }

  let result = (
    <DisplaySection
      mask
      label="common.status"
      value={active ? labels.enabled : labels.disabled}
    />
  );

  const hasExpiration = Number.isFinite(daysRemaining);
  if (hasExpiration) {
    result = (
      <>
        <DisplaySection
          mask
          label="common.expires-at"
          value={dateTimeFormat(expiresAt, { trimTime })}
        />
        <DisplaySection
          mask
          label="common.remaining-days"
          value={daysRemaining}
        />
        {result}
      </>
    );
  }

  return result;
};
