import { memo, useMemo, type FC } from "react";
import type { LogViewerProps } from "./LogViewer.i";
import { InfoBadge, Section, SkeletonBox } from "@Components";
import { dateTimeFormat } from "@Services";
import { CubePink } from "@Assets";
import { t } from "i18next";

import "./style.scss";

export const LogsViewer: FC<LogViewerProps> = ({ loading, logs }) => {
  const content = useMemo(() => {
    if (loading) {
      return <Loading />;
    }
    if (!logs?.length) {
      return <NoLogs />;
    }
    return logs?.map((log, i) => (
      <div className="log-info" key={`${i}-${log.createdAt}`}>
        <div
          className="log-info__bg"
          style={{ backgroundImage: `url("${CubePink}")` }}
        ></div>
        <div className="log-info__content">
          <div className="log-info__header">
            <InfoBadge bgColor="green" children={t("common.login")} />
            <span className="log-time ms-2">
              {dateTimeFormat(log.createdAt)}
            </span>
          </div>
          <p className="log-info__info">{log.userAgent}</p>
        </div>
      </div>
    ));
  }, [loading, logs]);

  return (
    <Section title="common.actions" className="logs-viewer">
      {content}
    </Section>
  );
};

const Loading = memo(() => {
  return (
    <SkeletonBox height="100%" width="100%">
      {Array.from({ length: 12 }).map((_, i) => {
        const y = `${i * 6}rem`;
        return (
          <rect x="0" y={y} rx="3" ry="3" width="100%" height="5rem" key={i} />
        );
      })}
    </SkeletonBox>
  );
});

const NoLogs = () => {
  return (
    <div className="text-center p-4">
      <h5>{t("common.no-logs")}</h5>
    </div>
  );
};
