import { t } from "i18next";
import { DashboardCard, NoVulnerableData } from "../Components";
import type { DailySeverityCount } from "@Services";
import { VulnerabilityChart } from "@Components";
import { DASHBOARD_ISSUE_PERIOD } from "../constants";
import type { DashboardCardProps } from "./DashboardCard.i";

export const WeeklyTrendCard = ({
  showNoData,
  vulnerabilities,
}: DashboardCardProps<{
  vulnerabilities?: DailySeverityCount[];
}>) => {
  return (
    <DashboardCard className="titled weekly-severity-trend">
      <span className="title">{t("common.weekly-severity-trend")}</span>
      {showNoData || !vulnerabilities?.length ? (
        <NoVulnerableData text="common.no-issues" />
      ) : (
        <div className="position-relative h-100 w-100">
          <VulnerabilityChart
            vulnerabilities={vulnerabilities}
            period={DASHBOARD_ISSUE_PERIOD}
          />
        </div>
      )}
    </DashboardCard>
  );
};
