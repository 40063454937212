import type { Injectable } from "@Services";
import { InjectableDisplayTable } from "@Components";
import { memo } from "react";

export const InjectablesTab = memo((props: { injectables: Injectable[] }) => {
  return (
    <>
      {props.injectables.map((entry, i) => (
        <div className={i ? "mt-3" : undefined} key={entry.name}>
          <InjectableDisplayTable injectable={entry} />
        </div>
      ))}
    </>
  );
});
