import { t } from "i18next";
import { FormGroup, Input, Label } from "reactstrap";

type Props = {
  label: string;
  name: string;
  value: string;
  valid?: boolean;
  invalid?: boolean;
  onChange: (value: string) => void;
};

export const SimplePasswordInput = ({
  label,
  name,
  onChange,
  value,
  valid,
  invalid,
}: Props) => {
  return (
    <FormGroup>
      <Label for={name}>{t(label)}</Label>
      <Input
        type="password"
        id={name}
        bsSize="sm"
        autoComplete="off"
        value={value}
        onChange={e => onChange(e.target.value)}
        valid={valid}
        invalid={invalid}
      />
    </FormGroup>
  );
};
