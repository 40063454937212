import { t } from "i18next";
import type { ChangeEvent, Dispatch, FC } from "react";
import { InputGroup } from "reactstrap";
import { FormInput, FormMessage } from "@Components";

type NameInputProps = {
  value: string;
  onChange: Dispatch<ChangeEvent<HTMLInputElement>>;
  valid: boolean;
  editable: boolean;
  duplicated: boolean;
  disabled: boolean;
};

export const NameInput: FC<NameInputProps> = ({
  duplicated,
  editable,
  onChange,
  valid,
  value,
  disabled,
}) => {
  return (
    <>
      <InputGroup className="flex-nowrap">
        <FormInput
          className="flex-grow-1"
          type="text"
          label="common.name"
          name="name"
          id="authentication-setting-name"
          value={value}
          onChange={editable ? onChange : undefined}
          bsSize="sm"
          valid={valid}
          invalid={!valid}
          autoTrim
          autoComplete="off"
          disabled={!editable || disabled}
          data-cy="authentication-settings-input-name"
          required
        />
      </InputGroup>
      {duplicated && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.name") })}
        />
      )}
    </>
  );
};
