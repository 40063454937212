import {
  InfoBadge,
  InventoryFilters,
  ListItems,
  Paginator,
  Section,
} from "@Components";
import { ProjectContext } from "@Contexts";
import { memo, useContext } from "react";
import { Card } from "reactstrap";
import { useAPIDefinitionList } from "../Hooks/useAPIDefinitionList";

export const APIDefinitionList = memo(() => {
  const {
    config,
    fetch,
    loading,
    defaultFilters,
    headers,
    response,
    updateFilters,
  } = useAPIDefinitionList();
  const { project } = useContext(ProjectContext);

  return (
    <div id="api-definition-list">
      <Card className="p-4 border-tl-0">
        <div className="d-flex flex-column mb-4 justify-content-start align-items-start">
          <b>Base URL</b>
          <InfoBadge>{project?.baseURL ?? "-"}</InfoBadge>
        </div>
        <Section removeLowerRadius>
          <InventoryFilters refresh={fetch} updateFilters={updateFilters} />
        </Section>
        <ListItems
          headers={headers}
          showSkeleton={loading}
          config={config}
          cardVersion={false}
          noDataMessage="common.no-endpoints-found"
          clickable
          updateCardItemOrder={[0, 2, 4, 3, 1, 5]}
          fullRowIndexes={[0, 1]}
          setSorts={updateFilters}
          defaultSorts={defaultFilters}
        />
        <Paginator
          setFilters={updateFilters}
          totalItems={response?.totalItems}
        />
      </Card>
    </div>
  );
});
