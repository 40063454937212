import { t } from "i18next";
import { DashboardCard, NoVulnerableData } from "../Components";
import { capitalizeString } from "@Services";
import type { DashboardCardProps } from "./DashboardCard.i";

export const RiskLevelCard = ({
  riskLevel,
  showNoData,
}: DashboardCardProps<{ riskLevel?: string }>) => {
  return (
    <DashboardCard className="risk-level">
      {showNoData || !riskLevel ? (
        <NoVulnerableData direction="row" text="common.no-risk-level" />
      ) : (
        <div className="d-inline-block">
          <span className={`response-value color-severity-${riskLevel}`}>
            {capitalizeString(riskLevel)}
          </span>
          <span>{t("common.risk-level")}</span>
        </div>
      )}
    </DashboardCard>
  );
};
