import type { Method } from "@Interfaces";
import type { Dispatch } from "react";
import { useMemo } from "react";
import { Button } from "reactstrap";

type ButtonSelectionProps = {
  options: Method[];
  currentSelection: Method[];
  setCurrentSelection: Dispatch<Method[]>;
  disabled?: boolean;
  minSelected: number;
};

export const ButtonSelection = ({
  options,
  currentSelection,
  setCurrentSelection,
  disabled,
  minSelected,
}: ButtonSelectionProps) => {
  const content = useMemo(() => {
    return options.map(method => {
      const activeIndex = currentSelection.findIndex(m => m === method);
      const active = activeIndex >= 0;
      const required = active && currentSelection.length <= minSelected;

      const handleOnClick = () => {
        if (required) return false; // do nothing. at least {minSelected} buttons should be selected
        const newSelection = [...currentSelection];
        if (active) newSelection.splice(activeIndex, 1);
        else newSelection.push(method);
        setCurrentSelection(newSelection);
      };

      return (
        <Button
          key={method}
          color={active ? "primary" : "secondary"}
          children={method}
          disabled={disabled}
          onClick={handleOnClick}
          size="sm"
          required={required || undefined}
        />
      );
    });
  }, [currentSelection, disabled, minSelected, options, setCurrentSelection]);
  return <>{content}</>;
};
