import { useOrganizationMember } from "./Hooks/useOrganizationMember";
import { GridContainer, LogsViewer } from "@Components";
import { ErrorPage, LoadingPage, UserSectionDetails } from "./Components";
import { useMemo } from "react";
import "./style.scss";

export const OrganizationMember = () => {
  const { user, userLoading, logs, logsLoading, fetchUser } =
    useOrganizationMember();

  const loading = !!userLoading || logsLoading;
  const allFetched = !!user && !!logs;

  const content = useMemo(() => {
    if (loading) return <LoadingPage />;
    if (!allFetched) return <ErrorPage />;
    return (
      <GridContainer>
        <UserSectionDetails user={user} fetchUser={fetchUser} />
        <LogsViewer logs={logs}></LogsViewer>
      </GridContainer>
    );
  }, [allFetched, fetchUser, loading, logs, user]);

  return <div id="organization-member">{content}</div>;
};
