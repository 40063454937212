import { t } from "i18next";

type PendingTabProps = {
  customLabel?: string;
};
export const PendingTab = ({ customLabel }: PendingTabProps) => {
  return (
    <div
      className="d-flex w-100 align-items-center h-100"
      id="scan-details-pending-tab"
    >
      <div className="text-center w-100 d-flex align-items-center justify-content-center">
        <i className="bi bi-exclamation-square-fill font-size-h3"></i>{" "}
        <h1 className="m-0 ms-3">
          {t(customLabel ?? "scans.scan-in-progress")}
        </h1>
      </div>
    </div>
  );
};
