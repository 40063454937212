export const HOME_PATH = "/organizations/:organizationId";
export const HOME_SCANS_URL = `${HOME_PATH}/scans`;
export const HOME_ISSUES_URL = `${HOME_PATH}/issues`;
export const HOME_SCAN_URL = `${HOME_PATH}/project/:projectId/scans/:scanId`;
export const HOME_ISSUE_URL = `${HOME_SCAN_URL}/issues/:issueId`;

export const PROJECTS_PATH = `/organizations/:organizationId/projects`;
export const PROJECT_DETAILS_URL = `${PROJECTS_PATH}/:projectId`;
export const PROJECT_DETAILS_SCANS_URL = `${PROJECT_DETAILS_URL}/scans`;
export const PROJECT_DETAILS_SCAN_URL = `${PROJECT_DETAILS_URL}/scans/:scanId`;
export const PROJECT_DETAILS_SCAN_ISSUES_URL = `${PROJECT_DETAILS_SCAN_URL}#issues`;
export const PROJECT_DETAILS_SCAN_INVENTORY_URL = `${PROJECT_DETAILS_SCAN_URL}/inventory/:inventoryId`;
export const PROJECT_DETAILS_ISSUES_URL = `${PROJECT_DETAILS_URL}/issues`;
export const PROJECT_DETAILS_ISSUE_URL = `${PROJECT_DETAILS_SCAN_URL}/issues/:issueId`;
export const PROJECT_DETAILS_SETTINGS_URL = `${PROJECT_DETAILS_URL}/settings`;
export const PROJECT_DETAILS_API_DEFINITIONS_PATH = `${PROJECT_DETAILS_URL}/api-definitions`;
export const PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH = `${PROJECT_DETAILS_API_DEFINITIONS_PATH}/:apiDefinitionId/inventory`;
export const PROJECT_DETAILS_API_DEFINITION_PATH = `${PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH}/:inventoryId`;

export const ORGANIZATION_PATH = `/organizations/:organizationId`;
export const ORGANIZATION_PATH_GENERAL = `${ORGANIZATION_PATH}/general`;
export const ORGANIZATION_PATH_NEW_PROJECT = `${ORGANIZATION_PATH}/newproject`;
export const ORGANIZATION_PATH_API_KEYS = `${ORGANIZATION_PATH}/apikeys`;
export const ORGANIZATION_PATH_SPACE_LINKS = `${ORGANIZATION_PATH}/spacelinks`;
export const ORGANIZATION_PATH_USERS = `${ORGANIZATION_PATH}/users`;

export const PROFILE_PATH = "/profile";
export const PROFILE_SHORTCUTS_PATH = `${PROFILE_PATH}/shortcuts`;

export const LOGIN_PATH = "/login";
export const MAINTENANCE_PATH = "/maintenance-in-progress";

// this looks for any recent path in the navigation with that history
// we need to take care of URL params, but at the same time avoid paths with the same root
// for instance we need to keep /projects/scans?whatever but not /projects/scans/scanId
export function projectLastVisitedPageRegExp(portion: string) {
  return new RegExp(
    `^\\/organizations\\/:organizationId\\/projects\\/:projectId\\/${portion}(?:\\?.*)?(?:#.*)?$`,
    "gi"
  );
}
export function organizationLastVisitedPageRegexp(portion: string) {
  return new RegExp(
    `^\\/organizations\\/:organizationId\\/${portion}(?:\\?.*)?(?:#.*)?$`,
    "gi"
  );
}

export const homeLastVisitedPageRegex = (portion: string) =>
  organizationLastVisitedPageRegexp(portion);

export const profileLastVisitedPageRegex = new RegExp(
  `^\\/profile(?:\\?.*)?(?:#.*)?$`,
  "gi"
);
