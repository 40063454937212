import type { Props } from "./VerticalStep.i";

export const VerticalStep = ({ name, step, className, onClick }: Props) => {
  return (
    <div
      className={`vertical-step ${className}`.trim()}
      data-step={step}
      onClick={onClick}
    >
      <b>{name}</b>
    </div>
  );
};

export type { VerticalStepData } from "./VerticalStep.i";
