import { usePreference, useResetCachedData } from "@Hooks";
import { BEM, joinClasses, pureComponent } from "@Utils";
import { t } from "i18next";
import { memo, useCallback, useContext, useState } from "react";
import {
  DateSettingsCard,
  FeedSettingsCard,
  StyleCard,
} from "../../../Components";
import { AuthContext } from "@Contexts";
import { config } from "@Services";
import { Button, ConfirmDialog } from "@Components";

const ResetSettingsButton = memo(() => {
  const { settings, setSettings } = useContext(AuthContext);
  const [, setAppTheme] = usePreference("theme");
  const [, setAppZoom] = usePreference("appZoom");

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleResetSettingsToDefaults = useCallback(() => {
    setAppTheme("system");
    setAppZoom("100");

    if (settings) {
      setSettings(
        {
          ...settings,
          notification: false,
          timeZone: config.defaultTimeZone,
        },
        true
      );
    }

    window.location.reload();
  }, [setAppTheme, setAppZoom, setSettings, settings]);

  return (
    <>
      <div className="single-option-section card mt-5">
        <i className="bi bi-gear-wide-connected font-size-h4" />
        <div>
          <h4>{t("profile.reset-settings.title")}</h4>
          <span className="font-size-caption color-gray">
            {t("profile.reset-settings.description")}
          </span>
        </div>

        <Button
          iconClass="bi bi-x-square-fill"
          color="danger"
          onMouseDown={() => setShowConfirmDialog(true)}
          data={{ cy: "reset-to-defaults-btn" }}
          children="common.restore"
        />
      </div>
      {showConfirmDialog && (
        <ConfirmDialog
          title="profile.confirmation-dialog.title.reset-settings"
          description="profile.confirmation-dialog.description.reset-settings"
          cancelLabel="profile.confirmation-dialog.cancel.reset-settings"
          confirmLabel="profile.confirmation-dialog.confirm.reset-settings"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={handleResetSettingsToDefaults}
        />
      )}
    </>
  );
}, pureComponent);

const ClearCacheButton = memo(() => {
  const { resetCachedData } = useResetCachedData();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  return (
    <>
      <div className="single-option-section card mt-3">
        <i className="bi bi-database-fill font-size-h4" />
        <div>
          <h4>{t("profile.clear-cached-data.title")}</h4>
          <span className="font-size-caption color-gray">
            {t("profile.clear-cached-data.description")}
          </span>
        </div>

        <Button
          iconClass="bi bi-trash-fill"
          color="danger"
          onMouseDown={() => setShowConfirmDialog(true)}
          data={{ cy: "clear-cached-data-btn" }}
          children="common.clear"
        />
      </div>
      {showConfirmDialog && (
        <ConfirmDialog
          title="profile.confirmation-dialog.title.clear-cache"
          description="profile.confirmation-dialog.description.clear-cache"
          cancelLabel="profile.confirmation-dialog.cancel.clear-cache"
          confirmLabel="profile.confirmation-dialog.confirm.clear-cache"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={resetCachedData}
        />
      )}
    </>
  );
}, pureComponent);

export const ProfileDetailsPageSettingsTab = memo(() => {
  return (
    <>
      <div
        className={joinClasses(BEM("profile-details", "container"), "card")}
        id="profile-details-settings-tab"
      >
        <DateSettingsCard />
        <FeedSettingsCard />
        <StyleCard />

        <ResetSettingsButton />
        <ClearCacheButton />
      </div>
    </>
  );
}, pureComponent);
