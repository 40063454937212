// --------------------------
// - USERS

import type { OrganizationUser } from "@Interfaces";
import { projectMaxEndDate } from "@Services";
import moment from "moment-timezone";

// --------------------------
export const users: OrganizationUser[] = [
  {
    id: "11",
    name: "Leonardo",
    surname: "Rossi",
    email: "leonardo.rossi@organization1.com",
  },
  {
    id: "12",
    name: "Sofia",
    surname: "Russo",
    email: "sofia.russo@organization1.com",
  },
  {
    id: "13",
    name: "Alessandro",
    surname: "Ferrari",
    email: "alessandro.ferrari@organization2.com",
  },
  {
    id: "14",
    name: "Aurora",
    surname: "Bianchi",
    email: "aurora.bianchi@organization2.com",
  },
  // user not related to any org
  {
    id: "15",
    name: "Massimo",
    surname: "Orfano",
    email: "massimo.orfano@organization3.com",
  },
  // user disabled to org
  {
    id: "16",
    name: "Stefano",
    surname: "Orfano",
    email: "stefano.orfano@organization2.com",
  },
  // user with different orgs
  {
    id: "17",
    name: "Laura",
    surname: "Rossi",
    email: "laura.rossi@organization47.com",
  },
  // user related to an organization without license
  {
    id: "18",
    name: "Giulio",
    surname: "Bianchi",
    email: "giulio.bianchi@organization50.com",
  },
].map(userConfig =>
  // roles are evaluated at runTime
  ({
    active: true,
    createdAt: mockCreatedAt(),
    expiresAt: projectMaxEndDate,
    role: "",
    roleActive: false,
    twoFactorAuthenticationEnabled: true,
    lastLogin: "",
    ...userConfig,
  })
);

function mockCreatedAt() {
  const subtract = Math.floor(60 + Math.random() * 720);
  return moment().subtract(subtract, "days").format("YYYY-MM-DD");
}
