import { CubePink } from "@Assets";
import type { NewProjectData } from "@Interfaces";
import { t } from "i18next";

type Props = Pick<NewProjectData, "name">;

export const ProjectNameLabel = ({ name }: Props) => {
  return (
    <div
      id="bottom-bar-project-name"
      style={{ backgroundImage: `url("${CubePink}")` }}
    >
      <span className="color-pink white-space-nowrap pe-2">
        {t("project.new")}
      </span>
      <span data-cy="project-name">{name || t("common.untitled")}</span>
    </div>
  );
};
