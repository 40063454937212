import { BEM, joinClasses, pureComponent } from "@Utils";
import { memo } from "react";

import { ProfileCard } from "../../../Components";

export const ProfileDetailsPageGeneralTab = memo(() => {
  return (
    <div
      className={joinClasses(BEM("profile-details", "container"), "card")}
      id="profile-details-general-tab"
    >
      <ProfileCard />
    </div>
  );
}, pureComponent);
