import { Label } from "reactstrap";
import { ComboBox, GridContainer, MethodsComboBox } from "@Components";
import { t } from "i18next";
import type { PathExclusionFormProps } from "./PathExclusionForm.i";
import type { FC } from "react";
import { memo, useMemo } from "react";
import type { Method, Options } from "@Interfaces";

const allowedMethods: Method[] = ["GET", "POST", "PATCH", "PUT", "DELETE"];

export const PathExclusionForm: FC<PathExclusionFormProps> = memo(
  ({
    path,
    setPath,
    activeMethods,
    setActiveMethods,
    validities,
    paths,
    gridColumns,
  }) => {
    const comboBoxData: Options<string> = useMemo(() => {
      return (paths ?? []).map(entry => ({
        label: entry,
        value: entry,
      }));
    }, [paths]);
    return (
      <GridContainer id="path-exclusion-form" columns={gridColumns || 2}>
        <div>
          <Label for="path-exclusion-input" children={t("common.path")} />
          <ComboBox
            id="path-exclusion-input"
            data={comboBoxData}
            value={path}
            onChange={e => setPath(e.trim())}
            bsSize="sm"
            valid={validities.path}
            invalid={!validities.path}
          />
        </div>
        <div className="align-self-end">
          <span className="form-label">{t(`common.methods`)}</span>
          <MethodsComboBox
            methods={allowedMethods}
            activeMethods={activeMethods}
            setActiveMethods={setActiveMethods}
            addAll
          />
        </div>
      </GridContainer>
    );
  }
);
