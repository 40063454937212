import { equals, isDevelopment } from "@Services";
import type { FC } from "react";
import { memo, useCallback } from "react";
import { Button } from "reactstrap";

type DownloadJsonButtonProps = {
  data: object | undefined;
};
export const DownloadJsonButton: FC<DownloadJsonButtonProps> = memo(
  ({ data }) => {
    const handleDownloadJson = useCallback(() => {
      if (!data) {
        return;
      }

      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = "project.json";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }, [data]);

    if (!isDevelopment()) {
      return null;
    }

    return (
      <Button
        id="download-json-btn"
        className="m-3"
        color="pink"
        children="Download JSON"
        onMouseDown={handleDownloadJson}
        disabled={!data}
      />
    );
  },
  equals
);
