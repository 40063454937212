import type {
  AuthenticationFlow,
  AuthenticationTokenLocation,
  AuthenticationTokenScheme,
} from "@Interfaces";

export const flows: AuthenticationFlow[] = ["static_token", "credentials"];

export const locations: AuthenticationTokenLocation[] = [
  "header",
  "cookie",
  "query",
];

export const roles = ["admin", "user"];

export const schemes: AuthenticationTokenScheme[] = [
  "api-key",
  "http-bearer",
  "http-basic",
];
