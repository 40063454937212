import type { InventoryDetail } from "@Interfaces";
import { inventoryItems, organizations, parameters } from "../../data";

const getSingleInventory = (
  organizationId: string,
  inventoryItemId: string
) => {
  const orgName = (organizations.find(o => o.id === organizationId)?.name || "")
    .replaceAll(" ", "-")
    .toLowerCase();

  const inventoryItem = inventoryItems.find(i => i.id === inventoryItemId) as
    | InventoryDetail
    | undefined;

  let status = 200;

  if (inventoryItem) {
    inventoryItem.baseURL = inventoryItem.baseURL.replace("example", orgName);

    inventoryItem.name = inventoryItem.path.replaceAll("/", " ").trim();
    inventoryItem.description =
      Number(inventoryItem.id) % 2
        ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        : "";

    inventoryItem.excluded = +inventoryItemId === 9393;

    const getRandomElementsFromArray = <T>(p: T[], seed = 1): T[] => {
      // Calculate the hash based on the string
      let hash = 0;
      for (let i = 0; i < inventoryItem.id.length; i++) {
        hash = (hash << 5) - hash + inventoryItem.id.charCodeAt(i);
      }
      hash = Math.floor(hash / seed);

      // Extract random elements based on the hash
      const randomElements: T[] = [];
      for (let i = 0; i < Math.abs(hash) % 32; i++) {
        randomElements.push(p[Math.abs(hash + i) % p.length]);
      }
      return randomElements;
    };

    inventoryItem.headers = getRandomElementsFromArray(
      parameters.filter(p => p.in === "header")
    );
    inventoryItem.parameters = getRandomElementsFromArray(
      parameters,
      17
    ).filter(
      p =>
        !inventoryItem.headers.map(h => h.description).includes(p.description)
    );
  } else {
    status = 404;
  }

  return { status, inventoryItem };
};

export default getSingleInventory;
