import { NewProjectContext } from "@Contexts";
import type { Injectable } from "@Services";
import { useContext, useMemo } from "react";
import { useProject } from "./useProject";

const useProjectInjectables = () => {
  const { project } = useProject();
  const { project: newProject } = useContext(NewProjectContext);

  const injectables = useMemo(() => {
    let collection: Injectable[] = [];

    if (project?.injectables.length) {
      collection = [...project.injectables];
    } else if (newProject.injectables?.length) {
      collection = [...newProject.injectables];
    }

    return collection;
  }, [newProject.injectables, project?.injectables]);

  return injectables;
};

export const useGlobalInjectables = () => {
  const injectables = useProjectInjectables();
  const result = useMemo(() => {
    return injectables.filter(i => !i.associatedAuthSetting);
  }, [injectables]);
  return result;
};
