import type { UserListIssueExclusionsInProjectResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, issueExclusion } from "src/Mock";

export const listIssueExclusions = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;

  const offset = (page - 1) * size;
  const limit = offset + size;

  const response: UserListIssueExclusionsInProjectResponse = {
    data: issueExclusion.slice(offset, limit),
    page,
    size,
    totalItems: issueExclusion.length,
    totalPages: Math.ceil(issueExclusion.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
