import { useCallback, useMemo, useState } from "react";
import type { ApiDefinitionProps } from "../ApiDefinition.i";
import { useDefaultBaseUrl } from ".";
import type { UserUpdateProjectRequest } from "@Services";
import { isFilledString } from "@Services";
import type { ButtonProps } from "@Components";

export const useApiDefinition = ({
  project,
  projectExtras,
  setProject,
  setProjectExtras,
  availableEndpoints,
  updatingProjectBaseURL = "",
}: ApiDefinitionProps) => {
  const [spaceLinkCanvasOpen, setSpaceLinkCanvasOpen] = useState(false);

  // change the baseURL after uploading the oas file in page section
  useDefaultBaseUrl({
    project,
    baseUrlSuggestions: projectExtras?.oasFile?.baseUrls ?? [],
    updateBaseURL: (baseURL: string) => setProject({ ...project, baseURL }),
    originalBaseURL: updatingProjectBaseURL,
  });

  const getPathsLimit = useCallback(() => {
    return availableEndpoints;
  }, [availableEndpoints]);

  const handleSpaceLinkUpdate = useCallback(
    async (spaceLinkID: string): Promise<boolean> => {
      project.spaceLinkID = spaceLinkID;
      setProject({ ...project });
      return true;
    },
    [project, setProject]
  );

  const editSpaceLinkButton = useMemo(() => {
    const isAlreadyConnected = isFilledString(project.spaceLinkID);
    const buttonProps: ButtonProps = {
      iconClass: isAlreadyConnected ? "pencil" : "bi bi-link-45deg",
      children: isAlreadyConnected ? "common.edit" : "common.connect",
      color: "primary",
      onClick: () => setSpaceLinkCanvasOpen(true),
      data: { cy: "edit-space-links" },
    };
    return [buttonProps];
  }, [project.spaceLinkID]);

  const handleCloseSpaceLinkCanvas = useCallback(
    () => setSpaceLinkCanvasOpen(false),
    []
  );

  const handleSetSpaceLink = useCallback(
    ({ spaceLinkID }: UserUpdateProjectRequest) =>
      handleSpaceLinkUpdate(spaceLinkID ?? ""),
    [handleSpaceLinkUpdate]
  );

  return {
    fileOAS: projectExtras.oasFile,
    project,
    projectExtras,
    editSpaceLinkButton,
    spaceLinkCanvasOpen,
    setProject,
    setProjectExtras,
    getPathsLimit,
    setSpaceLinkCanvasOpen,
    handleSpaceLinkUpdate,
    handleCloseSpaceLinkCanvas,
    handleSetSpaceLink,
  };
};
