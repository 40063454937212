import { memo, useCallback, useContext, useRef, useState } from "react";
import type { Props } from "./ClipboardBadge.i";
import { ThemeContext } from "@Contexts";
import { CopySvg, Tooltip } from "@Components";
import { t } from "i18next";
import "./style.scss";
import { BEM } from "@Utils";

export const ClipboardBadge = memo(({ value, delay = 1500 }: Props) => {
  const [copied, setCopied] = useState(false);
  const { theme } = useContext(ThemeContext);
  const ref = useRef<HTMLButtonElement>(null);

  const handleOnClick = useCallback(() => {
    if (copied) return;
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), delay);
  }, [copied, delay, value]);

  return (
    <>
      <button
        disabled={copied}
        ref={ref}
        className={`btn ${BEM("btn-clipboard", null, theme)}`}
        onClick={handleOnClick}
      >
        <span className="clipboard-icon">
          {copied ? <i className="bi bi-check" /> : <CopySvg />}
        </span>
      </button>
      <Tooltip
        target={ref}
        children={t("components.clipboard-button.copied")}
        isOpen={copied}
        fade
      />
    </>
  );
});
