import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setupOtpHandler } from "./functions";

const useSetupOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (otp.length === 6) {
      error && setError("");
      setupOtpHandler(otp, setError, navigate, setLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return {
    setError,
    setOtp,
    loading,
    error,
  };
};

export default useSetupOtp;
