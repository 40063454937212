import { DisplayRow } from "..";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";
import { memo } from "react";

type DisplayRowApiDefinitionProps = {
  fileName: {
    label: string;
    value: string;
  };
  endpoints: number;
} & Pick<MainProps, "buttonProps">;

export const DisplayRowApiDefinition = memo(
  ({ fileName, endpoints, buttonProps }: DisplayRowApiDefinitionProps) => (
    <DisplayRow
      title="common.api-definition"
      columns={[
        {
          label: "common.endpoints",
          value: endpoints ? `${endpoints}` : "-",
          className: "mb-4 mb-xl-0",
          xs: 12,
          xl: 4,
        },
        {
          label: fileName.label,
          value: fileName.value,
          xs: 12,
          xl: 8,
        },
      ]}
      buttonProps={buttonProps}
    />
  )
);
