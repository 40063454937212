import { memo } from "react";
import { ScansPage } from "@Pages";
import { useScans } from "./useScans";

export const ProjectScans = memo(() => {
  const { fetch, loading, response } = useScans();
  return (
    <ScansPage
      forProject={true}
      loading={loading}
      refreshScans={fetch}
      response={response}
    />
  );
});
