import { useUrlParams } from "@Hooks";
import { useIssueListStore } from "@Stores";
import { useMemo } from "react";

export const useIssueDetailsCanvasIssueIds = () => {
  const {
    organizationId: urlOrganizationId,
    projectId: urlProjectId,
    scanId: urlScanId,
  } = useUrlParams();

  const {
    organizationId: storeOrganizationId,
    projectId: storeProjectId,
    scanId: storeScanId,
  } = useIssueListStore();

  const organizationId = useMemo(
    () => storeOrganizationId ?? urlOrganizationId,
    [storeOrganizationId, urlOrganizationId]
  );
  const scanId = useMemo(
    () => storeScanId ?? urlScanId,
    [storeScanId, urlScanId]
  );
  const projectId = useMemo(
    () => storeProjectId ?? urlProjectId,
    [storeProjectId, urlProjectId]
  );

  return {
    organizationId,
    projectId,
    scanId,
  };
};
