import type { ChangeEvent } from "react";
import { memo, useCallback, useMemo, useState, type FC } from "react";
import {
  Button,
  DisplayTable,
  FormInput,
  FormMessage,
  MethodBadge,
  MethodsComboBox,
  TrashButton,
} from "@Components";
import type { Method } from "@Interfaces";
import { isFilledString } from "@Services";
import { t } from "i18next";
import { FormGroup } from "reactstrap";
import { useAuthenticationSettingFormStore } from "@Stores";

const ALLOWED_METHODS: Method[] = ["GET", "POST", "PUT"];

const InputOperationsForm: FC = memo(() => {
  const {
    authenticationSettings: { inputOperations },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const [path, setPath] = useState("");
  const [methods, setMethods] = useState<Method[]>([]);

  const { duplicatePath, valid, validitied } = useMemo(() => {
    const duplicatePath = inputOperations?.find(e => e.path === path);
    const validitied = {
      path: !duplicatePath && isFilledString(path),
      methods: methods.length > 0,
    };
    const valid = Object.values(validitied).every(Boolean);
    return { duplicatePath, validitied, valid };
  }, [inputOperations, methods.length, path]);

  const handlePathChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setPath(value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    setAuthenticationSettings(prev => {
      const inputOperations = structuredClone(prev.inputOperations);
      if (!inputOperations) {
        return prev;
      }
      inputOperations.push({
        methods,
        path,
      });
      setPath("");
      return { ...prev, inputOperations };
    });
  }, [methods, path, setAuthenticationSettings]);

  return (
    <>
      <div id="input-operations-form">
        <FormInput
          id="operation-path"
          data-cy="authentication-settings-input-operation-path"
          label="common.path"
          type="text"
          bsSize="sm"
          value={path}
          valid={validitied.path}
          invalid={!validitied.path}
          onChange={handlePathChange}
          disabled={loading}
          autoTrim
        />
        <div>
          <span className="input-label">{t("common.methods")}</span>
          <MethodsComboBox
            activeMethods={methods}
            setActiveMethods={setMethods}
            methods={ALLOWED_METHODS}
            addAll={false}
            disabled={loading}
          />
        </div>
        <Button
          color="primary"
          size="sm"
          data-cy="authentication-settings-input-operations-button"
          onClick={handleSubmit}
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!valid || loading}
        />
      </div>
      {duplicatePath && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.path") })}
        />
      )}
    </>
  );
});

/**
 * Input Operations component. Contains Form and Showcase
 */
export const InputOperationsTab: FC = memo(() => {
  const {
    authenticationSettings: { inputOperations },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const length = (inputOperations ?? []).length;

  const deleteEntry = useCallback(
    (i: number) => {
      setAuthenticationSettings(prev => {
        const newInputOperations = [...(prev.inputOperations ?? [])];
        newInputOperations.splice(i, 1);
        return { ...prev, inputOperations: newInputOperations };
      });
    },
    [setAuthenticationSettings]
  );

  const table = useMemo(() => {
    if (!length) return null;
    return (
      <DisplayTable>
        <thead>
          <tr>
            <th></th>
            <th>{t("common.path")}</th>
            <th>{t("common.methods")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {inputOperations?.map(({ path, methods }, i) => {
            return (
              <tr key={`${i}-${path}`}>
                <td>{i + 1}.</td>
                <td>{path}</td>
                <td>
                  <div className="d-flex align-items-center justify-content-end">
                    {(methods as Method[]).map(m => (
                      <MethodBadge type={m} className="ms-1" key={m} />
                    ))}
                  </div>
                </td>
                <td>
                  <TrashButton
                    onClick={() => deleteEntry(i)}
                    disabled={loading}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </DisplayTable>
    );
  }, [deleteEntry, inputOperations, length, loading]);

  return (
    <>
      <FormGroup noMargin={length === 0}>
        <InputOperationsForm />
      </FormGroup>
      {table}
    </>
  );
});
