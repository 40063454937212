import { t } from "i18next";
import { useCallback, useMemo } from "react";
import { useIssueDetailsCanvasStore } from "../IssueDetailsCanvas.store";

export const useIssueDetailsCanvasExcludeButton = () => {
  const { issueDetails, setIssueExclusionCanvasOpen } =
    useIssueDetailsCanvasStore();

  const handleExcludeIssue = useCallback(async () => {
    setIssueExclusionCanvasOpen(true);
  }, [setIssueExclusionCanvasOpen]);

  const excludeButton = useMemo(() => {
    const canExclude = issueDetails && !issueDetails.excluded;

    return {
      color: "danger",
      children: "issue.exclude-btn-label",
      iconClass: "bi bi-slash-circle",
      disabled: !canExclude,
      onClick: canExclude ? handleExcludeIssue : () => false,
      tooltip: {
        content: t(
          `issue.exclude${issueDetails?.excluded ? "d" : ""}-issue-tooltip`
        ),
        place: "left",
      },
      data: {
        cy: "issue-exclude-button",
      },
    };
  }, [handleExcludeIssue, issueDetails]);

  return {
    excludeButton,
  };
};
