import type { ListItemConfigElement } from "@Components";
import { mapHeaderItemsWithSorts } from "@Services";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { defaultSorts } from "../../../constants";
import type { SpaceLinkTableProps } from "../SpaceLinkTable.i";
import { getSpaceLinkTableConfig } from "../tableConfig";
import { headerItems } from "../tableHeaders";
import { useInlineStatusToggle } from "./useInlineStatusToggle";

export const useSpaceLinkTable = ({
  updateFiltersFunction,
  selectedSpaceLinkID,
  projectSpaceLinkID,
  loading,
  spaceLinks,
  handleSpaceLinkSelect,
}: SpaceLinkTableProps) => {
  const { search } = useLocation();

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultSorts,
      items: headerItems,
      search,
      updateFunction: updateFiltersFunction,
    });
  }, [search, updateFiltersFunction]);

  const { updateInlineStatus, updatingInlineStatus } = useInlineStatusToggle();
  const config = useMemo<ListItemConfigElement[]>(() => {
    if (!spaceLinks) return [];
    return getSpaceLinkTableConfig({
      spaceLinks,
      selectedSpaceLinkID,
      projectSpaceLinkID,
      handleSpaceLinkSelect,
      updateInlineStatus,
      updatingInlineStatus,
    });
  }, [
    spaceLinks,
    selectedSpaceLinkID,
    projectSpaceLinkID,
    handleSpaceLinkSelect,
    updateInlineStatus,
    updatingInlineStatus,
  ]);

  return {
    headers,
    config,
    updateFiltersFunction,
    loading,
  };
};
