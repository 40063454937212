import type {
  NodeParameterData,
  NodeParameters,
  ParameterOrigin,
} from "@Components";
import {
  PARAMETER_LINKS_GRAPH_NODE_ITEM_HEIGHT,
  PARAMETER_LINKS_GRAPH_NODE_TITLE_HEIGHT,
  PARAMETER_LINKS_GRAPH_NODE_WIDTH,
  type ParameterLinksGraphEdges,
  type ParameterLinksGraphNodeData,
  type ParameterLinksGraphNodes,
} from "@Components";
import { BASE_EDGE } from "@Constants";
import type {
  ParameterGraphEdge,
  ParameterGraphNode,
  UserGetParameterGraphResponse,
} from "@Services";
import { APP_ZOOM_PERCENTAGE } from "@Stores";
import { Position, type Node } from "@xyflow/react";

export const parseResponseToNodesAndEdges = (
  response: UserGetParameterGraphResponse
): [ParameterLinksGraphNodes, ParameterLinksGraphEdges] => {
  const { nodes: originalNodes, edges: originalEdges } = response;

  if (!originalNodes.length || !originalEdges.length) {
    return [[], []];
  }

  const [producingEdgesByNode, consumingEdgesByNode] =
    buildEdges(originalEdges);

  let resultNodes: ParameterLinksGraphNodes = generateNodes(
    originalNodes,
    producingEdgesByNode,
    consumingEdgesByNode
  );

  const resultEdges: ParameterLinksGraphEdges = generateEdges(
    originalEdges,
    resultNodes
  );

  resultNodes = filterOutNodesWithoutConnections(resultNodes, resultEdges);

  return [resultNodes, resultEdges];
};

export const buildEdges = (
  originalEdges: ParameterGraphEdge[]
): [
  Record<string, ParameterGraphEdge[]>,
  Record<string, ParameterGraphEdge[]>,
] => {
  const producingEdgesByNode: Record<string, ParameterGraphEdge[]> = {};
  const consumingEdgesByNode: Record<string, ParameterGraphEdge[]> = {};

  originalEdges.forEach(edge => {
    const { producingInventoryItemName, consumingInventoryItemName } = edge;
    addEdgeToNode(producingEdgesByNode, producingInventoryItemName, edge);
    addEdgeToNode(consumingEdgesByNode, consumingInventoryItemName, edge);
  });

  return [producingEdgesByNode, consumingEdgesByNode];
};

const addEdgeToNode = (
  edgeMap: Record<string, ParameterGraphEdge[]>,
  nodeName: string,
  edge: ParameterGraphEdge
) => {
  if (!edgeMap[nodeName]) {
    edgeMap[nodeName] = [];
  }
  edgeMap[nodeName].push(edge);
};

export const generateEdges = (
  originalEdges: ParameterGraphEdge[],
  nodes: ParameterLinksGraphNodes
): ParameterLinksGraphEdges => {
  // create a record to use direct access to node data
  const nodesMap: Record<string, Node<ParameterLinksGraphNodeData>> = {};
  nodes.forEach(
    node => (nodesMap[node.id] = node as Node<ParameterLinksGraphNodeData>)
  );

  return originalEdges.map(edge => {
    const {
      producingInventoryItemName,
      outputName,
      consumingInventoryItemName,
      inputName,
    } = edge;
    const id = `${producingInventoryItemName}_${outputName}-${consumingInventoryItemName}_${inputName}`;
    nodesMap[producingInventoryItemName].data.origin =
      edge.origin as ParameterOrigin;
    nodesMap[consumingInventoryItemName].data.origin =
      edge.origin as ParameterOrigin;

    return {
      id,
      source: producingInventoryItemName,
      sourceHandle: `handle-source-${producingInventoryItemName}_${outputName}`,
      target: consumingInventoryItemName,
      targetHandle: `handle-target-${consumingInventoryItemName}_${inputName}`,
      data: {
        sourceNode: nodesMap[producingInventoryItemName],
        targetNode: nodesMap[consumingInventoryItemName],
      },
      ...BASE_EDGE,
    };
  });
};

export const generateNodes = (
  originalNodes: ParameterGraphNode[],
  producingEdgesByNode: Record<string, ParameterGraphEdge[]>,
  consumingEdgesByNode: Record<string, ParameterGraphEdge[]>
): ParameterLinksGraphNodes => {
  return originalNodes.map(originalNode => {
    const producingParameters = getEdgesParameters(
      producingEdgesByNode[originalNode.name] ?? [],
      true
    );
    const consumingParameters = getEdgesParameters(
      consumingEdgesByNode[originalNode.name] ?? [],
      false
    );
    const parameters = sanitizeParameters([
      ...consumingParameters,
      ...producingParameters,
    ]);

    return {
      id: originalNode.name,
      type: "default",
      position: { x: 0, y: 0 },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      data: {
        ...originalNode,
        parameters,
        origin: "engine",
      },
      width: PARAMETER_LINKS_GRAPH_NODE_WIDTH * APP_ZOOM_PERCENTAGE,
      height:
        (PARAMETER_LINKS_GRAPH_NODE_TITLE_HEIGHT +
          parameters.length * PARAMETER_LINKS_GRAPH_NODE_ITEM_HEIGHT) *
        APP_ZOOM_PERCENTAGE,
    };
  });
};

export const getEdgesParameters = (
  targetEdges: ParameterGraphEdge[],
  isProducer: boolean
): NodeParameters => {
  return targetEdges.map(edge => ({
    name: isProducer ? edge.outputName : edge.inputName,
    type: "username" /* @todo handle right type */,
    output: isProducer,
    input: !isProducer,
  }));
};

export const sanitizeParameters = (
  parameters: NodeParameters
): NodeParameters => {
  if (!parameters) {
    return [];
  }

  const seenItems: Record<string, NodeParameterData> = {};

  parameters.forEach(parameter => {
    const existingOne = seenItems[parameter.name];
    if (existingOne) {
      existingOne.output ||= parameter.output;
      existingOne.input ||= parameter.input;
    } else {
      seenItems[parameter.name] = parameter;
    }
  });

  return Object.values(seenItems);
};

export const filterOutNodesWithoutConnections = (
  nodes: ParameterLinksGraphNodes,
  edges: ParameterLinksGraphEdges
): ParameterLinksGraphNodes => {
  const connectedNodeIds = new Set<string>();

  edges.forEach(edge => {
    connectedNodeIds.add(edge.source);
    connectedNodeIds.add(edge.target);
  });

  return nodes.filter(node => connectedNodeIds.has(node.id));
};
