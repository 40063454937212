import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useNavigationHistory = () => {
  const location = useLocation();

  useEffect(() => {
    const cache = sessionStorage.getItem("recentPages");
    // Get the array of recent pages from sessionStorage
    const recentPages = (cache ? JSON.parse(cache) : []) as string[];

    // Get the current URL from the location
    const currentUrl = location.pathname + location.search + location.hash;

    if (recentPages[0] !== currentUrl) {
      // Add the current URL to the array of recent pages
      recentPages.unshift(currentUrl);
    }

    // Keep only the last 10 pages
    const updatedPages = recentPages.slice(0, 10);

    // Save the updated array to sessionStorage
    sessionStorage.setItem("recentPages", JSON.stringify(updatedPages));
  }, [location]); // This effect will run whenever the location changes
};
