import { NewProjectContext } from "@Contexts";
import { useGlobalMTLSCertificates } from "@Hooks";
import { type MTLSCertificate, equals } from "@Services";
import { useCallback, useContext, useState } from "react";

export const useMTLSCertificates = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { setProject } = useContext(NewProjectContext);

  const addCertificate = useCallback(
    async (newCertificate: MTLSCertificate) => {
      setProject(prev => {
        const mTLSCertificates = structuredClone(prev.mTLSCertificates ?? []);
        mTLSCertificates.push(newCertificate);
        return { ...prev, mTLSCertificates };
      });
      return true;
    },
    [setProject]
  );

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const removeCertificate = useCallback(
    async (c: MTLSCertificate) => {
      setProject(prev => {
        const mTLSCertificates = structuredClone(prev.mTLSCertificates ?? []);
        const indexOf = mTLSCertificates.findIndex(i => equals(i, c));
        if (indexOf === -1 || indexOf === undefined) return prev; // no update
        mTLSCertificates.splice(indexOf, 1);
        return { ...prev, mTLSCertificates };
      });
    },
    [setProject]
  );

  const globalMTLSCertificates = useGlobalMTLSCertificates();

  return {
    isOpen,
    openCanvas: open,
    closeCanvas: close,
    addCertificate,
    removeCertificate,
    globalMTLSCertificates,
  };
};
