import { memo, useMemo } from "react";
import { Tabs } from "@Components";
import { Col, Container, Row } from "reactstrap";
import { useSettings } from "./Hooks";
import {
  AdvancedSettings,
  Authentication,
  Dictionary,
  InjectableParameters,
  MTLSCertificates,
  PathExclusion,
} from "./Components";

export const Settings = memo(() => {
  const { activeTab, setActiveTab, tabs } = useSettings();
  const content = useMemo(() => {
    switch (activeTab) {
      case 0:
        return <Authentication />;
      case 1:
        return <Dictionary />;
      case 2:
        return <PathExclusion />;
      case 3:
        return <InjectableParameters />;
      case 4:
        return <MTLSCertificates />;
      case 5:
        return <AdvancedSettings />;
    }
  }, [activeTab]);
  return (
    <div id="settings">
      <Container fluid>
        <Row className="flex-column">
          <Col>
            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
            />
          </Col>
          <Col>{content}</Col>
        </Row>
      </Container>
    </div>
  );
});
