/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrganizationAdminUpdateOrganizationSettingsRequest } from '../model';
// @ts-ignore
import { UserGetOrganizationSettingsResponse } from '../model';
/**
 * OrganizationSettingsApi - axios parameter creator
 * @export
 */
export const OrganizationSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the settings of the requested organization
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateOrganizationSettingsRequest} requestBody OrganizationAdminUpdateOrganizationSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateOrganizationSettings: async (organizationId: string, requestBody: OrganizationAdminUpdateOrganizationSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminUpdateOrganizationSettings', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminUpdateOrganizationSettings', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the settings of the requested organization
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetOrganizationSettings: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('userGetOrganizationSettings', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/settings`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationSettingsApi - functional programming interface
 * @export
 */
export const OrganizationSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the settings of the requested organization
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateOrganizationSettingsRequest} requestBody OrganizationAdminUpdateOrganizationSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminUpdateOrganizationSettings(organizationId: string, requestBody: OrganizationAdminUpdateOrganizationSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminUpdateOrganizationSettings(organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the settings of the requested organization
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetOrganizationSettings(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetOrganizationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetOrganizationSettings(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationSettingsApi - factory interface
 * @export
 */
export const OrganizationSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationSettingsApiFp(configuration)
    return {
        /**
         * Updates the settings of the requested organization
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminUpdateOrganizationSettingsRequest} requestBody OrganizationAdminUpdateOrganizationSettingsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateOrganizationSettings(organizationId: string, requestBody: OrganizationAdminUpdateOrganizationSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminUpdateOrganizationSettings(organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the settings of the requested organization
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetOrganizationSettings(organizationId: string, options?: any): AxiosPromise<UserGetOrganizationSettingsResponse> {
            return localVarFp.userGetOrganizationSettings(organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationSettingsApi - interface
 * @export
 * @interface OrganizationSettingsApi
 */
export interface OrganizationSettingsApiInterface {
    /**
     * Updates the settings of the requested organization
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateOrganizationSettingsRequest} requestBody OrganizationAdminUpdateOrganizationSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSettingsApiInterface
     */
    organizationAdminUpdateOrganizationSettings(organizationId: string, requestBody: OrganizationAdminUpdateOrganizationSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the settings of the requested organization
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSettingsApiInterface
     */
    userGetOrganizationSettings(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetOrganizationSettingsResponse>;

}

/**
 * OrganizationSettingsApi - object-oriented interface
 * @export
 * @class OrganizationSettingsApi
 * @extends {BaseAPI}
 */
export class OrganizationSettingsApi extends BaseAPI implements OrganizationSettingsApiInterface {
    /**
     * Updates the settings of the requested organization
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminUpdateOrganizationSettingsRequest} requestBody OrganizationAdminUpdateOrganizationSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSettingsApi
     */
    public organizationAdminUpdateOrganizationSettings(organizationId: string, requestBody: OrganizationAdminUpdateOrganizationSettingsRequest, options?: AxiosRequestConfig) {
        return OrganizationSettingsApiFp(this.configuration).organizationAdminUpdateOrganizationSettings(organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the settings of the requested organization
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSettingsApi
     */
    public userGetOrganizationSettings(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationSettingsApiFp(this.configuration).userGetOrganizationSettings(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}
