import type { ApiKeyId, OrganizationId, ProjectId, ScanId } from "@Interfaces";
import type { SpaceLink } from "@Services";

export const ProjectOrganization: {
  organizationId: OrganizationId;
  projectId: ProjectId;
}[] = [
  { organizationId: "1", projectId: "1" },
  { organizationId: "1", projectId: "2" },
  { organizationId: "3", projectId: "3" }, // pending
  { organizationId: "1", projectId: "4" },
  { organizationId: "1", projectId: "5" },
  { organizationId: "3", projectId: "6" },
  { organizationId: "11", projectId: "7" },
];

export const OrganizationAPIKeys: {
  organizationId: OrganizationId;
  apiKeyId: ApiKeyId;
}[] = [];

export const ProjectSpaceLink = new Map<ProjectId, SpaceLink["id"]>([
  ["1", "1"], //  [Project Alpha] - SpaceLink#1
  ["2", "fake-one"], //  [Project Gamma] - INVALID
  ["4", "1"], //  [Project Beta] - SpaceLink#1
  ["5", "1"], //  [Project Gamma] - SpaceLink#1
]);

export const ScanSpaceLink = new Map<ScanId, SpaceLink["id"]>([
  ["1-100000", "1"],
  ["1-100001", "0"], // invalid space link
]);
