import type { FC } from "react";
import type { EditFormProps } from "./UserForm.i";
import { useUserForm } from "./Hooks/useUserForm";
import { useFormValidation } from "./Hooks/useFormValidation";
import { RoleExpirationFields } from "./Components/RoleExpirationFields";
import { Form, FormGroup } from "reactstrap";
import { NameField } from "./Components/NameField";
import { SurnameField } from "./Components/SurnameField";
import { ActiveField } from "./Components/ActiveField";

export const EditOrganizationUserForm: FC<EditFormProps> = ({
  selfEditing,
  setUser: propsSetUser,
  user: propsUser,
  disabled,
  setFormValid,
}) => {
  const { user, setUser } = useUserForm(propsUser, propsSetUser);
  const { validities } = useFormValidation(user, setFormValid);

  return (
    <Form onSubmit={undefined}>
      <FormGroup>
        <NameField
          name={user.name}
          nameValid={validities.name}
          setUser={setUser}
          disabled={disabled}
        />
      </FormGroup>
      <FormGroup noMargin={selfEditing}>
        <SurnameField
          surname={user.surname}
          surnameValid={validities.surname}
          setUser={setUser}
          disabled={disabled}
        />
      </FormGroup>
      {!selfEditing && (
        <>
          <FormGroup>
            <RoleExpirationFields
              expiresAt={user.expiresAt}
              role={user.role}
              roleValid={validities.role}
              setUser={setUser}
              disabled={disabled}
            />
          </FormGroup>
          <FormGroup noMargin>
            <ActiveField
              active={user.active}
              setUser={setUser}
              disabled={disabled}
            />
          </FormGroup>
        </>
      )}
    </Form>
  );
};
