import { usePaginatedResult } from "@Hooks";
import type { ApiArgsType } from "./OrganizationList.i";
import { useCallback, useMemo, useState } from "react";
import { API, getDefaultFilters } from "@Services";

export const useOrganizationList = () => {
  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters: useMemo(() => getDefaultFilters("users"), []),
    getResponse: useCallback(args => {
      return API.user().organizationAdminListUsersByOrganizationId(
        args.organizationId,
        args.page,
        args.pageSize,
        args.sortBy as ApiArgsType["sortBy"],
        args.sortMode,
        args.criteria as ApiArgsType["criteria"],
        args.operators as ApiArgsType["operators"],
        args.values
      );
    }, []),
  });

  // Invite user
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return {
    response,
    loading,
    fetch,
    sidebarOpen,
    setSidebarOpen,
  };
};
