import { Button } from "@Components";
import type { ExtendedButtonProps } from "../DropdownButtons.i";

export const ActiveButton = ({
  activeButton,
}: {
  activeButton: ExtendedButtonProps;
}) => {
  const props = { ...activeButton };
  delete props["dropdownLabel"];
  delete props["onSelect"];
  return <Button {...props} data={{ role: "main-button", ...props.data }} />;
};
