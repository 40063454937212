import { useProject } from "@Hooks";
import { useAPIDefinitionDetailsStore } from "../Store/APIDefinitionDetailsStore";

export const usePathExclusion = () => {
  const { details } = useAPIDefinitionDetailsStore();
  const { project, loading, update } = useProject();

  const exclude = async () => {
    if (!project || !details) {
      return;
    }

    project.pathExclusion.push({
      path: details.path,
      methods: [details.verb],
    });

    const excluded = await update(project, true, {
      showSuccessToast: true,
      labelKey: "path-exclusion-creation",
    });

    details.excluded = excluded;
  };

  return {
    exclude,
    loading: loading === "put",
    canExclude: details && !details.excluded,
  };
};
