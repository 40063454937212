import { Offcanvas, RunPolicyForm } from "@Components";
import useScheduleEditCanvas from "./useScheduleEditCanvas";
import type { CanvasCommonProps } from "../Components.i";

export const ScheduleEditCanvas = (props: CanvasCommonProps) => {
  const { canvasProps, scheduleProps } = useScheduleEditCanvas(props);

  return (
    <Offcanvas
      id="project-schedule-canvas"
      className={canvasProps.loading ? "loading" : undefined}
      isOpen={props.isOpen}
      toggle={canvasProps.setClosed}
      buttons={[canvasProps.saveButton]}
      title="project.edit-canvas-titles.schedule"
      children={
        <RunPolicyForm
          runPolicy={scheduleProps.runPolicy}
          setRunPolicy={scheduleProps.setRunPolicy}
          setValid={scheduleProps.setFormValid}
          startDateOffset={0}
          disclaimer="project.run-schedule.disclaimer-short"
        />
      }
    />
  );
};
