import { Breadcrumb } from "@Components";
import { PROJECT_DETAILS_ISSUES_URL } from "@Constants";
import { useUrlParams } from "@Hooks";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type IssueListBreadcrumbProps = {
  disabled?: boolean;
};

export const ProjectIssueListBreadcrumb = ({
  disabled = false,
}: IssueListBreadcrumbProps) => {
  const { projectId } = useUrlParams();
  const to = disabled
    ? undefined
    : generateUrl(PROJECT_DETAILS_ISSUES_URL, [projectId ?? ""]);
  return (
    <Breadcrumb disabled={disabled} name={"project-issues"} to={to}>
      {t("common.list-issues")}
    </Breadcrumb>
  );
};
