import "./style.scss";
import type { Props } from "./LicenseCard.i";
import { memo, useMemo } from "react";
import { Card, Col, Row } from "reactstrap";
import { DisplaySection } from "@Components";
import { getLabels } from "./Functions";
import { useResponsive } from "@Hooks";

export const LicenseCard = memo(({ license, className, direction }: Props) => {
  const forceColumnDesign = useResponsive("md");
  if (forceColumnDesign || direction === undefined) {
    direction = "column";
  }

  const labels = useMemo(() => {
    return getLabels(license, direction === "column");
  }, [license, direction]);

  return (
    <Card className={`license-card ${className || "p-3 h-100"}`}>
      <Row className={`flex-${direction} flex-basis-100`}>
        {labels.map(({ label, value, className }) => (
          <Col
            className={className}
            key={`${label}${value}${className}`}
            xs={12}
            lg={direction === "column" ? 12 : 4}
            children={<DisplaySection label={label} value={value} />}
          />
        ))}
      </Row>
    </Card>
  );
});
