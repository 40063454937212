import { InfoBadge } from "@Components";
import { HOME_PATH, ORGANIZATION_PATH } from "@Constants";
import { useOrganization } from "@Hooks";
import { generateUrl } from "@Utils";
import { Link, useLocation } from "react-router-dom";

export const OrganizationBreadcrumb = () => {
  const organization = useOrganization();
  const { pathname } = useLocation();
  const isOnOrganizationDetails = pathname.startsWith("/organization");
  if (!organization || !organization.name) {
    return null;
  }
  return (
    <InfoBadge
      bgColor="pink"
      className="me-2 text-truncate"
      id="organization-breadcrumb"
      data-cy={"organization-breadcrumb"}
      title={organization.name}
    >
      <Link
        to={
          isOnOrganizationDetails
            ? generateUrl(ORGANIZATION_PATH, [organization.id])
            : HOME_PATH
        }
      >
        {organization.name}
      </Link>
    </InfoBadge>
  );
};
