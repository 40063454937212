export const Page = {
  General: 0,
  Authentication: 1,
  Dictionary: 2,
  PathExclusion: 3,
  IssueExclusion: 4,
  InjectableParameters: 5,
  MTLSCertificate: 6,
  SecurityChecks: 7,
  ParameterLinks: 8,
  AdvancedSettings: 9,
};
