import { ErrorPage } from "@Pages";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

export const MainOutlet = () => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => <ErrorPage error={error} />}
    >
      <Outlet />
    </ErrorBoundary>
  );
};
