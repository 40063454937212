import type { Nullable, ScanDetail } from "@Interfaces";
import type { ListedScan } from "../client";

export const showReportButton = (
  scan?: Nullable<ListedScan | ScanDetail["scan"]>
) => {
  if (!scan) {
    return false;
  }
  const { status } = scan;
  return status === "completed" || status === "stopped";
};
