import { equals, getEmptyApiKey, getFilterAllowedOptions } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { EdiAPiKeyProps } from "./EditApiKey.i";

export const useEditApiKey = ({
  apiKey: propsApiKey,
  isOpen,
  toggle,
  onSubmit,
}: EdiAPiKeyProps) => {
  const [apiKey, setApiKey] = useState(getEmptyApiKey);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setApiKey(propsApiKey);
  }, [propsApiKey]);

  const modified = useMemo(() => {
    return !equals(apiKey, propsApiKey);
  }, [apiKey, propsApiKey]);

  const options = useMemo(() => {
    return getFilterAllowedOptions("api_key_role").filter(e => e.value);
  }, []);

  const closeCanvas = useCallback(() => {
    setApiKey(getEmptyApiKey());
    toggle(false);
  }, [toggle]);

  const handleUpdate = useCallback(async () => {
    setLoading(true);
    onSubmit(apiKey)
      .then(updated => {
        updated && closeCanvas();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiKey, closeCanvas, onSubmit]);

  return {
    apiKey,
    setApiKey,
    loading,
    modified,
    options,
    closeCanvas,
    isOpen,
    handleUpdate,
  };
};
