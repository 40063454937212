import {
  useOrganizationApiKey,
  useOrganizationAuditLogs,
  useUrlParams,
} from "@Hooks";
import type { ApiKey } from "@Interfaces";
import { useCallback, useEffect, useState } from "react";
import { getFetchPromise, handleComponentState } from "../functions";
import type { PageState } from "../ApiKeyDetail.i";

export const useApiKeyDetail = () => {
  const [pageState, setPageState] = useState<PageState>("LOADING_API_KEY");
  const { organizationId } = useUrlParams();

  const {
    apiKey,
    loading: loadingApiKey,
    actions: { update },
  } = useOrganizationApiKey();

  const {
    logs,
    loading: loadingLogs,
    fetch: fetchLogs,
  } = useOrganizationAuditLogs();

  const handleFetchLogs = useCallback(() => {
    if (!apiKey?.id || !organizationId) return;
    const promise = getFetchPromise(apiKey.id, organizationId);
    fetchLogs(promise);
  }, [fetchLogs, organizationId, apiKey?.id]);

  const handleUpdate = useCallback(
    (apiKey: ApiKey) => {
      return update({
        active: apiKey.active,
        role: apiKey.role,
      });
    },
    [update]
  );

  useEffect(() => {
    handleComponentState({
      handleFetchLogs,
      apiKeyFetched: !!apiKey,
      loadingApiKey: !!loadingApiKey,
      loadingLogs,
      pageState,
      setPageState,
    });
  }, [apiKey, handleFetchLogs, loadingApiKey, loadingLogs, pageState]);

  return {
    apiKey,
    logs,
    apiKeyLoading: pageState === "LOADING_API_KEY" || loadingApiKey === "GET",
    logsLoading: pageState === "LOADING_LOG" || loadingLogs,
    handleUpdate,
  };
};
