import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useNewPasswordForm } from "./useNewPasswordForm";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { Button, PasswordInput } from "@Components";

export const NewPassword = React.memo(() => {
  const {
    email,
    error,
    loading,
    password,
    isFormValid,
    isValidEmail,
    repeatPassword,
    passwordCreated,
    setEmail,
    setPassword,
    setNewPassword,
    setValidPassword,
    setRepeatPassword,
  } = useNewPasswordForm();

  return passwordCreated ? (
    <>
      <h4 className="m-0 text-center">{t("login.new-psw.success")}</h4>
      <div className="mt-5">
        <Link to="/login" className="d-grid gap-2 no-underline">
          <Button
            color="primary"
            children="login.back"
            iconClass="bi bi-arrow-left-square-fill"
          />
        </Link>
      </div>
    </>
  ) : (
    <>
      <h4 className="mb-3">{t("login.new-psw.create-psw")}</h4>
      <FormGroup>
        <Label for="email">{t("common.email")}</Label>
        <Input
          type="email"
          id="email"
          bsSize="sm"
          value={email}
          autoComplete="email"
          onChange={event => setEmail(event.target.value)}
          valid={!!email && isValidEmail}
          invalid={!!email && !isValidEmail}
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">{t("common.password")}</Label>
        <PasswordInput
          id="password"
          password={password}
          setPassword={setPassword}
          setValidPassword={setValidPassword}
          autoComplete="new-password"
          bsSize="sm"
        />
      </FormGroup>
      <FormGroup>
        <Label for="repeatPassword">{t("login.new-psw.repeat-password")}</Label>
        <Input
          type="password"
          id="repeatPassword"
          bsSize="sm"
          value={repeatPassword}
          autoComplete="new-password"
          onChange={event => setRepeatPassword(event.target.value)}
          invalid={!!repeatPassword && password !== repeatPassword}
          valid={!!repeatPassword && password === repeatPassword}
        />
      </FormGroup>

      <div className="mt-3 d-grid gap-2">
        <Button
          color="primary"
          onClick={setNewPassword}
          disabled={!isFormValid}
          loading={loading}
          children="login.new-psw.submit"
          iconClass="bi bi-floppy"
        />
      </div>
      {error && (
        <>
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
          <Link
            to={`/forgot-password?email=${email}`}
            className="flex-center mt-2 w-100"
          >
            {t("login.request-new-password-link")}
          </Link>
        </>
      )}
    </>
  );
});
