import { createStore } from "@Hooks";
import type { InventoryDetail, Nullable } from "@Interfaces";
import type { Dispatch } from "react";

type APIDefinitionDetailsStoreProperties = {
  details: Nullable<InventoryDetail>;
  isLatestAPIDefinition: boolean;
  loading: boolean;
  notFound: boolean;
};

type APIDefinitionDetailsStore = APIDefinitionDetailsStoreProperties & {
  setDetails: Dispatch<Nullable<InventoryDetail>>;
  setLoading: Dispatch<boolean>;
  setIsLatestAPIDefinition: Dispatch<boolean>;
  reset: VoidFunction;
};

const initialState: APIDefinitionDetailsStoreProperties = {
  details: null,
  loading: true,
  notFound: false,
  isLatestAPIDefinition: false,
};

export const useAPIDefinitionDetailsStore =
  createStore<APIDefinitionDetailsStore>((get, set) => ({
    ...initialState,
    setDetails: details =>
      set(state => ({ ...state, details, notFound: !details })),
    setLoading: loading => set(state => ({ ...state, loading })),
    setIsLatestAPIDefinition: isLatestAPIDefinition =>
      set(store => ({ ...store, isLatestAPIDefinition })),
    reset: () => set(state => ({ ...state, ...initialState })),
  }));
