import { useEffect, useState } from "react";
import type { Tr } from "./ListItems.i";
import type { HeaderItem } from "@Interfaces";

export const useOrderedConfig = (
  config: Tr[],
  headers: HeaderItem[],
  updateCardItemOrder?: number[]
) => {
  const [orderedConfig, setOrderedConfig] = useState(config);
  const [orderedHeaders, setOrderedHeaders] = useState(headers);

  useEffect(() => {
    if (
      updateCardItemOrder &&
      config.length > 0 &&
      updateCardItemOrder.length === config[0].items.length
    ) {
      const newOrderedConfig = config.map(item => {
        const newItem = { ...item };
        newItem.items = updateCardItemOrder.map(index => item.items[index]);
        return newItem;
      });

      setOrderedConfig(newOrderedConfig);
      setOrderedHeaders(updateCardItemOrder.map(index => headers[index]));
    } else {
      setOrderedConfig(config);
    }
  }, [config, updateCardItemOrder, headers]);

  return { orderedConfig, orderedHeaders };
};
