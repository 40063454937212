import { DictionaryForm, Offcanvas } from "@Components";
import useDictionaryFormCanvas from "./useDictionaryFormCanvas";
import type { Props } from "./DictionaryFormCanvas.i";

export const DictionaryFormCanvas = (props: Props) => {
  const { canvas, saveButton, setEntry } = useDictionaryFormCanvas(props);
  return (
    <Offcanvas
      id="dictionary-form-canvas"
      isOpen={canvas.isOpen}
      toggle={canvas.close}
      title="common.dictionary"
      buttons={[saveButton.props]}
      className={canvas.loading ? "loading" : undefined}
      children={
        <DictionaryForm
          onFormValidityChange={valid => saveButton.setDisabled(!valid)}
          onModelUpdate={setEntry}
          disabled={canvas.loading}
        />
      }
    />
  );
};
