/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserGetIssueResponse } from '../model';
// @ts-ignore
import { UserListIssuesResponse } from '../model';
// @ts-ignore
import { UserListOpenIssuesResponse } from '../model';
/**
 * IssueApi - axios parameter creator
 * @export
 */
export const IssueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the issue with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {string} issueId Issue ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueGet: async (organizationId: string, projectId: string, scanId: string, issueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueGet', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueGet', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('issueGet', 'scanId', scanId)
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('issueGet', 'issueId', issueId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/issues/{issue_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)))
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the issues in the project, excluding issues related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListInOrganization: async (organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueListInOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/issues`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the issues in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListInProject: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueListInProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueListInProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/issues`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the issues in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListInScan: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueListInScan', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueListInScan', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('issueListInScan', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/issues`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the open issues in the project, excluding open issues related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListOpenInOrganization: async (organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueListOpenInOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/issues/open`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the open issues in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListOpenInProject: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('issueListOpenInProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueListOpenInProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/issues/open`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssueApi - functional programming interface
 * @export
 */
export const IssueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssueApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the issue with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {string} issueId Issue ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueGet(organizationId: string, projectId: string, scanId: string, issueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetIssueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueGet(organizationId, projectId, scanId, issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the issues in the project, excluding issues related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueListInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the issues in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the issues in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueListInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueListInScan(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the open issues in the project, excluding open issues related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueListOpenInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListOpenIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueListOpenInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the open issues in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueListOpenInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListOpenIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueListOpenInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssueApi - factory interface
 * @export
 */
export const IssueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssueApiFp(configuration)
    return {
        /**
         * Returns the issue with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {string} issueId Issue ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueGet(organizationId: string, projectId: string, scanId: string, issueId: string, options?: any): AxiosPromise<UserGetIssueResponse> {
            return localVarFp.issueGet(organizationId, projectId, scanId, issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the issues in the project, excluding issues related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListIssuesResponse> {
            return localVarFp.issueListInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the issues in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListIssuesResponse> {
            return localVarFp.issueListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the issues in the scan
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListIssuesResponse> {
            return localVarFp.issueListInScan(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the open issues in the project, excluding open issues related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListOpenInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListOpenIssuesResponse> {
            return localVarFp.issueListOpenInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the open issues in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueListOpenInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListOpenIssuesResponse> {
            return localVarFp.issueListOpenInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IssueApi - interface
 * @export
 * @interface IssueApi
 */
export interface IssueApiInterface {
    /**
     * Returns the issue with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {string} issueId Issue ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueGet(organizationId: string, projectId: string, scanId: string, issueId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetIssueResponse>;

    /**
     * Returns the issues in the project, excluding issues related to archived projects
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListIssuesResponse>;

    /**
     * Returns the issues in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListIssuesResponse>;

    /**
     * Returns the issues in the scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueListInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListIssuesResponse>;

    /**
     * Returns the open issues in the project, excluding open issues related to archived projects
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueListOpenInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListOpenIssuesResponse>;

    /**
     * Returns the open issues in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueListOpenInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListOpenIssuesResponse>;

}

/**
 * IssueApi - object-oriented interface
 * @export
 * @class IssueApi
 * @extends {BaseAPI}
 */
export class IssueApi extends BaseAPI implements IssueApiInterface {
    /**
     * Returns the issue with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {string} issueId Issue ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public issueGet(organizationId: string, projectId: string, scanId: string, issueId: string, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).issueGet(organizationId, projectId, scanId, issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the issues in the project, excluding issues related to archived projects
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public issueListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).issueListInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the issues in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public issueListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).issueListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the issues in the scan
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public issueListInScan(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_category' | 'issue_owasp_top_ten' | 'issue_severity' | 'issue_verb' | 'issue_path'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).issueListInScan(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the open issues in the project, excluding open issues related to archived projects
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public issueListOpenInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).issueListOpenInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the open issues in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'issue_path' | 'issue_created_at' | 'issue_severity'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public issueListOpenInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'issue_path' | 'issue_created_at' | 'issue_severity', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'issue_name' | 'issue_severity' | 'issue_verb' | 'issue_path' | 'issue_category' | 'issue_owasp_top_ten' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).issueListOpenInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }
}
