import { useProject, useUrlParams } from "@Hooks";
import {
  getDefaultFilters,
  mapHeaderItemsWithSorts,
  type ListedIssue,
} from "@Services";
import { useIssueListStore } from "@Stores";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  getCardItemOrder,
  getConfig,
  getFullRowIndexes,
  getHeaders,
} from "./Functions";
import { type IssuesTableProps } from "./IssueTable";

const useIssueTable = (props: IssuesTableProps) => {
  const { setOpenIssueId, openIssueId } = useIssueListStore();
  const { organizationId } = useUrlParams();
  const { project } = useProject();
  const { search } = useLocation();
  const defaultFilters = useMemo(() => getDefaultFilters("issues"), []);
  const issues = useMemo(() => props.issues || [], [props.issues]);

  const forProject = !!project;

  const config = useMemo(() => {
    if (!issues.length) return [];
    const onClick = (issue: ListedIssue) =>
      setOpenIssueId(
        issue.id,
        issue.scanID,
        issue.projectID,
        organizationId ?? ""
      );

    return getConfig(issues, openIssueId ?? "", forProject, onClick);
  }, [issues, openIssueId, forProject, setOpenIssueId, organizationId]);

  const tableProps = useMemo(
    () => ({
      cardItemOrder: getCardItemOrder(forProject),
      fullRowIndexes: getFullRowIndexes(forProject),
      headers: mapHeaderItemsWithSorts({
        items: getHeaders(forProject),
        defaults: defaultFilters,
        search,
        updateFunction: props.setFilters,
      }),
    }),
    [defaultFilters, forProject, props.setFilters, search]
  );

  return {
    ...props,
    ...tableProps,
    config,
    defaultFilters,
  };
};

export default useIssueTable;
