import { Button } from "@Components";
import { KeyAction, KeyCodes } from "@Constants";
import { useKeyboardEvent } from "@Hooks";
import { BEM } from "@Utils";
import { t } from "i18next";
import { memo, useCallback } from "react";
import ReactDOM from "react-dom";
import isEqual from "react-fast-compare";
import OutsideClickHandler from "react-outside-click-handler";
import "./style.scss";

type ConfirmDialogProps = {
  title: string;
  description: string;
  cancelLabel: string;
  confirmLabel: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

const CANCEL_ACTION = new KeyAction(KeyCodes.Escape)
  .withPriority(10)
  .withPrefix("confirm-dialog");

const CONFIRM_ACTION = new KeyAction(KeyCodes.Enter)
  .withPriority(10)
  .withPrefix("confirm-dialog");

export const ConfirmDialog: React.FC<ConfirmDialogProps> = memo(
  ({
    title,
    description,
    cancelLabel,
    confirmLabel,
    onCancel,
    onConfirm,
  }: ConfirmDialogProps) => {
    useKeyboardEvent({
      action: CANCEL_ACTION,
      onKeyDown: onCancel,
    });

    useKeyboardEvent({
      action: CONFIRM_ACTION,
      onKeyDown: onConfirm,
    });

    const handleContainerClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      },
      []
    );

    return ReactDOM.createPortal(
      <div className="confirm-dialog" onClick={handleContainerClick}>
        <OutsideClickHandler onOutsideClick={onCancel}>
          <div className="confirm-dialog-container">
            <div className={BEM("confirm-dialog-container", "title")}>
              <b>{t(title)}</b>
            </div>
            <div className={BEM("confirm-dialog-container", "content")}>
              {t(description)}
            </div>
            <div className={BEM("confirm-dialog-container", "footer")}>
              <Button
                iconClass="bi bi-x-lg"
                color="transparent"
                onMouseDown={onCancel}
                data={{ cy: "cancel-btn" }}
                children={cancelLabel}
              />

              <Button
                iconClass="bi bi-check-square-fill"
                color="danger"
                onMouseDown={onConfirm}
                data={{ cy: "confirm-btn" }}
                children={confirmLabel}
              />
            </div>
          </div>
        </OutsideClickHandler>
      </div>,
      document.body
    );
  },
  isEqual
);
