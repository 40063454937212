import { AuthenticationCard, ProjectSettingsCardContainer } from "@Components";
import type {
  AuthenticationSettings,
  Injectable,
  MTLSCertificate,
} from "@Services";
import { joinClasses } from "@Utils";
import { memo, useCallback, useMemo, useState } from "react";
import { DisplayRow } from "..";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";

export type FormModel = {
  authenticationSettings: AuthenticationSettings;
  injectables: Injectable[];
  mTLSCertificates: MTLSCertificate[];
};
type DisplayRowProjectAuthenticationsProps = {
  authenticationSettings: Array<FormModel>;
  onDelete?: (i: number) => Promise<boolean>;
  onEdit?: (i: number) => void;
  onClone?: (i: number) => void;
  injectables?: Injectable[];
  mTLSCertificates?: MTLSCertificate[];
} & Pick<MainProps, "buttonProps">;

export const DisplayRowProjectAuthentications = memo(
  ({
    authenticationSettings,
    onDelete,
    onEdit,
    onClone,
    buttonProps,
  }: DisplayRowProjectAuthenticationsProps) => {
    const [deletingIndex, setDeletingIndex] = useState<number>();

    const getOnDeleteFunction: (i: number) => VoidFunction | undefined =
      useCallback(
        (i: number) => {
          if (!onDelete) return undefined;
          return async () => {
            setDeletingIndex(i);
            await onDelete(i);
            setDeletingIndex(undefined);
          };
        },
        [onDelete]
      );

    const cardsContainer = useMemo(
      () => (
        <ProjectSettingsCardContainer
          cardType="authentication"
          emptyLabel="common.no-authentication-settings"
        >
          {authenticationSettings.map((a, i) => {
            const handleOnEdit = onEdit ? () => onEdit?.(i) : undefined;
            const handleOnClone = onClone ? () => onClone?.(i) : undefined;
            const handleOnDelete = getOnDeleteFunction(i);
            return (
              <AuthenticationCard
                authenticationSettings={a.authenticationSettings}
                key={`${i}-${a.authenticationSettings.name}`}
                onDelete={handleOnDelete}
                onEdit={handleOnEdit}
                onClone={handleOnClone}
                loading={deletingIndex === i}
                disabled={deletingIndex !== undefined}
                injectables={a.injectables ?? []}
                mtlsCertificates={a.mTLSCertificates ?? []}
              />
            );
          })}
        </ProjectSettingsCardContainer>
      ),
      [
        authenticationSettings,
        getOnDeleteFunction,
        deletingIndex,
        onEdit,
        onClone,
      ]
    );

    return (
      <DisplayRow
        title="common.authentication"
        buttonProps={buttonProps}
        className={joinClasses(
          "h-100",
          authenticationSettings.length <= 0 && "no-data"
        )}
      >
        {cardsContainer}
      </DisplayRow>
    );
  }
);
