import { useCallback } from "react";
import type { AuthenticationFormMode, CanvasFlow } from "../Authentication.i";
import { useAuthenticationStore } from "../Authentication.store";

export const useAuthentication = () => {
  const { setCanvasMode, setCanvasFlow } = useAuthenticationStore();

  const handleOpenCanvas = useCallback(
    (flow: CanvasFlow, mode: AuthenticationFormMode) => {
      setCanvasMode(mode);
      setCanvasFlow(flow);
    },
    [setCanvasFlow, setCanvasMode]
  );

  return {
    handleOpenCanvas,
  };
};
