import { useMaintenanceModeStore } from "@Stores";
import { pureComponent } from "@Utils";
import { t } from "i18next";
import moment from "moment";
import { memo } from "react";
import { NavItem } from "reactstrap";

export const MaintenanceModeIcon = memo(() => {
  const { maintenanceModeScheduled, maintenanceModeStart, maintenanceModeEnd } =
    useMaintenanceModeStore();

  if (!maintenanceModeScheduled) {
    return null;
  }

  return (
    <NavItem
      className="flex-center maintenance-mode mb-2"
      data-tooltip-id="tooltip-nav"
      data-tooltip-html={t("maintenance-mode.tooltip", {
        startDate: moment(maintenanceModeStart).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        ),
        endDate: moment(maintenanceModeEnd).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        ),
      })}
      data-tooltip-place="right"
      data-cy="maintenance-mode-icon"
    >
      <div className="icon-container">
        <i className="bi bi-exclamation-square-fill color-severity-medium"></i>
      </div>
    </NavItem>
  );
}, pureComponent);
