import type { PlainObject } from "@Interfaces";
import type { ApiKeyResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { apiKeys, delay } from "src/Mock";

export const apiKeyGet = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { apiKeyId } = req.params as PlainObject<string>;
  const response: ApiKeyResponse | undefined = apiKeys.find(
    e => e.id === apiKeyId
  );
  return res(
    ctx.delay(delay),
    ctx.json(response),
    ctx.status(response ? 200 : 404)
  );
};
