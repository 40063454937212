import type { UserListIssuesResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getOpenIssues } from "src/Mock";

export const getScanIssues = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;
  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const severityIndex = criterias.findIndex(c => c === "issue_severity");
  const severityValue = severityIndex >= 0 ? values[severityIndex] : "";

  const { organizationId, projectId, scanId } = req.params as Record<
    string,
    string
  >;

  if (!organizationId || !projectId || !scanId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const displayedIssue = getOpenIssues(organizationId, projectId).filter(i => {
    return !severityValue || severityValue === i.severity;
  });

  const offset = (page - 1) * size;
  const limit = offset + size;

  const responseData = displayedIssue.slice(offset, limit);

  return res(
    ctx.delay(delay),
    ctx.status(200),
    ctx.json({
      data: responseData.map(i => ({
        ...i,
        scanID: scanId,
      })),
      page,
      size,
      totalItems: displayedIssue.length,
      totalPages: Math.ceil(displayedIssue.length / size),
    } as UserListIssuesResponse)
  );
};
