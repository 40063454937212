export function isElementActive(
  currentPath: string,
  destination: string,
  partial: boolean
) {
  if (!destination || !currentPath) return false;

  const [destinationPathname] = destination.split(/[?#]/);
  const cleanCurrentPath =
    currentPath.length > 1 && currentPath.endsWith("/")
      ? currentPath.slice(0, -1)
      : currentPath;

  return partial
    ? cleanCurrentPath.includes(destinationPathname)
    : destinationPathname === cleanCurrentPath;
}
