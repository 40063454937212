import React, { memo, useEffect, useRef, useContext } from "react";
import { ThemeContext } from "@Contexts";
import type { LinearScale } from "chart.js";
import { Chart, registerables } from "chart.js";
import type { Props } from "./HorizontalLineChart.i";
import { getChartHeight, getMax, manageTicks } from "./functions";

import "./style.scss";

Chart.register(...registerables);

export const HorizontalLineChart = memo(({ data }: Props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<"bar", number[], string> | null>(null);
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const height = getChartHeight(data);

  useEffect(() => {
    if (chartRef.current) {
      const chartCanvas = chartRef.current.getContext("2d");

      if (chartCanvas) {
        const maxValue = data.reduce(function (prev, item) {
          return Math.max(prev, item.value);
        }, 0);
        if (!chartInstance.current) {
          chartInstance.current = new Chart(chartCanvas, {
            type: "bar",
            data: {
              labels: data.map(item => item.label),
              datasets: [
                {
                  data: data.map(i => i.value),
                  backgroundColor: data.map(i => i.color),
                  borderColor: data.map(i => i.color),
                },
              ],
            },
            options: {
              indexAxis: "y",
              responsive: false,
              elements: {
                bar: {
                  borderWidth: 1,
                  borderRadius: 6,
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                  max: getMax(maxValue),
                  grid: {
                    color: isDark ? "rgba(255,255,255, 0.1)" : undefined,
                  },
                  ticks: {
                    callback: value => (Number.isInteger(value) ? value : ""),
                    color: isDark ? "whiteSmoke" : undefined,
                  },
                },
                y: {
                  grid: {
                    color: isDark ? "rgba(255,255,255, 0.1)" : undefined,
                  },
                  ticks: {
                    callback(this, value) {
                      return manageTicks(
                        this?.getLabelForValue(value as number)
                      );
                    },
                    color: isDark ? "whiteSmoke" : undefined,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
              },
            },
          });
        } else {
          chartInstance.current.data.labels = data.map(item => item.label);
          chartInstance.current.data.datasets = [
            {
              data: data.map(i => i.value),
              backgroundColor: data.map(i => i.color),
              borderColor: data.map(i => i.color),
            },
          ];
          const scaleX = chartInstance.current.scales.x as LinearScale;
          scaleX.max = getMax(maxValue);

          chartInstance.current.update();
        }
      }
    }
  }, [data, isDark]);

  return (
    <canvas
      data-cy="horizontal-line-chart"
      className="horizontal-line-chart"
      height={`${height}px`}
      ref={chartRef}
    />
  );
});
