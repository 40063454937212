import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getAndCreateScans } from "src/Mock";
import type { ListedScan, UserListScansResponse } from "@Services";

export const getOrganizationScans = (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams } = req.url;

  const { organizationId } = req.params as Record<string, string>;

  const listScans: ListedScan[] = getAndCreateScans(
    organizationId,
    searchParams
  );

  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("size")) || 12;

  const start = pageSize * (page - 1);
  const end = start + pageSize;

  const response: UserListScansResponse = {
    data: listScans.slice(start, end),
    page: page,
    size: pageSize,
    totalItems: listScans.length,
    totalPages: Math.ceil(listScans.length / pageSize),
  };

  return res(ctx.delay(delay), ctx.json(response));
};
