import { useProject } from "@Hooks";
import type { PathExclusionEntry } from "@Services";
import { useCallback, useEffect, useState } from "react";
import updateEntries from "../updateEntries";

export const usePathExclusion = () => {
  const { project, loading, update } = useProject();
  const [pathExclusion, setPathExclusion] = useState<PathExclusionEntry[]>([]);
  const [canvasOpen, setCanvasOpen] = useState(false);

  useEffect(() => {
    if (project) {
      setPathExclusion(project.pathExclusion.slice());
    }
  }, [project]);

  const addEntry = useCallback(
    async (entry: PathExclusionEntry) => {
      if (!project) return false;
      const action = "add";
      return updateEntries({ project, update, action, entry });
    },
    [project, update]
  );

  const deleteEntry = useCallback(
    async (index: number) => {
      if (!pathExclusion || !project) return false;
      const action = "remove";
      return updateEntries({ project, update, action, index });
    },
    [pathExclusion, project, update]
  );

  return {
    pathExclusion,
    noData: !loading && !project,
    loading: {
      any: loading !== false,
      get: loading === "get",
      put: loading === "put",
    },
    actions: {
      addEntry,
      deleteEntry,
    },
    canvas: {
      isOpen: canvasOpen,
      open: () => setCanvasOpen(true),
      close: () => setCanvasOpen(false),
    },
  };
};
