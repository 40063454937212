/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Category = {
    CategoryArchitecture: 'Architecture, Design and Threat Modeling',
    CategoryAuthentication: 'Authentication',
    CategorySessionManagement: 'Session Management',
    CategoryAccessControl: 'Access Control',
    CategoryValidationSanitizationAndEncoding: 'Validation, Sanitization and Encoding',
    CategoryStoredCryptography: 'Stored Cryptography',
    CategoryErrorHandlingAndLogging: 'Error Handling and Logging',
    CategoryDataProtection: 'Data Protection',
    CategoryCommunicationEncryption: 'Communication Encryption',
    CategoryMaliciousCode: 'Malicious Code',
    CategoryBusinessLogic: 'Business Logic',
    CategoryFileAndResources: 'File and Resources',
    CategoryAPIAndWebService: 'API and Web Service',
    CategoryConfiguration: 'Configuration'
} as const;

export type Category = typeof Category[keyof typeof Category];



