import { createStore } from "@Hooks";
import type { NullableArray } from "@Interfaces";
import { type MTLSCertificate } from "@Services";
import type { Dispatch } from "react";

type MTLSCertificatesStoreProperties = {
  mTLSCertificates: NullableArray<MTLSCertificate>;
  loading: boolean;
  error: boolean;
};

type MTLSCertificatesStore = MTLSCertificatesStoreProperties & {
  setLoading: Dispatch<boolean>;
  setError: Dispatch<boolean>;
  setProjectMTLSCertificates: Dispatch<MTLSCertificate[]>;
  resetStore: VoidFunction;
};

const initialState: MTLSCertificatesStoreProperties = {
  mTLSCertificates: null,
  loading: false,
  error: false,
};

export const useMTLSCertificatesStore = createStore<MTLSCertificatesStore>(
  (get, set) => ({
    ...initialState,
    setLoading: loading => {
      set(state => ({ ...state, loading }));
    },
    setError: error => {
      set(state => ({ ...state, error }));
    },
    setProjectMTLSCertificates: mTLSCertificates => {
      set(state => ({
        ...state,
        mTLSCertificates: mTLSCertificates.filter(
          c => !c.associatedAuthSetting
        ),
      }));
    },
    resetStore: () => {
      set(state => ({ ...state, ...initialState }));
    },
  })
);
