import { ItemNotFound } from "@Components";
import { PROJECT_DETAILS_SCANS_URL } from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPage } from "@Services";
import { generateUrl, pureComponent } from "@Utils";
import { memo } from "react";

export const NotFound = memo(() => {
  const { organizationId, projectId } = useUrlParams();

  const redirectTo =
    lastVisitedPage("scans") ||
    generateUrl(PROJECT_DETAILS_SCANS_URL, [
      organizationId ?? "",
      projectId ?? "",
    ]);

  return (
    <div className="grid-w-100 grid-h-100">
      <ItemNotFound message="errors.no-scan" redirectTo={redirectTo} />
    </div>
  );
}, pureComponent);
