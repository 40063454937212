import type { HeaderItem } from "@Interfaces";

export const headerItems: HeaderItem[] = [
  { text: "common.name", sortByKey: "space_link_name" },
  { text: "common.created-at", sortByKey: "space_link_created_at" },
  { text: "common.expires-at", sortByKey: "space_link_expires_at" },
  "common.remaining-days",
  "common.connected",
  "common.status",
];
