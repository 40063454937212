import moment from "moment-timezone";

export const getStartOf = function (period: string): string {
  let start;
  if (period === "last_7_days") {
    start = moment().add(-7, "day").startOf("day");
  } else if (period === "last_90_days") {
    start = moment().add(-90, "day").startOf("day");
  } else if (period === "last_180_days") {
    start = moment().add(-180, "day").startOf("day");
  } else if (period === "last_year") {
    start = moment().add(-1, "year").startOf("day");
  } else if (period === "current_month") {
    start = moment().startOf("month");
  } else if (period === "current_quarter") {
    start = moment().startOf("quarter");
  } else if (period === "current_year") {
    start = moment().startOf("year");
  } else {
    start = moment().add(-30, "day").startOf("day");
  }
  return start.format("YYYY-MM-DD");
};
