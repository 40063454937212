import { InventoryFilters, ListItems, Paginator, Section } from "@Components";
import { memo, useMemo } from "react";
import { Container } from "reactstrap";
import { useScanDetailsInventory } from "./Hooks/useScanDetailsInventory";
import { useScanDetailsInventoryStore } from "./Inventory.store";

export const Inventory = memo(() => {
  const { loading, totalItems } = useScanDetailsInventoryStore();
  const { config, fetch, defaultFilters, headers, updateFilters } =
    useScanDetailsInventory();

  const filters = useMemo(
    () => (
      <Section className={"border-none"}>
        <InventoryFilters refresh={fetch} updateFilters={updateFilters} />
      </Section>
    ),
    [fetch, updateFilters]
  );

  const table = useMemo(
    () => (
      <ListItems
        headers={headers}
        showSkeleton={loading}
        config={config}
        cardVersion={false}
        noDataMessage="common.no-endpoints-found"
        clickable
        updateCardItemOrder={[0, 2, 4, 3, 1, 5]}
        fullRowIndexes={[0, 1]}
        setSorts={updateFilters}
        defaultSorts={defaultFilters}
      />
    ),
    [config, defaultFilters, headers, loading, updateFilters]
  );

  return (
    <Container fluid id="scan-details-inventory" className="endpoints">
      {filters}
      {table}
      <Paginator
        setFilters={updateFilters}
        totalItems={totalItems}
        className="p-4"
      />
    </Container>
  );
});
