import type { TabsProps } from "@Components";
import { NewProjectContext } from "@Contexts";
import { useOrganizationContext } from "@Hooks";
import { getDefaultAdvancedSettings } from "@Services";
import { useContext, useEffect, useMemo } from "react";

type TabsConfig = TabsProps["tabs"];

export const useProjectAdvancedSettings = (tabs: TabsConfig): TabsConfig => {
  const { advancedSettings } = useOrganizationContext();
  const { project, setProject } = useContext(NewProjectContext);
  const showAdvancedSettings = advancedSettings.enabled;

  const updatedTabs = useMemo<TabsConfig>(() => {
    const label = `common.advanced-settings`;
    const advancedSettingsTabIndex = tabs.findIndex(t => t.label === label);
    const hasAdvancedSettingsTab = advancedSettingsTabIndex !== -1;
    if (showAdvancedSettings && !hasAdvancedSettingsTab) {
      const updatedTabs = tabs.slice();
      updatedTabs.push({ label });
      return updatedTabs;
    } else if (!showAdvancedSettings && hasAdvancedSettingsTab) {
      const updatedTabs = tabs.slice();
      updatedTabs.splice(advancedSettingsTabIndex, 1);
      return updatedTabs;
    }
    return tabs;
  }, [showAdvancedSettings, tabs]);

  useEffect(() => {
    if (showAdvancedSettings && !project.advancedSettings) {
      project.advancedSettings = getDefaultAdvancedSettings();
      setProject({ ...project });
    }
    if (!showAdvancedSettings && project.advancedSettings) {
      project.advancedSettings = undefined;
      setProject({ ...project });
    }
  }, [showAdvancedSettings, project, setProject]);

  return updatedTabs;
};
