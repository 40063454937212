import { useMatches } from "react-router-dom";
import { memo, useMemo } from "react";
import { type Match } from "src/Components/Router/Router.i";

export const Breadcrumbs = memo(() => {
  const matches = useMatches() as Match[];

  const crumbs = useMemo(
    () =>
      matches
        .filter(match => Boolean(!!match.handle?.crumb))
        .flatMap(match => {
          const crumbs = match.handle?.crumb;

          if (Array.isArray(crumbs)) {
            return crumbs;
          } else if (typeof crumbs === "function") {
            return crumbs(match.params, match.data);
          } else {
            return crumbs; // Return an empty array if buttons is neither an array nor a function
          }
        }),
    [matches]
  );

  return useMemo(
    () => (
      <div className="menu-horizontal-container menu-horizontal-paths d-flex align-items-center justify-content-center">
        {crumbs.map((crumb, index) => (
          <div
            className={"menu-horizontal-element-wrapper"}
            key={`breadcrumb-${index}`}
          >
            {crumb}
          </div>
        ))}
      </div>
    ),
    [crumbs]
  );
});
