import type { PathExclusionEntry } from "@Services";
import { generateRandomUUID } from "../other";

export const generateRandomPathExclusions = (): PathExclusionEntry[] => {
  return [
    {
      path: `services/bola/preview/${generateRandomUUID()}`,
      methods: ["GET"],
    },
    {
      path: `services/bola/preview/${generateRandomUUID()}/download`,
      methods: ["GET", "POST", "PUT"],
    },

    {
      path: `services/login`,
      methods: ["POST"],
    },
    {
      path: `api/login/attempts`,
      methods: ["GET"],
    },
    {
      path: `api/v2/login/attempts`,
      methods: ["GET"],
    },
    {
      path: `services/img/convert`,
      methods: ["PUT"],
    },
    {
      path: `docs/${generateRandomUUID()}`,
      methods: ["GET"],
    },
    {
      path: `api/users/payments`,
      methods: ["POST"],
    },
    {
      path: "/users",
      methods: ["POST", "PATCH"],
    },
  ];
};
