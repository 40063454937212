import type { ChangeEvent } from "react";
import type { Props } from "../OtpInput.i";

type OtpSetter = Props["setOtp"];

export const handleInputChange = (
  refs: HTMLInputElement[],
  event: ChangeEvent<HTMLInputElement>,
  index: number,
  setOtp: OtpSetter
) => {
  const value = event.target.value;
  const length = value.length;

  if (length === 1 && index < refs.length - 1) {
    refs[index + 1].focus();
  } else if (length === 0 && index > 0) {
    refs[index - 1].focus();
  }
  const otp = refs.map(i => i.value).join("");
  setOtp(otp);
};
