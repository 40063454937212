import { storeJwtToken, verifyOtp } from "@Services";
import type { NavigateFunction } from "react-router-dom";

export const sendOtpHandler = async (
  otp: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  verifyOtp({
    otp,
    setError,
    setLoading,
    successCallback: token => {
      storeJwtToken(token, () => navigate("/"));
    },
  });
};
