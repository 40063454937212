import { InfoBadge, MethodBadge, NotificationBadge } from "@Components";
import type { Inventory, Method as MethodI } from "@Interfaces";
import { getIcons } from "@Pages";
import { type ListedInventoryItem } from "@Services";
import { type Dispatch } from "react";
import ReactDOMServer from "react-dom/server";
import { getTooltips } from "../Components/functions";

export const useParameterLinksTableConfig = (
  items: Inventory[],
  onItemSelect: Dispatch<ListedInventoryItem>
) => {
  const config = [];

  for (const index in items) {
    const data = items[index];

    const { tagsTooltipProps } = getTooltips(data);
    const maxElements = 5;

    config.push({
      props: {
        className: "inventory-item-list-row active",
        onClick: () => onItemSelect(data),
      },
      items: [
        {
          jsx: (
            <div className="d-flex align-items-center">
              <MethodBadge className="me-2" type={data.verb as MethodI} />
              <span>{data.path}</span>
              {data.createdFrom === "FROM_EXTENSION" && (
                <NotificationBadge display="sup" type="shadow" />
              )}
            </div>
          ),
        },
        {
          jsx: <div>{data.category}</div>,
        },
        {
          jsx: (
            <div className="d-flex" {...tagsTooltipProps}>
              {data.tags.map((t, index) =>
                index < 2 ? (
                  <InfoBadge key={t + index} className="me-2">
                    {t}
                  </InfoBadge>
                ) : (
                  <></>
                )
              )}
              {data.tags.length > 2 ? <span className="ms-2">...</span> : ""}
            </div>
          ),
        },
        {
          jsx: (
            <div className="d-flex">
              {getIcons(data.dataFormatInputs)
                .filter((_, i) => i < maxElements)
                .map((i, index) => (
                  <i
                    key={i.label + index}
                    data-tooltip-id="tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      <>
                        <span className="color-pink">Category: </span> {i.label}
                      </>
                    )}
                    className={i.icon + ` ms-2`}
                  />
                ))}
              {data.dataFormatInputs.length >= maxElements ? (
                <span className="ms-2">...</span>
              ) : (
                ""
              )}
            </div>
          ),
          hide: !data.dataFormatInputs.length,
        },
        {
          jsx: (
            <div className="d-flex">
              {getIcons(data.dataFormatOutputs)
                .filter((_, i) => i < maxElements)
                .map((o, index) => (
                  <i
                    key={o.label + index}
                    data-tooltip-id="tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      <>
                        <span className="color-pink">Category: </span> {o.label}
                      </>
                    )}
                    className={o.icon + ` ms-2`}
                  />
                ))}
              {data.dataFormatOutputs.length >= maxElements ? (
                <span className="ms-2">...</span>
              ) : (
                ""
              )}
            </div>
          ),
          hide: !data.dataFormatOutputs.length,
        },
        {
          jsx: (
            <div>
              {data.authenticationRequired ? <i className="bi-lock" /> : ""}
            </div>
          ),
          hide: !data.authenticationRequired,
        },
      ],
    });
  }

  return config;
};
