import type { Setter } from "@Interfaces";
import type { ListedAPIDefinition } from "@Services";
import { dateTimeFormat, getPreference } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";
import type { NavigateFunction } from "react-router-dom";
import { PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH } from "@Constants";

const maxDescriptionSize = 70;

export const getConfig = (
  items: ListedAPIDefinition[],
  organizationId: string,
  projectId: string,
  navigate: NavigateFunction,
  setShowMoreCanvasProps: Setter<[string, string]>
) => {
  const searchUrl = getPreference("project.inventory.search");
  return items.map(data => {
    let descriptionJsx = <>{data.description}</>;

    if (data.description.length > maxDescriptionSize) {
      const sliced = data.description.slice(0, maxDescriptionSize);
      descriptionJsx = (
        <>
          {sliced}...
          <span
            className="color-pink ms-2"
            onClick={e => {
              e.stopPropagation();
              setShowMoreCanvasProps(["description", data.description]);
            }}
          >
            {t("common.show-more")}
          </span>
        </>
      );
    }
    return {
      props: {
        className: "api-definitions-list-row active",
        onClick: (e: React.MouseEvent<HTMLTableRowElement>) => {
          e.stopPropagation();
          let to = generateUrl(PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH, [
            organizationId,
            projectId,
            data.id,
          ]);
          if (searchUrl) to = `${to}${searchUrl}`;
          navigate(to);
        },
      },
      items: [
        {
          jsx: <div>{data.apiVersion}</div>,
        },
        {
          jsx: <div>{dateTimeFormat(data.createdAt)}</div>,
        },
        {
          jsx: <div>{data.title}</div>,
        },
        {
          jsx: <div>{descriptionJsx}</div>,
        },
        {
          jsx: <div>{data.revisionVersion}</div>,
        },
        {
          jsx: (
            <div>
              {data.wasPatched ? (
                <span className="color-severity-medium">
                  {t("common.patched")}
                </span>
              ) : (
                t("common.unpatched")
              )}
            </div>
          ),
        },
      ],
    };
  });
};
