import { createStore } from "@Hooks";
import type { InventoryDetail, Nullable } from "@Interfaces";
import type { Parameter } from "@Services";
import type { Dispatch } from "react";

export type BaseLocation = "body" | "header" | "cookie";

export type ProducerLocation = BaseLocation;
export type ConsumerLocation = BaseLocation | "query" | "path";

type OffCanvasAddParameterLinkStoreProperties = {
  producingInventory: Nullable<InventoryDetail>;
  producingParameter: Nullable<Parameter>;
  producingParameterLocation: Nullable<ProducerLocation>;
  producingParameterPath: Nullable<string>;
  producingLocations: Array<string>;
  producingParameters: Array<Parameter>;
  consumingInventory: Nullable<InventoryDetail>;
  consumingParameter: Nullable<Parameter>;
  consumingParameterLocation: Nullable<ConsumerLocation>;
  consumingParameterPath: Nullable<string>;
  consumingLocations: Array<string>;
  consumingParameters: Array<Parameter>;
  activeStep: number;
};

type OffCanvasAddParameterLinkStore =
  OffCanvasAddParameterLinkStoreProperties & {
    setProducingInventory: Dispatch<InventoryDetail>;
    setProducingParameterLocation: Dispatch<Nullable<ProducerLocation>>;
    setProducingParameterPath: Dispatch<Nullable<Parameter>>;
    setConsumingInventory: Dispatch<Nullable<InventoryDetail>>;
    setConsumingParameterLocation: Dispatch<Nullable<ConsumerLocation>>;
    setConsumingParameterPath: Dispatch<Nullable<Parameter>>;
    reset: VoidFunction;
  };

const initialState: OffCanvasAddParameterLinkStoreProperties = {
  producingInventory: null,
  producingParameter: null,
  producingParameterLocation: null,
  producingParameterPath: null,
  producingLocations: [],
  producingParameters: [],
  consumingInventory: null,
  consumingParameter: null,
  consumingParameterLocation: null,
  consumingParameterPath: null,
  consumingLocations: [],
  consumingParameters: [],
  activeStep: 0,
};

const determineNextActiveStep = (
  state: OffCanvasAddParameterLinkStoreProperties
): number => {
  if (!state.producingParameterLocation) return 0;
  if (!state.producingParameterPath) return 1;
  if (!state.consumingInventory) return 2;
  if (!state.consumingParameterLocation) return 3;
  if (!state.consumingParameterPath) return 4;
  return 5;
};

export const useOffCanvasAddParameterLinkStore =
  createStore<OffCanvasAddParameterLinkStore>((get, set) => ({
    ...initialState,
    setProducingInventory: (producingInventory: InventoryDetail) => {
      set(state => {
        const producingLocations = producingInventory.parameters
          .map(p =>
            p.in && ["body", "header", "cookie"].includes(p.in) ? p.in : ""
          )
          .filter(Boolean) as string[];

        return {
          ...state,
          producingInventory,
          producingLocations,
          activeStep: determineNextActiveStep(state),
        };
      });
    },
    setProducingParameterLocation: (
      producerLocation: Nullable<ProducerLocation>
    ) => {
      set(state => {
        const nextProducerLocation =
          producerLocation === state.producingParameterLocation
            ? null
            : producerLocation;

        const nextParameters = nextProducerLocation
          ? state.producingInventory?.parameters.filter(
              p => p.in === nextProducerLocation
            ) ?? []
          : [];

        const newState = {
          ...state,
          producingParameterLocation: nextProducerLocation,
          producingParameterPath: null,
          producingParameters: removeDuplicates(nextParameters),
        };
        return {
          ...newState,
          activeStep: determineNextActiveStep(newState),
        };
      });
    },
    setProducingParameterPath: (producingParameter: Nullable<Parameter>) => {
      set(state => {
        if (!producingParameter) {
          return {
            ...state,
            producingParameterPath: null,
            producingParameter: null,
          };
        }

        const nextProducingPath =
          producingParameter.pathName === state.producingParameterPath
            ? null
            : producingParameter.pathName;

        const newState = {
          ...state,
          producingParameterPath: nextProducingPath,
          producingParameter,
        };
        return {
          ...newState,
          activeStep: determineNextActiveStep(newState),
        };
      });
    },
    setConsumingInventory: (consumingInventory: Nullable<InventoryDetail>) => {
      set(state => {
        const consumingLocations = consumingInventory?.parameters
          .map(p =>
            p.in && ["body", "header", "cookie", "path", "query"].includes(p.in)
              ? p.in
              : ""
          )
          .filter(Boolean) as string[];

        const newState = {
          ...state,
          consumingInventory,
          consumingLocations,
          consumingParameterLocation: null,
          consumingParameterPath: null,
        };
        return {
          ...newState,
          activeStep: determineNextActiveStep(newState),
        };
      });
    },
    setConsumingParameterLocation: (
      consumingParameterLocation: Nullable<ConsumerLocation>
    ) => {
      set(state => {
        const nextConsumingParameterLocation =
          consumingParameterLocation === state.consumingParameterLocation
            ? null
            : consumingParameterLocation;

        const nextParameters = nextConsumingParameterLocation
          ? state.consumingInventory?.parameters.filter(
              p => p.in === nextConsumingParameterLocation
            ) ?? []
          : [];

        const newState = {
          ...state,
          consumingParameterLocation: nextConsumingParameterLocation,
          consumingParameters: removeDuplicates(nextParameters),
        };

        return {
          ...newState,
          activeStep: determineNextActiveStep(newState),
        };
      });
    },
    setConsumingParameterPath: (consumingParameter: Nullable<Parameter>) => {
      set(state => {
        if (!consumingParameter) {
          return {
            ...state,
            consumingParameterPath: null,
            consumingParameter: null,
          };
        }

        const nextConsumingPath =
          consumingParameter.pathName === state.consumingParameterPath
            ? null
            : consumingParameter.pathName;

        const newState = {
          ...state,
          consumingParameterPath: nextConsumingPath,
          consumingParameter,
        };

        return {
          ...newState,
          activeStep: determineNextActiveStep(newState),
        };
      });
    },
    reset: () => set(state => ({ ...state, ...initialState })),
  }));

const removeDuplicates = (parameters: Parameter[]) => {
  const registeredKeys: Set<string> = new Set<string>();
  const result: Parameter[] = [];

  parameters.forEach(p => {
    const key = `${p.name}_${p.pathName}`;
    if (!registeredKeys.has(key)) {
      result.push(p);
      registeredKeys.add(key);
    }
  });

  return result;
};
