import type { ApiKeyId, Log } from "@Interfaces";
import { apiKeys } from "../apiKeys";
import moment from "moment-timezone";

const apiKeyAuditLogsMap = new Map<ApiKeyId, Log[]>();

apiKeys.forEach(({ id }, i) => {
  apiKeyAuditLogsMap.set(
    id,
    Array.from({ length: 3 }, (_, j) => ({
      createdAt: moment()
        .subtract(i + j, "days")
        .toISOString(),
      id,
      ipAddress: "192.168.1.1",
      method: "",
      path: "/path",
      requesterRole: "",
      userAgent: `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3`,
      userID: id,
      resourceID: "",
      status: 0,
      organization_id: "1",
    }))
  );
});

export const apiKeyAuditLogs = apiKeyAuditLogsMap;
