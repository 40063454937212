import React, { useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { useOtpToken, useQrCode } from "@Hooks";
import { OtpInput, SkeletonImg } from "@Components";
import useSetupOtp from "./useSetupOtp";
import { t } from "i18next";
import { setAxiosAuth } from "@Services";

export const SetupOtp = React.memo(() => {
  const { setOtp, error, loading } = useSetupOtp();
  const token = useOtpToken();
  const { loading: qrCodeLoading, qrCodeImg } = useQrCode({ token });

  useEffect(() => {
    if (token) {
      setAxiosAuth(token);
    }
  }, [token]);

  return (
    <>
      <h2 className="text-center mt-0 mb-3">{t("login.otp.title")}</h2>
      <Row>
        <Col xs={12} sm={6}>
          {(qrCodeLoading && <SkeletonImg width="128" height="128" />) || (
            <>
              {(qrCodeImg && (
                <img src={qrCodeImg} className="w-100" alt="QR code" />
              )) ||
                ""}
            </>
          )}
        </Col>
        <Col xs={12} sm={6}>
          <div className="d-flex align-items-center">
            <i className="bi-qr-code-scan" />
            <span className="ms-2"> {t("login.otp.step1")}</span>
          </div>
          <div className="d-flex align-items-center mt-2">
            <i className="bi-check-circle" />
            <span className="ms-2">{t("login.otp.step2")}</span>
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <p className="form-label">{t("login.otp.input-label")}</p>
        <OtpInput setOtp={setOtp} disabled={loading} />
      </div>
      {loading && !error && (
        <div className="text-center mt-3">
          <Spinner />
        </div>
      )}
      {error && (
        <div className="alert alert-danger mt-3 mb-0" role="alert">
          {error}
        </div>
      )}
    </>
  );
});
