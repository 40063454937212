import { useState } from "react";

export const useApiKeyCanvas = () => {
  const [canvasOpen, setCanvasOpen] = useState(false);

  return {
    canvasOpen,
    openCanvas: () => setCanvasOpen(true),
    closeCanvas: () => setCanvasOpen(false),
  };
};
