import { useCallback, useMemo } from "react";
import type { PathInfoProps } from "./PathInfo.i";
import { useValidDomain } from "@Hooks";
import { isValid, trimByChar } from "@Services";

export const usePathInfo = ({
  project,
  projectExtras,
  setProject,
}: PathInfoProps) => {
  const { baseURL, isProductionURL } = project;

  const { isDomainEnabled, enabledDomains } = useValidDomain();

  const baseUrlOptions = useMemo(() => {
    const collection = new Set<string>();
    projectExtras.oasFile?.baseUrls?.forEach(u => collection.add(u));
    enabledDomains?.forEach(u => collection.add(u));
    return Array.from(collection).map(url => ({
      label: url,
      value: trimByChar(url, "*"),
    }));
  }, [projectExtras.oasFile?.baseUrls, enabledDomains]);

  const { isValidUrl, domainEnabled } = useMemo(() => {
    const trimmedBaseUrl = baseURL.trim();
    const domainEnabled = isDomainEnabled(trimmedBaseUrl);
    const valid = isValid("url", trimmedBaseUrl);
    const isValidUrl = domainEnabled && valid;
    return { isValidUrl, domainEnabled };
  }, [isDomainEnabled, baseURL]);

  const handleProjectChange = useCallback(
    (args: { baseURL?: string; isProductionURL?: boolean }) => {
      setProject({ ...project, ...args });
    },
    [project, setProject]
  );

  return {
    isProductionURL,
    baseURL,
    baseUrlOptions,
    handleProjectChange,
    isValidUrl,
    enabledDomains,
    domainEnabled,
  };
};
