import type { ListedProject } from "@Services";
import { dateTimeFormat } from "@Services";
import type { NavigateFunction } from "react-router-dom";
import { getStatusIcon } from "./getStatusIcon";
import { getNextRun } from "./getNextRun";
import { Vulnerabilities } from "@Components";
import { t } from "i18next";
import { getProjectStatus } from "@Utils";

export const getConfig = (
  projects: ListedProject[],
  pathname: string,
  navigate: NavigateFunction
) =>
  projects.map((project, index) => {
    const status = getProjectStatus(project);
    const isActive = status !== "pending";
    const oddEven = index % 2 ? "even" : "odd";
    const activeClass = isActive ? "active" : "inactive";

    return {
      props: {
        className: `project-list-row ${status} ${oddEven} ${activeClass} col-md-12 col-lg-6 col-xl-6 col-xxl-4`,
        onClick: (e: React.MouseEvent<HTMLTableRowElement>) => {
          e.stopPropagation();
          if (!isActive) return;
          const dest = project.status === "error" ? "/settings" : "";
          navigate(`${pathname}/${project.id}${dest}`);
        },
      },
      items: [
        {
          props: {
            className: "project-list-row",
          },
          jsx: <div>{project.name}</div>,
        },
        {
          jsx: (
            <div>
              <i className={`${getStatusIcon(status)} me-2`} />
              {status === "archived" ? (
                <span
                  data-tooltip-id="tooltip"
                  data-tooltip-content={t("common.archived-on", {
                    date: dateTimeFormat(project.archivedAt),
                  })}
                >
                  {t(`labels.${status}`)}
                </span>
              ) : (
                t(`labels.${status}`)
              )}
            </div>
          ),
        },
        {
          jsx: (
            <div>{dateTimeFormat(project.createdAt, { trimTime: true })}</div>
          ),
        },
        {
          jsx: (
            <div>
              {project?.latestScan ? dateTimeFormat(project.latestScan) : ""}
            </div>
          ),
          hide: !project?.latestScan,
        },
        {
          jsx: (
            <div className="d-flex align-items-center h-100">
              <Vulnerabilities {...project.latestScanSeverityCount} />
            </div>
          ),
          hide: !(project?.latestScanSeverityCount && isActive),
        },
        {
          jsx: <div>{getNextRun(project?.nextRun)}</div>,
          hide: !project?.nextRun || status === "archived",
        },
      ],
    };
  });
