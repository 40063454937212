import type { UserDataProps } from "./UserData.i";
import { DisplaySection } from "@Components";
import { equals } from "@Services";
import { memo, type FC } from "react";
import { useUserData } from "./useUserData";

export const UserData: FC<UserDataProps> = memo(({ user }) => {
  const { email, expiresAt, name, role, surname } = useUserData(user);
  return (
    <div>
      <DisplaySection mask label="common.name" value={name} />
      <DisplaySection mask label="common.surname" value={surname} />
      <DisplaySection mask label="common.email" value={email} />
      <DisplaySection mask label="common.role" value={role} />
      {expiresAt && (
        <DisplaySection mask label="common.expires-at" value={expiresAt} />
      )}
    </div>
  );
}, equals);
