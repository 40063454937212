import { SkeletonBox, SortInput, Table } from "@Components";
import { memo } from "react";
import { ListItemCard } from "./ListItemCard";
import type { Props, Tr } from "./ListItems.i";
import { SwitchButtons } from "./SwitchButtons";
import { useOrderedConfig } from "./useOrderedConfig";

import "./style.scss";
import { BEM, joinClasses } from "@Utils";

export const ListItems = memo((props: Props) => {
  const {
    config,
    cardVersion,
    updateCardItemOrder,
    fullRowIndexes,
    showSkeleton,
    clickable = true,
    setCardVersion,
    defaultSorts,
    setSorts,
    ...tableProps
  } = props;

  const { orderedConfig, orderedHeaders } = useOrderedConfig(
    config,
    tableProps.headers,
    updateCardItemOrder
  );

  const { className: tableClassName = "" } = tableProps;

  return (
    <>
      {setCardVersion && (
        <div className="d-flex align-items-center justify-content-end">
          {cardVersion && setSorts && (
            <SortInput
              headerItems={tableProps.headers}
              updateFunction={setSorts}
              className="me-2"
              defaultSorts={defaultSorts}
            />
          )}
          <SwitchButtons
            cardVersion={cardVersion}
            setCardVersion={setCardVersion}
          />
        </div>
      )}
      {cardVersion ? (
        <>
          {showSkeleton ? (
            <SkeletonBox width="100%" height="250">
              <rect x="0" y="0" rx="3" ry="3" width="32%" height="250" />
              <rect x="34%" y="0" rx="3" ry="3" width="32%" height="250" />
              <rect x="68%" y="0" rx="3" ry="3" width="32%" height="250" />
            </SkeletonBox>
          ) : (
            <div className={`card-style ${clickable ? "clickable" : ""}`}>
              {orderedConfig.map((tr, j) => {
                const { className = "", ...rest } = tr.props;
                return (
                  <div
                    key={j}
                    {...(rest || {})}
                    className={joinClasses(
                      BEM("card-style", "item"),
                      className
                    )}
                    data-cy={"table-row"}
                  >
                    <ListItemCard
                      tr={tr}
                      headers={orderedHeaders}
                      fullRowIndexes={fullRowIndexes}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <Table
          showSkeleton={showSkeleton}
          {...tableProps}
          className={joinClasses(
            tableClassName,
            clickable && "clickable",
            showSkeleton && "loading"
          )}
        >
          {config.map((tr, j) => (
            <tr key={j} {...(tr.props || {})} data-cy={"table-row"}>
              {tr.items.map((td, i) => (
                <td
                  key={i}
                  {...(td?.props || {})}
                  style={{ width: td.fixedWidth }}
                >
                  {td.jsx}
                </td>
              ))}
            </tr>
          ))}
        </Table>
      )}
    </>
  );
});

export type { Tr as ListItemConfigElement };
