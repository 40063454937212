import { Breadcrumb } from "@Components";
import {
  ORGANIZATION_PATH_USERS,
  organizationLastVisitedPageRegexp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type OrganizatiPropsonScanListBreadcrumb = {
  disabled?: boolean;
};

export const OrganizationUserListBreadcrumbBreadcrumb = ({
  disabled = false,
}: OrganizatiPropsonScanListBreadcrumb) => {
  const { organizationId } = useUrlParams();
  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(organizationLastVisitedPageRegexp("users")) ??
      generateUrl(ORGANIZATION_PATH_USERS, [organizationId ?? ""]);
  return (
    <Breadcrumb disabled={disabled} name={"organization-users"} to={to}>
      {t("common.users")}
    </Breadcrumb>
  );
};
