import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import type { InjectableFormProps } from "../InjectableParameterForm.i";
import { useInjectableNameErrorDetector } from "./useInjectableNameErrorDetector";
import { InjectableLocation, isFilledString } from "@Services";

const validLocations = Object.values(InjectableLocation);

export const useInjectableParameterForm = (props: InjectableFormProps) => {
  const {
    injectableEntry,
    setInjectableEntry,
    setFormValid,
    localInjectables,
    globalInjectables,
  } = props;

  const handleChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setInjectableEntry(prev => ({ ...prev, [name]: value }));
    },
    [setInjectableEntry]
  );

  useEffect(() => {
    if (!injectableEntry.location) {
      setInjectableEntry(prev => ({ ...prev, location: "header" }));
    }
  }, [injectableEntry.location, setInjectableEntry]);

  const injectableNameError = useInjectableNameErrorDetector(
    injectableEntry,
    localInjectables,
    globalInjectables
  );

  const validities = useMemo(() => {
    const dangerMessage = !!(injectableNameError?.severity === "danger");
    const validLocation = validLocations.includes(injectableEntry.location);

    return {
      location: validLocation,
      name: !dangerMessage && isFilledString(injectableEntry.name),
      value: isFilledString(injectableEntry.value),
      associatedAuthSetting: true,
    };
  }, [
    injectableNameError?.severity,
    injectableEntry.location,
    injectableEntry.name,
    injectableEntry.value,
  ]);

  useEffect(() => {
    if (!setFormValid) return;
    const valid = Object.values(validities).every(Boolean);
    setFormValid(valid);
  }, [setFormValid, validities]);

  return {
    ...props,
    setInjectableEntry: handleChange,
    validities,
    injectableNameError,
  };
};
