import { type TabProps } from "@Components";
import { useState } from "react";

const tabs: Array<TabProps> = [
  {
    label: "common.general",
    iconClass: "bi bi-info-square-fill",
  },
  {
    label: "common.parameter-link",
    iconClass: "bi bi-link-45deg",
  },
];

export const useAPIDefinitionDetailsTabs = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return {
    activeTab,
    setActiveTab,
    tabs,
  };
};
