import type { Inventory, Predicate } from "@Interfaces";
import type { UserListInventoryItemsResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  delay,
  getFiltersFromUrlParams,
  getInventoryItemsByApiDefinitionId,
  getProjectInfoById,
} from "src/Mock";

export const getInventory = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { params } = req;
  const apiDefinitionId = `${params.apiDefinitionId}`;
  const projectId = `${params.projectId}`;
  const organizationId = `${params.organizationId}`;

  const project = getProjectInfoById(projectId);

  if (!organizationId || !apiDefinitionId || !project) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  let collection: Inventory[] = getInventoryItemsByApiDefinitionId(
    apiDefinitionId
  ).map(i => ({
    ...i,
    baseURL: project.baseURL,
  }));

  const { searchParams } = req.url;

  collection = filterCollection(collection, searchParams);
  collection = sortCollection(collection, searchParams);

  const { page, size } = getFiltersFromUrlParams(searchParams);

  const offset = (page - 1) * size;
  const limit = offset + size;

  const response: UserListInventoryItemsResponse = {
    data: collection.slice(offset, limit),
    page,
    size,
    totalItems: collection.length,
    totalPages: Math.ceil(collection.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};

function filterCollection(
  collection: Inventory[],
  searchParams: URLSearchParams
) {
  const { criteria, values, operators } = getFiltersFromUrlParams(searchParams);
  const predicates: Predicate<Inventory>[] = [];

  criteria.forEach((c, i) => {
    const value = values[i].toLowerCase();
    const operator = operators ? operators[i] : null;
    if (!value) {
      return;
    } else if (c === "inventory_item_verb") {
      predicates.push(i => {
        return operator && operator === "NEQ"
          ? i.verb.toLowerCase() !== value
          : i.verb.toLowerCase() === value;
      });
    } else if (c === "inventory_item_path") {
      predicates.push(i =>
        operator && operator === "NEQ"
          ? i.path.toLowerCase() !== value
          : i.path.toLowerCase().includes(value)
      );
    } else if (c === "inventory_item_category") {
      predicates.push(i => i.category.toLowerCase().includes(value));
    } else if (c === "inventory_item_authentication_expected") {
      if (value === "true" || value === "false") {
        const needAuth = value === "true";
        predicates.push(i => i.authenticationRequired === needAuth);
      }
    }
  });

  return collection.filter(e => predicates.every(p => p(e)));
}

function sortCollection(
  collection: Inventory[],
  searchParams: URLSearchParams
) {
  const { sortBy, sortMode } = getFiltersFromUrlParams(searchParams);
  const result = collection.slice();
  if (!sortMode || sortBy === "inventory_item_path") {
    result.sort((a, b) => {
      const mult = sortMode === "asc" ? 1 : -1;
      return mult * a.path.localeCompare(b.path);
    });
  }
  return result;
}
