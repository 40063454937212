import type { AuthenticationSettings } from "@Services";
import { generateRandomJWT } from "../other";

export const generateRandomAuthenticationProfiles =
  (): AuthenticationSettings[] => {
    return [
      {
        name: `API JWT`,
        description: `API V1 Authentication. Privileges : GET + CREATE + UPDATE. Authorization Level : project admin`,
        role: `admin`,
        group: ``,
        authenticationFlow: `static_token`,
        // ----------------
        credentialInputs: {},
        inputOperations: [],
        userAuthTokens: [
          {
            authenticationSchemeType: "http-bearer",
            key: "Authorization",
            location: "header",
            value: generateRandomJWT(),
          },
        ],
        refreshDefinition: {
          type: "external",
          refreshInterval: 10000000000,
          oAuthData: {
            clientID: "client-id",
            clientSecret: "client-secret",
            grantType: "refresh_token",
            tokenEndpoint: "https://app.equixly.com",
            additionalData: {},
            password: "",
            refreshToken:
              "eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0=.eyJyYW5kb21fdmFsdWVfMCI6MTUzMTk0LCJyYW5kb21fdmFsdWVfMSI6MTUyMzA4LCJyYW5kb21fdmFsdWVfMiI6MTMzMzE2fQ==.ZXlKaGJHY2lPaUp1YjI1bElpd2lkSGx3SWpvaVNsZFVJbjA9LmV5SnlZVzVrYjIxZmRtRnNkV1ZmTUNJNk1UVXpNVGswTENKeVlXNWtiMjFmZG1Gc2RXVmZNU0k2TVRVeU16QTRMQ0p5WVc1a2IyMWZkbUZzZFdWZk1pSTZNVE16TXpFMmZRPT0=",
            revocationEndpoint: "",
            scope: "email",
            username: "",
          },
        },
      },
      {
        name: `Basic authentication`,
        description: `HTTP Authentication. Role : User. Credentials accepted until 31/12/2024`,
        role: `user`,
        group: ``,
        authenticationFlow: `credentials`,
        credentialInputs: {
          username: `equixly_test_user`,
          password: `EquixLY_1_test_BOLA_#`,
        },
        inputOperations: [
          {
            methods: [`POST`],
            path: `/v1/login/users`,
          },
          {
            methods: [`GET`],
            path: `/v1/login/authToken`,
          },
        ],
        // ----------------
        userAuthTokens: [
          {
            authenticationSchemeType: "http-basic",
            key: "Authorization",
            location: "header",
            value: generateRandomJWT(),
          },
        ],
      },
      {
        name: `Privileged access - Admin`,
        description: `HTTP Authentication. Role : Admin. Credentials accepted until 31/12/2024`,
        role: `admin`,
        group: `admin`,
        authenticationFlow: `credentials`,
        credentialInputs: {
          username: `equixly_test_admin`,
          password: `EquixLY_2_test_BOLA_#`,
        },
        inputOperations: [
          {
            methods: [`POST`, `PUT`],
            path: `/v1/login/authToken`,
          },
        ],
        // ----------------
        userAuthTokens: [
          {
            authenticationSchemeType: "http-basic",
            key: "Authorization",
            location: "header",
            value: generateRandomJWT(),
          },
        ],
      },
      {
        name: "Test OAuth",
        description: "Test OAuth Description",
        authenticationFlow: "oauth_credentials",
        role: "admin",
        group: "Test",
        credentialInputs: {},
        inputOperations: [],
        oAuthData: {
          clientID: "Test",
          clientSecret: "Test",
          grantType: "client_credentials",
          tokenEndpoint: "https://api.equixly.com",
          additionalData: {
            "test-data-1": "test data",
          },
          password: "",
          refreshToken: "",
          revocationEndpoint: "https://api.equixly.com",
          scope: "Test",
          username: "",
        },
        userAuthTokens: [
          {
            authenticationSchemeType: "http-bearer",
            key: "Authorization",
            location: "header",
          },
        ],
      },
    ];
  };
