import { type FC } from "react";
import { FormInput, GridContainer } from "@Components";
import { FormGroup } from "reactstrap";
import type { Options } from "@Interfaces";
import { roles } from "../../constants";
import { useMainForm } from "./useMainForm";
import type { MainFormProps } from "./MainForm.i";
import { NameInput } from "./NameInput";

const roleOptions: Options = roles.map(role => ({
  label: `common.${role}`,
  value: role,
}));

export const MainForm: FC<MainFormProps> = props => {
  const {
    authenticationSettings,
    handleUpdate,
    validities,
    nameDuplicated,
    nameEditable,
    loading,
  } = useMainForm(props);

  return (
    <>
      <FormGroup>
        <NameInput
          duplicated={nameDuplicated}
          editable={nameEditable}
          onChange={handleUpdate}
          valid={validities.name}
          value={authenticationSettings.name}
          disabled={loading}
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          type="textarea"
          label="common.description"
          name="description"
          id="authentication-setting-description"
          value={authenticationSettings.description}
          onChange={handleUpdate}
          bsSize="sm"
          rows={3}
          valid={validities.description}
          invalid={!validities.description}
          required
          disabled={loading}
          autoTrim
          autoComplete="off"
          data-cy="authentication-settings-input-description"
        />
      </FormGroup>
      <FormGroup>
        <GridContainer columns={2}>
          <FormInput
            type="select"
            label="common.role"
            name="role"
            id="authentication-setting-role"
            value={authenticationSettings.role}
            onChange={handleUpdate}
            bsSize="sm"
            disabled={loading}
            options={roleOptions}
            data-cy="authentication-settings-input-role"
          />
          <FormInput
            type="text"
            label="common.group"
            name="group"
            id="authentication-setting-group"
            value={authenticationSettings.group}
            onChange={handleUpdate}
            bsSize="sm"
            disabled={loading}
            autoTrim
            data-cy="authentication-settings-input-group"
          />
        </GridContainer>
      </FormGroup>
    </>
  );
};
