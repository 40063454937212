interface State {
  fetched: boolean;
  fetching: boolean;
  hasAnyItem: boolean | null;
}

type Action =
  | { type: "START_FETCHING" }
  | { type: "FINISH_FETCHING" }
  | { type: "SET_HAS_ANY_ITEM"; payload: boolean }
  | { type: "RESET" };

export const initialState: State = {
  fetched: false,
  fetching: false,
  hasAnyItem: null,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "START_FETCHING":
      return {
        ...state,
        fetching: true,
      };
    case "FINISH_FETCHING":
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    case "SET_HAS_ANY_ITEM":
      return {
        ...state,
        hasAnyItem: action.payload,
      };
    default:
      return state;
  }
};
