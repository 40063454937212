import { t } from "i18next";
import { CurtesyPage } from "../CurtesyPage";

export const NoParameterLinks = () => {
  return (
    <CurtesyPage>
      <i className="bi bi-link-45deg font-size-h1 color-gray"></i>
      <h3 className="mb-3">{t("common.no-parameter-links")}</h3>
      <p style={{ width: "clamp(10rem, 50vw, 50rem)" }} className="text-center">
        {t("help.no-parameter-links")}
      </p>
    </CurtesyPage>
  );
};
