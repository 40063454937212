import { FormInput } from "../Form";
import { getFilterAllowedOptions } from "@Services";
import { useMemo } from "react";
import type { Props } from "./PageFilters.i";
import { RefreshListButton } from "../Buttons";
import { usePageFilters } from "./usePageFilters";
import { PageFiltersWrapper } from "./PageFiltersWrapper";

export const ScansFilters = ({ updateFilters, refresh }: Props) => {
  const { getValueOf } = usePageFilters();
  const [periodOptions, scanStatusOptions] = useMemo(() => {
    return [
      getFilterAllowedOptions("period"),
      getFilterAllowedOptions("scan_status"),
    ];
  }, []);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-scan-period"
        label="components.date-filter.period-label"
        type="select"
        options={periodOptions}
        value={getValueOf("period")}
        onChange={e => {
          updateFilters({
            period: e.target.value,
          });
        }}
      />
      <FormInput
        id="select-scan-status"
        className="ms-3"
        label="common.status"
        type="select"
        options={scanStatusOptions}
        value={getValueOf("scan_status")}
        onChange={e => {
          updateFilters({
            scan_status: e.target.value,
          });
        }}
      />
      <div className="d-flex align-items-end ms-3">
        <RefreshListButton onClick={refresh} />
      </div>
    </PageFiltersWrapper>
  );
};
