import React, { useEffect, useRef } from "react";
import type { FontSpec } from "chart.js";
import { Chart, registerables } from "chart.js";
import { toFont } from "chart.js/helpers";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type { Props } from "./DoughnutChart.i";
import type { Font } from "chartjs-plugin-datalabels/types/options";
import { displayLabels, percentageLabel, sortData } from "./functions";
import { SectorsSizePlugin } from "./pluginSectorsSize";

import "./style.scss";

Chart.register(...registerables);
Chart.register(SectorsSizePlugin);
Chart.register(ChartDataLabels);

export const DoughnutChart = ({ data }: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<"doughnut"> | null>(null);

  // must be sorted in order to have high severity as last sector
  sortData(data);

  useEffect(() => {
    if (chartRef.current) {
      const chartCanvas = chartRef.current.getContext("2d");

      if (chartCanvas) {
        if (!chartInstance.current) {
          const font: Partial<Font & FontSpec> = {
            size: 16,
            weight: "bold",
          };
          const normFont = toFont(font);
          const paddingHeight = normFont.lineHeight;
          chartCanvas.save();
          chartCanvas.font = normFont.string;
          const paddingWidth = chartCanvas.measureText("XX %").width;
          chartCanvas.restore();

          chartInstance.current = new Chart(chartCanvas, {
            type: "doughnut",
            data: {
              labels: data.map(item => item.label),
              datasets: [
                {
                  data: data.map(item => item.value),
                  backgroundColor: data.map(item => item.color),
                  borderWidth: 0,
                },
              ],
            },
            options: {
              responsive: true,
              cutout: "70%",
              layout: {
                padding: {
                  top: paddingHeight,
                  left: paddingWidth,
                  bottom: paddingHeight,
                  right: paddingWidth,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
                sectorsSize: {
                  enabled: true,
                },
                datalabels: {
                  display: displayLabels,
                  anchor: "end",
                  align: "end",
                  padding: 0,
                  color: data.map(item => item.color),
                  font,
                  formatter: percentageLabel,
                },
              },
            },
          });
        } else {
          const dataset = chartInstance.current.data.datasets[0];
          dataset.data = data.map(item => item.value);
          dataset.backgroundColor = data.map(item => item.color);
          dataset.borderWidth = 0;
          chartInstance.current.update();
        }
      }
    }
  }, [data]);

  return (
    <canvas
      data-cy="doughnut-chart"
      className="doughnut-chart"
      ref={chartRef}
    />
  );
};
