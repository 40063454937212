import { withLicence } from "@Components";
import { DisplayRow } from "..";
import { t } from "i18next";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";
import { useSpaceLinkDetails } from "@Hooks";

type DisplayRowSpaceLinkProps = Pick<MainProps, "buttonProps"> & {
  spaceLinkID: string;
};

export const DisplayRowSpaceLink = withLicence(
  "Enterprise",
  ({ buttonProps, spaceLinkID }: DisplayRowSpaceLinkProps) => {
    const { spaceLink, fetching } = useSpaceLinkDetails(spaceLinkID);

    const getValue = () => {
      if (fetching) {
        return (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        );
      }

      if (spaceLink) {
        return <span>{spaceLink.name}</span>;
      }

      return (
        <span className="color-gray">
          {t("project.settings.connect-space-link-text")}
        </span>
      );
    };

    return (
      <DisplayRow
        title="common.space-link"
        columns={[
          {
            label: "common.name",
            value: getValue(),
            className: "mb-4 mb-xl-0",
            xs: 12,
          },
        ]}
        buttonProps={buttonProps}
      />
    );
  }
);
