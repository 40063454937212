import { Breadcrumb } from "@Components";
import {
  PROJECT_DETAILS_API_DEFINITIONS_PATH,
  projectLastVisitedPageRegExp,
} from "@Constants";
import { useUrlParams } from "@Hooks";
import { lastVisitedPageByRegexp } from "@Services";
import { generateUrl } from "@Utils";
import { t } from "i18next";

type ProjectAPIDefinitionsBreadcrumbProps = {
  disabled?: boolean;
};

export const ProjectAPIDefinitionsBreadcrumb = ({
  disabled = false,
}: ProjectAPIDefinitionsBreadcrumbProps) => {
  const { organizationId, projectId } = useUrlParams();
  const to = disabled
    ? undefined
    : lastVisitedPageByRegexp(
        projectLastVisitedPageRegExp("api-definitions")
      ) ??
      generateUrl(PROJECT_DETAILS_API_DEFINITIONS_PATH, [
        organizationId ?? "",
        projectId ?? "",
      ]);
  return (
    <Breadcrumb disabled={disabled} name={"project-api-definitions"} to={to}>
      {t("common.api-definitions")}
    </Breadcrumb>
  );
};
