import type {
  AuthenticationSettings,
  AuthenticationSettingsFlowType,
  OAuthData,
  RefreshDefinition,
} from "@Services";

export const EMPTY_OAUTHDATA: OAuthData = {
  clientID: "",
  clientSecret: "",
  grantType: "client_credentials",
  tokenEndpoint: "",
  additionalData: {},
  password: "",
  refreshToken: "",
  revocationEndpoint: "",
  scope: "",
  username: "",
};

export const EMPTY_REFRESH_DEFINITION: RefreshDefinition = {
  type: "internal",
  oAuthData: EMPTY_OAUTHDATA,
  operation: {
    methods: [],
    path: "",
  },
  refreshInterval: 0,
  tokenOutputKey: "",
};

const model: AuthenticationSettings = {
  name: "",
  description: "",
  authenticationFlow: "" as AuthenticationSettingsFlowType,
  role: "",
  group: "",
  // flow = credentials
  credentialInputs: {},
  inputOperations: [],
  // flow = token
  userAuthTokens: [],
};

export const getEmptyAuthenticationSetting = (
  base: Partial<AuthenticationSettings> = {}
): AuthenticationSettings => {
  const result: AuthenticationSettings = Object.assign({}, model, base);
  return result;
};
