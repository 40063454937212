import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useEffect, useMemo } from "react";
import { useAuthorizationMatrixStore } from "./Store/AuthorizationMatrixStore";
import { SCAN_AUTHMATRIX_FILTERS_KEY } from "src/Pages/Scans/constants";
type SortByType =
  | "auth_matrix_verb"
  | "auth_matrix_path"
  | "auth_matrix_category"
  | undefined;
type CriteriaType = ("auth_matrix_verb" | "auth_matrix_path")[] | undefined;
type OperatorsType = ("EQ" | "LIKE")[] | undefined;

export const useGetData = () => {
  const { setLoading, setOperationsAndProfiles, operations, profiles } =
    useAuthorizationMatrixStore();
  const setFilters = useUpdateCacheFilters(SCAN_AUTHMATRIX_FILTERS_KEY);

  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters: useMemo(() => getDefaultFilters("authMatrix"), []),
    getResponse: useCallback(
      ({
        organizationId,
        projectId,
        scanId,
        page,
        pageSize,
        sortBy,
        sortMode,
        criteria,
        operators,
        values,
      }) => {
        return API.scan().scanGetAuthorizationMatrix(
          organizationId,
          projectId,
          scanId,
          page,
          pageSize,
          sortBy as SortByType,
          sortMode,
          criteria as CriteriaType,
          operators as OperatorsType,
          values
        );
      },
      []
    ),
    debounceDelay: 500,
    skipFirstFetch: !!operations && !!profiles,
  });

  useEffect(() => setLoading(loading), [loading, setLoading]);
  useEffect(() => {
    if (!response) {
      return;
    }

    setOperationsAndProfiles(
      response.data.operations ?? [],
      response.data.profiles ?? [],
      response.totalItems ?? 0
    );
  }, [response, setOperationsAndProfiles]);

  return { fetch, setFilters };
};
