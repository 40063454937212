import type { DictionaryEntry } from "../client";

const model: DictionaryEntry = {
  key: "",
  value: "",
};

export const getEmptyDictionaryEntry = (): DictionaryEntry => {
  return JSON.parse(JSON.stringify(model));
};
