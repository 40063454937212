import React, { useContext } from "react";
import { AuthContext } from "@Contexts";
import { Section, TimeZone } from "@Components";

export const DateSettingsCard = React.memo(() => {
  const { settings, setSettings } = useContext(AuthContext);

  return settings ? (
    <Section title="common.date-settings">
      <TimeZone
        value={settings.timeZone}
        onChange={e =>
          setSettings(
            {
              ...(settings || {}),
              timeZone: e.target.value,
            },
            true
          )
        }
      />
    </Section>
  ) : (
    <></>
  );
});
