import { BASE_EDGE, nodesColors } from "@Constants";
import type { Method, Nullable, Vector2 } from "@Interfaces";
import { APP_ZOOM_PERCENTAGE } from "@Stores";
import {
  type Edge,
  type EdgeMarker,
  type Node,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIssueDetailsCanvasStore } from "../../../../../IssueDetailsCanvas.store";
import { getFlow } from "../../../functions";
import type {
  ApiCallEdges,
  ApiCallNode,
  ApiCallNodeData,
} from "../IssueDetailsApiCall.i";

const NODE_SIZE: Vector2 = { x: 350, y: 81 };

export const useIssueDetailsApiCall = () => {
  const { issueDetails } = useIssueDetailsCanvasStore();
  const [selectedFlowIndex, setSelectedFlowIndex] = useState<number>(0);
  const [nodes, setNodes, onNodesChange] = useNodesState<ApiCallNode>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [graphReady, setGraphReady] = useState(false);
  const flows = useMemo(() => getFlow(issueDetails), [issueDetails]);
  const memoizedNodes = useMemo(() => nodes, [nodes]);
  const memoizedEdges = useMemo(() => edges, [edges]);

  const generateNodes = useCallback(() => {
    const nodes: Array<ApiCallNode> = [];

    flows.forEach((flow, index) => {
      const requestSplitted = flow.request.split(" ");
      const verb = requestSplitted[0] as Method;
      const path = requestSplitted[1] ?? "";
      const status = flow.response.split(" ")[1] ?? "";

      nodes.push({
        id: `node-${index}`,
        position: { x: 0, y: index * (NODE_SIZE.y + 75) },
        type: "default",
        width: NODE_SIZE.x * APP_ZOOM_PERCENTAGE,
        height: NODE_SIZE.y * APP_ZOOM_PERCENTAGE,
        data: {
          index,
          first: index === 0,
          last: index === flows.length - 1,
          selected: index === selectedFlowIndex,
          flow,
          verb,
          path,
          status,
        },
      });
    });

    setNodes(nodes);
  }, [flows, selectedFlowIndex, setNodes]);

  const generateEdges = useCallback(() => {
    const edges: ApiCallEdges = [];
    if (flows.length < 2) {
      setEdges([]);
      return;
    }

    for (let flowIndex = 0; flowIndex < flows.length - 1; ++flowIndex) {
      edges.push({
        ...BASE_EDGE,
        id: `edge-${flowIndex}-${flowIndex + 1}`,
        source: `node-${flowIndex}`,
        target: `node-${flowIndex + 1}`,
        sourceHandle: `handle-source-node-${flowIndex}`,
        targetHandle: `handle-target-node-${flowIndex + 1}`,
        animated: true,
        markerEnd: {
          ...((BASE_EDGE.markerEnd as EdgeMarker) ?? {}),
          color: nodesColors.edge.marker.highlight,
        },
      });
    }

    setEdges(edges);
  }, [flows, setEdges]);

  const handleNodeClick = useCallback(
    (
      event: Nullable<React.MouseEvent<Element>>,
      node: Node<ApiCallNodeData>
    ) => {
      setSelectedFlowIndex(node.data.index);
    },
    []
  );

  useEffect(() => {
    generateNodes();
    generateEdges();

    const timeout = setTimeout(() => {
      setGraphReady(true);
    }, 100); /* this is an hack to let react graph center nodes */

    return () => clearTimeout(timeout);
  }, [generateEdges, generateNodes]);

  return {
    graphReady,
    nodes: memoizedNodes,
    edges: memoizedEdges,
    selectedFlow: flows ? flows[selectedFlowIndex] ?? null : null,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    handleNodeClick,
  };
};
