import { Category } from "@Services";
import type { Category as CategoryInterface } from "@Services";

export type AvailableCategory = Exclude<
  CategoryInterface,
  "CategoryArchitecture"
>;

export const getScanExtensionCategories = () => {
  const result = Object.assign({}, { ...Category }) as Record<string, string>;

  // currently unsupported
  delete result["CategoryArchitecture"];

  return result as Record<string, AvailableCategory>;
};
