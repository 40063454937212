import { Tabs } from "@Components";
import { t } from "i18next";
import { memo, useState } from "react";
import { getTabs } from "./functions";
import { Parameter } from "./Parameter";
import { useAPIDefinitionDetailsStore } from "./Store/APIDefinitionDetailsStore";
import isEqual from "react-fast-compare";

export const AdditionalInfo = memo(() => {
  const [activeTab, setActiveTab] = useState(0);
  const { details } = useAPIDefinitionDetailsStore();

  if (!details) {
    return null;
  }

  const tabs = getTabs(details);
  const tabContent = tabs[activeTab];

  return (
    <section className="section-container" id="additional-info">
      <h2 className="section-title">{t("common.parameters")}</h2>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      {tabContent && !!tabContent.items.length && (
        <div className="param-container card">
          {tabContent.items.map((parameter, i) => (
            <Parameter key={i + parameter.name} parameter={parameter} />
          ))}
        </div>
      )}
    </section>
  );
}, isEqual);
