import { MethodBadge } from "@Components";
import type { Method } from "@Interfaces";
import { BEM, getNodeParameterIcon, joinClasses, limitString } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import React, { memo, useEffect, useMemo } from "react";
import isEqual from "react-fast-compare";
import type { ParameterLinkNodeProps } from "./ParameterLinkNode.i";
import "./styles.scss";

const MAX_LENGTH = 40; // evaluated by default parameter-link-node length (20rem)

// CustomNode component with types
export const ParameterLinkNode: React.FC<ParameterLinkNodeProps> = memo(
  ({ id: nodeId, data }) => {
    const updateNodeInternals = useUpdateNodeInternals();

    const properties = useMemo(() => {
      const { parameters } = data;
      if (!parameters) {
        return null;
      }

      const result = [];

      for (const parameter of parameters) {
        const key = `${nodeId}_${parameter.name}`;
        result.push(
          <div className={BEM("parameter-link-node", "property")} key={key}>
            {parameter.input && (
              <Handle
                type="target"
                position={Position.Left}
                id={`handle-target-${key}`}
                isConnectable={false}
              >
                <i className="bi bi-arrow-right-square-fill" />
              </Handle>
            )}
            <i className={getNodeParameterIcon(parameter.type)} />
            <span title={parameter.name}>
              {limitString(parameter.name, MAX_LENGTH)}
            </span>
            {parameter.output && (
              <Handle
                type="source"
                position={Position.Right}
                id={`handle-source-${key}`}
                isConnectable={false}
              >
                <i className="bi bi-arrow-right-square-fill" />
              </Handle>
            )}
          </div>
        );
      }

      return result;
    }, [data, nodeId]);

    useEffect(() => {
      updateNodeInternals(nodeId);
    }, [data, nodeId, updateNodeInternals]);

    return (
      <div
        id={nodeId}
        className={joinClasses(
          "parameter-link-node",
          data.consumer && "consumer",
          data.selected && "selected"
        )}
      >
        <div className={BEM("parameter-link-node", "title")}>
          <span title={data.path}>{limitString(data.name, 30)}</span>
          {data.verb && <MethodBadge type={data.verb as Method} />}
        </div>
        {properties}
      </div>
    );
  },
  isEqual
);
