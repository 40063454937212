/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserAuthorizationMatrixResponse } from '../model';
// @ts-ignore
import { UserCreateScanRequest } from '../model';
// @ts-ignore
import { UserCreateScanResponse } from '../model';
// @ts-ignore
import { UserGetReportRequest } from '../model';
// @ts-ignore
import { UserGetScanResponse } from '../model';
// @ts-ignore
import { UserListAuthenticationProfilesResponse } from '../model';
// @ts-ignore
import { UserListScansResponse } from '../model';
// @ts-ignore
import { UserReportScanResponse } from '../model';
// @ts-ignore
import { UserStopScanResponse } from '../model';
/**
 * ScanApi - axios parameter creator
 * @export
 */
export const ScanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new scan with the specified details
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserCreateScanRequest} [requestBody] UserCreateScanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScan: async (organizationId: string, projectId: string, requestBody?: UserCreateScanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createScan', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createScan', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/create`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGet: async (organizationId: string, projectId: string, scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanGet', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanGet', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('scanGet', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the authorization matrix with the given scan id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'auth_matrix_verb' | 'auth_matrix_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGetAuthorizationMatrix: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'auth_matrix_verb' | 'auth_matrix_path'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanGetAuthorizationMatrix', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanGetAuthorizationMatrix', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('scanGetAuthorizationMatrix', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/authorization-matrix`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the report with the given scan id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {UserGetReportRequest} requestBody UserGetReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGetReport: async (organizationId: string, projectId: string, scanId: string, requestBody: UserGetReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanGetReport', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanGetReport', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('scanGetReport', 'scanId', scanId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('scanGetReport', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/report`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the authentication profiles of the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_profile_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'authentication_profile_name' | 'authentication_profile_origin'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanListAuthenticationProfiles: async (organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_profile_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'authentication_profile_name' | 'authentication_profile_origin'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanListAuthenticationProfiles', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanListAuthenticationProfiles', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('scanListAuthenticationProfiles', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/authentication-profiles`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the scans in the organization, excluding scans related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanListInOrganization: async (organizationId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanListInOrganization', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/scans`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the scans in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanListInProject: async (organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanListInProject', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanListInProject', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanStop: async (organizationId: string, projectId: string, scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('scanStop', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanStop', 'projectId', projectId)
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('scanStop', 'scanId', scanId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/scans/{scan_id}/stop`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"scan_id"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScanApi - functional programming interface
 * @export
 */
export const ScanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScanApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new scan with the specified details
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserCreateScanRequest} [requestBody] UserCreateScanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScan(organizationId: string, projectId: string, requestBody?: UserCreateScanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScan(organizationId, projectId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanGet(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanGet(organizationId, projectId, scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the authorization matrix with the given scan id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'auth_matrix_verb' | 'auth_matrix_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanGetAuthorizationMatrix(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'auth_matrix_verb' | 'auth_matrix_path'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthorizationMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanGetAuthorizationMatrix(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the report with the given scan id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {UserGetReportRequest} requestBody UserGetReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanGetReport(organizationId: string, projectId: string, scanId: string, requestBody: UserGetReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReportScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanGetReport(organizationId, projectId, scanId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the authentication profiles of the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_profile_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'authentication_profile_name' | 'authentication_profile_origin'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanListAuthenticationProfiles(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_profile_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'authentication_profile_name' | 'authentication_profile_origin'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListAuthenticationProfilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanListAuthenticationProfiles(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the scans in the organization, excluding scans related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanListInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the scans in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListScansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanStop(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStopScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanStop(organizationId, projectId, scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScanApi - factory interface
 * @export
 */
export const ScanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScanApiFp(configuration)
    return {
        /**
         * Creates a new scan with the specified details
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserCreateScanRequest} [requestBody] UserCreateScanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScan(organizationId: string, projectId: string, requestBody?: UserCreateScanRequest, options?: any): AxiosPromise<UserCreateScanResponse> {
            return localVarFp.createScan(organizationId, projectId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGet(organizationId: string, projectId: string, scanId: string, options?: any): AxiosPromise<UserGetScanResponse> {
            return localVarFp.scanGet(organizationId, projectId, scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the authorization matrix with the given scan id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'auth_matrix_verb' | 'auth_matrix_path'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGetAuthorizationMatrix(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'auth_matrix_verb' | 'auth_matrix_path'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserAuthorizationMatrixResponse> {
            return localVarFp.scanGetAuthorizationMatrix(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the report with the given scan id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {UserGetReportRequest} requestBody UserGetReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGetReport(organizationId: string, projectId: string, scanId: string, requestBody: UserGetReportRequest, options?: any): AxiosPromise<UserReportScanResponse> {
            return localVarFp.scanGetReport(organizationId, projectId, scanId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the authentication profiles of the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'authentication_profile_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'authentication_profile_name' | 'authentication_profile_origin'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanListAuthenticationProfiles(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_profile_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'authentication_profile_name' | 'authentication_profile_origin'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListAuthenticationProfilesResponse> {
            return localVarFp.scanListAuthenticationProfiles(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the scans in the organization, excluding scans related to archived projects
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScansResponse> {
            return localVarFp.scanListInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the scans in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListScansResponse> {
            return localVarFp.scanListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops the scan with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {string} scanId Scan ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanStop(organizationId: string, projectId: string, scanId: string, options?: any): AxiosPromise<UserStopScanResponse> {
            return localVarFp.scanStop(organizationId, projectId, scanId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScanApi - interface
 * @export
 * @interface ScanApi
 */
export interface ScanApiInterface {
    /**
     * Creates a new scan with the specified details
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserCreateScanRequest} [requestBody] UserCreateScanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    createScan(organizationId: string, projectId: string, requestBody?: UserCreateScanRequest, options?: AxiosRequestConfig): AxiosPromise<UserCreateScanResponse>;

    /**
     * Returns the scan with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanGet(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetScanResponse>;

    /**
     * Returns the authorization matrix with the given scan id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'auth_matrix_verb' | 'auth_matrix_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanGetAuthorizationMatrix(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'auth_matrix_verb' | 'auth_matrix_path'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserAuthorizationMatrixResponse>;

    /**
     * Returns the report with the given scan id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {UserGetReportRequest} requestBody UserGetReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanGetReport(organizationId: string, projectId: string, scanId: string, requestBody: UserGetReportRequest, options?: AxiosRequestConfig): AxiosPromise<UserReportScanResponse>;

    /**
     * Returns the authentication profiles of the scan with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'authentication_profile_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'authentication_profile_name' | 'authentication_profile_origin'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanListAuthenticationProfiles(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_profile_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'authentication_profile_name' | 'authentication_profile_origin'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListAuthenticationProfilesResponse>;

    /**
     * Returns the scans in the organization, excluding scans related to archived projects
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScansResponse>;

    /**
     * Returns the scans in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListScansResponse>;

    /**
     * Stops the scan with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanStop(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig): AxiosPromise<UserStopScanResponse>;

}

/**
 * ScanApi - object-oriented interface
 * @export
 * @class ScanApi
 * @extends {BaseAPI}
 */
export class ScanApi extends BaseAPI implements ScanApiInterface {
    /**
     * Creates a new scan with the specified details
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserCreateScanRequest} [requestBody] UserCreateScanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public createScan(organizationId: string, projectId: string, requestBody?: UserCreateScanRequest, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).createScan(organizationId, projectId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the scan with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanGet(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanGet(organizationId, projectId, scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the authorization matrix with the given scan id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'auth_matrix_verb' | 'auth_matrix_path'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanGetAuthorizationMatrix(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'auth_matrix_verb' | 'auth_matrix_path' | 'auth_matrix_category', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'auth_matrix_verb' | 'auth_matrix_path'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanGetAuthorizationMatrix(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the report with the given scan id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {UserGetReportRequest} requestBody UserGetReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanGetReport(organizationId: string, projectId: string, scanId: string, requestBody: UserGetReportRequest, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanGetReport(organizationId, projectId, scanId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the authentication profiles of the scan with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'authentication_profile_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'authentication_profile_name' | 'authentication_profile_origin'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'EQ' | 'LIKE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanListAuthenticationProfiles(organizationId: string, projectId: string, scanId: string, page?: number, size?: number, sortBy?: 'authentication_profile_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'authentication_profile_name' | 'authentication_profile_origin'>, filterOperators?: Array<'EQ' | 'LIKE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanListAuthenticationProfiles(organizationId, projectId, scanId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the scans in the organization, excluding scans related to archived projects
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanListInOrganization(organizationId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanListInOrganization(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the scans in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'scan_started_at' | 'scan_completed_at'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanListInProject(organizationId: string, projectId: string, page?: number, size?: number, sortBy?: 'scan_started_at' | 'scan_completed_at', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'scan_status' | 'scan_triggered_by' | 'scan_exploitation' | 'period'>, filterOperators?: Array<'LIKE' | 'EQ' | 'NEQ' | 'GE' | 'LE'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanListInProject(organizationId, projectId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops the scan with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {string} scanId Scan ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanStop(organizationId: string, projectId: string, scanId: string, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanStop(organizationId, projectId, scanId, options).then((request) => request(this.axios, this.basePath));
    }
}
