import type { Nullable } from "@Interfaces";
import { createStore } from "@Hooks";

export type MaintenanceModeStoreProperties = {
  maintenanceModeInProgress: boolean;
  maintenanceModeScheduled: boolean;
  maintenanceModeStart: Nullable<number>;
  maintenanceModeEnd: Nullable<number>;
  maintenanceModeLockStart: Nullable<number>;
  maintenanceModeLockEnd: Nullable<number>;
};

export type MaintenanceModeStore = MaintenanceModeStoreProperties & {
  setMaintenanceModeRanges: (
    start: number,
    end: number,
    lockStart: number,
    lockEnd: number
  ) => void;
};

const initialState: MaintenanceModeStoreProperties = {
  maintenanceModeInProgress: false,
  maintenanceModeScheduled: false,
  maintenanceModeStart: null,
  maintenanceModeEnd: null,
  maintenanceModeLockStart: null,
  maintenanceModeLockEnd: null,
};

export const useMaintenanceModeStore = createStore<MaintenanceModeStore>(
  (get, set) => ({
    ...initialState,
    setMaintenanceModeRanges: (start, end, lockStart, lockEnd) =>
      set(store => {
        const now = Date.now();
        return {
          ...store,
          maintenanceModeInProgress: now >= lockStart && now <= lockEnd,
          maintenanceModeScheduled: now <= end,
          maintenanceModeStart: start,
          maintenanceModeEnd: end,
          maintenanceModeLockStart: lockStart,
          maintenanceModeLockEnd: lockEnd,
        };
      }),
  })
);
