import { useUrlParams } from "@Hooks";
import { API } from "@Services";
import { useState } from "react";
import { useProjectSettingsStore } from "../ProjectSettings.store";

export const useIssueExclusion = (refresh: () => Promise<void>) => {
  const [canvasOpen, setCanvasOpen] = useState(false);
  const { organizationId, projectId } = useUrlParams();
  const [editIndex, setEditIndex] = useState<number>();
  const { loading, issueExclusions } = useProjectSettingsStore();

  const canvas = {
    isOpen: canvasOpen,
    open: () => setCanvasOpen(true),
    close: () => {
      setCanvasOpen(false);
      setEditIndex(undefined);
    },
  };

  const actions = {
    deleteEntry: async (index: number) => {
      if (!organizationId || !projectId) return;
      await API.scanSettings().issueExclusionDelete(
        organizationId,
        projectId,
        issueExclusions[index].id
      );
      await refresh();
    },
    editEntry: async (index: number) => {
      setEditIndex(index);
      canvas.open();
    },
  };

  return {
    editIndex,
    actions,
    canvas,
    issueExclusions,
    loading,
  };
};
