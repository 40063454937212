import { getTokenFromCache } from "@Services";

const base64UrlDecode = (base64Url: string) => {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return atob(base64);
};

const parseJwtPayload = (token: string) => {
  const [, payload] = token.split(".");
  if (!payload) return undefined;
  try {
    const decodedPayload = base64UrlDecode(payload);
    return JSON.parse(decodedPayload);
  } catch {
    return undefined;
  }
};

const getTimeUntilExpiration = (token: string): number => {
  const payload = parseJwtPayload(token);

  // Check if the payload and expiration time (exp) are present in the token
  if (payload && payload.exp) {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const timeUntilExpirationInSeconds = payload.exp - currentTimeInSeconds;

    // Check if the token is still valid (expiration time is in the future)
    if (timeUntilExpirationInSeconds > 0) {
      // Convert the time until expiration from seconds to minutes
      const timeUntilExpirationInMinutes = Math.floor(
        timeUntilExpirationInSeconds / 60
      );
      return timeUntilExpirationInMinutes;
    } else {
      // The token has already expired
      return -1;
    }
  }

  // The token doesn't contain an expiration date
  return -1;
};

export const getMinutesUntilExpiration = (token?: string) => {
  const tokenData = token || getTokenFromCache();
  return tokenData ? getTimeUntilExpiration(tokenData) : -1;
};
