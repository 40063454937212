import { Accordion } from "@Components";
import { joinClasses } from "@Utils";
import { t } from "i18next";
import { type FC, useMemo } from "react";
import { RefreshDefinitionForm } from "./Components";
import { UserTokensForm } from "./Components/UserTokensForm";
import { useStaticTokenForm } from "./Hooks";

export const StaticTokenForm = () => {
  const {
    authenticationSettings,
    enabledDomains,
    validities,
    refreshDefinitionValid,
    handleChange,
  } = useStaticTokenForm();

  const refreshDefinitionAccordion = useMemo(() => {
    return (
      <Accordion
        data={[
          {
            children: <UserTokensForm />,
            header: <UserTokensFormHeader valid={validities.userAuthTokens} />,
            key: "user-tokens",
          },
          {
            children: (
              <RefreshDefinitionForm
                validities={validities}
                enabledDomains={enabledDomains}
                handleChange={handleChange}
              />
            ),
            header: (
              <RefreshDefinitionHeader
                dirty={!!authenticationSettings.refreshDefinition?.type}
                valid={refreshDefinitionValid}
              />
            ),
            key: "refresh-definition",
          },
        ]}
      />
    );
  }, [
    authenticationSettings.refreshDefinition?.type,
    enabledDomains,
    handleChange,
    refreshDefinitionValid,
    validities,
  ]);

  return <>{refreshDefinitionAccordion}</>;
};

const RefreshDefinitionHeader: FC<{ dirty: boolean; valid: boolean }> = ({
  dirty,
  valid,
}) => {
  const icon = valid
    ? "bi-check text-success"
    : "bi-exclamation-circle text-danger";
  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <span>
        {t("project.authentication-and-dictionary.refresh-definition")}
      </span>
      {dirty && (
        <i
          className={joinClasses("ms-2 bi cursor-pointer", icon)}
          data-tooltip-id="tooltip"
          data-tooltip-place="top"
          data-tooltip-content={t("common.required")}
        />
      )}
    </div>
  );
};

const UserTokensFormHeader: FC<{ valid: boolean }> = ({ valid }) => {
  const icon = valid
    ? "bi-check text-success"
    : "bi-exclamation-circle text-danger";
  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <span>{t("project.authentication-and-dictionary.user-tokens")}</span>
      <i
        className={joinClasses("ms-2 bi cursor-pointer", icon)}
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-content={t("common.required")}
      />
    </div>
  );
};
