import type { SwitchButtonsProps } from "./ListItems.i";
import { Button } from "@Components";

export const SwitchButtons = ({
  cardVersion,
  setCardVersion,
}: SwitchButtonsProps) => (
  <div className="mt-2 mb-2 d-flex justify-content-end switch-list-items">
    <Button
      iconClass="grid"
      color={cardVersion ? "primary" : "secondary"}
      onClick={() => setCardVersion(true)}
      className={cardVersion ? "active" : ""}
    />
    <Button
      className={`${cardVersion ? "" : "active"}`}
      iconClass="list"
      color={cardVersion ? "secondary" : "primary"}
      onClick={() => setCardVersion(false)}
    />
  </div>
);
