import {
  Accordion,
  DisplayRow,
  DisplaySection,
  InfoBadge,
  MarkdownText,
  MethodBadge,
  NotificationBadge,
} from "@Components";
import { BEM } from "@Utils";
import { t } from "i18next";
import { memo } from "react";
import { useIssueDetailsCanvasStore } from "../../../../IssueDetailsCanvas.store";

export const IssueDetailsMainInfo = memo(() => {
  const { issueDetails } = useIssueDetailsCanvasStore();

  if (!issueDetails) {
    return null;
  }

  const { description, remediation } = issueDetails;
  return (
    <>
      <div
        id={BEM("issue-details__content", "main-info-container")}
        data-cy={"issue-details-main-info"}
      >
        <div>
          <div id={BEM("issue-details__content__main-info-container", "title")}>
            <h1 className="issue-name">{issueDetails.name}</h1>
            {issueDetails.isNew && (
              <NotificationBadge
                display="badge"
                type="new"
                iconClass="bi bi-exclamation-square-fill"
              />
            )}
          </div>
          <DisplaySection
            className="mt-2 mb-4"
            label="common.endpoint"
            value={
              <div className="endpoint-box">
                <MethodBadge type={issueDetails.verb} className="me-2" />
                <span>{issueDetails.endpoint}</span>
              </div>
            }
          />

          <DisplayRow
            noHeader
            className="m-0"
            columns={[
              {
                label: "common.severity",
                value: (
                  <InfoBadge
                    bgColor={`severity-${issueDetails.severity}`}
                    children={t(`common.${issueDetails.severity}`)}
                  />
                ),
                xs: 4,
                xl: 2,
              },
              {
                label: "common.confidence",
                value: (
                  <InfoBadge
                    bgColor={
                      issueDetails.confidence === "certain"
                        ? "green"
                        : undefined
                    }
                    children={t(`common.${issueDetails.confidence}`)}
                  />
                ),
                xs: 4,
                xl: 2,
              },
              {
                label: "OWASP",
                value: issueDetails.owaspTopTen || "-",
                xs: 4,
                xl: 2,
              },
              {
                label: "common.category",
                value: issueDetails.category,
                xs: 12,
                lg: 6,
              },
            ]}
          />
          <DisplayRow
            noHeader
            className="mt-0 mb-4"
            columns={[
              {
                label: "CVSS",
                value: <InfoBadge children={<>{issueDetails.cvss}</>} />,
                xs: 4,
                xl: 2,
              },
              {
                label: "CWE",
                value: issueDetails.cwes.map(c => (
                  <InfoBadge key={c} className="me-2" children={c} />
                )),
                xs: 4,
              },
            ]}
          />

          <Accordion
            data={[
              {
                children: <MarkdownText>{description}</MarkdownText>,
                header: "common.description",
                iconClass: "bi bi-info-square-fill",
                key: "issue-description",
                active: true,
              },
              {
                children: <MarkdownText>{remediation}</MarkdownText>,
                header: "common.remediation",
                iconClass: "bi bi-wrench-adjustable-circle-fill",
                key: "issue-remediation",
              },
            ]}
          />
        </div>
      </div>
    </>
  );
});
