import { Button, type ButtonProps } from "@Components";
import { memo, useCallback, type FC } from "react";
import { useOrganizationSpaceLinkStore } from "@Stores";
import isEqual from "react-fast-compare";

const defaultProps: ButtonProps = {
  color: "primary",
  iconClass: "plus-square-fill",
  data: { cy: "create-space-link-button" },
};

type OpenCanvasButtonProps = {
  label: string;
};

export const OpenCanvasButton: FC<OpenCanvasButtonProps> = memo(({ label }) => {
  const { setCanvasOpen, contentLoaded } = useOrganizationSpaceLinkStore();

  const handleCanvasOpen = useCallback(() => {
    if (!contentLoaded) return;
    setCanvasOpen(true);
  }, [contentLoaded, setCanvasOpen]);

  return (
    <Button {...defaultProps} children={label} onClick={handleCanvasOpen} />
  );
}, isEqual);
