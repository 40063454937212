import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, generateRandomToken, getAuthUserContext } from "src/Mock";

export const verifyOtp = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { otp } = (await req.json()) as {
    otp: string;
  };

  if (otp.length !== 6) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const context = getAuthUserContext();

  const user = context.getUser();
  const loginPhase = context.getLoginPhase();

  if (!user || loginPhase !== "otp") {
    return res(ctx.delay(delay), ctx.status(403));
  }

  context.setLoginPhase("authenticated");

  return res(
    ctx.delay(delay),
    ctx.json({
      authenticationToken: generateRandomToken(),
    })
  );
};
