import React from "react";
import { Meta, NoAuthPage } from "@Components";
import type { Props } from "./Login.i";
import {
  NewPassword,
  ForgotPassword,
  LoginCard,
  SetupOtp,
  SendOtp,
} from "./Components";
import { useLogOff } from "./useLogOff";
import { useErrors } from "./useErrors";

export const LoginPage = React.memo(({ subPage = "login" }: Props) => {
  useErrors();
  useLogOff();

  return (
    <>
      <Meta pageName="Login" />
      <NoAuthPage>
        <>
          {subPage === "login" && <LoginCard />}
          {subPage === "setup-2fa" && <SetupOtp />}
          {subPage === "send-otp" && <SendOtp />}
          {subPage === "forgot-password" && <ForgotPassword />}
          {subPage === "new-password" && <NewPassword />}
        </>
      </NoAuthPage>
    </>
  );
});
