import type { PlainObject } from "@Interfaces";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { apiKeys, delay } from "src/Mock";

export const apiKeyDelete = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const exit = (status: number) => {
    return res(ctx.delay(delay), ctx.json({}), ctx.status(status));
  };

  const { apiKeyId } = req.params as PlainObject<string>;
  if (!apiKeyId) return exit(400);

  const index = apiKeys.findIndex(k => k.id === apiKeyId);
  if (index < 0) return exit(404);

  apiKeys.splice(index, 1);
  return exit(200);
};
