import type { ApiKeyId, ApiKeys, HeaderItems, Setter } from "@Interfaces";
import { dateTimeFormat, getApiKeyExtraData } from "@Services";
import { joinClasses } from "@Utils";
import { t } from "i18next";

export const getHeaders = (): HeaderItems => [
  { text: "common.name", sortByKey: "api_key_name" },
  { text: "common.role", sortByKey: "api_key_role_value" },
  { text: "common.created-at", sortByKey: "api_key_created_at" },
  { text: "common.expires-at", sortByKey: "api_key_expires_at" },
  { text: "common.remaining-days" },
  { text: "common.status", sortByKey: "api_key_role_status" },
];

const apiKeysStatusIcons: Record<string, string> = {
  enabled: "check2 color-success",
  disabled: "x color-severity-low",
  expired: "exclamation-triangle color-severity-high",
};

export const getConfig = (apiKeys: ApiKeys, onClick: Setter<ApiKeyId>) =>
  apiKeys.map(apiKey => {
    const { remainingDays, status, isExpired } = getApiKeyExtraData(apiKey);

    const classNames = ["active api-key-wrapper"];
    if (isExpired) classNames.push("color-severity-high");

    return {
      props: {
        className: classNames.join(" "),
        onClick: () => onClick(apiKey.id),
      },
      items: [
        {
          jsx: <span data-cy="name">{apiKey.name}</span>,
        },
        {
          jsx: <span data-cy="role">{t(`roles.${apiKey.role}`)}</span>,
        },
        {
          jsx: <>{dateTimeFormat(apiKey.createdAt, { trimTime: true })}</>,
        },
        {
          jsx: (
            <span data-cy="expire-date">
              {dateTimeFormat(apiKey.expiresAt, {
                trimTime: true,
                checkMaxEndDate: true,
              })}
            </span>
          ),
        },
        {
          jsx: (
            <span
              data-cy="remaining-days"
              className={joinClasses(
                remainingDays === 0 && "color-severity-high"
              )}
            >
              {Number.isFinite(remainingDays) ? remainingDays : "-"}
            </span>
          ),
        },
        {
          jsx: (
            <span className={`status-${status}`}>
              {isExpired ? (
                <i className={`bi bi-${apiKeysStatusIcons.expired}`} />
              ) : (
                <i className={`bi bi-${apiKeysStatusIcons[status]}`} />
              )}
              <>{t(`common.${status}`)}</>
            </span>
          ),
        },
      ],
    };
  });
