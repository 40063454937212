import { AuthContext } from "@Contexts";
import { useOrganizationContext } from "@Hooks";
import { checkRBACCondition, parseCondition } from "@Utils";
import { useContext } from "react";

export const useRbac = (condition: string | undefined) => {
  const { role } = useContext(AuthContext);
  const { license } = useOrganizationContext();

  if (license.loading) {
    return { canAccess: false, loading: true };
  }

  if (!condition) {
    return { canAccess: true, loading: false };
  }

  const canAccess = checkRBACCondition(
    parseCondition(condition),
    role ? [role.role] : [],
    license.name
  );

  return { canAccess, loading: false };
};
